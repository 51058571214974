import orderCenter from './orderCenter.html'
import orderCenterCtrl from './orderCenterCtrl'

export default angular.module('borepc.pages.orderCenter', [])
    .controller('orderCenterCtrl', ['$scope', '$uibModal', 'ModalUtils', '$state',orderCenterCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('orderCenter', {
            url: '/orderCenter',
            title: '我的订单',
            controller: 'orderCenterCtrl',
            template: orderCenter
        })
}
/**
 * Created by  jaray  2018-11-28
 */
'use strict';
import * as util from  '../../util/util'
import EchartRenderEngine from "../../rengine/EchartRenderEngine";

class PieRender{
    //默认高度
    height = 300;

     render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        var render = new EchartRenderEngine(containerDom, option);
        //  render.addClick(chartConfig, relations, $state, $window);
        render.addClick(chartConfig, relations);
        return render.chart(null, persist);
    };

     parseOption = function (data) {
        //  console.log("pei",data)
        var chartConfig = data.chartConfig;
        var casted_keys = data.keys;
        var casted_values = data.series;
        var aggregate_data = data.data;
        var newValuesConfig = data.seriesConfig;
        var colFormatter = [];
        var setColFormatter = false;
        //获取指标数组
        for (var i = 0; i < chartConfig.values.length; i++) {
            for (var j = 0; j < chartConfig.values[i].cols.length; j++) {
                var col = chartConfig.values[i].cols[j];
                colFormatter.push(col);
                //适配原有的图表配置,如果设置了提示框单位转换才需要转换单位,否则就用原先默认的
                if(col && (col.unit || col.symbol ||col.dividend || col.mantissa)){
                    setColFormatter = true;
                }
            }
        }
        var series = new Array();
        var string_keys = _.map(casted_keys, function (key) {
            return key.join('-');
        });
        var string_value = _.map(casted_values, function (value) {
            return value.join('-');
        });
        var b = 100 / (casted_values.length * 9 + 1);
        var titles = [];
        var radius = chartConfig.option.radius;
        // if(!_.isUndefined(radius) && radius && radius.replace("，",",").split(",").length>1){
        //     radius = chartConfig.option.radius.replace("，",",").split(",");
        // }

        //获取指标的数量
        var valueAccount = chartConfig.values[0].cols.length;


        for (var i = 0; i < aggregate_data.length; i++) {

            //获取当前图是第几个指标的(0开始计算)-----引入列维的可能
            var index = i % valueAccount;

            var joined_values = casted_values[i].join('-');
            var realType = util.clone(newValuesConfig[joined_values]).type;
            var radius = chartConfig.values[0].cols[i] && chartConfig.values[0].cols[i].radius;
            if(!_.isUndefined(radius) && radius && radius.replace("，",",").split(",").length>1){
                radius = radius.replace("，",",").split(",");
            }
            var center = ( chartConfig.values[0].cols[i] && chartConfig.values[0].cols[i].center) ? chartConfig.values[0].cols[i].center.split(",") :[5 * b + i * 9 * b + '%', '50%'];

            //文本标签相关设置
            var label = chartConfig.values[0].cols[index].label ? util.clone(chartConfig.values[0].cols[index].label) : {};
            label.show == undefined ? label.show = true : null;

            //当展示标签时才进行的相关设置
            if(label.show) {
                label.position = label.position ? label.position : "outside";
                label.rotate = label.rotate == "true" ? true : false;
                // label.rotate ? null : label.rotate = 0;
                label.fontSize = label.fontSize ? label.fontSize : 12;
                label.color = label.position === 'inside' ? (label.color ? label.color : '#fff') : 'inherit';
                //标签显示内容
                if(chartConfig.values[0].cols[index].showLabelType == "top"){
                    label.formatter = '{b}\n{d}%';
                } else if(chartConfig.values[0].cols[index].showLabelType == "percent") {
                    label.formatter = '{d}%';
                } else {
                    label.formatter = '{b}: {d}%';
                }

            }

            //引导线
            if(label.show && label.position == "outside") {
                var labelLine = chartConfig.values[0].cols[index].labelLine ? util.clone(chartConfig.values[0].cols[index].labelLine) : {};
                //默认打开
                labelLine.show == undefined ? labelLine.show = true : null;
                if(labelLine.show) {
                    labelLine.length = labelLine.length || labelLine.length === 0 ? labelLine.length : 15;
                    labelLine.length2 = labelLine.length2 || labelLine.length2 === 0 ? labelLine.length2 : 10;
                }
            } else {
                var labelLine = {
                    show: false
                }
            }

            var s = {
                name: string_value[i],
                type: 'pie',
                radius:radius||180,
                realType: realType,
                center: center,
                data: [],
                //roseType: 'angle'
                itemStyle: {
                    // normal: {
                        // label: {
                        //     show: true,
                        //     formatter: '{b}: {d}%'
                        // }
                    // },
                    emphasis: {
                        shadowBlur: 10,
                        shadowOffsetX: 0,
                        shadowColor: 'rgba(0, 0, 0, 0.5)'
                    }
                    // labelLine: {show: true}
                },
                label: label,
                labelLine : labelLine

            };
            if (realType == 'coxcomb') {
                s.roseType = 'angle';
            }
            if(chartConfig.option.isRoseType){
                s.roseType='radius'
            }

            titles.push({
                textAlign: 'center', textStyle: {
                    fontSize: 12,
                    fontWeight: 'normal'
                }, text: string_value[i], left: 5 * b + i * 9 * b + '%', top: '90%'
            });
            for (var j = 0; j < aggregate_data[i].length; j++) {
                s.data.push({
                    name: string_keys[j],
                    value: _.isUndefined(aggregate_data[i][j]) ? 0 : aggregate_data[i][j]
                });
            }
            //隐藏低于设定值的数据标签--按百分比
            if(label.show && (chartConfig.values[0].cols[index].minShowLabelAngle || chartConfig.values[0].cols[index].minShowLabelAngle >= 0 )){
                var minShowLabelAngle = chartConfig.values[0].cols[index].minShowLabelAngle / 100;
                var sum = 0;
                s.data.forEach(function(item){
                    if(!isNaN(Number(item.value))) {
                        sum += Number(item.value);
                    }
                });
                if(sum === 0){
                    s.label.show = false;
                } else {
                    for (var j = 0; j < s.data.length; j++) {
                        //不考虑负值
                        if(sum && (s.data[j].value / sum) <= minShowLabelAngle){
                            s.data[j].label = {
                                show: false
                            };
                            s.data[j].labelLine = {
                                show: false
                            };
                            s.data[j].emphasis = {
                                label: {
                                    show: false
                                },
                                labelLine: {
                                    show: false
                                }
                            }
                        };
                    }
                }

            }

            series.push(s);
        }
        var echartOption = {
            title: titles,
            legend: {
                orient: 'vertical',
                left: 'left',
                data: string_keys
            },
            tooltip: {
                trigger: 'item',
                formatter: setColFormatter?function(params){
                    var value = util.convertEchartUnit(colFormatter[params.seriesIndex],params.value);
                    var s = params.seriesName+"<br/>" +params.name+" : "+value+"("+params.percent+"%)"
                    return s
                }:"{a} <br/>{b} : {c} ({d}%)",
            },
            toolbox: false,
            series: series
        };

        util.updateEchartOptions(chartConfig.option, echartOption);
        util.updateEchartsTooltipPosition(echartOption);
        return echartOption;
    };
}
export {PieRender}


// 没有考虑列维的引入项---pie的大小和位置，单位设置

import BasicService from './BasicService';
import { DTPMANAGERAPI, WIDGETAPI, BOARDAPI } from './ApiServer';
/**
 */
class ModelBuyService extends BasicService {

    getDataSourceList (params) {
        if (params) {
            return this.post(DTPMANAGERAPI.GETDATASOURCELIST, params)
        }else{
            return this.get(DTPMANAGERAPI.GETDATASOURCELIST)
        }
    }

    getDtpList () {
        return this.get( DTPMANAGERAPI.GETDTPLIST)
    }

    getDatasetCategoryList () {
        return this.get(BOARDAPI.GETDATASETCATEGORYLIST)
    }

    getWidgetCategoryList () {
        return this.get( WIDGETAPI.GETWIDGETCATEGORYLIST)
    }

    getCategoryList () {
        return this.get( BOARDAPI.BOARDCATEGORY)
    }

    getBoardList () {
        return this.get( BOARDAPI.BOARDLIST)
    }

}

export const modelBuyService = new ModelBuyService();
import BasicService from './BasicService';
import { MODELCENTERAPI } from './ApiServer';
/**
 */
class ModelCenterService extends BasicService {
    // constructor () {
    //     super('/api/odm');
    // }

    getDataMarkerList (params) {
        return this.post( MODELCENTERAPI.GETDATAMARKETLIST, params);
    }

    resetDataMarket (params) {
        return this.post(MODELCENTERAPI.resetDataMarket, params);
    }

    generateDataMarket (params) {
        return this.post(MODELCENTERAPI.GENERATEDATAMARKET, params)
    }

}

export const modelCenterService = new ModelCenterService();
import  baWizardTpl from './baWizard.html'
import './_baWizard.scss'
  /** @ngInject */
export default function baWizard() {
    return {
      restrict: 'E',
      transclude: true,
      template: baWizardTpl,
      controllerAs: '$baWizardController',
      controller: 'baWizardCtrl'
    }
  }

import { match } from "angular-route";

/**
 * mySetToolInputcolor
 * 
 * boreDataTitle: 输入框前面的说明文---限制5个字
 * boreDataTip: 移入显示说明文---tip
 * boreDataValue: 关联的值--------------------------需要注意ng-repeat，不能直接使用，需用[arr][$index]的方式
 * boreDataInit: 初始值
 */

function getRandom(){
    return Math.ceil(Math.random()*10000);
}

export default function mySetToolInputcolor(){
    return {
        restrict: 'EA',
        template: ` <div class="bore-set-tool">
                        <div class="bore-set-tool-title" ng-click="inputFocus($event)">{{boreDataTitle}}</div>
                        <div class="bore-set-tool-content">
                            <div class="bore-set-tool-inputcolor" id="{{id}}">
                                <input type="text" class="inputcolor-text" ng-model="boreDataValue">
                                <span class="inputcolor-btn input-group-addon" ng-click="openColorModel()">
                                    <i style="background-color: {{boreDataValue?boreDataValue:'#ccc'}};"></i>
                                </span>
                            </div>
                        </div>
                        <div class="bore-set-tool-tip" ng-class="{'show':boreDataTip?true:false}">
                            <span>?</span>
                            <span class="tip-content">{{boreDataTip}}</span>
                        </div>
                    </div>`,
        replace: true,
        scope: {
            boreDataValue: '='
        },
        link: function(scope, element, attrs) {
            scope.inputFocus = function($event){
                $($event.target).next().children().children()[0].focus();
            };
            scope.boreDataTitle = attrs.boreDataTitle ? attrs.boreDataTitle : "";
            scope.boreDataTip = attrs.boreDataTip ? attrs.boreDataTip : "";

            //初始化颜色值
            scope.boreDataValue ? null : (scope.boreDataValue = attrs.boreDataInit ? attrs.boreDataInit : '#000');

            scope.id = "settoolcolor" + getRandom();

            //初始化时防止页面未加载完，防抖
            scope.timeId;
            scope.openColorModel = function(){
                clearTimeout(scope.timeId);
                scope.timeId = setTimeout(function(){
                    $("#" + scope.id).colorpicker({
                        customClass: 'inputcolor-box',
                        container: '#' + scope.id,
                    });
                    clearTimeout(scope.timeId);
                }, 100);
            };
            //初始化控件
            scope.openColorModel();
        }
    };
}
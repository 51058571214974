
export default function jsTreeCtrl($scope) {
    /*jshint validthis:true */
    var nodeSerialId = 1;
    var vm = this;
    vm.nodesFingerprint = function (e) {
        if (!e.__uiNodeId) {
            e.__uiNodeId = nodeSerialId++;
        }
        return '' + e.__uiNodeId + (e.id || '') + (e.text || '') + (e.type || '');
    };

    vm.changeWatcher = function (arraySource, tokenFn) {
        var self;
        var getTokens = function () {
            var result = [], token, el;
            if (arraySource) {
                var array = angular.isFunction(arraySource) ? arraySource() : arraySource;
                for (var i = 0, n = array.length; i < n; i++) {
                    el = array[i];
                    token = tokenFn(el);
                    map[token] = el;
                    result.push(token);
                }
            }
            return result;
        };
        // returns elements in that are in a but not in b
        // subtractAsSets([4, 5, 6], [4, 5, 7]) => [6]
        var subtractAsSets = function (a, b) {
            var result = [], inB = {}, i, n;
            for (i = 0, n = b.length; i < n; i++) {
                inB[b[i]] = true;
            }
            for (i = 0, n = a.length; i < n; i++) {
                if (!inB[a[i]]) {
                    result.push(a[i]);
                }
            }
            return result;
        };

        // Map objects to tokens and vice-versa
        var map = {};

        var applyChanges = function (newTokens, oldTokens) {
            var i, n, el, token;
            var replacedTokens = {};
            var removedTokens = subtractAsSets(oldTokens, newTokens);
            for (i = 0, n = removedTokens.length; i < n; i++) {
                var removedToken = removedTokens[i];
                el = map[removedToken];
                delete map[removedToken];
                var newToken = tokenFn(el);
                // if the element wasn't removed but simply got a new token, its old token will be different from the current one
                if (newToken === removedToken) {
                    self.onRemoved(el);
                } else {
                    replacedTokens[newToken] = removedToken;
                    self.onChanged(el);
                }
            }

            var addedTokens = subtractAsSets(newTokens, oldTokens);
            for (i = 0, n = addedTokens.length; i < n; i++) {
                token = addedTokens[i];
                el = map[token];
                if (!replacedTokens[token]) {
                    self.onAdded(el);
                }
            }

        };
        self = {
            subscribe: function (scope, onChanged) {
                scope.$watch(getTokens, function (newTokens, oldTokens) {
                    if (!onChanged || onChanged(newTokens, oldTokens) !== false) {
                        applyChanges(newTokens, oldTokens);
                    }
                }, true);
            },
            onAdded: angular.noop,
            onChanged: angular.noop,
            onRemoved: angular.noop
        };
        return self;
    };
}
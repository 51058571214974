
export default function ($scope, $uibModalInstance, param, filter, getSelects, ok) {
    $scope.type = ['=', '≠', '>', '<', '≥', '≤', '(a,b]', '[a,b)', '(a,b)', '[a,b]'];
    $scope.param = param;
    $scope.operate = {};
    $scope.filter = filter;
    $scope.byFilter = {a: false};
    $scope.loadSelect = false;
    // $scope.getSelects = function () {
    //     $scope.loading = true;
    //     getSelects($scope.byFilter.a, $scope.param.col, function (d) {
    //         $scope.selects = d;
    //         $scope.loading = false;
    //     });
    // };
    getSelects($scope.byFilter.a, $scope.param.col, function (d) {
        //大数据渲染解决方案一,渲染依然有卡顿现象
        // $scope.selects = [];
        // $scope.loading = false;
        // utilHandleBigData(d, (data) => {
        //     $scope.selects.push(...data);
        //     $scope.$apply();
        // });

        //大数据渲染解决方案二
        $scope.selects = d ;
        // $scope.allSelects = d;
        $scope.loading = false;
        $scope.$apply();
    });

    // $scope.filterSelected =function(data,a,b){
    //     console.log("data",data)
    //     console.log(a)
    //     console.log(b)
    // }
    var showValues = function () {
        var equal = ['=', '≠'];
        var openInterval = ['>', '<', '≥', '≤'];
        var closeInterval = ['(a,b]', '[a,b)', '(a,b)', '[a,b]'];
        $scope.operate.equal = $.inArray($scope.param.type, equal) > -1 ? true : false;
        $scope.operate.openInterval = $.inArray($scope.param.type, openInterval) > -1 ? true : false;
        $scope.operate.closeInterval = $.inArray($scope.param.type, closeInterval) > -1 ? true : false;
    };
    showValues();
    $scope.dbclickPush = function (o) {
        if ($scope.operate.equal) {
            if($scope.param.values.length == 1 && (_.isUndefined($scope.param.values[0]) || $scope.param.values[0]=='')){
                $scope.param.values.length = 0;
            }
            $scope.param.values.push(o);
        }
        if ($scope.operate.openInterval) {
            $scope.param.values[0] = o;
        }
        if ($scope.operate.closeInterval) {
            if ($scope.param.values[0] == undefined || $scope.param.values[0] == '') {
                $scope.param.values[0] = o;
            } else {
                $scope.param.values[1] = o;
            }
        }
    };
    $scope.deleteValues = function (array) {
        if ($scope.operate.equal) {
            $scope.param.values = _.difference($scope.param.values, array);
        }
    };
    $scope.pushValues = function (array) {
        if($scope.param.values.length == 1 && (_.isUndefined($scope.param.values[0]) || $scope.param.values[0]=='')){
            $scope.param.values.length = 0;
        }
        if ($scope.operate.openInterval) {
            array.splice(1, array.length - 1);
        }
        if ($scope.operate.closeInterval) {
            array.splice(2, array.length - 2);
        }
        _.each(array, function (e) {
            $scope.param.values.push(e);
        });
    };
    $scope.selected = function (v) {
        return _.indexOf($scope.param.values, v) == -1;
    };
    $scope.filterType = function () {
        $scope.param.values = [];
        $scope.param.values.length = 1;
        showValues();
    };
    $scope.close = function () {
        $uibModalInstance.close(closeModal);
    };
    $scope.ok = function () {
        $uibModalInstance.close(closeModal);
        $scope.param.values = _.filter($scope.param.values, function(e){
                return e != null && !_.isUndefined(e);
            }
        );
        // console.log($scope.param)
        ok($scope.param);
    };

    $scope.initValues = function () {
        if($scope.param.values.length==0){
            $scope.param.values.length = 1;
        }
    };

    //绑定backspace退出弹窗
    var bindKeydownEvent = function (e) {
        var e = e || window.event;
        if (e.keyCode === 8) {
            $uibModalInstance.close(closeModal);
        }
    }

    $(document).on('keydown', bindKeydownEvent);
   
    $scope.cancleBind = function () {
        $(document).off('keydown', bindKeydownEvent);
    }

    $scope.addBind = function () {
        $(document).on('keydown', bindKeydownEvent);
    }
    
    var closeModal = function () {
        $uibModalInstance.close(closeModal);
    }
    window.addEventListener('popstate', closeModal);

    //大数据渲染解决方案一
    // var utilHandleBigData = function(data, callback, pageSize = 200) {
    //     let totalCount = data.length; // 共多少条
    //     let currentPageNumber = 1; // 当前页数
    //     let totalPageNumer = Math.ceil(totalCount / pageSize); //可分多少页,就是分割为多少个小数组
        
    //     let handler = () => {
    //         console.log("currentPageNumber",currentPageNumber)
    //       let start = (currentPageNumber - 1) * pageSize;
    //       let end = currentPageNumber * pageSize;
    //       let currentData = data.slice(start, end); // 当前页的数据
    //       if (typeof callback === "function") {
    //         callback(currentData, {
    //           totalCount,
    //           totalPageNumer,
    //           currentPageNumber,
    //           pageSize,
    //         });
    //       }
    //       if (currentPageNumber < totalPageNumer) {
    //         //   setTimeout(() => {
    //         window.requestAnimationFrame(handler);
    //         //     handler();
    //         //   }, 100);
    //       }
    //       currentPageNumber++;
    //     };
    //     handler();
    // }
} 
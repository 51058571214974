import { globalSettingService,userAdminService,userRoleAdminService,behaviorLogService } from '../../../server'
import './userAdmin.scss'


export default function (  $scope, ModalUtils, $filter,toastr  ) {

    var translate = $filter('translate');
    $scope.optFlag;
    $scope.curUser;
    $scope.filterByRole = false;
    $scope.userKeyword = '';
    $scope.searckey={
        searchLoginName:'',
        searchName:''
    }
    $scope.selectRole = [];
    $scope.userRoles =[];
    $scope.setDefaultPassword = '0';

    var getGlobalSetting = function(){
        globalSettingService.getInfo().then(function(response){
            if(response){
                $scope.setDefaultPassword = response.setDefaultPassword;
            }
        })
    }
    getGlobalSetting();

    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 10,
        onChange: function () {
            getUserList();//重新加载
        }
    };



    var getUserList = function (firstLoad) {
        var params = {
            "pageNo": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "loginName": $scope.searckey.searchLoginName,
            "name": $scope.searckey.searchName,
        }
        userAdminService.postUserList(params).then(response => {
            $scope.paginationConf.totalItems = response.totolCount;
            $scope.userList = response.listdata;
            $scope.$apply();
            if(firstLoad){$scope.inserBehaviortLog();}
        })
    };

    getUserList(true);


    $scope.inserBehaviortLog =function(){
        try {
            behaviorLogService.insertLog({module:"user",function:"查看",name:"用户管理"})
        } catch (error) {
            // console.log("插入日志失败:"+error)
        }
    }
    // var getResList = function () {
    //     userAdminService.getRoleList().then(response => {
    //         $scope.resList = response;
    //     })
    // };
    // getResList();

    //获取用户列表
    var getRoleList = function(){
        userRoleAdminService.getRoleList().then(response =>{
            $scope.roleList = response;
        })
    }
    getRoleList();
    $scope.getTitleNames =function(list){
        if(!list||list.length==0)return "";
        var titles = "";
        _.map(list,function(e){
            titles+=(e.roleName+";")
        })
        return titles;
    }
    var getBindWechatIds = function (callback) {
        userAdminService.getBindUserIdByCompany('type=1').then(response => {
            if (response.status == '1') {
                $scope.bindUsers = response.data;
            }
            $scope.isBindWechat = function (userId) {
                if ($scope.bindUsers && $scope.bindUsers.indexOf(userId) != -1) return true;
                return false;
            }
            if (callback) {
                callback();
            }
        })
    }
    getBindWechatIds();

    $scope.searchUserByName = function (user) {
        getUserList();
    };



    $scope.newUser = function () {
        $scope.selectRole = []
        $scope.optFlag = 'newUser';
        $scope.showPassword = $scope.setDefaultPassword=='1'?false:true;
        $scope.curUser = {};
    };

    $scope.cancel = function () {
        $scope.optFlag = 'none';
        $scope.curUser = {};
    };

    $scope.editUser = function (user) {
        $scope.showPassword = true;
        $scope.optFlag = 'editUser';
        $scope.curUser = angular.copy(user);
        $scope.curUser.oldLoginName = user.loginName;
    };

    $scope.editUserInfo = function (userInfo) {
        $scope.optFlag = 'editUser';
        $scope.curUser = userInfo;
        $scope.curUser.oldLoginName = userInfo.loginName;
        $scope.showPassword = true;

        $scope.userRoles = _.filter(_.map(userInfo.userRoleLists,function(role){
            return role.roleId;
        }),function(id){
            return id != '1';
        })
        // $scope.userRoles =  _.filter($scope.roleList,function(role){
        //     return data.indexOf(role.id) >-1;
        // })
        $scope.selectRole =angular.copy( $scope.userRoles);
        // $scope.getRoleByUserInfo(userInfo);
    };

    $scope.showUserInfo = function (userInfo) {
        $scope.optFlag = 'showUser';
        $scope.curUser = userInfo;
        $scope.showPassword=false;
        // $scope.getRoleByUserInfo(userInfo);
    };


    $scope.getRoleByUserInfo = function(user){
        // $scope.selectRole = ['1','eaf15974e3f54596afd42f24130f3d91'];
        $scope.selectRole = [];
        userAdminService.getUserRoleListById({userId:$scope.curUser.id}).then(function(response){
            $scope.selectRole = _.filter(_.map(response,function(data){
                return data.roleId;
            }),function(id){
                return id!='1';
            })
            $scope.userRoles = angular.copy( $scope.selectRole);
        })
    }


    var validate = function (type) {
        if (type == 'user') {
            if (!$scope.curUser.loginName || !$scope.curUser.name ) {
                // ModalUtils.alert("账号、姓名、密码不能为空", "modal-warning", "sm");
                alert("姓名、登录名不能为空")
                return false;
            }
            if($scope.showPassword && $scope.optFlag=='newUser'  && (!$scope.curUser.newPassword || !$scope.curUser.confirmNewPassword) ){
                alert("密码不能为空");
                return;
            }
            if ($scope.showPassword && ($scope.curUser.confirmNewPassword != $scope.curUser.newPassword)) {
                alert("两次密码输入不一致")
                return false;
            }
            //校验邮箱
            // var regEmail = /^\w+@[a-z0-9]+\.[a-z]+$/i;
            var regEmail = /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+(\.[a-zA-Z0-9_-]+)+$/;
            if ($scope.curUser.email !== '' && $scope.curUser.email !== undefined) {
                if (!regEmail.test($scope.curUser.email)) {
                    alert("输入的邮箱有误，请重新输入");
                    return false;
                }
            }
            //校验手机
            var regMobile = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if ($scope.curUser.mobile !== '' && $scope.curUser.mobile !== undefined) {
                // console.log(regMobile.test($scope.curUser.mobile))
                if (!regMobile.test($scope.curUser.mobile)) {
                    alert("输入的手机号码有误，请重新输入");
                    return false;
                }
            }
        } else {
            if (!$scope.curRole.roleName || !$scope.curRole.userId) {
                alert("角色名和管理员不能为空")
                return false
            }
        }
        return true;
    }

    $scope.saveUser = function () {
        if (!validate('user')) {
            return;
        }
        if ($scope.optFlag == 'newUser') {
            userAdminService.saveNewUser($scope.curUser).then(function (response) {
                if(response.status == '1'){
                    $scope.updateUserRole(response.id,$scope.selectRole);
                    $('#newUserModal').modal('hide');
                } else {
                    toastr.error("保存失败!"+serviceStatus.msg);
                }
            },err=>{
                toastr.error("保存失败!"+err.head.errorMsg);
            })
        } else {
            userAdminService.editUser( $scope.curUser ).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    $scope.optFlag = 'none';
                    getUserList();
                    $scope.verify = {
                        dsName: true
                    };
                    toastr.success("保存成功!");
                    $('#newUserModal').modal('hide');
                } else {
                    toastr.error("保存失败!"+serviceStatus.msg);
                }
            },err=>{
                toastr.error("保存失败!"+err.head.errorMsg);
            })
        }

    };

    //获取企业微信
    var  getBindEnWechatIds =function(){
      userAdminService.getBindUserIdByCompany('type=5').then(function (response) {
        if (response.status == '1') {
            $scope.bindEWUsers = response.data;
        }
        $scope.isBindEnterpriseWechat = function (userId) {
            if ($scope.bindEWUsers && $scope.bindEWUsers.indexOf(userId) != -1){ return true};
            return false;
        }
        if (callback) {
            callback();
        }
    },err=>{
        toastr.error("保存失败!"+err.head.errorMsg);
    });
    }
    getBindEnWechatIds();

    var getBindPCIds = function (callback) {
        userAdminService.getBindUserIdByCompany('type=2').then(function (response) {
            if (response.status == '1') {
                $scope.bindPCUsers = response.data;
            }
            $scope.isBindPC = function (userId) {
                if ($scope.bindPCUsers && $scope.bindPCUsers.indexOf(userId) != -1) return true;
                return false;
            }
            if (callback) {
                callback();
            }
        },err=>{
            toastr.error("保存失败!"+err.head.errorMsg);
        });
    }
    getBindPCIds();

    $scope.deleteUserInfo = function (userInfo) {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "md", function () {
            userAdminService.deleteUser({userId:userInfo.id,userName:userInfo.name}).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                   toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
                getUserList();
            }).catch(function (serviceStatus) {
                toastr.error(serviceStatus.msg);
            });
        });
    }



    $scope.delUserRole = function () {
        if (!$scope.selectRole || $scope.selectRole.length < 1) {
            ModalUtils.alert("请选择要移除角色", "modal-warning", "sm");
            return;
        }



        var isDel =true;
        _.each($scope.selectRole, function (e) {
            if ($scope.userRoles.length==0 ||$scope.userRoles.indexOf(e) == -1) {
               isDel = false;
               return;
            }
        })
        if(!isDel){
            ModalUtils.alert("无法移除非用户角色", "modal-warning", "sm");
            return;
        };

        var roleNames = [];
        _.map($scope.roleList,function(e){
            if ($scope.selectRole.indexOf(e.id) > -1) {
                roleNames.push(e.name)
            }
        })
        userAdminService.batchDeleteUserRole({
            userId: $scope.curUser.id,
            roleIds: angular.toJson($scope.selectRole),
            userName:$scope.curUser.name,
            roleNames:roleNames.toString()
        }).then(function (response) {
            $scope.selectRole = [];
            $scope.userRoles = [];
            getUserList();
            $('#newUserModal').modal('hide');
            toastr.success("移除用户角色成功！")
        });

    };

    $scope.addUserRole = function(roles){
        //当用户角色组里有数据时 对比已经选择的角色 将不重复的添加进用户角色组
        if (!$scope.selectRole || $scope.selectRole.length < 1) {
            ModalUtils.alert("请选择要增加的角色", "modal-warning", "sm");
            return;
        }
        _.each($scope.selectRole, function (e) {
            if ($scope.userRoles.indexOf(e) == -1) {
                $scope.userRoles.push(e)
            }
        })
        $scope.updateUserRole($scope.curUser.id,$scope.userRoles)
    }

    $scope.updateUserRole =function(newUserId,newRoleIds){
        // newRoleIds = ["54448302cb5149a9ae96061de94833e8","eaf15974e3f54596afd42f24130f3d91"];
        var roleNames = [];
        _.map($scope.roleList,function(e){
            if ($scope.selectRole.indexOf(e.id) > -1) {
                roleNames.push(e.name)
            }
        })
        userAdminService.saveUserRole( {
            userId: newUserId,
            roleIds: angular.toJson(newRoleIds),
            userName:$scope.curUser.name,
            roleNames:roleNames.toString()
        }).then(function (res) {
            $scope.selectRole = [];
            $scope.userRoles = [];
            getUserList();
            $('#newUserModal').modal('hide');
            toastr.success("添加用户角色成功！");
        });
    }

   $scope.setStyle = function (roleId) {
       if($scope.selectRole.indexOf(roleId)>-1 || $scope.userRoles.indexOf(roleId)>-1){
        return "background-color:#ddd !important";
       }
    }

}


import editorTpl from './editor.html'
function   Editor( ) {
  
}


var  editor ={
    crtl:Editor,
    template:editorTpl
}

export  default editor;

import baProgressModal from './baProgressModal';
import baUtil from './baUtil';
import fileReader from './fileReader';
import preloader from './preloader';
import stopableInterval from './stopableInterval';
// import ModalUtils from './ModalUtils';


export default angular.module('BlurAdmin.theme.services',[])
    .factory('baProgressModal', ['$uibModal',baProgressModal])
    .service('baUtil', baUtil)
    .service('fileReader', ['$q',fileReader])
    .service('preloader', ['$q',preloader])
    // .service('ModalUtils', ModalUtils)
    .service('stopableInterval', ['$window',stopableInterval]).name 

import * as util from '../util/util'
class CommonRenderEngine {
    ERR_TPL = `<div id="common_dom" class='result-warning' style=" text-align: center;   padding-top: 30px; ">
                 <div class='result-icon'><i class='ion-alert-circled' style="   font-size: 30px; "></i></div>
                 <div class='result-title'><h3 style="
                 font-weight: 500;
                 font-size: 20px;
                  ">{msg}</h3></div>
               </div>`
    LOADING_TPL = `
                <div id="common_dom" class="bore-loading-mask" style="
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 2;
                margin: 0 auto;
                background-color: hsla(0,0%,100%,.5);
            "><div class="loading-gif" style="
                position: absolute;
                top: calc(50% - 30px);
                left: calc(50% - 20px);
                background-image: url(&quot;data:image/svg+xml;utf8,<svg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 30 30' style='enable-background:new 0 0 30 30;' xml:space='preserve'><style type='text/css'>.st0{fill:blue;}</style><path class='st0' d='M19.8,21L19.8,21c-0.6,0-1-0.4-1-1V10c0-0.5,0.4-1,1-1h0c0.5,0,1,0.4,1,1v10C20.8,20.6,20.4,21,19.8,21z'><animateTransform  attributeType='xml' attributeName='transform' type='scale' values='1,1; 1,2; 1,1' begin='0s' dur='0.8s' repeatCount='indefinite' /></path><path class='st0' d='M10.2,21L10.2,21c-0.6,0-1-0.4-1-1V10c0-0.5,0.4-1,1-1h0c0.5,0,1,0.4,1,1v10C11.2,20.6,10.7,21,10.2,21z'><animateTransform  attributeType='xml' attributeName='transform' type='scale' values='1,1; 1,2; 1,1' begin='0.2s' dur='0.8s' repeatCount='indefinite' /></path><path class='st0' d='M15,21L15,21c-0.6,0-1-0.4-1-1V10c0-0.5,0.4-1,1-1h0c0.5,0,1,0.4,1,1v10C16,20.6,15.6,21,15,21z'><animateTransform  attributeType='xml' attributeName='transform' type='scale' values='1,1; 1,2; 1,1' begin='0.4s' dur='0.8s' repeatCount='indefinite' /></path></svg>&quot;);
                background-position: 0 0;
                display: inline-block;
                width: 40px;
                height: 40px;
                z-index: 100;
            "></div></div>
                `
    error = function (dom, msg) {
        var html = util.StringRender(this.ERR_TPL, { msg: msg })
        dom.html(html);
    }
    loading = function (dom) {
        dom.html(this.LOADING_TPL);
    }

}
const commonRender = new CommonRenderEngine();
export { commonRender }
import numbro from 'numbro'
import * as util from '../../util/util'
import EchartRenderEngine from '../../rengine/EchartRenderEngine';
class LineRender {
    //默认高度
    height = 300;

    render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        var render = new EchartRenderEngine(containerDom, option);
        // render.addClick(chartConfig, relations, $state, $window);
        render.addClick(chartConfig, relations);
        return render.chart(null, persist);
    };

    parseOption = function (data,scope) {

        var chartConfig = data.chartConfig;
        var casted_keys = data.keys;
        var casted_values = data.series;
        var aggregate_data = data.data;
        var newValuesConfig = data.seriesConfig;
        var colFormatter = [];
        var setColFormatter = [];
        //获取指标数组
        var k = 0;  //计数用---cols的数量
        for (var i = 0; i < chartConfig.values.length; i++) {
            for (var j = 0; j < chartConfig.values[i].cols.length; j++) {
                var col = chartConfig.values[i].cols[j];
                colFormatter.push(col);
                //适配原有的图表配置,如果设置了提示框单位转换才需要转换单位,否则就用原先默认的
                if(col && (col.unit || col.symbol ||col.dividend || col.mantissa)){
                    setColFormatter[k] = true;
                } else {
                    setColFormatter[k] = false;
                }
                k++;
            }
        }
        //将colFormatter的长度重复调整为data.data的长度, 以适配列维
        var dataLength = aggregate_data.length;
        for(var i = k; i< dataLength; i++) {
            colFormatter[i] = colFormatter[i % k];
            setColFormatter[i] = setColFormatter[i % k];
        }

        var series_data = [];
        var string_keys = _.map(casted_keys, function (key) {
            return key.join('-');
        });

        var tunningOpt = chartConfig.option;
        var zipDataWithCfg = _.chain(aggregate_data)
            .map(function (data, i) {
                var joined_values = casted_values[i].join('-');
                var s = newValuesConfig[joined_values];
                s.key = joined_values;
                s.data = data;
                return s;
            }).value()

        var hasInfinity = false;  //记录是否出现无穷值
        var hasInfinityArr = []; //用于判断是否存在无穷大或无穷小的详细情况, 并记录最大最小值
        var sum_data = _.chain(zipDataWithCfg)
            .groupBy(function (item) {
                return item.valueAxisIndex;
            })
            .map(function (axisSeries) {
                var infinityObj = {
                    max: undefined,
                    hasInfinityP: false,
                    min: undefined,
                    hasInfinityN: false
                };
                var sumArr = [];
                for (var i = 0; i < axisSeries[0].data.length; i++) {
                    var sumItem = 0;
                    for (var j = 0; j < axisSeries.length; j++) {
                        var cell = axisSeries[j].data[i];
                        sumItem += cell ? Number(cell) : 0;
                        // 判断是否为有限数----字符串为false
                        if( Number.isFinite( Number(axisSeries[j].data[i]) )) {
                            if(infinityObj.max === undefined) {
                                infinityObj.max = axisSeries[j].data[i];
                                infinityObj.min = axisSeries[j].data[i];
                                continue;
                            }
                            Number(axisSeries[j].data[i]) > infinityObj.max ? infinityObj.max = axisSeries[j].data[i] : null;
                            Number(axisSeries[j].data[i]) < infinityObj.min ? infinityObj.min = axisSeries[j].data[i] : null;
                        } else {
                            if(axisSeries[j].data[i] === Infinity){
                                infinityObj.hasInfinityP = true;
                                hasInfinity = true;
                            }
                            if(axisSeries[j].data[i] === -Infinity){
                                infinityObj.hasInfinityN = true;
                                hasInfinity = true;
                            }
                        }
                    }
                    sumArr.push(sumItem)
                }
                hasInfinityArr.push(infinityObj);
                return sumArr;
            })
            .value();

        for (var j = 0; aggregate_data[0] && j < aggregate_data[0].length; j++) {
            for (var i = 0; i < aggregate_data.length; i++) {
                //修复折线图数据为0 不显示bug，这里0、空字符符合==0
                if (aggregate_data[i][j] == 0 ) {
                    aggregate_data[i][j] = aggregate_data[i][j];
                }else{
                    //null会被传递为undefined，改成空字符避免传递undefined
                    aggregate_data[i][j] = aggregate_data[i][j] ? Number(aggregate_data[i][j]) : '';
                }
            }
        }
        for (var i = 0; i < zipDataWithCfg.length; i++) {
            var s = zipDataWithCfg[i];
            s.name = s.key;
            var sumData = sum_data[s.valueAxisIndex];
            if (s.type.indexOf('percent') > -1) {
                if (chartConfig.valueAxis === 'horizontal') {
                    s.data = _.map(s.data, function (e, i) {
                        return (e / sumData[i] * 100).toFixed(2);
                    })
                } else {
                    s.data = _.map(s.data, function (e, i) {
                        return [i, (e / sumData[i] * 100).toFixed(2), e];
                    });
                }
            }
            //设置图表数值标签
            var label = chartConfig.values[s.valueAxisIndex].label ? util.clone(chartConfig.values[s.valueAxisIndex].label) : {};
            label.show ? null : label.show = false;
            label.rotate ? null : label.rotate = 0;
            label.fontSize ? null : label.fontSize = 12;
            label.position ? null : label.position = "top";
            label.color ? null : label.color = "#000";

            // 容器小于1200时适当缩小字体大小
            if( data.containerWidth && data.containerWidth < 1200) {
                label.fontSize = Math.ceil(label.fontSize * (data.containerWidth / 1200 + 1) / 2);
            }

            s.label = label;
            s.label.formatter = function (value) {
                let data;
                // 数组的情况----百分比类型图形
                if(value.data instanceof Array) {
                    data = value.data[2];
                // 非数组的对象----存在itemStyle等设置，取value属性
                } else if(value.data instanceof Object) {
                    if(value.data.value instanceof Array) {
                        data = value.data.value[2];
                    } else {
                        data = value.data.value;
                    }
                // 其它单个数据
                } else {
                    data = value.data;
                }
                if(!colFormatter[value.seriesIndex]){
                    //空字符串时防止报错
                    if(data || data === 0){
                        return numbro(data).format({
                            thousandSeparated: true,
                        });
                    } else {
                        return data;
                    }
                }
                return util.convertEchartUnit(colFormatter[value.seriesIndex], data);
            }

            s.coordinateSystem = chartConfig.coordinateSystem;
            if (s.type == 'stackbar') {
                s.type = 'bar';
                s.stack = s.valueAxisIndex.toString();
            } else if (s.type == 'percentbar') {
                s.type = 'bar';
                s.stack = s.valueAxisIndex.toString();
            } else if (s.type == "arealine") {
                s.type = "line";
                s.areaStyle = { normal: {} };
            } else if (s.type == "stackline") {
                s.type = "line";
                s.stack = s.valueAxisIndex.toString();
                s.areaStyle = { normal: {} };
            } else if (s.type == 'percentline') {
                s.type = "line";
                s.stack = s.valueAxisIndex.toString();
                s.areaStyle = { normal: {} };
            }
            if (chartConfig.valueAxis == 'horizontal') {
                s.xAxisIndex = s.valueAxisIndex;
            } else {
                s.yAxisIndex = s.valueAxisIndex;
            }
            series_data.push(s);
        }

        var valueAxis = util.clone(chartConfig.values);

        /**
         * 遍历所有系列----对数据值继续处理
         * 1.负值的处理
         * 2.添加值的判断----单独设置图形的样式
         *
         * */
        //双轴时，负值导致y轴0点不在一起的问题
        var hasDoValueAxis = false;     //默认不需要处理，即无负值
        var hasjudgeDoVA = false;       //是否需要判断负值的存在
        if(valueAxis.length == 2 && series_data.length >= 2) {
            //双y轴均未设置最大最小值时，才可能需要处理y轴
            if( !valueAxis[0].max && !valueAxis[0].min && !valueAxis[1].max && !valueAxis[1].min ) {
                // //判断是否存在负值
                // for(var i = 0; i < series_data.length; i++) {
                //     for(var j = 0; j < series_data[i].data.length; j++) {
                //         if(series_data[i].data[j] < 0) {
                //             hasDoValueAxis = true;
                //             break;
                //         }
                //     }
                //     if(hasDoValueAxis){
                //         break;
                //     }
                // }
                hasjudgeDoVA = true;
            }
        }
        // 开始遍历----后续不在对数据进行处理--这里之后的数据会出现对象的情况
        for(var i = 0; i < series_data.length; i++) {
            var h= colFormatter[i].highlightArr;    // 设置图形的颜色----s的数量即图表的series的数量----与colformatter的排列相同
            for(var j = 0; j < series_data[i].data.length; j++) {
                // 是否需要判断负值--且负值是否已出现的判断
                if(hasjudgeDoVA && !hasDoValueAxis ) {
                    //判断是否存在负值
                    series_data[i].data[j] < 0 ? hasDoValueAxis = true : null;
                }
                if(!!h && h.length > 0) {
                    var v = series_data[i].data[j] instanceof Array ? series_data[i].data[j][2] : series_data[i].data[j];
                    var color = util.getColColor(h, v);
                    if(color){
                        series_data[i].data[j] = {
                            value: series_data[i].data[j],
                            itemStyle: {
                                color: color
                            }
                        };
                    }
                }
            }
        }

        //修改数值轴(y轴)样式
        _.each(valueAxis, function (axis, index) {

            // 刻度标签
            axis.axisLabel ? null : axis.axisLabel = {};
            axis.axisLabel.formatter = function (value,findex) {
                if(!tunningOpt.valAxisFormatter || !tunningOpt.valAxisFormatter[index]){
                    return numbro(value).format({
                        thousandSeparated: true,
                    });
                }
                var yfArray =  tunningOpt.valAxisFormatter[index];
                return util.convertEchartUnit(yfArray,value);
            };
            // axis.axisLabel = {
            //     formatter: function (value,findex) {
            //         if(!tunningOpt.valAxisFormatter || !tunningOpt.valAxisFormatter[index]){
            //             return numbro(value).format({
            //                 thousandSeparated: true,
            //             });
            //         }
            //         var yfArray =  tunningOpt.valAxisFormatter[index];
            //         return util.convertEchartUnit(yfArray,value);
            //     }
            // };
            // 坐标轴指示器
            axis.axisPointer = {
                label : {
                    formatter: function(param){
                        var value = numbro(param.value).format({
                                    thousandSeparated: false,
                                    mantissa: 2
                                });
                        if(!tunningOpt.valAxisFormatter || !tunningOpt.valAxisFormatter[index]){
                            return numbro(value).format({
                                thousandSeparated: true,
                            });
                        }
                        var yfArray =  tunningOpt.valAxisFormatter[index];
                        return util.convertEchartUnit(yfArray,value);
                    }
                }
            }
            // 两个值比较绝对值，返回较大值的1.2倍----
            function getMaxValue(value1, value2, hasPositive) {
                var absMax = Math.abs(value1) > Math.abs(value2) ? Math.abs(value1) : Math.abs(value2);
                if(hasPositive) {
                    if(absMax * 1.2 < 2) {
                        return (absMax * 1.2).toFixed(2);
                    } else {
                        return Math.floor(absMax * 1.2);
                    }
                } else {
                    if(absMax * 1.2 < 2) {
                        return (-absMax * 1.2).toFixed(2);
                    } else {
                        return Math.ceil(-absMax * 1.2);
                    }
                }
            }
            //设置最大最小刻度
            if (axis.series_type == "percentbar" || axis.series_type == "percentline") {
                axis.min = 0;
                axis.max = 100;
            } else {
                //存在无穷值, 且min和max有一个未设置
                if( ( !axis.max || !axis.min ) && hasInfinity && ( hasInfinityArr[index].hasInfinityP || hasInfinityArr[index].hasInfinityN )) {
                    if(hasDoValueAxis) {
                        axis.max = getMaxValue(hasInfinityArr[index].max, hasInfinityArr[index].min, true);
                        axis.min = getMaxValue(hasInfinityArr[index].max, hasInfinityArr[index].min);
                    } else {
                        axis.max = Math.abs( hasInfinityArr[index].max ) < 2 ? Number(hasInfinityArr[index].max).toFixed(2) : Math.ceil(hasInfinityArr[index].max);
                        axis.min = Math.abs( hasInfinityArr[index].min ) < 2 ? Number(hasInfinityArr[index].min).toFixed(2) : Math.floor(hasInfinityArr[index].min);
                    }
                //双轴未设置最大最小且存在负值
                } else if(hasDoValueAxis) {
                // if(hasDoValueAxis) {
                    axis.max = value => {
                        return getMaxValue(value.max, value.min, true);
                        // var absMax = Math.abs(value.max) > Math.abs(value.min) ? Math.abs(value.max) : Math.abs(value.min);
                        // if(absMax * 1.2 < 2) {
                        //     return (absMax * 1.2).toFixed(2);
                        // } else {
                        //     return Math.floor(absMax * 1.2);
                        // }
                    };
                    axis.min = value => {
                        return getMaxValue(value.max, value.min);
                        // var absMax = Math.abs(value.max) > Math.abs(value.min) ? Math.abs(value.max) : Math.abs(value.min);
                        // if(absMax * 1.2 < 2) {
                        //     return (-absMax * 1.2).toFixed(2);
                        // } else {
                        //     return Math.ceil(-absMax * 1.2);
                        // }
                    };
                // 其它情况
                } else {
                    axis.min = axis.min || null;
                    axis.max = axis.max || null;
                }
            }
            // if (index > 0) {
            //     axis.splitLine = false;
            // }
            //设置最大最小间隔
            axis.scale = true;
            axis.minInterval = axis.minInterval ? axis.minInterval : null;
            axis.maxInterval = axis.maxInterval ? axis.maxInterval : null;

            // 分隔线
            axis.splitLine = axis.splitLine ? axis.splitLine : {show:true};

            // 轴线
            axis.axisLine ? null : null;

            // 容器小于1200时适当缩小字体大小
            if( data.containerWidth && data.containerWidth < 1200) {
                let fontSizeRate = ( data.containerWidth / 1200 + 1 ) / 2;
                // 轴名称
                if ( axis.name ) {
                    if( axis.nameTextStyle ) {
                        axis.nameTextStyle.fontSize = axis.nameTextStyle.fontSize ? Math.ceil( axis.nameTextStyle.fontSize * fontSizeRate ) : Math.ceil( 12 * fontSizeRate );
                    } else {
                        axis.nameTextStyle = {
                            fontSize : Math.ceil( 12 * fontSizeRate )
                        };
                    }
                }
                // 刻度标签
                if( axis.axisLabel.textStyle ) {
                    axis.axisLabel.textStyle.fontSize = axis.axisLabel.textStyle.fontSize ? Math.ceil( axis.axisLabel.textStyle.fontSize * fontSizeRate ) : Math.ceil( 12 * fontSizeRate );
                } else {
                    axis.axisLabel.textStyle = {
                        fontSize : Math.ceil( 12 * fontSizeRate )
                    };
                }
            }

        })

        // 类目轴部分
        var categoryAxis = {
            type: 'category',
            // position: 'auto',
            data: string_keys,
            inverse: false,
            boundaryGap: false,
            nameTextStyle: {
                fontSize: 12
            },
            axisLine: {
                lineStyle: {}
            },
            splitLine: {
                show: true,
                lineStyle: {}
            },
            axisLabel: {
                interval: 'auto',
                rotate: 0,
                textStyle: {
                    fontSize: 12
                }
            },
        };
        if( tunningOpt ) {
            // 反向坐标轴
            tunningOpt.categoryInverse ? categoryAxis.inverse = tunningOpt.categoryInverse : null;
            // // 位置
            // tunningOpt.position ? categoryAxis.position = tunningOpt.position : null;
            // 轴名称
            tunningOpt.categoryAxisName         ? categoryAxis.name                   = tunningOpt.categoryAxisName         : null;
            tunningOpt.categoryAxisNameLocation ? categoryAxis.nameLocation           = tunningOpt.categoryAxisNameLocation : null;
            tunningOpt.categoryAxisNameGap      ? categoryAxis.nameGap                = tunningOpt.categoryAxisNameGap      : null;
            tunningOpt.categoryAxisNameRotate   ? categoryAxis.nameRotate             = tunningOpt.categoryAxisNameRotate   : null;
            tunningOpt.categoryAxisNameFontSize ? categoryAxis.nameTextStyle.fontSize = tunningOpt.categoryAxisNameFontSize : null;
            tunningOpt.categoryAxisNameColor    ? categoryAxis.nameTextStyle.color    = tunningOpt.categoryAxisNameColor    : null;

            // 轴线
            tunningOpt.categoryAxisLine ? categoryAxis.axisLine  = tunningOpt.categoryAxisLine : null;
            // 分隔线
            tunningOpt.splitLine        ? categoryAxis.splitLine = tunningOpt.splitLine        : null;

            // 标签
            tunningOpt.categoryAxisLabelMargin || tunningOpt.categoryAxisLabelMargin === 0 ? categoryAxis.axisLabel.margin = tunningOpt.categoryAxisLabelMargin : null;
            tunningOpt.ctgLabelRotate            ? categoryAxis.axisLabel.rotate             = tunningOpt.ctgLabelRotate            : null;
            tunningOpt.categoryAxisLabelFontSize ? categoryAxis.axisLabel.textStyle.fontSize = tunningOpt.categoryAxisLabelFontSize : null;
            tunningOpt.categoryAxisLabelColor    ? categoryAxis.axisLabel.textStyle.color    = tunningOpt.categoryAxisLabelColor    : null;

            tunningOpt.ctgLabelInterval === undefined ? null : categoryAxis.axisLabel.interval = tunningOpt.ctgLabelInterval;

            // 容器小于1200时适当缩小字体大小
            if( data.containerWidth && data.containerWidth < 1200) {
                let fontSizeRate                          = ( data.containerWidth / 1200 + 1 ) / 2;
                categoryAxis.nameTextStyle.fontSize       = Math.ceil( categoryAxis.nameTextStyle.fontSize * fontSizeRate );
                categoryAxis.axisLabel.textStyle.fontSize = Math.ceil( categoryAxis.axisLabel.textStyle.fontSize * fontSizeRate );
            }
        }

        // if (tunningOpt) {
        //     var labelInterval, labelRotate,splitLine;
        //     tunningOpt.ctgLabelInterval ? labelInterval = tunningOpt.ctgLabelInterval : 'auto';
        //     tunningOpt.ctgLabelRotate ? labelRotate = tunningOpt.ctgLabelRotate : 0;
        //     tunningOpt.splitLine?splitLine = tunningOpt.splitLine:({show:true})
        // }

        // var categoryAxis = {
        //     type: 'category',
        //     data: string_keys,
        //     axisLabel: {
        //         interval: labelInterval,
        //         rotate: labelRotate
        //     },
        //     splitLine:splitLine,
        //     boundaryGap: false,
        // };

        // 类目轴图形两侧留白策略----中心是否与y轴重合
        _.each(valueAxis, function (axis) {
            var _stype = axis.series_type;
            if (_stype.indexOf('bar') !== -1) {
                categoryAxis.boundaryGap = true;
            }
        });

        var echartOption = {
            grid: {
                left: '50',
                right: '20',
                bottom: '15%',
                top: '15%',
                containLabel: false
            }, //util.clone(echartsBasicOption.grid),
            legend: {
                data: _.map(casted_values, function (v) {
                    return v.join('-');
                })
            },
            tooltip: {
                formatter: function (params) {
                    var name = params[0].name;
                    var s = name + "</br>";
                    // var paramFromatter =colFormatter;
                    // if(params.length!=colFormatter.length){
                    //     var seriesArray = _.map(params,function(e){return e.seriesName})
                    //     paramFromatter=_.filter(colFormatter,function(v){
                    //         if(seriesArray.indexOf(v.col) >-1){
                    //             return v;
                    //         }
                    //     })
                    // }
                    var sIndex = 0;
                    for (var i = 0; i <params.length; i++) {
                        s += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + params[i].color + '"></span>';
                        sIndex = params[i].seriesIndex;
                        if (params[i].value instanceof Array) {
                            if(colFormatter[sIndex]){
                                var numValue1 = params[i].value[1];
                                var numValue2 = util.convertEchartUnit(colFormatter[sIndex],params[i].value[2]);
                                s += params[i].seriesName + " : " + (numValue1 ? numValue1 : '') + "% (" + (numValue2 ? numValue2 : '') + ")<br>";
                            }else{
                                if (chartConfig.option.numStyle === true) {
                                    s += params[i].seriesName + " : " + (params[i].value[1] ? numbro(params[i].value[1]).format('0,0.00') : '') + "% (" + (params[i].value[2] ? numbro(params[i].value[2]).format('0,0.00') : '') + ")<br>";
                                } else {
                                    s += params[i].seriesName + " : " + (params[i].value[1] ? params[i].value[1] : '') + "% (" + (params[i].value[2] ? params[i].value[2] : '') + ")<br>";
                                }
                            }
                        } else {
                            if(colFormatter[sIndex]){
                                var numValue = util.convertEchartUnit(colFormatter[sIndex],params[i].value);
                                s += (params[i].seriesName + " : " + numValue + "<br>");
                            }else {
                                if (chartConfig.option.numStyle === true) {
                                    if (params[i].value == 0) {
                                        s += params[i].seriesName + " : " + numbro(params[i].value).format('0,0.00')  + "<br>";
                                    }else{
                                        s += params[i].seriesName + " : " + (params[i].value ? numbro(params[i].value).format('0,0.00') : '') + "<br>";
                                    }
                                } else {
                                    if (params[i].value == 0) {
                                        s += params[i].seriesName + " : " + params[i].value + "<br>";
                                    }else{
                                        s += params[i].seriesName + " : " + (params[i].value ? params[i].value : '') + "<br>";
                                    }
                                }
                            }
                        }
                    }
                    return s;
                },
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                }
            },
            series: series_data,
        };


        if (chartConfig.coordinateSystem == 'polar') {
            echartOption.angleAxis = chartConfig.valueAxis == 'horizontal' ? valueAxis : categoryAxis;
            echartOption.radiusAxis = chartConfig.valueAxis == 'horizontal' ? categoryAxis : valueAxis;
            echartOption.polar = {};
        } else {
            echartOption.xAxis = chartConfig.valueAxis == 'horizontal' ? valueAxis : categoryAxis;
            echartOption.yAxis = chartConfig.valueAxis == 'horizontal' ? categoryAxis : valueAxis;
        }

        if (chartConfig.valueAxis === 'horizontal') {
            echartOption.grid.left = 'left';
            echartOption.grid.containLabel = true;
            echartOption.grid.bottom = '5%';
        }

        if (chartConfig.valueAxis === 'vertical' && chartConfig.values.length > 1) {
            echartOption.grid.right = 40;
        }

         //添加水平线
        if (tunningOpt.isMarkline !== undefined && tunningOpt.isMarkline === true) {
            if (tunningOpt.yAxis !== undefined && tunningOpt.yAxis !== null) {
                if (!tunningOpt.lineColor) {
                    var markLine = {
                        data : [{
                            yAxis: tunningOpt.yAxis
                        }]
                    }
                }else{
                    var markLine = {
                        data : [{
                            yAxis: tunningOpt.yAxis
                        }],
                        lineStyle:{
                            normal:{
                                color: tunningOpt.lineColor
                            }
                        }
                    }
                }

                echartOption.series[0]['markLine'] = markLine;
            }
        }

        util.updateEchartOptions(tunningOpt, echartOption);
        util.updateEchartsTooltipPosition(echartOption);
        // 水平放置的一直包含标签
        if (chartConfig.valueAxis === 'horizontal') {
            echartOption.grid.containLabel = true;
        }
        // 处理数据域----水平数据域
        var dataZoom1 = null, dataZoom2 = null, dataZoom3 = null, dataZoom4 = null;
        if(tunningOpt.dataZoom1){
            var dzOpt1 = tunningOpt.dataZoom1;
            if(tunningOpt.dataZoom) {
                dataZoom1 = {
                    show: true,
                    type: 'slider',
                    orient: 'horizontal',
                    brushSelect: false
                };
                dzOpt1.top || dzOpt1.top === 0 ? dataZoom1.top = dzOpt1.top : null;
                if(dzOpt1.textStyle) {
                    dataZoom1.textStyle = {
                        color: dzOpt1.textStyle.color ? dzOpt1.textStyle.color : '#333',
                        fontSize: dzOpt1.textStyle.fontSize ? dzOpt1.textStyle.fontSize : 12
                    };
                }
                dataZoom1 = getDataZoom(dataZoom1, dzOpt1);
                if(chartConfig.coordinateSystem == 'polar') {
                    dataZoom1.radiusAxisIndex = 0;
                }
            }
            if(dzOpt1.hasInside) {
                dataZoom2 = {
                    type: 'inside',
                    orient: 'horizontal'
                };
                dataZoom2 = getDataZoom(dataZoom2, dzOpt1);
                dataZoom2 = getDataZoomMouse(dataZoom2, dzOpt1);
                if(chartConfig.coordinateSystem == 'polar') {
                    dataZoom2.radiusAxisIndex = 0;
                }
            }
        }
        // 处理数据域----竖直数据域
        if(tunningOpt.dataZoom2){
            var dzOpt2 = tunningOpt.dataZoom2;
            if(dzOpt2.hasSlider) {
                dataZoom3 = {
                    show: true,
                    type: 'slider',
                    orient: 'vertical',
                    brushSelect: false
                };
                dzOpt2.left || dzOpt2.left === 0 ? dataZoom3.left = dzOpt2.left : null;
                if(dzOpt2.textStyle) {
                    dataZoom3.textStyle = {
                        color: dzOpt2.textStyle.color ? dzOpt2.textStyle.color : '#333',
                        fontSize: dzOpt2.textStyle.fontSize ? dzOpt2.textStyle.fontSize : 12
                    };
                }
                dataZoom3 = getDataZoom(dataZoom3, dzOpt2);
                if(chartConfig.coordinateSystem == 'polar') {
                    dataZoom3.angleAxisIndex = 0;
                }
            }
            if(dzOpt2.hasInside) {
                dataZoom4 = {
                    type: 'inside',
                    orient: 'vertical'
                };
                dataZoom4 = getDataZoom(dataZoom4, dzOpt2);
                dataZoom4 = getDataZoomMouse(dataZoom4, dzOpt2);
                if(chartConfig.coordinateSystem == 'polar') {
                    dataZoom4.angleAxisIndex = 0;
                }
            }
        }
        // 合并opt
        if(dataZoom1 || dataZoom2 || dataZoom3 || dataZoom4) {
            echartOption.dataZoom = [];
            dataZoom1 ? echartOption.dataZoom.push(dataZoom1) : null;
            dataZoom2 ? echartOption.dataZoom.push(dataZoom2) : null;
            dataZoom3 ? echartOption.dataZoom.push(dataZoom3) : null;
            dataZoom4 ? echartOption.dataZoom.push(dataZoom4) : null;
        }

        return echartOption;

        // 数据域的方法1
        function getDataZoom(dataZoom, opt) {
            dataZoom.start = opt.start ? Number(opt.start) : 0;
            dataZoom.end   = opt.end && !(Number(opt.end) < dataZoom.start) ? Number(opt.end) : 100;
            return dataZoom;
        }
        // 数据域的方法2-----滚轮操作----inside类型
        function getDataZoomMouse(dataZoom, opt) {
            if(opt.zoomOnMouseWheel) {
                switch(opt.zoomOnMouseWheel) {
                    case 'false' : dataZoom.zoomOnMouseWheel = false; break;
                    case 'true'  : dataZoom.zoomOnMouseWheel = true;  break;
                    default      : dataZoom.zoomOnMouseWheel = opt.zoomOnMouseWheel;
                }
            }
            if(opt.moveOnMouseWheel) {
                switch(opt.moveOnMouseWheel) {
                    case 'false' : dataZoom.moveOnMouseWheel = false; break;
                    case 'true'  : dataZoom.moveOnMouseWheel = true;  break;
                    default      : dataZoom.moveOnMouseWheel = opt.moveOnMouseWheel;
                }
            }
            return dataZoom;
        }
    };
}
export { LineRender }

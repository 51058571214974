/** @ngInject */
import PubSub from "../../../../common/utils/PubSub"
export default function hasPerms(hasPermsService) {
  return {
    restrict:'EA',
    link: function (scope, element, attrs) {
      if (!angular.isString(attrs.optPerms))
        throw "hasPerms value must be a string";
      var value = attrs.optPerms.trim();
      var notPermissionFlag = value[0] === '!';
      var isShow = attrs.isShow;
      var addClass = attrs.addClass;  //用于移动端有权限时添加样式
      if (notPermissionFlag) {
        value = value.slice(1).trim();
      }

      function toggleVisibilityBasedOnPermission() {
        var hasPermission = hasPermsService.hasPermission(value);
        if (hasPermission && !notPermissionFlag || !hasPermission && notPermissionFlag) {
            element.show();
            element.children().css({"pointer-events":"all","cursor":"pointer"});
            element.css({"cursor":"pointer"});
            if(!!addClass){
                //移动端将增加类名
                element.children().addClass(addClass);
            }
        } else {
            if (isShow=="true"){
                // 禁用子元素事件 并修改样式
                element.children().css({"pointer-events":"none","cursor":"url('/assets/imgs/icon_lock.ico'),not-allowed"});
                element.css({"cursor":"url('/assets/imgs/icon_lock.ico'),not-allowed"});
                // scope.permTitle="权限不足！";
            }else if(!!addClass){
                //移动端有则去掉
                if(element.children().hasClass(addClass)){
                    element.children().removeClass(addClass);
                }
            }else{
                element.hide();
            }
        }
      }

      toggleVisibilityBasedOnPermission();
      scope.$on('permissionsChanged', function() {
        toggleVisibilityBasedOnPermission();
      });
    }
  };
}

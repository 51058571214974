import BasicService from './BasicService';
import { WIDGETAPI, DATASETAPI, DTPMANAGERAPI, BOARDAPI, DATAAPI } from './ApiServer';
import { retrieve } from '../charts/util/util';
/**
 */
class DatasetService extends BasicService {

    getDatasetDetail (params) {
        return this.post( BOARDAPI.GETDATASETDETAIL, params);
    }

    getDatasetDetailNoDs(params) {
        return this.post( DATAAPI.DATASETDETAILNODS, params);
    }

    getDtpList () {
        return this.get( DTPMANAGERAPI.GETDTPLIST );
    }

    getDataSourceList (params) {
        return this.post( DTPMANAGERAPI.GETDATASOURCELIST, params);
    }

    getDataSourceListForDashboard () {
        return this.get( BOARDAPI.GETDATASOURCELIST)
    }

    getDatasetList (params) {
        return this.post( DATAAPI.DATASETLIST, params);
    }
    getDatasetPageList (params) {
        return this.post( DATAAPI.DATASETPAGELIST, params);
    }

    getDatasetCategoryList () {
        return this.get(BOARDAPI.GETDATASETCATEGORYLIST)
    }

    checkDatasource (params) {
        return this.post(BOARDAPI.CHECKDATASOURCE, params)
    }

    getAllWidgetList () {
        return this.get(BOARDAPI.GETALLWIDGETLIST)
    }

    deleteDataset (params) {
        return this.post(BOARDAPI.DELETEDATASET, params)
    }

    saveNewDataset (params) {
        return this.post( BOARDAPI.SAVENEWDATASET, params)
    }

    updateDataset (params) {
        return this.post(BOARDAPI.UPDATEDATASET, params)
    }

    getConfigParams (params) {
        return this.get(WIDGETAPI.GETCONFIGPARAMS, params)
    }

    getCompleteDataSql (params) {
        return this.post(DATAAPI.GETCOMPLETEDATASQL, params)
    }
}

export const datasetService = new DatasetService();

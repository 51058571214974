import BasicService from './BasicService';
import { DATAAPI } from './ApiServer';
import {cacheService} from './CacheService'
/**
 */

class DataService extends BasicService {
  constructor() {
    super();
  }
  getDataSetList() {
    return this.get(DATAAPI.DATASETLIST);
  }
  // getDataSetList(ids) {
  //   return this.get(DATAAPI.DATASETLISTBYIDS,{ids:ids});
  // }
  getDimensionValues(params, cache) {
      return cacheService.post(DATAAPI.DIMENSIONVALUES, params, cache);
  }
  getAggregateData(params, cache) {
    if(params&&params.isPage){
      return cacheService.post(DATAAPI.GETPAGEAGGREGATEDATA, params, cache);
    }
    return cacheService.post(DATAAPI.AGGREGATEDATA, params, cache);
  }

  //传java的时间日期（now）表达式--字符串-----返回解析后的字符串---DATAAPI.PARSEREXPRESSION
  getTimeParserExpression(params) {
    // return cacheService.post(DATAAPI.PARSEREXPRESSION, {"expression" : params}, {type:'parserExpression', isCache: true, expireTime: 20} );
    return cacheService.post_window(DATAAPI.PARSEREXPRESSION, {"expression" : params}, {type:'parserExpression', expireTime: 20000} );
  }

  getViewAggDataQuery(params) {
    return this.post(DATAAPI.AGGDATAQUERY, params);
  }
  getColumns(params) {
    return this.post(DATAAPI.COLUMNS, params);
  }
  previewDatasetData(params) {
    return this.post(DATAAPI.PREVIEWDATASETDATA, params);
  }
  getColumnsByIds(params) {
    return this.post(DATAAPI.GETCOLUMNSBYIDS, params);
  }

  //所有的获取数据集都缓存20s
  getDatasetDetailNoDs(params) {
      return cacheService.post(DATAAPI.DATASETDETAILNODS, params, {type:'DatasetDetailNoDs', isCache: true, expireTime: 20} );
      //return this.post(DATAAPI.DATASETDETAILNODS, params);
  }

}

export const dataService = new DataService();

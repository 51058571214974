
  /** @ngInject */
  export default   function preloader($q) {
    return {
      loadImg: function (src) {
        var d = $q.defer();
        var img = new Image();
        img.src = src;
        img.onload = function(){
          d.resolve();
        };
        return d.promise;
      },
      loadAmCharts : function(){
        var d = $q.defer();
        // AmCharts.ready(function(){
        //   d.resolve();
        // });
        d.resolve();
        return d.promise;
      }
    }
  }

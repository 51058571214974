import EchartRenderEngine from "../../rengine/EchartRenderEngine";
class BudgetRender {
    //默认高度
    height = 300;
    
    render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        var render = new  EchartRenderEngine(containerDom, option);
        return render.chart(null, persist);
    };
    parseOption = function (data) {
        var vdata = [{
            name: '一、主营业务收入',
            code: 'zysr',
            value: 0
        }, {
            name: '直接材料',
            code: 'zjcl',
            value: 0
        }, {
            name: '直接人工',
            code: 'zjrg',
            value: 0
        }, {
            name: '制造费用',
            code: 'zzfy',
            value: 0
        }, {
            name: '减：营业费用',
            code: 'yyfy',
            value: 0
        }, {
            name: '管理费用',
            code: 'glfy',
            value: 0
        }, {
            name: '财务费用',
            code: 'cwfy',
            value: 0
        }, {
            name: '加：其他业务利润',
            code: 'qtywlr',
            value: 0
        }, {
            name: '加：投资收益',
            code: 'tzsy',
            value: 0
        }, {
            name: '补贴收入',
            code: 'btsr',
            value: 0
        }, {
            name: '营业外收入',
            code: 'yywsr',
            value: 0
        }, {
            name: '减：营业外支出',
            code: 'yywzc',
            value: 0
        }, {
            name: '减：所得税',
            code: 'sds',
            value: 0
        }, {
            name: '主营业务成本',
            code: 'zyywcb',
            value: 0
        }
            , {
            name: '二、主营业务利润',
            code: 'zyywlr',
            value: 0
        }
            , {
            name: '总费用',
            code: 'zfy',
            value: 0
        }, {
            name: '营业利润',
            code: 'yylr',
            value: 0
        }, {
            name: '利润总额',
            code: 'lrze',
            value: 0
        }, {
            name: '净利润',
            code: 'jlr',
            value: 0
        }
        ];
        for (var i in vdata) {
            vdata[i].newvalue = vdata[i].value;
            vdata[i].percent = 0;
        }
        var isinit = false;
        // console.log(JSON.stringify(data))
        for (var i in data.series) {
            for (var j in vdata) {
                if (vdata[j].code == String(data.series[i]).toLowerCase()) {
                    vdata[j].value = data.data[i]
                    isinit = true;
                    continue;
                }
            }
        }
        //   console.log('AAAAA'.toLowerCase() )
        if (!isinit) {
            alert('数据格式错误！！，请调整数据格式');
        }

        var option = {
            // toolbox: {
            //     show: true,
            //     feature: {
            //         dataView: { readOnly: false },
            //         restore: {},
            //         saveAsImage: {}
            //     }
            // },
            series: [
                {
                    // name: '访问来源',
                    type: 'budget',
                    data: vdata,
                }
            ],
        };
        // console.log(JSON.stringify(option))
        return option;
    };
}

export  {BudgetRender}
import  widgetsTpl from './widgets.html'
  
  /** @ngInject */
  function widgets() {
    return {
      restrict: 'EA',
      scope: {
        ngModel: '='
      },
      template:widgetsTpl,
      replace: true
    };
  }


  export default angular.module('BlurAdmin.theme.components.widgets',[])
  .directive('widgets', widgets)
  .directive('pwWidget', function () {
    return {
      template: require('../../../../pcbore/pages/prewarning/widgetPwList.html'),
      scope: false
    }
  })
  .name;

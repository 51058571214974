import icon_pic from './funnel.png'
import icon_pic_active from './funnel-active.png'
var config = {
    rule: {
        keys: -1,
        groups: 0,
        filters: -1,
        values: 2
    },
    info:{
        name: 'CONFIG.WIDGET.FUNNEL',
        value: 'funnel',
        // class: 'cFunnel',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_1_MORE'
    }
}
export default config;
import messageTpl from './message.html'
import messageCtrl  from './messageCtrl'
import messageDetailTpl from './messageDetail/messageDetail.html'
import messageDetailCrl  from './messageDetail/messageDetailCtrl'
  
export default angular.module('borepc.pages.message', [])
.controller('messageCtrl', ['toastr', '$scope',  '$state',messageCtrl])
.controller('messageDetailCrl', ['$rootScope', '$stateParams','$sce','$scope',  '$http','$state',messageDetailCrl])
.config(['$stateProvider',routeConfig]).name ;
/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('message', {
      url: '/message',
      title: '消息列表',
      template: messageTpl,
      controller: 'messageCtrl',
    })
    .state('messageDetail', {
      url: '/messageDetail?id',
      param:{id:null},
      title: '消息详情',
      template: messageDetailTpl,
      controller: 'messageDetailCrl',
    });
}


/**
 * Created by  jaray  2018-11-28
 */
import './_CrossTable.scss'
import  * as util from '../../util/util'
import numbro from 'numbro'
import {userRoleAdminService,behaviorLogService } from "../../../server";
var crossTable = {
    table: function (args) {
        var data = args.data,
            chartConfig = args.chartConfig,
            tall = args.tall,
            pageDataNum,
            drill = args.drill,
            random = Math.random().toString(36).substring(2),
            container = args.container,
            chartName = args.name ? args.name : "table";
        var tableId = 'tableWrapper'+random;
        var html = "<table class = 'table_wrapper' id='" + tableId + "'><thead class='fixedHeader'>",
            colContent = "<tr>";

        //group列维
        for (var i = 0; i < chartConfig.groups.length; i++) {
            var groupId = chartConfig.groups[i].id;
            var rowHeaderSortg = true;
            var colspan = 1;
            var colList = [];
            _.each(chartConfig.groups, function(g, index) {
                index <= i && g.sort === undefined ? rowHeaderSortg = false : null;
            });
            for (var t = 0; t < chartConfig.keys.length; t++) {
                colContent += "<th class='" + data[i][t].property + "'><div></div></th>";
            }
            for (var y = chartConfig.keys.length; y < data[i].length; y++) {
                if (data[i][y + 1] && (data[i][y].data == data[i][y + 1].data) && rowHeaderSortg) {
                    if (i > 0) {
                        var noEqual = false;
                        for (var s = i - 1; s > -1; s--) {
                            if (data[s][y].data != data[s][y + 1].data) {
                                noEqual = true;
                                break;
                            }
                        }
                        if (noEqual ) {
                            colList.push({
                                data: data[i][y].data,
                                colSpan: colspan,
                                property: data[i][y].property
                            });
                            colspan = 1;
                        }
                        else {
                            colspan++;
                        }
                    }
                    else if (i == 0) {
                        colspan++;
                    }
                }
                else {
                    data[i][y] != data[i][y - 1] ? colList.push({
                        data: data[i][y].data,
                        colSpan: colspan,
                        property: data[i][y].property
                    }) : null;
                    colspan = 1;
                }
            }
            for (var c = 0; c < colList.length; c++) {
                var d = ""
                if (drill && drill.config[groupId] && (drill.config[groupId].down || drill.config[groupId].up)) {
                    d += "class='table_drill_cell'";
                    if (drill.config[groupId].down) {
                        d += " drill-down='" + groupId + "' ";
                    }
                    if (drill.config[groupId].up) {
                        d += " drill-up='" + groupId + "' ";
                    }
                }
                var value = "<div " + d + ">" + colList[c].data + "</div>";
                colContent += colList[c].colSpan > 1 ? "<th colspan=" + colList[c].colSpan +
                    " class='" + colList[c].property + "'>" + value + "</th>" :
                    "<th class='" + colList[c].property + "'>" + value + "</th>";
            }
            colContent += "</tr><tr>";
        }
        for (var k = 0; k < data[chartConfig.groups.length].length; k++) {
            colContent += "<th class='" + data[chartConfig.groups.length][k].property + "'><div>" + data[chartConfig.groups.length][k].data + "</div></th>";
        }
        html += colContent + "</tr></thead><tbody class='scrollContent'>";
        var headerLines = chartConfig.groups.length + 1;
        var colNum = data[0].length;
        var rowNum = colNum ? data.length - headerLines : 0;
        //分页
        if ( !chartConfig.option.initDataNum ) {
            pageDataNum = rowNum >= 20 ? 20 : rowNum;
        }else{
            pageDataNum = rowNum >= chartConfig.option.initDataNum ? chartConfig.option.initDataNum : rowNum;
        }
        data = this.changeDataUnit(chartConfig.values[0].cols,data,tableId);
        //分页
        var dataPage = this.paginationProcessData(data, headerLines, pageDataNum,tableId);
        var datapageLen;
        //处理dataPage[0]没有数据的情况；
        if (dataPage[0]) {
            datapageLen = dataPage[0].length;
        }else{
            datapageLen = 0;
        }

        //小计
        var trDom = this.render(dataPage[0], chartConfig, drill, data,tableId);

        html = html + trDom + "</tbody></table>";

        // var optionDom = "<select><option value='20'>20</option><option value='50'>50</option><option value='100'>100</option><option value='150'>150</option></select>";
        var optionDom = `<input type="number" placeholder="请输入数字" value="${pageDataNum}" style="width:35%;text-align:center;margin:0 5px" />`;
        var p_class = "p_" + random;
        var PaginationDom = "<div class='" + p_class + "'><div class='optionNum'><span>" + '显示' + "</span>" + optionDom + "<span>" +'条'+ "</span> 当前 <span id='curDataNum'>"+ datapageLen +"</span> 条</div><div class='page'><ul></ul></div></div>";
        var operate = "<div class='toolbar toolbar" + random + "' style='margin:unset;box-shadow:unset;background:transparent'><span class='info'><b>总:</b> " + rowNum + "[行] x " + colNum + "[列]</span>" +
            "<span class='exportBnt' title='" + '导出'+ "'></span>"
            +"<span class='exportCsvBnt' title='导出文件'></span>"
            +"</div>";
        $(container).html(""); //重新渲染表格时，清空原来的内容
        $(container).html(operate);
        $(container).append("<div class='tableView table_" + random + "' style='width:99%;max-height:" + (tall ? (tall-82) + "px" : " calc(100% - 82px);") + ";overflow-x:auto'>" + html + "</div>");
        if ( chartConfig.option.tableStyleConfig == true && chartConfig.option.hidePagination == true ) {
            PaginationDom = "<div class='" + p_class + "' style='opacity: 0'><div class='optionNum'><span>" + '显示' + "</span>" + optionDom + "<span>" +'条'+ "</span> 当前 <span id='curDataNum'>"+ datapageLen +"</span> 条</div><div class='page'><ul></ul></div></div>";
            $(container).append(PaginationDom);
        }else {
            $(container).append(PaginationDom);
        }
        $('#'+tableId+' thead th').css({'font-size':chartConfig.option.tableFontSize,'color':chartConfig.option.tableColor});
        $('#'+tableId+' tbody tr td').css({'font-size': chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': chartConfig.option.tableContentColor});
        $('#'+tableId+' tbody tr th').css({'font-size': chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': chartConfig.option.tableContentColor});
        $('#'+tableId+' .table_drill_cell').css({'color': chartConfig.option.tableDrillColor})
        for (var i=0,len=$('#'+tableId+' tbody tr').length; i<len; i++) {
           $($('#'+tableId+' tbody tr')[i]).children().eq(chartConfig.option.warnRow - 1).find('div').css({ 'color': '#f00' });
        }
        if (chartConfig.option.tableStyle === 'styleA') {
            $('#'+tableId+' tbody tr td:last-child').css({ 'color': '#f00' });
        }
        var pageObj = {
            data: dataPage,
            chartConfig: chartConfig,
            drill: drill
        };
        data.length ? this.renderPagination(dataPage.length, 1, pageObj, $('.' + p_class + ' .page>ul')[0],tableId) : null;
        this.clickPageNum(dataPage, chartConfig, drill, p_class, data,tableId);
        this.clickNextPrev(dataPage.length, pageObj, p_class, data,chartConfig,tableId);
        this.selectDataNum(data, chartConfig.groups.length + 1, chartConfig, drill, p_class, "table_" + random, args.render,tableId);
        this.export(random, data,chartConfig,tableId,chartName);
        this.highlightValue(chartConfig.values[0].cols,data,tableId);
        this.setKeyhighlightValue(chartConfig.keys,data,tableId);
        this.carousePage(random, chartConfig);

    },

    //获取指标颜色,highlightArr:指标设置的高亮颜色数组,numContent:比较值
    getColColor:function (highlightArr,numContent){
        var color;
        for (var i = 0;i < highlightArr.length; i++) {
            if(!highlightArr[i] || !highlightArr[i].sign || !highlightArr[i].color || highlightArr[i].value==null)continue;
            var value = highlightArr[i].value.replace("，",",").split(",");//区间
            var numContent = Number(util.clearComma(numContent));//值
            var valueColor = highlightArr[i].color;//颜色
            switch(highlightArr[i].sign){
                case "<":
                    if(numContent<Number(value[0]))color = valueColor;
                    break;
                case ">":
                    if(numContent>Number(value[0]))color = valueColor;
                    break;
                case "=":
                    if(numContent==Number(value[0]))color = valueColor;
                    break;
                case "≠":
                    if(numContent!=Number(value[0]))color = valueColor;
                    break;
                case "≤":
                    if(numContent<=Number(value[0]))color = valueColor;
                    break;
                case "≥":
                    if(numContent>=Number(value[0]))color = valueColor;
                    break;
                case "(a,b)":
                    if(numContent>Number(value[0]) && numContent<Number(value[1]))color = valueColor;
                    break;
                case "[a,b)":
                    if(numContent>=Number(value[0]) && numContent<Number(value[1]))color = valueColor;
                    break;
                case "(a,b]":
                    if(numContent>Number(value[0]) && numContent<=Number(value[1]))color = valueColor;
                    break;
                case "[a,b]":
                    if(numContent>=Number(value[0]) && numContent<=Number(value[1]))color = valueColor;
                    break;
                default:
                    break;
            }
            if(color)break;
        }
        return color;
    },

    //对指标数值满足设定条件者高亮显示
    highlightValue:function(col,data,tableId){
        //对交叉表的行进行循环
        for (var i = 0, len = $('#'+tableId+' tbody tr').length; i < len; i++) {
            //获取每行中数据格式为data的列
            var tdHtml = $($('#'+tableId+' tbody tr')[i]).children("td.data");
            for (var j = 0, lan = tdHtml.length; j < lan; j++) {
                var content = $(tdHtml[j]).text();//获取列的值
                var colNum = (j)%(col.length)
                if (content && col[colNum]) {
                    //去对获取到的数组除单位转换成数字类型
                    var numContent = Number(util.clearComma(content).replace(col[colNum].unit, ""));
                    if(col[colNum].highlightArr && col[colNum].highlightArr.length>0){
                        var colColor = this.getColColor(col[colNum].highlightArr, numContent,tableId);
                        if(colColor){
                            // //直接用.css设置!import无效,先要获取原来样式用cssText拼接
                            // var cssText =  $($('#'+tableId+' tbody tr')[i]).children("td.data").eq(j).attr("style")+";color:"+colColor+"!important"
                            // $($('#'+tableId+' tbody tr')[i]).children("td.data").eq(j).css("cssText",cssText);
                            // 优先级----选项卡 > 警示列
                            tdHtml.eq(j).children().css("color", colColor);
                        }
                    }
                }
            }
        }
    },
    //获取维度颜色,highlightArr:维度设置的高亮颜色数组,strContent:比较值
    getKeyColor:function (highlightArr,strContent){
        var color;
        for (var i = 0;i < highlightArr.length; i++) {
            if(!highlightArr[i] || !highlightArr[i].color || highlightArr[i].value==null)continue;
            var value = highlightArr[i].value.trim();       //去掉两端的空格
            var str = strContent.trim();
            if(value == str){
                color = highlightArr[i].color;
            }
            if(color)break;
        }
        return color;
    },

    //对维度的值满足设定条件者高亮显示
    setKeyhighlightValue:function(col,data,tableId){
        // 获取交叉表tBody的所有行----rowJQDom
        var rowJQDom = $('#'+tableId+' tbody tr');
        //对行进行循环
        for (var i = 0, len = rowJQDom.length; i < len; i++) {
            //获取每行中所有key形成的单元格
            var cellJQDom = $(rowJQDom[i]).children("th.row");
            for (var j = 0, lan = cellJQDom.length; j < lan; j++) {
                var content = $(cellJQDom[j]).text();//获取内部的文本
                var colNum = (j)%(col.length);
                if (content && col[colNum]) {
                    if(col[colNum].highlightArr && col[colNum].highlightArr.length>0){
                        var colColor = this.getKeyColor(col[colNum].highlightArr, content);
                        if(colColor){
                            // //直接用.css设置!import无效,先要获取原来样式用cssText拼接
                            // var cssText =  cellJQDom.eq(j).attr("style")+";color:"+colColor+"!important";
                            // cellJQDom.eq(j).css("cssText",cssText);
                            // 优先级----选项卡 > 警示列 > 下钻
                            var drillDom = cellJQDom.eq(j).find('.table_drill_cell');
                            if(drillDom.length > 0) {
                                drillDom.css('color', colColor);
                            } else {
                                cellJQDom.eq(j).children().css("color", colColor);
                            }
                        }
                    }
                }
            }
        }
    },

    //对指标数据进行单位转换,col:指标列表,data:需要转换数据(包含非data类型数据)
    changeDataUnit:function(col,data){
        var numberData = [];
        //头部第一行数据不需要转换单位,将其单独拿出,其余进入循环
        numberData.push(data[0]);
        for (var i = 1; i < data.length; i++) {
            var temData = data[i];
            var unitData = [];
            var count = 0;
            for (var j = 0,lans=temData.length; j < lans; j++) {
                var res  = temData[j];
                //数据类型为data才需要转换单位，
                //count记录非data类型的数据量,用以计算出data数据在指标数组中对应索引j-count
                if(res.property!='data'){
                    unitData.push(res);
                    count++;
                    continue;
                }

                var colNum = (j-count)%(col.length)
                if(col[colNum] && (col[colNum].symbol || col[colNum].mantissa)){
                    res.data = isNaN(res.data)? "" : util.convertEchartUnit(col[colNum],res.data);
                    res.raw = isNaN(res.raw)? "" : util.convertEchartUnit(col[colNum],res.raw);
                }else{
                    res.data = isNaN(res.data)? "" : numbro(Number(res.data)).format({
                        thousandSeparated: true,
                        mantissa: 6
                    });
                    res.raw = isNaN(res.raw)? "" :numbro(Number(res.raw)).format({
                        thousandSeparated: true,
                        mantissa: 6
                    });
                }
                unitData.push(res);
            };
            numberData.push(unitData);
        }
        return data
    },

    filterData: function (data, filterArr) {
        var {col=[], row=[], value=[]} = filterArr;
        var numberData = []; //数据的数组
        for (var i = 0, len = col.length; i < data.length; i++) {
            var temData = data[i].slice(len);
            numberData.push(temData);
        }
        //行合计 列合计
        var colSumArr = [],
            rowSumArr = [];
        for (var j = 0, len = numberData.length; j < len; j++) {
            var temColSum = 0;
            for (var k = 0; k < numberData[j].length; k++) {
                var colNum = (k)%(value[0].cols.length)
                //单位
                var unit = (value[0].cols[colNum] && value[0].cols[colNum].unit) || "";
                //小数位
                var mantissa = (value[0].cols[colNum] && value[0].cols[colNum].mantissa) || 6;
                var numValue = numberData[j][k]['data'] || 0;
                //去除单位进行计算
                if(numValue&&!_.isUndefined(numValue) && unit){
                    numValue = String(numberData[j][k]['data']).replace(unit,"");
                }
                temColSum += ( isNaN(util.clearComma(numValue)) ? 0 : Number(util.clearComma(numValue)) );
                rowSumArr[k] = Number(util.clearComma(rowSumArr[k] === undefined ? 0 : rowSumArr[k]) ) + ( isNaN(util.clearComma(numValue)) ? 0 : Number(util.clearComma(numValue)) );
                //计算完后加上单位
                if(rowSumArr[k] && !isNaN(rowSumArr[k]) && j == numberData.length-1){
                    rowSumArr[k] = (numbro(rowSumArr[k]).format({thousandSeparated: true,mantissa:Number(mantissa)}))+""+unit;
                    // rowSumArr[k].toFixed(mantissa)+""+unit;
                }
                //计算完后加上单位
                if(temColSum && !isNaN(Number(util.clearComma(temColSum))) && k==numberData[j].length-1){
                    temColSum = numbro(temColSum).format({thousandSeparated: true,mantissa:Number(mantissa)})+""+unit;
                }
            }
            colSumArr.push(temColSum);
        }
        return {colSumArr, rowSumArr}
    },
    //列合计 生成没一行 <tr><th><></tr>
    countColSum: function (chartConfig, data, filterArr, countRowSumFlag, chooseRowNum) {
        var temtr = '';
        if ( !countRowSumFlag ) {
            temtr = `<tr><th colspan='${chartConfig.keys.length}' style='text-align: center' class='row'>合计</th>`;
        }else{
            temtr = `<tr>`;
            for (var i = 0, len = chartConfig.keys.length; i < len; i++) {
                var align = chartConfig.keys[i].align;
                if ( i < chooseRowNum) {
                    temtr += "<th class='row_null'><div></div></th>";
                }else if ( i === chooseRowNum ) {
                    temtr += "<th style='text-align:"+align+"; border-bottom:1px solid #f2eded; border-top:unset' class='row'><div>小计</div></th>"
                }else {
                    temtr += "<th class='row_null' style='border-bottom:1px solid #f2eded; border-top:1px solid #f2eded; border-left:unset'><div></div></th>";
                }
            }
        }
        //小计合计
        var { rowSumArr } = this.filterData(data, filterArr);

        var temtd = ``;
        //小计合计样式及数据
        for (var j = 0, len = rowSumArr.length; j < len; j++) {
            temtd += `<td class="data" >${rowSumArr[j]}</td>`;
        }
        temtr = temtr + temtd + `</tr>`;
        return temtr
    },
    bandDrillEvent: function (t_class, drill, render) {
        $('.' + t_class + ' .table_drill_cell[drill-down]').click(function(){
            var down = $(this).attr('drill-down');
            var value = $(this).html();
            drill.drillDown(down, value, render);
        });
        $.contextMenu({
            selector: '.' + t_class + ' .table_drill_cell',
            build: function ($trigger, e) {
                var down = $trigger.attr('drill-down');
                var up = $trigger.attr('drill-up');
                var value = $trigger.html();
                var items = {};
                if (up) {
                    // items.up = {name: OurboreTranslate("COMMON.ROLL_UP"), icon: "fa-arrow-up"}
                    items.up = {name:'上卷', icon: "fa-arrow-up"}
                }
                if (down) {
                    // items.down = {name: OurboreTranslate("COMMON.DRILL_DOWN"), icon: "fa-arrow-down"}
                    items.down = {name: '下钻', icon: "fa-arrow-down"}
                }
                return {
                    callback: function (key, options) {
                        if ('up' == key) {
                            drill.drillUp(up, render);
                        } else if ('down' == key) {
                            drill.drillDown(down, value, render);
                        }
                    },
                    items: items
                };
            }
        });

    },
    paginationProcessData: function (rawData, headerLines, pageSize) {
        var dataLength = rawData.length - headerLines;
        var lastPageLines = dataLength % pageSize;
        var fullSizePages = parseInt(dataLength / pageSize);
        var totalPages;
        lastPageLines == 0 ? totalPages = fullSizePages : totalPages = fullSizePages + 1;
        var pageData = [];
        for (var currentPage = 1; currentPage < totalPages + 1; currentPage++) {
            var startRow = (currentPage - 1) * pageSize + headerLines;
            var partData = rawData.slice(startRow, startRow + pageSize);
            pageData.push(partData);
        }
        if(pageData.length==0){
          pageData.push([])
        }
        return pageData;
    },
    dataWrap: function (data){
        if(data == null || data == "" || !isNaN(Number(data))){
            return data;
        }
        var result = data, len = data.length, s = 40;
        if(data && len > s){
            result = "";
            var curlen = 0, patten = /.*[\u4e00-\u9fa5]+.*$/;
            for(var i = 0; i < len; i++){
                patten.test(data[i]) ? curlen += 2 : curlen++;
                if(curlen >= s){
                    curlen = 0;
                    result += "<br />";
                }
                result += data[i];
            }
        }
        return result;
    },
    render: function (data, chartConfig, drill, dataAll,tableId) {
        var html = '';
        if (data === undefined) {
            return html;
        }
        for (var r = 0; r < chartConfig.keys.length; r++) {
            for (var n = 1; n < data.length; n++) {
                if (chartConfig.option.mergeCell && chartConfig.option.mergeCell === true) {
                     var node = data[n][r].data;
                    if (r > 0) {
                        var parent = data[n][r - 1].data;
                        var next;
                        n > 0 ? next = data[n - 1][r - 1].data : null;
                        (node == data[n - 1][r].data && parent == next) ? data[n][r] = {
                            data: data[n][r].data,
                            rowSpan: 'row_null',
                            property: data[n][r].property
                        } : data[n][r] = {
                            data: data[n][r].data,
                            rowSpan: 'row',
                            property: data[n][r].property
                        };
                    }
                    else if (r == 0) {
                        var preNode = n > 0 ? data[n - 1][r].data : null;
                        (node == preNode) ? data[n][r] = {
                            data: data[n][r].data,
                            rowSpan: 'row_null',
                            property: data[n][r].property
                        } : data[n][r] = {
                            data: data[n][r].data,
                            rowSpan: 'row',
                            property: data[n][r].property
                        };
                    }
                }else{
                    // 去除相同合并项 都显示
                    data[n][r] = {
                        data: data[n][r].data,
                        rowSpan: 'row',
                        property: data[n][r].property
                    };
                }
            }
        }
        for (var n = 0; n < data.length; n++) {
            var rowContent = "<tr>";
            var isFirstLine = (n == 0) ? true : false;
            for (var m = 0; m < chartConfig.keys.length; m++) {
                var currentCell = data[n][m];
                // var rowParentCell = data[n][m - 1];
                if (chartConfig.option.mergeCell && chartConfig.option.mergeCell === true) {
                    var cur_data = this.dataWrap(currentCell.data ? currentCell.data : "");
                }else{
                    //数据显示不换行
                    var cur_data = currentCell.data;
                }
                cur_data = this.blankFormate(cur_data);
                var keyId = chartConfig.keys[m].id;
                var align = chartConfig.keys[m].align;
                if (drill && drill.config[keyId] && (drill.config[keyId].down || drill.config[keyId].up)) {
                    var d = "";
                    if (drill.config[keyId].down) {
                        d += " drill-down='" + keyId + "' ";
                    }
                    if (drill.config[keyId].up) {
                        d += " drill-up='" + keyId + "' ";
                    }
                    cur_data = "<div class='table_drill_cell' " + d + ">" + cur_data + "</div>";
                }
                var sortg = true;
                _.each(chartConfig.keys, function(key, index) {
                    index <= m && key.sort === undefined ? sortg = false : null;
                });
                if (chartConfig.option.mergeCell && chartConfig.option.mergeCell === true) {
                    if (m > 0 && sortg) {
                        // 判断所有前置单元格是否已经是合并状态
                        var rowParentsCellFalg = true;  //预设是合并的
                        for(var x = m; x > 0; x--){
                            if( n > 0 && data[n][x - 1].rowSpan != 'row_null') {
                                rowParentsCellFalg = false;
                            }
                        }
                        if (currentCell.rowSpan == 'row_null' && rowParentsCellFalg && !isFirstLine) {
                            rowContent += "<th class='row_null'><div></div></th>";
                        } else {
                            rowContent += "<th style='text-align:"+align+"' class='row'><div>" + cur_data + "</div></th>";
                        }
                    } else {
                        if (currentCell.rowSpan == 'row_null' && !isFirstLine && sortg) {
                            rowContent += "<th class='row_null'><div></div></th>";
                        } else {
                            rowContent += "<th style='text-align:"+align+"' class='row'><div>" + cur_data + "</div></th>";
                        }
                    }
                }else{
                    // 去除相同合并项 都显示
                    rowContent += "<th style='text-align:"+align+"' class='row'><div>" + cur_data + "</div></th>";
                }
            }
            for (var y = chartConfig.keys.length; y < data[n].length; y++) {
                var align = chartConfig.values[0].cols[(y-chartConfig.keys.length)%chartConfig.values[0].cols.length].align;
                var temData = data[n][y].data/* === "" ? "" : data[n][y].data + " ABCD EFG HIJKLMNO PQ RST"*/;
                // var celData = this.dataWrap(temData);
                // 数据显示不换行
                var celData = temData;
                rowContent += "<td style='text-align:"+align+"' class='" + data[n][m].property + "'><div>" + celData + "</div></td>";
            }''
            html = html + rowContent + "</tr>";
            if (chartConfig.option.chooseRowNumArr && chartConfig.option.chooseRowNumArr !== undefined) {
                var chooseRowArr = chartConfig.option.chooseRowNumArr.split(' ').filter( items => items !== '');
                var sortChooseRowArr = [...new Set(chooseRowArr)].sort( (a, b) => b-a);//列数由大到小排列
                for ( var i = 0; i < sortChooseRowArr.length; i++) {
                    var filterArr = [];
                    var curRowNum = parseInt(sortChooseRowArr[i]) - 1;
                    //只对比到 当页倒数第二行
                    if ( n < data.length - 1) {
                         //保证输入为大于1的数字 且当前[n][curRowNum]['data']与[n+1][curRowNum]['data']不一样 进行小计
                        if ( !isNaN( parseInt( sortChooseRowArr[i] ) ) &&  parseInt( sortChooseRowArr[i] ) > 0 && parseInt( sortChooseRowArr[i] ) <= chartConfig.keys.length && this.isSubtotal(data[n], data[n+1], curRowNum)) {
                            var headerLines = chartConfig.groups.length + 1;
                            var curdataAll = dataAll.slice(headerLines);
                            filterArr = curdataAll.filter( item => {
                                var flag;
                                for (var i = 0; i <= curRowNum; i++) {
                                    if ( data[n][i]['data'] !== item[i]['data']) {
                                        return false
                                    }else {
                                        flag = true;
                                    }
                                }
                                return flag
                            })
                             // chartConfig, data, filterArr, countRowSumFlag, chooseRowNum
                            var filterArrArgs = {col:chartConfig.keys, row:chartConfig.groups, value:chartConfig.values};
                            var trDom = this.countColSum(chartConfig, filterArr, filterArrArgs, true, curRowNum);
                            html += trDom;
                        }
                    }else {
                        if ( !isNaN( parseInt( sortChooseRowArr[i] ) ) &&  parseInt( sortChooseRowArr[i] ) > 0 && parseInt( sortChooseRowArr[i] ) <= chartConfig.keys.length ) {
                            var headerLines = chartConfig.groups.length + 1;
                            var curdataAll = dataAll.slice(headerLines);
                            filterArr = curdataAll.filter( item => {
                                var flag;
                                for (var i = 0; i <= curRowNum; i++) {
                                    if ( data[n][i]['data'] !== item[i]['data']) {
                                        return false
                                    }else {
                                        flag = true;
                                    }
                                }
                                return flag
                            })
                             // chartConfig, data, filterArr, countRowSumFlag, chooseRowNum
                            var filterArrArgs = {col:chartConfig.keys, row:chartConfig.groups, value:chartConfig.values};
                            var trDom = this.countColSum(chartConfig, filterArr, filterArrArgs, true, curRowNum);
                            html += trDom;
                        }
                    }
                }
            }
        }
        //合计
        if (chartConfig.option.rowSum && chartConfig.option.rowSum === true) {
            var headerLines = chartConfig.groups.length + 1;
            var curdataAll = dataAll.slice(headerLines);
            var filterArr = {col:chartConfig.keys, row:chartConfig.groups, value:chartConfig.values};
            var temtr = this.countColSum(chartConfig, curdataAll, filterArr, false);
            html += temtr;
        }
        return html;
    },
    //选着具体列时，是否进行小计
    isSubtotal: function (dataRow, nextDataRow, chooseRowNum) {
        var flag;
        for (var i = 0; i <= chooseRowNum; i++) {
            if ( dataRow[i]['data'] !== nextDataRow[i]['data']) {
                return true
            }else {
                flag = false;
            }
        }
        return flag
    },
    selectDataNum: function (data, num, chartConfig, drill, random, t_class, render,tableId) {
        var _this = this;
        $('.' + random).on('change', '.optionNum input', function (e) {
            var pageDataNum = parseInt(e.target.value);
            var dataPage = _this.paginationProcessData(data, num, pageDataNum,tableId);
            var dom = $(e.target.offsetParent).find('.page>ul')[0];
            var tbody = $(e.target.offsetParent).find('tbody')[0];
            tbody.innerHTML = (_this.render(dataPage[0], chartConfig, drill, data,tableId));
            //参数chartConfig, data, drill
            // tbody.innerHTML = _this.renderByRowSum(chartConfig, dataPage[0], drill, data);
            $('#curDataNum').text(dataPage[0].length);
            _this.renderPagination(dataPage.length, 1, null, dom,tableId);
            $('.' + random).off('click');
            _this.clickPageNum(dataPage, chartConfig, drill, random, data,tableId);
            var pageObj = {
                data: dataPage,
                chartConfig: chartConfig,
                drill: drill
            };
            _this.clickNextPrev(dataPage.length, pageObj, random, data,tableId);
            $('#'+tableId+' tbody tr td').css({'font-size': chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': chartConfig.option.tableContentColor});
            $('#'+tableId+' tbody tr th').css({'font-size': chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': chartConfig.option.tableContentColor});
            $('#'+tableId+' .table_drill_cell').css({'color': chartConfig.option.tableDrillColor})
            for (var i=0,len=$('#'+tableId+' tbody tr').length; i<len; i++) {
                $($('#'+tableId+' tbody tr')[i]).children().eq(chartConfig.option.warnRow - 1).find('div').css({ 'color': '#f00' });
            }
            if (chartConfig.option.tableStyle === 'styleA') {
                $('#'+tableId+' tbody tr td:last-child').css({ 'color': '#f00' });
            }
            _this.highlightValue(chartConfig.values[0].cols,data,tableId);
            _this.setKeyhighlightValue(chartConfig.keys,data,tableId);
        });
        _this.bandDrillEvent(t_class, drill, render,tableId);
    },
    clickPageNum: function (data, chartConfig, drill, random, dataAll,tableId) {
        var _this = this;
        $('.' + random).on('click', 'a.pageLink', function (e) {
            var pageNum = e.target.innerText - 1;
            var pageObj = {
                data: data,
                chartConfig: chartConfig,
                drill: drill
            };

            var dom = $(e.target.offsetParent).find('.page>ul')[0];
            var tbody = $(e.target.offsetParent).find('tbody')[0];
            tbody.innerHTML = _this.render(data[pageNum], chartConfig, drill, dataAll,tableId);
            // tbody.innerHTML = _this.renderByRowSum(chartConfig, data[pageNum], drill, dataAll);
            $('#curDataNum').text(data[pageNum].length);
            _this.renderPagination(data.length, parseInt(e.target.innerText), pageObj, dom,tableId);
            $('#'+tableId+' tbody tr td').css({'font-size': chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': chartConfig.option.tableContentColor});
            $('#'+tableId+' tbody tr th').css({'font-size': chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': chartConfig.option.tableContentColor});
            $('#'+tableId+' .table_drill_cell').css({'color': chartConfig.option.tableDrillColor});
            for (var i=0,len=$('#'+tableId+' tbody tr').length; i<len; i++) {
                $($('#'+tableId+' tbody tr')[i]).children().eq(chartConfig.option.warnRow - 1).find('div').css({ 'color': '#f00' });
            }
            if (chartConfig.option.tableStyle === 'styleA') {
                $('#'+tableId+' tbody tr td:last-child').css({ 'color': '#f00' });
            }
            _this.highlightValue(chartConfig.values[0].cols,data,tableId);
            _this.setKeyhighlightValue(chartConfig.keys,data,tableId);
            e.stopPropagation();
        });
    },
    renderPagination: function (pageCount, pageNumber, pageObj, target,tableId) {
        if (pageCount == 1&&target) {
            return  target.innerHTML = '';
        }
        var liStr = '<li><a class="previewLink">' +'上一页' + '</a></li>';
        // var liStr = '<li><a class="previewLink">' + OurboreTranslate("CROSS_TABLE.PREVIOUS_PAGE") + '</a></li>';

        //分页渲染规则
        if (pageCount < 7) {
            for (var a = 0; a < pageCount; a++) {
                liStr += '<li><a class="pageLink">' + (a + 1) + '</a></li>';
            }
        } else {
            if (pageNumber < 4) {
                for (var i = 0; i < 4; i++) {
                    liStr += '<li><a class="pageLink">' + (i + 1) + '</a></li>';
                }
                liStr += '<li class="disable"><span class="ellipse">...</span></li>';
            }else{
                liStr += '<li><a class="pageLink">' + 1 + '</a></li>';
                liStr += '<li class="disable"><span class="ellipse">...</span></li>';
                if (pageNumber <= pageCount - 3) {
                    for (var b = pageNumber; b < pageNumber + 2; b++) {
                        liStr += '<li><a class="pageLink">' + b + '</a></li>';
                    }
                    liStr += '<li class="disable"><span class="ellipse">...</span></li>';
                }else{
                    for (var c = pageCount - 3; c < pageCount; c++) {
                        liStr += '<li><a class="pageLink">' + c + '</a></li>';
                    }
                }
            }
            liStr += '<li><a class="pageLink">' + pageCount + '</a></li>';
        }
        liStr += '<li><a class="nextLink">' + '下一页' + '</a></li>';
        // liStr += '<li><a class="nextLink">' + OurboreTranslate("CROSS_TABLE.NEXT_PAGE") + '</a></li>';
        if (target) {
            target.innerHTML = liStr;
            if (pageNumber == 1) {
                target.childNodes[0].setAttribute('class', 'hide');
            } else if (pageNumber == pageCount) {
                target.childNodes[target.childNodes.length - 1].setAttribute('class', 'hide');
            }
            this.buttonColor(pageNumber, target);
        }
        // else {
        //     $('.page>ul').html(liStr);
        //     if (pageNumber == 1) {
        //         $('.page a.previewLink').addClass('hide');
        //     } else if (pageNumber == pageCount) {
        //         $('.page a.nextLink').addClass('hide');
        //     }
        //     this.buttonColor(pageNumber);
        //     this.clickNextPrev(pageCount, pageObj);
        // }
    },
    buttonColor: function (pageNum, target) {
        if (target) {
            var buttons = target.childNodes;
            for (var i = 0; i < buttons.length; i++) {
                buttons[i].childNodes[0].innerText == pageNum ? $(buttons[i].childNodes[0]).addClass('current') : null;
            }
        }
    },
    clickNextPrev: function (pageCount, pageObj, random, dataAll,chartConfig,tableId) {
        var _this = this;
        $('.' + random).on('click', '.page a.previewLink', function (e) {
            var kids = e.target.parentNode.parentNode.childNodes;
            var dom = e.target.parentNode.parentNode.parentNode.childNodes[0];
            var tbody = $(e.target.offsetParent).find('tbody')[0];

            for (var i = 0; i < kids.length; i++) {
                if (kids[i].childNodes[0].className.indexOf('current') > -1) {
                    var pageNum = parseInt(kids[i].childNodes[0].text) - 1;
                }
            }
            tbody.innerHTML = _this.render(pageObj.data[pageNum - 1], pageObj.chartConfig, pageObj.drill, dataAll,tableId);
            // tbody.innerHTML = _this.renderByRowSum(pageObj.chartConfig, pageObj.data[pageNum - 1], pageObj.drill, pageObj.data[pageNum - 1], dataAll);
            $('#curDataNum').text(pageObj.data[pageNum - 1].length);
            _this.renderPagination(pageCount, pageNum, pageObj, dom,tableId);
            //_this.clickPageNum(pageObj.data, pageObj.chartConfig);
            $('#'+tableId+' tbody tr td').css({'font-size': pageObj.chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': pageObj.chartConfig.option.tableContentColor});
            $('#'+tableId+' tbody tr th').css({'font-size': pageObj.chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': pageObj.chartConfig.option.tableContentColor});
            $('#'+tableId+' .table_drill_cell').css({'color': pageObj.chartConfig.option.tableDrillColor});
            for (var i=0,len=$('#'+tableId+' tbody tr').length; i<len; i++) {
                $($('#'+tableId+' tbody tr')[i]).children().eq(chartConfig.option.warnRow - 1).find('div').css({ 'color': '#f00' });
            }
            if (pageObj.chartConfig.option.tableStyle === 'styleA') {
                $('#'+tableId+' tbody tr td:last-child').css({ 'color': '#f00' });
            }
            _this.highlightValue(chartConfig.values[0].cols,null,tableId);
            _this.setKeyhighlightValue(chartConfig.keys,null,tableId);
            e.stopPropagation();
        });
        $('.' + random).on('click', '.page a.nextLink', function (e) {
            var kids = e.target.parentNode.parentNode.childNodes;
            var dom = e.target.parentNode.parentNode.parentNode.childNodes[0];
            var tbody = $(e.target.offsetParent).find('tbody')[0];

            for (var i = 0; i < kids.length; i++) {
                if (kids[i].childNodes[0].className.indexOf('current') > -1) {
                    var pageNum = parseInt(kids[i].childNodes[0].text) + 1;
                }
            }
            tbody.innerHTML = _this.render(pageObj.data[pageNum - 1], pageObj.chartConfig, pageObj.drill, dataAll,tableId);
            // tbody.innerHTML = _this.renderByRowSum(pageObj.chartConfig, pageObj.data[pageNum - 1], pageObj.drill, pageObj.data[pageNum - 1], dataAll);
            $('#curDataNum').text(pageObj.data[pageNum - 1].length);
            _this.renderPagination(pageCount, pageNum, pageObj, dom,tableId);
            //_this.clickPageNum(pageObj.data, pageObj.chartConfig);
            $('#'+tableId+' tbody tr td').css({'font-size': pageObj.chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': pageObj.chartConfig.option.tableContentColor});
            $('#'+tableId+' tbody tr th').css({'font-size': pageObj.chartConfig.option.tableContentFontSize,'font-weight': 'bold','color': pageObj.chartConfig.option.tableContentColor});
            $('#'+tableId+' .table_drill_cell').css({'color': pageObj.chartConfig.option.tableDrillColor});
            for (var i=0,len=$('#'+tableId+' tbody tr').length; i<len; i++) {
                $($('#'+tableId+' tbody tr')[i]).children().eq(chartConfig.option.warnRow - 1).find('div').css({ 'color': '#f00' });
            }
            if (pageObj.chartConfig.option.tableStyle === 'styleA') {
                $('#'+tableId+' tbody tr td:last-child').css({ 'color': '#f00' });
            }
            _this.highlightValue(chartConfig.values[0].cols,null,tableId,tableId);
            _this.setKeyhighlightValue(chartConfig.keys,null,tableId);
            e.stopPropagation();
        });
    },

    export: function (random, data,chartConfig,tableId, name) {
        var _this = this;
        $(".toolbar" + random + " .exportBnt").on('click', function () {
            _this.highlightValue(chartConfig.values[0].cols,data,tableId);
            _this.setKeyhighlightValue(chartConfig.keys,data,tableId);
            var xhr = new XMLHttpRequest();
            var formData = new FormData();
            formData.append('data', JSON.stringify({data: data, type: 'table'}));
            xhr.open('POST', '/be/api/bcp/dashboard/tableToxls');
            // xhr.responseType = 'arraybuffer';
            xhr.responseType = 'arraybuffer';
            xhr.onload = function (e) {
                var blob =    new Blob([this.response], {type: "application/vnd.ms-excel"});
                var objectUrl = window.URL.createObjectURL(blob);
                var aForExcel = $("<a><span class='forExcel'>下载excel</span></a>").attr("href", objectUrl);
                aForExcel.attr("download", name + ".xls");
                $("body").append(aForExcel);
                $(".forExcel").click();
                aForExcel.remove();
            };
            xhr.send(formData);
            behaviorLogService.insertLog({module:"widget",function:"导出Excel",name:name})
        });

        $(".toolbar" + random + " .exportCsvBnt").on('click', function () {
            _this.highlightValue(chartConfig.values[0].cols,data,tableId);
            _this.setKeyhighlightValue(chartConfig.keys,data,tableId);
            var escMatcher = '\n|\r|,|"';
            var row;
            var output = '\ufeff';
            var rows = data.length;
            var columns = data[0].length;
            for (var i = 0; i < rows; i++) {
                var rowArray = [];
                for (var j = 0; j < columns; j++) {
                    var cell = data[i][j].data;
                    var strValue = (cell === undefined || cell === null) ? '' : cell.toString();
                    strValue = strValue.replace(new RegExp('"', 'g'), '""');
                    if (strValue.search(escMatcher) > -1) {
                        strValue = '"' + strValue + '"';
                    }
                    rowArray.push(strValue);
                }
                output += rowArray.join(',') + '\n';
            }

            var blob = new Blob([output], {type: "application/csv"});
            var objectUrl = URL.createObjectURL(blob);
            var aForCsv = $("<a><span class='forCsv'>下载csv</span></a>").attr("href", objectUrl);
            aForCsv.attr("download", name + ".csv");
            $("body").append(aForCsv);
            $(".forCsv").click();
            aForCsv.remove();
            behaviorLogService.insertLog({module:"widget",function:"导出Excel",name:name})
        });

        //查询是否有权限导出,没有就移除这个按钮
        $(".toolbar" + random + " .exportCsvBnt").hide();
        $(".toolbar" + random + " .exportBnt").hide();
        userRoleAdminService.hasPermission("bore:widget:export").then((data)=>{
            if(data == 1){
                $(".toolbar" + random + " .exportCsvBnt").show();
                $(".toolbar" + random + " .exportBnt").show();
            } else {
                $(".toolbar" + random + " .exportCsvBnt").hide();
                $(".toolbar" + random + " .exportBnt").hide();
            }
        });
    },

    carousePage:function(random, chartConfig){
        // 顶级对象下增加属性crossTableTimer--obj, 用来装多个定时器
        if(!window.crossTableTimer) {
            window.crossTableTimer = {};
        }
        if(!chartConfig.option.tableStyleConfig||!chartConfig.option.changeTableTime){
            clearInterval(window.crossTableTimer[random]);
        }else{
            clearInterval(window.crossTableTimer[random]);
            var changeTableTime = chartConfig.option.changeTableTime * 1000
            window.crossTableTimer[random] = setInterval(function () {
                if ($('.table_' + random)[0] && $('.p_' + random + ' .page ul li')[0]) {
                    if ($('.p_' + random + ' .page ul li:last').attr('class') === 'hide') {
                        $('.p_' + random + ' .page ul li:eq(1) a.pageLink').trigger('click');
                    } else {
                        $('.p_' + random + ' .page .nextLink').trigger('click');
                    }
                } else {
                    // 找不到元素说明已关闭----注销定时器
                    clearInterval(window.crossTableTimer[random]);
                }
            },changeTableTime);
        }
    },

    //将空格转义、行维排版的问题--现用于key
    blankFormate:function (data) {
        return data.replace(new RegExp(' ', 'g'), '&nbsp;');
    }
};

export default crossTable;

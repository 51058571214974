//筛选出已选择的用户信息
export  default  function selectedUserFilter() {
    return function (userList, selectedIds) { //被过滤的数据userList；过滤条件selectedIds
        if (!selectedIds || selectedIds.length == 0) return [];
        var selectedUserList = [];
        angular.forEach(userList, function (user) {
            if (selectedIds.indexOf(user.id) != -1) {
                selectedUserList.push(user);
            }
        });
        return selectedUserList;
    }
}
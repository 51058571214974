import  boardContext  from '../../../../charts/BoardContext'
import PubSub from "../../../../common/utils/PubSub"
export default function BoardViewCrtl($scope, $sce,toastr, archivesDetailModal) {
    $scope.hasLoadBoard = false;
    //初始化变量
    $scope.timelineColor = ['bg-light-blue', 'bg-red', 'bg-aqua', 'bg-green', 'bg-yellow', 'bg-gray', 'bg-navy', 'bg-teal', 'bg-purple', 'bg-orange', 'bg-maroon', 'bg-black'];
    var groupTimeline = function () {
        $scope.timeline = [];
        var group = undefined;
        _.each($scope.board.layout.rows, function (row, idx) {
            // if (idx == 0) {
            //     $scope.timelineFilter = row;
            //     return;
            // }
            if (row.type != 'widget') {
                return;
            }
            if(!row.show)row.show = false;
            if (row.node == 'parent') {
                if (group) {
                    $scope.timeline.push(group);
                }
                group = [];
                row.show = true;
            }
            group.push(row);
        });
        $scope.timeline.push(group);
    };
   
    $scope.judgeHasLoad =function(event){
        if(!$scope.hasLoadBoard){
            toastr.warning("图表数据加载中，请勿频繁操作！");
        }
    }
    $scope.initLoadBoardWidget = function(){
        window.CURBOARDID = $scope.board.id;
        if (PubSub.hasSubscribers('loading_'+$scope.board.id)) {
            PubSub.unsubscribe('loading_'+$scope.board.id)
        }
    }
    $scope.initLoadBoardWidget();

    PubSub.subscribe('loading_'+$scope.board.id,function(status,topic){
        if(status==2){
           $scope.hasLoadBoard = true;
           angular.element("#board-slicer").css({"pointer-events": "all"})
           angular.element("#chromeHeadlessId").addClass("chrome-headless")
        }else{
            $scope.hasLoadBoard = false;
            angular.element("#board-slicer").css({"pointer-events":"none"})
        }
    });
    PubSub.subscribe("updataFilterTitle", function(){
        $scope.$apply();
    });
    
    //将切片器更新的方法$scope.resetFilter移做新方法，原方法调用它
    var resetFilterFn = function(){
        let boardFilter = [];//生效看板
        //处理筛选器中的查询参数
        _.each($scope.board.layout.rows, function (row) {
            _.each(row.params, function (param) {
                if (param.values.length <= 0) {
                    return;
                }
                _.each(param.col, function (col) {
                    if(!_.isUndefined(col.datasetId)){
                        var p = {
                            col: col.column,
                            type: param.type,
                            values: param.values,
                            datasetId:col.datasetId
                        };
                        boardFilter.push(p)
                    }else{
                        var p = {
                            col: col.column,
                            type: param.type,
                            values: param.values
                        };
                        boardFilter.push(p)
                    }
                  
                });
            });
        });
        boardContext.clearAllWidgetFilters();
        boardContext.setBoardFilters(boardFilter);
        boardContext.refreshAll();
        // $scope.$apply()
    };
    //点击按钮主动渲染图表
    $scope.renderBoard = function(){
        resetFilterFn();
        $scope.resetFilterReady = false;
    };
    //slicer调用它时，加判断，无按钮存在即时渲染
    $scope.resetFilterReady = false;
    $scope.resetFilter = function (isNotInit) {
        if(!$scope.board.layout.showParamButton) {
            resetFilterFn();
        } else if(isNotInit) {
            $scope.resetFilterReady = true;
        } else if(!isNotInit) {
            resetFilterFn();
            $scope.resetFilterReady = false;
        }

        // let boardFilter = [];//生效看板
        // //处理筛选器中的查询参数
        // _.each($scope.board.layout.rows, function (row) {
        //     _.each(row.params, function (param) {
        //         if (param.values.length <= 0) {
        //             return;
        //         }
        //         _.each(param.col, function (col) {
        //             if(!_.isUndefined(col.datasetId)){
        //                 var p = {
        //                     col: col.column,
        //                     type: param.type,
        //                     values: param.values,
        //                     datasetId:col.datasetId
        //                 };
        //                 boardFilter.push(p)
        //             }else{
        //                 var p = {
        //                     col: col.column,
        //                     type: param.type,
        //                     values: param.values
        //                 };
        //                 boardFilter.push(p)
        //             }
                  
        //         });
        //     });
        // });
        // boardContext.setBoardFilters(boardFilter);
        // boardContext.refreshAll();
        // $scope.$apply()
    }
    var initDsReloadStatus = function (reload) {
        var dsReloadStatus = {};
        _.each($scope.board.layout.rows, function (row) {
            _.each(row.widgets, function (widget) {
                var dataSetId = widget.widget && widget.widget.data && widget.widget.data.datasetId;
                if (dataSetId != undefined) {
                    dsReloadStatus[dataSetId] = reload;
                }
            });
        });
        return dsReloadStatus;
    };

    boardContext.clearBoardData();
    // $scope.board =$scope.boardData;
    //注入过滤条件------方法重新声明
    // $scope.resetFilter();
    resetFilterFn();
    //如果是timeline 
    if ($scope.board.layout.type == 'timeline') {
        groupTimeline();
    }
    var dsReloadStatus = initDsReloadStatus(false);
    _.each($scope.board.layout.rows, function (row) {
        //处理Row
        _.each(row.params, function (param) {
            if (!param.paramType) {
                param.paramType = 'selector';
            }
        });
        _.each(row.widgets, function (widget) {
            //处理widget 
            if (!_.isUndefined(widget.hasRole) && !widget.hasRole || widget.widgetId == '-1') {
                return;
            }
            if (widget || widget.widget || widget.widget.data) {
                return;
            }
            if (widget.widget.data.analysisText) {
                widget.widget.data.analysisText = $sce.trustAsHtml(widget.widget.data.analysisText)
            }
            var dataSetId = widget.widget.data.datasetId;
            var needReload = reload;
            // avoid repeat load offline dataset data
            if (dataSetId != undefined && reload) {
                var needReload = dsReloadStatus[dataSetId] ? true : false;
                dsReloadStatus[dataSetId] = false;
            }
            // buildRender(widget, needReload);

            widget.loading = true;
            if ($scope.board.layout.type == 'timeline') {
                if (row.show) {
                    widget.show = true;
                }
            } else {
                widget.show = true;
            }
            //real time load task
            var w = widget.widget.data;
            var ds = _.find($scope.datasetList, function (e) {
                return e.id == w.datasetId;
            });
            //是否要定时刷新判断
            if (ds && ds.data.interval && ds.data.interval > 0) {
                widget.isRealTime = true;
                widget.realtimeInterval = ds.data.interval
            }
        });
    });

    //完成拖拽;rows图表行,index拖拽后的图表索引，rdx拖拽后的图表行索引，type被拖拽图表的类型，item被拖拽数据
    $scope.onDropComplete =function(rows,index,rdx,type,item){
        //从原来行中删除 被拖拽元素，并获取该元素
        var data = rows[$scope.movingRdx].widgets.splice($scope.movingIndex,1);
        rows[rdx].widgets.splice(index-1,0,data[0]);
        //插入元素到被拖拽位置
        // console.log("rows",rows)
        // console.log("rdx",rdx)
        // console.log("$scope.movingRdx",$scope.movingRdx)
        // console.log("index",index)
        // console.log("$scope.movingIndex",$scope.movingIndex)
    }

    //开始拖拽
    $scope.startDragData =function(index,rdx){
        $scope.movingIndex = index;
        $scope.movingRdx = rdx;
    }


    //打开关闭的节点
    $scope.openCloseNode = function (row,group) {
        $scope.selectedLayoutRdx = row.rdx;
        if (row.show) {
            row.show = false;
            _.each(row.widgets, function (widget) {
                widget.show = false;
            });
        } else {
            row.show = true;
            _.each(row.widgets, function (widget) {
                widget.show = true;
            });
        }
    };

    // //将缓存的信息载入window
    // $scope.initCache = function(){
    //     if(window.BOARD){
    //         if(window.BOARD[$scope.board.id]) {
    //             window.BOARD[$scope.board.id].cache = $scope.board.layout.cache;
    //         } else {
    //             window.BOARD[$scope.board.id] = {cache: $scope.board.layout.cache};
    //         }

    //     } else {
    //         window.BOARD = {};
    //         window.BOARD[$scope.board.id] = {cache: $scope.board.layout.cache};
    //     }

    // }
    // $scope.initCache();
    // //编辑时可能会改变
    // $scope.$watch('board.layout.cache', function(){
    //     $scope.initCache();
    // }, true);

    boardContext.setBoardCache($scope.board.layout.cache);
    $scope.$watch('board.layout.cache', function(){
        boardContext.setBoardCache($scope.board.layout.cache);
        $scope.$broadcast('boardCacheChange', '');
    }, true);

    // 截屏准备中...
    $scope.domToImage = function(){
        if($scope.isGetImage) {
            return;
        }
        $scope.isGetImage = true;
        let pageDom = document.getElementById('boardContentBox');
        getImageAndShow(pageDom);
    };
    // 生成base64并打开弹窗
    var getImageAndShow = function(targetE){
        // 动画导致生成的图片样式不确定----先拿掉
        let animateDom = $('.animated');
        if(animateDom.hasClass('animated')) {
            animateDom.removeClass('animated');
        }
        //生成canvas
        html2canvas(targetE, {
            // allowTaint: true, //污染后无法导出
            scale: 2,
            logging: false,
            useCORS: true,
            y: targetE.getBoundingClientRect().top + window.scrollY,
            backgroundColor: '#f0f3f4'
        }).then(canvas => {
            animateDom.addClass('animated');                //去掉的样式补充回去

            let width = parseInt(canvas.width) / 2;         //目标元素宽度
            let height = parseInt(canvas.height) / 2;       //目标元素高度
            // 将canvas的大小还原
            let c = document.createElement('canvas');
            let ctx = c.getContext('2d');
            c.width = width;
            c.height = height;
            ctx.drawImage(canvas, 0, 0, width, height);

            let base64Data = c.toDataURL('image/jpeg', 0.92);
            var archives = {
                alias: $scope.board.name,
                url: base64Data,
                source: '看板',
            };
            var func_ok = function(){
                // 
            };
            // ｛id? , alias, url, source, tagId?, remarks?｝  无id则为新增，有为修改
            archivesDetailModal.openDetail(archives, func_ok);
            $scope.isGetImage = false;
        }, err=>{
            $scope.isGetImage = false;
            toastr.error('获取图片失败');
            // console.log(err);
        });
    };
}
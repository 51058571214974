import { userService } from './user.service';
import { boardService } from './board.service';
import { dataService } from './data.service';
import { dtpManagerService } from './dtpManager.service';
import { prewarningService } from './prewarning.service';
import { widgetService } from './widget.service';
import { fileService } from './file.service'
import { userAdminService } from './userAdmin.service';
import { datasetService } from './dataset.service';
import { userResService } from './userRes.service'
import { modelCenterService } from './modelCenter.service'
import { modelBuyService } from './modelBuy.service'
import { orderCenterService } from './orderCenter.service'
import { accountCenterService } from './accountCenter.service'
import { appService } from './app.service'
import {userRoleAdminService} from './userRoleAdmin.service' 
import { dataSupportService } from './dataSupport.service'
import { officeService } from './office.service'

import {reportService} from './report.service'
import {collectionService} from './collection.service'
import {messageService} from './message.service'
import {timerPushService} from './timerPush.service'
import {borePropertyService} from './boreProperty.service'
import {dtpService}from './dtp.service'
import {behaviorLogService}from './behaviorLog.service'
import {datasetMetaTableService}from './datasetMetaTable.service'
import {accessControlService}from './accessControl.service'
import {cacheService} from './CacheService'
import {globalSettingService} from './globalSetting.service'

import { boredvChartmarketService } from './boredvChartmarket.service';
import { archivesService } from './archives.service';

export {
    userService, boardService, dataService, widgetService, 
    dtpManagerService, prewarningService, userAdminService, 
    userResService, modelCenterService, modelBuyService,
    orderCenterService,fileService,   accountCenterService,
    appService,datasetService, userRoleAdminService,
    dataSupportService, officeService,reportService,collectionService,messageService,timerPushService
    ,borePropertyService,dtpService,behaviorLogService,datasetMetaTableService,accessControlService,
    cacheService,globalSettingService,
    boredvChartmarketService,
    archivesService,
};
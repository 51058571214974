import BasicService from './BasicService';
import { DTPMANAGERAPI } from './ApiServer';


class DtpManagerService extends BasicService {
  constructor() {
    super();
  }

  getDtpConnectList(params){
    return this.post(DTPMANAGERAPI.GETDTPCONNECTLIST,params)
  }
  getDtpList(params){
    return this.post(DTPMANAGERAPI.GETDTPLIST,params)
  }
  judgeIsConnect(params){
    return this.post(DTPMANAGERAPI.JUDGEISCONNECT,params)
  }
  deleteDtp(params){
    return this.post(DTPMANAGERAPI.DELETEDTP,params);
  }
  getDtpByAppkey(params){
    return this.post(DTPMANAGERAPI.GETDTPBYAPPKEY,params);
  }
  saveDtp(params){
    return this.post(DTPMANAGERAPI.SAVEDTP,params);
  }
  getDataSourceListByDtpId(params){
    return this.post(DTPMANAGERAPI.GETDATASORCEBYDTPID,params);
  }
  connectTestSource(params){
    return this.post(DTPMANAGERAPI.CONNECTTESTSOURCE,params);
  }
  delDataSource(params){
    return this.post(DTPMANAGERAPI.DELDATADTPSOURCE,params);
  }
  saveDataSource(params){
    return this.post(DTPMANAGERAPI.SAVEDATASOURCE,params);
  }
  getDataSourceList(params){
    return this.post(DTPMANAGERAPI.GETDATASOURCELIST,params);
  }
}

export const dtpManagerService = new DtpManagerService();

// import BasicService from './BasicService';
// import { DTP_MANAGER_API } from './ApiServer';


// class DtpManagerService extends BasicService {
//   constructor() {
//     super();
//   }

//   getDtpConnectList(params){
//     return this.post(DTP_MANAGER_API.GET_DTP_CONNECT_LIST,params)
//   }
//   deleteDtp(params){
//     return this.post(DTP_MANAGER_API.DELETE_DTP,params);
//   }
//   getDtpByAppkey(params){
//     return this.post(DTP_MANAGER_API.GET_DTP_BY_APPKEY,params);
//   }
//   saveDtp(params){
//     return this.post(DTP_MANAGER_API.SAVE_DTP,params);
//   }
//   getDataSourceListByDtpId(params){
//     return this.post(DTP_MANAGER_API.GET_DATASORCE_BY_DTPID,params);
//   }
//   connectTestSource(params){
//     return this.post(DTP_MANAGER_API.CONNECT_TEST_SOURCE,params);
//   }
//   delDataSource(params){
//     return this.post(DTP_MANAGER_API.DELDATA_DTP_SOURCE,params);
//   }
//   saveDataSource(params){
//     return this.post(DTP_MANAGER_API.SAVE_DATASOURCE,params);
//   }
//   getDataSourceList(params){
//     return this.post(DTP_MANAGER_API.GET_DATASOURCE_LIST,params);
//   }
// }

// export const dtpManagerService = new DtpManagerService();


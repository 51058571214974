import config from './theme.config';
import configProvider from './theme.configProvider'
import themeRun from './theme.run'
import themeLayoutSettings from './theme.service'
import initConstant from './theme.constants'
import components from './components/components.module'
import services from './services/services.module'
import filters from './filters/filters.module'
import directives from './directives/directives.module';
import inputs from './inputs/inputs.module'
import './main.scss';
var theme = angular.module('boreAdmin.theme', [
  components,
  services,
  filters,
  directives,
  inputs
  // 'BlurAdmin.theme.inputs'
]);

export default initConstant(theme) //初始化配置
  .provider('baConfig', ['colorHelper',configProvider])
  .config(['$provide',config])
  .config(['toastrConfig',function (toastrConfig) {
    angular.extend(toastrConfig, {
      autoDismiss: false,
      containerId: 'toast-container',
      maxOpened: 0,
      newestOnTop: true,
      positionClass: 'toast-top-center',
      preventDuplicates: false,
      preventOpenDuplicates: false,
      target: 'body',
      tapToDismiss:true,
      timeOut:500
    });
  }])
  .service('themeLayoutSettings',  ['baConfig',themeLayoutSettings])
  .run(['$timeout','$rootScope','hasPermsService','layoutPaths','preloader','$q','baSidebarService','themeLayoutSettings','toastr','$state','ModalUtils',themeRun]).name


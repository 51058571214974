import * as util from '../../util/util'
import EchartRenderEngine from '../../rengine/EchartRenderEngine'
// Ourbore.service('chartFunnelService', function ($state, $window) {

class FunnelRender {
    //默认高度
    height = 300;

    render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        if(option.theme){
            EchartRenderEngine.prototype.theme=option.theme;
        }
        var render = new EchartRenderEngine(containerDom, option);
        // render.addClick(chartConfig, relations, $state, $window);
        render.addClick(chartConfig, relations);
        return render.chart(null, persist);
    };

    parseOption = function (data) {
        try{
        var chartConfig = data.chartConfig;
        var casted_keys = data.keys;
        var casted_values = data.series;
        var aggregate_data = data.data;
        var newValuesConfig = data.seriesConfig;

        var colFormatter = [];
        var setColFormatter = false;
        //获取指标数组
        for (var i = 0; i < chartConfig.values.length; i++) {
            for (var j = 0; j < chartConfig.values[i].cols.length; j++) {
                var col = chartConfig.values[i].cols[j];
                colFormatter.push(col);
                //适配原有的图表配置,如果设置了提示框单位转换才需要转换单位,否则就用原先默认的
                if(col && (col.unit || col.symbol ||col.dividend || col.mantissa)){
                    setColFormatter = true;
                }
            }
        }

        var string_keys = util.map(casted_keys, function (key) {
            return key.join('-');
        });
        var string_values = util.map(casted_values, function (value) {
            return value.join('-');
        });

        var series = [];
        var b = 100 / (string_keys.length * 9 + 1);
        var titles = [];
        for (var i = 0; i < string_keys.length; i++) {
            var s = {
                name: string_keys[i],
                type: 'funnel',
                left: b + i * b * 9 + '%',
                width: b * 8 + '%',
                top: '10%',
                bottom: '5%',
                maxSize: '100%',
                minSize: '10%',
                label: {
                    normal: {
                        formatter: function (params) {
                            let dvalue = params.value;
                            if(setColFormatter){
                                dvalue = util.convertEchartUnit(colFormatter[params.dataIndex], params.value);
                            }
                            return dvalue + "\n" + params.data.percent + "%";
                        },
                        show: true,
                        position: 'inside'
                    }
                },
                data: []
            };
            titles.push({
                textAlign: 'center', textStyle: {
                    fontSize: 12,
                    fontWeight: 'normal'
                }, text: string_keys[i], left: 5 * b + i * 9 * b + '%', top: '90%'
            });
            var m = _.max(aggregate_data, function (d) {
                return Number(d[i]);
            })[i];
            for (var d = 0; d < string_values.length; d++) {

                s.data.push({
                    name: string_values[d],
                    value: aggregate_data[d][i],
                    percent: (aggregate_data[d][i] / m * 100).toFixed(2)
                });
            }
            series.push(s);
        }

        var echartOption = {
            title: titles,
            legend: {
                data: string_values
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {
                    var dvalue = params.value;
                    if(setColFormatter){
                        dvalue = util.convertEchartUnit(colFormatter[params.dataIndex],params.value);
                    }
                    return params.seriesName + " <br/>" + params.name + " : " + dvalue + "<br>" + params.data.percent + "%";
                }
            },
            toolbox: false,
            series: series
        };
        util.updateEchartOptions(chartConfig.option, echartOption);
        util.updateEchartsTooltipPosition(echartOption);
        return echartOption;
        }catch(e){
            console.log(e)
            return ;
        }
    };
}


export {FunnelRender}

import { officeService,borePropertyService,userAdminService, boredvChartmarketService} from '../../../server'
export default function ($scope, toastr) {
    $scope.isLoading = true;
    $scope.loginLogPng = "/be/api/open/bcp/file/showfile/loginLogo.png";
    $scope.userAvagPng = "/be/api/open/bcp/file/showAvatar/userAvag.png";
    $scope.instationLogoPng = "/be/api/open/bcp/file/showfile/instationLogo.png";

    $scope.chartMarketSync = false;              //同步图表市场的状态

    $scope.getLicenseInfo = function () {
        officeService.getLiceseInfo().then(function (response) {
            $scope.isLoading = false;
            if (response.status == '1') {
                $scope.systemInfo = response.data.systemInfo;
                $scope.licenseInfo = response.data.licenseInfo;
                $scope.userCounts = response.data.userCounts;
                $scope.$apply();
                return;
            }
            $scope.$apply();
            toastr.error("获取授权信息失败");
        });
    }
    // $scope.getSystemProperty = function(){
    //     borePropertyService.getPropertyList({key:"bore.login.logo"}).then(function(response){
    //         if(response&&response[0]){
    //             $scope.systemProperty={
    //                 key:response[0].key,
    //                 id:response[0].id,
    //                 value:response[0].value,
    //                 modifyBy: "N",
    //                 remark:'登录页LOGO'
    //             };
    //         }else{
    //             $scope.systemProperty = {
    //                 key:"bore.login.logo",
    //                 value:"",
    //                 remark:'登录页LOGO',
    //                 modifyBy: "N",
    //             }
    //         }
    //     })
    // }
    // $scope.getSystemProperty();
    $scope.getLicenseInfo();
    $scope.removePicture = function () {
        $scope.picture = $filter('liImage')('theme/no-photo.png');
        $scope.noPicture = true;
      };
    
    $scope.uploadPicture = function (loginImg) {
        var fileInput = document.getElementById(loginImg);
        fileInput.click();
    };
    $scope.uploadLogo = (files,fileName) => {
        const formData = new FormData();
        formData.append("file", files[0]);
        formData.append("fileName",fileName)
        userAdminService.attUploadImage(formData).then((data) => {
            // $scope.systemProperty.value=data;
            // $scope.loginLogPng = "/"+$scope.loginLogPng;
            // $scope.userAvagPng = "/"+$scope.userAvagPng;
            // $scope.instationLogoPng = "/"+$scope.instationLogoPng;
            window.location.reload();
            $scope.$apply();
        }, err => {
            console.log(err)
            toastr.error(err.head.errorMsg||"修改图片失败！")
        })
    }
    $scope.refreshLic = function () {
        officeService.refreshLic().then(function (response) {
            if (response.status == '1') {
                $scope.getLicenseInfo();
                $scope.$apply();
                toastr.success('刷新成功！！！')
                return;
            }
            toastr.error("刷新授权信息失败");
        });
    };

    $scope.syncChart = function(){
        $scope.chartMarketSync = true;
        boredvChartmarketService.syncChart().then(data=>{
            $scope.chartMarketSync = false;
            if(data){
                toastr.success(data);
            } else {
                toastr.success('同步成功');
            }
        }, err=>{
            $scope.chartMarketSync = false;
            toastr.error(err.head.errorMsg || "同步图表失败");
        })
    };
};
import widgetModal from './WidgetModal'
import paramSelector from './paramSelector'
import ChartManager from '../../../charts/ChartManager'
import widgetListCtrl from './WidgetListCtrl'
import widgetListTpl from './widgetlist.html'
import widgetShareTpl from './widgetShare/widgetShare.html'
import widgetShareCtrl from './widgetShare/widgetShareCtrl'
var charts=ChartManager.getAllCharts();
var widgetModule = 
angular.module('borepc.pages.chartwidget', [])


//注册图标的配置属性
Object.keys(charts).forEach(function (key) {
    if (!_.isUndefined(charts[key].option)) {//配置项
        // console.log(key,charts[key]);
        // console.log("charts-key222222222",charts[key])
        var optionName = key.toLowerCase()+'Option';
        widgetModule.directive(optionName, function () {
            return {
                template: _.isUndefined(charts[key].option.template) ? '' : charts[key].option.template,
                scope: false,
                controllerAs: optionName,
                controller: _.isUndefined(charts[key].option.crtl) ? angular.noop : charts[key].option.crtl
            }
        })
    }
    if (!_.isUndefined(charts[key].editor)) {//编辑器
        // console.log(key,charts[key]);
        var editorName = key.toLowerCase()+'Editor';
        widgetModule.directive(editorName, function () {
            return {
                template: _.isUndefined(charts[key].editor.template) ? '' : charts[key].editor.template,
                scope: false,
                controllerAs: editorName,
                controller: _.isUndefined(charts[key].editor.crtl) ? angular.noop : charts[key].editor.crtl
            }
        })
    }
});

export default widgetModule
.controller('paramSelector', ['$scope', '$uibModalInstance', 'param', 'filter', 'getSelects', 'ok',paramSelector])
.controller('widgetListCtrl', ['$scope', 'ModalUtils','$timeout',  '$uibModal','widgetModal','toastr',widgetListCtrl])
.controller('widgetShareCtrl', ['$scope', '$stateParams','ModalUtils','$state',widgetShareCtrl])
.config(['$stateProvider',routeConfig])
.service('widgetModal', ['$uibModal',widgetModal]).name

/** @ngInject */
function routeConfig($stateProvider) {
    $stateProvider
        .state('widgetList', {
            url: '/widgetList',
            title: '图表列表',
            template: widgetListTpl,
            controller: 'widgetListCtrl',
        })
        .state('widgetShare', {
            url: '/widgetShare?widgetId',
            params:{widgetId: null},
            title: '图表分享',
            template: widgetShareTpl,
            controller: 'widgetShareCtrl',
        })
   }
/**
 * mySetToolInputSilder-----仅限数字
 * 
 *  <my-set-tool-input-silder bore-data-title = "标题" bore-data-tip = "说明文" bore-data-value = "" bore-data-step = "数字" bore-data-placeholder = "" bore-data-init = "" bore-data-range = "" bore-data-icon = ""></my-set-tool-input-silder>
 * 
 * boreDataTitle: 输入框前面的说明文---限制5个字
 * boreDataTip: 移入显示说明文---tip
 * boreDataValue: 关联的值
 * boreDataStep: 步进的大小
 * boreDataPlaceholder: 无内容时显示的文本
 * boreDataInit: 初始化值-------------------------------------空字符串和0的结果都是0
 * boreDataRange : 正负的范围
 * boreDataIcon: 按钮图标的样式名
 */

export default function mySetToolInputSilder(){

    return {
        restrict: 'EA',
        transclude: true,
        template: ` <div class="bore-set-tool">
                        <div class="bore-set-tool-title" ng-click="inputFocus($event)">{{boreDataTitle}}</div>
                        <div class="bore-set-tool-content">
                            <div class="bore-set-tool-input-silder">
                                <input type="number" class="input-silder-input" ng-model ="boreDataValue" placeholder="{{boreDataPlaceholder}}" ng-click="close()">
                                <div class="input-silder-btn" ng-click="toggle()">
                                    <i class="default-icon"></i>
                                </div>
                                <div class="input-silder-pop" ng-if = "show" ng-click="$event.stopPropagation()">
                                    <rzslider rz-slider-model="silder.value" rz-slider-options="{floor : (midValue-boreDataRange), ceil : (midValue+boreDataRange), step : boreDataStep}"></rzslider>
                                </div>
                            </div>
                        </div>
                        <div class="bore-set-tool-tip" ng-class="{'show':boreDataTip?true:false}">
                            <span>?</span>
                            <span class="tip-content">{{boreDataTip}}</span>
                        </div>
                    </div>`,
        replace: true,
        scope: {
            boreDataValue: '='
        },
        link: function(scope, element, attrs) {
            scope.inputFocus = function($event){
                $($event.target).next().children().children()[0].focus();
                close();
            };
            scope.boreDataTitle = attrs.boreDataTitle ? attrs.boreDataTitle : "";
            scope.boreDataTip = attrs.boreDataTip ? attrs.boreDataTip : "";
            scope.boreDataPlaceholder = attrs.boreDataPlaceholder ? attrs.boreDataPlaceholder : "";
            scope.boreDataStep = attrs.boreDataStep ? Number(attrs.boreDataStep) : 1;
            scope.boreDataRange = attrs.boreDataRange ? Number(attrs.boreDataRange) : 100;
            scope.iconClass = attrs.boreDataIcon ? attrs.boreDataIcon : "default-icon";

            if(scope.boreDataValue === undefined && attrs.boreDataInit !== undefined){
                scope.boreDataValue = Number(attrs.boreDataInit);
            }

            scope.boreDataValue = Number(scope.boreDataValue);
            scope.$watch("boreDataValue", function(){
                if(scope.boreDataValue || scope.boreDataValue == "0") {
                    scope.boreDataValue = Number(scope.boreDataValue);
                } else {
                    scope.boreDataValue = undefined;
                }
            });


            //滑动条部分----ng-show滑动条无法初始化，ng-if出现无法传值的bug---需要对象进行中间传值
            scope.silder = {};
            scope.silder.value = scope.boreDataValue;   //必须---不然监视器会将scope.boreDataValue值为""
            //监视滑动条，改变后更新输入框的值
            scope.$watch("silder.value", function(){
                scope.boreDataValue = scope.silder.value;
            });
            scope.show = false;
            scope.midValue = scope.boreDataValue ? scope.boreDataValue : (attrs.boreDataInit ? Number(attrs.boreDataInit) : 0);
            scope.toggle = function(){
                scope.show = !scope.show;
                if(scope.show) {
                    //每次打开，更新当前值和中间值
                    scope.silder.value = scope.boreDataValue ? scope.boreDataValue : (attrs.boreDataInit ? Number(attrs.boreDataInit) : 0);
                    scope.midValue = scope.silder.value;
                }
            };
            scope.close = function(){
                scope.show = false;
            };

        }
    };
}
import { dataService } from '../../../../server'
import { util } from '../../../utils/util'
import slicerTpl from './slicer.html';
import paramCtrl from './paramCtrl'
require('ng-screening');//引入组件
require('!style-loader!css-loader!ng-screening/css/ngScreening.css')
require('angularjs-slider');//引入组件
require('!style-loader!css-loader!angularjs-slider/dist/rzslider.css')
import './_slicer.scss'
/** @ngInject */
   
function updateParamTitle(param) {
  if ('slider' == param.paramType) {
    return;
  }

  if (param.values && param.values.length > 0) {

    //判断是否需要隐藏当前过滤词条
    var flag = false;     //默认不隐藏

    var promisecontain = [];
    for(var i = 0; i < param.values.length; i++) {
      //判断存在不是时间的表达式---存在即终止循环
      if(param.values[i].indexOf('{') == 0 && param.values[i].indexOf('{now') !== 0) {
        flag = true;
        break;
      }
      var newPromise = new Promise((resolve, reject) => {
        if(param.values[i].indexOf('{now') == 0) {
          dataService.getTimeParserExpression(param.values[i]).then(res => {
            resolve(res);
          });
        } else {
          resolve(param.values[i]);
        }
      });
      promisecontain.push(newPromise);

    }
    if(!flag){
      Promise.all(promisecontain).then(res=>{
        var paramObj = util.updateParamTitle(param, res);
        param.title = paramObj;
      });
    } else {
      param.title = undefined;
    }

  } else {
    //values为空
    param.title = undefined;
  }
}

function slicer() {
  return {
    restrict: 'EA',
    template: slicerTpl,
    controller:['$scope', function ($scope) {
      $scope.paramToString1 = function (row) {
        _.each(row.params, (param) => {
          updateParamTitle(param);
        })
        return _.filter(_.map(row.params, function (e) {
          return e.title;
        }), function (e) {
          return e && e.length > 0;
        }).join('; ');
      };
    }] 
  };
}

export default angular.module('BlurAdmin.theme.components.slicer', ['ngScreening', 'rzSlider'])
  .filter('paramToString', function () { //可以注入依赖
    return function (row) {
      _.each(row.params, (param) => {
        updateParamTitle(param);
      })
      return _.filter(_.map(row.params, function (e) {
        return e.title;
      }), function (e) {
        return e && e.length > 0;
      }).join('; ');
    }
  })
  .controller('paramCtrl', ['$scope', '$timeout', 'ngScreening', 'ParamFilterModal',paramCtrl])
  .directive('slicer', slicer).name;

export default function () {
    return {
        require: '?ngModel',
        link: function (scope, element, attrs, ngModel) {
           
            var config = {
                toolbarCanCollapse : true  
            }
            if (attrs.config) {
                Object.assign(config, JSON.parse(attrs.config));
            }
            console.log( config)
            var ckeditor = CKEDITOR.replace(element[0],config);
            if (!ngModel) {
                return;
            }
            ckeditor.on('instanceReady', function () {
                ckeditor.setData(ngModel.$viewValue);
            });
            ckeditor.on('pasteState', function () {
                scope.$apply(function () {
                    ngModel.$setViewValue(ckeditor.getData());
                });
            });
            ngModel.$render = function (value) {
                ckeditor.setData(ngModel.$viewValue);
            };
        }
    };
} 

/**
 * mySetToolPop------只剩左边的标题和说明文了！！！
 * 
 *  <my-set-tool-pop bore-data-title = "标题" bore-data-tip = "说明文">
 *      <div class="bore-set-tool-text">{{显示文本}}</div>
        <div class="bore-set-tool-btn" ng-click="弹窗的方法">
            <i class="标识符类名"></i>
        </div>
    </my-set-tool-pop>
 * 
 * boreDataTitle: 输入框前面的说明文---限制5个字
 * boreDataTip: 移入显示说明文---tip
 */

export default function mySetToolPop(){

    return {
        restrict: 'EA',
        transclude: true,
        template: ` <div class="bore-set-tool">
                        <div class="bore-set-tool-title">{{boreDataTitle}}</div>
                        <div class="bore-set-tool-content">
                            <div class="bore-set-tool-pop">
                                <div ng-transclude></div>
                            </div>
                        </div>
                        <div class="bore-set-tool-tip" ng-class="{'show':boreDataTip?true:false}">
                            <span>?</span>
                            <span class="tip-content">{{boreDataTip}}</span>
                        </div>
                    </div>`,
        replace: true,
        scope: {

        },
        link: function(scope, element, attrs) {
            scope.boreDataTitle = attrs.boreDataTitle ? attrs.boreDataTitle : "";
            scope.boreDataTip = attrs.boreDataTip ? attrs.boreDataTip : "";

        }
    };
}
import angular from 'angular'
import angularUiRouter from 'angular-ui-router'; //'ui.router',
import ngAnimate from 'angular-animate';
import UIbootstrap from 'angular-ui-bootstrap'
import ngTouch from 'angular-touch';
import toastr from 'angular-toastr';
import dataRangePicker from 'angular-daterangepicker'
require('!style-loader!css-loader!angular-toastr/dist/angular-toastr.css')
require('!style-loader!css-loader!animate.css/animate.css')
import smartTable from 'angular-smart-table';
import xeditable from 'angular-xeditable';
import uiSelect from 'ui-select'
import ngFileUpload from 'ng-file-upload'

require('angular-uuid4');
require('angular-ui-ace');
require('angular-slimscroll/angular-slimScroll');
require('angular-ui-sortable')
require('angular-translate')
require('angular-translate-loader-partial')
// require('ng-js-tree');
require('angular-progress-button-styles/dist/angular-progress-button-styles');
require('angular-drag-and-drop-lists');
//引入一个jstree 控件
// require('../common/utils/JsTreeUtils')
import '../charts'
import theme from '../common/theme/theme.module'
import pages from './pages/pages.module'
import {
  collectionService,
  userService
} from '../server'
//国际化
function tranconfig($translateProvider, $translatePartialLoaderProvider) {

  $translatePartialLoaderProvider.addPart('Ourbore');
  $translateProvider.useLoader('$translatePartialLoader', {
    urlTemplate: 'i18n/{lang}/{part}.json'
  });
  $translateProvider.preferredLanguage("cn");
}
/** @ngInject */
function routeConfig($urlRouterProvider, $locationProvider) {
  $locationProvider.html5Mode(false);
  $locationProvider.hashPrefix('');
  $urlRouterProvider.otherwise('/userPanel');
};
angular.module('borePC', [
    ngAnimate,
    UIbootstrap, 
    //  UISorttable,
    angularUiRouter,
    ngTouch,
    toastr,
    smartTable,
    xeditable,
    uiSelect,
    ngFileUpload,
    dataRangePicker,
    'ui.ace',
    'dndLists',
    'ui.sortable',
    'ngSlimScroll',
    'angular-progress-button-styles',
    'pascalprecht.translate',
    // 'ngJsTree',
    'uuid4',
    theme,
    pages
  ]).config(['$translateProvider', '$translatePartialLoaderProvider', tranconfig])
  .config(['$urlRouterProvider', '$locationProvider', routeConfig]);

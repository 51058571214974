import BasicService from './BasicService';
import { USERROLEADMIN } from './ApiServer';
/**
 */
class UserRoleAdminService extends BasicService {
  //getMenuList
  getMenuList() {
    return this.get(USERROLEADMIN.GETALLMENULIST)
  }

  getAuthMenuList(params) {
    return this.post(USERROLEADMIN.GETAUTHMENULIST,params)
  }

  //获取单个操作的权限
  hasPermission(params) {
    return this.post(USERROLEADMIN.HASPERMISSION, {'pmcode':params})
  }

  //saveNewUser
  saveNewUser(params) {
    return this.post(USERROLEADMIN.SAVENEWUSER, params)
  }

  //updateUserRole
  updateUserRole(params) {
    return this.post(USERROLEADMIN.UPDATEUSERROLE, params)
  }

  //editUser
  editUser(params) {
    return this.post(USERROLEADMIN.EDITUSER, params)
  }

  //checkExistRoleName
  // checkExistRoleName (params) {
  //   return this.post(USERROLEADMIN.CHECKEXISTROLENAME,params)
  // }

  //saveRole
  saveOrUpdateRole(params) {
    return this.post(USERROLEADMIN.SAVE, params)
  }

  // //updateRole
  // updateRole (params) {
  //   return this.post(USERROLEADMIN.UPDATEROLE,params)
  // }

  //updateRoleUser
  updateRoleUser(params) {
    return this.post(USERROLEADMIN.UPDATEROLEUSER, params)
  }

  //deleteUserRole
  deleteUserRole(params) {
    return this.post(USERROLEADMIN.DELETEROLEUSER, params)
  }

  //updateRoleRes
  // updateRoleRes (params) {
  //   return this.post(USERROLEADMIN.UPDATEROLERES, params)
  // }
  getRoleList() {
    return this.post(USERROLEADMIN.GETROLELIST);
  }
  //getRole
  getRole(id) {
    return this.get(USERROLEADMIN.GETROLE, { id: id })
  }

  //deleteRole
  deleteRole(params) {
    return this.post(USERROLEADMIN.DELETE, params)
  }

  //deleteUser 
  deleteUser(params) {
    return this.get(USERROLEADMIN.DELETEUSER + `?userId=${params}`)
  }
  getRoleUserList(roleId) {
    return this.get(USERROLEADMIN.GETROLEUSERLIST,{roleId:roleId})
  }
  saveRoleUser(param){
    return this.get(USERROLEADMIN.SAVEROLEUSER,param)
  }

}

export const userRoleAdminService = new UserRoleAdminService();


import timerEditTpl from './timerEdit/timerEdit.html'
import timerEditCtrl  from './timerEdit/timerEditCtrl'
import timerPushListTpl from './timerPushList.html'
import timerPushListCtrl  from './timerPushListCtrl'
import timerLogListTpl from './timerLog/timerLogList.html'
import timerLogListCtrl  from './timerLog/timerLogListCtrl'
import timerLogDetailTpl from './timerLog/timerLogDetail.html'
import timerLogDetailCtrl  from './timerLog/timerLogDetailCtrl'
  
export default angular.module('borepc.pages.timerPush', [])
.controller('timerPushListCtrl', ['toastr', '$scope',  'ModalUtils', '$uibModal',timerPushListCtrl])
.controller('timerEditCtrl', ['$scope', '$uibModal', 'toastr','ok', 'userList', '$uibModalInstance', 'ModalUtils', 'timer',timerEditCtrl])
.controller('timerLogListCtrl', ['toastr','$scope', '$state', 'ModalUtils',timerLogListCtrl])
.controller('timerLogDetailCtrl', ['toastr', '$scope', '$state',  '$stateParams',timerLogDetailCtrl])
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('timer', {
      url: '/timer',
      title: '预警列表',
      template: timerPushListTpl,
      controller: 'timerPushListCtrl',
    })
    .state('timerLog', {
      url: '/timerLog',
      title: '预警日志',
      template: timerLogListTpl,
      controller: 'timerLogListCtrl'
    })
    .state('timerLogDetail', {
      url: '/timerLog/detail?id',
      title: '预警日志详情',
      params: { id: null},
      template: timerLogDetailTpl,
      controller: 'timerLogDetailCtrl'
    })
}


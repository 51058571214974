/**
 * mySetToolToggle
 * 
 * <my-set-tool-toggle bore-data-title = "" bore-data-tip = "" bore-data-role = "" bore-data-init = "" bore-data-string = "空字符串以外" bore-data-string-true = "存在不能为空" bore-data-string-false = "存在不能为空"></my-set-tool-toggle>
 * 
 * boreDataTitle: 输入框前面的说明文---------------------------------限制5个字，额外一个数字（0-9）
 * boreDataTip: 移入显示说明文---tip---------------------------------为空即无相关元素
 * boreDataRole: 关联的值 boolean类型--------------------------------必填的
 * boreDataInit: 初始化值--------------------------------------------传true则初始化为true，默认false
 * boreDataString: 返回的值为字符串----------------------------------存在这个参数则默认返回字符串的true或false
 * boreDataStringTrue: 为true时替代的字符串--------------------------需前置boreDataString
 * boreDataStringFalse: 为false时替代的字符串------------------------需前置boreDataString
 */


export default function mySetToolToggle(){
    return {
        restrict: 'EA',
        template: ` <div class="bore-set-tool">
                        <div class="bore-set-tool-title" ng-click="toggleRole()">{{boreDataTitle}}</div>
                        <div class="bore-set-tool-content">
                            <button type="button" class="bore-set-tool-toggle-btn" ng-class="{'toggle-on': (boreDataRole && (isString ? boreDataRole !== isString : true )) }" ng-click="toggleRole()">
                                <span class="bore-set-tool-toggle-btn-inner"></span>
                            </button>
                        </div>
                        <div class="bore-set-tool-tip" ng-class="{'show':boreDataTip?true:false}">
                            <span>?</span>
                            <span class="tip-content">{{boreDataTip}}</span>
                        </div>
                    </div>`,
        replace: true,
        scope: {
            boreDataRole: '='
        },
        link: function(scope, element, attrs) {
            scope.toggleRole = function(){
                scope.boreDataRole = !scope.boreDataRole;
                // if(scope.boreDataRole && scope.boreDataRole == true) {
                //     scope.boreDataRole = false;
                // } else {
                //     scope.boreDataRole = true;
                // }
            };
            scope.boreDataTitle = attrs.boreDataTitle ? attrs.boreDataTitle : "";
            scope.boreDataTip = attrs.boreDataTip ? attrs.boreDataTip : "";
            //初始化
            if(scope.boreDataRole == undefined){
                scope.boreDataRole = attrs.boreDataInit == 'true' ? true : false;
            } else {
                //
            }

            //将值转为字符串类型
            scope.isString = "";    //空字符串，判断时为false
            if(attrs.boreDataString) {
                var dataTrue = attrs.boreDataStringTrue ? attrs.boreDataStringTrue : "true";
                var dataFalse = attrs.boreDataStringFalse ? attrs.boreDataStringFalse : "false";
                scope.isString = dataFalse;     //字符串类型，判断时为true
                //考虑初始化出现的情况
                if(scope.boreDataRole && scope.boreDataRole !== dataTrue && scope.boreDataRole !== dataFalse) {
                    scope.boreDataRole = dataTrue;
                } else if (!scope.boreDataRole) {
                    scope.boreDataRole = dataFalse;
                }
                scope.toggleRole = function(){
                    scope.boreDataRole = scope.boreDataRole === dataFalse ? dataTrue : dataFalse;
                    // if(scope.boreDataRole === dataFalse) {
                    //     scope.boreDataRole = dataTrue;
                    // } else {
                    //     scope.boreDataRole = dataFalse;
                    // }
                };
            }
        }
    };
}
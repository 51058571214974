
import dataContextUtil from './dataContextUtil';

class DataContextSingle {
    constructor() { }

    // 1. 后台数据==>BI图表需要的数据
    castRawData2Series = function(aggData, chartConfig) {
        return dataContextUtil.aggDataToBIData(aggData, chartConfig, 'single');
    }
}

var dataContextSingle = new DataContextSingle();
export default dataContextSingle;
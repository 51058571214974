import layoutTpl from './layout.html'
import LayoutCrtl  from './layoutCrtl'
// import LayoutParam from  './layouts/modal/LayoutParam.module'
import LayoutGrid  from './layouts/grid/LayoutGrid.module'
import layoutTimeline from './layouts/timeline/LayoutTimeline.module'

export default angular.module('borepc.pages.dblayout', [LayoutGrid,layoutTimeline])
.filter('noCarouseBoardFilter', function () {
  return function (data) {
      var filterData = new Array;
      _.each(data, function (e) {
          if (!e.layout || e.layout.type != 'carouse') {
              filterData.push(e);
          }
      })
      return filterData;
  }
})
.filter('propsFilter', function () {
  return function (items, props) {
      var out = [];

      if (angular.isArray(items)) {
          var keys = Object.keys(props);

          items.forEach(function (item) {
              var itemMatches = false;

              for (var i = 0; i < keys.length; i++) {
                  var prop = keys[i];
                  var text = props[prop].toLowerCase();
                  if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                      itemMatches = true;
                      break;
                  }
              }

              if (itemMatches) {
                  out.push(item);
              }
          });
      } else {
          out = items;
      }

      return out;
  };
}) 
.controller('LayoutCrtl', ['$rootScope','$state','$scope','ModalUtils','$filter','ParamFilterModal','$stateParams','toastr','widgetListModal', 'boardListModal', LayoutCrtl])
.config(['$stateProvider',routeConfig]).name ;
/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('layout', {
      url: '/layout?boardId&caseType&optFlag&layoutType',
      title: 'layout',
      params:{ boardId: null, caseType:null, optFlag: 'none',layoutType:'grid'},
      template: layoutTpl,
      controller: 'LayoutCrtl',
    })
    // .state('oldLayout', {
    //     url: '/oldLayout',
    //     title: 'oldLayout',
    //     template: oldLayoutTpl,
    //     controller: 'oldLayoutCrtl',
    //   });
}


import BasicService from './BasicService';
import { OFFICEAPI } from './ApiServer';
/**
 */
class OfficeService extends BasicService {
    constructor () {
        super();
    }

    getOffice () {
        return this.get(OFFICEAPI.GETOFFICE);
    }
    updateOffice (params) {
        return this.post(OFFICEAPI.UPDATEOFFICE, params);
    }
    getLiceseInfo(){
        return this.post(OFFICEAPI.LICENSEINFO)
    }
    refreshLic(){
        return this.post(OFFICEAPI.REFRESHLIC)
    }

}

export const officeService = new OfficeService();
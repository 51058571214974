import basiderbar from './baSidebar/basidebar.module'
import pageTop from './pageTop/pageTop.directive'
import backTop from './backTop/backTop.directive'
import baPanel from './baPanel/baPanel.module'
import baWizard from './baWizard/baWizard.module'
import contentTop from './contentTop/contentTop.directive'
import msgCenter from './msgCenter/msgCenter.directive'
import progressBarRound from './progressBarRound/progressBarRound.directive'
import widgets from './widgets/widgets.directive'
import chartWidget from './chartWidget/chartWidget.directive'
import toastrLibConfig from './toastrLibConfig'
import  slicer from './slicer/slicer.directive'
import  modal  from './modal/modal.directive'
import  widgetOption from './widgetOption/widgetOption.directive'
import  widgetEditor from './widgetEditor/widgetEditor.directive'
import  boreCronInput  from './cron/boreCronInput.directive'
import  ParamFilter from './paramFilter/paramFilter.module'
import  permission  from './permission/permission.module'
import setTool from './setTool/setTool.module'
import widgetList from './widgetList/widgetList.module'
import boardList from './boardList/boardList.module'
import myJsTree from './myJsTree/myJsTree.module'

export default angular.module('boreAdmin.theme.components',
    [   basiderbar,
        pageTop,
        backTop,
        baPanel,
        baWizard,
        contentTop,
        msgCenter,
        progressBarRound,
        widgets,
        chartWidget,
        slicer,
        modal,
        widgetOption,
        widgetEditor,
        ParamFilter,
        permission,
        boreCronInput,
        setTool,
        widgetList,
        boardList,
        myJsTree
    ]).config(['toastrConfig',toastrLibConfig]).name;


import BasicService from './BasicService';
import { BOARDAPI } from './ApiServer';
/**
 * @desc 测试用
 */
class BoardService extends BasicService {
  constructor() {
    super();
  }
  getBoardList(params){
    return this.post( BOARDAPI.BOARDLIST,params)
  }
  getBoardByWidget(params){
    return this.post( BOARDAPI.GETBOARDBYWIDGET,params)
  }
  getBoardColList(params){
    return this.post( BOARDAPI.GETBOARDCOLLIST,params)
  }
  getBoardData(id){
    return  this.get(BOARDAPI.BOARDDATA+'?id='+id);
  }
  getBoardParam(boardId){
    return  this.get(BOARDAPI.BOARDPARAM+'boardId='+boardId)
  }
  updateBoard(params){
    return this.post(BOARDAPI.BOARDUPDATE,params)
  }
  saveNewBoard(params){
    return this.post(BOARDAPI.BOARDSAVE,params)
  }
  deleteBoard(params){
    return this.post(BOARDAPI.BOARDDEL,params)
  }
  getCategoryList(){
    return this.get(BOARDAPI.BOARDCATEGORY)
  }
  getBoardWidget(id){
    return  this.get(BOARDAPI.BOARDWIDGET+'?id='+id)
  }
  saveCategory(params){
    return this.post(BOARDAPI.SAVECATEGORY,params)
  }
  copyCategory(params){
    return this.post(BOARDAPI.COPYCATEGORY,params)
  }
  deleteCategory(params){
    return this.post(BOARDAPI.DELETECATEGORY,params)
  }

}

export const boardService = new BoardService();


import { dataService } from '../server';
import * as util from './util/util';

import { dataContextUtil, dataContextMerge, dataContextSingle } from './dataprocess';

// var existData = true;    //类中不适合使用全局变量进行状态控制

class DataContext {

    // 疑似私用方法----获取数据集
    getDatasetDetailNoDs = function (datasetId,datasetDetail) {
        return new Promise((resolve, reject) => {
            if (datasetDetail) {
                resolve(datasetDetail);
            } else {
                dataService.getDatasetDetailNoDs({ "id": datasetId }).
                    then(function (response) {
                        if (!response.dataset) {
                            reject('数据集或数据源已删除!');
                        }
                        resolve(response);
                    }).catch(function (err) {
                        reject(err);
                    })
            }
        })
    }
    //连接数据集
    linkDataset = function (datasetId, chartConfig,datasetDetail) {
        if ((util.isUndefined(datasetId)&&util.isUndefined(datasetDetail)) || util.isUndefined(chartConfig)) {
            return Promise.resolve();
        } else if (datasetDetail) {
            var dataset = util.clone(datasetDetail);
            dataContextUtil.organizeChartConfigByDataset(dataset, chartConfig);
            return Promise.resolve(datasetDetail);
        } else {
            return dataService.getDatasetDetailNoDs({ "id": datasetId }).then(function (datasetData) {
                if (!datasetData || !datasetData.dataset) {
                    // existData = false;
                    return Promise.resolve();
                }
                var dataset = util.clone(datasetData);
                dataContextUtil.organizeChartConfigByDataset(dataset, chartConfig);
                return Promise.resolve(datasetData);
            });
        }
    }

    /**获取图表配置中包含的关联字段
     * widgetCtrl调用，判断多源数据集的图表是否已使用关联字段
     * */
    getConfigAssArr = function(params,callBack){
        let self = this;
        self.getDatasetDetailNoDs(params.datasetId,params.datasetDetail).then(function (datasetData) {
            var assIndexArr = [];
            var hasAss = false;
            if(datasetData.dataset.type!='1'){
                callBack(true);
                return;
            }
            var datasetData;
            if(params.datasetDetail){
                datasetData = params.datasetDetail.dataset.data;
            }else{
                datasetData = JSON.parse(datasetData.dataset.data);
            }

            var assIndexArr = dataContextMerge.configHasAssColumn(params.widgetConfig,datasetData);
            hasAss = (assIndexArr && assIndexArr.length>0)?true:false;
            callBack(hasAss);
        })
    }
    /**依据子数据集Id与维度名称获取维度值
     *
     * 数据集和图表调用
     * */
    getDimensionValues = function (datasource, query, dtpId, isDtpManager, datasetId, colmunName, chartConfig, callback) {
        chartConfig = util.clone(chartConfig);
        this.linkDataset(datasetId, chartConfig).then(function () {
            var cfg = undefined;
            if (chartConfig) {
                cfg = {
                    rows: [],
                    columns: [],
                    filters: []
                };
                cfg.rows = dataContextUtil.getDimensionConfig(chartConfig.keys);
                cfg.columns = dataContextUtil.getDimensionConfig(chartConfig.groups);
                cfg.filters = dataContextUtil.getDimensionConfig(chartConfig.filters);
            }
            var params = {
                datasourceId: datasource,
                query: JSON.stringify(query),
                datasetId: datasetId,
                colmunName: colmunName,
                cfg: JSON.stringify(cfg),
                dtpId: dtpId,
                isDtpManager: isDtpManager,
            }
            dataService.getDimensionValues(params).then().then(function (response) {
                callback(response);
            });
        });
    }
    //datasetData父数据集信息,widget图表配置----chartContent和widgetCtrl调用
    getWidgetDataSeries = function (widget, callback, reload, cache,datasetDetail) {
        const self=this;
        self.getDatasetDetailNoDs(widget.datasetId,datasetDetail).then(function (datasetData) {
            if (!datasetData || !datasetData.dataset) {
                // existData = false;
                return;
            }
            var dataset = util.clone(datasetData.dataset);
            dataset.data = JSON.parse(datasetData.dataset.data);
            //判断cache参数中是否设置缓存---isCache==false
            if(!cache || !cache.isCache) {
                //看板没有且图表没有，将数据集的缓存设置载入---dataset.data
            }
            self.getDataSeries(dataset.data.datasource,
                dataset.data.query,
                dataset.data.dtpId,
                dataset.data.isDtpManager,
                widget.datasetId,
                widget.config,
                callback,
                reload,
                cache,
                datasetData,
                widget
                )
        },err=>{
            callback({errMsg:err});
        })

    }

    //疑似私用方法----getWidgetDataSeries使用1次     分支----多源和单源数据集分别调用获取数据的方法
    getDataSeries = function (datasource, query, dtpId, isDtpManager, datasetId, chartConfig, callback, reload, cache,datasetDetail,widget) {
        let self = this;
        self.getDatasetDetailNoDs(datasetId,datasetDetail).then(function (datasetData) {
            if(datasetData.dataset.type=='1'){
                if(widget&&widget.isPage){
                    callback({errMsg:"多源数据集不支持局部预览!"});
                    return;
                }
                self.getMergeDataSeries(datasetId,widget,datasetDetail, callback, reload, cache);
            }else{
                self.getSingleDataSeries(datasource, query, dtpId, isDtpManager, datasetId, chartConfig, callback, reload, cache,datasetDetail,widget.isPage);
            }
        })
    }
    // 疑似私用方法----getDataSeries使用1次        多源数据集获取数据的方法
    getMergeDataSeries = function(datasetId,widget,datasetData, callback, reload, cache){
        let self = this,
            chartConfig = util.clone(widget.config),
            dataset = util.clone(datasetData.dataset);
        dataset.data = JSON.parse(dataset.data);
        var assIndexArr = dataContextMerge.configHasAssColumn(chartConfig, dataset.data );
        if(assIndexArr.length == 0){
            callback({errMsg:"多源数据集中请至少选择一个关联字段!"});
            return;
        }
        this.updateConfig(chartConfig);
        self.linkDataset(datasetId, chartConfig,datasetData).then(function () {
            var datasetIds = dataset.data.datasetIds;
            //依据子数据集拆分图表配置
            var childrensConfig = dataContextMerge.getChildrenDatasetConfig(datasetIds,chartConfig,datasetId,dataset.data);
            let sections = [];

            _.map(datasetData.childrens, function (e, index) {
                sections.push(new Promise((resolve, reject) => {
                    var config = childrensConfig[index];
                    //将可选表达式转换为指标名的运算
                    var dataSeries = dataContextUtil.parseDataSeries(config);
                    var mydata = JSON.parse(e.dataset.data);
                    var query = mydata.query;
                    var datasource = mydata.datasource;
                    var isDtpManager = mydata.isDtpManager;
                    var dtpId = mydata.dtpId;
                    var cfg = {
                        rows: [],
                        columns: [],
                        filters: []
                    };
                    cfg.rows = dataContextUtil.getDimensionConfig(config.keys);
                    cfg.columns = dataContextUtil.getDimensionConfig(config.groups);
                    cfg.columns = cfg.columns.concat(dataContextMerge.getAssColumnsNoInConfig(dataset.data.assFields,cfg,index,assIndexArr));
                    cfg.filters = dataContextUtil.getDimensionConfig(config.filters);
                    cfg.filters = cfg.filters.concat(dataContextUtil.getDimensionConfig(config.boardFilters));
                    cfg.filters = cfg.filters.concat(dataContextUtil.getDimensionConfig(config.boardWidgetFilters));
                    cfg.values = util.map(dataSeries, function (s) {
                        return {
                            column: s.name,
                            aggType: s.aggregate
                        };
                    });
                    //多源数据集中表达式所属数据集用-d1 -d2来进行标志
                    cfg.values = dataContextMerge.getCfgFilter(cfg.values, index);
                    // if (!existData) {
                    //     return;
                    // }
                    var reqparams = {
                        datasourceId: datasource,
                        query: JSON.stringify(query),
                        dtpId: dtpId,
                        isDtpManager: isDtpManager,
                        datasetId: e.dataset.id,
                        cfg: JSON.stringify(cfg),
                        reload: reload
                    };
                    dataService.getAggregateData(reqparams, cache).then(function (data) {
                        resolve(data);
                    },err=>{
                        callback({errMsg:err.head.errorMsg});
                    });
                }))
            })
            Promise.all(sections).then((results) => {
                // var parentChartConfig = util.clone(widget.config);
                //获取合并的数据
                var mergeResult = dataContextMerge.getMergeData(results, dataset, widget, datasetIds);
                //移除多源数据集图表配置中为区分表达式所属数据数据集中定义的标志浮:-d
                chartConfig.values = dataContextMerge.removeExpSign(chartConfig.values, datasetIds);
                var backResult = dataContextMerge.castMergeRawData2Series(mergeResult, chartConfig);
                if (!backResult) {
                    callback(null,util.clone(datasetData));
                }
                backResult.chartConfig = chartConfig;
                if (!util.isUndefined(dataset.id)) {
                    self.getDrillConfig(dataset.id, chartConfig, util.clone(datasetData)).then(function (c) {
                        backResult.drill = {
                            config: c
                        };
                        callback(backResult, util.clone(datasetData));
                    });
                } else {
                    callback(backResult, util.clone(datasetData));
                }
            });
        });
    }
    // 疑似私用方法----getDataSeries使用1次         单源数据集获取数据
    getSingleDataSeries = function (datasource, query, dtpId, isDtpManager, datasetId, chartConfig, callback, reload, cache,detasetDetails,isPage) {
        let self = this;
        chartConfig = util.clone(chartConfig);
        this.updateConfig(chartConfig);
        self.linkDataset(datasetId, chartConfig,detasetDetails).then(function () {
            var dataSeries = dataContextUtil.parseDataSeries(chartConfig);
            var cfg = {
                rows: [],
                columns: [],
                filters: []
            };
            cfg.rows = dataContextUtil.getDimensionConfig(chartConfig.keys);
            cfg.columns = dataContextUtil.getDimensionConfig(chartConfig.groups);
            cfg.filters = dataContextUtil.getDimensionConfig(chartConfig.filters);
            cfg.filters = cfg.filters.concat(dataContextUtil.getDimensionConfig(chartConfig.boardFilters));
            cfg.filters = cfg.filters.concat(dataContextUtil.getDimensionConfig(chartConfig.boardWidgetFilters));
            cfg.values = util.map(dataSeries, function (s) {
                return {
                    column: s.name,
                    aggType: s.aggregate
                };
            });
            // if (!existData) {
            //     return;
            // }
            var reqparams = {
                datasourceId: datasource,
                query: JSON.stringify(query),
                dtpId: dtpId,
                isDtpManager: isDtpManager,
                datasetId: datasetId,
                cfg: JSON.stringify(cfg),
                reload: reload,
                isPage:isPage
            };
            dataService.getAggregateData(reqparams, cache).then(function (data) {
                var result = dataContextSingle.castRawData2Series(data, chartConfig);
                if (chartConfig.chart_type == "kpi2") {
                    var copyCfg = util.clone(cfg);
                    // copyCfg.filters = [];
                    for(let i = 0; i< copyCfg.filters.length; i++) {
                        if(copyCfg.filters[i].columnName === '年' || copyCfg.filters[i].columnName === '月') {
                            copyCfg.filters.splice(i, 1);
                            i--;
                        }
                    }
                    var reqparams = {
                        datasourceId: datasource,
                        query: JSON.stringify(query),
                        dtpId: dtpId,
                        isDtpManager: isDtpManager,
                        datasetId: datasetId,
                        cfg: JSON.stringify(copyCfg),
                        reload: reload,
                        isPage:isPage
                    };
                    dataService.getAggregateData(reqparams, cache)
                        .then(function (res) {
                            var totalResult = dataContextSingle.castRawData2Series(res, chartConfig);
                            //get index
                            var len = result['keys'].length;
                            if (len !== 0) {
                                totalResult.currentKey = result['keys'][len - 1];
                            } else {
                                var firstValue, secondValue;
                                for (var j = 0; j < chartConfig.boardFilters.length; j++) {
                                    if (chartConfig.boardFilters[j]['col'] == chartConfig.keys[0]['col']) {
                                        firstValue = chartConfig.boardFilters[j].values;
                                    }
                                    if (chartConfig.boardFilters[j]['col'] == chartConfig.keys[1]['col']) {
                                        secondValue = chartConfig.boardFilters[j].values;
                                    }
                                }
                                var lastKeys = [...firstValue, ...secondValue];
                                totalResult.currentKey = lastKeys;
                                totalResult['keys'].push(lastKeys);
                                totalResult['data'][0].push('暂无数据');
                            }
                            if (!totalResult) {
                                return callback(null,detasetDetails);
                            }
                            totalResult.chartConfig = chartConfig;
                            if (!util.isUndefined(datasetId)) {
                                self.getDrillConfig(datasetId, chartConfig).then(function (c) {
                                    totalResult.drill = { config: c };
                                    callback(totalResult,detasetDetails);
                                });
                            } else {
                                callback(totalResult,detasetDetails);
                            }

                        },err=>{
                            callback({errMsg:err.head.errorMsg});
                        })
                } else {
                    if (!result) {
                        return callback(null,detasetDetails);
                    }
                    result.chartConfig = chartConfig;
                    if (!util.isUndefined(datasetId)) {
                        self.getDrillConfig(datasetId, chartConfig).then(function (c) {
                            result.drill = { config: c };
                            callback(result,detasetDetails);
                        });
                    } else {
                        callback(result,detasetDetails);
                    }
                }
            },err=>{
                callback({errMsg:err.head.errorMsg});
            });
        });
    }
    //chartContent使用1次----无数据处理
    getDrillPath = function (datasetId, id,datasetDetail) { //获取钻取数据的下转的 元信息  参数：数据集id  层级id
        let self = this;
        return new Promise(function (resolve, reject) {
            self.getDatasetDetailNoDs(datasetId,datasetDetail).then(function (datasetData) {
                if (!datasetData || !datasetData.dataset) {
                    // existData = false;
                    return Promise.resolve();
                }
                var dataset = util.clone(datasetData.dataset);
                dataset.data = JSON.parse(datasetData.dataset.data);
                var path = [];
                var level;
                util.each(dataset.data.schema.dimension, function (_e) { //取得对应层级的栏目名称
                    if (_e.type == 'level') { // 为层级
                        util.each(_e.columns, function (_c) {
                            if (_c.id == id) {
                                path = _e.columns;
                                level = _e;
                            }
                        });
                    }
                });
                path = util.map(path, function (e) {
                    return {
                        id: e.id,
                        alias: e.alias, //别名
                        col: e.column,
                        level: level.alias, //层级的别名
                        type: '=',
                        values: [],
                        sort: 'asc'
                    }
                });
                resolve(path);
            });
        })

        // return deferred.promise;
    }
    // 疑似私用方法
    getDrillConfig = function (datasetId, chartConfig) {
        let self = this;
        return new Promise(function (resolve, reject) {
            self.getDatasetDetailNoDs(datasetId).then(function (datasetData) {
                if (!datasetData || !datasetData.dataset) {
                    // existData = false;
                    return reject();
                }
                var drillConfig = {};
                var dataset = util.clone(datasetData.dataset);
                dataset.data = JSON.parse(datasetData.dataset.data);
                if (!dataset.data.schema || dataset.data.schema.dimension.length == 0) {
                    resolve(drillConfig);
                    //return deferred.promise;
                }
                var _f = function (array) {
                    util.each(array, function (c, i_array) {
                        var level;
                        var i_level;
                        util.find(dataset.data.schema.dimension, function (_e) { //获取钻取纬度信息
                            if (_e.type == 'level') {
                                return util.find(_e.columns, function (_c, _i) {
                                    if (_c.id == c.id) {
                                        level = _e;
                                        i_level = _i;
                                        return true;
                                    }
                                });
                            }
                        });
                        if (!level) {
                            return;
                        }
                        var prevIsInLevel = false;
                        if (i_array > 0 && i_level > 0) {
                            prevIsInLevel = array[i_array - 1].id == level.columns[i_level - 1].id;
                        }
                        var prevDrilled = i_array > 0 && array[i_array - 1].values.length == 1 && array[i_array - 1].type == '=';
                        var nextIsInLevel = false;
                        if (i_array < array.length - 1 && i_level < level.columns.length - 1) {
                            nextIsInLevel = array[i_array + 1].id == level.columns[i_level + 1].id;
                        }
                        var isLastLevel = i_level == level.columns.length - 1;
                        var drillDownExistIdx = 0;
                        var drillDownExist = util.find(array, function (e, i) {
                            if (i_level < level.columns.length - 1 && level.columns[i_level + 1].id == e.id) {
                                drillDownExistIdx = i;
                                return true;
                            }
                        });
                        //if next level exist in array,the level must be the next of current
                        var drillDown = drillDownExist ? drillDownExistIdx == i_array + 1 : true;
                        var up = i_level > 0 && i_array > 0 && prevIsInLevel && (i_array == array.length - 1 || !nextIsInLevel) && prevDrilled;
                        var down = (nextIsInLevel || !isLastLevel) && drillDown && (!prevIsInLevel || (array[i_array - 1].type == '=' && array[i_array - 1].values.length == 1));
                        drillConfig[c.id] = {
                            up: up,
                            down: down
                        };
                    });
                };
                _f(chartConfig.keys);
                _f(chartConfig.groups);
                resolve(drillConfig);
            });
        })
    }
    // widget使用----查询脚本
    viewQuery = function (params, callback) {
        let self = this;
        self.getDatasetDetailNoDs(params.datasetId,params.datasetDetail).then(function (datasetData) {
            if(datasetData.dataset.type=="1"){
                self.viewMergeQuery(params,datasetData, callback);
            }else{
                self.viewSingleQuery(params, callback);
            }
        })

    };
    // 疑似私用方法----viewQuery使用1次
    viewMergeQuery = function(params,datasetData,callback){
        let self = this;
        if (!datasetData || !datasetData.dataset) {
            // existData = false;
            return;
        }
        var dataset = util.clone(datasetData.dataset);
        dataset.data = JSON.parse(datasetData.dataset.data);
        var datasetIds = dataset.data.datasetIds;
        //获取多源图表拆分后的子配置项
        var childrensConfig = dataContextMerge.getChildrenDatasetConfig(datasetIds, params.config,params.datasetId,dataset.data);
        let sections = [];
        _.map(datasetData.childrens, function (e, index) {
            sections.push(new Promise((resolve, reject) => {
                var config = util.clone(childrensConfig[index]);
                //移除拆分后的数据集value
                childrensConfig[index].values = dataContextMerge.removeExpSign(childrensConfig[index].values, datasetIds);
                var detail = util.clone(e);
                self.updateConfig(config);
                self.linkDataset(datasetIds[index],config, detail).then(function () {
                    detail.dataset.data = JSON.parse(detail.dataset.data);
                    var dataSeries = dataContextUtil.parseDataSeries(config);
                    var cfg = {
                        rows: [],
                        columns: [],
                        filters: []
                    };
                    cfg.rows = dataContextUtil.getDimensionConfig(config.keys);
                    cfg.columns = dataContextUtil.getDimensionConfig(config.groups);
                    cfg.filters = dataContextUtil.getDimensionConfig(config.filters);
                    cfg.filters = cfg.filters.concat(dataContextUtil.getDimensionConfig(config.boardFilters));
                    cfg.values = util.map(dataSeries, function (s) {
                        return {
                            column: s.name,
                            aggType: s.aggregate
                        };
                    });
                    //多源数据集中表达式所属数据集用-d1 -d2来进行标志
                    //筛选出归属于第index数据集的values
                    cfg.values = dataContextMerge.getCfgFilter(cfg.values, index);
                    var reqparams = {
                        datasourceId: detail.dataset.data.datasource,
                        query: JSON.stringify(params.query),
                        datasetId: detail.dataset.id,
                        cfg: JSON.stringify(cfg),
                    };
                    dataService.getViewAggDataQuery(reqparams).then(function (response) {
                        resolve(response[0]);
                    },err=>{
                        resolve("");
                    });
                });
            }));
        });
        Promise.all(sections).then((results) => {
            let sqlString ="";
            _.map(results,function(r){
                sqlString = sqlString + r +"\n\t";
            })
            callback(sqlString);
        });
    }
    // 疑似私用方法----viewQuery使用1次
    viewSingleQuery =function(params, callback){
        params.config = util.clone(params.config);
        this.updateConfig(params.config);
        this.linkDataset(params.datasetId, params.config).then(function () {
            var dataSeries = dataContextUtil.parseDataSeries(params.config);
            var cfg = {
                rows: [],
                columns: [],
                filters: []
            };
            cfg.rows = dataContextUtil.getDimensionConfig(params.config.keys);
            cfg.columns = dataContextUtil.getDimensionConfig(params.config.groups);
            cfg.filters = dataContextUtil.getDimensionConfig(params.config.filters);
            cfg.filters = cfg.filters.concat(dataContextUtil.getDimensionConfig(params.config.boardFilters));
            cfg.values = util.map(dataSeries, function (s) {
                return {
                    column: s.name,
                    aggType: s.aggregate
                };
            });
            var reqparams = {
                datasourceId: params.datasource,
                query: JSON.stringify(params.query),
                datasetId: params.datasetId,
                cfg: JSON.stringify(cfg),
            };
            dataService.getViewAggDataQuery(reqparams).then(function (response) {
                callback(response[0]);
            });
        });
    }
    // widget,dataset调用
    getColumns = function (option) {
        //多源数据集新建时不存在datasetId,只有子数据集Ids
        if(option && option.childIds){
            dataService.getColumnsByIds({childIds:option.childIds}).then(function (response) {
                option.callback(response);
            });
        }else{
            var reqparams = {
                datasourceId: option.datasource,
                query: option.query ? JSON.stringify(option.query) : null,
                datasetId: option.datasetId,
                reload: option.reload,
                isDtpManager: option.isDtpManager,
                dtpId: option.dtpId,
                datasetIds:option.datasetIds
            };
            dataService.getColumns(reqparams).then(function (response) {
                option.callback(response);
            });
        }
    };
    // 疑似私用方法----补充属性进行的适配
    updateConfig = function (config) {
        var toFilterConfig = function (e) {
            if (util.isString(e)) {
                return { col: e, type: 'eq', values: [] };
            }
            return e;
        };
        config.keys = util.map(config.keys, toFilterConfig);
        config.groups = util.map(config.groups, toFilterConfig);
        if (!config.filters) {
            config.filters = [];
        }
        switch (config.chart_type) {
            case 'pie':
                //the new pie
                if (!config.groups) {
                    config.groups = [];
                }
                break;
            case 'line':
                if (!config.valueAxis) {
                    config.valueAxis = 'vertical';
                }
                break;
        }
    }
    // 预览数据集数据----数据集列表和详情使用
    previewDatasetData = function(params,callback){
        dataService.previewDatasetData(params).then(function (response) {
            callback(response);
        },err=>{
            callback(err.head);
        });
    }
}

const dataContext = new DataContext();

export default dataContext;


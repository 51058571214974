import dtpManagerDetailTpl from './dtpManagerDetail.html'
import {dtpManagerService,behaviorLogService} from '../../../server'
import windowTpl from '../../../common/theme/components/modal/window.html'
import './_dtpManager.scss'

export default  function dtpManagerCtrl( ModalUtils,$scope,  $state,  $window, $uibModal,toastr) {
    // var translate = $filter('translate');
    $scope.isLoading = true;
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 18,
        onChange: function () {
            $scope.getDtpList(); //重新加载
        }
    };

    //获取当前的分辨率,根据分辨率调整每页数量
    $scope.$watch('window.innerWidth', function () {
        if ($window.innerWidth > 1800) {
            $scope.paginationConf.itemsPerPage = 21;
        }
        if (1500 < $window.innerWidth && $window.innerWidth <= 1800) {
            $scope.paginationConf.itemsPerPage = 18;
        }
        if ($window.innerWidth <= 1500) {
            $scope.paginationConf.itemsPerPage = 15;
        }
    });

    // 前往详情页
    $scope.gotoDatasource = function (dtp) {
        $state.go('dtpDatasource', {
            dtpJson: dtp.id
        });
    }


    //获取Dtp列表
    $scope.getDtpList = function () {
        $scope.isLoading = true;
        var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage
        }
        dtpManagerService.getDtpList(params).then(function (response) {
            $scope.isLoading = false;
            if (response.status != '1') {
                ModalUtils.alert(response.msg, "modal-warning", "lg");
            } else {
                $scope.dtpManagerList = response.data.listdata;
                $scope.paginationConf.totalItems = response.data.totolCount;
                $scope.$apply();
                if($scope.dtpManagerList.length==0)return;
                judgeIsConnect();
            }
        })
    }

    var judgeIsConnect = function(){
        angular.forEach($scope.dtpManagerList,function(dtp){
            dtp.dtpDbConfigModel = null;
            dtpManagerService.judgeIsConnect(dtp).then(function(response){
                dtp.isConnect = response.data;
            },err =>{
                dtp.isConnect = false;
            })
        })
    }

    $scope.deleteDtp = function (dtpManager) {
        ModalUtils.confirm("确定要删除DTP吗?", "modal-warning", "lg", function () {
            dtpManagerService.deleteDtp(dtpManager).then(function (response) {
                if (response.status != '1') {
                    toastr.error(response.msg || "删除DTP失败");
                    return;
                } else {
                    toastr.success("成功");
                    $scope.getDtpList();
                }
            })
        })
    }
   
    $scope.gotoDetail = function (option, dtpManager) {
        var uibModal = $uibModal.open({
            template: dtpManagerDetailTpl,
            windowTemplate: windowTpl,
            backdrop: false,
            windowClass: 'dtp-manager-detail',
            size: 'lg',
            resolve: {
                totalItems: $scope.paginationConf.totalItems,
                dtpManagerList: function () {
                    return $scope.dtpManagerList;
                },  
                insertBehaviroLog:function(){
                    return function (dtpManager,callback) {
                        try { behaviorLogService.insertLog({module:"dtp",function:"查看DTP",name:dtpManager.label}).then(function(){
                            callback();
                        })}catch(error){};
                    };
                }
            },
            controller: ['$scope', '$uibModalInstance', 'totalItems', 'dtpManagerList','toastr','insertBehaviroLog',function ($scope, $uibModalInstance, totalItems, dtpManagerList,toastr,insertBehaviroLog) {
                $scope.option = option;
                $scope.dtpManager = angular.copy(dtpManager);
                $scope.buttonClick = true;
                insertBehaviroLog($scope.dtpManager,function(){});
                if(dtpManager && dtpManager.type!=null)$scope.dtpManager.type = angular.toJson(dtpManager.type);
                $scope.getDtpByAppkey =function(data){
                    dtpManagerService.getDtpByAppkey(data).then(function (response) {
                        if (response.status != '1') {
                            toastr.error(response.msg || '获取数据失败!');
                            return;
                        } else {
                            if(!response.data.baseurl &&  !response.data.clnodeIp){
                                toastr.error("获取数据失败!");
                                return;
                            }
                            toastr.success("成功");
                            $scope.dtpManager.type = angular.toJson(response.data.type);
                            $scope.dtpManager.baseurl = response.data.baseurl;
                            $scope.dtpManager.clnodeIp = response.data.clnodeIp;
                            $scope.dtpManager.label = response.data.label; 
                        }
                    })
                }

                $scope.editDtp = function (data) {
                    $scope.buttonClick = true;
                    if (!data || !data.appkey || data.type==null || !data.label) {
                        ModalUtils.alert("必填项不能为空", "modal-warning", "sm");
                        return;
                    }
                    if (data.type == '1' && !data.baseurl) {
                        ModalUtils.alert("域名不能为空", "modal-warning", "sm");
                        return;
                    }
                    if (data.type == '0' && !data.clnodeIp) {
                        ModalUtils.alert("节点ip不能为空", "modal-warning", "sm");
                        return;
                    }
                    saveDtp(data);
                };

                var saveDtp = function (data) {
                    dtpManagerService.saveDtp(data).then(function (response) {
                        if (response.status != '1') {
                            toastr.error(body.msg);
                            return;
                        } else {
                            toastr.success("成功");
                            $scope.buttonClick = false;
                            $scope.isLoading = true;
                            getDtpList();
                        }
                    })
                }
                var getDtpList = function () {
                    dtpManagerService.getDtpList().then(function (response) {
                        if (response.status != '1') {
                            toastr.error(response.msg);
                            return;
                        } else {
                            dtpManagerList = response.data.listdata;
                            totalItems = response.data.totolCount;
                            $scope.close(true);
                        }
                    })
                }

                $scope.close = function (isEdit) {
                    //传递参数给原页面
                    $uibModalInstance.close({
                        "isEdit": isEdit,
                        "totalItems": totalItems,
                        "dtpManagerList": dtpManagerList
                    });
                };
            }]
        })

        //接收窗口传递的参数
        uibModal.result.then(function (result) {
            //单纯的关闭窗口不需要,重新给list赋值,否则会导致页面显示闪烁问题
            if (result && result.isEdit) {
                $scope.dtpManagerList = result.dtpManagerList;
                $scope.paginationConf.totalItems = result.totalItems;
                judgeIsConnect();
            }
        });
    }
}


import backTopTpl from './backTop.html'
import './_backTop.scss';
require('./lib/jquery.backTop.min.js')
function backTop() {
  return {
    restrict: 'E',
    template: backTopTpl,
    controller: function () {
      $('#backTop').backTop({
        'position': 200,
        'speed': 100
      });
    }
  };
}
export default angular.module('BlurAdmin.theme.components.backTop',[])
  .directive('backTop', backTop).name;
import baSidebar from './baSidebar.directive';
import baSidebarServiceProvider from './baSidebar.service';
import BaSidebarCtrl from './BaSidebarCtrl'
import { baSidebarToggleMenu, baSidebarCollapseMenu, baSidebarTogglingItem, BaSidebarTogglingItemCtrl, baUiSrefTogglingSubmenu, baUiSrefToggler } from './baSidebarHelpers.directive'
var basidebar = angular.module('BlurAdmin.theme.components.basidebar', [])
    .directive('baSidemenu', function () {
        return {
            template: require('./ba-sidemenu.html'),
            scope: true,
            link: function ($scope, element, attr, ctrl, linker) {
                 $scope.menutype=attr.menutype;
                //$scope:指令所在的作用域，有关上下文
                //element：指令元素的封装，可以调用angular封装的简装jq方法和属性
                //attr：指令元素的属性的集合
                //ctrl：用于调用其他指令的方法,指令之间的互相通信使用，需要配合require，刚刚演示了
                //linker:用于transClude里面嵌入的内容
            }
        }
    })
    .provider('baSidebarService', baSidebarServiceProvider)
    .directive('baSidebar', ['$timeout','baSidebarService','baUtil',baSidebar])
    .controller('BaSidebarCtrl', ['$scope','baSidebarService',BaSidebarCtrl])
    .directive('baSidebarToggleMenu', ['baSidebarService',baSidebarToggleMenu])
    .directive('baSidebarCollapseMenu', ['baSidebarService',baSidebarCollapseMenu])
    .directive('baSidebarTogglingItem', baSidebarTogglingItem)
    .controller('BaSidebarTogglingItemCtrl', ['$scope','$element','$attrs','$state','baSidebarService',BaSidebarTogglingItemCtrl])
    .directive('baUiSrefTogglingSubmenu', baUiSrefTogglingSubmenu)
    .directive('baUiSrefToggler', ['baSidebarService',baUiSrefToggler]).name;
export default basidebar;
import icon_custom from './custom.png'
import icon_custom_active from './custom-active.png'
var config = {
    rule: {
        keys: -1,
        groups: -1,
        filters: -1,
        values: -1
    },
    info:{
        name: '自定义图表',
        value: 'custom',
        // class: 'cCUSTOM',
        icon: {
            icon: icon_custom,
            icon_active: icon_custom_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE'
    }
}
export default config;
/**
 * mySetToolSelectInputbox
 * 
 * boreDataTitle: 输入框前面的说明文---限制5个字
 * boreDataTip: 移入显示说明文---tip
 * boreDataPlaceholder: 无内容时显示的文本
 * 
 * boreDataSelects: 列表的字符串--不同项用";"隔开----name
 * boreDataSelectsValue: 列表值的字符串--不同项用";"隔开----value
 * boreDataSelectsInit: 初始化，同上
 * 
 * boreDataValue: 输入框的值
 * 
 * boreDataIcon: 选择下拉框的图标
 */

export default function mySetToolSelectExpSearch(){

    return {
        restrict: 'EA',
        template: ` <div class="bore-set-tool">
                        <div class="bore-set-tool-title" ng-click="inputFocus($event)">{{boreDataTitle}}</div>
                        <div class="bore-set-tool-content">
                            <div class="bore-set-tool-select-input">
                                <input type="text" ng-model="boreDataValue" class="select-input-box" placeholder="{{boreDataPlaceholder}}"/>
                                <div class="icon-box" ng-class = "{'show':show}" ng-click="selecting($event); $event.preventDefault()">
                                    <i ng-class="iconClass"></i>
                                </div>
                                <ul class="select-list" ng-show="show">
                                    <li ng-repeat="item in boreDataSelects track by $index" ng-click="checked($index); $event.preventDefault()">
                                        {{item}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="bore-set-tool-tip" ng-class="{'show':boreDataTip?true:false}">
                            <span>?</span>
                            <span class="tip-content">{{boreDataTip}}</span>
                        </div>
                    </div>`,
        replace: true,
        scope: {
            boreDataValue: '='
        },
        link: function(scope, element, attrs) {
            scope.inputFocus = function($event){
                $($event.target).next().children().children()[0].focus();
            };
            //获取显示和存储的列表值
            scope.boreDataSelects = attrs.boreDataSelects.split(";");
            scope.boreDataSelectsValue = attrs.boreDataSelectsValue.split(";");

            scope.boreDataTitle = attrs.boreDataTitle ? attrs.boreDataTitle : "";
            scope.boreDataTip = attrs.boreDataTip ? attrs.boreDataTip : "";
            scope.boreDataPlaceholder = attrs.boreDataPlaceholder ? attrs.boreDataPlaceholder : "";

            //下拉按钮图标样式
            scope.iconClass = attrs.boreDataIcon ? attrs.boreDataIcon : "default-icon";
            
            //初始化选中值----有值即，无值init值
            if(scope.boreDataValue === undefined && attrs.boreDataInit !== undefined ){
                scope.boreDataValue = attrs.boreDataInit;
            }

            scope.show = false;
            //开始/取消选择的方法--点击下拉框
            scope.selecting = function($event){
                scope.show = !scope.show;
            };

            //选中的方法
            scope.checked = function(index){
                scope.boreDataValue = scope.boreDataSelectsValue[index];
                scope.show = false;
            }

        }
    };
}
// 获取随机数
function getRandom(){
    return parseInt(Math.random() * 1000000);
}

/**
 * 获取单元格的位置-----excel标识符
 * 
 * @param {Number} row     行的索引
 * @param {Number} column  列的索引
 * @return {String}                     eg: (1,1) => 'B2'
 * */
function indexToCell(row, column){
    return formulaParser.toLabel({index: row}, {index: column});    
}

/**
 * 获取单元格的列号
 * 
 * @param {Number} index     列的索引
 * @return {String}          eg: 5 => 'E'
 * */
function columnIndexToLabel(index){
    return formulaParser.columnIndexToLabel(index);
}

/**
 * 获取单元格的行号
 * 
 * @param {Number} index     行的索引
 * @return {String}          eg: 5 => '6'
 * */
function rowIndexToLabel(index){
    return formulaParser.rowIndexToLabel(index);
}

/**
 * exp中的Math常量的转换-----常量中间不能用.连接
 * 
 * @param {String} exp
 * @return {String}
 * */ 
function expMathconvert(exp){
    var regArr = [/Math.E/g, /Math.LN2/g, /Math.LN10/g, /Math.LOG2E/g, /Math.LOG10E/g, /Math.PI/g, /Math.SQRT1_2/g, /Math.SQRT2/g, /Math.max/g, /Math.min/g],
        strArr = ['E()', 'LN2()', 'LN10()', 'LOG2E()', 'LOG10E()', 'PI()', 'SQRT1_2()', 'SQRT2()', 'MY_Math_MAX', 'MY_Math_MIN'];
    var rExp = exp;
    for(let i = 0; i < regArr.length; i++) {
        rExp = rExp.replace(regArr[i], strArr[i]);
    }
    return rExp;
}

/** 表达式处理----获取整列数据的方法被替换为值----放在表达式处理1后面
 * 
 * @param {String} exp      表达式
 * @param {RegExp} reg      全局模式的正则表达式
 * @param {BoreFormula} parser   boreFormula的实例
 * @param {Number} to       结束的行号-----即数据的长度
 * @param {String} name     取代的方法名------空则不替换
 * 
 * @return {String} newExp
 * 
 * eg. ('TOTAL(A{_#rowIndex})', reg, parser, 20, 'SUM') => SUM(A1:A20) => value
 * */ 
function getExpFuncToValue(exp, reg, parser, to, name) {
    if( !parser || !reg || !to ) {
        return exp;
    }
    var newExp = exp;
    // 按匹配到的顺序一个个处理
    var result = newExp.match(reg) || [];
    for(let i = 0; i < result.length; i++) {
        let str = result[i];
        // 存在新方法名时，用新方法名的取代
        if(name) {
            let fName = str.substring(0, str.indexOf('('));
            str = str.replace(fName, name);
        }
        // 将单元格的替换为一列的范围
        let result1 = str.match(/[A-Z]+\{\_\#rowIndex\}/g) || [];
        for(let j = 0; j < result1.length; j++) {
            let column = result1[j].replace('{_#rowIndex}', '');
            let area = column + 1 + ':' + column + to;
            str = str.replace(result1[j], area);
        }
        //每一个方法都计算为数值
        var value = parser.getResult(str);
        newExp = newExp.replace(result[i], value);
    }
    return newExp;
}
// 获取整列数据的方法----表达式第1次处理后直接替换为值
const funcOfGetColAll = [
    {
        reg: /(TOTAL)\(.*?\)/,
        do: function(exp, parser, dataLength){
            return getExpFuncToValue(exp, /(TOTAL)\(.*?\)/g, parser, dataLength, 'SUM');
        }
    }
];

/** 表达式处理----累进方法的第1次处理，列号{_#rowIndex} ==> 列号{_#fromIndex}:列号{_#toIndex}
 * 
 * @param {String} exp      表达式
 * @param {RegExp} reg      全局模式的正则表达式
 * @param {String} funcName 方法名称
 * @param {Array} keyIndexInCL keys在colList中的索引----用于替换方法中的维度字段----[{name, index}]
 * 
 * @return {String} newExp
 * 
 * eg. ('OVERLAY(F{_#rowIndex})', reg, {}) => OVERLAY('标识符',F{_#fromIndex}:F{_#toIndex})
 * eg. ('OVERLAYK(F{_#rowIndex},{年})', reg, [{name: '年', index: 4}]) => OVERLAY('标识符',F{_#fromIndex}:F{_#toIndex},4)
 * */ 
function getExpOverlay1(exp, reg, funcName, keyIndexInCL) {
    if( !reg ) {
        return exp;
    }
    var newExp = exp;
    // 按匹配到的顺序一个个处理
    var result = newExp.match(reg) || [];
    for(let i = 0; i < result.length; i++) {
        let str = result[i];
        // 将单元格的替换为一列的范围
        let result1 = str.match(/[A-Z]+\{\_\#rowIndex\}/g) || [];
        for(let j = 0; j < result1.length; j++) {
            let column = result1[j].replace('{_#rowIndex}', '');
            let area = column + '{_#fromIndex}:' + column + '{_#toIndex}';
            str = str.replace(result1[j], area);
        }
        // [字段]替换为数字，没找到的去掉
        let result2 = str.match(/\,\s*\[.*?\]/g) || [];
        for(let k = 0; k < result2.length; k++) {
            let kName = result2[k].substring((result2[k].indexOf('\[') + 1), result2[k].length - 1);
            if(keyIndexInCL[kName] != undefined) {
                str = str.replace(result2[k], ',' + keyIndexInCL[kName]);
            } else {
                str = str.replace(result2[k], '');
            }
        }
        let randomName = funcName + '_' + getRandom();   //为每个方法增加一个标识符，作为第一个参数
        randomName = "('" + randomName + "',";
        str = str.replace('(', randomName);
        newExp = newExp.replace(result[i], str);
    }
    return newExp;
}
/** 表达式处理----累进方法的第2次处理----列号{_#fromIndex}:列号{_#toIndex} ==> 列号行号:列号行号
 * 
 * @param {String} exp      表达式
 * @param {RegExp} reg      全局模式的正则表达式
 * @param {Number} from     开始的行号----最小为1
 * @param {Number} to       结束的行号
 * @param {String} name     取代的方法名------空则不替换
 * @param {Array} params    方法内被添加到最后面的参数 
 * 
 * @return {String} newExp
 * 
 * eg. ('OVERLAY('标识符',F{_#fromIndex}:F{_#toIndex})', reg, 1, 6, '', [3,1]) => OVERLAY('标识符',F1:F6,3,1)
 * */ 
function getExpOverlay2(exp, reg, from, to, name, params) {
    if( !from || !reg || !to ) {
        return exp;
    }
    var newExp = exp;
    // 按匹配到的顺序一个个处理
    var result = newExp.match(reg) || [];
    for(let i = 0; i < result.length; i++) {
        let str = result[i];
        // 存在新方法名时，用新方法名的取代
        if(name) {
            let fName = str.substring(0, str.indexOf('('));
            str = str.replace(fName, name);
        }
        // 开始的行号替换
        while( str.indexOf('{_#fromIndex}') > -1 ) {
            str = str.replace('{_#fromIndex}', from);
        }
        // 结束的行号替换
        while( str.indexOf('{_#toIndex}') > -1 ) {
            str = str.replace('{_#toIndex}', to);
        }
        // 需要接到最后面的参数
        if(params && params.length > 0) {
            str = str.replace('(', '(' + params.join(',') + ',');
        }
        newExp = newExp.replace(result[i], str);
    }
    return newExp;
}
// 累进的方法
const funcOfOverlay = [
    // 取和----按系列取和
    {
        reg: /(TOTALG)\(.*?\)/,
        do1: function(exp, keyIndexInCL){
            return getExpOverlay1(exp, /(TOTALG)\(.*?\)/g, 'totalg', {});
        },
        do2: function(exp, currentRowIndex, dataLength, params){
            return getExpOverlay2(exp, /(TOTALG)\(.*?\)/g, 1, dataLength, '', [params.curGroupStr, params.groupSameIndex, params.groupsIndexStr]);
        }
    },
    // 累加----无过滤
    {
        reg: /(OVERLAY)\(.*?\)/,
        do1: function(exp, keyIndexInCL){
            return getExpOverlay1(exp, /(OVERLAY)\(.*?\)/g, 'overlay', {});
        },
        do2: function(exp, currentRowIndex, dataLength, params){
            var to = rowIndexToLabel(currentRowIndex);
            return getExpOverlay2(exp, /(OVERLAY)\(.*?\)/g, 1, to, '', []);
        }
    },
    // 累加----每个系列单独累加
    {
        reg: /(OVERLAYG)\(.*?\)/,
        do1: function(exp, keyIndexInCL){
            return getExpOverlay1(exp, /(OVERLAYG)\(.*?\)/g, 'overlayg', {});
        },
        do2: function(exp, currentRowIndex, dataLength, params){
            var to = rowIndexToLabel(currentRowIndex);
            return getExpOverlay2(exp, /(OVERLAYG)\(.*?\)/g, 1, to, '', [params.groupSameIndex]);
        }
    },
    // 累加----每个（系列-指定的key）
    {
        reg: /(OVERLAYK)\(.*?\)/,
        do1: function(exp, keyIndexInCL){
            return getExpOverlay1(exp, /(OVERLAYK)\(.*?\)/g, 'overlayk', keyIndexInCL);
        },
        do2: function(exp, currentRowIndex, dataLength, params){
            var to = rowIndexToLabel(currentRowIndex);
            return getExpOverlay2(exp, /(OVERLAYK)\(.*?\)/g, 1, to, '', [params.curGroupStr]);
        }
    }
];
// 字符串中获取其中的索引----''=>[], '1-2'=>['1','2']
function getStrIndex(str){
    if(str.length == 0) {
        return [];
    } else {
        return str.split('-');
    }
}

var Parser = formulaParser.Parser;
// formulajs中的错误类型
const boreError = {
    nil : new Error('#NULL!'),
    div0 : new Error('#DIV/0!'),
    value : new Error('#VALUE!'),
    ref : new Error('#REF!'),
    name : new Error('#NAME?'),
    num : new Error('#NUM!'),
    na : new Error('#N/A'),
    error : new Error('#ERROR!'),
    data : new Error('#GETTING_DATA')
};
const FLOAT_REG = /^\-?\d+(\.\d+)?(\e(\-)?\d+)?$/;
class BoreFormula extends Parser {
    constructor(data){
        super();
        this.data = data;
        this.overlayData = {};      //存储累进方法的值----key：方法名_随机数_groups值_keys值
        this.parser = new Parser();
        var _this = this;
        this.parser.on('callRangeValue', function(startCellCoord,endCellCoord,done){
            var fragment = [];
            for (var row = startCellCoord.row.index; row <= endCellCoord.row.index; row++) {
                var rowData = _this.data[row] || [];
                var colFragment = [];
                for (var col = startCellCoord.column.index; col <= endCellCoord.column.index; col++) {
                    colFragment.push(rowData[col]);
                }
                fragment.push(colFragment);
            }
            if (fragment) {
                done(fragment);
            }
        });
        this.parser.on('callCellValue', function(cellCoord,done){
            var value;
            var rowData = _this.data[cellCoord.row.index] || [];
            value = rowData[cellCoord.column.index];
            if(value === undefined)value = null;    //undefined的结果是true, 判断时为false，待分析
            done(value);
        });
        // 修改已有方法----内置方法只能通过该方法实现
        this.parser.on('callFunction', function( name, params, done ){
            // 除法的方法
            if(name === 'DIVIDE') {
                if (params.length < 2 ) {
                    done(boreError.na);
                } else {
                    let dividend = parseFloat(params[0]),
                        divisor  = parseFloat(params[1]);
                    if( isNaN(dividend) || isNaN(divisor) ) {
                        done(boreError.value);
                    } else {
                        if(divisor === 0 && params[2] !== undefined ) {
                            done(params[2]);
                        } else {
                            let result = dividend / divisor;
                            done(result);
                        }
                    }
                }
            }
        });

        // 新方法----获取整列类型
        // this.parser.setFunction('TOTAL', function(params) {
        //     // 该方法被替换为SUM计算
        // });

        // 新方法--叠加类----获取一个系列所有数的和----分列取和
        this.parser.setFunction('TOTALG', function(params) {
            var id = params[3],
                curGroupStr = params[0];
            id = id + '_' + curGroupStr;
            if(_this.overlayData[id]) {
                return _this.overlayData[id];
            }
            var groupSameIndex = params[1],                // 求和的第一个值的索引
                groupsIndexArr = getStrIndex(params[2]),
                tData = params[4],                         // 全部的1列数据
                lastIndex = tData.length;                  // 求和的最后一个数的索引+1
            for(let i = groupSameIndex + 1; i < tData.length; i++) {
                let curRowData = _this.data[i];
                let curGroupArr = [];
                for(let k = 0; k < groupsIndexArr.length; k++) {
                    curGroupArr.push(curRowData[groupsIndexArr[k]]);
                }
                if(curGroupArr.join('-') != curGroupStr){
                    lastIndex = i;
                    break;
                }
            }
            tData = tData.slice(groupSameIndex, lastIndex);
            var exp = 'SUM([' + tData + '])';     //平铺数组，包括其中的子数组
            var value = _this.parser.parse(exp);
            _this.overlayData[id] = value.result;
            return value.result;
        });
        // 新方法--叠加类----累进全部----params第2个参数是需要取和的数组
        this.parser.setFunction('OVERLAY', function(params) {
            var tData = params[1];                //需要执行求和的数据
            var exp = 'SUM([' + tData + '])';     //平铺数组，包括其中的子数组
            var value = _this.parser.parse(exp);
            return value.result;
        });
        // 新方法--叠加类----累进----分列按维度叠加
        this.parser.setFunction('OVERLAYG', function(params) {
            var groupSameIndex = params[0],              //累进的第一个值的索引
                tData = params[2];                         //需要执行求和的数据
            if(groupSameIndex > -1) {
                tData.splice(0, groupSameIndex);
            }
            var exp = 'SUM([' + tData + '])';     //平铺数组，包括其中的子数组
            var value = _this.parser.parse(exp);
            return value.result;
        });
        // 新方法--叠加类----累进----分列及指定key按剩余的维度进行
        this.parser.setFunction('OVERLAYK', function(params) {
            var curGroupStr = params[0],
                id = params[1],
                tmp = params[2],
                tData = [ tmp[tmp.length - 1] ],                  // 新增的数
                lastData = _this.data[tmp.length - 1],
                keyArr = [];                                    // 指定key的数组
            for(let i = 3; i < params.length; i++) {            // 从第4个开始
                let index = parseFloat(params[i]);              // 必须是数字
                if(!isNaN(index)) {
                    keyArr.push(lastData[index]);
                }
            }
            id = id + '_' +  curGroupStr + '_' + keyArr.join('-');
            if( _this.overlayData[id] ) {
                tData.push(_this.overlayData[id]);
            }
            var exp = 'SUM([' + tData + '])';
            var value = _this.parser.parse(exp);
            _this.overlayData[id] = value.result;           //更新预存的累计值
            return value.result;
        });

        // 设置常量的方法
        // this.parser.setVariable('name', value);


        // 适配Math的常量----formulajs有提供方法，但formula-parser遗漏了6个
        // this.parser.setFunction('E', function(){
        //     return Math.E;
        // });
        // this.parser.setFunction('PI', function(){
        //     return Math.PI;
        // });
        this.parser.setFunction('LN10', function(){
            return Math.LN10;
        });
        this.parser.setFunction('LN2', function(){
            return Math.LN2;
        });
        this.parser.setFunction('LOG10E', function(){
            return Math.LOG10E;
        });
        this.parser.setFunction('LOG2E', function(){
            return Math.LOG2E;
        });
        this.parser.setFunction('SQRT1_2', function(){
            return Math.SQRT1_2;
        });
        this.parser.setFunction('SQRT2', function(){
            return Math.SQRT2;
        });
        // 以下适配Math的方法-----可以使用Math.abs作为name
        this.parser.setFunction('Math.abs', function(params){
            return Math.abs(params[0]);
        });
        this.parser.setFunction('Math.acos', function(params){
            return Math.acos(params[0]);
        });
        this.parser.setFunction('Math.acosh', function(params){
            return Math.acosh(params[0]);
        });
        this.parser.setFunction('Math.asin', function(params){
            return Math.asin(params[0]);
        });
        this.parser.setFunction('Math.asinh', function(params){
            return Math.asinh(params[0]);
        });
        this.parser.setFunction('Math.atan', function(params){
            return Math.atan(params[0]);
        });
        this.parser.setFunction('Math.atanh', function(params){
            return Math.atanh(params[0]);
        });
        this.parser.setFunction('Math.atan2', function(params){
            return Math.atan2(params[0], params[1]);
        });
        this.parser.setFunction('Math.cbrt', function(params){
            return Math.cbrt(params[0]);
        });
        this.parser.setFunction('Math.ceil', function(params){
            return Math.ceil(params[0]);
        });
        this.parser.setFunction('Math.clz32', function(params){
            return Math.clz32(params[0]);
        });
        this.parser.setFunction('Math.cos', function(params){
            return Math.cos(params[0]);
        });
        this.parser.setFunction('Math.cosh', function(params){
            return Math.cosh(params[0]);
        });
        this.parser.setFunction('Math.exp', function(params){
            return Math.exp(params[0]);
        });
        this.parser.setFunction('Math.expml', function(params){
            return Math.expml(params[0]);
        });
        this.parser.setFunction('Math.floor', function(params){
            return Math.floor(params[0]);
        });
        this.parser.setFunction('Math.fround', function(params){
            return Math.fround(params[0]);
        });
        this.parser.setFunction('Math.hypot', function(params){
            return Math.hypot(...params);
        });
        this.parser.setFunction('Math.imul', function(params){
            return Math.imul(params[0], params[1]);
        });
        this.parser.setFunction('Math.log', function(params){
            return Math.log(params[0]);
        });
        this.parser.setFunction('Math.log1p', function(params){
            return Math.log1p(params[0]);
        });
        this.parser.setFunction('Math.log10', function(params){
            return Math.log10(params[0]);
        });
        this.parser.setFunction('Math.log2', function(params){
            return Math.log2(params[0]);
        });
        this.parser.setFunction('MY_Math_MAX', function(params){
            return Math.max(...params);
        });
        this.parser.setFunction('MY_Math_MIN', function(params){
            return Math.min(...params);
        });
        this.parser.setFunction('Math.pow', function(params){
            return Math.pow(params[0], params[1]);
        });
        this.parser.setFunction('Math.random', function(params){
            return Math.random();
        });
        this.parser.setFunction('Math.round', function(params){
            return Math.round(params[0]);
        });
        this.parser.setFunction('Math.sign', function(params){
            return Math.sign(params[0]);
        });
        this.parser.setFunction('Math.sin', function(params){
            return Math.sin(params[0]);
        });
        this.parser.setFunction('Math.sinh', function(params){
            return Math.sinh(params[0]);
        });
        this.parser.setFunction('Math.sqrt', function(params){
            return Math.sqrt(params[0]);
        });
        this.parser.setFunction('Math.tan', function(params){
            return Math.tan(params[0]);
        });
        this.parser.setFunction('Math.tanh', function(params){
            return Math.tanh(params[0]);
        });
        this.parser.setFunction('Math.toSource', function(params){
            return Math.toSource();
        });
        this.parser.setFunction('Math.trunc', function(params){
            return Math.trunc(params[0]);
        });
    }
    /**
     * 计算表达式----获取解析结果
     * 
     * @param {String} 表达式
     * @return {*} 对象 {error: null/'错误类型', result: *}
     * */ 
     parse(exp){
        return this.parser.parse(exp);
    }
    /**
     * 计算表达式----获取值
     * 
     * @param {String} 表达式
     * @return {*}
     * */ 
     getResult(exp){
        return this.parser.parse(exp).result;
    }
    /**
     * 根据行和列的索引----获取解析结果
     * 
     * @param {Number} rowIndex     行的索引
     * @param {Number} columnIndex  列的索引
     * @return {*} 对象 {error: null/'错误类型', result: *}
     * */ 
     parseByIndex(rowIndex, columnIndex){
        var exp = indexToCell(rowIndex, columnIndex);
        return this.parser.parse(exp);
    }
    /**
     * 根据行和列的索引----获取值
     * 
     * @param {Number} rowIndex     行的索引
     * @param {Number} columnIndex  列的索引
     * @return {*}
     * */ 
     getResultByIndex(rowIndex, columnIndex){
         var exp = indexToCell(rowIndex, columnIndex);
        return this.parser.parse(exp).result;
    }
}

const parser = new BoreFormula([[]]);
var boreFormula =  {
    indexToCell,                    //(rowIndex, columnIndex) => 列号行号                   eg. (1,2)=>C2
    columnIndexToLabel,             //(columnIndex) => 列号                                 eg. (3)=> D
    rowIndexToLabel,                //(rowIndex) => 行号                                    eg. (3)=> 4
    expMathconvert,                 //(exp) => exp    将其中的Math常量转为能识别的name       eg. ('Math.PI') => 'MY_MATH_PI'
    funcOfOverlay,                  //表达式第3次处理------对象组成的数组----{reg, do}
    funcOfGetColAll,                //表达式第1.5次处理----对象组成的数组----{reg, do}
    BoreFormula,                    //实例化时传入一个参数----二维数组, 可以通过实例.data更改
    parser                          //一个BoreFormula实例, 用于计算常量
};
export default boreFormula;
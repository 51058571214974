
import widgetListCrtl from './widgetListCrtl'
import './_widgetList.scss'
function widgetListModal($uibModal) {
    this.openWidgetList = function (widgetId, func_ok, widgetList, categoryList) {
        $uibModal.open({
            template: require('./widgetList.html'),
            // windowTemplate: require('./window.html'),//'com/ourbore/view/util/modal/window.html',
            controller: ['$scope', '$uibModalInstance', 'widgetModal', 'toastr', 'widgetList', 'categoryList', 'widgetId', 'ok', widgetListCrtl],
            backdrop: false,
            size: 'lg',
            windowClass: 'widget-list-modal',
            resolve: {
                widgetList: function(){
                    return widgetList;
                },
                categoryList: function(){
                    return categoryList;
                },
                widgetId: function(){
                    return widgetId;
                },
                ok: function () {
                    return func_ok;
                }
            }
        });
    };

}



export default angular.module('borepc.pages.dblayout.layoutWidgetList', [])
    .service('widgetListModal', ['$uibModal', widgetListModal]).name







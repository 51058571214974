import BasicService from './BasicService';
import { FILEAPI } from './ApiServer';
/**
 * @desc 测试用
 */

 
class FileService extends BasicService {
  constructor() {
    super();
  }
  uploadImage(formData,onProgress) {
    return  this.post_file(FILEAPI.IMAGEUPLOAD,formData,onProgress)
  }
  fileList(param){
   return  this.post(FILEAPI.FILELIST,param)
  }
  fileDel(param){
    return  this.post(FILEAPI.FILEDEL,param)
  }
  tagList(param){
    return  this.post(FILEAPI.TAGLIST,param)
   }
  tagDel(param){
    return  this.post(FILEAPI.TAGDEL,param)
  }
  tagSave(param){
    return this.post(FILEAPI.TAGSAVE,param)
  }

}

export const fileService = new FileService();

import {timerPushService} from '../../../../server'
import '../_timerPush.scss'

export default  function timerLogDetailCtrl( toastr, $scope, $state,  $stateParams) {


    timerPushService.getBtLogDetail({
        id: $stateParams.id
    }).then(function (response) {
        if (response.status == '1') {
            $scope.btLog = response.data;
            $scope.btLog.frequency =angular.fromJson(response.data.frequency);
          
        } else {
            toastr.error("获取日志失败");
        }
    },err=>{
        toastr.error(err.head.errorMsg)
    })

    $scope.goBackLogList = function(){
        $state.go('timerLog');
    }
}
import BasicService from './BasicService';
import { USERAPI } from './ApiServer';



/**
 * @desc 测试用
 */
class UserService extends BasicService {
  constructor() {
    super();
  }
  // 登录
  login(params) {
    var setToken=this.setToken;
    return new Promise((resolve, reject) => {
      this.post(USERAPI.LOGIN, params,true).then(function (res) {
        console.log(res);
        if (res.head.status === 1) {
          setToken(res.data.token);
          resolve(res);
        } else {
          resolve(res);
        }
      }
      );
    });

  }
  // 退出登录
  logout() {
    return this.get(USERAPI.LOGINOUT);
  }
  // 获取用户信息
  getUserInfo() {
    return this.get(USERAPI.USERDETAIL);
  }
  //获取用户列表
  getUserList() {
    return this.post(USERAPI.GETUSERLIST);
  }

  //isAdmin
  isAdmin () {
    return this.get( USERAPI.ISADMIN );
  }

  getMenuList(){
    return this.post(USERAPI.USERMENULIST);
  }
  getPermissionList(){
    return this.post(USERAPI.USERPERMISSIONLIST);
  }
  //获取已绑定微信的用户列表
  getBindUserIdByCompany(params) {
    return this.post(USERAPI.GETBINDUSERIDBYCOMPANY,params);
  }
  uploadAvatar(formData) {
    return  this.post_file(USERAPI.UPLOADAVATAR,formData,false,()=>{});
  }
  removeBind(params) {
    return  this.post(USERAPI.REMOVEBIND,params);
  }
  getBindsByUser(params) {
    return  this.post(USERAPI.GETBINDSBYUSER,params);
  }

  judgePermission(params) {
    return this.post(USERAPI.JUDGEPERMISSION, params);
  }

  getPermission(params) {
    return this.post(USERAPI.GETPERMISSION, params);
    // return new Promise((resolve, reject) => {
    //   this.post(USERRESAPI.GETPERMISSION, params, true).then(function (res) {
    //     if (res.head.status === 1) {
    //       var option = params.option;
    //       var data = res.data;
    //       if (option == 'query' && data != '-11') {
    //         res.data = true;
    //       } else if (option == 'update' && (data == '11' || data == '10')) {
    //         res.data = true;
    //       } else if (option == 'delete' && (data == '11' || data == '01')) {
    //         res.data = true;
    //       } else {
    //         res.data = false;
    //       }
    //       resolve(res);
    //     } else {
    //       resolve(res);
    //     }
    //   });
    // });
  }


}

export const userService = new UserService();

import { appService,userService,behaviorLogService } from '../../../server'
import * as newTpl from './data'
import appShareCrtl from './appSahreCrtl'
export default function ($scope, ModalUtils, $uibModal, $window,toastr) {
    function getRootPath_dc() {
        var pathName = window.location.pathname.substring(1);
        var webName = pathName == '' ? '' : pathName.substring(0, pathName.indexOf('/'));
        if (webName == "") {
            return window.location.protocol + '//' + window.location.host;
        }
        else {
            return window.location.protocol + '//' + window.location.host + '/' + webName;
        }
    }
    $scope.isActive = 'all';
    $scope.searchKey = '';
    let getAppList = () => {
        appService.getNoDataList({
            name: $scope.searchKey
        }).then(res => {
            $scope.totolCount = res.totolCount;
            $scope.listdata = res.listdata;
            $scope.changeAppType()
            // $scope.currentListdata = angular.copy($scope.listdata);
            $scope.$apply()
        })
    }
    getAppList();
 
    $scope.switchAppType=function(type){
        if (type) {
            $scope.isActive = type;
        } else {
            $scope.isActive = 'all';
        }
        getAppList();
    }

    $scope.changeAppType = function () {
        const type=  $scope.isActive
        switch (type) {
            case 'all':
                $scope.currentListdata = angular.copy($scope.listdata);
                break;
            case 'pc':
                $scope.currentListdata = $scope.listdata.filter(item => {
                    return item.type == 'pc'
                })
                break;
            case 'ppt':
                $scope.currentListdata = $scope.listdata.filter(item => {
                    return item.type == 'ppt'
                })
                break;
            case 'phone':
                $scope.currentListdata = $scope.listdata.filter(item => {
                    return item.type == 'phone'
                })
                break;
        }
    }

    $scope.publish = function (id) {
        appService.appPublish({ id: id }).then(res => {
            ModalUtils.alert(res, "modal-warning", "lg");
        })
    }

    $scope.delete = function (id) {
        ModalUtils.confirm("确定删除应用吗?", "modal-info", "lg", function () {
            userService.judgePermission({
                option:"delete",
                resType:"app",
                resId:id
            }).then(function(data){
                if(data){
                    appService.appDel({ id: id }).then(res => {
                        toastr.success("删除应用成功!")
                        getAppList();
                    },err=>{
                        toastr.error(err.head && err.head.errorMsg)
                    })
                }else{
                    toastr.error("您没有删除该应用权限！")
                }
            },err=>{
                toastr.error(err.head.errorMsg || '获取权限失败！')
            })
        })
        
    }
    $scope.edit = (item) => {
        userService.judgePermission({
            option:"update",
            resType:"app",
            resId:item.id
        }).then(function(data){
            if(data){ 
                window.open("/design.html?appId=" + item.id);
                $scope.inserBehaviortLog(item.name);
            }else{
                toastr.error("您没有编辑该应用权限！")
            }
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
    }

    $scope.openApp = function(item) {
        userService.judgePermission({
            option:"query",
            resType:"app",
            resId:item.id
        }).then(function(data){
            if(data){
                $scope.getApp(item);
            }else{
                toastr.error("您没有查看该应用权限！")
            }
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
       
    }

    $scope.inserBehaviortLog =function(name){
        try {
            behaviorLogService.bvInsertLog({module:"app",function:"查看",name:name})
        } catch (error) {
            // console.log("插入日志失败:"+error)
        }
    }
    $scope.getApp = function(item){
        appService.appGet({ id: item.id }).then((data) => {
            if(data.status=='1'){
                var url = getRootPath_dc() + '/display.html?appId=' + item.id;;
                $window.open(url, '_blank');
                $scope.inserBehaviortLog(item.name);
            }else{
                ModalUtils.alert('当前应用未发布！！！', "modal-warning", "lg");
            }
        }, err => {
            ModalUtils.alert('系统发生异常', "modal-warning", "lg");
        })
    }


    $scope.new = () => {
        let appdata = {}
        switch ($scope.isActive) {
            case 'phone':
                appdata = newTpl.phone;
                appdata.name = "新建移动H5"
                break;
            case 'ppt':
                appdata = newTpl.ppt;
                appdata.name = "新建ppt"
                break;
            case 'pc':
                appdata = newTpl.pc;
                appdata.name = "新建大屏"
                break
        }
        appdata.cache = {
            dataCache: {
                type: 'app',
                isCache: false,
                expireTime: 0
            },
            dimensionCache: {
                type: 'app',
                isCache: false,
                expireTime: 0
            }
        };
        var param = {
            type: appdata.type,
            name: appdata.name,
            pic: appdata.img,
            remarks: appdata.info,
            dataDraft: JSON.stringify(appdata),
            shtml: ''
        }
        appService.appSave(param).then((appId) => {
            this.APP_ID = appId;
            getAppList()
            window.open("/design.html?appId=" + appId);
        }, err => {
            console.log(err)
        })




    }

    $scope.copy = (id) => {
        userService.judgePermission({
            option:"update",
            resType:"app",
            resId:id
        }).then(function(data){
            if(data){
                appService.appGet({ id: id }).then((data) => {
                    data.id = ''
                    data.name = data.name + '_copy'
                    var dataDraft= JSON.parse(data.dataDraft) 
                    dataDraft.name= data.name;
                    data.dataDraft=JSON.stringify(dataDraft)
                    data.data='';
                    appService.appSave(data).then((appId) => {
                        getAppList();
                        $scope.$apply();
                    }, err => {
                        console.log(err)
                    })
                }, err => {
        
                })
            }else{
                toastr.error("您没有复制该应用权限！")
            }
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
       
    }

    $scope.share = function (appId,appName) {
        userService.judgePermission({
            option:"update",
            resType:"app",
            resId:appId
        }).then(function(data){
            if(data){
                $scope.openShare(appId,appName);
            }else{
                toastr.error("您没有分享该应用权限！")
            }
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
        
    }

    $scope.openShare =function(appId,appName){
        $uibModal.open({
            animation: true,
            template: require('./appShare.html'),
            controller: ['$scope', 'appId','appName', 'close', 'toastr', appShareCrtl],
            size: 'lg',
            resolve: {
                appId: function () {
                    return appId;
                },
                appName: function () {
                    return appName;
                },
                close: function(){
                    return getAppList;
                }
            }
        });
    }
}
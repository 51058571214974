import prewarningListTpl from './prewarningList.html'
import prewarningListCtrl  from './prewarningListCtrl'
import pwLogListTpl from './pwLog/pwLogList.html'
import pwLogListCtrl  from './pwLog/pwLogListCtrl'
import pwLogDetailTpl from './pwLog/pwLogDetail.html'
import pwLogDetailCtrl  from './pwLog/pwLogDetailCtrl'
  
export default angular.module('borepc.pages.prewarning', [])
.controller('prewarningListCtrl', ['ModalUtils','$scope', '$state','$uibModal','toastr',prewarningListCtrl])
.controller('pwLogListCtrl', ['$scope', '$state', 'ModalUtils','toastr',pwLogListCtrl])
.controller('pwLogDetailCtrl', ['$scope', '$state',  'ModalUtils',  '$stateParams','toastr',pwLogDetailCtrl])
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('prewarning', {
      url: '/prewarning',
      title: '预警列表',
      template: prewarningListTpl,
      controller: 'prewarningListCtrl',
    })
    .state('pwLog', {
      url: '/pwLog',
      title: '预警日志',
      template: pwLogListTpl,
      controller: 'pwLogListCtrl'
    })
    .state('pwLogDetail', {
      url: '/pwLog/detail?id',
      title: '预警日志详情',
      params: { id: null},
      template: pwLogDetailTpl,
      controller: 'pwLogDetailCtrl'
    })
}


import reportListTpl from './reportList.html'
import reportListCtrl  from './reportListCtrl'
import reportDetailTpl from './reportDetail.html'
import reportDetailCrl  from './reportDetailCtrl'
require('./_.report.scss')
export default angular.module('borepc.pages.report', [])
.controller('reportListCtrl', ['$scope', '$rootScope',  'ModalUtils', '$window', '$filter', '$state','toastr',reportListCtrl])
.controller('reportDetailCrl', ['$scope', '$location','$stateParams','$sce',reportDetailCrl])
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('report', {
      url: '/report',
      title: 'report',
      template: reportListTpl,
      title: '报表列表',
      controller: 'reportListCtrl',
    })
    .state('reportDatail', {
      url: '/report/detail?reportId&reportName&dtpId',
      params: { reportId: null, reportName:null,dtpId: null },
      template: reportDetailTpl,
      controller: 'reportDetailCrl',
    });
}


 
import   './_pageTop.scss'  ;
import  pageTopTpl from './pageTop.html';
import {userService, cacheService} from '../../../../server'  
import { util } from '../../../utils';
export  default  angular.module('BlurAdmin.theme.components.pageTop',[])
      .directive('pageTop', ['toastr', pageTop]).name ;
function pageTop(toastr) {
    return {
      restrict: 'E',
      template: pageTopTpl,
      controller:['$scope',function($scope){
        $scope.loginOut=()=>{
          userService.logout().then(()=>{
            localStorage.clear();
            util.delCookie("token");
            window.open('/login.html', '_self');
          });
        }

        $scope.clearCache = function(){
          cacheService.clear();
          toastr.success("清除缓存成功");
        };
      }]
    };
  }

import boardListTpl from './boardList.html';
import boardListCtrl from './BoardListCtrl';


export default angular.module('borepc.pages.dashboard.boardList', [])
.controller('boardListCtrl', ['$scope', '$rootScope', '$filter', '$http', 'ModalUtils',  'toastr','$state','$uibModal','$stateParams',boardListCtrl])
.directive('boardIconSvg', function(){

  function hashcode(str) {
    var hash = 0, i, chr, len;
    if (str.length === 0) return hash;
    for (i = 0, len = str.length; i < len; i++) {
        chr = str.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }

  var geticon = function (name) {
    var hashname = Math.abs(hashcode(name));
    var cnum = hashname % 6 + 1;
    return 'icon_board' + cnum;
  }

  return {
    restrict: 'AE',
    scope: {
        boardname: '@'
    }, 
    template: ' <img ng-src="/assets/imgs/common/{{sname}}.svg" alt="image" />',
    replace: true,
    link: function (scope, element, attrs) {
        scope.sname = "icon_board1";
        scope.sname = geticon(scope.boardname);
    }
  };
})
.config(['$stateProvider',routeConfig]).name ;

function routeConfig($stateProvider) {
    $stateProvider
      .state('boardList', {
        url: '/boardList',
        title: '分析看板',
        template: boardListTpl,
        controller: 'boardListCtrl',
        params:{category:null}
      })
  }
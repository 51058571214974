require('./_directives.scss');

import animatedChange from './animatedChange';
import autoExpand from './autoExpand';
import autoFocus from './autoFocus';
import includeWithScope from './includeWithScope';
import ionSlider from './ionSlider';
import ngFileSelect from './ngFileSelect';
import scrollPosition from './scrollPosition';
import trackWidth from './trackWidth';
import zoomIn from './zoomIn';
import myPagination from './myPagination';
import myDateTimePicker from './myDateTimePicker';
import ckeditor from './ckeditor';
import myCategoryTree from './myCategoryTree';
import myWaterMark from './myWaterMark';
export default angular.module('BlurAdmin.theme.directives', [])
    .directive('animatedChange', ['$timeout',animatedChange])
    .directive('autoExpand', autoExpand)
    .directive('autoFocus', ['$timeout', '$parse',autoFocus])
    .directive('includeWithScope', includeWithScope)
    .directive('ionSlider', ['$timeout',ionSlider])
    .directive('ngFileSelect', ngFileSelect)
    .directive('scrollPosition', scrollPosition)
    .directive('trackWidth', trackWidth)
    .directive('myPagination', myPagination)
    .directive('myDateTimePicker', ['$timeout',myDateTimePicker])
    .directive('ckeditor',ckeditor)
    .directive('zoomIn', ['$timeout', '$rootScope',zoomIn])
    .directive('myCategoryTree', myCategoryTree)
    .directive('myWaterMark', ['$compile', myWaterMark]).name;

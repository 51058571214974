/**
 * 
 * @param {*} $scope 
 * @param {*} $uibModalInstance 
 * @param {Object} param {type: string, selects: string[], values: string[]}
 * @param {Function} ok 
 */

export default function ($scope, $uibModalInstance, toastr, param, ok) {
    $scope.type = ['=', '≠', '>', '<', '≥', '≤', '(a,b]', '[a,b)', '(a,b)', '[a,b]'];
    $scope.param = param;
    $scope.param.type ? null : $scope.param.type = '=';
    // $scope.param.selects.sort((a,b) => a - b);               //排序-----外部排序
    $scope.selects = [];                                        //可选区域的值---------后面通过search初始化
    $scope.paramValuesType = 3;                                 //1==>values的长度为1，2==> values的长度为2， 3==> values的长度为n
    $scope.selected = [];                                       //可选值选中的值
    // $scope.operate = {};
    // $scope.filter = filter;
    // $scope.byFilter = {a: false};
    // $scope.loadSelect = false;
    function isEmpty(obj) {
        if (typeof obj == "undefined" || obj == null || obj == "") {
            return true;
        } else {
            return false;
        }
    };
    var setParamValuesType = function (paramType) {
        var type = paramType ? paramType : $scope.param.type;
        switch (type) {
            case $scope.type[0] : ;
            case $scope.type[1] : $scope.paramValuesType = 3; break;        //多个值
            case $scope.type[2] : ;
            case $scope.type[3] : ;
            case $scope.type[4] : ;
            case $scope.type[5] : $scope.paramValuesType = 1; break;        //最多一个值
            case $scope.type[6] : ;
            case $scope.type[7] : ;
            case $scope.type[8] : ;
            case $scope.type[9] : $scope.paramValuesType = 2; break;        //两个值或无
                        default : $scope.paramValuesType = 3;
        }
    };
    setParamValuesType($scope.param.type);
    // 初始化values
    var initParamValues = function(){
        if($scope.paramValuesType === 2) {
            !$scope.param.values || $scope.param.values.length < 2 || $scope.param.values.length > 2 ? $scope.param.values = ['', ''] : null;
        } else {
            !$scope.param.values || $scope.param.values.length === 0 ? $scope.param.values = [''] : null;
        }
    }
    initParamValues();
    // 合并两个数组----目标数组中的空字符串优先被填充, 返回合并后的数组
    var assginArray = function(target, source){
        var s = source;
        if( !s && s !== 0   ) {
            return target;
        }
        s instanceof Array ? null : s = [s];
        var arr = [], i = 0, l = s.length;
        _.each(target, (item)=>{
            if( i < l ){
                if(item || item === 0) {
                    arr.push(item);
                } else {
                    arr.push(s[i]);
                    i++;
                }
            } else {
                arr.push(item);
            }
        });
        for( ; i< l ; i++ ){
            arr.push(s[i]);
        }
        return arr;
    };
    //处理移动端需要双击才能聚焦的问题
    $scope.searchFocus = function (e){
        e.target.focus();
    };
    // 切换param的type
    $scope.changeParamType = function(type) {
        $scope.param.type = type;
        $scope.selected = [];
        setParamValuesType(type);
        $scope.clearParamValues();
    };
    // 可选区域选中选项
    $scope.changeSelected = function(value){
        var index = $scope.selected.indexOf(value);
        if(index > -1) {
            $scope.selected.splice(index, 1);
        } else {
            if($scope.paramValuesType === 1) {
                $scope.selected[0] = value;
            } else if($scope.paramValuesType === 2) {
                $scope.selected.length < 2 ? null : $scope.selected.splice(0, 1);
                $scope.selected.push(value);
            } else {
                $scope.selected.push(value);
            }
        }
    };
    // 可选区域当前项是否已选中
    $scope.isSelected = function(value){
        return $scope.selected.indexOf(value) > -1 ? true : false;
    };
    // 将可选区域的选择项移到设定值
    $scope.selectedToParamValues = function(){
        if($scope.selected.length == 0) {
            return;
        }
        if($scope.paramValuesType === 1) {
            $scope.param.values[0] = $scope.selected[0];
        } else if($scope.paramValuesType === 2) {
            if($scope.selected.length == 1) {
                !isEmpty($scope.param.values[0]) ? $scope.param.values[1] = $scope.selected[0] : $scope.param.values[0] = $scope.selected[0];
            } else {
                $scope.param.values[0] = $scope.selected[0];
                $scope.param.values[1] = $scope.selected[1];
            }
        } else {
            $scope.param.values = assginArray($scope.param.values, $scope.selected);
        }
        $scope.selected = [];
        $scope.search($scope.searchValue);
    };
    // 清空设定值
    $scope.clearParamValues = function(){
        if($scope.paramValuesType === 1) {
            $scope.param.values = [''];
        } else if($scope.paramValuesType === 2) {
            $scope.param.values = ['', ''];
        } else {
            $scope.param.values = [''];
        }
        $scope.search($scope.searchValue);
    };
    // 可选值区域双击设定设定值
    $scope.dbclickPush = function(value) {
        if($scope.paramValuesType === 1) {
            $scope.param.values[0] = value;
        } else if($scope.paramValuesType === 2) {
            !isEmpty($scope.param.values[0]) ? $scope.param.values[1] = value : $scope.param.values[0] = value;
        } else {
            $scope.param.values = assginArray($scope.param.values, [value]);
        }
        var index = $scope.selected.indexOf(value);
        if(index > -1) {
            $scope.selected.splice(index, 1);
        }
        $scope.search($scope.searchValue);
    };
    // 可选值区域的搜索功能
    $scope.search = function(value){
        $scope.selects = [];
        if(value) {
            _.each($scope.param.selects, (v, index)=>{
                if($scope.param.values.indexOf(v) == -1 && v.indexOf(value) > -1 ) {
                    $scope.selects.push(v);
                }
            });
        } else {
            _.each($scope.param.selects, (v, index)=>{
                if($scope.param.values.indexOf(v) == -1) {
                    $scope.selects.push(v);
                }
            });
        }
        $scope.selected = [];   //搜索时清空可选区域选中项
    };
    $scope.search();        //初始化可选值一次
    //values的长度+1
    $scope.addParamValuesL = function(){
        $scope.param.values.push('');
    };
    //values的值减1
    $scope.delParamValue = function(index){
        if($scope.param.values.length === 1) {
            $scope.param.values = [''];
            $scope.search($scope.searchValue);
            return;
        }
        $scope.param.values.splice(index, 1);
        $scope.search($scope.searchValue);
    };

    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.ok = function () {
        // 两个值的类型，进行判断
        if($scope.paramValuesType === 2 ) {
            if(isEmpty($scope.param.values[0]) ^ isEmpty($scope.param.values[1])) {
                toastr.error('设定值选项应都为空或都有值');
                return;
            }
        }
        $scope.param.values = _.filter($scope.param.values, function(e){
            return !isEmpty(e);
        });
        ok($scope.param);
        $scope.close();
    };

    //下拉框的方法
    $scope.changeDropdown = function(e){
        e.stopPropagation();
        if($(e.target).hasClass('bore-dropdown')){
            var ele = $(e.target);
            var bodyClickEvent = function(){
                ele.removeClass('open');
                $('body').off('click', bodyClickEvent);
            };
            if(ele.hasClass('open')) {
                ele.removeClass('open');
                $('body').off('click', bodyClickEvent);
            } else {
                ele.addClass('open');
                $('body').on('click', bodyClickEvent);
            }
        } else {
            $('body').click();
        }
    };




    // //绑定backspace退出弹窗
    // var bindKeydownEvent = function (e) {
    //     var e = e || window.event;
    //     if (e.keyCode === 8) {
    //         $uibModalInstance.close(closeModal);
    //     }
    // }

    // $(document).on('keydown', bindKeydownEvent);
   
    // $scope.cancleBind = function () {
    //     $(document).off('keydown', bindKeydownEvent);
    // }

    // $scope.addBind = function () {
    //     $(document).on('keydown', bindKeydownEvent);
    // }
    
    // var closeModal = function () {
    //     $uibModalInstance.close(closeModal);
    // }
    // window.addEventListener('popstate', closeModal);

} 
import accountCenter from './accountCenter.html'
import accountCenterCtrl from './accountCenterCtrl'

export default angular.module('borepc.pages.accountCenter', [])
    .controller('accountCenterCtrl', ['$scope', '$uibModal', 'toastr',accountCenterCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('accountCenter', {
            url: '/accountCenter',
            title: '我的账户',
            controller: 'accountCenterCtrl',
            template: accountCenter
        })
}
import { storage } from '../../../common/utils';
import { messageService, collectionService, userService } from '../../../server';
require('./_.userPanel.scss')
export default function userPanelCtrl($scope, $rootScope, toastr, $state, ModalUtils) {
    $scope.browseRecords = [];
    $scope.user = angular.copy(JSON.parse(window.localStorage.getItem("userInfo")));
    var arrs2 = _.uniq([{ name: 'ads', age: 12 }, { name: 'ads', age: 23 }, { name: 'ads', age: 12 }, { name: 'ads', age: 23 }], function (item) {
        return item.name;
    })
    $scope.gotoBoard = function () {
        $state.go('boardList');
    };

    $scope.gotoDataset = function () {
        $state.go('dataset');
    };
    $scope.gotoDataApp = function () {
        $state.go('dataApp');
    };

    $scope.gotoArchives = function () {
        $state.go('archives');
    };

    $scope.gotoCollectionList = function () {
        $state.go('collection');
    }
    //获取浏览记录列表
    $scope.getBrowseRecordList = function () {
        var datas = storage.get('browseRecords') || [];
        userService.getUserInfo().then((user) => {
            $scope.user = user;
            if (datas.length > 0){
                $scope.browseRecords = _.filter(_.uniq(datas, function (data) { return data.companyId + data.userId + data.id }), function (data) {
                    return data.userId == user.id && data.companyId == user.companyId;
                })
            }
        });
        
    };

    $scope.getBrowseRecordList();

    //移除浏览记录
    $scope.removeBrowseRecords = function () {
        storage.remove('browseRecords');
        $scope.browseRecords = [];
        toastr.success("清除浏览记录成功!")
    }

    //删除单条浏览记录
    $scope.delRecord = function (index) {
        $scope.browseRecords.splice(index, 1);
        storage.set("browseRecords", $scope.browseRecords);
        toastr.success("删除浏览记录成功!")
    }

    //前往展示页面
    $scope.gotoDisplay = function (board) {
        userService.judgePermission({option:"query",resType:"board",resId:board.id}).then(function(data){
            if(!data){
                toastr.error("您没有查看该看板权限！")
                return;
            }
            $state.go('display', {id: board.id});
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
        
    }

    //获取我的收藏列表
    $scope.getCollectionList = function () {
        collectionService.getCollectionList().then(function (response) {
            $scope.collectionList = response.listdata;
            $scope.$apply()
        }, err => {
            toastr.error(err.head.errorMsg);
        })
    }
    $scope.getCollectionList();

    $scope.delCollection = function (param) {
        ModalUtils.confirm("确定要取消收藏吗?", "modal-warning", "lg", function () {
            collectionService.delCollection({
                collectionId: param.collectionId,
                type: param.type
            }).then(function (response) {
                $scope.getCollectionList();
                toastr.success("取消收藏成功!")
            }, err => {
                toastr.error(err.head.errorMsg || '取消收藏失败')
            })
        })
    }

    $scope.gotoCollection = function (collection) {
        userService.judgePermission({option:"query",resType:collection.type,resId:collection.collectionId}).then(function(data){
            if(!data){
                toastr.error("您没有查看该看板权限！")
                return;
            }
            if (collection.type == 'board') {
                setBrowseRecords(collection)
                $state.go('display', {id: collection.collectionId});
            }
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
        
    }

    $scope.getMessageList = function () {
        $scope.isLoading = true;
        var params = {
            "pageNumber": 1,
            "pageSize": 10,
            "status": '0'
        }
        messageService.getMessageList(params).then(function (response) {
            // $scope.messageList = response.list;
            $rootScope.userMessageLists = response.list;
            $rootScope.userMessageCounts = response.count;
            $scope.$apply();
        }, err => {
            toastr.error(err.head.errorMsg || "获取消息列表失败!");
        })
    }
    $scope.getMessageList();

    $scope.gotoMessageDetail = function (id) {
        $state.go('messageDetail', { id: id });
    }

    $scope.changeHomePage =function(col){
        if(col.type!='board'){
            ModalUtils.alert("请选择看板！", "modal-warning", "lg");
            return;
        }
        if(!col.showHomePage || col.showHomePage=='0'){
            setHomePage(col);
        }else{
            cancelHomePage(col);
        }
    }
    var cancelHomePage = function (col) {
        collectionService.cancelHomePage({collectionId:col.collectionId}).then(function(response){
            toastr.success("取消首页展示成功!");
            $scope.getCollectionList();
        },err=>{
            toastr.error(err.head.errorMsg || "取消失败!")
        })
    }

    var setHomePage = function (col) {
        collectionService.setHomePage({collectionId:col.collectionId}).then(function(response){
            toastr.success("设置首页展示成功!");
            $scope.getCollectionList();
        },err=>{
            toastr.error(err.head.errorMsg || "设置失败!")
        })
    }

     //记录浏览历史记录
     var setBrowseRecords = function (board) {
        if (!board) return;
        var browseRecord = {
            type:'board',
            id:board.collectionId,
            name:board.collectionName,
            categoryId:$scope.categoryId,
            categoryName:$scope.categoryName,
            userId:$scope.user.id,
            companyId:$scope.user.companyId,
            dataTime:Date.parse(new Date())
        }
        var browseRecords = storage.get('browseRecords') || [];
        browseRecords.unshift(browseRecord);
        // if(browseRecords.length >20)browseRecords = browseRecords.slice(0,20);
        storage.set("browseRecords",browseRecords)
    }

}


import { userService, userAdminService  } from '../../../server';
import {storage} from '../../../common/utils';
export default function ProfilePageCtrl($scope, $filter, toastr,ModalUtils) {

  // $scope.picture = $filter('profilePicture')('Nasta');
  $scope.userData = {}
  $scope.getUserInfo = () => {
    userService.getUserInfo().then((data) => {
      $scope.userData = data;
      $scope.oldUserData = data;
      $scope.$apply();
    }, err => {
      toastr.error("您提交的信息有误！请核对后提交")
    })
  }
  $scope.getUserInfo();

  $scope.removePicture = function () {
    $scope.picture = $filter('appImage')('theme/no-photo.png');
    $scope.noPicture = true;
  };

  $scope.uploadPicture = function () {
    var fileInput = document.getElementById('uploadFile');
    fileInput.click();
  };
  $scope.uploadAvatar = (files) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    userService.uploadAvatar(formData)
      .then((data) => {
        $scope.userData.photo=data;
        $scope.$apply();
      }, err => {
        console.log(err)
      })
  }

  $scope.socialProfiles = [
    // {
    //   name: 'Facebook',
    //   href: 'https://www.facebook.com/akveo/',
    //   icon: 'socicon-facebook'
    // },if(type=='0')return 'fa fa-institution';
    {
      name: '定制化授权',
      icon: 'fa fa-institution',
      type:'0'
      // icon: 'socion-wechat'
    },
    {
      name: '微信公众号',
      icon: 'fa fa-wechat',
      type:'1'
      // icon: 'socion-wechat'
    },

    {
      name: 'PC端扫码登陆',
      icon: 'fa fa-desktop',
      type:'2'
      // icon: 'socicon-windows'
    }
  ];

  $scope.updateUserData=function(){
    const userData =$scope.userData
    if(userData.newPassword&&userData.newPassword!=userData.confirmNewPassword){
      toastr.error("两次密码不一致,请核对后再更新!")
      return
    }
    var params = {
        id:userData.id,
        companyId:userData.companyId,
        name:userData.name,
        photo:userData.photo,
        newPassword:userData.newPassword,
        confirmNewPassword:userData.confirmNewPassword,
        email:userData.email,
        phone:userData.phone,
        loginName:userData.loginName,
        oldLoginName:$scope.oldUserData.loginName,
    }
    userAdminService.editUser(params).then(function(response){
      toastr.success('更新成功!');
      if(userData.newPassword&&userData.newPassword){
        ModalUtils.confirm("密码修改成功,请重新登陆!", "modal-warning", "sm");
        setTimeout(function(){
          $scope.getUserInfo();
        },2000)
      }else{
         $scope.getUserInfo();
      }

    },err => {
      ModalUtils.confirm(err.head.errorMsg || "更新失败!", "modal-warning", "sm");
      // toastr.error(err.head.errorMsg || "更新失败!");
    });

  }

  $scope.getBindsByUser = function(){
    userService.getBindsByUser().then(function(response){
        $scope.bindList= response;
        $scope.$apply();
    })
  }
  $scope.getBindsByUser();

  $scope.removeBind = function(type){
    ModalUtils.confirm("确定移除绑定吗?", "modal-info", "md", function () {
        userService.removeBind({type:type}).then(function(){
          $scope.getUserInfo();
          $scope.getBindsByUser();
          toastr.success("移除绑定成功");
        },err => {
          toastr.error(err.head.errorMsg || "移除绑定失败!");
        });
    })
  }

  $scope.getStyle = function(type){
    if(type=='0')return 'icon iconfont icon-custom-authorization';
    if(type=='1')return 'icon iconfont icon-wechat';
    if(type=='2')return 'icon iconfont icon-pc';
    if(type=='4')return 'icon iconfont icon-dingding';
    if(type=='5')return 'icon iconfont icon-wechat';
    return 'fa fa-institution';
  }

  $scope.getItemName = function(type){
    if(type=='0')return '定制化授权';
    if(type=='1')return '微信公众号';
    if(type=='2')return 'PC端扫码登陆';
    if(type=='4')return '钉钉授权登陆';
    if(type=='5')return '企业微信';
    return '其他';
  }

}


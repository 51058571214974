import BasicService from './BasicService';
import { COLLECTIONAPI } from './ApiServer';
/**
 * @desc 收藏
 */
class CollectionService extends BasicService {
  constructor() {
    super();
  }
  getCollectionList(params){
    return this.post(COLLECTIONAPI.GETCOLLECTIONLIST,params)
  }
  getCollectionData(params){
    return  this.post(COLLECTIONAPI.GETCOLLECTIONDATA,params);
  }
  saveCollection(params){
    return this.post(COLLECTIONAPI.SAVECOLLECTION,params)
  }
  delCollection(params){
    return this.post(COLLECTIONAPI.DELCOLLECTION,params)
  }
  getHomePage(params){
    return this.post(COLLECTIONAPI.GETHOMEPAGE,params)
  }
  setHomePage(params){
    return this.post(COLLECTIONAPI.SETHOMEPAGE,params)
  }
  cancelHomePage(params){
    return this.post(COLLECTIONAPI.CANCELHOMEPAGE,params)
  }
}

export const collectionService = new CollectionService();

import modelCenter from './modelCenter.html'
import modelCenterCtrl from './modelCenterCtrl'

export default angular.module('borepc.pages.modelCenter', [])
    .controller('modelCenterCtrl', ['$scope', '$sce',  '$uibModal', 'ModalUtils',modelCenterCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('modelCenter', {
            url: '/modelCenter',
            title: '模型市场',
            controller: 'modelCenterCtrl',
            template: modelCenter
        })
}
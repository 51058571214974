import chartManager from '../../ChartManager'
import {GaugeRender} from './GaugeRender'
// console.log(Kpi2Render)
import option from './option'
import editor from  './editor'
import config from  './config'
chartManager.mountChart('gauge', {render:new  GaugeRender(),option,editor,config })
//仪表盘
export   {GaugeRender };


 
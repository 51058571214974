import {timerPushService, userAdminService} from '../../../server'
import timerEditTpl from './timerEdit/timerEdit.html'
import timerEditCtrl from './timerEdit/timerEditCtrl'
import windowTpl from '../../../common/theme/components/modal/window.html'
import './_timerPush.scss'

export default  function timerListCtrl( toastr, $scope,  ModalUtils, $uibModal) {
    $scope.btList =[];
    $scope.isLoading = true;
    $scope.orderBy = [];
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 18,
        onChange: function () {
            $scope.getBtList(); //重新加载
        }
    };

    $scope.getSort = function(column){
        var orderColumn = _.find($scope.orderBy,function(e){
            if(e.orderField==column)return e.orderType;
        })
        if(!orderColumn){
          return "sorting";
        }else{
          return "sorting_"+orderColumn.orderType;
        }
    }

    $scope.sortColumn =function(column){
        var orderColumn = _.find($scope.orderBy,function(e){
            if(e.orderField==column)return e.orderType;
        })
        if(!orderColumn) {
            $scope.orderBy.push({orderField:column,orderType:'asc'});
        }else{
            $scope.orderBy = _.filter($scope.orderBy,function(e){
                if(e.orderField==column && e.orderType=='asc'){
                    e.orderType='desc'
                    return e;
                }else if(e.orderField!=column){
                    return e;
                }
            })
        }
        $scope.getBtList();
    }

    //获取用户信息
    userAdminService.postUsers({pageNo:1,pageSize:9999}).then(function (response) {
        $scope.userList = response.listdata;
        $scope.$apply();
    });

    $scope.saveTimer = function(data){
        timerPushService.saveBoreTimer({btJson:angular.toJson(data)}).then(function (response) {
            if(response.status == '1'){
                toastr.success("成功", "modal-success", "sm");
            }else{
                toastr.error(response.msg || "保存定时推送器失败", "modal-warning", "sm");
            }
            $scope.getBtList();
            $scope.isLoading =false;
        })
    }
    $scope.search = {};
     //获取定时器列表
     $scope.getBtList = function(){
        // $scope.isLoading = true;
        var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "search":$scope.search.value,
            "orderBy":JSON.stringify($scope.orderBy)
        }
        timerPushService.getTimerPageList(params).then(function (response) {
            if(response.status=='1'){
                $scope.btList = response.data.listdata || [];
                if($scope.btList.length >0){
                    angular.forEach($scope.btList,function(btData){
                        btData.frequency =angular.fromJson(btData.frequency);
                        btData.receiptIds =angular.fromJson(btData.receiptIds);
                    })
                }
                $scope.paginationConf.totalItems = response.data.totolCount;

            }else{
                toastr.error("获取列表失败", "modal-warning", "sm");
            }
            $scope.$apply();
            $scope.isLoading = false;
        });
    }

    $scope.updateTimerStatus =function(data,status){
    	var timers = angular.copy(data);

        timers.status = status == '1'?'0':'1';
        timerPushService.updateTimerStatus({
            btJson:angular.toJson(timers)
        }).then(function (response) {
            if(response.status != '1'){
                toastr.error(response.msg ||"更新失败,请检查您的配置!", "modal-warning", "sm");
            }else{
            	toastr.success("更新状态成功！", "modal-warning", "sm");
            }
            $scope.getBtList();
        })
    }

    //删除定时器
    $scope.delTimer = function(timer){
        ModalUtils.confirm("确认删除"+timer.name+"吗?", "modal-info", "lg", function () {
            timerPushService.delBoreTimer({
                btJson:angular.toJson(timer)
            }).then(function (response) {
            	 if(response.status != '1'){
                    toastr.error(response.msg ||"删除失败,请检查您的配置!", "modal-warning", "sm");
                 }else{
                    toastr.success("删除成功！", "modal-warning", "sm");
                 }
            	 $scope.getBtList();
            })
        });
    }

    //编辑定时器
    $scope.editTimer = function (btData,index) {
        $uibModal.open({
            template: timerEditTpl,
            windowTemplate:windowTpl,
            size: 'lg',
            windowClass: 'pw-modal',
            resolve: {
                ok: function () {
                    return function () {
                        $scope.isLoading = true;
                        $scope.getBtList();
                    }
                },
                timer:function(){
                    return btData;
                },
                userList:function(){
                    return $scope.userList;
                }
            },
            controller: ['$scope', '$uibModal', 'toastr','ok', 'userList', '$uibModalInstance', 'ModalUtils', 'timer',timerEditCtrl]
        });
    };
}

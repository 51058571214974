import {
  cloneDeep,
  debounce,
  isObject,
  throttle,
  uniq
} from 'lodash';

import dayjs from 'dayjs';
var moment = require('moment');
const dateFormatPreset = {
  datetime: 'YYYY/MM/DD HH:mm:ss',
  date: 'YYYY/MM/DD',
  time: 'HH:mm:ss'
};

class Util {
  /**
   *
   * @desc   判断是否为URL地址
   * @param  {String} str
   * @return {Boolean}
   */
  isUrl(str) {
    return /https?:\/\/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(str);
  }

  /**
   *
   * @desc   判断是否为手机号
   * @param  {String|Number} str
   * @return {Boolean}
   */
  isPhoneNum(str) {
    return /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/.test(str);
  }

  /**
   *
   * @desc   url参数转对象
   * @param  {String} url  default: window.location.href
   * @return {Object}
   */
  getUrlData(name) {
    let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)');
    let r = window.location.search.substr(1).match(reg);
    let data = null;
    if (r != null) {
      data = unescape(r[2]);
    }
    return data;
  }

  /**
 * url地址修改
 * @param url 待修改url
 * @param arg 修改的参数名
 * @param arg_val 修改的具体值
 * @returns {String}
 */
  changeURLArg(url, arg, arg_val) {
    var pattern = arg + '=([^&]*)';
    var replaceText = arg + '=' + arg_val;
    if (url.match(pattern)) {
        var tmp = '/(' + arg + '=)([^&]*)/gi';
        tmp = url.replace(eval(tmp), replaceText);
        return tmp;
    } else {
        if (url.match('[\?]')) {
            return url + '&' + replaceText;
        } else {
            return url + '?' + replaceText;
        }
    }
  }

  /**
   * @desc 数组去重
   */
  uniq(arr, param) {
    return uniq(arr, param);
  }

  /**
   * 深拷贝对象
   * @param {*} value 要拷贝的对象
   */
  cloneDeep(value) {
    return cloneDeep(value);
  }

  /**
   * @desc toJS
   * @param {object}
   * @return obj
   */
  toJS(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * 防抖函数，延迟一定时间后执行fn
   * @param {Function} func 实际要执行的方法
   * @param {number} wait 要延迟的毫秒数
   */
  debounce(func, wait) {
    return debounce(func, wait);
  }

  /**
   * 节流函数，保证func在一定时间内只执行1次。（如：水龙头，起到限速作用）
   * @param {Function} func 实际要执行的方法
   * @param {number} wait 限制在多少毫秒内执行一次
   */
  throttle(func, wait) {
    return throttle(func, wait);
  }

  /**
   * 将对象使用escape进行编码（支持对象和字符串）
   * @param {*} value
   */
  escape(value) {
    try {
      const str = isObject(value) ? JSON.stringify(value) : value;
      return window.escape(str);
    } catch (e) {
      return '';
    }
  }

  /**
   * 设置  本地缓存
   */
  setStorage(key, obj) {
    if (typeof obj === 'string') {
      window.localStorage.setItem(key, obj);
    } else {
      window.localStorage.setItem(key, JSON.stringify(obj));
    }
  }

  /**
   * 获取
   */
  getStorage(key) {
    let val = window.localStorage.getItem(key);
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  }

  /**
   * 删除， 如果不传值，删除所有
   */
  clearStorage(key) {
    if (key) {
      window.localStorage.removeItem(key);
    } else {
      window.localStorage.clear();
    }
  }

  /**
   * 设置  本地缓存
   */
  setSession(key, obj) {
    if (typeof obj === 'string') {
      window.sessionStorage.setItem(key, obj);
    } else {
      window.sessionStorage.setItem(key, JSON.stringify(obj));
    }
  }

  /**
   * 获取
   */
  getSession(key) {
    let val = window.sessionStorage.getItem(key);
    try {
      return JSON.parse(val);
    } catch (e) {
      return val;
    }
  }

  /**
   * 删除， 如果不传值，删除所有
   */
  clearSession(key) {
    if (key) {
      window.sessionStorage.removeItem(key);
    } else {
      window.sessionStorage.clear();
    }
  }

  /**
   * 保留 n 位小数
   * @param {string|number} val 要处理的文本或数字
   * @param {number} n 要保留的小数位数
   */
  toFixed(value, n = 2) {
    if (value) {
      value = parseFloat(value);
      value = parseFloat(value.toFixed(n));
    } else {
      value = 0;
    }
    return value;
  }

  /**
   * 交换数组元素
   * @param {Array} arr 要交换元素的数组
   * @param {number} fromIdx 元素索引 1
   * @param {number} toIdx 元素索引 2
   */
  exchageArrayElem(arr, from, to) {
    [arr[to], arr[from]] = [arr[from], arr[to]];
  }

  /**
   * 移除数组元素（会在原数组上移除，并返回新数组）
   * @param {Array} arr 要移除元素的数组
   * @param {*} elem 要移除的元素
   * @returns 返回移除后新数组
   */
  removeArrayElement(arr, elem) {
    const idx = arr.indexOf(elem);
    if (idx >= 0) {
      arr.splice(idx, 1);
    }
    return this.cloneDeep(arr);
  }

  /**
   * 计算两个数字的加法，相对精确值
   * @param {number} num1
   * @param {number} num2
   */
  plus(num1, num2) {
    return +(num1 + num2).toFixed(10);
  }

  /**
   * 计算两个数字的减法，相对精确值
   * @param {*} num1
   * @param {*} num2
   */
  minus(num1, num2) {
    return +(num1 - num2).toFixed(10);
  }

  /**
   * 格式化日期时间
   * @param {Date} date 要格式化的日期，默认为当期时间
   * @param {string} format 具体的format，提供datetime, date, time三个预设，其他格式请参考 dayjs / momentjs
   */
  formatDate(date = new Date(), format = 'datetime') {
    return dayjs(date).format(dateFormatPreset[format] || format);
  }

  /**
   * 生成随机数字
   * @param {number} min 最小值（包含）
   * @param {number} max 最大值（不包含）
   */
  randomNumber(min = 0, max = 100) {
    return Math.min(Math.floor(min + Math.random() * (max - min)), max);
  }

  /**
   * 生成随机颜色
   */
  randomColor() {
    return '#' + ('00000' + ((Math.random() * 0x1000000) << 0).toString(16)).slice(-6);
  }

  /**
   * 随机id ,长度默认是8
   */
  randomID(randomLength = 8) {
    return Number(
      Math.random()
        .toString()
        .substr(3, randomLength) + Date.now()
    ).toString(36);
  }

  /**
   * @desc 获取图片64 data， 和 imgLazy 配合使用
   * @param {string} src 图片资源地址
   * @param {object} obj 设置裁剪
   */
  getBase64(src, {
    width,
    height,
    x,
    y
  }) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous'; // 允许跨域
      img.onload = () => {
        const canvas = $('<canvas width="' + width + '" height="' + height + '"></canvas>')[0];
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, x, y, width, height, 0, 0, width, height);
        resolve(canvas.toDataURL());
      };
      img.onerror = err => {
        console.error('图片转base64失败！');
        reject(err);
      };
      img.src = src + '?t=' + this.randomID();
    });
  }

  /**
   * @desc 将对象数组转换为对象
   * @param {*} arr 要处理的对象数组
   * @param {boolean} cover 是否覆盖，默认false
   */
  arrayToObject(arr, cover = false) {
    const result = {};
    arr.forEach(item => {
      Object.keys(item).forEach(key => {
        const value = item[key];
        // 如果允许覆盖，直接设置值，否则，检查没有设置过该属性
        if (cover || !result.hasOwnProperty(key)) {
          result[key] = value;
        }
      });
    });
    return result;
  }

  /**
   * @desc 判断是否是空，null, undefined, '', 不包含0
   * @param {*} value
   */
  isEmpty(value) {
    if (value === null || value === undefined || value === "undefined" || value === '' || value === "") {
      return true;
    } else {
      return false;
    }
  }

  /**
   * @desc 替换背景字符串中的图片地址
   * @param {string} 背景图url，eg: url('http://xxx.jpg')
   */
  getBackGroundImageUrl(url = '') {
    url = url.replace(/url\((.*)\)/, '$1');
    return url.replace(/["']/g, '');
  }

  /**
   * @desc 图片预加载
   * @param 预加载src
   */
  imgLazy(src) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = function () {
        resolve(img);
      };
      img.onerror = function () {
        console.error('图片加载失败', null);
        reject(null);
      };
    });
  }

  /**
   * @desc 编码
   * @param {string} code 需要编码的代码
   */
  compile(code) {
    var c = String.fromCharCode(code.charCodeAt(0) + code.length);
    for (var i = 1; i < code.length; i++) {
      c += String.fromCharCode(code.charCodeAt(i) + code.charCodeAt(i - 1));
    }
    return escape(c);
  }

  /**
   * @desc 解码
   * @param {string} code 需要解码的代码
   */
  uncompile(code) {
    code = unescape(code);
    var c = String.fromCharCode(code.charCodeAt(0) - code.length);
    for (var i = 1; i < code.length; i++) {
      c += String.fromCharCode(code.charCodeAt(i) - c.charCodeAt(i - 1));
    }
    return c;
  }


  json2form(data) {
    try {
      var tempArr = [];
      for (var i in data) {
        if (data[i] == null || _.isUndefined(data[i])) {
          continue;
        }
        var key = encodeURIComponent(i);
        var value = encodeURIComponent(data[i]);
        tempArr.push(key + '=' + value);
      }
      var urlParamsStr = tempArr.join('&');
      return urlParamsStr;
    } catch (err) {
      return '';
    }
  }

  //处理过滤器词条，返回处理好的字符串
  updateParamTitle = function(param, values){
    var paramObj;
    switch (param.type) {
      case '=':
      case '≠':
        paramObj = param.name + ' ' + param.type + ' (' + values.join('，') + ')';
        break;
      case '>':
      case '<':
      case '≥':
      case '≤':
        paramObj = param.name + ' ' + param.type + ' ' + values;
        break;
      case '(a,b]':
      case '[a,b)':
      case '(a,b)':
      case '[a,b]':
        var leftBrackets = param.type.split('a')[0];
        var rightBrackets = param.type.split('b')[1];
        paramObj = param.name + '  ' + leftBrackets + values[0] + '，' + values[1] + rightBrackets + '';
        break;
    }
    return paramObj;
  }

  // 获取昨天的开始结束时间
  getYesterday = function () {
    let date = []
    date.push(moment().subtract('days', 1).startOf('day'))
    date.push(moment().subtract('days', 1).endOf('day'))
    return date
  }
  // 获取最近七天的开始结束时间
  getLast7Days = function () {
    let date = []
    date.push(moment().subtract('days', 7))
    date.push(moment().subtract('days', 1))
    return date
  }
  // 获取最近30天的开始结束时间
  getLast30Days = function (format) {
    if (!format) {
      format = 'YYYY-MM-DD'
    }
    let date = []
    date.push(moment().subtract('days', 30))
    date.push(moment().subtract('days', 1))
    return date
  }
  // 获取上一周的开始结束时间
  getLastWeekDays = function (format) {
    if (!format) {
      format = 'YYYY-MM-DD'
    }
    debugger
    let date = []
    let weekOfday = parseInt(moment().format('d')) // 计算今天是这周第几天  周日为一周中的第一天
    let start = moment().subtract(weekOfday + 7, 'days') // 周一日期
    let end = moment().subtract(weekOfday + 1, 'days') // 周日日期
    date.push(start)
    date.push(end)
    return date
  }
  // 获取上一个月的开始结束时间
  getLastMonthDays = function (format) {
    if (!format) {
      format = 'YYYY-MM-DD'
    }
    let date = []
    let start = moment().subtract('month', 1).format('YYYY-MM') + '-01'
    let end = moment(start).subtract('month', -1).add('days', -1).endOf('days')
    date.push(start)
    date.push(end)
    return date
  }
  // 获取当前周的开始结束时间
  getCurrWeekDays = function () {

    let date = []
    let weekOfday = parseInt(moment().format('d')) // 计算今天是这周第几天 周日为一周中的第一天
    let start = moment().subtract(weekOfday - 1, 'days').startOf('day') // 周一日期
    let end = moment().add(7 - weekOfday, 'days').endOf('day') // 周日日期
    date.push(start)
    date.push(end)
    return date
  }
  // 获取当前月的开始结束时间
  getCurrMonthDays = function () {
    let date = []
    let start = moment().add('month', 0).format('YYYY-MM') + '-01'
    let end = moment(start).add('month', 1).add('days', -1).endOf('days')
    date.push(start)
    date.push(end)
    return date
  }
  // 获取当前月的开始结束时间
  getCurrDays = function () {

    let date = []
    let start = moment().startOf('day')
    let end = moment().endOf('day')
    //console.log(start)
    date.push(start)
    date.push(end)
    return date
  }
  getCurrYers = function () {

    let date = []
    let start = moment().startOf('year')
    let end = moment().endOf('day')
    date.push(start)
    date.push(end)
    return date
  }
  //写cookies

  setCookie = function (name, value) {
    var Days = 30;
    var exp = new Date();
    exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
  }

  //读取cookies
  getCookie = function (name) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
    return (arr = document.cookie.match(reg)) ? unescape(arr[2]) : null;
  }

  //删除cookie
  delCookie = function (name) {
    var exp = new Date();
    exp.setTime(exp.getTime() - 1);
    var cval = util.getCookie(name);
    if (cval != null)
      document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
  }

  getUrlPara = function (name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var param = window.location.search.substr(1) || window.location.hash.split("?")[1];
    if (!param) return null;
    var para = param.match(reg); //search,查询？后面的参数，并匹配正则
    if (para != null) return decodeURIComponent(para[2]);
    return null
  }

isNumber = function (val){
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if(regPos.test(val) && regNeg.test(val)){
        return true;
    }else{
        return false;
    }
}

  replaceAll = function (data, s1, s2) {
    return data.replace(new RegExp(s1, "gm"), s2);
  };
  hashCode = function (s) {
    var hash = 0, c = (typeof s == 'string') ? s.length : 0, i = 0;
    while (i < c) {
      hash = ((hash << 5) - hash) + s.charCodeAt(i++);
      //hash = hash & hash; // Convert to 32bit integer
    }
    return "key"+((hash < 0) ? ((hash * -1) + 0xFFFFFFFF) : hash); // convert to unsigned
  }

  isMobileAgents = function(agents){
    const mobileAgents = ["iphone", "android", "phone", "mobile", "wap", "netfront", "java", "opera mobi",
    "opera mini", "ucweb", "windows ce", "symbian", "series", "webos", "sony", "blackberry", "dopod",
    "nokia", "samsung", "palmsource", "xda", "pieplus", "meizu", "midp", "cldc", "motorola", "foma",
    "docomo", "up.browser", "up.link", "blazer", "helio", "hosin", "huawei", "novarra", "coolpad", "webos",
    "techfaith", "palmsource", "alcatel", "amoi", "ktouch", "nexian", "ericsson", "philips", "sagem",
    "wellcom", "bunjalloo", "maui", "smartphone", "iemobile", "spice", "bird", "zte-", "longcos", "pantech",
    "gionee", "portalmmm", "jig browser", "hiptop", "benq", "haier", "^lct", "320x320", "240x320",
    "176x220", "w3c ", "acs-", "alav", "alca", "amoi", "audi", "avan", "benq", "bird", "blac", "blaz",
    "brew", "cell", "cldc", "cmd-", "dang", "doco", "eric", "hipt", "inno", "ipaq", "java", "jigs", "kddi",
    "keji", "leno", "lg-c", "lg-d", "lg-g", "lge-", "maui", "maxo", "midp", "mits", "mmef", "mobi", "mot-",
    "moto", "mwbp", "nec-", "newt", "noki", "oper", "palm", "pana", "pant", "phil", "play", "port", "prox",
    "qwap", "sage", "sams", "sany", "sch-", "sec-", "send", "seri", "sgh-", "shar", "sie-", "siem", "smal",
    "smar", "sony", "sph-", "symb", "t-mo", "teli", "tim-", "tosh", "tsm-", "upg1", "upsi", "vk-v", "voda",
    "wap-", "wapa", "wapi", "wapp", "wapr", "webc", "winw", "winw", "xda", "xda-", "Googlebot-Mobile"]
    return mobileAgents.indexOf(agents)>-1;
  }
  // //禁止回退到url页面
  // fobidenRollbackPage = function(url){
  //   // if(!url)return
  //   history.pushState(null, null, "http://127.0.0.1:8001/#/redirect");
  //   window.addEventListener('popstate', function () {
  //     history.pushState(null, null, "http://127.0.0.1:8001/#/redirect");
  //   });
  // }
}

export const util = new Util();

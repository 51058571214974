import { archivesService } from '../../../server';

export default  function reportListCtrl($scope, toastr, archivesDetailModal ) {
	$scope.isLoading = true;										//加载状态
	$scope.search = {value: ''};									//搜索关键词
	$scope.archivesList = [1,2,3,4,5,6,7,8];						//档案列表
	$scope.curArchives = null;										//当前档案
	// $scope.sourceList = ['', 'PPT', '看板', '大屏', '报表'];		 //来源列表
	$scope.curSource = '';											//当前来源
	$scope.tagList = [];											//标签列表
	$scope.curTagId = '';											//当前标签的Id

	$scope.isPreDelArchives = false;								//准备删除存档
	$scope.isShowBigImage = false;									//显示大号缩略图
	$scope.isShowBigImageLeft = false;								//显示在左边

	$scope.isShowTagModal = false;									//修改或新增标签的弹窗
	$scope.planChangeTag = {id: '', name: ''};						//计划修改的标签

	// currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
	$scope.paginationConf = {
		currentPage: 1,
		totalItems: 1,
		itemsPerPage: 24,
		onChange: function () {
			getArchivesList();
		}
	};

	// 获取列表
	var getArchivesList = function(){
		$scope.isLoading = true;
		// 来源、标签、搜索、页码、一页数量
		archivesService.getFileList({
			'alias': $scope.search.value ? $scope.search.value : '',
			'source': $scope.curSource ? $scope.curSource : '',
			'tagId': $scope.curTagId ? $scope.curTagId : '',
			'pageNum': $scope.paginationConf.currentPage,
			'pageSize': $scope.paginationConf.itemsPerPage
		}).then(data=>{
			if(data) {
				$scope.paginationConf.totalItems = data.totolCount;
				$scope.archivesList = data.listdata;
			} else {
				$scope.paginationConf.totalItems = 0;
				$scope.archivesList = [];
			}
			$scope.isLoading = false;
			$scope.$apply();
		}, err=>{
			console.log(err);
			$scope.isLoading = false;
			$scope.$apply();
		});
	};
	// 获取标签列表
	var getTagList = function(){
		archivesService.getTagList({
			pageNum: 1,
			pageSize: 999
		}).then(data=>{
			if(data){
				$scope.tagList = data.listdata;
			}
			$scope.$apply();
		}, err=>{
			// console.log(err);
		});
	};
	// 初始化
	getTagList();
	getArchivesList();

	// 切换来源
	$scope.changeSource = function(value){
		$scope.curSource = value;
		getArchivesList();
	};
	// 切换标签
	$scope.changeTag = function(value){
		$scope.curTagId = value;
		getArchivesList();
	};
	//增加一个标签
	$scope.addTag = function(){
		$scope.planChangeTag = {name: ''};
		$scope.isShowTagModal = true;
	};
	// 修改一个标签----修改或删除
	$scope.amendTag = function(tag){
		$scope.planChangeTag = {
			id: tag.id,
			name: tag.name
		};
		$scope.isShowTagModal = true;
	};
	// 保存标签----新增或修改
	$scope.saveTag = function(){
		if(!$scope.planChangeTag.name) {
			toastr.error('标签名称不能为空');
			return;
		}
		var tag = {name: $scope.planChangeTag.name};
		$scope.planChangeTag.id ? tag.id = $scope.planChangeTag.id : null;
		archivesService.updateTag(tag).then(data=>{
			toastr.success(data ? data : '操作成功');
			getTagList();
		}, err=>{
			toastr.error('操作失败');
		});
		$scope.isShowTagModal = false;
	};
	// 删除一个标签
	$scope.delTag = function(){
		var id = $scope.planChangeTag.id;
		archivesService.deleteTag(id).then(data=>{
			toastr.success(data ? data : '操作成功');
			getTagList();
		}, err=>{
			toastr.error('操作失败');
		});
		$scope.isShowTagModal = false;
	};
	// // 开启修改标签的弹窗
	// $scope.openTagModal = function(){
	// 	$scope.isShowTagModal = true;
	// };
	// 关闭修改标签的弹窗
	$scope.closeTagModal = function(){
		$scope.isShowTagModal = false;
	};
	
	// 搜索
	$scope.onSearch = function(value){
		getArchivesList();
	};
	// 准备删除一个存档
	$scope.preDelArchives = function(archives) {
		$scope.curArchives = archives;
		$scope.isPreDelArchives = true;
	};
	// 关闭删除存档的弹窗
	$scope.closeDelArchivesModal = function(){
		$scope.isPreDelArchives = false;
	};
	// 删除一个档案
	$scope.delArchives = function(id){
		archivesService.deleteFile(id).then((data)=>{
			$scope.closeDelArchivesModal();
			getArchivesList();
			toastr.success(data ? data : '删除成功');
		}, err=>{
			$scope.closeDelArchivesModal();
			toastr.error('删除失败');
		});
	};
	// 查看一个档案
	$scope.showArchives = function(archives){
		var c = angular.copy(archives);
		var func_ok = function(data){
			// 需要更新信息----重新获取列表
			getArchivesList();
		};
		archivesDetailModal.openDetail(c, func_ok);
	};

	// 显示大图-----鼠标进入事件
	$scope.showBigImage = function(e, archives){
		$scope.curArchives = archives;
		$scope.isShowBigImage = true;
		var domW = document.documentElement.clientWidth;
		if(e.pageX < (domW / 2 - 10)) {
			$scope.isShowBigImageLeft = false;
		} else {
			$scope.isShowBigImageLeft = true;
		}
	}
	// 隐藏大图----鼠标移出事件
	$scope.hiddenBigImage = function(){
		$scope.isShowBigImage = false;
	};

};
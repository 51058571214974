import BasicService from './BasicService';
import { APPAPI } from './ApiServer';
/**
 */
class AppService extends BasicService {
  // constructor() {
  //   super();
  // }

  appList(param) {
    return this.post(APPAPI.APPLIST, param)
  }
  appDel(param) {
    return this.post(APPAPI.APPDEL, param)
  }
  appSave(param) {
    return this.post(APPAPI.APPSAVE, param)
  }
  appPublish(param) {
    return this.post(APPAPI.APPPUBLISH, param)
  }
  appUnPublish(param){
    return this.post(APPAPI.APPUNPUBLISH, param)
  }
  appGet(param){
    return this.post(APPAPI.APPGET,param)
  }
  appGetData(param){
    return this.post(APPAPI.APPPUBDATA,param)
  }
  getNoDataList (param){
     return this.post(APPAPI.NODATALIST,param)
  }
  getAuthList (param){
    return this.post(APPAPI.GETAUTHLIST,param)
 }
  getDataDraft(id){
      return this.post(APPAPI.getDataDraft,{id:id})
  }
  getShare(param){
    return this.post(APPAPI.GETSHARE,param)
  }    
  shareList(param){
    return this.post(APPAPI.SHARELIST,param)
  }   
  shareSave(data){
    return this.post(APPAPI.SHARESAVE,data)
  }   
  shareDel(param){
    return this.post(APPAPI.SHAREDEL,param)
  }    
  shareRefresh(param){
    return this.post(APPAPI.SHAREREFRESH,param)
  }
  // share       
  appVisual(param){
    return this.post(APPAPI.APPVISUAL,param)
  }   
  getShareByAppId(param){
    return this.post(APPAPI.GETSHAREBYAPPID,param)
  }
  delShareByAppId(param){
    return this.post(APPAPI.SHAREDELBYAPPID,param)
  }
  getShortUrl(param){
    return this.post(APPAPI.GETSHORTURL,param)
  }
}

export const appService = new AppService();

import dataset from './dataset.html'
import datasetCtrl from './datasetCtrl'
import datasetListFilter from './datasetListFilter'
import metaTableCtrl from './metaTable/metaTableCtrl'
import metaTableTpl from './metaTable/metaTable.html'

export default angular.module('borepc.pages.dataset', [])
    .controller('datasetCtrl', ['$scope', '$state','widgetModal','toastr', '$uibModal', 'ModalUtils', 'uuid4', '$filter',datasetCtrl])
    .controller('metaTableCtrl', ['$scope', '$state','widgetModal','toastr', '$uibModal', 'ModalUtils', 'uuid4', '$filter',metaTableCtrl])
    .filter('datasetListFilter', datasetListFilter)
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('dataset', {
            url: '/dataset',
            title: '数据集市',
            controller: 'datasetCtrl',
            template: dataset
        })
        .state('metaTable', {
            url: '/metaTable',
            title: '元表',
            controller: 'metaTableCtrl',
            template: metaTableTpl
        })
}
'use strict';
import '../kpi/_kpi.scss';
import '../kpi3/_kpi3.scss';
import numbro from 'numbro';
import KpiRenderEngine from "../../rengine/KpiRenderEngine";
import Kpi2RenderEngine from '../../rengine/Kpi2RenderEngine';
import Kpi3RenderEngine from '../../rengine/Kpi3RenderEngine';

import * as util from '../../util/util'
import EchartRenderEngine from "../../rengine/EchartRenderEngine";

class CustomRender {
  //默认高度
  height = 300;

  render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
    var renderType = option.renderType || "";
    switch (renderType) {
      case "kpi":
        var render = new KpiRenderEngine(containerDom, option);
        var html = render.html(persist);
        if (scope) {
          containerDom.append($compile(html)(scope));
        } else {
          containerDom.html(html);
        }
        return render.realTimeTicket();
      case "kpi2":
        var render = new Kpi2RenderEngine(containerDom, option);
        var html = render.html(persist);
        if (scope) {
          containerDom.append(this.$compile(html)(scope));
        } else {
          containerDom.html(html);
        }
        return render.realTimeTicket();
      case "kpi3":
        var render = new Kpi3RenderEngine(containerDom, option);
        var html = render.html(persist);
        if (scope) {
          containerDom.append(this.$compile(html)(scope));
        } else {
          containerDom.html(html);
        }
        return render.realTimeTicket();
      case "custom":
        var func = new Function('containerDom', 'option', 'scope', 'persist', 'drill', 'relations', 'chartConfig', option.cusFunc);
        return func(containerDom, option, scope, persist, drill, relations, chartConfig);
      default:
        var render = new EchartRenderEngine(containerDom, option);
        //  render.addClick(chartConfig, relations, $state, $window);
        render.addClick(chartConfig, relations);
        return render.chart(null, persist);
    }

  };

  parseOption = function (data, customCode) {
    try {
      var func = new Function('data', 'numbro', 'util', customCode);
      return func(data, numbro, util)
    } catch (e) {
      console.log(e)
      // return ['语法错误，请看console日志'];
    }
  };
}
export {
  CustomRender
}

 import  EchartRenderEngine from '../../rengine/EchartRenderEngine'
 import * as util  from '../../util/util'
 class ContrastRender{
    //默认高度
    height = 300;

     render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        var render = new EchartRenderEngine(containerDom, option);
        render.addClick(chartConfig, relations);
        return render.chart(null, persist);
    };

    parseOption = function (data) {
        var chartConfig = data.chartConfig;
        var casted_keys = data.keys;
        var casted_values = data.series;
        var aggregate_data = data.data;
        var newValuesConfig = data.seriesConfig;
        var tunningOpt = chartConfig.option;
        var string_keys = _.map(casted_keys, function (key) {
            return key.join('-');
        });

        var colFormatter = [];
        var setColFormatter = [];
        //获取指标数组
        var k = 0;  //计数用---cols的数量
        for (var i = 0; i < chartConfig.values.length; i++) {
            for (var j = 0; j < chartConfig.values[i].cols.length; j++) {
                var col = chartConfig.values[i].cols[j];
                colFormatter.push(col);
                //适配原有的图表配置,如果设置了提示框单位转换才需要转换单位,否则就用原先默认的
                setColFormatter[k] = col && (col.unit || col.symbol ||col.dividend || col.mantissa) ? true : false;
                k++;
            }
        }

        var max = aggregate_data[0][0];
        _.each(aggregate_data, function(arr){
            _.each(arr, function(e){
                if(parseFloat(e) > parseFloat(max)){
                    max = e;
                }
            });
        });

        // var Axis = [
        //     {
        //         type : 'value',
        //         min:-max,
        //         max:max
        //     }
        // ];
        // var valueAxis = [
        //     {
        //         type : 'category',
        //         axisTick : {show: false},
        //         data : string_keys
        //     }
        // ];

    // 数据的轴-----2021.04.16
        var vAxis = util.clone(chartConfig.values[0])
        // 刻度标签
        vAxis.axisLabel ? null : vAxis.axisLabel = {};
        vAxis.axisLabel.formatter = function (value,findex) {
            value < 0 ? value = -value : null;
            if(!tunningOpt.valAxisFormatter || !tunningOpt.valAxisFormatter[0]){
                return numbro(value).format({
                    thousandSeparated: true,
                });
            }
            var yfArray =  tunningOpt.valAxisFormatter[0];
            return util.convertEchartUnit(yfArray,value);
        };
        //设置最大最小间隔
        vAxis.scale = true;
        vAxis.minInterval = vAxis.minInterval ? vAxis.minInterval : null;
        vAxis.maxInterval = vAxis.maxInterval ? vAxis.maxInterval : null;
        // 分隔线
        vAxis.splitLine = vAxis.splitLine ? vAxis.splitLine : {show:true};
        // 轴线
        vAxis.axisLine ? null : null;

    // 类目轴部分-----2021.04.16
        var categoryAxis = {
            type: 'category',
            // position: 'auto',
            data: string_keys,
            inverse: false,
            boundaryGap: true,
            nameTextStyle: {
                fontSize: 12
            },
            axisLine: {
                lineStyle: {}
            },
            splitLine: {
                show: true,
                lineStyle: {}
            },
            axisLabel: {
                interval: 'auto',
                rotate: 0,
                textStyle: {
                    fontSize: 12
                }
            },
            axisTick: {
                show: false
            },
            data: string_keys
        };
        if( tunningOpt ) {
            // 反向坐标轴
            tunningOpt.categoryInverse ? categoryAxis.inverse = tunningOpt.categoryInverse : null;
            // // 位置
            // tunningOpt.position ? categoryAxis.position = tunningOpt.position : null;
            // 轴名称
            tunningOpt.categoryAxisName         ? categoryAxis.name                   = tunningOpt.categoryAxisName         : null;
            tunningOpt.categoryAxisNameLocation ? categoryAxis.nameLocation           = tunningOpt.categoryAxisNameLocation : null;
            tunningOpt.categoryAxisNameGap      ? categoryAxis.nameGap                = tunningOpt.categoryAxisNameGap      : null;
            tunningOpt.categoryAxisNameRotate   ? categoryAxis.nameRotate             = tunningOpt.categoryAxisNameRotate   : null;
            tunningOpt.categoryAxisNameFontSize ? categoryAxis.nameTextStyle.fontSize = tunningOpt.categoryAxisNameFontSize : null;
            tunningOpt.categoryAxisNameColor    ? categoryAxis.nameTextStyle.color    = tunningOpt.categoryAxisNameColor    : null;

            // 轴线
            tunningOpt.categoryAxisLine ? categoryAxis.axisLine  = tunningOpt.categoryAxisLine : null;
            // 分隔线
            tunningOpt.splitLine        ? categoryAxis.splitLine = tunningOpt.splitLine        : null;

            // 标签
            tunningOpt.categoryAxisLabelMargin || tunningOpt.categoryAxisLabelMargin === 0 ? categoryAxis.axisLabel.margin = tunningOpt.categoryAxisLabelMargin : null;
            tunningOpt.ctgLabelRotate            ? categoryAxis.axisLabel.rotate             = tunningOpt.ctgLabelRotate            : null;
            tunningOpt.categoryAxisLabelFontSize ? categoryAxis.axisLabel.textStyle.fontSize = tunningOpt.categoryAxisLabelFontSize : null;
            tunningOpt.categoryAxisLabelColor    ? categoryAxis.axisLabel.textStyle.color    = tunningOpt.categoryAxisLabelColor    : null;

            tunningOpt.ctgLabelInterval === undefined ? null : categoryAxis.axisLabel.interval = tunningOpt.ctgLabelInterval;

        }


        var valueAxis = categoryAxis;
        // var valueAxis = {
        //     type : 'category',
        //     axisTick : {show: false},
        //     data : string_keys
        // };
        vAxis.type = 'value';
        vAxis.min = vAxis.max || vAxis.max > 0 ? -vAxis.max : -max;
        vAxis.max = vAxis.max || vAxis.max > 0 ? vAxis.max : max;
        var keyAxis = vAxis;
        // var keyAxis = {
        //     type : 'value',
        //     min:-max,
        //     max:max,
        //     axisLabel: {
        //         formatter: function(value, index){
        //             return value < 0 ? -value : value;
        //         }
        //     }
        // };
        var xAxis = [];
        var yAxis = [];
        if(chartConfig.valueAxis == "horizontal") {
            xAxis[0] = valueAxis;
            yAxis[0] = keyAxis;
        } else {
            xAxis[0] = keyAxis;
            yAxis[0] = valueAxis;
        }

        //设置图表的数值标签-----series
        var label = chartConfig.values[0].label ? util.clone(chartConfig.values[0].label) : {};
        label.show ? null : label.show = false;
        label.rotate ? null : label.rotate = 0;
        label.fontSize ? null : label.fontSize = 12;
        label.position ? null : label.position = "top";
        label.color ? null : label.color = "#000";
        label.formatter = function (value) {
            let data = value.data < 0 ? -value.data : value.data;
            if(!colFormatter[value.seriesIndex]){
                //空字符串时防止报错
                if(data){
                    return numbro(data).format({
                        thousandSeparated: true,
                    });
                } else {
                    return data;
                }
            }
            return util.convertEchartUnit(colFormatter[value.seriesIndex], data);
        }

        var echartOption = {
            tooltip: {
                formatter: function (params) {
                    var name = params[0].name;
                    var s = name + "</br>";
                    for (var i = 0; i < params.length; i++) {
                        s += '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:9px;height:9px;background-color:' + params[i].color + '"></span>';
                        if (params[i].value instanceof Array) {
                            // s += params[i].seriesName + " : " + params[i].value[1] + "% (" + params[i].value[2] + ")<br>";
                            var v1, v2;
                            if(setColFormatter[i]) {
                                v1 = util.convertEchartUnit(colFormatter[i], ( i%2 === 0 ? -params[i].value[1] : params[i].value[1] ));
                                v2 = util.convertEchartUnit(colFormatter[i], ( i%2 === 0 ? -params[i].value[2] : params[i].value[2] ));
                            } else {
                                v1 = i%2 === 0 ? -params[i].value[1] : params[i].value[1];
                                v2 = i%2 === 0 ? -params[i].value[2] : params[i].value[2];
                            }
                            s += params[i].seriesName + " : " + v1 + "% (" + v2 + ")<br>";
                        } else {
                            // s += params[i].seriesName + " : " + params[i].value + "<br>";
                            var v;
                            if(setColFormatter[i]) {
                                v = util.convertEchartUnit(colFormatter[i], ( i%2 === 0 ? -params[i].value : params[i].value ));
                            } else {
                                v = i%2 === 0 ? -params[i].value : params[i].value;
                            }
                            s += params[i].seriesName + " : " + v + "<br>";
                        }
                    }
                    return s;
                }
            },
            legend: {
                data: [casted_values[0][0],casted_values[1][0]]
            },
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                containLabel: true
            },
            // xAxis : chartConfig.valueAxis == "horizontal" ? valueAxis:Axis,
            // yAxis : chartConfig.valueAxis == "horizontal" ? Axis:valueAxis,
            xAxis : xAxis,
            yAxis : yAxis,
            series : [
                {
                    name:casted_values[0][0],
                    type:'bar',
                    stack: '总量',
                    barWidth: 20,
                    label: label,
                    // label: {
                    //     normal: {
                    //         show: false
                    //     }
                    // },
                    itemStyle:{
                        normal: {
                            color : '#C23531'
                        }
                    },
                    data:_.map(aggregate_data[0],function(e){return -e})
                },
                {
                    name:casted_values[1][0],
                    type:'bar',
                    stack: '总量',
                    barWidth: 20,
                    label: label,
                    // label: {
                    //     normal: {
                    //         show: false
                    //     }
                    // },
                    itemStyle:{
                        normal: {
                            color : '#3C8DBC'
                        }
                    },
                    data:aggregate_data[1]
                }
            ]
        };

        util.updateEchartOptions(tunningOpt, echartOption);
        util.updateEchartsTooltipPosition(echartOption);
        return echartOption;
    };
}

export {ContrastRender}

import widgetTpl from './widget.html'
import WidgetCtrl from './WidgetCtrl'
import {behaviorLogService} from '../../../server';
export default function widgetModal($uibModal) {
    this.open = function (widgetId) {
        var uibModalInstance = $uibModal.open({
            animation: true,
            template: widgetTpl,
            backdrop: false,
            controller: ['$scope','toastr','$state','widgetId','datasetId','ModalUtils','$uibModal','$filter','$timeout','$uibModalInstance',WidgetCtrl],
            size: 'lg',
            windowClass: 'widgetmodal',
            resolve: {
                widgetId: function () {
                    return widgetId;
                },
                datasetId:function () {
                    return null;
                },
            }
        });
        return uibModalInstance;
    };
    this.new = function (datasetId,categoryName) {
        var uibModalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
            template: widgetTpl,
            controller: ['$scope','toastr','$state','widgetId','datasetId','ModalUtils','$uibModal','$filter','$timeout','$uibModalInstance','categoryName',WidgetCtrl],
            size: 'lg',
            windowClass: 'widgetmodal',
            resolve: {
                widgetId: function () {
                    return null;
                },
                datasetId:function () {
                    return datasetId;
                },
                categoryName:function () {
                    return categoryName;
                },
            }
        });
        return uibModalInstance.result;
    };

    this.show = function (widget) {
        $uibModal.open({
            animation: true,
            template: require('./widgetShow.html'),
            size: 'lg',
            windowClass: 'widgetmodal',
            resolve: {
                widget: function () {
                    return widget;
                },
                height: function(){
                    // console.log(widget)
                    let height = 600;
                    if(widget && widget.widget) {
                        widget.widget.data.config.chart_type ==='kpi' ? height = 200 : null;
                        widget.widget.data.config.chart_type ==='kpi2' ? height = 200 : null;
                        widget.widget.data.config.chart_type ==='kpi3' ? height = 200 : null;
                        widget.widget.data.config.chart_type ==='kpi4' ? height = 200 : null;
                    }
                    return height;
                },
                insertBehaviroLog:function(){
                    return function (widget,callback) {
                        behaviorLogService.insertLog({module:"widget",function:"查看图表",name:widget.name}).then(function(){
                            callback();
                        })
                   };
                }
            },
            controller:['$scope', 'widget', 'height', 'insertBehaviroLog','$uibModalInstance',function ($scope, widget, height, insertBehaviroLog,$uibModalInstance) {
                $scope.widget = widget;
                $scope.height = height;
                try {
                    insertBehaviroLog(widget,function(){});
                } catch (error) {
                    console.log("插入日志失败:"+error)
                }

            }]
        });
    }


}

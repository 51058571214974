import {boardService, userService } from '../../../../server'

export default function($scope, $uibModalInstance, toastr, boardList, categoryList, boardId, ok){

    $scope.isLoading = true;                                    //加载状态

    $scope.boardList = boardList ? boardList : [];              //看板列表
    $scope.curBoard = {};                                       //当前的看板
    $scope.curBoard.id = boardId;                               //当前的看板的id
    $scope.showBoardList = [];                                  //筛选后的列表----显示用看板列表

    $scope.categoryList = categoryList ? categoryList : [];     //目录列表
    $scope.curCategoryId = "0";                                 //当前目录的id--默认为根目录
    $scope.curCategoryList = [];                                //当前目录列表-----不包括根目录
    $scope.curChildrenCategoryList = [];                        //当前子目录列表

    $scope.search = {};
    $scope.search.value = "";

    //分页
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 24,
        onChange: function () {

        }
    };
    //获取筛选后看板的总数
    var getPaginationConfTotal = function(){
        $scope.paginationConf.totalItems = $scope.showBoardList ? $scope.showBoardList.length : 0;
    };

    $scope.showBoardListMax = function(){
        return $scope.paginationConf.currentPage * $scope.paginationConf.itemsPerPage - 1;
    };
    $scope.showBoardListMin = function(){
        return $scope.paginationConf.currentPage * $scope.paginationConf.itemsPerPage - $scope.paginationConf.itemsPerPage;
    };

    //确定的方法
    $scope.ok = function(){
        //只有重新选择后才执行ok的方法
        if($scope.curBoard.id != boardId) {
            ok($scope.curBoard);
        }
        $uibModalInstance.close();
    };

    $scope.close = function(){
        $uibModalInstance.close();
    };

    //选择目录的方法
    $scope.selectCategory = function(category){
        $scope.isLoading = true;
        if(category) {
            $scope.curCategoryId = category.id;
            $scope.curCategoryList = [];
            $scope.curChildrenCategoryList = [];

            var curCategoryList = [];
            var curParentIds = category.parentIds.split(",");
            //筛选出当前目录和子目录
            _.each($scope.categoryList, function(e){
                if(e.parentId && e.parentId == category.id) {
                    $scope.curChildrenCategoryList.push(e);
                }
                if(curParentIds.indexOf(e.id) != -1 ) {
                    curCategoryList.push(e);
                }
            });
            //第一个父id为根目录，不循环
            for(var i = 1; i < curParentIds.length; i++) {
                //最后有个逗号导致出现空字符串
                if (curParentIds[i]) {
                    _.each(curCategoryList, function (ele) {
                        if(curParentIds[i] === ele.id){
                            $scope.curCategoryList.push(ele);
                        }
                    });
                }
            }
            $scope.curCategoryList.push(category);
            getshowBoardList();
        } else {
            $scope.curCategoryId = "0";
            $scope.curCategoryList = [];
            $scope.curChildrenCategoryList = _.filter($scope.categoryList, function (e) {
                return e.parentId === "0"
            });
            getshowBoardList();
        }
    };

    //获取当前目录下的所有子目录包括自己的id---根目录0不在$scope.categoryList列表里,已排除
    var getAllCategoryIdById = function(categoryId){
        var allCategoryIdById = [];
        _.each($scope.categoryList, function(category){
            if(categoryId == category.id) {
                allCategoryIdById.push(category.id);
            } else {
                var parentIds = [];
                parentIds = category.parentIds.split(",");
                if(parentIds.indexOf(categoryId) != -1){
                    allCategoryIdById.push(category.id);
                }
            }
        });
        return allCategoryIdById;
    };

    //搜索的方法
    $scope.search = function() {
        $scope.isLoading = true;
        getshowBoardList();
    };

    //获取筛选的看板列表
    var getshowBoardList = function(){
        if($scope.boardList && $scope.boardList.length > 0) {
            $scope.showBoardList = []; 
            //当前目录及其子目录
            var curIds = getAllCategoryIdById($scope.curCategoryId);
            var i = 0, len = $scope.boardList.length;
            var flag = 0;
            for(; i< len; i++) {
                //看板的目录不是当前目录及其子目录，或 搜索关键字存在且不符合看板标题，则跳过
                if( curIds.indexOf($scope.boardList[i].categoryId) == -1 || ($scope.search.value && $scope.boardList[i].name.indexOf($scope.search.value) == -1)) {
                    continue;
                }
                $scope.showBoardList.push($scope.boardList[i]); 
            }
            //分页的看板总数
            getPaginationConfTotal();

        } else {
            $scope.showBoardList = []; 
            getPaginationConfTotal();
        }
        $scope.isLoading = false;
    };

    //展示看板的方法---先放着
    $scope.showBoard = function(board){
        window.open("/fullScreenBoard.html?id="+board.id);
        //
    };

    //选择看板的方法
    $scope.selectBoard = function(board){
        $scope.curBoard = board;
    };

    //初始化
    //没有传看板列表的情况
    if($scope.boardList.length == 0) {
        //获取所有的看板
        // boardService.getBoardList().then(function (data) {
        //     $scope.boardList = data;
        //     $scope.selectCategory();
        // }, err => { });
        boardService.getBoardColList({
            "pageNumber": 1,
            "pageSize": 999,
            "categoryId": null,
            "search": ""
        }).then(function(response) {
            $scope.boardList = response.listdata;
            $scope.$apply();
            $scope.selectCategory();
        });
    }
    //没有传目录列表的情况
    if($scope.categoryList.length == 0) {
        //重新获取目录列表
        boardService.getCategoryList().then(function(response) {
            $scope.categoryList = response;
            $scope.$apply();
            $scope.selectCategory();
        });
    }

    //目录和看板列表均传了值
    if($scope.categoryList.length > 0 && $scope.boardList.length > 0){
        //模拟点击根目录
        $scope.selectCategory();
    }

    //目录树部分 start
    $scope.categoryListTree = {
        'current' : {
            'name': '根目录',
            'id' : '0'
        },
        'children' : []
    };
    function setCategoryTree(list, target){
        target.children = [];
        _.each(list, function(e){
            if(e.parentId == target.current.id) {
                if(target.children) {
                    target.children[target.children.length] = {
                        'current' : {
                            'name': e.name,
                            'id' : e.id
                        },
                        'children' : []
                    };
                } else {
                    target.children = [{
                        'current' : {
                            'name': e.name,
                            'id' : e.id
                        },
                        'children' : []
                    }];
                }
            }
        });
        if(target.children && target.children.length > 0) {
            for(var i = 0; i< target.children.length; i++) {
                setCategoryTree(list, target.children[i]);
            }
        }
    }
    $scope.myCategoryTreeShow = false;
    $scope.myCategoryTreeToggle = function(){
        $scope.myCategoryTreeShow = !$scope.myCategoryTreeShow;
    };
    $scope.$watch('categoryList', function(){
        setCategoryTree($scope.categoryList, $scope.categoryListTree);
    });

    $scope.$watch('curCategoryId', function(){
        if($scope.curCategoryId !== "0" && $scope.categoryList.length > 0) {
            var c = _.filter($scope.categoryList, function(e){
                return e.id === $scope.curCategoryId;
            });
            $scope.selectCategory(c[0]);
        } else {
            $scope.selectCategory();
        }
    });
    //目录树部分 end

}
import userRes from './userRes.html'
import userResCtrl from './userResCtrl'

export default angular.module('borepc.pages.userResCtrl', [])
    .controller('userResCtrl', ['$scope', '$uibModal',  'ModalUtils', '$filter','toastr',userResCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('userRes', {
            url: '/userRes',
            title: '资源权限',
            controller: 'userResCtrl',
            template: userRes
        })
}
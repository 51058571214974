/*
 * @Description: 
 * @Autor: zinch
 * @Date: 2021-03-18 09:49:44
 * @LastEditTime: 2021-07-23 12:14:10
 */
 
import  UserProfilePageCtrl from './UserProfilePageCtrl';
import  UserProfileModalCtrl  from  './UserProfileModalCtrl';

require ('./_userProfile.scss')
export default angular.module('borepc.pages.userProfile', [])
       .controller('UserProfilePageCtrl',['$scope', '$filter', 'toastr','ModalUtils',UserProfilePageCtrl])
       .controller('UserProfileModalCtrl', ['$scope', '$uibModalInstance',UserProfileModalCtrl])
       .config(['$stateProvider',routeConfig]).name;

  function routeConfig($stateProvider) {
    $stateProvider
        .state('userProfile', {
          url: '/userprofile',
          title: '用户中心',
          template:  require('./userprofile.html'),
          controller: 'UserProfilePageCtrl',
        });
  }


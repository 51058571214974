import BasicService from './BasicService';
import { DATASUPPORTAPI } from './ApiServer';
/**
 */
class DataSupportService extends BasicService {
    constructor () {
        super();
    }

    getDataSourceList (params) {
        return this.post(DATASUPPORTAPI.GETDATASOURCELIST, params)
    }

    getAllTableName (params) {
        return this.post(DATASUPPORTAPI.GETALLTABLENAME, params)
    }

    getSchemaData (params) {
        return this.post(DATASUPPORTAPI.GETSCHEMADATA, params)
    }

    getTableData (params) {
        return this.post(DATASUPPORTAPI.GETTABLEDATA, params)
    }

    save (params) {
        return this.post(DATASUPPORTAPI.SAVE, params)
    }

    delete (params) {
        return this.post(DATASUPPORTAPI.DELETE, params)
    }

}

export const dataSupportService = new DataSupportService();
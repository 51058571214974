import {prewarningService,userAdminService,userService} from '../../../server'
import windowTpl from '../../../common/theme/components/modal/window.html'
import './_prewarning.scss'
export default  function prewarningEditCtrl ($scope, $uibModal, ok, userList, $uibModalInstance, originType, ModalUtils,  prewarning) {
    $scope.types = ['=', '≠', '>', '<', '≥', '≤', '(a,b]', '[a,b)', '(a,b)', '[a,b]'];
    // $scope.times = ['分', '时', '日', '周', '月', '年'];
    $scope.times = ['分','时', '日', '月'];
    $scope.userList = angular.copy(userList);
    $scope.prewarning = angular.copy(prewarning);
    if(!$scope.userList){
      //获取用户信息
      userAdminService.postUsers({pageNo:1,pageSize:9999}).then(function (response) {
         $scope.userList = response.listdata;
         $scope.$apply();
     },err=>{
         toastr.error(err.head.errorMsg)
     });
  }

    var getBindUserIds = function (callback) {
        userService.getBindUserIdByCompany({"type":"1"}).then(function (response) {
            if(response.status=='1'){
                $scope.bindUsers = response.data;
            }
            $scope.$apply();
            $scope.isBindWechat =function(userId){
                if($scope.bindUsers && $scope.bindUsers.indexOf(userId)!=-1)return true;
                return false;
            }
            if (callback) {
                callback();
            }
        });
    }
    getBindUserIds();

    var getBindEnWxUserIds = function (callback) {
      userService.getBindUserIdByCompany({"type":"5"}).then(function (response) {
          if(response.status=='1'){
              $scope.bindEnWxUsers = response.data;
          }
          $scope.$apply();
          $scope.isBindEnWechat =function(userId){
              if($scope.bindEnWxUsers && $scope.bindEnWxUsers.indexOf(userId)!=-1)return true;
              return false;
          }
          if (callback) {
              callback();
          }
      });
  }
  getBindEnWxUserIds();


    //关闭弹窗
    $scope.close = function () {
        $uibModalInstance.close();
    };
    //确认按钮
    $scope.ok = function (isValidate) {
        if (isValidate || !$scope.prewarning.userIds || $scope.prewarning.userIds.length == 0) {
            ModalUtils.alert("请输入必填项!", "modal-warning", "lg");
            return;
        }
        if (originType == "warnEdit") { //当从预警列表页面跳转过来时需要关闭
            $uibModalInstance.close();
            ok($scope.prewarning); //向主界面传递参数
        } else { //从图表页面跳转过来增加预警时需要保存图表，因此提示一下用户
            ModalUtils.confirm("新增预警同时将保存图表,确定吗?", "modal-warning", "lg", function () {
                $uibModalInstance.close();
                ok($scope.prewarning);
                return;
            })
        }

    };

    //删除用户
    $scope.delUser = function (user) {
        var userIds = angular.copy($scope.prewarning.userIds);
        $scope.prewarning.userIds = userIds.filter(function(id){
            return id!=user.id
        });
    }

    //前往新增用户页面
    $scope.gotoUserPage = function () {
        if (!$scope.userList) {
            ModalUtils.alert("获取用户列表失败!", "modal-warning", "lg");
            return;
        }
        $uibModal.open({
            template: require('../user/userSelectedList.html'),
            windowTemplate: windowTpl,
            size: 'lg',
            windowClass: 'pw-user-modal',
            resolve: {
                userList: function () {
                    return $scope.userList;
                },
                selectIds: function () {
                    return $scope.prewarning.userIds;
                },
                bindUsers:function(){
                    return $scope.bindUsers;
                },
                bindEnWxUsers:function(){
                  return $scope.bindEnWxUsers;
                },
                ok: function () {
                    return function (param) {
                        $scope.prewarning.userIds = param;
                    }
                }
            },
            controller: ['$scope', '$uibModalInstance',   'selectIds', 'ok','bindUsers','bindEnWxUsers',function ($scope, $uibModalInstance,   selectIds, ok,bindUsers,bindEnWxUsers) {
                $scope.selectIds = angular.copy(selectIds) || [];
                // $scope.userList = angular.copy(userList);
                $scope.bindUsers = angular.copy(bindUsers)
                $scope.bindEnWxUsers = angular.copy(bindEnWxUsers)
                $scope.paginationConf = {
                    currentPage: 1,
                    totalItems: 0,
                    itemsPerPage: 15,
                    onChange: function () {
                        $scope.getUserList(); //重新加载
                    }
                };

                $scope.getUserList =function(){
                    var params = {
                        "pageNo": $scope.paginationConf.currentPage,
                        "pageSize": $scope.paginationConf.itemsPerPage,
                        "name":$scope.search
                    }
                    userAdminService.postUsers(params).then(function (response) {
                        $scope.userList = response.listdata;
                        $scope.paginationConf.totalItems = response.totolCount;
                        $scope.ifCheckedAll();
                        $scope.$apply();
                    },err=>{
                        toastr.error(err.head.errorMsg)
                    });
                }

                //获取已经绑定了微信的用户
                $scope.isBindWechat =function(userId){
                    if($scope.bindUsers && $scope.bindUsers.indexOf(userId)!=-1)return true;
                    return false;
                }

                //获取已经绑定了微信的用户
                $scope.isBindEnWechat =function(userId){
                  if($scope.bindEnWxUsers && $scope.bindEnWxUsers.indexOf(userId)!=-1)return true;
                  return false;
              }

                //更新选择的用户
                $scope.updateSelection = function ($event, id) {
                    //只有被选中的时候才添加到ids集合中
                    if ($event.target.checked) {
                        $scope.selectIds.push(id); //push向集合中添加元素
                    } else {
                        //如果没被选中则删除集合中的元素
                        var index = $scope.selectIds.indexOf(id); //查找值的位置
                        $scope.selectIds.splice(index, 1); //参数1:移除的位置 参数2:移除的个数
                    }
                }


                //判断是否选择好了
                $scope.ifChecked = function (id) {
                    //如果Id出现在数组中，则需要返回true
                    var index = $scope.selectIds.indexOf(id);
                    if (index == -1) {
                        return false;
                    } else { //如果id没有出现在selectIds数组中需要返回false
                        return true;
                    }
                };

                //判断是否全选了
                $scope.ifCheckedAll = function () {
                    //$scope.userList中的对象的id 是否都在 $scope.selectIds中
                    //如果全中，则返回true
                    //如果有一个还在，就返回false
                    var isCheckedAll = true;
                    if(!$scope.userList) return false;
                    for (var i = 0; i < $scope.userList.length; i++) {
                        if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                            isCheckedAll = false;
                            break;
                        }
                    }
                    return isCheckedAll;
                };

                //全选与取消全选
                $scope.selectAll = function ($event) {
                    if ($event.target.checked) {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            //当前页面的数据的id放到数组中
                            if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                                $scope.selectIds.push($scope.userList[i].id);
                            }
                        }
                    } else {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            var index = $scope.selectIds.indexOf($scope.userList[i].id);
                            $scope.selectIds.splice(index, 1);
                        }
                    }
                };

                //关闭
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                //确定
                $scope.ok = function () {
                    $uibModalInstance.close();
                    ok($scope.selectIds);
                };
            }]
        });
    }

    // 二级弹窗的再次弹框时，bootstrap的zindex增加不是基于二级弹窗而是基于一级弹窗。
    // 所以二级弹窗的zindex初始值应该比一级弹窗的初始值低一些，否则会导致三级弹窗的zindex低于二级弹窗
    $(document).ready(function() {
        // 通过该方法来为每次弹出的模态框设置最新的zIndex值，从而使最新的modal显示在最前面
        $(document).on('show.bs.modal', '.modal', function() {
            var zIndex = 1032 + (10 * $('.modal:visible').length);//二级弹窗初始值为1032+，一级为1040+，所以三级再弹就是1050+
            console.log("pwEdit"+zIndex,zIndex)
            $(this).css('z-index', zIndex);
            setTimeout(function() {
                $('.modal-backdrop').not('.modal-stack').css('z-index', zIndex-1).addClass('modal-stack');
            }, 0);
        });
    });
};

import {timerPushService} from '../../../../server'
import '../_timerPush.scss'


export default  function timerLogListCtrl( toastr,$scope, $state, ModalUtils ) {
    $scope.btLogList =[];
    $scope.isLoading = true;
    $scope.search = {};
    $scope.status = {};
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 18,
        onChange: function () {
            $scope.getBtLogList(); //重新加载
        }
    };

    $scope.gotoDetail = function(id){
        $state.go('timerLogDetail', {id: id});
    }

     //获取列表
     $scope.getBtLogList = function(){
        var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "search":$scope.search.value,
            "status":$scope.status.value
        }
        timerPushService.getBtLogList(params).then(function (response) {
            $scope.isLoading = false;
            if(response.status=='1'){
                $scope.btLogList = response.data.listdata || [];
                if($scope.btLogList.length >0){
                    angular.forEach($scope.btLogList,function(pwData){
                        pwData.frequency =angular.fromJson(pwData.frequency);
                    })
                }
                $scope.paginationConf.totalItems = response.data.totolCount; 
                $scope.$apply();
                //toastr.success("获取日志列表成功!");
            }else{
                toastr.error("获取日志列表失败");
            }
        },err=>{
            toastr.error(err.head.errorMsg)
        })
    }

    //删除
    $scope.delBtLog = function(data,index){
        ModalUtils.confirm("确认删除日志吗?", "modal-info", "lg", function () {
            timerPushService.delBtLog({id:data.id}).then(function (response) {
                toastr.success("成功");
            },err=>{
                toastr.error(err.head.errorMsg)
            })
        });
    }

}

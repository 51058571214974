import displayTpl from './display.html'
require('./_display.scss')
require('./lib/timeline.js')
import displayCtrl from './DisplayCtrl'
import fullScreenBoardCtrl from '../fullScreenBoard/fullScreenBoardCtrl'
import boardViewCrtl from './boardViewCrtl'
export default angular.module('borepc.pages.dashboard.dbdisplay', [])
    .directive('sideboardHidden', [function(){
      return {
          restrict: 'A',
          link: function(scope, elem) {
            elem.on('click', function($evt) {
              var sideboard = elem.parent().parent();
              scope.$apply(function() {
                sideboard.prev().css("display","block");
                sideboard.css("display","none").next().css("width","calc(100% + 15px)");
              });
            });
          }
        };
    }])    
    .directive('sideboardShow', [function(){
      return {
        restrict: 'A',
        link: function(scope, elem) {
          elem.on('click', function($evt) {
            scope.$apply(function() {
              elem.css("display","").next().css("display","").next().css("width","");
            });
          });
        }
      };
    }])
    .directive('displayGrid', function () {
        return {
            template: require('./grid.html'),
            scope: false
        }
    })
    .directive('displayTimeline', function () {
        return {
            template: require('./timeline.html'),
            scope: false
        }
    }).directive('boardView', function () {
        return {
            template: require('./boardView.html'),
            controller: ['$scope', '$sce','toastr', 'archivesDetailModal', boardViewCrtl],
            restrict: 'EA',
            scope: false
        }
    })
    .controller('displayCtrl', ['$scope', 'toastr', '$rootScope', '$window', '$state', '$stateParams', 'ModalUtils', displayCtrl])
    .controller('fullScreenBoardCtrl', ['$scope', 'toastr', '$rootScope', '$window', '$state', '$stateParams', 'ModalUtils','$location', fullScreenBoardCtrl])
    .config(['$stateProvider', routeConfig]).name;
function routeConfig($stateProvider) {
    $stateProvider
        .state('display', {
            url: '/display?id&param',
            title: '分析看板',
            params: { id: null,param: null },
            template: displayTpl,
            controller: 'displayCtrl',
        });
}


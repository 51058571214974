import { dataService, boardService, userService,behaviorLogService } from '../../../../server'
import { storage,util} from '../../../../common/utils';
import PubSub from '../../../../common/utils/PubSub'
export default function DashbaordCtrl($scope, toastr, $rootScope, $window, $state, $stateParams, ModalUtils) {
    //初始化 初始化数据集配置loadDataSet-》初始化看板数据loadBoard -》初始化图表数据
    $scope.board = {};
    $scope.loading = true;
    $scope.datasetList = {};
    // $scope.categoryId = $stateParams.categoryId;
    $scope.boardId = $stateParams.id;

   //全屏
   $scope.fullScreen = function () {
        // var name = "_blank";
        // var feature = "fullscreen=yes,channelmode=yes, titlebar=no, toolbar=no, scrollbars=no," +
        //     "resizable=yes, status=no, copyhistory=no, location=no, menubar=no,width="+(screen.availWidth) +
        //     "height="+(screen.availHeight);
        window.open("/fullScreenBoard.html?id="+$scope.board.id,'');
    }
    //看板跳转参数处理
    if ($stateParams.param) {
        $scope.urlParam = {
            param: util.isEmpty($stateParams.param)?{}:JSON.parse(decodeURI($stateParams.param)),
            boardId: $stateParams.id
        }
    }

    if (PubSub.hasSubscribers('widgetChange')) {
        PubSub.unsubscribe('widgetChange')
    }
    //看板关联 跳转
    PubSub.subscribe('widgetChange', (data, topic) => {
        loadBoard()
    });
    if (PubSub.hasSubscribers('boardLink')) {
        PubSub.unsubscribe('boardLink')
    }
    //看板关联 跳转
    PubSub.subscribe('boardLink', (data, topic) => {
        // console.log(data)
        var param = JSON.stringify(data.param);
        var url = $state.href('display', { id: data.targetId, param: encodeURI(param) });
        $window.open(url, '_blank');
    });

    //跳转到其它页面后，注销接收广播
    $scope.$on('$destroy', function(){
        if (PubSub.hasSubscribers('widgetChange')) {
            PubSub.unsubscribe('widgetChange');
        }
        // 离开时还原网页标题
        document.title = 'Ourbore分析云';
    });





    var getBoardList = function () {
        boardService.getBoardList({ categoryId: $scope.categoryId }).then(function (response) {
            $scope.boardList = response;
            $scope.categoryName = response[0].categoryName;
            $scope.$apply()
            // loadDataSet();
        })
    }

    var loadBoard = function (reload) {
      $scope.loading = true;
      userService.judgePermission({resType:'board',resId:$scope.boardId,option:'query'}).then(function(data){
          if(data){
              $scope.getBoardData(reload)
          }else{
              toastr.error("您没有查看看板权限!");
              $scope.loading = false;
          }
      })
    }

    var loadDataSet = function (params) {
        dataService.getDataSetList().then(function (dataset) {
            $scope.datasetList = dataset;
            loadBoard()
        })
    }
    loadDataSet();

    $scope.getBoardData =function(reload){
        boardService.getBoardData($scope.boardId).then(function (boardData) {
            // boardContext.initBoardContext($scope.boardId)
            $scope.board = boardData;
            //原有看板没有这些设置
            if(!$scope.board.layout.cache){
                $scope.board.layout.cache = {
                    dataCache: {
                        type: 'board',
                        isCache: false,
                        expireTime: 0
                    },
                    dimensionCache: {
                        type: 'board',
                        isCache: true,
                        expireTime: '1,天'
                    }
                };
            }
            if(!$scope.board.layout.watermark) {
                $scope.board.layout.watermark = {
                    isShow: false,
                    customText: '',
                    isShowDate: true,
                    isShowUsername: true
                };
            }

            if ($scope.urlParam && ( $scope.urlParam.param.length > 0 ) && $scope.urlParam.boardId == $scope.boardId) {
                let urlParam = $scope.urlParam;
                _.each($scope.board.layout.rows, function (row) {
                    _.each(row.params, function (param) {
                        param.values = [];  // 先清空默认值
                        _.each(urlParam.param, (url_param) => {
                            if (param.name == url_param.col){
                              if(url_param.values.length > 0) {
                                switch(param.paramType) {
                                  case 'selector': url_param.type === '=' && ( param.values = param.checktype === 'radio' ? [url_param.values[0]] : [ ...url_param.values ] ); break;
                                  case 'pull'    : url_param.type === '=' && ( param.values = param.pulltype === 'radio' ? [url_param.values[0]] : [ ...url_param.values ] ); break;
                                  case 'popup'   : url_param.type === param.type && ( param.values = [ ...url_param.values ] ); break;
                                  case 'datetime':
                                  case 'slider'  : url_param.values.length > 1 && ( param.values = [ url_param.values[0], url_param.values[1] ] ); break;
                                }
                              }
                                // if (param.values.length == 0 || (param.checktype=="radio"&&url_param.values.length>0)) {
                                //     param.values = url_param.values;
                                // }else if (param.values.length > 0 && url_param.type == '=') {
                                //     _.each(url_param.values, (value) => {
                                //         param.values.push(value);
                                //     })
                                //     param.values = _.uniq(param.values)
                                // }
                            }
                        })
                    });
                });
            }
            $scope.loading = false;
            document.title = $scope.board.name;
            $scope.categoryId = $scope.board.categoryId;
            if (!reload) getBoardList();
            $scope.$apply()
            $scope.insertLog();
        }, err => {
            toastr.error(err.head.errorMsg)
        });
    }
    $scope.insertLog =function(){
        try {
            behaviorLogService.insertLog({module:"board",function:"查看看板",name:$scope.board.name})
        } catch (error) {

        }

    }

    //选择看板
    $scope.selectBoard = function (board) {
        $scope.boardId = board.id;
        setBrowseRecords(board);
        loadBoard(true);
    }

    //记录浏览历史记录
    var setBrowseRecords = function (board) {
        if (!board) return;
        var userInfo = JSON.parse(window.localStorage.getItem("userInfo"))
        var browseRecord = {
            type: 'board',
            id: board.id,
            name: board.name,
            categoryId: $scope.categoryId,
            categoryName: $scope.categoryName,
            userId: userInfo.id,
            companyId: userInfo.companyId,
            dataTime: Date.parse(new Date())
        }
        // console.log("storage.get('browseRecords')", storage.get('browseRecords'))
        var browseRecords = storage.get('browseRecords') || [];
        browseRecords.unshift(browseRecord);
        // if(browseRecords.length >20)browseRecords = browseRecords.slice(0,20);
        storage.set("browseRecords", browseRecords)
    }

    //前往编辑页面
    $scope.gotoEdit = function (id) {
        userService.judgePermission({
            option: "update",
            resType: "board",
            resId: id
        }).then(function (data) {
            if (data) {
                ModalUtils.confirm("即将离开预览页面，前往编辑页面，确定吗？", "modal-warning", "lg", function () {
                    $state.go('layout', {
                        boardId: id
                    });
                })
            } else {
                toastr.error("您没有编辑该看板权限！")
            }
        }, err => {
            toastr.error('获取看板编辑权限失败！' + err.head.errorMsg)
        })
    }

    $scope.backToBoardList = function () {
        $state.go('boardList',{ category: $scope.categoryId });
        // ModalUtils.confirm("即将离开预览页面，返回列表页面，确定吗？", "modal-warning", "lg", function () {

        // })
    }

    $scope.haveRelationBoard = function (widget) {
        if (!widget.relations || !widget.relations.relations) return false;
        var relations = widget.relations.relations;
        var boardRelation = _.find(relations, function (relation) {
            return relation.type == 'board';
        })
        if (boardRelation && boardRelation.targetId) return true;
        return false;
    }

    $scope.goRelationBoard = function (widget) {
        var boardRelation = _.find(widget.relations.relations, function (relation) {
            return relation.type == 'board';
        })
        $state.go('display', { id: boardRelation.targetId })
    }

}

require('./underscore-min.js')
import numbro from 'numbro'
/**
 * 此处 编写工具方法
 */

// 用于处理merge时无法遍历Date等对象的问题
var BUILTIN_OBJECT = {
    '[object Function]': 1,
    '[object RegExp]': 1,
    '[object Date]': 1,
    '[object Error]': 1,
    '[object CanvasGradient]': 1,
    '[object CanvasPattern]': 1,
    // For node-canvas
    '[object Image]': 1,
    '[object Canvas]': 1
};

var TYPED_ARRAY = {
    '[object Int8Array]': 1,
    '[object Uint8Array]': 1,
    '[object Uint8ClampedArray]': 1,
    '[object Int16Array]': 1,
    '[object Uint16Array]': 1,
    '[object Int32Array]': 1,
    '[object Uint32Array]': 1,
    '[object Float32Array]': 1,
    '[object Float64Array]': 1
};

var objToString = Object.prototype.toString;

var arrayProto = Array.prototype;
var nativeForEach = arrayProto.forEach;
var nativeFilter = arrayProto.filter;
var nativeSlice = arrayProto.slice;
var nativeMap = arrayProto.map;
var nativeReduce = arrayProto.reduce;

// Avoid assign to an exported variable, for transforming to cjs.
var methods = {};

export function $override(name, fn) {
    // Clear ctx instance for different environment
    if (name === 'createCanvas') {
        _ctx = null;
    }

    methods[name] = fn;
}

/**
 * Those data types can be cloned:
 *     Plain object, Array, TypedArray, number, string, null, undefined.
 * Those data types will be assgined using the orginal data:
 *     BUILTIN_OBJECT
 * Instance of user defined class will be cloned to a plain object, without
 * properties in prototype.
 * Other data types is not supported (not sure what will happen).
 *
 * Caution: do not support clone Date, for performance consideration.
 * (There might be a large number of date in `series.data`).
 * So date should not be modified in and out of echarts.
 *
 * @param {*} source
 * @return {*} new
 */
export function clone(source) {
    if (source == null || typeof source !== 'object') {
        return source;
    }

    var result = source;
    var typeStr = objToString.call(source);

    if (typeStr === '[object Array]') {
        if (!isPrimitive(source)) {
            result = [];
            for (var i = 0, len = source.length; i < len; i++) {
                result[i] = clone(source[i]);
            }
        }
    }
    else if (TYPED_ARRAY[typeStr]) {
        if (!isPrimitive(source)) {
            var Ctor = source.constructor;
            if (source.constructor.from) {
                result = Ctor.from(source);
            }
            else {
                result = new Ctor(source.length);
                for (var i = 0, len = source.length; i < len; i++) {
                    result[i] = clone(source[i]);
                }
            }
        }
    }
    else if (!BUILTIN_OBJECT[typeStr] && !isPrimitive(source) && !isDom(source)) {
        result = {};
        for (var key in source) {
            if (source.hasOwnProperty(key)) {
                result[key] = clone(source[key]);
            }
        }
    }

    return result;
}

/**
 * @memberOf module:zrender/core/util
 * @param {*} target
 * @param {*} source
 * @param {boolean} [overwrite=false]
 */
export function merge(target, source, overwrite) {
    // We should escapse that source is string
    // and enter for ... in ...
    if (!isObject(source) || !isObject(target)) {
        return overwrite ? clone(source) : target;
    }

    for (var key in source) {
        if (source.hasOwnProperty(key)) {
            var targetProp = target[key];
            var sourceProp = source[key];

            if (isObject(sourceProp)
                && isObject(targetProp)
                && !isArray(sourceProp)
                && !isArray(targetProp)
                && !isDom(sourceProp)
                && !isDom(targetProp)
                && !isBuiltInObject(sourceProp)
                && !isBuiltInObject(targetProp)
                && !isPrimitive(sourceProp)
                && !isPrimitive(targetProp)
            ) {
                // 如果需要递归覆盖，就递归调用merge
                merge(targetProp, sourceProp, overwrite);
            }
            else if (overwrite || !(key in target)) {
                // 否则只处理overwrite为true，或者在目标对象中没有此属性的情况
                // NOTE，在 target[key] 不存在的时候也是直接覆盖
                target[key] = clone(source[key], true);
            }
        }
    }

    return target;
}

/**
 * @param {Array} targetAndSources The first item is target, and the rests are source.
 * @param {boolean} [overwrite=false]
 * @return {*} target
 */
export function mergeAll(targetAndSources, overwrite) {
    var result = targetAndSources[0];
    for (var i = 1, len = targetAndSources.length; i < len; i++) {
        result = merge(result, targetAndSources[i], overwrite);
    }
    return result;
}

/**
 * @param {*} target
 * @param {*} source
 * @memberOf module:zrender/core/util
 */
export function extend(target, source) {
    for (var key in source) {
        if (source.hasOwnProperty(key)) {
            target[key] = source[key];
        }
    }
    return target;
}

/**
 * @param {*} target
 * @param {*} source
 * @param {boolean} [overlay=false]
 * @memberOf module:zrender/core/util
 */
export function defaults(target, source, overlay) {
    for (var key in source) {
        if (source.hasOwnProperty(key)
            && (overlay ? source[key] != null : target[key] == null)
        ) {
            target[key] = source[key];
        }
    }
    return target;
}

export var createCanvas = function () {
    return methods.createCanvas();
};

methods.createCanvas = function () {
    return document.createElement('canvas');
};

// FIXME
var _ctx;

export function getContext() {
    if (!_ctx) {
        // Use util.createCanvas instead of createCanvas
        // because createCanvas may be overwritten in different environment
        _ctx = createCanvas().getContext('2d');
    }
    return _ctx;
}

/**
 * 查询数组中元素的index
 * @memberOf module:zrender/core/util
 */
export function indexOf(array, value) {
    if (array) {
        if (array.indexOf) {
            return array.indexOf(value);
        }
        for (var i = 0, len = array.length; i < len; i++) {
            if (array[i] === value) {
                return i;
            }
        }
    }
    return -1;
}

/**
 * 构造类继承关系
 *
 * @memberOf module:zrender/core/util
 * @param {Function} clazz 源类
 * @param {Function} baseClazz 基类
 */
export function inherits(clazz, baseClazz) {
    var clazzPrototype = clazz.prototype;
    function F() { }
    F.prototype = baseClazz.prototype;
    clazz.prototype = new F();

    for (var prop in clazzPrototype) {
        clazz.prototype[prop] = clazzPrototype[prop];
    }
    clazz.prototype.constructor = clazz;
    clazz.superClass = baseClazz;
}

/**
 * @memberOf module:zrender/core/util
 * @param {Object|Function} target
 * @param {Object|Function} sorce
 * @param {boolean} overlay
 */
export function mixin(target, source, overlay) {
    target = 'prototype' in target ? target.prototype : target;
    source = 'prototype' in source ? source.prototype : source;

    defaults(target, source, overlay);
}

/**
 * Consider typed array.
 * @param {Array|TypedArray} data
 */
export function isArrayLike(data) {
    if (!data) {
        return;
    }
    if (typeof data === 'string') {
        return false;
    }
    return typeof data.length === 'number';
}

/**
 * 数组或对象遍历
 * @memberOf module:zrender/core/util
 * @param {Object|Array} obj
 * @param {Function} cb
 * @param {*} [context]
 */
export function each(obj, cb, context) {
    if (!(obj && cb)) {
        return;
    }
    if (obj.forEach && obj.forEach === nativeForEach) {
        obj.forEach(cb, context);
    }
    else if (obj.length === +obj.length) {
        for (var i = 0, len = obj.length; i < len; i++) {
            cb.call(context, obj[i], i, obj);
        }
    }
    else {
        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                cb.call(context, obj[key], key, obj);
            }
        }
    }
}

/**
 * 数组映射
 * @memberOf module:zrender/core/util
 * @param {Array} obj
 * @param {Function} cb
 * @param {*} [context]
 * @return {Array}
 */
export function map(obj, cb, context) {
    if (!(obj && cb)) {
        return;
    }
    if (obj.map && obj.map === nativeMap) {
        return obj.map(cb, context);
    }
    else {
        var result = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            result.push(cb.call(context, obj[i], i, obj));
        }
        return result;
    }
}

/**
 * @memberOf module:zrender/core/util
 * @param {Array} obj
 * @param {Function} cb
 * @param {Object} [memo]
 * @param {*} [context]
 * @return {Array}
 */
export function reduce(obj, cb, memo, context) {
    if (!(obj && cb)) {
        return;
    }
    if (obj.reduce && obj.reduce === nativeReduce) {
        return obj.reduce(cb, memo, context);
    }
    else {
        for (var i = 0, len = obj.length; i < len; i++) {
            memo = cb.call(context, memo, obj[i], i, obj);
        }
        return memo;
    }
}

/**
 * 数组过滤
 * @memberOf module:zrender/core/util
 * @param {Array} obj
 * @param {Function} cb
 * @param {*} [context]
 * @return {Array}
 */
export function filter(obj, cb, context) {
    if (!(obj && cb)) {
        return;
    }
    if (obj.filter && obj.filter === nativeFilter) {
        return obj.filter(cb, context);
    }
    else {
        var result = [];
        for (var i = 0, len = obj.length; i < len; i++) {
            if (cb.call(context, obj[i], i, obj)) {
                result.push(obj[i]);
            }
        }
        return result;
    }
}

/**
 * 数组项查找
 * @memberOf module:zrender/core/util
 * @param {Array} obj
 * @param {Function} cb
 * @param {*} [context]
 * @return {*}
 */
export function find(obj, cb, context) {
    if (!(obj && cb)) {
        return;
    }
    for (var i = 0, len = obj.length; i < len; i++) {
        if (cb.call(context, obj[i], i, obj)) {
            return obj[i];
        }
    }
}

/**
 * @memberOf module:zrender/core/util
 * @param {Function} func
 * @param {*} context
 * @return {Function}
 */
export function bind(func, context) {
    var args = nativeSlice.call(arguments, 2);
    return function () {
        return func.apply(context, args.concat(nativeSlice.call(arguments)));
    };
}

/**
 * @memberOf module:zrender/core/util
 * @param {Function} func
 * @return {Function}
 */
export function curry(func) {
    var args = nativeSlice.call(arguments, 1);
    return function () {
        return func.apply(this, args.concat(nativeSlice.call(arguments)));
    };
}

/**
 * @memberOf module:zrender/core/util
 * @param {*} value
 * @return {boolean}
 */
export function isArray(value) {
    return objToString.call(value) === '[object Array]';
}

/**
 * @memberOf module:zrender/core/util
 * @param {*} value
 * @return {boolean}
 */
export function isFunction(value) {
    return typeof value === 'function';
}

/**
 * @memberOf module:zrender/core/util
 * @param {*} value
 * @return {boolean}
 */
export function isString(value) {
    return objToString.call(value) === '[object String]';
}

/**
 * @memberOf module:zrender/core/util
 * @param {*} value
 * @return {boolean}
 */
export function isObject(value) {
    // Avoid a V8 JIT bug in Chrome 19-20.
    // See https://code.google.com/p/v8/issues/detail?id=2291 for more details.
    var type = typeof value;
    return type === 'function' || (!!value && type === 'object');
}

/**
 * @memberOf module:zrender/core/util
 * @param {*} value
 * @return {boolean}
 */
export function isBuiltInObject(value) {
    return !!BUILTIN_OBJECT[objToString.call(value)];
}

/**
 * @memberOf module:zrender/core/util
 * @param {*} value
 * @return {boolean}
 */
export function isTypedArray(value) {
    return !!TYPED_ARRAY[objToString.call(value)];
}

/**
 * @memberOf module:zrender/core/util
 * @param {*} value
 * @return {boolean}
 */
export function isDom(value) {
    return typeof value === 'object'
        && typeof value.nodeType === 'number'
        && typeof value.ownerDocument === 'object';
}

/**
 * Whether is exactly NaN. Notice isNaN('a') returns true.
 * @param {*} value
 * @return {boolean}
 */
export function eqNaN(value) {
    return value !== value;
}

/**
 * If value1 is not null, then return value1, otherwise judget rest of values.
 * Low performance.
 * @memberOf module:zrender/core/util
 * @return {*} Final value
 */
export function retrieve(values) {
    for (var i = 0, len = arguments.length; i < len; i++) {
        if (arguments[i] != null) {
            return arguments[i];
        }
    }
}

export function retrieve2(value0, value1) {
    return value0 != null
        ? value0
        : value1;
}

export function retrieve3(value0, value1, value2) {
    return value0 != null
        ? value0
        : value1 != null
            ? value1
            : value2;
}

/**
 * @memberOf module:zrender/core/util
 * @param {Array} arr
 * @param {number} startIndex
 * @param {number} endIndex
 * @return {Array}
 */
export function slice() {
    return Function.call.apply(nativeSlice, arguments);
}

/**
 * Normalize css liked array configuration
 * e.g.
 *  3 => [3, 3, 3, 3]
 *  [4, 2] => [4, 2, 4, 2]
 *  [4, 3, 2] => [4, 3, 2, 3]
 * @param {number|Array.<number>} val
 * @return {Array.<number>}
 */
export function normalizeCssArray(val) {
    if (typeof (val) === 'number') {
        return [val, val, val, val];
    }
    var len = val.length;
    if (len === 2) {
        // vertical | horizontal
        return [val[0], val[1], val[0], val[1]];
    }
    else if (len === 3) {
        // top | horizontal | bottom
        return [val[0], val[1], val[2], val[1]];
    }
    return val;
}

/**
 * @memberOf module:zrender/core/util
 * @param {boolean} condition
 * @param {string} message
 */
export function assert(condition, message) {
    if (!condition) {
        throw new Error(message);
    }
}

/**
 * @memberOf module:zrender/core/util
 * @param {string} str string to be trimed
 * @return {string} trimed string
 */
export function trim(str) {
    if (str == null) {
        return null;
    }
    else if (typeof str.trim === 'function') {
        return str.trim();
    }
    else {
        return str.replace(/^[\s\uFEFF\xA0]+|[\s\uFEFF\xA0]+$/g, '');
    }
}

var primitiveKey = '__ec_primitive__';
/**
 * Set an object as primitive to be ignored traversing children in clone or merge
 */
export function setAsPrimitive(obj) {
    obj[primitiveKey] = true;
}

export function isPrimitive(obj) {
    return obj[primitiveKey];
}

/**
 * @constructor
 * @param {Object} obj Only apply `ownProperty`.
 */
function HashMap(obj) {
    var isArr = isArray(obj);
    // Key should not be set on this, otherwise
    // methods get/set/... may be overrided.
    this.data = {};
    var thisMap = this;

    (obj instanceof HashMap)
        ? obj.each(visit)
        : (obj && each(obj, visit));

    function visit(value, key) {
        isArr ? thisMap.set(value, key) : thisMap.set(key, value);
    }
}

HashMap.prototype = {
    constructor: HashMap,
    // Do not provide `has` method to avoid defining what is `has`.
    // (We usually treat `null` and `undefined` as the same, different
    // from ES6 Map).
    get: function (key) {
        return this.data.hasOwnProperty(key) ? this.data[key] : null;
    },
    set: function (key, value) {
        // Comparing with invocation chaining, `return value` is more commonly
        // used in this case: `var someVal = map.set('a', genVal());`
        return (this.data[key] = value);
    },
    // Although util.each can be performed on this hashMap directly, user
    // should not use the exposed keys, who are prefixed.
    each: function (cb, context) {
        context !== void 0 && (cb = bind(cb, context));
        for (var key in this.data) {
            this.data.hasOwnProperty(key) && cb(this.data[key], key);
        }
    },
    // Do not use this method if performance sensitive.
    removeKey: function (key) {
        delete this.data[key];
    }
};

export function createHashMap(obj) {
    return new HashMap(obj);
}

export function concatArray(a, b) {
    var newArray = new a.constructor(a.length + b.length);
    for (var i = 0; i < a.length; i++) {
        newArray[i] = a[i];
    }
    var offset = a.length;
    for (i = 0; i < b.length; i++) {
        newArray[i + offset] = b[i];
    }
    return newArray;
}


export function noop() { }






export function parseParams(data) {
    try {
        var tempArr = [];
        for (var i in data) {
            if (!data[i]) {
                continue;
            }
            var key = encodeURIComponent(i);
            var value = encodeURIComponent(data[i]);
            tempArr.push(key + '=' + value);
        }
        var urlParamsStr = tempArr.join('&');
        return urlParamsStr;
    } catch (err) {
        return '';
    }
}



// Is a given value equal to null?
export function isNull(obj) {
    return obj === null;
};

// Is a given variable undefined?
export function isUndefined(obj) {
    return obj === void 0;
};






/**
* Created by  jaray  2018-11-28
*/
// 生成随机字符串
export function randomStr() {
    return Math.random().toString(36).substring(2);
}

function render(template, context, tokenReg, hasDollarPrefix, resultProcessor) {
    return template.replace(tokenReg, function (word, slash1, token, slash2) {
        if (slash1 || slash2) {
            return word.replace('\\', '');
        }
        var variables = token.replace(/\s/g, '').split('.');
        var currentObject = context;
        var i, length, variable;
        for (i = 0, length = variables.length; i < length; ++i) {
            variable = variables[i];
            currentObject = currentObject[variable];
            if (currentObject === undefined || currentObject === null) {
                if (hasDollarPrefix === true) {
                    return '${' + token + '}';
                } else {
                    return '{' + token + '}';
                }
            }
        }
        if (resultProcessor) {
            return resultProcessor(currentObject);
        } else {
            return currentObject;
        }
    })
}

export var StringRender = function (str, context) {
    var tokenReg = /(\\)?\{([^\{\}\\]+)(\\)?\}/g;
    return render(str, context, tokenReg);
};

/**
 * 字符串模板变量替换 replace ${name} style variable
 * @param template
 * @param context
 * @returns {void|string|XML|*|{by}|{state, paramExpr}}
 */
export var StringRender2 = function (str, context, resultProcessor) {
    var tokenReg = /(\\)?\$\{([^\{\}\\]+)(\\)?\}/g;
    return render(str, context, tokenReg, true, resultProcessor);
};

// String.prototype.hashCode = function() {
//     var hash = 0, i, chr;
//     if (this.length === 0) return hash;
//     for (i = 0; i < this.length; i++) {
//         chr   = this.charCodeAt(i);
//         hash  = ((hash << 5) - hash) + chr;
//         hash |= 0; // Convert to 32bit integer
//     }
//     return hash;
// };



export function dataStructure(d) {
    var dataString = d ? d.toString() : "";
    var isNumber = /^\-?\d+(\.\d+)?$/.test(dataString);
    var intBit = isNumber ? dataString.split("\.")[0].length : 0;
    var floatBit = isNumber && dataString.indexOf(".") != -1 ? dataString.split("\.")[1].length : 0;
    return {
        isNumber: isNumber,
        intBit: intBit,
        floatBit: floatBit
    };
}

/**
 * 数字格式化
 * @param {格式化 数字} d
 */
export function dataFormat(d) {
    var ds = dataStructure(d);
    if (ds.isNumber) {
        //return numbro(d).format("0.[0000]");
        return percent(d, 6)
    } else {
        return d;
    }
}
/**
 *
 * @param {数字} data
 * @param {保留小数位} n
 */
function percent(data, n) {
    if (data != null) {
        return data.toFixed(n);
    } else {
        return;
    }
}





// Verify whether the aggregation expression is valid
export function verifyAggExpRegx(exp) {

    var result = { isValid: false, msg: "" };

    var exp = exp.replace(/\s/g, '').replace(/(sum|avg|count|max|min)\([\u4e00-\u9fa5_a-zA-Z0-9]+\)/g, '1');

    try {
        eval(exp);
    } catch (e) {
        result.msg = e.message;
        return result;
    }

    result.isValid = true;
    result.msg = "ok!";
    return result;
}

export function OurboreTranslate(path) {
    var keys = path.split(".");
    var exp = "CB_I18N";
    for (var i = 0; i < keys.length; i++) {
        exp += "['" + keys[i] + "']";
    }
    var result = eval(exp);
    return result ? result : path;
}


export function UserException(message) {
    this.message = message;
    this.name = 'UserException';
}








/**
 * 此处代码 后期移入到公共utils
 */

var echartsBasicOption = {
    title: {},
    grid: {
        left: '50',
        right: '20',
        bottom: '15%',
        top: '15%',
        containLabel: false
    },
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        left: 'left',
        itemWidth: 15,
        itemHeight: 10
    }
};
//判断值是否为空，公共utils移回-2020.08.04
export var isEmpty = function(value) {
    if (value === null || value === undefined || value == "undefined" || value === '' || value === "") {
      return true;
    } else {
      return false;
    }
  }

export var  isRealNum = function (val) {
    if (val === "" || val == null) {
      return false;
    }
    if (!isNaN(val)) {
      return true;
    } else {
      return false;
    }
}
//去除千位符
export var clearComma =function(s) {
  if ($.trim(s) == "") {
      return s;
  } else {
  return (s + "").trim().replace(/[,，]/g, "");
  }
}
export var compareSize =function(data,symbol,value){
    if((typeof data !='number' && isNaN(Number(data)))|| !data){
        return false;
    }

    // <option label="=" value="=" selected="selected">=</option>
    // <option label="≠" value="≠">≠</option>
    // <option label=">" value=">">&gt;</option>
    // <option label="<" value="<">&lt;</option>
    // <option label="≥" value="≥">≥</option>
    // <option label="≤" value="≤">≤</option>
    // <option label="(a,b]" value="(a,b]">(a,b]</option>
    // <option label="[a,b)" value="[a,b)">[a,b)</option>
    // <option label="(a,b)" value="(a,b)">(a,b)</option>
    // <option label="[a,b]" value="[a,b]">[a,b]</option>

    try {
        switch(symbol){
            case "=":
                return Number(data)==Number(value);
            case "≠":
                return Number(data)!=Number(value);
            case ">":
                return Number(data)>Number(value);
            case "<":
                return Number(data)<Number(value);
            case "≥":
                return Number(data)>=Number(value);
            case "≤":
                return Number(data)<=Number(value);
            case "(a,b)":
                var vArray = String(value).replace("，",",").split(",");
                return Number(data)>Number(vArray[0]) && Number(data)<Number(vArray[1]);
            case "(a,b]":
                var vArray = String(value).replace("，",",").split(",");
                return Number(data)>Number(vArray[0]) && Number(data)<=Number(vArray[1]);
            case "[a,b)":
                var vArray = String(value).replace("，",",").split(",");
                return Number(data)>=Number(vArray[0]) && Number(data)<Number(vArray[1]);
            case "[a,b]":
                var vArray = String(value).replace("，",",").split(",");
                return Number(data)>=Number(vArray[0]) && Number(data)<=Number(vArray[1]);
            default:
                return false;
        }
    } catch (error) {
        return false;
    }
}
//转换图表单位 option:指标配置；value:要转换的值；excludeUnit:是否排除单位;是否排除千分符
export var convertEchartUnit = function(option,value,excludeUnit,excludeSeparated){
    //当传入值不为数字时返回默认数值
    // if((typeof value !='number' && isNaN(Number(value)))|| !value){
    //     return value;
    // }
    value = clearComma(value);
    //当传入值不为数字且转不成数字，或为null、空字符串、undefined
    if((typeof value !='number' && isNaN(Number(value))) || value === null || value === "" || value === undefined){
        return value;
    }
    // if(value == "0" || Number(value)==0){
    //     return value;
    // }
    if(!option || (!option.unit && !option.symbol && !option.dividend && !option.mantissa)) {
        return value;
    };
    var unit = option.unit || "";//单位
    var symbol = option.symbol || "*";//符号
    var dividend = option.dividend || 1;//被除(加减乘)数
    var mantissa = option.mantissa || 0;//小数位
    // value = clearComma(value);
    var data;
    switch(symbol){
        case "+":
            data = numbro(Number(value)).add(Number(dividend)).format({
                thousandSeparated: excludeSeparated?false:true,
                mantissa: Number(mantissa) || 0
            });
            break;
        case "-":
            data = numbro(Number(value)).subtract(Number(dividend)).format({
                thousandSeparated: excludeSeparated?false:true,
                mantissa: Number(mantissa) || 0
            });
            break;
        case "*":
            data = numbro(Number(value)).multiply(Number(dividend)).format({
                thousandSeparated: excludeSeparated?false:true,
                mantissa: Number(mantissa) || 0
            });
            break;
        case "%":
            data = numbro(Number(value)).divide(Number(dividend)).format({
                thousandSeparated: excludeSeparated?false:true,
                mantissa: Number(mantissa) || 0
            });
            break;
        default:
            data = numbro(Number(value)).format({
                thousandSeparated: excludeSeparated?false:true,
                mantissa: Number(mantissa) || 0
            });
    }
    if(excludeUnit)return Number(data);
    return data + unit;
};

//转换图表单位 option:指标配置；value:要转换的值；---返回一个对象
export var convertEchartUnitToObj = function(option,value){
    //当传入值不为数字时返回默认数值
    // if((typeof value !='number' && isNaN(Number(value)))|| !value){
    //     return value;
    // }
    value = clearComma(value);
    //当传入值不为数字且转不成数字，或为null、空字符串、undefined
    if((typeof value !='number' && isNaN(Number(value))) || value === null || value === "" || value === undefined){
        return value;
    }
    // if(value == "0" || Number(value)==0){
    //     return value;
    // }
    //不会出现
    if(!option || (!option.unit && !option.symbol && !option.dividend && !option.mantissa && !option.unitFontSize)) {
        return {value:value};
    };
    var unit = option.unit || "";//单位
    var symbol = option.symbol || "*";//符号
    var dividend = option.dividend || 1;//被除(加减乘)数
    var mantissa = option.mantissa || 0;//小数位
    var unitFontSize = (option.unitFontSize ? option.unitFontSize : 1) + "em" || "1em"; //单位的字体大小
    // value = clearComma(value)
    var data = {};
    data.unit = unit;
    data.unitFontSize = unitFontSize;
    switch(symbol){
        case "+":
            data.value = numbro(Number(value)).add(Number(dividend)).format({
                thousandSeparated: true,
                mantissa: Number(mantissa) || 0
            });
            break;
        case "-":
            data.value = numbro(Number(value)).subtract(Number(dividend)).format({
                thousandSeparated: true,
                mantissa: Number(mantissa) || 0
            });
            break;
        case "*":
            data.value = numbro(Number(value)).multiply(Number(dividend)).format({
                thousandSeparated: true,
                mantissa: Number(mantissa) || 0
            });
            break;
        case "%":
            data.value = numbro(Number(value)).divide(Number(dividend)).format({
                thousandSeparated: true,
                mantissa: Number(mantissa) || 0
            });
            break;
        default:
            data.value = numbro(Number(value)).format({
                thousandSeparated: true,
                mantissa: Number(mantissa) || 0
            });
    }
    return data;
};

export var updateEchartOptions = function (tuningOpt, rawOpt) {
    if (tuningOpt) {
        if (tuningOpt.dataZoom == true) {
            rawOpt.dataZoom = {
                show: true,
                start: 0,
                end: 100,
                brushSelect: false
            };
        }

        // legend
        rawOpt.grid === undefined ? rawOpt.grid = clone(echartsBasicOption.grid) : null;
        if (tuningOpt.legendShow == false) {
            rawOpt.grid.top = '5%';
            rawOpt.legend.show = false;
        } else {
            rawOpt.legend === undefined ? rawOpt.legend = clone(echartsBasicOption.legend) : null;
            rawOpt.legend.type = tuningOpt.legendType ? tuningOpt.legendType : 'plain';                 //自定义
            rawOpt.legend.left = tuningOpt.legendX ? tuningOpt.legendX : 'left';                        //自定义
            rawOpt.legend.top  = tuningOpt.legendY ? tuningOpt.legendY : 'top';                         //自定义
            rawOpt.legend.orient = tuningOpt.legendOrient ? tuningOpt.legendOrient : 'horizontal';      //自定义
            if(tuningOpt.legendOrient == 'horizontal') {
                tuningOpt.legendWidth > 0 ? rawOpt.legend.width = tuningOpt.legendWidth : null;         //自定义
            }
            if(tuningOpt.legendOrient == 'vertical') {
                tuningOpt.legendHeight > 0 ? rawOpt.legend.height = tuningOpt.legendHeight : null;      //自定义
            }
            rawOpt.legend.tooltip = {
                show: tuningOpt.legendTooltipShow ? tuningOpt.legendTooltipShow : false                 //自定义
            };
            rawOpt.legend.padding = tuningOpt.legendPadding ? tuningOpt.legendPadding : [6, 10];
            rawOpt.legend.backgroundColor = tuningOpt.legendBackgroundColor ? tuningOpt.legendBackgroundColor : 'transparent';  //自定义
            rawOpt.legend.borderRadius = tuningOpt.legendBorderRadius > 0 ? tuningOpt.legendBorderRadius : 0;
            rawOpt.legend.textStyle = {
                color: function(){
                    // console.log(arguments)
                },
                fontSize: tuningOpt.legendFontSize > 0 ? tuningOpt.legendFontSize : 12,                 //自定义
                padding: [2,0,0,0]
            };
            rawOpt.legend.inactiveColor = '#ccc';
            rawOpt.legend.formatter = function(name){                                           //自定义了最大字数
                var nameStr;
                if(tuningOpt.legendFontMaxMount && tuningOpt.legendFontMaxMount > 0) {
                    if(name && name.length > tuningOpt.legendFontMaxMount) {
                        nameStr = name.slice(0, tuningOpt.legendFontMaxMount) + '...';
                    } else {
                        nameStr = name;
                    }
                } else {
                    nameStr = name;
                }
                return nameStr;
            };
            // 图例块
            rawOpt.legend.icon = tuningOpt.legendIcon ? tuningOpt.legendIcon : 'rect';                      //自定义
            rawOpt.legend.itemGap = tuningOpt.legendItemGap ? tuningOpt.legendItemGap : 10;
            rawOpt.legend.itemWidth = tuningOpt.legendItemWidth > 0 ? tuningOpt.legendItemWidth : 15;       //自定义
            rawOpt.legend.itemHeight = tuningOpt.legendItemHeight > 0 ? tuningOpt.legendItemHeight : 10;    //自定义

            // 滚动相关样式
            if(tuningOpt.legendType == 'scroll') {
                rawOpt.legend.pageButtonPosition = 'end';
                rawOpt.legend.pageIconColor = tuningOpt.legendPageIconColor ? tuningOpt.legendPageIconColor : '#136efa';    //自定义
                rawOpt.legend.pageIconInactiveColor = tuningOpt.legendPageIconInactiveColor ? tuningOpt.legendPageIconInactiveColor : '#a6aab8';    //自定义
                rawOpt.legend.pageIconSize = tuningOpt.legendPageIconSize > 0 ? tuningOpt.legendPageIconSize : 14;    //自定义
                rawOpt.legend.pageTextStyle = {
                    fontSize: tuningOpt.legendPageTextFontSize > 0 ? tuningOpt.legendPageTextFontSize : 14,     //自定义
                    color: tuningOpt.legendPageTextColor ? tuningOpt.legendPageTextColor : '#333',              //自定义
                    lineHeight: tuningOpt.legendPageTextFontSize > 0 ? tuningOpt.legendPageTextFontSize : 14    //同字体大小
                };
                // 控件和图例间的间距
                rawOpt.legend.pageButtonGap = tuningOpt.legendPageButtonGap ? tuningOpt.legendPageButtonGap : 15;
                rawOpt.legend.animation = true;
            }

            // // 按钮样式----全选反选----需要4.4.0及以上----现版本4.2
            // if(tuningOpt.legendSelectorShow == true) {
            //     rawOpt.legend.selector = [
            //         {type: 'all', title: '全选'},
            //         {type: 'inverse', title: '反选'}
            //     ];
            //     rawOpt.legend.selectorLabel = {
            //         borderRadius: 0,
            //         borderColor: '#136efa',
            //         backgroundColor: '#fff',
            //         color: '#136efa',
            //         padding: [5, 10],
            //         fontSize: 12
            //     };
            //     rawOpt.legend.emphasis = {
            //         selector: {
            //             backgroundColor: '#136efa',
            //             color: '#fff'
            //         }
            //     };
            //     rawOpt.legend.selectorPosition = 'start';
            // }

        }

        // grid
        if (tuningOpt.gridCustom == true) {
            tuningOpt.gridTop ? rawOpt.grid.top = tuningOpt.gridTop : null;
            tuningOpt.gridBottom ? rawOpt.grid.bottom = tuningOpt.gridBottom : null;
            tuningOpt.gridLeft ? rawOpt.grid.left = tuningOpt.gridLeft : null;
            tuningOpt.gridRight ? rawOpt.grid.right = tuningOpt.gridRight : null;
            tuningOpt.containLabel ? rawOpt.grid.containLabel = tuningOpt.containLabel : null;
        }
    }
};

// 自定义echarts的tooltip的位置
export var updateEchartsTooltipPosition = (opt) =>{
  let p = function(point, params, dom, rect, size){
    // point: 鼠标位置[10, 10]
    // params: 同formatter
    // dom：tooltip的dom
    // rect：鼠标在图形上时有效{x,y,width,height}，什么图形?
    // size：dom的尺寸和echarts的尺寸{contentSize:[width,height], viewSize: [width, height]}
    // 返回位置：如{left: 10, top: '20%'}或{right: '20%', bottom: 50}
    let obj = {}, vSize = size.viewSize, cSize = size.contentSize;
    // 判断水平位置
    if( (cSize[0] + 15) > vSize[0] ){
      // tip的宽度+15大于图表宽度的情况
      obj.left = 5;
    } else if ( (cSize[0] + 15) > vSize[0] / 2) {
      // tip的宽度+15大于一半图表宽度的情况
      obj[['left', 'right'][+( point[0] < vSize[0] / 2 )]] = 5;
    } else {
      if(point[0] < vSize[0] / 2) {
        obj.left = point[0] + 15;
      } else {
        obj.right = vSize[0] - point[0] + 15;
      }
    }
    // 判断竖直位置
    if( (cSize[1] + 15) > vSize[1] ){
      // tip的高度+15大于图表高度的情况
      obj.bottom = 5;
    } else if ( (cSize[1] + 15) > vSize[1] / 2) {
      // tip的高度+15大于一半图表高度的情况
      obj[['top', 'bottom'][+( point[1] < vSize[1] / 2 )]] = 5;
    } else {
      if(point[1] < vSize[1] / 2) {
        obj.top = point[1] + 15;
      } else {
        obj.bottom = vSize[1] - point[1] + 15;
      }
    }
    return obj;
  }
  opt && ( opt.tooltip ? ( !(opt.tooltip.position) && (opt.tooltip.position = p) ) : (opt.tooltip = {position: p}) );
  return opt;
};

export  var isNumber = function (val){
    var regPos = /^\d+(\.\d+)?$/; //非负浮点数
    var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
    if(regPos.test(val) && regNeg.test(val)){
        return true;
    }else{
        return false;
    }
}


export  var chartDataProcess = function(chartConfig,casted_keys, casted_values, aggregate_data,newValuesConfig) {
    var keysList = casted_keys,
        keyArr = [],
        emptyList = [],
        keyLength = chartConfig.keys.length,
        rowHeaderLength = keysList[0] ? keysList[0].length : 0,
        colFormatter = [],
        setColFormatter = false;
    Array.matrix = function (numrows, numcols, initial) {
        var arr = [];
        for (var a = 0; a < numrows; ++a) {
            var columns = [];
            for (var s = 0; s < numcols; ++s) {
                columns[s] = initial;
            }
            arr[a] = columns;
        }
        return arr;
    };
    // for(var i = 0; i < aggregate_data.length; i++){
    //     for(var j = 0; j < aggregate_data[i].length; j++){
    //         aggregate_data[i][j]  = convertEchartUnit(colFormatter[i],aggregate_data[i][j])
    //     }
    // }
    // for (var i = 0; i < chartConfig.values.length; i++) {
    //     for (var j = 0; j < chartConfig.values[i].cols.length; j++) {
    //         var col = chartConfig.values[i].cols[j];
    //         colFormatter.push(col);
    //         //适配原有的图表配置,如果设置了提示框单位转换才需要转换单位,否则就用原先默认的
    //         if(col && (col.unit || col.symbol ||col.dividend || col.mantissa)){
    //             setColFormatter = true;
    //         }
    //     }
    // }
    var table_data = Array.matrix(keysList.length, rowHeaderLength, 0);
    for (var h = 0; h < rowHeaderLength; h++) {
        for (var k = 0; k < keysList.length; k++) {
            table_data[k][h] = {
                property: 'column_key',
                data: keysList[k][h]
            };
        }
    }
    for (var i = 0; i < casted_values.length; i++) {
        var joined_values = casted_values[i].join('-');
        var formatter = newValuesConfig[joined_values].formatter;
        for (var j = 0; j < casted_keys.length; j++) {
            if (!_.isUndefined(aggregate_data[i][j])) {
                var raw = aggregate_data[i][j];

                // if(setColFormatter)colFormatter
                table_data[j][i + keyLength] = {
                    property: 'data',
                    data: formatter ? numbro(raw).format(formatter) : raw,
                    raw: raw
                };
            } else {
                table_data[j][i + keyLength] = {
                    property: 'data',
                    data: ''
                };
            }
        }
    }
    var column_header = Array.matrix(chartConfig.groups.length + 1, casted_values.length, 0);
    for (var n = 0; n < casted_values.length; n++) {
        for (var m = 0; m < casted_values[n].length; m++) {
            column_header[m][n] = {
                property: 'header_key',
                data: casted_values[n][m]
            };
        }
    }
    for (var y = 0; y < keyLength; y++) {
        keyArr.push({
            property: 'header_key',
            column_header_header: true,
            data: chartConfig.keys[y].alias ? chartConfig.keys[y].alias : chartConfig.keys[y].col
        });
        emptyList.push({
            property: 'header_empty',
            data: null
        });
    }
    for (var j = 0; j < column_header.length; j++) {
        j == column_header.length - 1 ?
            column_header[j] = keyArr.concat(column_header[j]) :
            column_header[j] = emptyList.concat(column_header[j]);
    }
    var chartData = {
        chartConfig: chartConfig,
        data: column_header.concat(table_data),
        tooltip: {
            trigger: 'item'
        },
    };
    table_data = null;
    column_header = null;
    return chartData;
};

/**
 * @desc  getColColor table图表中的方法-小改动----指标值设置颜色
 * @param {object} highlightArr  {sign:string, color:color, value:string}
 * @param {number:string} numContent    Number
 * @return {string:undefined}
 * */
export var getColColor = function(highlightArr, numContent){
    var color;
    // 增加数值的拦截
    var numContent = Number(clearComma(numContent));//值
    if(isNaN(numContent)){
        return color;
    }
    for (var i = 0;i < highlightArr.length; i++) {
        if(!highlightArr[i] || !highlightArr[i].sign || !highlightArr[i].color || highlightArr[i].value==null)continue;
        var value = highlightArr[i].value.replace("，",",").split(",");//区间
        var valueColor = highlightArr[i].color;//颜色
        switch(highlightArr[i].sign){
            case "<":
                if(numContent<Number(value[0]))color = valueColor;
                break;
            case ">":
                if(numContent>Number(value[0]))color = valueColor;
                break;
            case "=":
                if(numContent==Number(value[0]))color = valueColor;
                break;
            case "≠":
                if(numContent!=Number(value[0]))color = valueColor;
                break;
            case "≤":
                if(numContent<=Number(value[0]))color = valueColor;
                break;
            case "≥":
                if(numContent>=Number(value[0]))color = valueColor;
                break;
            case "(a,b)":
                if(numContent>Number(value[0]) && numContent<Number(value[1]))color = valueColor;
                break;
            case "[a,b)":
                if(numContent>=Number(value[0]) && numContent<Number(value[1]))color = valueColor;
                break;
            case "(a,b]":
                if(numContent>Number(value[0]) && numContent<=Number(value[1]))color = valueColor;
                break;
            case "[a,b]":
                if(numContent>=Number(value[0]) && numContent<=Number(value[1]))color = valueColor;
                break;
            default:
                break;
        }
        if(color)break;
    }
    return color;
};

(function ($, undefined) {
    'use strict';
    $.jstree.defaults.alltrigger = null;
    $.jstree.plugins.alltrigger = function (options, parent) {
        this.init = function (el, opts) {
            if (options) {
                this.trigger = function (ev, data) {
                    parent.trigger.call(this, ev, data);
                    options(ev.replace('.jstree', '') + '.jstree', data);
                };
                var contextTrigger = $.vakata.context._trigger;
                $.vakata.context._trigger = function (event_name) {
                    contextTrigger(event_name);
                    options('context_' + event_name + '.vakata');
                };
                var dndTrigger = $.vakata.dnd._trigger;
                $.vakata.dnd._trigger = function (event_name, e, data) {
                    dndTrigger(event_name, e, data);
                    options('dnd_' + event_name + '.vakata', e, data);
                };
            }
            parent.init.call(this, el, opts);
        };
    };
})(jQuery);

import jsTreeCtrl from './myJsTreeCtrl';
import jsTreeDirective from './myJsTree.directive';

export default angular.module('BlurAdmin.theme.components.myjstree',[])
.controller('jsTreeCtrl', ['$scope', jsTreeCtrl])
.directive('jsTree', ['$timeout', jsTreeDirective]).name;

export default function redirectCtrl($state, $location) {
  var id = $location.search().id;
  var redirectType = $location.search().redirectType;
  var param = $location.search().param
  if (param) {
    param = encodeURI(param);
  }

  function redirectTo() {
    if (isPhone() || istv()) {
      if (redirectType == "warn") {
        window.location.replace("/mbore.html#/widgetShare?widgetId=" + id + "&param=" + param);
        return;
      }
      window.location.replace("/mbore.html#/display?id=" + id + "&param=" + param)
    } else {
      if (redirectType == "warn") {
        // $state.go('widgetShare', {
        //   widgetId: id,
        //   param: param
        // });
        window.location.replace("/#/widgetShare?widgetId=" + id + "&param=" + param)
        return;
      }
      window.location.replace("/#/display?id=" + id + "&param=" + param)
      //   $state.go('display', {
      //     id: id,
      //     param: param
      //   });
    }
  }

  redirectTo();


  function isPhone() {
    if (istv()) {
      return false;
    }
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
      return true;
    } else {
      return false;
    }
  }

  function getCookie(name) {
    var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");

    if (arr = document.cookie.match(reg))

      return unescape(arr[2]);
    else
      return null;
  }

  function istv() {
    var viewType = getCookie("viewType")
    //console.log("viewTypeviewType:"+viewType)
    if (viewType == "TV" || viewType == "tv") {
      return true;
    } else {
      return false;
    }
  }
};

/**
 * @author jaray
 *   
 */
  
  /** @ngInject */
  export  default  function profilePicture(layoutPaths) {
    return function(input, ext) {
      ext = ext || 'png';
      return layoutPaths.images.profile + input + '.' + ext;
    };
  }


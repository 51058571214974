//筛选数据并展示num条
export  default  function bigDataFilter() {
    return function (data, allData,keyword,num) {
        if(!data)return null;
        if(!num)num=500;
        if(!keyword)return data.slice(0,num); 
        if(!allData)allData = data;
        var filterData =_.filter(allData, function (e) {
            var index = e.indexOf(keyword);
            if (index>-1) {
                return e;
            }
        });
        
        return filterData.slice(0,num);
    }
}
import icon_pic from './table.png'
import icon_pic_active from './table-active.png'
var config = {
    rule: {
        keys: -1,
        groups: -1,
        filters: -1,
        values: -1
    },
    info:{
        name: '交叉表',
        value: 'table',
        // class: 'cTable',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE',
        index:0
    }
}
export default config;
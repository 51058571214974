// api 请求服务器
const DEV_API = '/be/api';
const PRO_API = '/be/api';
const debug = false;

let apiUrl = DEV_API;
if (process.env.NODE_ENV !== 'development') {
  apiUrl = PRO_API;
}

const serverApi={
  
}
export { apiUrl, debug,serverApi };

import icon_pic from './kpi3.png'
import icon_pic_active from './kpi3-active.png'
var config = {
    rule: {
        keys: 0,
        groups: 0,
        filters: -1,
        values: 4  // 指标一个或两个
    },
    info:{
        name: '指标卡3',
        value: 'kpi3',
        // class: 'cKpi2',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_1_2'
    }
}
export default config;
import * as util  from  '../util/util'
var DupontRenderEngine = function (jqContainer, options) {
    this.container = jqContainer; // jquery object
    this.options = options;
};
DupontRenderEngine.prototype.theme = "theme-fin1"; // 主题

DupontRenderEngine.prototype.html = function (persist) {
    var self = this;
    var temp = "" + self.template;
    var html  =util.StringRender(temp,self.options)
    return html;
};

DupontRenderEngine.prototype.realTimeTicket = function () {
    var self = this;
    self.changeSize()
    self.container.resize(function (e) {
        self.changeSize()
    }); 
};

DupontRenderEngine.prototype.changeSize = function () {
    var self = this;
    var parentWidth = $(self.container).width();
    var childWidth = $(self.container).find("table tbody").width();
    var size = parentWidth/childWidth;
    size = size<1?size:1;
    $(self.container).find("table").css("transform","scale("+size+")")
};

DupontRenderEngine.prototype.do = function () {
    var self = this;
    $(self.container).html(self.rendered());
};
DupontRenderEngine.prototype.template =
'<div class="bore-dupont-container">'+
'<table style="table-layout:fixed;line-height:1.1;" class="bore-dupont" border="0" cellpadding="0" cellspacing="0">'+
'        <colgroup>'+
'            <col col="D" style="width:80px;" />'+
'            <col col="E" style="width:80px;" />'+
'            <col col="F" style="width:80px;" />'+
'            <col col="G" style="width:80px;" />'+
'            <col col="H" style="width:80px;" />'+
'            <col col="I" style="width:80px;" />'+
'            <col col="J" style="width:80px;" />'+
'            <col col="K" style="width:80px;" />'+
'            <col col="L" style="width:80px;" />'+
'            <col col="M" style="width:80px;" />'+
'            <col col="N" style="width:80px;" />'+
'            <col col="O" style="width:80px;" />'+
'            <col col="P" style="width:80px;" />'+
'            <col col="Q" style="width:80px;" />'+
'            <col col="R" style="width:80px;" />'+
'            <col col="S" style="width:80px;" />'+
'            <col col="T" style="width:80px;" />'+
'            <col col="U" style="width:80px;" />'+
'            <col col="V" style="width:80px;" />'+
'        </colgroup>'+
'        <tr name="rowA1" class="bore-dupont-row">'+

'            <td name="D" ></td>'+
'            <td name="E" ></td>'+
'            <td name="F" ></td>'+
'            <td name="G" ></td>'+
'            <td name="H" ></td>'+
'            <td name="I" ></td>'+
'            <td name="J" ></td>'+
'            <td name="K" ></td>'+
'            <!-- <td name="L" ></td>  -->'+
'            <td name="M" class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">净资产收益率 %</div>'+
'                </div>'+
'                <div class="box-value" id = "ROE">'+
'                    <div class="value-new">{ROE_nvalue}</div>'+
'                    <div class="box-float {ROE_style}">{ROE_float}</div>'+
'                    <div class="value-old">{ROE_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="N" ></td>  -->'+
'            <td name="O" ></td> '+
'            <td name="P" ></td> '+
'            <td name="Q" ></td>'+
'            <td name="R" ></td>'+
'            <td name="S" ></td>'+
'            <td name="T" ></td>'+
'            <td name="U" ></td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowB2" class="bore-line-row">'+

'            <td name="D" ></td>'+
'            <td name="E" ></td>'+
'            <td name="F" ></td>'+
'            <td name="G" ></td>'+
'            <td name="H" ></td>'+
'            <td name="I" ></td>'+
'            <td name="J" class="bottom-border"></td>'+
'            <td name="K" class="bottom-border"></td>'+
'            <td name="L" class="bottom-border"></td> '+
'            <td name="M" class="bottom-border">'+
'                <div class="midcourt-line"></div> '+
'            </td>'+
'            <td name="N" class="bottom-border"></td> '+
'            <td name="O" class="bottom-border"></td> '+
'            <td name="P" class="bottom-border"></td> '+
'            <td name="Q" class="bottom-border"></td>'+
'            <td name="R" ></td>'+
'            <td name="S" ></td>'+
'            <td name="T" ></td>'+
'            <td name="U" ></td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowB3" class="bore-line-row">'+

'            <td name="D" ></td>'+
'            <td name="E" ></td>'+
'            <td name="F" ></td>'+
'            <td name="G" ></td>'+
'            <td name="H" ></td>'+
'            <td name="I" >'+
'                <div class="left-top-border"></div>'+
'            </td>'+
'            <td name="J" ></td>'+
'            <td name="K" ></td>'+
'            <td name="L" ></td> '+
'            <td name="M" ></td>'+
'            <td name="N" ></td> '+
'            <td name="O" ></td> '+
'            <td name="P" ></td> '+
'            <td name="Q"></td>'+
'            <td name="R" >'+
'                <div class="right-top-border"></div>'+
'            </td>'+
'            <td name="S" ></td>'+
'            <td name="T" ></td>'+
'            <td name="U" ></td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowA2" class="bore-dupont-row">'+

'            <td name="D" ></td>'+
'            <td name="E" ></td>'+
'            <td name="F" ></td>'+
'            <td name="G" ></td>'+
'            <!-- <td name="H" ></td> -->'+
'            <td name="I" class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">总资产净利率 %</div>'+
'                </div>'+
'                <div class="box-value" id="JROA">'+
'                    <div class="value-new">{JROA_nvalue}</div>'+
'                    <div class="box-float {JROA_style}">{JROA_float}</div>'+
'                    <div class="value-old">{JROA_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="J" ></td> -->'+
'            <td name="K" ></td>'+
'            <td name="L"></td>'+
'            <td name="M" ></td>'+
'            <td name="N" ></td> '+
'            <td name="O" ></td>'+
'            <td name="P" ></td>'+
'            <!-- <td name="Q" ></td> -->'+
'            <td name="R" class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">&ensp;&ensp;&emsp;权益乘数&emsp;&ensp;</div>'+
'                </div>'+
'                <div class="box-value" id="equity_multiplier">'+
'                    <div class="value-new">{equity_multiplier_nvalue}</div>'+
'                    <div class="box-float {equity_multiplier_style}">{equity_multiplier_float}</div>'+
'                    <div class="value-old">{equity_multiplier_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="S" ></td> -->'+
'            <td name="T"></td>'+
'            <td name="U" ></td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowB4" class="bore-line-row">'+

'            <td name="D" ></td>'+
'            <td name="E" ></td>'+
'            <td name="F" ></td>'+
'            <td name="G" ></td>'+
'            <td name="H" class="bottom-border"></td>'+
'            <td name="I" class="bottom-border">'+
'                <div class="midcourt-line"></div> '+
'            </td>'+
'            <td name="J"  class="bottom-border"></td>'+
'            <td name="K" ></td>'+
'            <td name="L" ></td> '+
'            <td name="M" ></td>'+
'            <td name="N" ></td> '+
'            <td name="O" ></td> '+
'            <td name="P" ></td> '+
'            <td name="Q" ></td>'+
'            <td name="R" >'+
'                <div class="midcourt-line"></div> '+
'            </td>'+
'            <td name="S" ></td>'+
'            <td name="T" ></td>'+
'            <td name="U" ></td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowB5" class="bore-line-row">'+

'            <td name="D" ></td>'+
'            <td name="E" ></td>'+
'            <td name="F" ></td>'+
'            <td name="G" >'+
'                <div class="left-top-border"></div> '+
'            </td>'+
'            <td name="H" ></td>'+
'            <td name="I" ></td>'+
'            <td name="J" ></td>'+
'            <td name="K" >'+
'                <div class="right-top-border"></div> '+
'            </td>'+
'            <td name="L" ></td> '+
'            <td name="M" ></td>'+
'            <td name="N" ></td> '+
'            <td name="O" ></td> '+
'            <td name="P" ></td> '+
'            <td name="Q" ></td>'+
'            <td name="R" >'+
'                <div class="midcourt-line"></div> '+
'            </td>'+
'            <td name="S" ></td>'+
'            <td name="T" ></td>'+
'            <td name="U" ></td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowA6" class="bore-dupont-row">'+

'            <td name="D" ></td>'+
'            <td name="E" ></td>'+
'            <!-- <td name="F"></td> -->'+
'            <td name="G"class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">营业净利润率 %</div>'+
'                </div>'+
'                <div class="box-value" id="operate_profit_ratio">'+
'                    <div class="value-new">{operate_profit_ratio_nvalue}</div>'+
'                    <div class="box-float {operate_profit_ratio_style}">{operate_profit_ratio_float}</div>'+
'                    <div class="value-old">{operate_profit_ratio_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="H" ></td> -->'+
'            <td name="I" ></td>'+
'            <!-- <td name="J"></td> -->'+
'            <td name="K"  class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">总资产周转率 次</div>'+
'                </div>'+
'                <div class="box-value" id="total_assets_turnover">'+
'                    <div class="value-new">{total_assets_turnover_nvalue}</div>'+
'                    <div class="box-float {total_assets_turnover_style}">{total_assets_turnover_float}</div>'+
'                    <div class="value-old">{total_assets_turnover_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="L" ></td>  -->'+
'            <td name="M" ></td>'+
'            <td name="N" ></td> '+
'            <td name="O" ></td> '+
'            <td name="P" ></td> '+
'            <!-- <td name="Q"></td> -->'+
'            <td name="J"class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">&ensp;资产负债率 %&ensp;</div>'+
'                </div>'+
'                <div class="box-value" id="debt_asset_ratio">'+
'                    <div class="value-new">{debt_asset_ratio_nvalue}</div>'+
'                    <div class="box-float {debt_asset_ratio_style}">{debt_asset_ratio_float}</div>'+
'                    <div class="value-old">{debt_asset_ratio_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="S" ></td> -->'+
'            <td name="T" ></td>'+
'            <td name="U" ></td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowB7" class="bore-line-row">'+

'            <td name="D" ></td>'+
'            <td name="E" ></td>'+
'            <td name="F" class="bottom-border"></td>'+
'            <td name="G"  class="bottom-border">'+
'                <div class="midcourt-line"></div> '+
'            <td name="H" class="bottom-border"></td>'+
'            <td name="I" class="bottom-border"></td>'+
'            <td name="J" class="bottom-border"></td>'+
'            <td name="K" class="bottom-border">'+
'                <div class="midcourt-line"></div> '+
'            </td>'+
'            <td name="L" class="bottom-border"></td> '+
'            <td name="M" class="bottom-border"></td>'+
'            <td name="N" class="bottom-border"></td> '+
'            <td name="O" class="bottom-border"></td> '+
'            <td name="P" class="bottom-border"></td> '+
'            <td name="Q" class="bottom-border"></td>'+
'            <td name="R" class="bottom-border">'+
'                <div class="midcourt-line"></div> '+
'            </td>'+
'            <td name="S" class="bottom-border"></td>'+
'            <td name="T" class="bottom-border"></td>'+
'            <td name="U" ></td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowB8" class="bore-line-row">'+

'            <td name="D" ></td>'+
'            <td name="E" >'+
'                <div class="left-top-border"></div> '+
'            </td>'+
'            <td name="F" ></td>'+
'            <td name="G" ></td>'+
'            <td name="H" ></td>'+
'            <td name="I" >'+
'                <div class="midcourt-line"></div> '+
'            </td>'+
'            <td name="J" ></td>'+
'            <td name="K" ></td>'+
'            <td name="L" ></td> '+
'            <td name="M" ></td>'+
'            <td name="N" ></td> '+
'            <td name="O" >'+
'                <div class="midcourt-line"></div> '+
'            </td> '+
'            <td name="P" ></td> '+
'            <td name="Q" ></td>'+
'            <td name="R" ></td>'+
'            <td name="S" ></td>'+
'            <td name="T" ></td>'+
'            <td name="U" >'+
'                <div class="right-top-border"></div> '+
'            </td>'+
'            <td name="V" ></td>'+

'        </tr>'+
'        <tr name="rowA9" class="bore-dupont-row">'+

'            <!-- <td name="D" ></td> -->'+
'            <td name="E" class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">&emsp;&emsp;&ensp;净利润&emsp;&emsp;</div>'+
'                </div>'+
'                <div class="box-value" id="parent_netprofit">'+
'                    <div class="value-new">{parent_netprofit_nvalue}</div>'+
'                    <div class="box-float {parent_netprofit_style}">{parent_netprofit_float}</div>'+
'                    <div class="value-old">{parent_netprofit_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="F" ></td> -->'+
'            <td name="G" ></td>'+
'            <!-- <td name="H" ></td> -->'+
'            <td name="I" class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">&emsp;&ensp;营业总收入&emsp;</div>'+
'                </div>'+
'                <div class="box-value" id="total_operate_income">'+
'                    <div class="value-new">{total_operate_income_nvalue}</div>'+
'                    <div class="box-float {total_operate_income_style}">{total_operate_income_float}</div>'+
'                    <div class="value-old">{total_operate_income_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="J" ></td> -->'+
'            <td name="K" ></td>'+
'            <td name="L" ></td> '+
'            <td name="M"></td>'+
'            <!-- <td name="N" ></td> -->'+
'            <td name="O"  class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">&ensp;&emsp;&ensp;资产总额&emsp;&ensp;</div>'+
'                </div>'+
'                <div class="box-value" id="total_assets">'+
'                    <div class="value-new">{total_assets_nvalue}</div>'+
'                    <div class="box-float {total_assets_style}">{total_assets_float}</div>'+
'                    <div class="value-old">{total_assets_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="P" ></td>  -->'+
'            <td name="Q" ></td>'+
'            <td name="R" ></td>'+
'            <td name="S"></td>'+
'            <!-- <td name="T"></td> -->'+
'            <td name="U" class="bore-dupont-box" colspan="3">'+
'                <div class="box-font">'+
'                    <div class="key-font">&emsp;&ensp;&ensp;负债总额&ensp;&emsp;</div>'+
'                </div>'+
'                <div class="box-value" id="total_liabilities">'+
'                    <div class="value-new">{total_liabilities_nvalue}</div>'+
'                    <div class="box-float {total_liabilities_style}">{total_liabilities_float}</div>'+
'                    <div class="value-old">{total_liabilities_ovalue}</div>'+
'                </div>'+
'            </td>'+
'            <!-- <td name="V" ></td> -->'+

'        </tr>'+
'    </table>'
'</div>'
;


export default  DupontRenderEngine;
'use strict';
import './_.dupont.scss'
import DupontRenderEngine from '../../rengine/DupontRenderEngine'
import numbro from 'numbro'
import * as util from '../../util/util'
class DuPontRender {
  //默认高度
  height = 300;
  
  render = function (containerDom, option, scope, persist) {
    var render = new DupontRenderEngine(containerDom, option);
    var html = render.html(persist);
    if (scope) {
      containerDom.append($compile(html)(scope));
    } else {
      containerDom.html(html);
    }
    return render.realTimeTicket();
  };

  parseOption = function (data) {
    var chartConfig = data.chartConfig;
    var keys = data.keys;
    var cols = chartConfig.values[0].cols;
    var datas = data.data;
    var option = {
      ROE_nvalue: 0, //净资产收益率
      ROE_float: 0,
      ROE_ovalue: 0,
      JROA_nvalue: 0, //总资产净利率
      JROA_float: 0,
      JROA_ovalue: 0,
      equity_multiplier_nvalue: 0, //权益乘数
      equity_multiplier_float: 0,
      equity_multiplier_ovalue: 0,
      operate_profit_ratio_nvalue: 0, //营业净利润率
      operate_profit_ratio_float: 0,
      operate_profit_ratio_ovalue: 0,
      total_assets_turnover_nvalue: 0, //总资产周转率
      total_assets_turnover_float: 0,
      total_assets_turnover_ovalue: 0,
      debt_asset_ratio_nvalue: 0, //资产负债率
      debt_asset_ratio_float: 0,
      debt_asset_ratio_ovalue: 0,
      parent_netprofit_nvalue: 0, //净利润
      parent_netprofit_float: 0,
      parent_netprofit_ovalue: 0,
      total_operate_income_nvalue: 0, //营业总收入
      total_operate_income_float: 0,
      total_operate_income_ovalue: 0,
      total_assets_nvalue: 0, //资产总额
      total_assets_float: 0,
      total_assets_ovalue: 0,
      total_liabilities_nvalue: 0, //负债总额
      total_liabilities_float: 0,
      total_liabilities_ovalue: 0
    };

    if (chartConfig.boardFilters && chartConfig.boardFilters.length > 1 && cols && cols.length > 3 && datas && datas.length > 4) {
      //===============计算第一年的杜邦数据==============
      var newArray = _.pluck(cols, 'col');

      //净利润
      var parent_netprofit_nvalue = datas[_.indexOf(newArray, '净利润')][0];
      //营业总收入
      var total_operate_income_nvalue = datas[_.indexOf(newArray, '营业总收入')][0];
      //资产总额
      var total_assets_nvalue = datas[_.indexOf(newArray, '资产总额')][0];
      //期初资产总额
      var begin_total_assets_nvalue = datas[_.indexOf(newArray, '期初资产总额')][0];
      //负债总额
      var total_liabilities_nvalue = datas[_.indexOf(newArray, '负债总额')][0];

      //资产负债率(debt_asset_ratio)=负债总额(total_liabilities)/资产总额(total_assets)
      var debt_asset_ratio_nvalue = numbro(Number(total_liabilities_nvalue)).divide(Number(total_assets_nvalue)).format({
        mantissa: 4
      });
      //平均资产总额=(资产总额(total_assets)+期初资产总额(begin_total_assets)]/2
      var average_total_assets_nvalue = numbro(0.5).multiply(numbro(Number(total_assets_nvalue)).add(Number(begin_total_assets_nvalue))).format({
        mantissa: 4
      });
      //总资产周转率(total_assets_turnover)次=营业总收入(total_operate_income)/平均资产总额(total_assets)
      var total_assets_turnover_nvalue = numbro(Number(total_operate_income_nvalue)).divide(Number(average_total_assets_nvalue)).format({
        mantissa: 2
      });
      //营业净利润率(operate_profit_ratio)%=净利润(parent_netprofit)/营业总收入(total_operate_income)
      var operate_profit_ratio_nvalue = numbro(Number(parent_netprofit_nvalue)).divide(Number(total_operate_income_nvalue)).format({
        mantissa: 4
      });
      //权益乘数(equity_multiplier)=1/(1-资产负债率(debt_asset_ratio))
      var equity_multiplier_nvalue = numbro(1).divide(numbro(1).subtract(Number(debt_asset_ratio_nvalue))).format({
        mantissa: 2
      });
      //总资产净利率(JROA)=营业净利润率(operate_profit_ratio)*总资产周转次(total_assets_turnover)
      var JROA_nvalue = numbro(Number(operate_profit_ratio_nvalue)).multiply(Number(total_assets_turnover_nvalue)).format({
        mantissa: 4
      });
      //净资产收益率(ROE)=权益乘数(equity_multiplier)*总资产净利率(JROA)
      var ROE_nvalue = numbro(Number(equity_multiplier_nvalue)).multiply(Number(JROA_nvalue)).format({
        mantissa: 4
      });

      option.parent_netprofit_nvalue = util.convertEchartUnit(cols[_.indexOf(newArray, '净利润')], parent_netprofit_nvalue);
      option.total_operate_income_nvalue = util.convertEchartUnit(cols[_.indexOf(newArray, '营业总收入')], total_operate_income_nvalue);
      option.total_assets_nvalue = util.convertEchartUnit(cols[_.indexOf(newArray, '资产总额')], total_assets_nvalue);
      option.total_liabilities_nvalue = util.convertEchartUnit(cols[_.indexOf(newArray, '负债总额')], total_liabilities_nvalue);
      //资产负债率
      option.debt_asset_ratio_nvalue = numbro(Number(debt_asset_ratio_nvalue)).multiply(100).format({
        mantissa: 2
      }) + "%"
      //总资产周转率
      option.total_assets_turnover_nvalue = total_assets_turnover_nvalue + "次"
      //营业净利润率
      option.operate_profit_ratio_nvalue = numbro(Number(operate_profit_ratio_nvalue)).multiply(100).format({
        mantissa: 2
      }) + "%"
      //权益乘数
      option.equity_multiplier_nvalue = Number(equity_multiplier_nvalue)
      //总资产净利率
      option.JROA_nvalue = numbro(Number(JROA_nvalue)).multiply(100).format({
        mantissa: 2
      }) + "%"
      //净资产收益率
      option.ROE_nvalue = numbro(Number(ROE_nvalue)).multiply(100).format({
        mantissa: 2
      }) + "%"

      if( datas.length < 5)return option;

      //===============计算第二年的杜邦数据==============
       var oldArray = _.pluck(cols, 'col');
       var le = cols.length;
 
       //净利润
       var parent_netprofit_ovalue = datas[_.indexOf(oldArray, '净利润')+le][0];
       //营业总收入
       var total_operate_income_ovalue = datas[_.indexOf(oldArray, '营业总收入')+le][0];
       //资产总额
       var total_assets_ovalue = datas[_.indexOf(oldArray, '资产总额')+le][0];
       //期初资产总额
       var begin_total_assets_ovalue = datas[_.indexOf(oldArray, '期初资产总额')+le][0];
       //负债总额
       var total_liabilities_ovalue = datas[_.indexOf(oldArray, '负债总额')+le][0];
 
       //资产负债率(debt_asset_ratio)=负债总额(total_liabilities)/资产总额(total_assets)
       var debt_asset_ratio_ovalue = numbro(Number(total_liabilities_ovalue)).divide(Number(total_assets_ovalue)).format({
         mantissa: 4
       });
       //平均资产总额=(资产总额(total_assets)+期初资产总额(begin_total_assets)]/2
       var average_total_assets_ovalue = numbro(0.5).multiply(numbro(Number(total_assets_ovalue)).add(Number(begin_total_assets_ovalue))).format({
         mantissa: 4
       });
       //总资产周转率(total_assets_turnover)次=营业总收入(total_operate_income)/平均资产总额(total_assets)
       var total_assets_turnover_ovalue = numbro(Number(total_operate_income_ovalue)).divide(Number(average_total_assets_ovalue)).format({
         mantissa: 2
       });
       //营业净利润率(operate_profit_ratio)%=净利润(parent_netprofit)/营业总收入(total_operate_income)
       var operate_profit_ratio_ovalue = numbro(Number(parent_netprofit_ovalue)).divide(Number(total_operate_income_ovalue)).format({
         mantissa: 4
       });
       //权益乘数(equity_multiplier)=1/(1-资产负债率(debt_asset_ratio))
       var equity_multiplier_ovalue = numbro(1).divide(numbro(1).subtract(Number(debt_asset_ratio_ovalue))).format({
         mantissa: 2
       });
       //总资产净利率(JROA)=营业净利润率(operate_profit_ratio)*总资产周转次(total_assets_turnover)
       var JROA_ovalue = numbro(Number(operate_profit_ratio_ovalue)).multiply(Number(total_assets_turnover_ovalue)).format({
         mantissa: 4
       });
       //净资产收益率(ROE)=权益乘数(equity_multiplier)*总资产净利率(JROA)
       var ROE_ovalue = numbro(Number(equity_multiplier_ovalue)).multiply(Number(JROA_ovalue)).format({
         mantissa: 4
       });
       
       //净利润
       option.parent_netprofit_ovalue = util.convertEchartUnit(cols[_.indexOf(oldArray, '净利润')], parent_netprofit_ovalue);
       //营业总收入
       option.total_operate_income_ovalue = util.convertEchartUnit(cols[_.indexOf(oldArray, '营业总收入')], total_operate_income_ovalue);
       //资产总额
       option.total_assets_ovalue = util.convertEchartUnit(cols[_.indexOf(oldArray, '资产总额')], total_assets_ovalue);
       //负债总额
       option.total_liabilities_ovalue = util.convertEchartUnit(cols[_.indexOf(oldArray, '负债总额')], total_liabilities_ovalue);
       //资产负债率
       option.debt_asset_ratio_ovalue = numbro(Number(debt_asset_ratio_ovalue)).multiply(100).format({
         mantissa: 2
       }) + "%"
       //总资产周转率
       option.total_assets_turnover_ovalue = total_assets_turnover_ovalue + "次"
       //营业净利润率
       option.operate_profit_ratio_ovalue = numbro(Number(operate_profit_ratio_ovalue)).multiply(100).format({
         mantissa: 2
       }) + "%"
       //权益乘数
       option.equity_multiplier_ovalue = Number(equity_multiplier_ovalue)
       //总资产净利率
       option.JROA_ovalue = numbro(Number(JROA_ovalue)).multiply(100).format({
         mantissa: 2
       }) + "%"
       //净资产收益率
       option.ROE_ovalue = numbro(Number(ROE_ovalue)).multiply(100).format({
         mantissa: 2
       }) + "%"

      //===============计算浮动的杜邦数据==============

      //净利润浮动
      var parent_netprofit_float = numbro(numbro(numbro(Number(parent_netprofit_ovalue)).divide(Number(parent_netprofit_nvalue))).subtract(1)).multiply(100).format({
        mantissa: 2
      });
      //营业总收入浮动
      var total_operate_income_float = numbro(numbro(numbro(Number(total_operate_income_ovalue)).divide(Number(total_operate_income_nvalue))).subtract(1)).multiply(100).format({
        mantissa: 2
      });
       //资产总额浮动
      var total_assets_float = numbro(numbro(numbro(Number(total_assets_ovalue)).divide(Number(total_assets_nvalue))).subtract(1)).multiply(100).format({
        mantissa: 2
      });
      //资产总额浮动
      var total_liabilities_float = numbro(numbro(numbro(Number(total_liabilities_ovalue)).divide(Number(total_liabilities_nvalue))).subtract(1)).multiply(100).format({
        mantissa: 2
      });
      //资产负债率浮动
      var debt_asset_ratio_float = numbro(Number(debt_asset_ratio_ovalue)).subtract(Number(debt_asset_ratio_nvalue)).multiply(100).format({
        mantissa: 2
      })
      //总资产周转率次浮动
      var total_assets_turnover_float = numbro(Number(total_assets_turnover_ovalue)).subtract(Number(total_assets_turnover_nvalue)).format({
        mantissa: 2
      })
      //营业净利润率浮动
      var operate_profit_ratio_float = numbro(Number(operate_profit_ratio_ovalue)).subtract(Number(operate_profit_ratio_nvalue)).multiply(100).format({
        mantissa: 2
      })
      //权益乘数浮动
      var equity_multiplier_float = numbro(Number(equity_multiplier_ovalue)).subtract(Number(equity_multiplier_nvalue)).format({
        mantissa: 2
      })
      //总资产净利率浮动
      var JROA_float = numbro(Number(JROA_ovalue)).subtract(Number(JROA_nvalue)).multiply(100).format({
        mantissa: 2
      })
      //净资产收益率浮动
      var ROE_float = numbro(Number(ROE_ovalue)).subtract(Number(ROE_nvalue)).multiply(100).format({
        mantissa: 2
      })

      if(ROE_float>0){
        option.ROE_float = "↑" +ROE_float;
        option.ROE_style = "rising";
      }else{
        option.ROE_float = "↓"+ROE_float;
        option.ROE_style = "falling";
      }

      if(JROA_float>0){
        option.JROA_float = "↑"+JROA_float
        option.JROA_style = "rising";
      }else{
        option.JROA_float = "↓"+JROA_float
        option.JROA_style = "falling";
      }

      if(equity_multiplier_float>0){
        option.equity_multiplier_float = "↑"+equity_multiplier_float
        option.equity_multiplier_style = "rising";
      }else{
        option.equity_multiplier_float = "↓"+equity_multiplier_float
        option.equity_multiplier_style = "falling";
      }

      if(operate_profit_ratio_float>0){
        option.operate_profit_ratio_float = "↑"+operate_profit_ratio_float
        option.operate_profit_ratio_style = "rising";
      }else{
        option.operate_profit_ratio_float = "↓"+operate_profit_ratio_float
        option.operate_profit_ratio_style = "falling";
      }

      if(total_assets_turnover_float>0){
        option.total_assets_turnover_float = "↑"+total_assets_turnover_float
        option.total_assets_turnover_style = "rising";
      }else{
        option.total_assets_turnover_float = "↓"+total_assets_turnover_float
        option.total_assets_turnover_style = "falling";
      }

      if(debt_asset_ratio_float>0){
        option.debt_asset_ratio_float = "↑"+debt_asset_ratio_float
        option.debt_asset_ratio_style = "rising";
      }else{
        option.debt_asset_ratio_float = "↓"+debt_asset_ratio_float
        option.debt_asset_ratio_style = "falling";
      }

      if(total_liabilities_float>0){
        option.total_liabilities_float = "↑"+total_liabilities_float+"%"
        option.total_liabilities_style = "rising";
      }else{
        option.total_liabilities_float = "↓"+total_liabilities_float+"%"
        option.total_liabilities_style = "falling";
      }

      if(total_assets_float>0){
        option.total_assets_float = "↑"+total_assets_float+"%"
        option.total_assets_style = "rising";
      }else{
        option.total_assets_float = "↓"+total_assets_float+"%"
        option.total_assets_style = "falling";
      }

      if(total_operate_income_float>0){
        option.total_operate_income_float = "↑"+total_operate_income_float+"%"
        option.total_operate_income_style = "rising";
      }else{
        option.total_operate_income_float = "↓"+total_operate_income_float+"%"
        option.total_operate_income_style = "falling";
      }

      if(parent_netprofit_float>0){
        option.parent_netprofit_float = "↑"+parent_netprofit_float+"%"
        option.parent_netprofit_style = "rising";
      }else{
        option.parent_netprofit_float = "↓"+parent_netprofit_float+"%"
        option.parent_netprofit_style = "falling";
      }

      

    }
    option.edit = 'edit' //translate("COMMON.EDIT");
    option.refresh = 'refresh' //translate("COMMON.REFRESH");
    return option;
  }
}
export {
  DuPontRender
}

// Ourbore.service('chartKpi2Service', function (dataService, $compile) {

import   numbro  from 'numbro'
import * as util from '../../util/util'
import Kpi3RenderEngine from '../../rengine/Kpi3RenderEngine'
import './_kpi3.scss'
class Kpi3Render   {
    //默认高度
    height = 190;

    render = function (containerDom, option, scope, persist) {
        var render = new Kpi3RenderEngine(containerDom, option);
        var html = render.html(persist);
        if (scope) {
            containerDom.append(this.$compile(html)(scope));
        } else {
            containerDom.html(html);
        }
        return render.realTimeTicket();
    };

    parseOption = function (data) {
        var option = {};
        var config = data.chartConfig;
        var aggregate_data = data.data;

        //获取展示值 KpiValue
        // var col = config.values[0].cols[0];
        // option.kpiValue = aggregate_data.length > 0 ? aggregate_data[0][0] : 'N/A';
        // if(col && (col.unit || col.symbol ||col.dividend || col.mantissa)){
        //     option.kpiValue = util.convertEchartUnit(col,option.kpiValue);
        // }else{
        //     if (config.values[0].format) {
        //         option.kpiValue = numbro(option.kpiValue).format(config.values[0].format);
        //         var index = config.values[0].format.lastIndexOf("|");
        //         if (index != -1) {
        //             var unit = config.values[0].format.substring(index + 1, config.values[0].format.length);
        //             option.kpiValue = option.kpiValue + unit;
        //         }
        //     }
        // }

        option.kpiValue = {          //设置option.kpiValue为有三个键的对象
            // value:"",
            // unit:"",
            // unitFontSize
        };
        var col = config.values[0].cols[0];
        var value = aggregate_data.length > 0 ? aggregate_data[0][0] : 'N/A';
        if(col && (col.unit || col.symbol ||col.dividend || col.mantissa || col.unitFontSize)){
            option.kpiValue = util.convertEchartUnitToObj(col, value);
        }else{
            if (config.values[0].format) {
                option.kpiValue.value = numbro(value).format(config.values[0].format);
                var index = config.values[0].format.lastIndexOf("|");
                if (index != -1) {
                    var unit = config.values[0].format.substring(index + 1, config.values[0].format.length);
                    option.kpiValue.unit = unit;
                }
            }
        }
        option.kpiValue = {
            value: option.kpiValue.value || value,
            unit: option.kpiValue.unit || "",
            unitFontSize: option.kpiValue.unitFontSize || "1em"
        };

        //获取目标值 KpiValueTarget
        option.kpiValueTarget = {          //同样设置option.kpiValueTarget为有三个键的对象
            // value:"",
            // unit:"",
            // unitFontSize
        };
        if ( aggregate_data.length == 2) {
            var colTarget = config.values[0].cols[1];
            //option.kpiValueTarget = aggregate_data.length > 0 ? aggregate_data[1][0] : 'N/A';
            option.kpiValueTarget.value = aggregate_data.length > 0 ? aggregate_data[1][0] : 'N/A';
            if(colTarget && (colTarget.unit || colTarget.symbol ||colTarget.dividend || colTarget.mantissa || col.unitFontSize)){
                option.kpiValueTarget = util.convertEchartUnitToObj(colTarget,option.kpiValueTarget.value);
            }else{
                if (config.values[0].format) {
                    //option.kpiValueTarget = numbro(option.kpiValueTarget).format(config.values[0].format);
                    option.kpiValueTarget.value = numbro(option.kpiValueTarget.value).format(config.values[0].format);
                    var index = config.values[0].format.lastIndexOf("|");
                    if (index != -1) {
                        var unit = config.values[0].format.substring(index + 1, config.values[0].format.length);
                        //option.kpiValueTarget = option.kpiValueTarget + unit;
                        option.kpiValueTarget.unit = unit;
                    }
                }
            }
        }else if ( config.option.isKpi3Target && config.option.Kpi3TargetNumber && aggregate_data.length == 1) {
            //option.kpiValueTarget = config.option.Kpi3TargetNumber;
            option.kpiValueTarget.value = config.option.Kpi3TargetNumber;
            if (config.values[0].format) {
                //option.kpiValueTarget = numbro(option.kpiValueTarget).format(config.values[0].format);
                option.kpiValueTarget.value = numbro(option.kpiValueTarget.value).format(config.values[0].format);
                var index = config.values[0].format.lastIndexOf("|");
                if (index != -1) {
                    var unit = config.values[0].format.substring(index + 1, config.values[0].format.length);
                    //option.kpiValueTarget = option.kpiValueTarget + unit;
                    option.kpiValueTarget.unit = unit;
                }
            }
        }else{
            //option.kpiValueTarget = 0;
            option.kpiValueTarget.value = 0;
        }
        option.kpiValueTarget = {
            value: option.kpiValueTarget.value,
            unit: option.kpiValueTarget.unit || "",
            unitFontSize: option.kpiValueTarget.unitFontSize || "1em"
        };

        // 百分比 KpiPercent
        let target_data = 0, kpiPercent = 100;
        if ( aggregate_data.length == 2) {
            target_data = aggregate_data[1][0];
        } else if ( config.option.isKpi3Target && config.option.Kpi3TargetNumber && aggregate_data.length == 1) {
            target_data = config.option.Kpi3TargetNumber;
        }
        if(target_data > 0) {
            kpiPercent =  numbro( ( aggregate_data[0][0] / target_data ) * 100 ).format({
                mantissa: 2
            });
        } else if (target_data < 0) {
            kpiPercent =  numbro( ( 2 - (aggregate_data[0][0] / target_data) ) * 100 ).format({
                mantissa: 2
            });
        // } else {
          // 0的情况即未设置或为0，默认达成率为100
        }

        // if ( aggregate_data.length == 2) {
        //     var kpiPercent =  numbro( ( aggregate_data[0][0] / aggregate_data[1][0] ) * 100 ).format({
        //         mantissa: 2
        //     });
        // }else if ( config.option.isKpi3Target && config.option.Kpi3TargetNumber && aggregate_data.length == 1) {
        //     var kpiPercent =  numbro( ( aggregate_data[0][0] / config.option.Kpi3TargetNumber ) * 100 ).format({
        //         mantissa: 2
        //     });

        // }else{
        //     var kpiPercent = 100
        // }

        //进度条option配置
        var kpi3EchartsOption = {
            // backgroundColor: '#fff',
            series: {
                type: 'pie',
                radius: ['86%', '100%'],
                hoverAnimation: false,
                center: ['50%', '50%'],
                label: {
                    normal: {
                        position: 'center'
                    },
                },
                data: [
                        {
                            value: kpiPercent,
                            itemStyle: {
                                normal: {
                                    color: '#136EFA',
                                }
                            },
                            label: {
                                normal: {
                                    show: false,
                                }
                            }
                        },
                        {
                            value: (kpiPercent >= 100 ? 0 : 100 - kpiPercent),
                            name: '占位',
                            tooltip: {
                                show: false
                            },
                            itemStyle: {
                                normal: {
                                    // color: '#136EFA1A',
                                    color: 'rgba(19, 110, 250, 0.1)'
                                }
                            },
                            label: {
                                normal: {
                                    formatter: kpiPercent + '%',
                                    textStyle: {
                                        fontSize: 20,
                                        color: '#136EFA'
                                    }
                                },

                            }
                        }
                    ]
                }
            }

        option.echartOption = kpi3EchartsOption;
        option.kpiName = config.values[0].name;
        option.hasKpiName = option.kpiName || option.kpiName === 0 ? 'block' : 'none';
        option.bodyClass = option.kpiName || option.kpiName === 0 ? 'new_kpi_body' : 'new_kpi_body_noheader';
        option.style = config.values[0].style;
        option.edit = 'edit'//translate("COMMON.EDIT");
        option.refresh = 'refresh'//translate("COMMON.REFRESH");
        return option;
    };
}


export {Kpi3Render}

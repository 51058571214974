import * as util from '../util/util'
var Kpi2RenderEngine = function (jqContainer, options) {
    this.container = jqContainer; // jquery object
    this.options = options;
};

Kpi2RenderEngine.prototype.html = function (persist) {
    var self = this;
    var temp = "" + self.template;
    var html = util.StringRender(temp, self.options)
    // var html = temp.render(self.options);
    if (persist) {
        setTimeout(function () {
            self.container.css('background', '#fff');
            html2canvas(self.container, {
                onrendered: function (canvas) {
                    persist.data = canvas.toDataURL("image/jpeg");
                    persist.type = "jpg";
                    persist.widgetType = "kpi";
                }
            });
        }, 1000);
        // persist.data = {name: self.options.kpiName, value: self.options.kpiValue};
        // persist.type = "kpi";
    }
    return html;
};

Kpi2RenderEngine.prototype.realTimeTicket = function () {
    var self = this;
    return function (o) {
        var htmlStr = "<span style='vertical-align: middle;'>" + o.kpiValue.value + "</span><span style='vertical-align: middle; font-size:" + o.kpiValue.unitFontSize + ";'>" + o.kpiValue.unit + "</span>";
        $(self.container).find('.new_kpi_value').html(htmlStr);
        // $(self.container).find('h3').html(htmlStr);
        //$(self.container).find('h3').html(o.kpiValue);
        $(self.container).find('.kpi2_time').html(o.currentYear + o.yearName + o.currentMonth + o.monthName);
        $(self.container).find('.lastMonthValue').html(o.lastMonthValue);
        $(self.container).find('.lastYearValue').html(o.lastYearValue);
        $(self.container).find('.lastMonthPercent').html(o.lastMonthPercent + '%');
        $(self.container).find('.lastYearPercent').html(o.lastYearPercent + '%');
        $(self.container).find('.monthStyle span:first').removeClass().addClass(`iconfont ${o.monthStyle}`);
        $(self.container).find('.yearStyle span:first').removeClass().addClass(`iconfont ${o.yearStyle}`);
        $(self.container).find('.yearStyle').removeClass().addClass(`yearStyle _${o.yearStyle}`);
        $(self.container).find('.monthStyle').removeClass().addClass(`monthStyle _${o.monthStyle}`);
    }
};

Kpi2RenderEngine.prototype.do = function () {
    var self = this;
    $(self.container).html(self.rendered());
};
// <div class='kpi_handleClick'>
//     <span name='reload_{{widget.widget.id}}' ng-click='reload(widget)' style='cursor: pointer;margin-right:.5em'><i class='fa fa-refresh'></i> {refresh}</span>
//     <span ng-click='config(widget)' ng-if='widgetCfg' style='cursor: pointer'><span class='iconfont icon-icon_edit_kanban' style='font-size:1em!important; line-height:unset!important'></span> {edit}</span>
// </div>
// Kpi2RenderEngine.prototype.template =
//     `<div class='kpi2_wrapper'>
//     <div class="kpi_bgImg kpi_bgStyle">
//         <div style="padding: 0.5em 1.5em; overflow:hidden;" class="kpi2_color">
//             <div class="kpi2_name" title='{kpiName}'>{kpiName}</div>
//             <div class="kpi2_warm" style="display: {kpi2WarmFlag}"><span class='iconfont icon-icon_caveat'></span></div>
//         </div>
//         <div class='kpi2_time'>{currentYear}{yearName}{currentMonth}{monthName}</div>
//         <div class='inner' style="padding: 0 1.5em; text-align:center;">
//             <h3 style='font-size: 2.5em; font-weight: 700'>
//                 <span style="vertical-align: middle; ">{kpiValue.value}</span><span style="vertical-align: middle; font-size: {kpiValue.unitFontSize};" >{kpiValue.unit}</span>
//             </h3> 
//         </div>
//         <div style="padding:0 1.5em 1em 1.5em; font-size: 1.2em;">
//             <div class="kpi2_position" style="border-right: 2px solid #eee">
//                 <div class="_{monthStyle} monthStyle"><span class="iconfont {monthStyle}" aria-hidden="true"></span> <span class='lastMonthPercent' style="margin-left:.5em">{lastMonthPercent}%</span></div> 
//                 <div class='kpi2_color'>上{monthName} <span class="lastMonthValue">{lastMonthValue}</span></div>
//             </div>
//             <div class="kpi2_position">
//                 <div class="_{yearStyle} yearStyle"><span class="iconfont {yearStyle}" aria-hidden="true"></span> <span class='lastYearPercent' style="margin-left:.5em">{lastYearPercent}%</span></div>
//                 <div class='kpi2_color'>上{yearName} <span class="lastYearValue">{lastYearValue}</span></div>
//             </div>
//         </div>
//     </div>    
// </div>`;

Kpi2RenderEngine.prototype.template =`
    <div class="kpi2_wrapper new_kpi_container">
        <div class="kpi_bgImg kpi_bgStyle new_kpi_content">
            <div class="kpi_header_container">
                <div class="kpi_name" title='{kpiName}'>{kpiName}</div>
                <div class="kpi_warm" style="display: {kpi2WarmFlag}"><span class='iconfont icon-icon_caveat'></span></div>
            </div>
            <div class="new_kpi_body">
                <div class='inner' style="padding: 0 1.5em; text-align:center;">
                    <div class="new_kpi_value">
                        <span style="vertical-align: middle; ">{kpiValue.value}</span><span style="vertical-align: middle; font-size: {kpiValue.unitFontSize};" >{kpiValue.unit}</span>
                    </div> 
                </div>
                <div class='kpi2_time'>{currentYear}{yearName}{currentMonth}{monthName}</div>
                <div style="padding:0 1.5em 1em 1.5em; font-size: 1.2em;">
                    <div class="kpi2_position" style="border-right: 2px solid #eee">
                        <div class="_{monthStyle} monthStyle"><span class="iconfont {monthStyle}" aria-hidden="true"></span> <span class='lastMonthPercent' style="margin-left:.5em">{lastMonthPercent}%</span></div> 
                        <div class='kpi2_color'>上{monthName} <span class="lastMonthValue">{lastMonthValue}</span></div>
                    </div>
                    <div class="kpi2_position">
                        <div class="_{yearStyle} yearStyle"><span class="iconfont {yearStyle}" aria-hidden="true"></span> <span class='lastYearPercent' style="margin-left:.5em">{lastYearPercent}%</span></div>
                        <div class='kpi2_color'>上{yearName} <span class="lastYearValue">{lastYearValue}</span></div>
                    </div>
                </div>
            </div>
        </div>    
    </div>`;


export default Kpi2RenderEngine;
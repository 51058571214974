import BasicService from './BasicService';
import { ARCHIVES } from './ApiServer';
/**
 */
class ArchivesService extends BasicService {

  // 归档列表----alias--过滤关键词, source--来源, tagId, pageNum, pageSize
  getFileList(param) {
    return this.post(ARCHIVES.GETFILELIST, param);
  }
  //获取归档详情
  getFile(id) {
    return this.post(ARCHIVES.GETFILE, {id: id});
  }
  //删除一个归档
  deleteFile(id) {
    return this.post(ARCHIVES.DELETEFILE, {id: id});
  }
  //新增或修改一个归档----file--文件即图片, alias--名称, source--来源, tagId, remarks--描述, id?
  uploadImage(formData,onProgress) {
    return  this.post_file(ARCHIVES.UPLOADIMG, formData, onProgress)
  }

  //获取标签列表----pageNum--页码, pageSize----每页数量
  getTagList(param) {
    return this.post(ARCHIVES.GETTAGLIST, param);
  }
  //新增或修改标----id--新增不传, name----标签名称
  updateTag(param) {
    return this.post(ARCHIVES.UPDATETAG, param);
  }
  //删除一个标签
  deleteTag(id) {
    return this.post(ARCHIVES.DELETETAG, {id: id});
  }

}

export const archivesService = new ArchivesService();

import icon_pic from './contrast.png'
import icon_pic_active from './contrast-active.png'
var config = {
    rule: {
        keys: 1,
        groups: 0,
        filters: -1,
        values: 2
    },
    info:{
        name: 'CONFIG.WIDGET.CONTRAST',
        value: 'contrast',
        // class: 'cContrast',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_1',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_2'
    }
}
export default config;
import icon_pic from './sankey.png'
import icon_pic_active from './sankey-active.png'
var config = {
    rule: {
        keys: 2,
        groups: 2,
        filters: -1,
        values: 1
    },
    info:{
        name: 'CONFIG.WIDGET.SANKEY',
        value: 'sankey',
        // class: 'cSankey',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_1_MORE',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_1'
    }
}
export default config;
import mySetToolToggle from './mySetToolToggle.directive';
import mySetToolInputbox from './mySetToolInputbox.directive';
import mySetToolInputcolor from './mySetToolInputcolor.directive';
import mySetToolSelect from './mySetToolSelect.directive';
import mySetToolSelectExp from './mySetToolSelectExp.directive';
import mySetToolSelectExpSearch from './mySetToolSelectExpSearch.directive';
import mySetToolSelectInputbox from './mySetToolSelectInputbox.directive';
import mySetToolInputSilder from './mySetToolInputSilder.directive';
import mySetToolPop from './mySetToolPop.directive';
import './_setTool.scss';

export default angular.module('BlurAdmin.theme.components.setTool', [])
  .directive('mySetToolToggle', mySetToolToggle)
  .directive('mySetToolInputbox', mySetToolInputbox)
  .directive('mySetToolInputcolor', mySetToolInputcolor)
  .directive('mySetToolSelect', mySetToolSelect)
  .directive('mySetToolSelectExp', mySetToolSelectExp)
  .directive('mySetToolSelectExpSearch', mySetToolSelectExpSearch)
  .directive('mySetToolSelectInputbox', mySetToolSelectInputbox)
  .directive('mySetToolInputSilder', mySetToolInputSilder)
  .directive('mySetToolPop', mySetToolPop).name;
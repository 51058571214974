/**
 * Created by  jaray  2018-11-28
 */
'use strict';
import * as util from '../../util/util'
import EchartRenderEngine from "../../rengine/EchartRenderEngine";
import numbro from 'numbro'
class ScatterRender{
    //默认高度
    height = 500;

     render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        var render = new  EchartRenderEngine(containerDom, option);
        render.addClick(chartConfig, relations);
        return render.chart(null, persist);
    };

     parseOption = function (data) {
        var chartConfig = data.chartConfig;
        var casted_keys = data.keys;
        var casted_values = data.series;
        var aggregate_data = data.data;
        var newValuesConfig = data.seriesConfig;
        var tunningOpt = chartConfig.option;

        var colFormatter = [];
        var setColFormatter = false;
        //获取指标数组
        for (var i = 0; i < chartConfig.values.length; i++) {
            for (var j = 0; j < chartConfig.values[i].cols.length; j++) {
                var col = chartConfig.values[i].cols[j];
                colFormatter.push(col);
                //适配原有的图表配置,如果设置了提示框单位转换才需要转换单位,否则就用原先默认的
                if(col && (col.unit || col.symbol ||col.dividend || col.mantissa)){
                    setColFormatter = true;
                }
            }
        }

        var string_keys = _.map(casted_keys, function (key) {
            return key.join('-');
        });
        var series = [];
        var valueName = [];

        for (var i = 0; i < casted_values.length; i++) {
            var joined_values = casted_values[i].join('-');
            var valueAxisIndex = newValuesConfig[joined_values].valueAxisIndex;
            var name = casted_values[i].slice(0, -1).join('-');

            var s = _.find(series, function (_s) {
                return _s.name == name;
            });
            if (!s) {
                s = {name: name};
                series.push(s);
            }
            if (valueAxisIndex == 0) {
                s.yIdx = i;
            }
            if (valueAxisIndex == 1) {
                s.sizeIdx = i;
            }
            if (valueAxisIndex == 2) {
                s.colorIdx = i;
            }
            valueName[valueAxisIndex] = casted_values[i][casted_values[i].length - 1];
        }
        var data = _.unzip(aggregate_data);

        _.each(series, function (s) {
            s.data = _.map(data, function (d, i) {
                return [string_keys[i], d[s.yIdx], d[s.sizeIdx] ? d[s.sizeIdx] : 1, d[s.colorIdx] ? d[s.colorIdx] : 1];
            });
            s.sizeMax = _.max(s.data, function (d) {
                return Number(d[2]);
            })[2];
            s.sizeMin = _.min(s.data, function (d) {
                return Number(d[2]);
            })[2];
            s.colorMax = _.max(s.data, function (d) {
                return Number(d[3]);
            })[3];
            s.colorMin = _.min(s.data, function (d) {
                return Number(d[3]);
            })[3];
        });
        var sizeMax = _.max(series, function (s) {
            return Number(s.sizeMax);
        }).sizeMax;
        var sizeMin = _.min(series, function (s) {
            return Number(s.sizeMin);
        }).sizeMin;
        var colorMax = _.max(series, function (s) {
            return Number(s.colorMax);
        }).colorMax;
        var colorMin = _.max(series, function (s) {
            return Number(s.colorMin);
        }).colorMin;

        // x轴部分
        var xAxis = {
            data: string_keys,
            inverse: false,
            nameTextStyle: {
                fontSize: 12
            },
            axisLine: {
                lineStyle: {}
            },
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            },
            axisLabel: {
                interval: 'auto',
                rotate: 0,
                textStyle: {
                    fontSize: 12
                }
            },
        };
        if( tunningOpt ) {
            // 反向坐标轴
            tunningOpt.categoryInverse ? xAxis.inverse = tunningOpt.categoryInverse : null;
            // 轴名称
            tunningOpt.categoryAxisName         ? xAxis.name                   = tunningOpt.categoryAxisName         : null;
            tunningOpt.categoryAxisNameLocation ? xAxis.nameLocation           = tunningOpt.categoryAxisNameLocation : null;
            tunningOpt.categoryAxisNameGap      ? xAxis.nameGap                = tunningOpt.categoryAxisNameGap      : null;
            tunningOpt.categoryAxisNameRotate   ? xAxis.nameRotate             = tunningOpt.categoryAxisNameRotate   : null;
            tunningOpt.categoryAxisNameFontSize ? xAxis.nameTextStyle.fontSize = tunningOpt.categoryAxisNameFontSize : null;
            tunningOpt.categoryAxisNameColor    ? xAxis.nameTextStyle.color    = tunningOpt.categoryAxisNameColor    : null;

            // 轴线
            tunningOpt.categoryAxisLine ? xAxis.axisLine  = tunningOpt.categoryAxisLine : null;
            // 分隔线
            tunningOpt.splitLine        ? xAxis.splitLine = tunningOpt.splitLine        : null;

            // 标签
            tunningOpt.categoryAxisLabelMargin || tunningOpt.categoryAxisLabelMargin === 0 ? xAxis.axisLabel.margin = tunningOpt.categoryAxisLabelMargin : null;
            tunningOpt.ctgLabelRotate            ? xAxis.axisLabel.rotate             = tunningOpt.ctgLabelRotate            : null;
            tunningOpt.categoryAxisLabelFontSize ? xAxis.axisLabel.textStyle.fontSize = tunningOpt.categoryAxisLabelFontSize : null;
            tunningOpt.categoryAxisLabelColor    ? xAxis.axisLabel.textStyle.color    = tunningOpt.categoryAxisLabelColor    : null;

            tunningOpt.ctgLabelInterval === undefined ? null : xAxis.axisLabel.interval = tunningOpt.ctgLabelInterval;

            // 容器小于1200时适当缩小字体大小
            if( data.containerWidth && data.containerWidth < 1200) {
                let fontSizeRate                   = ( data.containerWidth / 1200 + 1 ) / 2;
                xAxis.nameTextStyle.fontSize       = Math.ceil( xAxis.nameTextStyle.fontSize * fontSizeRate );
                xAxis.axisLabel.textStyle.fontSize = Math.ceil( xAxis.axisLabel.textStyle.fontSize * fontSizeRate );
            }
        }
        // if (tunningOpt) {
        //     var labelInterval, labelRotate, splitLine;
        //     tunningOpt.ctgLabelInterval ? labelInterval = tunningOpt.ctgLabelInterval : 'auto';
        //     tunningOpt.ctgLabelRotate ? labelRotate = tunningOpt.ctgLabelRotate : 0;
        //     tunningOpt.splitLine?splitLine = tunningOpt.splitLine.show : splitLine = true;
        // }


        // y轴部分
        var yValueAxis = util.clone(chartConfig.values[0]);
        var yAxis = {
            axisLabel: {},
            splitLine: {
                show: true,
                lineStyle: {
                    type: 'dashed'
                }
            },
            scale: true,
            //设置最大最小间隔
            minInterval :yValueAxis.minInterval || null,
            maxInterval :yValueAxis.maxInterval ||null,
            //最大最小刻度
            min: yValueAxis.min || null,
            max: yValueAxis.max || null,
            //y轴名称
            // name:yValueAxis.name || null,
        };
        if(yValueAxis) {
            // 刻度标签
            yValueAxis.axisLabel ? yAxis.axisLabel = yValueAxis.axisLabel : null;
            // 刻度标签数值换算
            yAxis.axisLabel.formatter = function (value) {
                if(tunningOpt.valAxisFormatter && tunningOpt.valAxisFormatter[0]){
                    return util.convertEchartUnit(tunningOpt.valAxisFormatter[0],value);
                }else{
                    return numbro(value).format("0a.[0000]");
                }
            };

            // 轴名称
            yValueAxis.name          ? yAxis.name          = yValueAxis.name          : null;
            yValueAxis.nameLocation  ? yAxis.nameLocation  = yValueAxis.nameLocation  : null;
            yValueAxis.nameGap       ? yAxis.nameGap       = yValueAxis.nameGap       : null;
            yValueAxis.nameRotate    ? yAxis.nameRotate    = yValueAxis.nameRotate    : null;
            yValueAxis.nameTextStyle ? yAxis.nameTextStyle = yValueAxis.nameTextStyle : null;

            // 轴线
            yValueAxis.axisLine  ? yAxis.axisLine  = yValueAxis.axisLine  : null;
            // 分隔线
            yValueAxis.splitLine ? yAxis.splitLine = yValueAxis.splitLine : null;

            // 容器小于1200时适当缩小字体大小
            if( data.containerWidth && data.containerWidth < 1200) {
                let fontSizeRate = ( data.containerWidth / 1200 + 1 ) / 2;
                // 轴名称
                if ( yAxis.name ) {
                    if( yAxis.nameTextStyle ) {
                        yAxis.nameTextStyle.fontSize = yAxis.nameTextStyle.fontSize ? Math.ceil( yAxis.nameTextStyle.fontSize * fontSizeRate ) : Math.ceil( 12 * fontSizeRate );
                    } else {
                        yAxis.nameTextStyle = {
                            fontSize : Math.ceil( 12 * fontSizeRate )
                        };
                    }
                }
                // 刻度标签
                if( yAxis.axisLabel.textStyle ) {
                    yAxis.axisLabel.textStyle.fontSize = yAxis.axisLabel.textStyle.fontSize ? Math.ceil( yAxis.axisLabel.textStyle.fontSize * fontSizeRate ) : Math.ceil( 12 * fontSizeRate );
                } else {
                    yAxis.axisLabel.textStyle = {
                        fontSize : Math.ceil( 12 * fontSizeRate )
                    };
                }
            }

        }

        // //暂且先这样--2020.09.04
        // var yValueSplitLine = true;
        // yValueSplitLine = yValueAxis.splitLine ? yValueAxis.splitLine.show : true;

        var echartOption = {
            legend: {
                data: _.map(series, function (v) {
                    return v.name;
                })
            },
            tooltip: {
                trigger: 'item',
                formatter: function (params) {

                    var s = params.seriesName + " " + params.value[0] + "</br>";
                    for (var i = 1; i < params.value.length; i++) {
                        //params数组第一个值是行维拼接而成所以不用转换单位,会比colFormatter(指标列)多一个值
                        if(setColFormatter){
                            params.value[i] =util.convertEchartUnit(colFormatter[i-1],params.value[i]);
                        }
                        if (valueName[i - 1] == undefined) continue;
                        s += valueName[i - 1] + " : " + params.value[i] + "<br>"
                    }
                    return s;
                }
            },
            xAxis: xAxis,
            yAxis: yAxis,
            // xAxis: {
            //     data: string_keys,
            //     splitLine: {
            //         show:splitLine,
            //         lineStyle: {
            //             type: 'dashed'
            //         }
            //     },
            //     axisLabel: {
            //         interval: labelInterval,
            //         rotate: labelRotate
            //     }
            // },
            // yAxis: {
            //     axisLabel: {
            //         formatter: function (value) {
            //             if(tunningOpt.valAxisFormatter && tunningOpt.valAxisFormatter[0]){
            //                 return util.convertEchartUnit(tunningOpt.valAxisFormatter[0],value);
            //             }else{
            //                 return numbro(value).format("0a.[0000]");
            //             }
            //         },

            //     },
            //     splitLine: {
            //         show:yValueSplitLine,
            //         lineStyle: {
            //             type: 'dashed'
            //         }
            //     },
            //     scale: true,
            //     //设置最大最小间隔
            //     minInterval :yValueAxis.minInterval || null,
            //     maxInterval :yValueAxis.maxInterval ||null,
            //     //最大最小刻度
            //     min: yValueAxis.min || null,
            //     max: yValueAxis.max || null,
            //     //y轴名称
            //     name:yValueAxis.name || null,
            // },
            visualMap: [
                {
                    dimension: 2,
                    show: false,
                    min: sizeMin * 0.8,
                    max: sizeMax * 1.5,
                    calculable: true,
                    precision: 0.1,
                    textStyle: {
                        color: 'white'
                    },
                    inRange: {
                        symbolSize: [5, 70]
                    }
                },
                {
                    dimension: 3,
                    show: false,
                    min: colorMin,
                    max: colorMax,
                    inRange: {
                        opacity: [0.2, 1]
                    }

                }],
            series: _.map(series, function (v) {
                var length = -1;
                return {
                    name: v.name,
                    data: _.map(v.data, function (u) {
                        length ++;
                        return {
                            name: string_keys[length],
                            value: u
                        }
                    }),
                    type: 'scatter'
                };
            })
        };

            // //设置图表数值标签
            // var label = util.clone(chartConfig.values[0].label);
            // label.position.left ? null : label.position.left = 0;
            // label.position.top ? null : label.position.top = 0;
            // label.rotate ? null : label.rotate = 0;
            // label.fontSize ? null : label.fontSize = 12;
            // label.position = [label.position.left,label.position.top];
            // echartOption.series[0].label = label;
            // echartOption.series[0].label.formatter = function (value) {
            //     var s = "";
            //     for (var i = 1; i < value.value.length; i++) {
            //         if(setColFormatter){
            //             value.value[i] =util.convertEchartUnit(colFormatter[i-1],value.value[i]);
            //         }
            //         if (valueName[i - 1] == undefined) continue;
            //         s += valueName[i - 1] + " : " + value.value[i] + "\n"
            //     }
            //     return s;
            // }

        util.updateEchartOptions(chartConfig.option, echartOption);
        util.updateEchartsTooltipPosition(echartOption);
        return echartOption;
    };
}
export {ScatterRender}

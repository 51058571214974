
//水印---覆盖父盒子----父盒子需要定位

import {userService} from '../../../server'  
export default function myWaterMark ($compile) {
    return {
        restrict: 'EA',
        template: `<div class="bore-water-mark-box" id="{{id}}" ng-show="isShow"></div>`,
        scope: {
            isShow: '=',
            customText: '=',
            isShowDate: '=',
            isShowUsername: '='
        },
        replace: true,
        link: function(scope, element, attrs){
            scope.id = "myWaterMark" + Math.ceil(Math.random()*10000);
            var targetAreaJQDom = $(element).parent();
            var width = targetAreaJQDom.width();
            var height = targetAreaJQDom.height();

            userService.getUserInfo().then((user) => {
                scope.user = user;
                createWaterMark();
            });
            //生成水印的方法
            var createWaterMark = function(){
                element.html('');
                if(scope.isShow) {
                    if(scope.isShowDate) {
                        scope.curDate = moment(new Date()).format("YYYY-MM-DD HH:mm:ss");
                    }
                    width = targetAreaJQDom.width();
                    height = targetAreaJQDom.height();
                    for (var i = 0; i < width / 300; i++){
                        for(var j = 0; j < (height + 300) / 300; j++){
                            // $('<div class="bore-water-mark-item" style="left: ' +(i*300 + j%2 * 100)+ 'px; top: ' + (j*300 + i%2 * 50 + 50) +'px;" ng-cloak><div ng-if="customText">{{customText}}</div><div ng-if="isShowUsername">{{user.name}}</div><div ng-if="isShowDate">{{curDate}}</div></div>').appendTo(element);
                            var canvas = document.createElement('canvas');
                            canvas.width = 200;
                            canvas.height = 60;
                            $(canvas).addClass('bore-water-mark-item').css({'left': (i*300 + j%2 * 100)+ 'px', 'top': (j*300 + (i + 1)%2 * 100 + 50) +'px'});
                            var ctx = canvas.getContext("2d");
                            // ctx.rotate(330*Math.PI/180);
                            ctx.font = "normal 16px arial";

                            var top = 18;
                            if(scope.customText) {
                                ctx.fillText(scope.customText,0,top);
                                top += 18;
                            }
                            if(scope.isShowUsername && !!scope.user && !!scope.user.name) {
                                ctx.fillText(scope.user.name,0,top);
                                top += 18;
                            }
                            if(scope.isShowDate) {
                                ctx.fillText(scope.curDate,0,top);
                            }

                            $(canvas).appendTo(element);
                        }
                    }
                    $compile(element)(scope);
                }
            };

            scope.$watch('isShow + customText+isShowDate + isShowUsername + user.name', function(){
                createWaterMark();
            });

        }
    };
  
};
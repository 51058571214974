import { datasetService,datasetMetaTableService,userService } from '../../../../server'
import '../dataset.scss'
import metaTableModelCtrl from './metaTableModelCtrl'
import  PubSub from '../../../../common/utils/PubSub'

export default function metaTableCtrl($scope, $state, widgetModal, toastr, $uibModal, ModalUtils, uuid4, $filter) {
    var translate = $filter('translate');
    $scope.search = {};

    $scope.paginationConf = {
        currentPage: 1, //第几页
        totalItems: 0, //共几条
        itemsPerPage: 20, //每页最多几条
        onChange: function () {
            $scope.getMetaTableList();
        }
    };
  
    $scope.doEditDataSet=(data, option)=>{
        // if(data.id && option=='edit'){
        //     userService.judgePermission({option:"update",resType:"dataset",resId:dataSetId}).then(function(data){
        //         if(!data){
        //             toastr.error("您没有编辑该数据集权限！")
        //             return;
        //         }
        //         $scope.openModel(data,option);
        //     },err=>{
        //         toastr.error(err.head.errorMsg || '获取权限失败！')
        //     })
        // }else if(data.id && option=='query'){
        //     userService.judgePermission({option:"query",resType:"dataset",resId:data.id}).then(function(data){
        //         if(!data){
        //             toastr.error("您没有编辑该数据集权限！")
        //             return;
        //         }
        //         $scope.openModel(data,option);
        //     },err=>{
        //         toastr.error(err.head.errorMsg || '获取权限失败！')
        //     })
        // }else{
        //     $scope.openModel(data,option);
        // }
        $scope.openModel(data,option);
    }

    $scope.openModel = function(metatableId,optFlag){
        var uibModalInstance = $uibModal.open({
            animation: true,
            backdrop: false,
            template: require('./metaTableModel.html'),
            controller: ['$scope', '$filter', 'toastr', 'metatableId','optFlag', '$uibModal', 'uuid4','ModalUtils', metaTableModelCtrl],
            size: 'lg',
            windowClass: 'widgetmodal',
            resolve: {
                metatableId: function () {
                    return metatableId;
                },
                optFlag:function(){
                    return optFlag;
                }
            }
        });
        return uibModalInstance.result;
    }

    $scope.getMetaTableList = function (callback) {
        datasetMetaTableService.getPageList({
            name: $scope.search.name,
            code: $scope.search.code,
            search: $scope.search.search,
            pageNumber: $scope.paginationConf.currentPage,
            pageSize: $scope.paginationConf.itemsPerPage
        }).then(function (response) {
            $scope.metaTableDatas = response.listdata;
            $scope.paginationConf.totalItems = response.totolCount;
            // toastr.success("数据加载成功！");        
            $scope.$apply()
        },err=>{
            toastr.error((err.head || err.head.errorMsg) || "数据加载失败!")
        });
    };

    PubSub.subscribe('METATABLECHANGE', () => {
        $scope.getMetaTableList()
    })

    $scope.deleteDs = function (id) {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-warning", "lg", function () {
            // userService.judgePermission({option:"delete",resType:"dataset",resId:ds.id}).then(function(data){
            //     if(data){
            datasetMetaTableService.delMetaTable({id:id}).then(function(response){
                toastr.success("删除成功!")
                $scope.getMetaTableList();
            },err=>{
                toastr.error(err.head.errorMsg || '删除失败！')
            })
            //     }else{
            //         toastr.error(err.head.errorMsg || '权限不足！')
            //     }
            // },err=>{
            //     toastr.error(err.head.errorMsg || '获取删除权限异常！')
            // })
        });   
    };

    // $scope.copyDs = function (ds) {
    //     userService.judgePermission({option:"update",resType:"dataset",resId:ds.id}).then(function(data){
    //         if(!data){
    //             toastr.error("您没有编辑该数据集权限！")
    //             return;
    //         }
    //         datasetService.getDatasetDetail({id:ds.id}).then(function(response){
    //             var copyDataset = response.dataset;
    //             copyDataset.data = angular.fromJson(response.dataset.data);
    //             copyDataset.name = copyDataset.name + "_copy";
    //             datasetService.saveNewDataset({
    //                 json: angular.toJson(copyDataset)
    //             }).then(function (serviceStatus) {
    //                 if (serviceStatus.status == '1') {
    //                     $scope.optFlag = 'none';
    //                     getDatasetList();
    //                     toastr.success("复制成功");
    //                 } else {
    //                     toastr.error("复制失败");
    //                 }
    //             });
    //         })
    //     },err=>{
    //         toastr.error(err.head.errorMsg || '获取权限失败！')
    //     })
        
    // };
}
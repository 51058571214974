import MapRenderEngine from '../../rengine/MapRenderEngine'
import * as util  from '../../util/util'
class  MapRender{
    //默认高度
    height = 500;
    
     render = function (containerDom, option, scope, persist, drill) {
        if (option == null) {
            containerDom.html("<div class=\"alert alert-danger\" role=\"alert\">No Data!</div>");
            return;
        }
        var height;
        scope ? height = scope.myheight : null;
        return new  MapRenderEngine(containerDom, option, drill).do(height, persist);
    };

    parseOption = function (data) {
      
        var mapOption = util.chartDataProcess(data.chartConfig, data.keys, data.series, data.data, data.seriesConfig);
        return mapOption;
    };
} 
export {MapRender}
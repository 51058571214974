import { datasetService,userService,behaviorLogService } from '../../../../server'
import dataContext from  '../../../../charts/DataContext'
import  PubSub from '../../../../common/utils/PubSub'
export default function multiSourceModelCrtl($scope, $filter, toastr, dataSetId, categoryName,$uibModal, uuid4, ModalUtils) {
    var translate = $filter('translate');
    $scope.optFlag =  'none';
    $scope.dataSetId = dataSetId;
    $scope.loading = true;
    $scope.queryAceOpt = cbAcebaseOption;
    $scope.dtp=[];
    $scope.dataset=[];
    $scope.selects=[];
    var trash = {};
    $scope.hierarchy = translate("CONFIG.DATASET.HIERARCHY");
    $scope.uuid4 = uuid4;
    $scope.associateList = [
        {
            "name":"左连接",
            "value":'left'
        },
        {
            "name":"右连接",
            "value":'right'
        },
        {
            "name":"全连接",
            "value":'all'
        },
        {
            "name":"内连接",
            "value":'inner'
        }
    ]
    $scope.curDataset ={
        categoryName:categoryName,
        name:categoryName?categoryName + '/':'',
        type:'1',
        data: {
            associate:"inner",
            id:null,
            datasetIds:[],
            expressions: [],
            filters: [],
            assFields:[],
            schema: {
                dimension: [],
                measure: []
            }
        }
    }

    $scope.columnToMeasure=function(index,o){
        var cols = _.pluck($scope.curDataset.data.schema.measure,"column")
        if(cols.indexOf(o.column)>-1){
            toastr.warning("指标名不允许同名")
            return;
        }
        $scope.curDataset.data.schema.dimension.splice(index, 1);
        $scope.curDataset.data.schema.measure.push(o)
    }
    $scope.getMultisourceDataseNoDs = function(){
        datasetService.getDatasetDetailNoDs({id:dataSetId}).then(function(response){
            $scope.curDataset = response.dataset;
            $scope.curDataset.name = $scope.curDataset.categoryName + '/' + $scope.curDataset.name;
            $scope.curDataset.data = angular.fromJson(response.dataset.data);
            $scope.datasets =  angular.fromJson(response.childrens);
            $scope.selects = $scope.curDataset.data.selects;
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg || "获取信息失败!")
        })
    }


    $scope.changeDataset =function(data,index){
        if (data) {
            $scope.selects=[];
            $scope.curDataset.data.expressions= [];
            $scope.curDataset.data.filters= [];
            $scope.curDataset.data.schema= {
                dimension: [],
                measure: []
            };
            $scope.curDataset.data.assFields= [];
            $scope.curDataset.data.expressions= [];
            $scope.curDataset.data.datasetIds[index]=data.id
        }
    }
    //指标列转换为维度
    $scope.measureToDimension = function (index, o) {
        $scope.curDataset.data.schema.measure.splice(index, 1);
        $scope.curDataset.data.schema.dimension.push(o);
    };

    //删除可选过滤
    $scope.deleteFilterGroup = function (index) {
        ModalUtils.confirm(translate("COMMON.FILTER_GROUP") + ": [" + $scope.curDataset.data.filters[index].group + "], " +
            translate("COMMON.CONFIRM_DELETE"), "modal-warning", "lg",
            function () {
                $scope.curDataset.data.filters.splice(index, 1)
            }
        );
    };
    //删除可选表达式
    $scope.deleteExp = function (index) {
        ModalUtils.confirm(translate("CONFIG.COMMON.CUSTOM_EXPRESSION") + ": [" + $scope.curDataset.data.expressions[index].alias + "], " +
            translate("COMMON.CONFIRM_DELETE"), "modal-warning", "lg",
            function () {
                $scope.curDataset.data.expressions.splice(index, 1)
            }
        );
    };

    $scope.deleteAssFields = function(index){
        ModalUtils.confirm("确定删除关联字段" + ": [" + $scope.curDataset.data.assFields[index].alias + "]吗? "
            , "modal-warning", "lg",
            function () {
                $scope.curDataset.data.assFields.splice(index, 1)
            }
        );
    }

     //删除可选表达式
     $scope.deleteExp = function (index) {
        ModalUtils.confirm(translate("CONFIG.COMMON.CUSTOM_EXPRESSION") + ": [" + $scope.curDataset.data.expressions[index].alias + "], " +
            translate("COMMON.CONFIRM_DELETE"), "modal-warning", "lg",
            function () {
                $scope.curDataset.data.expressions.splice(index, 1)
            }
        );
    };

    $scope.createNode = function (item) {
        if (trash[item.column]) {
            var _i = trash[item.column].pop();
            if (_i) {
                return _i;
            }
        }
        item.id = uuid4.generate();
        return item;
    };

    $scope.toDimension = function (o,sdx,odx) {
        o.alias = $scope.curDataset.data.aliasColumn[sdx][odx];
        $scope.curDataset.data.schema.dimension.push($scope.createNode(o));
    };

    $scope.checkExist = function (column,index,odx) {
        var alias = $scope.curDataset.data.aliasColumn[index][odx];
        var find = _.find($scope.curDataset.data.schema.measure, function (e) {
            return e.alias == alias;
        });
        if (!_.isUndefined(find)) {
            return true;
        }
        find = _.find($scope.curDataset.data.schema.dimension, function (e) {
            if (e.type == 'level') {
                var _find = _.find(e.alias, function (_e) {
                    return _e.alias == alias;
                });
                return !_.isUndefined(_find);
            } else {
                return e.alias == alias;
            }
        });
        return !_.isUndefined(find);
    };

    $scope.inserBehaviortLog =function(){
        try {
            behaviorLogService.insertLog({module:"dataset",function:"查看数据集",name:$scope.dataset.name})
        } catch (error) {
            // console.log("插入日志失败:"+error)
        }
    }

    $scope.toTrash = function (array, index) {
        var o = array[index];
        if (o.type == 'column') {
            if (!trash[o.column]) {
                trash[o.column] = [];
            }
            trash[o.column].push(o);
        }
        array.splice(index, 1);
    };

    $scope.dndTransfer = {
        dimension: function (list, index, item, type) {
            if (type == 'column') {
                list[index] = {
                    type: 'column',
                    column: item
                };
            }
        },
        measure: function (list, index, item, type) {
            if (type == 'column') {
                list[index] = {
                    type: 'column',
                    column: item
                };
            }
        }
    };
    //编辑表达式
    $scope.editExp = function (col) {
        var aggregate = [{
            name: 'sum',
            value: 'sum'
        },
        {
            name: 'count',
            value: 'count'
        },
        {
            name: 'avg',
            value: 'avg'
        },
        {
            name: 'max',
            value: 'max'
        },
        {
            name: 'min',
            value: 'min'
        },
        {
            name: 'distinct',
            value: 'distinct'
        }
        ];
        var ok;
        var data = {
            expression: ''
        };
        if (!col) {
            ok = function (exp, alias) {
                $scope.curDataset.data.expressions.push({
                    type: 'exp',
                    exp: data.expression,
                    alias: data.alias,
                    id: uuid4.generate(),
                    dId:-1
                });
            }
        } else {
            data.expression = col.exp;
            data.alias = col.alias;
            ok = function (data) {
                col.exp = data.expression;
                col.alias = data.alias;
                col.dId = -1;
            }
        }
        var columnObjs = schemaToSelect($scope.curDataset.data.schema, $scope.selects);
        var expressions = $scope.curDataset.data.expressions;
        $uibModal.open({
            template: require('../exp.html'),
            // windowTemplate: require('../../../public/util/modal/window.html'),
            backdrop: false,
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                $scope.data = data;
                $scope.columnObjs = columnObjs;
                $scope.aggregate = aggregate;
                $scope.expressions = expressions;
                $scope.alerts = [];
                $scope.expAceOpt = expEditorOptions($scope.selects, aggregate, function (_editor) {
                    $scope.expAceEditor = _editor;
                    $scope.expAceSession = _editor.getSession();
                    _editor.focus();
                });
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                $scope.addToken = function (str, agg,dId) {
                    var editor = $scope.expAceEditor;
                    if(dId){
                        var index = $scope.$parent.curDataset.data.datasetIds.indexOf(dId);
                        str += (index>-1?"-d"+(index+1):"");
                    }
                    editor.session.insert(editor.getCursorPosition(), str);
                    editor.focus();
                    if (agg) editor.getSelection().moveCursorLeft();
                };

                $scope.verify = function () {
                    $scope.alerts = [];
                    var v = verifyAggExpRegx($scope.data.expression);
                    $scope.alerts = [{
                        msg: v.isValid ? translate("COMMON.SUCCESS") : v.msg,
                        type: v.isValid ? 'success' : 'danger'
                    }];
                };
                $scope.ok = function () {
                    if (!$scope.data.alias) {
                        ModalUtils.alert(translate('CONFIG.WIDGET.ALIAS') + translate('COMMON.NOT_EMPTY'), "modal-warning", "lg");
                        return;
                    }
                    $scope.data.expression = $scope.expAceSession.getValue();
                    ok($scope.data);
                    $uibModalInstance.close();
                };
            }]
        });
    };

    //获取可选表达式字段
    var schemaToSelect = function (schema, rawSelects) {
        if (schema.selects) {
            var selects = []
            angular.forEach(schema.selects,function(index,select){
                angular.forEach(select,function(e){
                    // e.dId = $scope.curDataset.data.datasetIds[index]
                    selects.push(e+"-d"+(index+1))
                })
            })
            return selects;
        } else {
            var selects = [];
            selects = selects.concat(schema.measure);
            _.each(schema.dimension, function (e) {
                if (e.type == 'level') {
                    _.each(e.columns, function (c) {
                        selects.push(c);
                    });
                } else {
                    selects.push(e);
                }
            });
            _.each(rawSelects, function (rawSelect) {
                _.each(rawSelect, function (col) {
                if (_.find(selects, function (o) {
                    return col == o.column;
                }) === undefined) {
                    selects.push({
                        column: col
                    });
                }
            })});
            return angular.copy(selects);
        }
    };

    $scope.custom = function (o) {
        var selects = $scope.selects;
        var datasource = $scope.datasource;
        $uibModal.open({
            template: require('../custom.html'),
            // windowTemplate: require('../../../public/util/modal/window.html'),
            backdrop: false,
            size: 'lg',
            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                $scope.c = o;
                $scope.ok = function () {
                    $uibModalInstance.close();
                };
                // $scope.customAceOpt = schemaCustomOpt(selects, datasource.type);
            }]
        });
    };

     //编辑关联字段
    $scope.editAssFields =function(data,index){
        var selects = angular.copy($scope.selects);
        if(!selects ||selects.length<2 || selects[0].length<1 || selects[1].length<1){
            toastr.warning("数据集维度不能为空!")
            return;
        }
        $uibModal.open({
            // template: filterGroupHtml,
            template: require('../assFields.html'),
            // windowTemplate: require('../../../public/util/modal/window.html'),
            backdrop: false,
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                if (data) {
                    $scope.data = angular.copy(data);
                    $scope.option = 'edit';
                } else {
                    $scope.option = 'add';
                    $scope.data = {
                        d1: '',
                        d2: '',
                        alias: ''
                    };
                }
                $scope.selects = selects;
                $scope.close = function () {
                    $uibModalInstance.close();
                };

                $scope.ok = function () {
                    if(!$scope.data || !$scope.data.d1 || !$scope.data.d2 || !$scope.data.alias){
                        toastr.warning("请输入必填项!")
                        return;
                    }
                    if( $scope.option=='add'){
                      $scope.$parent.curDataset.data.assFields.push($scope.data)
                    }else{
                      $scope.$parent.curDataset.data.assFields[index]=$scope.data;
                    }
                    $uibModalInstance.close();
                };

            }]
        });
    }

    //编辑可选过滤
    $scope.editFilterGroup = function (col) {
        var columnObjs = schemaToSelect($scope.curDataset.data.schema);
        $uibModal.open({
            // template: filterGroupHtml,
            template: require('../filterGroup.html'),
            // windowTemplate: require('../../../public/util/modal/window.html'),
            backdrop: false,
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                if (col) {
                    $scope.data = angular.copy(col);
                } else {
                    $scope.data = {
                        group: '',
                        filters: [],
                        id: uuid4.generate()
                    };
                }
                $scope.columnObjs = columnObjs;
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                $scope.addColumn = function (str,o) {
                    $scope.data.filters.push({
                        col: str,
                        type: '=',
                        values: [],
                        dId:o.dId
                    })
                };
                $scope.ok = function () {
                    if (col) {
                        col.group = $scope.data.group;
                        col.filters = $scope.data.filters;
                    } else {
                        if ($scope.$parent.curDataset.data.filters == null) {
                            $scope.$parent.curDataset.data.filters = [];
                        }
                        $scope.$parent.curDataset.data.filters.push($scope.data);
                    }
                    $uibModalInstance.close();
                };
                $scope.editFilter = function (filter) {
                    $uibModal.open({
                        template: require('../param.html'),
                        // windowTemplate: require('../../../public/util/modal/window.html'),
                        backdrop: false,
                        size: 'lg',
                        resolve: {
                            param: function () {
                                return angular.copy(filter);
                            },
                            filter: function () {
                                return false;
                            },
                            getSelects: function () {
                                return function (byFilter, column, callback) {
                                    dataContext.getDimensionValues(undefined,undefined,undefined,true,filter.dId,column, undefined, function (filtered) {
                                        callback(filtered);
                                    });
                                };
                            },
                            ok: function () {
                                return function (param) {
                                    filter.type = param.type;
                                    filter.values = param.values;
                                }
                            }
                        },
                        controller: 'paramSelector'
                    });
                };
            }]
        });

    };

    //读取数据
    $scope.loadData = function () {
        $scope.loading = true;
        $scope.alerts = [];
        getColumnsByIds($scope.curDataset.id,$scope.curDataset.data.datasetIds);
    };


    $scope.getDatasetList = function () {
       datasetService.getDatasetList().then(function(response){
           $scope.datasetList = _.filter(response,function(e){
               return !e.type || e.type=='0'
           })
           if(dataSetId){
            $scope.getMultisourceDataseNoDs();
            }
       });
    };
    $scope.getDatasetList();

    var getColumnsByIds = function(datasetId,index){
        dataContext.getColumns({
            datasetId: datasetId,
            childIds:$scope.curDataset.data.datasetIds.toString(),
            callback: function (dps) {
                $scope.loading = false;
                if (dps.msg == "1") {
                    $scope.selects = dps.columns;
                    $scope.curDataset.data.aliasColumn = dps.aliasColumn;
                    $scope.curDataset.data.columns = dps.columns;
                } else {
                    var alert = {
                        msg: dps.msg,
                        type: 'danger'
                    }
                    $scope.alerts.push(alert);
                }
                //不允许同名字段
                // if(index>0 && $scope.selects[index-1] && $scope.selects[index-1].length>0){
                //     var filter = _.filter($scope.selects[index],function(e){
                //         return $scope.selects[index-1].indexOf(e)>-1
                //     })
                //     if(filter && filter.length>0){
                //         $scope.alerts.push({msg: '两数据集存在同名字段!',type: 'danger'})
                //         $scope.selects=[];
                //     }
                // }
                $scope.$apply()
            }
        });
    }

    // $scope.filterDataset=function(e,index){
    //     if($scope.curDataset.data.datasetIds.indexOf(e.id)>-1){
    //         return false;
    //     }
    //     return true;
    // }

    var validate = function () {
        $scope.alerts = [];
        if (!$scope.curDataset.name) {
            $scope.alerts = [{
                msg: translate('CONFIG.DATASET.NAME') + translate('COMMON.NOT_EMPTY'),
                type: 'danger'
            }];
            $scope.verify = {
                dsName: false
            };
            $("#DatasetName").focus();
            return false;
        }
        if (!$scope.curDataset.data || !$scope.curDataset.data.datasetIds || !$scope.curDataset.data.datasetIds[0] || !$scope.curDataset.data.datasetIds[1]) {
            $scope.alerts = [{
                msg:'数据集不能为空!',
                type: 'danger'
            }];
            $scope.verify = {
                dsName: false
            };
            return false;
        }
        if($scope.curDataset.data.datasetIds[0] == $scope.curDataset.data.datasetIds[1]){
            $scope.alerts = [{
                msg:'不能选择相同数据集!',
                type: 'danger'
            }];
            $scope.verify = {
                dsName: false
            };
            return false;
        }
        if (!$scope.curDataset.data.associate) {
            $scope.alerts = [{
                msg:'请选择数据集关联方式!',
                type: 'danger'
            }];
            return false;
        }
        if (!$scope.curDataset.data.assFields || $scope.curDataset.data.assFields.length<1) {
            $scope.alerts = [{
                msg:'请至少设置一个关联字段!',
                type: 'danger'
            }];
            return false;
        }
        for (i in $scope.params) {
            var name = $scope.params[i].name;
            var label = $scope.params[i].label;
            var required = $scope.params[i].required;
            var value = $scope.curWidget.query[name];
            if (required == true && value != 0 && (value == undefined || value == "")) {
                var pattern = /([\w_\s\.]+)/;
                var msg = pattern.exec(label);
                if (msg && msg.length > 0)
                    msg = translate(msg[0]);
                else
                    msg = label;
                $scope.alerts = [{
                    msg: "[" + msg + "]" + translate('COMMON.NOT_EMPTY'),
                    type: 'danger'
                }];
                $scope.verify[name] = false;
                return false;
            }
        }
       _.each($scope.curDataset.data.aliasColumn,function(e,index){

       });
       if(!$scope.curDataset.data.aliasColumn){
            $scope.alerts = [{
                msg: "列名列表为空",
                type: 'danger'
            }];
           return false;
       }
       if($scope.curDataset.data.aliasColumn){
            var mergeAlias = _.flatten($scope.curDataset.data.aliasColumn);
            var repetAlias = _.uniq(mergeAlias.filter(i => mergeAlias.indexOf(i) !== mergeAlias.lastIndexOf(i)))
            if(repetAlias&&repetAlias.length>0){
                $scope.alerts = [{
                    msg: "列名["+repetAlias.toString()+"]重命名后仍然重复,请修改子数据集列名!",
                    type: 'danger'
                }];
                return false;
            }

        }
        return true;
    };


    $scope.save = function () {
        // $scope.curDataset.data.query = $scope.curWidget.query;
        if (!validate()) {
            return;
        }
        $scope.curDataset.data.selects = $scope.selects;
        var ds = angular.copy($scope.curDataset);
        var index = ds.name.lastIndexOf('/');
        ds.categoryName = $scope.curDataset.name.substring(0, index).trim();
        ds.name = $scope.curDataset.name.slice(index + 1).trim();
        ds.data.name = $scope.curDataset.name.slice(index + 1).trim();
        if (!$scope.curDataset.id) {
            datasetService.saveNewDataset({
                json: angular.toJson(ds)
            }).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    // getCategoryList();
                    // getDatasetList();
                    $scope.verify = {
                        dsName: true
                    };
                    $scope.curDataset.id = serviceStatus.id;
                     toastr.success("保存数据集成功!");
                    $scope.$dismiss();
                    //去除遮盖
                    $('.modal-backdrop').css("z-index","-1");
                    PubSub.publish('DATASETCHANGE','')
                } else {
                    $scope.alerts = [{
                        msg: serviceStatus.msg,
                        type: 'danger'
                    }];
                }
            });
        } else {
            userService.judgePermission({option:"update",resType:"dataset",resId:$scope.curDataset.id}).then(function(data){
                if(!data){
                    toastr.error("您没有编辑该数据集权限！")
                    return;
                }
                datasetService.updateDataset({
                    json: angular.toJson(ds)
                }).then(function (serviceStatus) {
                    if (serviceStatus.status == '1') {
                        $scope.optFlag = 'edit';
                        $scope.verify = {
                            dsName: true
                        };
                        toastr.success("保存数据集成功!");
                        $scope.$dismiss();
                        PubSub.publish('DATASETCHANGE','')
                    } else {
                        $scope.alerts = [{
                            msg: serviceStatus.msg,
                            type: 'danger'
                        }];
                    }
                });
            },err=>{
                toastr.error(err.head.errorMsg || '获取权限失败！');
            })
        }

    };

    //编辑弹窗最大化
    $scope.editFullScreenFlag = false;
    $scope.toEditFullScreen = function () {
        if (!$scope.editFullScreenFlag) {
            $('.widgetmodal .modal-dialog').removeClass('modal-lg').addClass('modal-fullScreen');
            $('.widgetmodal .modal-header button span i').removeClass().addClass('fa fa-minus');
            $scope.editFullScreenFlag = true;
        } else {
            $('.widgetmodal .modal-dialog').removeClass('modal-fullScreen').addClass('modal-lg');
            $('.widgetmodal .modal-header button span i').removeClass().addClass('fa fa-square-o');
            $scope.editFullScreenFlag = false;
        }
    }
}

import BasicService from './BasicService';
import { USERRESAPI,   BOARDAPI } from './ApiServer';
/**
 */
class UserResService extends BasicService {
  //getRoleList
  getRoleList () {
    return this.get( USERRESAPI.GETROLELIST)
  }

  //getUserRoleList
  getUserRoleList () {
    return this.get( USERRESAPI.GETUSERROLELIST)
  }

  //getMenuList
  getMenuList () {
    return this.get( USERRESAPI.GETMENULIST )
  }

  //getDatasourceList
  getDatasourceList (params) {
    return this.post( USERRESAPI.GETDATASOURCELIST, params)
  }

  //getDatasetList
  getDatasetList (params) {
    return this.post( USERRESAPI.GETDATASETLIST, params)
  }

  //getWidgetList
  getWidgetList () {
    return this.get(USERRESAPI.GETWIDGETLIST)
  }

  //getJobList
  getJobList () {
    return this.get(USERRESAPI.GETJOBLIST)
  }

  //getRoleResList
  getRoleResList () {
    return this.get(USERRESAPI.GETROLERESLIST)
  }

  //saveNewUser
  saveNewUser (params) {
    return this.post(USERRESAPI.SAVENEWUSER, params)
  }

  //updateUserRole
  updateUserRole (params) {
    return this.post(USERRESAPI.UPDATEUSERROLE, params)
  }

  //editUser
  editUser (params) {
    return this.post(USERRESAPI.EDITUSER, params)
  }

  //checkExistRoleName
  checkExistRoleName (params) {
    return this.post(USERRESAPI.CHECKEXISTROLENAME,params)
  }

  //saveRole
  saveRole (params) {
    return this.post(USERRESAPI.SAVEROLE, params)
  }

  //updateRole
  updateRole (params) {
    return this.post(USERRESAPI.UPDATEROLE,params)
  }

  //updateRoleUser
  updateRoleUser (params) {
    return this.post(USERRESAPI.UPDATEROLEUSER, params)
  }

  //deleteUserRole
  deleteUserRole (params) {
    return this.post(USERRESAPI.DELETEUSERROLE, params)
  }

  //updateRoleRes
  updateRoleRes (params) {
    return this.post(USERRESAPI.UPDATEROLERES, params)
  }

  //deleteRole
  deleteRole (params) {
    return this.post(USERRESAPI.DELETEROLE, params)
  }

  //deleteUser
  deleteUser (params) {
    return this.get(USERRESAPI.DELETEUSER + `?userId=${params}`)
  }
}

export const userResService = new UserResService();
 

import BasicService from './BasicService';
import {GLOBALSETTINGAPI} from './ApiServer';
/**
 */
class GlobalSettingService extends BasicService {
  
  getInfo(params) {
    return this.post(GLOBALSETTINGAPI.GETINFO,params)
  }

  save(params) {
    return this.post(GLOBALSETTINGAPI.SAVE,params)
  }
}

export const globalSettingService = new GlobalSettingService();
 

import BasicService from './BasicService';
import {MESSAGEAPI} from './ApiServer';
/**
 */
class MessageService extends BasicService {
  constructor() {
    super();
  }

  getMessageList(params) {
    return this.post(MESSAGEAPI.GETMESSAGElLIST, params);
  }
  getMessageDetail(params) {
    return this.post(MESSAGEAPI.GETMESSAGEDETAIL, params);
  }
  updateStatus(params) {
    return this.post(MESSAGEAPI.UPDATESTATUS, params);
  }

}

export const messageService = new MessageService();

import BasicService from './BasicService';
import { TIMERPUSHAPI } from './ApiServer';

class TimerPushService extends BasicService {
  constructor() {
    super();
  }

  saveBoreTimer(params){
    return this.post(TIMERPUSHAPI.SAVEBORETIMER,params)
  }
  getTimerPageList(params){
    return this.post(TIMERPUSHAPI.GETTIMERPAGELIST,params);
  }
  updateTimerStatus(params){
    return this.post(TIMERPUSHAPI.UPDATETIMERSTATUS,params);
  }
  delBoreTimer(params){
    return this.post(TIMERPUSHAPI.DELBORETIMER,params);
  }
  deleteLog(params){
    return this.post(TIMERPUSHAPI.DELETELOG,params);
  }
  getBtLogList(params){
    return this.post(TIMERPUSHAPI.GETBTLOGLIST,params);
  }
  getBtLogDetail(params){
    return this.post(TIMERPUSHAPI.GETBTLOGDETAIL,params);
  }
  calcRunTime(params){
    return this.post(TIMERPUSHAPI.CALCRUNTIME,params);
  }
}
export const timerPushService = new TimerPushService();

import { orderCenterService } from '../../../server'
import '../modelCenter/modelCenter.scss'
import modelBuyCtrl from '../modelCenter/modelBuyCtrl'

export default function ( $scope, $uibModal, ModalUtils, $state) {
    $scope.isLoading = true;
    // 分页控件配置
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 12,
        onChange: function () {
            $scope.getOrderList();//重新加载
        }
    };

    //获取订单列表
    $scope.getOrderList = function () {
        $scope.isLoading = true;
        var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "orderNo": $scope.orderNo,
            "dataMarketName":$scope.dataMarketName
        }
        orderCenterService.getMarketOrderList(params).then(function (response) {
            if (response.head && response.head.status != '1') {
                ModalUtils.alert(response.head.errorMsg, "modal-warning", "lg");
                if(response.head.errorCode=="0002")$state.go("model.account");
            } else {
                $scope.marketOrderList = response.listdata;
                $scope.paginationConf.totalItems = response.totolCount;
                $scope.isLoading = false;
            }
            $scope.$apply();
        })
    }
    // getOrderList();

    //重新生成数据模型
    $scope.resetDataMarket = function (marketOrder, resetMarket) {
        $scope.marketOrder = marketOrder;
        $scope.marketOrder.resetMarket = resetMarket;
        $scope.marketOrder.dataMarket.resetMarket = resetMarket;
        $uibModal.open({
            template: require('./modelBuy.html'),
            backdrop: false,
            windowClass: 'buy-model-class',
            size: 'lg',
            controller: ['$rootScope', 'dmData', 'isOrder', '$scope', '$uibModalInstance', '$filter','ModalUtils', '$state',modelBuyCtrl],
            resolve: {
                dmData: $scope.marketOrder,
                isOrder: true
            }
        })
    }

    $scope.gotoDetail = function (orderDetail) {
        $uibModal.open({
            template: require('./orderDetail.html'),
            backdrop: false,
            windowClass: 'order-detail-model-class',
            size: 'lg',
            controller: ['$scope', '$uibModalInstance',function ($scope, $uibModalInstance) {
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                $scope.orderDetail = orderDetail;
            }]
            
        })
        
    }
};
import KpiRenderEngine from "../../rengine/KpiRenderEngine";
import './_kpi.scss'
import numbro from 'numbro'
import * as util from '../../util/util'


class KpiRender {
    //默认高度
    height = 190;

    render = function (containerDom, option, scope, persist) {
        var render = new KpiRenderEngine(containerDom, option);
        var html = render.html(persist);
        if (scope) {
            containerDom.append($compile(html)(scope));
        } else {
            containerDom.html(html);
        }
        return render.realTimeTicket();
    };

    parseOption = function (data) {
        var option = {};
        var config = data.chartConfig;
        var casted_keys = data.keys;
        var casted_values = data.series;
        var aggregate_data = data.data;
        var newValuesConfig = data.seriesConfig;
        var colFormatter = [];
        var setColFormatter = false;
        //设置option.kpiValue为有三个键的对象
        option.kpiValue = {
            // value:"",
            // unit:"",
            // unitFontSize
        };
        //获取指标数组
        var col = config.values[0].cols[0];
        var value = aggregate_data.length > 0 ? aggregate_data[0][0] : 'N/A';
        if(col && (col.unit || col.symbol ||col.dividend || col.mantissa || col.unitFontSize)){
            option.kpiValue = util.convertEchartUnitToObj(col, value);
        }else{
            if (config.values[0].format) {
                option.kpiValue.value = numbro(value).format(config.values[0].format);
                var index = config.values[0].format.lastIndexOf("|");
                if (index != -1) {
                    var unit = config.values[0].format.substring(index + 1, config.values[0].format.length);
                    option.kpiValue.unit = unit;
                }

            }
        }

        option.kpiValue = {
            value: option.kpiValue.value || value,
            unit: option.kpiValue.unit || "",
            unitFontSize: option.kpiValue.unitFontSize || "1em"
        };
        let twoChar = /[a-z0-9]/,       // 占半个字符位置
            threeChar = /[A-Z]/,        // 四分之三
            oneChar = /[\.\,]/,         // 四分之一
            oneLength = 9,              // 四分之一所占用px
            valueLength = 0;
        for(let i = 0; i < option.kpiValue.value.length; i++) {
            let char = option.kpiValue.value[i];
            if( twoChar.test(char) ) {
                valueLength += 2;
            } else if ( threeChar.test(char) ) {
                valueLength += 3;
            } else if ( oneChar.test(char) ) {
                valueLength += 1;
            } else {
                valueLength += 4;
            }
        }
        for(let j = 0; j < option.kpiValue.unit.length; j++) {
            let char = option.kpiValue.unit[j];
            if( twoChar.test(char) ) {
                valueLength += 2;
            } else if ( threeChar.test(char) ) {
                valueLength += 3;
            } else if ( oneChar.test(char) ) {
                valueLength += 1;
            } else {
                valueLength += 4;
            }
        }
        valueLength = valueLength * oneLength + 20;    // 预估数值占用的宽度
        let valueScale = 1;
        if(valueLength > data.containerWidth) {
          valueScale = data.containerWidth / valueLength;
        }
        option.valueScale = valueScale + 'em';
        option.kpiName = config.values[0].name;
        option.hasKpiName = option.kpiName || option.kpiName === 0 ? 'block' : 'none';
        option.bodyClass = option.kpiName || option.kpiName === 0 ? 'new_kpi_body' : 'new_kpi_body_noheader';
        option.style = config.values[0].style;
        option.edit = 'edit'//translate("COMMON.EDIT");
        option.refresh = 'refresh'//translate("COMMON.REFRESH");
        return option;
    };
}

export { KpiRender }



import {prewarningService} from '../../../../server'
import windowTpl from '../../../../common/theme/components/modal/window.html'
import '../_prewarning.scss'
export default  function pwLogListCtrl( $scope, $state, ModalUtils,toastr) {
    $scope.pwLogList =[];
    $scope.isLoading = true;
    $scope.search = {};
    $scope.type = {};
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 18,
        onChange: function () {
            $scope.getPwLogList(); //重新加载
        }
    };

    $scope.gotoDetail = function(id){
        $state.go('pwLogDetail', {id: id});
    }

     //获取预警列表
     $scope.getPwLogList = function(){
        var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "search":$scope.search.value,
            "type":$scope.type.value
        }
        prewarningService.getLogPageList(params).then(function (response) {
            $scope.isLoading = false;
            if(response.status=='1'){
                $scope.pwLogList = response.data.listdata || [];
                if($scope.pwLogList.length >0){
                    angular.forEach($scope.pwLogList,function(pwData){
                        pwData.frequency =angular.fromJson(pwData.frequency);
                        pwData.rule =angular.fromJson(pwData.rule);
                        pwData.target =angular.fromJson(pwData.target);

                    })
                }
                $scope.paginationConf.totalItems = response.data.totolCount;
                $scope.$apply();
                //toastr.success("获取日志列表成功!");
            }else{
                toastr.error("获取日志列表失败!");
            }
            
        });
    }

    //删除预警
    $scope.delPwLog = function(data,index){
        ModalUtils.confirm("确认删除日志吗?", "modal-info", "lg", function () {
            prewarningService.delPwLog({id:data.id}).then(function (response) {
                $scope.getPwLogList();
                ModalUtils.success("删除日志成功");
            })
        });
    }

    //编辑预警
    // $scope.getLogData = function (pwLog,index) {       
    //     $uibModal.open({
    //         templateUrl: 'com/ourbore/view/prewarning/prewarningEdit.html',
    //         windowTemplateUrl: 'com/ourbore/view/util/modal/window.html',
    //         size: 'lg',
    //         windowClass: 'pw-modal',
    //         resolve: {
    //             pwLog:function(){
    //                 return pwLog;
    //             },        
    //         },
    //         controller: function($scope, $uibModalInstance,pwLog) {
    //             $scope.pwLog = angular.copy(pwLog)
    //         }
    //     });
    // };

};

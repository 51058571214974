/**
 * mySetToolSelect
 * 
 * boreDataTitle: 输入框前面的说明文---限制5个字
 * boreDataTip: 移入显示说明文---tip
 * boreDataSelects: 列表的字符串--不同项用";"隔开----name
 * boreDataSelectsValue: 列表值的字符串--不同项用";"隔开----value
 * boreDataSelected: 选中的值
 * 
 * boreDataSelectsVariable: 下拉列表可变，变化关联的表达式，不能是不改变指针的对象
 * 
 * boreDataUndefinedBlank: 空值转为undefined----默认不转作为空字符串------第一个可能需要为空或undefined
 */

export default function mySetToolSelect(){

    return {
        restrict: 'EA',
        template: ` <div class="bore-set-tool">
                        <div class="bore-set-tool-title">{{boreDataTitle}}</div>
                        <div class="bore-set-tool-content">
                            <div class="bore-set-tool-select">
                                <div class="selected-box" ng-class="{'show':show}" ng-click="selecting(); $event.preventDefault()">
                                    <span>{{showSelected}}</span>
                                </div>
                                <ul class="select-list" ng-show="show">
                                    <li ng-repeat="item in boreDataSelects track by $index" ng-click="checked($index); $event.preventDefault()" ng-class="{'selected':boreDataSelectsValue[$index]==boreDataSelected}">
                                        {{item}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="bore-set-tool-tip" ng-class="{'show':boreDataTip?true:false}">
                            <span>?</span>
                            <span class="tip-content">{{boreDataTip}}</span>
                        </div>
                    </div>`,
        replace: true,
        scope: {
            boreDataSelected: '=',
            boreDataSelectsVariable: '='
        },
        link: function(scope, element, attrs) {

            //获取显示和存储的列表值
            scope.boreDataSelects = attrs.boreDataSelects.split(";");
            scope.boreDataSelectsValue = attrs.boreDataSelectsValue.split(";");

            if(attrs.boreDataUndefinedBlank === 'true' && scope.boreDataSelectsValue[0] === "") {
                scope.boreDataSelectsValue[0] = undefined;
            }

            scope.boreDataTitle = attrs.boreDataTitle ? attrs.boreDataTitle : "";
            scope.boreDataTip = attrs.boreDataTip ? attrs.boreDataTip : "";
            
            //初始化选中值----有值即，无值获取列表第一个
            scope.boreDataSelected = scope.boreDataSelected == undefined ? scope.boreDataSelectsValue[0] : scope.boreDataSelected;
            scope.showSelected = scope.boreDataSelects[scope.boreDataSelectsValue.indexOf(scope.boreDataSelected)];

            scope.show = false;
            //开始/取消选择的方法--点击下拉框
            scope.selecting = function(){
                scope.show = !scope.show;
            };

            //选中的方法
            scope.checked = function(index){
                scope.boreDataSelected = scope.boreDataSelectsValue[index];
                scope.show = false;
            }

            //展示值使用监视的方法
            scope.$watch("boreDataSelected", function(){
                var index = scope.boreDataSelectsValue.indexOf(scope.boreDataSelected);
                scope.showSelected = scope.boreDataSelects[index];
            });

            //传了一个{{表达式}}，需要更新---当表达式变化，说明下拉列表已经更新---表达式不能是不改变指针的对象，这种不会触发$watch
            if(scope.boreDataSelectsVariable !== undefined) {
                scope.$watch("boreDataSelectsVariable", function(){
                    scope.boreDataSelects = attrs.boreDataSelects.split(";");
                    scope.boreDataSelectsValue = attrs.boreDataSelectsValue.split(";");

                    //选中值不在新的列表中，则取第一个值作为值
                    if(scope.boreDataSelectsValue.indexOf(scope.boreDataSelected) == -1) {
                        scope.boreDataSelected = scope.boreDataSelectsValue[0];
                        scope.showSelected = scope.boreDataSelects[0];
                    }
                }, true);
            }

            //定时器的方法
            // scope.variable = attrs.boreDataSelectsVariable == 'true' ? true : false;
            // if(scope.variable) {
            //     setInterval(function(){
            //         scope.boreDataSelects = attrs.boreDataSelects.split(";");
            //         scope.boreDataSelectsValue = attrs.boreDataSelectsValue.split(";");
            //     }, 2000);
            // }
        }
    };
}
import { userService,userRoleAdminService } from "../../server";
import PubSuber from "../../common/utils/PubSub"
import {util} from "../../common/utils/util"

export default function themeRun($timeout, $rootScope, hasPermsService, layoutPaths, preloader, $q, baSidebarService, themeLayoutSettings,toastr,$state,ModalUtils) {
  //初始化权限控制
  userRoleAdminService.getAuthMenuList().then((data)=>{
    var permissions = _.map(_.filter(data,function(d){
        return !util.isEmpty(d.permission);
    }),function(p){
       return p.permission;
    }) || []
      hasPermsService.setPermissions(permissions)
  })
  // hasPermsService.setPermissions(['1','bore:dataSupport:table:query','bore:app:share','bore:variable:add','bore:variable:edit','bore:res:add','bore:res:edit','bore:res:query','userSet:userRes:query','userSet:userRes:add','bore:userRole::add','bore:role:query','bore:user:edit','bore:dataSupport:data:query','bore:dtp:query','bore:dtp:edit','bore:timer:edit','bore:timer:del','bore:timer:add','bore:prewarning:edit','bore:dataset:del','bore:dataset:add','bore:dataset:edit1','dataApp:app:edit','bore:board:edit','bore:widget:edit'])
  userService.getUserInfo().then((data) => {
    $rootScope.user = data;
    if(data && data.id)localStorage.setItem("userInfo", JSON.stringify(data));
    // if(data.isModifyInitialPassword!="1"){
    //   ModalUtils.confirm("请修改您的初始密码!", "modal-warning", "sm");
    //   // toastr.warning("请修改您的初始密码!")
    //   // $state.go('userProfile');
    //   // window.open('/forcedPasswordChange.html', '_self');
    // }
  })
  userService.isAdmin().then((data) => {
    $rootScope.isAdmin = data
  })
  //权限控制2
  var whatToWait = [
    preloader.loadAmCharts(),
    $timeout(3000)
  ];
  var theme = themeLayoutSettings;
  if (theme.blur) {
    if (theme.mobile) {
      whatToWait.unshift(preloader.loadImg(layoutPaths.images.root + 'blur-bg-mobile.jpg'));
    } else {
      whatToWait.unshift(preloader.loadImg(layoutPaths.images.root + 'blur-bg.jpg'));
      whatToWait.unshift(preloader.loadImg(layoutPaths.images.root + 'blur-bg-blurred.jpg'));
    }
  }
  $q.all(whatToWait).then(function () {
    $rootScope.$pageFinishedLoading = true;
  });

  $timeout(function () {
    if (!$rootScope.$pageFinishedLoading) {
      $rootScope.$pageFinishedLoading = true;
    }
  }, 100);
  $rootScope.$baSidebarService = baSidebarService;
}

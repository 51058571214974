/**
 * Created by  jaray  2018-11-28
 */
'use strict';
import DatatablesRenderEngine from '../../rengine/DatatablesRenderEngine'
import  * as util  from '../../util/util'
class DatatablesRender{
    //默认高度
    height = 300;

     render = function (containerDom, option, scope, persist, drill, relations, chartConfig, chartName) {
        if (option == null) {
            containerDom.html("<div class=\"alert alert-danger\" role=\"alert\">No Data!</div>");
            return;
        }
        var height;
        scope ? height = scope.myheight - 20 : null;
        return new  DatatablesRenderEngine(containerDom, option, drill,chartName).do(height, persist);
    };

    parseOption = function (data) {
        var tableOption = util.chartDataProcess(data.chartConfig, data.keys, data.series, data.data, data.seriesConfig);
        return tableOption;
    };
} 

export {DatatablesRender}
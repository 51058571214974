import { userRoleAdminService, userAdminService,behaviorLogService, userService } from '../../../server'
import windowTpl from '../../../common/theme/components/modal/window.html'
import userSelectedNoPageTpl from '../user/userSelectedNoPage.html'

import './userRoleAdmin.scss'

export default function ($scope,toastr, $uibModal, ModalUtils, $filter) {

    var translate = $filter('translate');
    $scope.optFlag;
    $scope.curUser;
    $scope.filterByRole = false;
    $scope.userKeyword = '';

    $scope.tab = 'menu';
    $scope.search = {};
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 9999,
        onChange: function () {
            getUserList();//重新加载
        }
    };
    // userRoleAdminService.isAdmin().then(response => {
    //     $scope.isAdmin = response;
    // })

    var getUserList = function () {

        var params = {
            "pageNo": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "loginName": $scope.searchLoginName,
            "name": $scope.searchName,
        }
        userAdminService.postUserList(params).then(response => {
            $scope.paginationConf.totalItems = response.totolCount;
            $scope.userList = response.listdata;
        })
    };


    getUserList();

    var getRoleList = function () {
        userRoleAdminService.getRoleList().then(response => {
            $scope.roleList = response;
        },err=>{
            toastr.error(err.head.errorMsg);
        })
    };
    getRoleList();
    var getRoleUserList = function () {
        userRoleAdminService.getRoleUserList().then(response => {
            $scope.roleUserList = response;
        },err=>{
            toastr.error(err.head.errorMsg);
        })
    };
    getRoleUserList();

    $scope.tree = {
        menu: {},
    };
    $scope.tree.menu.resList = []//[{
    //     id: 'menu_20001',
    //     text: translate('ADMIN.MENU'),
    //     parent: '#',
    //     icon: 'fa fa-folder-open',
    //     state: {
    //         disabled: true
    //     }
    // }];

    var getMenuList = function () {
        return userRoleAdminService.getAuthMenuList().then(function (response) {
            $scope.menuList = response;
             response=_.sortBy(response,(item)=>{
                 return item.id.length
             });
            _.each(response, function (e) {
                if(e.parentId == 1 ){
                    $scope.tree.menu.resList.push({
                        id: 'menu_' + e.id,
                        text: translate(e.name),
                        parent:'#',
                        resId: e.id,
                        type: 'menu',
                        icon:    'fa fa-folder-open',
                        name: e.name ,
                        state: {
                                    disabled: true
                                }
                    });
                }else{
                    $scope.tree.menu.resList.push({
                        id: 'menu_' + e.id,
                        text: translate(e.name),
                        parent: ('menu_' + e.parentId),
                        resId: e.id,
                        type: 'menu',
                        icon: e.icon==''||e.icon==null?(e.permission==''?'fa  fa-folder':' ion-document'):e.icon,
                        name:  e.name 
                    });
                }
              
            });
            // const menus=[{
            //     "id": "menu_20001",
            //     "text": "菜单1",
            //     "parent": "#",
            //     "icon": "fa fa-folder-open",
            //     "state": {
            //         "disabled": true
            //     },
            // }, {
            //     "id": "menu_200010005",
            //     "text": "预警",
            //     "parent": "menu_20001",
            //     "resId": "200010005",
            //     "type": "menu",
            //     "icon": "fa fa-cog",
            //     "name": "预警"
            // }, {
            //     "id": "menu_20001000500020004",
            //     "text": "测试权限",
            //     "parent": "menu_200010005",
            //     "resId": "20001000500020004",
            //     "type": "menu",
            //     "icon": "fa fa-cog",
            //     "name": "测试权限"
            // }, {
            //     "id": "menu_20001000500030004",
            //     "text": "测试权限",
            //     "parent": "menu_200010005",
            //     "resId": "20001000500030004",
            //     "type": "menu",
            //     "icon": "fa fa-cog",
            //     "name": "测试权限"
            // }]
            // $scope.tree.menu.resList.push({
            //     "id": "menu_200010006",
            //     "text": "预警2",
            //     "parent": "menu_20001",
            //     "resId": "menu_200010005",
            //     "type": "menu",
            //     "icon": "fa fa-cog",
            //     "name": "预2警"
            // })
            // $scope.tree.menu.resList.push({
            //     "id": "menu_200010005",
            //     "text": "预警",
            //     "parent": "menu_20001",
            //     "resId": "200010005",
            //     "type": "menu",
            //     "icon": "fa fa-cog",
            //     "name": "预警"
            // })
            // $scope.tree.menu.resList= [
            //     { id : 'ajson1', parent : '#', text : 'Simple root node' },
            //     { id : 'ajson2', parent : '#', text : 'Root node 2' },
            //     { id : 'ajson3', parent : 'ajson2', text : 'Child 1' },
            //     { id : 'ajson4', parent : 'ajson2', text : 'Child 2' }
            // ]
            // console.log( $scope.tree.menu.resList)
        });
    };
    getMenuList()
    var getCUDRlabel = function (e, d) {
        var a = ['查'];
        if (e) {
            a.push('编');
        }
        if (d) {
            a.push('删');
        }
        return ' (' + a.join(',') + ')';
    };



    var getContextMenu = function ($node) {
        function toggleACL(attr) {
            return function (obj) {
                $node.original[attr] = $node.original[attr] == undefined ? false : !$node.original[attr];
                _.each($node.children_d, function (e) {
                    var tree = $(obj.reference).jstree(true);
                    var node = tree.get_node(e);
                    if (node.children.length == 0) {
                        node.original[attr] = $node.original[attr];
                        tree.rename_node(node, node.original.name + getCUDRlabel(node.original[attr], node.original.delete));
                    }
                });
            }
        }

        if (_.isUndefined($node.original.resId)) {
            if ($node.parent != '#') {
                return {
                    edit: {
                        label: function () {
                            return translate('ADMIN.TOGGLE_UPDATE');
                        },
                        action: toggleACL('update')
                    },
                    delete: {
                        label: function () {
                            return translate('ADMIN.TOGGLE_DELETE');
                        },
                        action: toggleACL('delete')
                    }
                };
            } else {
                return;
            }
        } else {
            return {
                edit: {
                    label: function () {
                        return $node.original.edit ? '√ 编辑' : '× Update';
                    },
                    action: function (obj) {
                        $node.original.edit = !$node.original.edit;
                        $(obj.reference).jstree(true).rename_node($node, $node.original.name + getCUDRlabel($node.original.edit, $node.original.delete));
                    }
                },
                delete: {
                    label: function () {
                        return $node.original.delete ? '√ 删除' : '× Delete';
                    },
                    action: function (obj) {
                        $node.original.delete = !$node.original.delete;
                        $(obj.reference).jstree(true).rename_node($node, $node.original.name + getCUDRlabel($node.original.edit, $node.original.delete));
                    }
                }
            };
        }
    };
    var loadResData = function () {
        var config = {
            core: {
                multiple: true,
                animation: true,
                error: function (error) { console.log(error) },
                check_callback: true,
                worker: true
            },
            checkbox: {
                three_state: true
            },
            contextmenu: {
                items: getContextMenu,
                select_node: false
            },
            version: 1,
            plugins: ['types', 'checkbox', 'unique', 'contextmenu']
        };
        var configMenu = angular.copy(config);
        configMenu.checkbox.three_state = false;
        delete configMenu.contextmenu;
        configMenu.plugins = ['types', 'checkbox', 'unique'];
        $scope.treeConfig = config;
        $scope.treeMenuConfig =configMenu;
    }();


    // var getRoleResList = function () {
    //     // $http.get("api/bcp/admin/getRoleResList")
    //     userRoleAdminService.getRoleResList().then(function (response) {
    //         $scope.roleResList = response;
    //     });
    // };
    // getRoleResList();

    $scope.onRoleFilter = function (item) {
        $scope.roleFilter = _.map(_.filter($scope.roleUserList, function (e) {
            return e.roleId == item.roleId;
        }), function (u) {
            return u.userId;
        });
    };

    // $scope.setStyle = function (userId) {
    //    var str = ["cd7a4606-a590-4eb9-94bf-e698c7cc5425","51fd91b0-9439-4da2-899e-731c6cc07cec","86578e8e-133b-46c0-a939-daf907245ac7"];
    //    console.log("++++++++++++")
    //    if(str.indexOf(userId)){
    //     console.log("usserId",userId)
    //        return "red-color";
    //    }
    // }


    $scope.searchUserByRole = function (user) {
        if (!$scope.filterByRole) {
            return true;
        }
        return !_.isUndefined(_.find($scope.roleFilter, function (e) {
            return e == user.userId;
        }))
    };

    $scope.searchUserByName = function (user) {
        getUserList();

    };

    $scope.changeRoleSelect = function () {
        changeRoleSelect();
    }

    //角色管理点击
    $scope.showUserRole = false;
    $scope.roleSelect = function ($event, role) {
        $($event.target).parent().siblings().removeClass('liBackground');
        $($event.target).parent().addClass('liBackground');
        $scope.showUserRole = true;
        $scope.selectRole = role;
        getRoleUserInfo(role)
        userRoleAdminService.getRole(role.id).then((roledata)=>{
            clickRoleToChangeTree($scope.tree.menu,roledata.menuIdList);
            $scope.$apply();
        });
        $scope.search.value = undefined;  //切换时将搜索框清掉
        // $scope.$apply();
    }
    var getRoleUserInfo=(role)=>{
        $scope.selectUser=[];
        userRoleAdminService.getRoleUserList(role.id).then((data)=>{
            // console.log(data)
            $scope.$apply(function(){
                $scope.selectUser = _.filter($scope.userList, function (e) {
                    return _.find(data, function (ur) {
                        return ur.userId == e.id;
                    })
                });
            });
        })
    }


    var changeRoleSelect = function () {
        if ($scope.selectUser && $scope.selectUser.length == 1) {
            var userRole = _.filter($scope.roleUserList, function (e) {
                return e.userId == $scope.selectUser[0].id;
            });
            $scope.selectRole = _.filter($scope.roleList, function (e) {
                return _.find(userRole, function (ur) {
                    return ur.roleId == e.roleId;
                })
            });
            $scope.userRoles = angular.copy($scope.selectRole);
            $scope.changeResSelect();
        }
    };

    $scope.newUser = function () {
        $scope.selectUser = [];
        // if ($scope.selectUser && $scope.selectUser.length == 1) {
        //     var userRole = _.filter($scope.roleUserList, function (e) {
        //         return e.userId == $scope.selectUser[0].id;
        //     });
        //     $scope.selectRole = _.filter($scope.roleList, function (e) {
        //         return _.find(userRole, function (ur) {
        //             return ur.roleId == e.roleId;
        //         })
        //     });
        //     $scope.userRoles = angular.copy($scope.selectRole);
        // }
        $scope.selectRole = []

        $scope.optFlag = 'newUser';
        $scope.curUser = {};
    };

    $scope.cancel = function () {
        $scope.optFlag = 'none';
        $scope.curUser = {};
    };

    $scope.editUser = function (user) {
        $scope.optFlag = 'editUser';
        $scope.curUser = angular.copy(user);
        $scope.curUser.oldLoginName = user.loginName;
    };

    $scope.editUserInfo = function (userInfo) {
        $scope.selectUser = [userInfo];
        if ($scope.selectUser && $scope.selectUser.length == 1) {
            var userRole = _.filter($scope.roleUserList, function (e) {
                return e.userId == $scope.selectUser[0].id;
            });
            $scope.selectRole = _.filter($scope.roleList, function (e) {
                return _.find(userRole, function (ur) {
                    return ur.roleId == e.roleId;
                })
            });
            $scope.userRoles = angular.copy($scope.selectRole);
        }
        $scope.optFlag = 'editUser';
        $scope.curUser = angular.copy(userInfo);
        $scope.curUser.oldLoginName = userInfo.loginName;
    };

    $scope.showUserInfo = function (userInfo) {
        $scope.optFlag = 'showUser';
        $scope.selectUser = [userInfo];
        if ($scope.selectUser && $scope.selectUser.length == 1) {
            var userRole = _.filter($scope.roleUserList, function (e) {
                return e.userId == $scope.selectUser[0].id;
            });
            $scope.selectRole = _.filter($scope.roleList, function (e) {
                return _.find(userRole, function (ur) {
                    return ur.roleId == e.roleId;
                })
            });
            $scope.userRoles = angular.copy($scope.selectRole);
        }
        $scope.curUser = angular.copy(userInfo);
        $scope.curUser.oldLoginName = userInfo.loginName;
    };

    $scope.newRole = function () {
        $scope.optFlag = 'newRole';
        $scope.curRole = {};
    };

    $scope.editRole = function (role) {
        $scope.optFlag = 'editRole';
        $scope.curRole = angular.copy(role);
    };

    var validate = function (type) {
        if (type == 'user') {
            if (!$scope.curUser.loginName || !$scope.curUser.name || !$scope.curUser.confirmNewPassword || !$scope.curUser.newPassword) {
                // ModalUtils.alert("账号、姓名、密码不能为空", "modal-warning", "sm");
                alert("姓名、登录名、密码不能为空")
                return false;
            }
            if ($scope.curUser.confirmNewPassword != $scope.curUser.newPassword) {
                alert("两次密码输入不一致")
                return false;
            }
            //校验邮箱
            var regEmail = /^\w+@[a-z0-9]+\.[a-z]+$/i;
            if ($scope.curUser.email !== '' && $scope.curUser.email !== undefined) {
                if (!regEmail.test($scope.curUser.email)) {
                    alert("输入的邮箱有误，请重新输入");
                    return false;
                }
            }
            //校验手机
            var regMobile = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if ($scope.curUser.mobile !== '' && $scope.curUser.mobile !== undefined) {
                // console.log(regMobile.test($scope.curUser.mobile))
                if (!regMobile.test($scope.curUser.mobile)) {
                    alert("输入的手机号码有误，请重新输入");
                    return false;
                }
            }
        } else {
            if (!$scope.curRole.name  ) {
                alert("角色名不能为空")
                return false
            }
        }
        return true;
    }

    $scope.saveUser = function () {
        if (!validate('user')) {
            return;
        }
        if ($scope.optFlag == 'newUser') {
            userRoleAdminService.saveNewUser($scope.curUser).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    $scope.optFlag = 'none';
                    var newUserId = [];
                    var newRoleIds = [];
                    _.each($scope.selectRole, function (value) {
                        newRoleIds.push(value.roleId);
                    })
                    var oldUserIds = _.map(angular.copy($scope.userList), function (a) {
                        return a.id;
                    });

                    userRoleAdminService.getUserList().then(function (response) {
                        $scope.userList = response.listdata;
                        var curUserIds = _.map(response.listdata, function (a) {
                            return a.id;
                        });
                        _.each(curUserIds, function (value) {
                            if (oldUserIds.indexOf(value) == -1) {
                                newUserId.push(value)
                            }
                        })
                        userRoleAdminService.updateUserRole({
                            userIdArr: angular.toJson(newUserId),
                            roleIdArr: angular.toJson(newRoleIds)
                        }).then(function (res) {
                            if (res == '1') {
                                $scope.selectUser = null;
                                $scope.selectRole = null;
                                getRoleUserList();
                                getUserList();
                                toastr.success(translate("COMMON.SUCCESS"));
                            } else {
                                toastr.error(res.msg);
                            }
                        });
                    })
                } else {
                    toastr.error("保存失败!"+serviceStatus.body.msg);
                }
            },err=>{
                toastr.error("保存失败!"+err.head.errorMsg);
            })
        } else {
            userRoleAdminService.editUser($scope.curUser).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    $scope.optFlag = 'none';
                    getUserList();
                    $scope.verify = {
                        dsName: true
                    };
                    toastr.success("保存成功"+translate("COMMON.SUCCESS"));
                } else {
                    toastr.error("保存失败!"+serviceStatus.msg);
                }
            },err=>{
                toastr.error("保存失败!"+err.head.errorMsg);
            })
        }
        $('#newUserModal').modal('hide');
    };

    $scope.saveRole = function () {
        if (!validate('role')) {
            return;
        }
        userRoleAdminService.saveOrUpdateRole(
            $scope.curRole
        ).then(function (respose) {
                // console.log(respose)
                $scope.optFlag = 'none';
                getRoleList();
                $scope.verify = {
                    dsName: true
                };
                toastr.success(translate("COMMON.SUCCESS"))
        },err=>{
            console.log(err.head.errorMsg)
            toastr.error(err.head.errorMsg)
        });
        

        $('#newRoleModal').modal('hide');
    };

    $scope.grantRole = function () {

        var userIds = _.map($scope.selectUser, function (e) {
            return e.id;
        });

        // var roleIds = _.map($scope.selectRole, function(e) {
        //     return e.roleId;
        // });
        var userRoleIds = new Array;
        var selectRoleArr = $scope.selectRole;
        //当用户角色组里有数据时 对比已经选择的角色 将不重复的添加进用户角色组
        if ($scope.userRoles && $scope.userRoles.length > 0) {
            userRoleIds = _.map($scope.userRoles, function (a) {
                return a.roleId;
            });
            _.each($scope.selectRole, function (e) {
                if (userRoleIds.indexOf(e.roleId) == -1) {
                    userRoleIds.push(e.roleId)
                }
            })
        } else {
            //没有数据时 则已选择的数据就是用户角色组
            userRoleIds = _.map($scope.selectRole, function (e) {
                return e.roleId;
            });
        }
        userRoleAdminService.updateUserRole({
            userIdArr: angular.toJson(userIds),
            roleIdArr: angular.toJson(userRoleIds)
        }).then(function (serviceStatus) {
            if (serviceStatus == '1') {
                $scope.selectUser = null;
                $scope.selectRole = null;
                getRoleUserList();
                getUserList();
                toastr.success(translate("COMMON.SUCCESS"));
            } else {
                toastr.error(serviceStatus.msg);
            }
        });
        $('#newUserModal').modal('hide');
    };

//添加用户的弹窗start
    //点击添加用户
    // $scope.showSelectUser = function () {
    //     $scope.selectToRoleUser = $scope.selectUser;
    // }

    //不改变共用模板userSelectedListTpl，来源timerEditVtrl
    var getBindReceiptIds = function (callback) {
        userService.getBindUserIdByCompany({type:'1'}).then(function (response) {
            if(response.status=='1'){
                $scope.bindUsers = response.data;
            }
            // $scope.isBindWechat =function(userId){
            //     if($scope.bindUsers && $scope.bindUsers.indexOf(userId)!=-1)return true;
            //     return false;
            // }
            // if (callback) {
            //     callback();
            // }
        },err=>{
            toastr.error(err.head.errorMsg)
        });
    }
    getBindReceiptIds();

    //前往新增用户页面
    $scope.gotoUserPage = function () {
        if (!$scope.userList) {
            ModalUtils.alert("获取用户列表失败!", "modal-warning", "lg");
            return;
        }
        $uibModal.open({ 
            template: userSelectedNoPageTpl,
            windowTemplate:windowTpl,
            size: 'lg',
            windowClass: 'pw-user-modal',
            resolve: {
                selectIds: function () {
                    return _.map($scope.selectUser, function(e){
                        return e.id;
                    })
                },
                bindUsers:function(){
                    return $scope.bindUsers;
                },
                ok: function () {
                    return function (param) {
                        //预防为空的错误
                        if(param && param.length > 0) {
                            $scope.selectToRoleUser = [];
                            $scope.userList = !!$scope.userList?$scope.userList:[]; 
                            $scope.selectUser = !!$scope.selectUser?$scope.selectUser:[];
                            _.each($scope.userList, function(e){
                                //去除原有的，只包括新增的user
                                if(param.indexOf(e.id) != -1){
                                    if($scope.selectUser.indexOf(e) == -1){
                                        $scope.selectToRoleUser.push(e);
                                    }
                                }
                            });
    
                            $scope.addUserToRole();
                        }
                    }
                }
            },
            controller: ['$scope', '$uibModalInstance', 'selectIds', 'ok', 'bindUsers', function ($scope, $uibModalInstance, selectIds, ok, bindUsers) {
                $scope.selectIds = angular.copy(selectIds) || [];
                $scope.bindUsers = angular.copy(bindUsers);
                $scope.userTitle = "用户列表(不包含已选择用户)"
                // $scope.paginationConf = {
                //     currentPage: 1,
                //     totalItems: 0,
                //     itemsPerPage: 9999,
                //     onChange: function () {
                //         $scope.getUserList(); //重新加载
                //     }
                // };
                
                $scope.getUserList =function(){
                    var params = {
                        "pageNo": 1,
                        "pageSize": 9999,
                        "name":$scope.search
                    }
                    userAdminService.postUserList(params).then(function (response) {
                        // $scope.userList = response.listdata;
                        $scope.userList = _.filter(response.listdata,function(e){
                            return  $scope.selectIds.indexOf(e.id)<0;
                        })
                        // $scope.paginationConf.totalItems = response.totolCount;
                        $scope.ifCheckedAll();
                        $scope.$apply();
                    },err=>{
                        toastr.error(err.head.errorMsg)
                    });
                }
                $scope.getUserList();
                //获取已经绑定了微信的用户
                $scope.isBindWechat =function(userId){
                    if($scope.bindUsers && $scope.bindUsers.indexOf(userId)!=-1)return true;
                    return false;
                }              

                //更新选择的用户
                $scope.updateSelection = function ($event, id) {
                    //只有被选中的时候才添加到ids集合中 
                    if ($event.target.checked) {
                        $scope.selectIds.push(id); //push向集合中添加元素
                    } else {
                        //如果没被选中则删除集合中的元素
                        var index = $scope.selectIds.indexOf(id); //查找值的位置
                        $scope.selectIds.splice(index, 1); //参数1:移除的位置 参数2:移除的个数
                    }
                }

                //判断是否选择好了
                $scope.ifChecked = function (id) {
                    //如果Id出现在数组中，则需要返回true
                    var index = $scope.selectIds.indexOf(id);
                    if (index == -1) {
                        return false;
                    } else { //如果id没有出现在selectIds数组中需要返回false
                        return true;
                    }
                };

                //判断是否全选了
                $scope.ifCheckedAll = function () {
                    //$scope.userList中的对象的id 是否都在 $scope.selectIds中
                    //如果全中，则返回true
                    //如果有一个还在，就返回false
                    if(!$scope.userList) return false;
                    for (var i = 0; i < $scope.userList.length; i++) {
                        if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                            return false;
                        }
                    }
                    return true;
                };

                //全选与取消全选
                $scope.selectAll = function ($event) {
                    if ($event.target.checked) {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            //当前页面的数据的id放到数组中
                            if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                                $scope.selectIds.push($scope.userList[i].id);
                            }
                        }
                    } else {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            var index = $scope.selectIds.indexOf($scope.userList[i].id);
                            $scope.selectIds.splice(index, 1);
                        }
                    }
                };

                //关闭
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                //确定
                $scope.ok = function () {
                    $uibModalInstance.close();
                    ok($scope.selectIds);
                };
            }]
        });
    }
//添加用户的弹窗end

    //添加用户角色
    $scope.addUserToRole = function () {
        if(_.isUndefined($scope.selectRole))
        {
            toastr.error("请选择角色")
            return;}
        var userIds = [];
        if ($scope.selectUser.length > 0) {
            var uniqueUsers = Array.from(new Set($scope.selectUser.concat($scope.selectToRoleUser)));
            _.each(uniqueUsers, function (e) {
                userIds.push(e.id);
            })
        } else {
            //没有数据时 则已选择的数据就是用户角色组
            userIds = _.map($scope.selectToRoleUser, function (e) {
                return e.id;
            });
        }
        var addedUserNames = _.map($scope.selectToRoleUser, function (e) {
            return e.name;
        });
        userRoleAdminService.saveRoleUser({
            roleId:$scope.selectRole.id,
            userIds:userIds.toString(),
            roleName:$scope.selectRole.name,
            addedUserNames:addedUserNames.toString()
        }).then((data)=>{
            getRoleUserInfo($scope.selectRole);
            toastr.success(data);
        },err=>{
           console.log(err)
        })

        // userRoleAdminService.updateRoleUser({
        //     userIdArr: angular.toJson(userIds),
        //     roleIdArr: angular.toJson(userRoleId)
        // }).then(function (serviceStatus) {
        //     if (serviceStatus == '1') {
        //         getRoleUserList();
        //         $scope.selectUser = Array.from(new Set($scope.selectUser.concat($scope.selectToRoleUser)))
        //         ModalUtils.alert(translate("COMMON.SUCCESS"), "modal-success", "sm");
        //     } else {
        //         ModalUtils.alert(serviceStatus.msg, "modal-warning", "sm");
        //     }
        // });
        $('#newUserToRoleModal').modal('hide');
    }

    $scope.revokeRole = function () {

        if (!$scope.selectUser || $scope.selectUser.length < 0) {
            ModalUtils.alert("请选择要移除用户", "modal-warning", "sm");
        }
        if (!$scope.selectRole || $scope.selectRole.length < 0) {
            ModalUtils.alert("请选择要移除角色", "modal-warning", "sm");
        }
        var userRoleIds = _.map($scope.userRoles, function (e) {
            return e.roleId;
        });
        var roleIds = new Array;
        _.each($scope.selectRole, function (e) {
            if (userRoleIds.indexOf(e.roleId) == -1) {
                ModalUtils.alert("无法移除非用户角色: " + e.roleName, "modal-warning", "sm");
                return;
            } else {
                roleIds.push(e.roleId);
            }
        })
        var userIds = _.map($scope.selectUser, function (e) {
            return e.id;
        });

        userRoleAdminService.deleteUserRole({
            userIdArr: angular.toJson(userIds),
            roleIdArr: angular.toJson(roleIds)
        }).then(function (serviceStatus) {
            if (serviceStatus == '1') {
                $scope.selectUser = null;
                $scope.selectRole = null;
                getRoleUserList();
                getUserList();
                toastr.success(translate("COMMON.SUCCESS"));
            } else {
                toastr.error(serviceStatus.msg);
            }
        });
        $('#newUserModal').modal('hide');
    };

    //移除用户角色
    $scope.removeUserRole = function (index, u) {
        ModalUtils.confirm('真的从该角色中移除用户吗', "modal-info", "md", function () {
            userRoleAdminService.deleteUserRole({
                roleId: $scope.selectRole.id,
                userId: u.id,
                roleName:$scope.selectRole.name,
                userName:u.name
            }).then(function (data) {
                toastr.success(data)
                getRoleUserInfo($scope.selectRole);
            },err=>{
                toastr.error(err.head.errorMsg || "移除用户角色失败")
            });
        });
    };

    $scope.changeResSelect = function () {
        changeResSelectByTree($scope.tree.menu);
        // changeResSelectByTree($scope.tree.board);
        // changeResSelectByTree($scope.tree.dataset);
        // changeResSelectByTree($scope.tree.datasource);
        // changeResSelectByTree($scope.tree.job);
        // changeResSelectByTree($scope.tree.widget);
    };

    var changeResSelectByTree = function (tree) {
        $scope.optFlag = 'selectRes';
        tree.treeInstance.jstree(true).open_all();
        if ($scope.selectRole.length > 0) {
            var roleRes = _.filter($scope.roleResList, function (e) {
                return !_.isUndefined(_.find($scope.selectRole, function (r) {
                    return e.roleId == r.roleId;
                }));
            });

            tree.treeInstance.jstree(true).uncheck_all();



            _.each(tree.resList, function (e) {
                var f = _.find(roleRes, function (rr) {
                    return rr.resId == e.resId && rr.resType == e.type;
                });
                var _n = tree.treeInstance.jstree(true).get_node(e);
                if (!_.isUndefined(f)) {
                    tree.treeInstance.jstree(true).check_node(e);
                    if (e.name) { //菜单节点不需要更新权限标记
                        _n.original.edit = f.edit;
                        _n.original.delete = f.delete;
                    }
                } else {
                    if (e.name) { //菜单节点不需要更新权限标记
                        _n.original.edit = true;
                        _n.original.delete = true;
                    }
                }
                if (e.name) {
                    tree.treeInstance.jstree(true).rename_node(e, e.name + getCUDRlabel(_n.original.edit, _n.original.delete));
                }
            });
        }
    };

    var clickRoleToChangeTree = function (tree,roleRes) {
        if (tree.treeInstance) {
            tree.treeInstance.jstree(true).open_all();
            if ([$scope.selectRole].length > 0) {
                // var roleRes = _.filter($scope.roleResList, function (e) {
                //     return !_.isUndefined(_.find([$scope.selectRole], function (r) {
                //         return e.roleId == r.roleId;
                //     }));
                // });
                tree.treeInstance.jstree(true).uncheck_all();
                _.each(tree.resList, function (e) {
                    var f = _.find(roleRes, function (rr) {
                        return rr == e.resId //&& rr.resType == e.type;
                    });
                    var _n = tree.treeInstance.jstree(true).get_node(e);
                    if (!_.isUndefined(f)) {
                        tree.treeInstance.jstree(true).check_node(e);
                        if (e.name) { //菜单节点不需要更新权限标记
                            _n.original.edit = f.edit;//编辑权限
                            _n.original.delete = f.delete;//删除权限
                        }
                    } else {
                        try {
                            if (e.name && _n.original) { //菜单节点不需要更新权限标记
                                _n.original.edit = true;
                                _n.original.delete = true;
                            }
                        } catch (err) {
                        }
                    }
                    //暂时不需要其他标签
                    // if (e.name) {
                    //     tree.treeInstance.jstree(true).rename_node(e, e.name + getCUDRlabel(_n.original.edit, _n.original.delete));
                    // }
                });
            }
        }
    };

    $scope.grantRes = function () {
        var roleIds = _.map($scope.selectRole, function (e) {
            return e.roleId;
        });
        var roleNames = _.map([$scope.selectRole], function (e) {
            return e.roleName;
        });
        var resIds = [];
        for (var key in $scope.tree) {
            _.each(_.filter($scope.tree[key].treeInstance.jstree(true).get_checked(true), function (e) {
                return !_.isUndefined(e.original.resId);
            }), function (e) {
                resIds.push({
                    resId: e.original.resId,
                    resType: e.original.type,
                    edit: e.original.edit,
                    delete: e.original.delete
                });
            });
        }
        userRoleAdminService.updateRoleRes({
            roleIdArr: angular.toJson(roleIds),
            resIdArr: angular.toJson(resIds),
            roleNames:roleNames.toString()
        }).then(function (serviceStatus) {
            if (serviceStatus == '1') {
                $scope.selectRole = null;
                $scope.selectRes = null;
                getRoleResList();
                getRoleList();
                changeRoleSelect();
                toastr.success(translate("COMMON.SUCCESS"));
            } else {
                toastr.error(serviceStatus.msg);
            }
        });

    };

    $scope.inserBehaviortLog =function(){
        try {
            behaviorLogService.insertLog({module:"user",function:"查看",name:"角色管理"})
        } catch (error) {
            // console.log("插入日志失败:"+error)
        }
    }

    $scope.inserBehaviortLog();
    //更新权限
    $scope.updateRoleRes = function () {
        if ($scope.selectRole === undefined) {
            ModalUtils.alert('请选择角色！', "modal-warning", "sm");
            return
        }
        // var roleIds = _.map([$scope.selectRole], function (e) {
        //     return e.roleId;
        // });
        var resIds = [];
        for (var key in $scope.tree) {
            if ($scope.tree[key].treeInstance) {
                console.log($scope.tree[key])
                _.each(_.filter($scope.tree[key].treeInstance.jstree(true).get_checked(true), function (e) {
                    console.log(e)
                    return !_.isUndefined(e.original.resId);
                }), function (e) {
                    resIds.push( e.original.resId)
                    // resIds.push({
                    //     resId: e.original.resId,
                    //     resType: e.original.type,
                    //     edit: e.original.edit,
                    //     delete: e.original.delete
                    // });
                });
            }
        }
        
        // var menuNames = _.map(_.filter($scope.menuList,function(f){
        //     return _.indexOf(resIds,f.id) > 0
        // }),function(e){
        //     return e.name;
        // })
        userRoleAdminService.saveOrUpdateRole({
          id:$scope.selectRole.id,
          name:$scope.selectRole.name,
          menuIds: resIds.toString(),
        //   menuNames: menuNames.toString()
        }).then((data)=>{
            toastr.success('更新成功');
        },err=>{
            toastr.error(err.head.errorMsg)
        })

        // userRoleAdminService.updateRoleRes({
        //     roleIdArr: angular.toJson(roleIds),
        //     resIdArr: angular.toJson(resIds)
        // }).then(function (serviceStatus) {
        //     if (serviceStatus == '1') {
        //         // $scope.selectRole = null;
        //         $scope.selectRes = null;
        //         getRoleResList();
        //         // getRoleList();
        //         // changeRoleSelect();
        //         ModalUtils.alert(translate("COMMON.SUCCESS"), "modal-success", "sm");
        //     } else {
        //         ModalUtils.alert(serviceStatus.msg, "modal-warning", "sm");
        //     }
        // });

    };

    $scope.deleteRole = function () {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "lg", function () {
            userRoleAdminService.deleteRole({
                roleId: $scope.selectRole[0].roleId
            }).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    $scope.selectRole = null;
                    $scope.selectRes = null;
                    getRoleList();
                    getRoleResList();
                    $scope.optFlag = 'none';
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
            });
        });
    }

    $scope.deleteRoleInfo = function () {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "md", function () {
            userRoleAdminService.deleteRole({
                id: $scope.selectRole.id
            }).then(function (response) {
                toastr.success(response)
                getRoleList()
            },err=>{
                toastr.error('删除失败！')
            });
        });
    }

    $scope.deleteUser = function () {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "lg", function () {
            userRoleAdminService.deleteUser($scope.selectUser[0].id).then(function (serviceStatus) {
                if (serviceStatus == '1') {
                    $scope.selectUser = null;
                    $scope.selectRole = null;
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
                getUserList();
            }).catch(function (serviceStatus) {
                toastr.error(serviceStatus.msg);
            });
        });
    }

    $scope.deleteUserInfo = function (userInfo) {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "md", function () {
            userRoleAdminService.deleteUser(userInfo.id).then(function (serviceStatus) {
                if (serviceStatus == '1') {
                    $scope.selectUser = null;
                    $scope.selectRole = null;
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
                getUserList();
            }).catch(function (serviceStatus) {
                toastr.error(serviceStatus.msg);
            });
        });
    }

    
    //用户列表分页
    $scope.paginationConf1 = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 20,
        onChange: function () {

        }
    };
    var getPaginationConf1Total = function(){
        $scope.paginationConf1.totalItems = $scope.selectUserShow ? $scope.selectUserShow.length : 0;
    };

    // $scope.$watch(function() {     
    //     var newValue = $scope.selectUser + "";
    //     return newValue;
    // }, function(newValue, oldValue){
    //     $scope.selectUserBySearch();
    //     getPaginationConf1Total();
    // });
    $scope.$watch('selectUser', ()=>{
        $scope.selectUserBySearch();
        getPaginationConf1Total();
    }, true);

    $scope.getShowSelectUserMax = function(){
        return $scope.paginationConf1.currentPage * $scope.paginationConf1.itemsPerPage - 1;
    };
    $scope.getShowSelectUserMin = function(){
        return $scope.paginationConf1.currentPage * $scope.paginationConf1.itemsPerPage - $scope.paginationConf1.itemsPerPage;
    };
    //用户列表搜索显示
    $scope.selectUserBySearch = function(){
        $scope.selectUserShow = [];
        if(_.isUndefined($scope.search.value)){
            $scope.selectUserShow = $scope.selectUser;
            getPaginationConf1Total();
        } else {
            _.each($scope.selectUser, function(e){
                if(e.name.search($scope.search.value) != -1){
                    $scope.selectUserShow.push(e);
                };
            });
            getPaginationConf1Total();
        }
    };

};
import icon_pic from './pie.png'
import icon_pic_active from './pie-active.png'
var config = {
    rule: {
        keys: 2,
        groups: -1,
        filters: -1,
        values: 2
    },
    info:{
        name: 'CONFIG.WIDGET.PIE',
        value: 'pie',
        // class: 'cPie',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_1_MORE',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_1_MORE'
    }
}
export default config;
import dataContext  from '../../../../charts/DataContext'
export default function ($scope,ModalUtils, $uibModalInstance,$filter,params,ok) {
    var translate = $filter('translate');
    $scope.param_types = [
        { name: translate('CONFIG.DASHBOARD.PARAM_TYPE_SELECTOR'), value: 'selector' },
        { name: translate('CONFIG.DASHBOARD.PARAM_TYPE_SLIDER'), value: 'slider' },
        { name: translate('CONFIG.DASHBOARD.PARAM_TYPE_PULL'), value: 'pull' },
        { name: translate('CONFIG.DASHBOARD.PARAM_TYPE_POPUP'), value: 'popup' },
        { name: '时间', value: 'datetime' }
    ];
    $scope.status = { i: 0 };
    $scope.param = params.param;
    if (!$scope.param.paramType) {
        $scope.param.paramType = 'selector';
    }
    $scope.boardDataset = [];
    $scope.datasetList=params.datasetList
    $scope.curBoard=params.curBoard
    $scope.widgetList=params.widgetList
    var loadBoardDataset = function (status) {
        var datasetIdArr = [];
        var widgetArr = [];
        _.each($scope.curBoard.layout.rows, function (row) {
            _.each(row.widgets, function (widget) {
                var w = _.find($scope.widgetList, function (w) {
                    return w.id == widget.widgetId
                });
                if(!w){
                    console.log('切片器对应的图标')
                    return;
                }
                //图表没有数据集id????
                if (w.data.datasetId) {
                    datasetIdArr.push(w.data.datasetId);
                } else {
                    widgetArr.push(w);
                }
            });
        });

        datasetIdArr = _.union(datasetIdArr);
        $scope.boardDataset = [];
        _.each(datasetIdArr, function (d) {
            status.i++;
            dataContext.getColumns({
                datasource: null,
                query: null,
                datasetId: d,
                callback: function (dps) {
                    $scope.alerts = [];
                    if (dps.msg == "1") {
                        var dataset = _.find($scope.datasetList, function (ds) {
                            return ds.id == d;
                        });
                        if (dataset != undefined) {
                            $scope.boardDataset.push({
                                name: dataset.name,
                                columns: dps.columns,
                                datasetId: dataset.id
                            });
                        }
                        status.i--;
                    } else {
                        $scope.alerts = [{ msg: dps.msg, type: 'danger' }];
                    }
                    $scope.$apply()
                }
            });
        });
        _.each(widgetArr, function (w) {
            status.i++;
            dataContext.getColumns({
                datasource: w.data.datasource,
                query: w.data.query,
                datasetId: null,
                callback: function (dps) {
                    if (dps.msg == "1") {
                        $scope.boardDataset.push({ name: w.name, columns: dps.columns, widgetId: w.id });
                        status.i--;
                    } else {
                        $scope.alerts = [{ msg: dps.msg, type: 'danger' }];
                    }

                }
            });
        });
    };
    loadBoardDataset($scope.status)


    $scope.add = function (selectedDataset, column) {
        var v = angular.copy(selectedDataset);
        delete v.columns;
        v.column = column;
        var paramCol = $scope.param.col;
        var haveCol = null;
        for (var i = 0; i < paramCol.length; i++) {
            (paramCol[i].column == v.column && paramCol[i].name == v.name) ? haveCol = true : null;
        }
        (!haveCol || $scope.param.col == []) ? $scope.param.col.push(v) : null;
    };
    $scope.close = function () {
        $uibModalInstance.close();
    };
    $scope.deleteSelected = function (index) {
        var select = $scope.param.col[index].column;
        var nodes = $('.cube>span');
        for (var i = 0; i < nodes.length; i++) {
            if (($(nodes[i]))[0].innerText == select) {
                $(nodes[i]).removeClass('itemSelected');
            }
        }
        $scope.param.col.splice(index, 1);
    };

    var paramTypeInitial = $scope.param.paramType;  //将打开弹窗时的paramType保留作为ok方法的判断依据
    $scope.ok = function () {
        if ($scope.param.name) {

            //paramType经过变更需进行处理
            if ($scope.param.paramType != paramTypeInitial) {
                //最终是slider,只需要清空param.title
                if($scope.param.paramType == 'slider'){
                    $scope.param.title = "";
                //其它情况需清空values
                } else {
                    $scope.param.values = [];
                    //datatime之外需再清空type
                    if($scope.param.paramType != 'datatime') {
                        $scope.param.type = "";
                    }
                }
            }

            ok($scope.param);
            $uibModalInstance.close();
        } else {
            ModalUtils.alert(translate('CONFIG.DASHBOARD.ENTER_PARAMETER_NAME'), "modal-warning", "lg");
        }
    };
    $scope.foldCube = function (cube, e) {
        var node = (e.target.localName == 'img') ? e.target.parentNode.parentNode : e.target.parentNode;
        var imgNode = node.getElementsByTagName("img");
        if (e.target.className == "cubeName ng-binding" || e.target.localName == 'img') {
            if (node.style.height == "25px" || node.style.height == "") {
                node.style.height = 25 * (cube.columns.length + 1) + "px";
                imgNode[0].style.webkitTransform = "rotate(90deg)";
            } else {
                node.style.height = "25px";
                imgNode[0].style.webkitTransform = "rotate(0deg)";
            }
        } else if ($(e.target)[0].localName == 'span') {
            $(e.target).addClass('itemSelected');
        }
        $scope.param.col.map(function (d) {
            var columnSelect = d.column;
            var cubeName = d.name;
            var nodeList = $('.cube>span');
            for (var i = 0; i < nodeList.length; i++) {
                var name = nodeList[i].parentNode.firstElementChild.innerText;
                if (($(nodeList[i]))[0].innerText == columnSelect && cubeName == name) {
                    $(nodeList[i]).addClass('itemSelected');
                }
            }
        });
    };
}

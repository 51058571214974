/** @ngInject */
function widgetOption($compile) {
  var renderOption = function (scope, element, attrs) {
    // console.log(scope.widget)
    if(_.isUndefined(scope.curWidget )||_.isUndefined(scope.curWidget.config.chart_type)){return}
      var crtlname = scope.curWidget.config.chart_type.toLowerCase();
    var template = '<div id="widgetoption" class="option-config" ' + crtlname + '-option ></div>'
    var rhtml = $compile(template)(scope);
    $(element).find('#widgetoption').replaceWith(rhtml);
  };
  return {
    restrict: 'EA',
    scope: false,
    template:require('./option.html'),
    compile: function (element, attrs) {
      return {
        post: function (scope, element, attrs) {
          //监控widget数据变化
          scope.$watch('curWidget.config.chart_type', function () {
            renderOption(scope, element, attrs)
          }, true);
        }
      }
    }
  }
}


export default angular.module('BlurAdmin.theme.components.widgetOption', [])
.directive('optionBase', function () {
  return {
    template: require('./base/base.html'),
    scope: false
  }
})
.directive('optionCategoryAxis', function () {
  return {
    template: require('./base/categoryAxis.html'),
    scope: false
  }
})
.directive('optionGrid', function () {
  return {
    template: require('./base/grid.html'),
    scope: false
  }
})
.directive('optionLegend', function () {
  return {
    template: require('./base/legend.html'),
    scope: false
  }
})
.directive('optionThemeColor', function () {
  return {
    template: require('./base/themeColor.html'),
    scope: false
  }
})
.directive('optionTooltip', function () {
  return {
    template: require('./base/tooltip.html'),
    scope: false
  }
})
.directive('optionValAxis', function () {
  return {
    template: require('./base/valAxis.html'),
    scope: false
  }
})
.directive('optionTitle', function () {
  return {
    template: require('./base/optionTitle.html'),
    scope: false
  }
})
  .directive('widgetOption', ['$compile',widgetOption]).name;

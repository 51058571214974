import { officeService,globalSettingService } from '../../../server'
import './office.scss'
 export default function ( $scope, ModalUtils,toastr) {
    $scope.tab="info";
    $scope.globalSet = {
        setDefaultPassword:'0',
        passwordSecurityPolicy:"0"
    }
    var getOffice = function () {
        officeService.getOffice().then( response => {
            $scope.office = response;
            $scope.$apply();
        })
    }
    getOffice();

    var validateSetting = function(globalSet){
        if(!globalSet || !globalSet.setDefaultPassword){
            toastr.warning("请选择是否启用默认密码!")
            return false;
        }
        if(globalSet.setDefaultPassword=='1'){
            if(!globalSet.defaultPassword){
                toastr.warning("密码不能为空!")
                return false;
            }
        }
        return true;
    }

    
    $scope.toggleButton =function(setDefaultPassword){
        $scope.globalSet.setDefaultPassword = setDefaultPassword=='1'?'0':'1';
    }
    var getGlobalSetting =function(){
        globalSettingService.getInfo().then(function(response){
            if(response)$scope.globalSet =response
        })
    }
    getGlobalSetting();
    $scope.saveGlobalSet = function(globalSet){
        if(!validateSetting(globalSet))return;
        globalSettingService.save(globalSet).then(function(response){
            toastr.success("保存全局配置成功!")
            getGlobalSetting();
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg || "保存全局配置失败!")
        })
    }
    //关联账户
    $scope.associateOffice = function(){
        var param = {
            "id":$scope.office.id,
            "name":$scope.office.name,
            "address":$scope.office.address,
            "master":$scope.office.master,
            "phone":$scope.office.phone,
            "email":$scope.office.email,
            "remarks":$scope.office.remarks,
        }
        officeService.updateOffice(param).then(response => {
            ModalUtils.success(response, "", "");
            getOffice();
        }).catch(err => {
            ModalUtils.alert(err.head.errorMsg, "modal-warning", "lg")
        })
    }
};
export default angular.module('borepc.pages.dblayout.layoutgrid', [])
  .directive('gridParam', function () {
    return {
      template: require('./param.html'),
      scope: false
    }
  })
  .directive('gridWidget', function () {
    return {
      template: require('./widget.html'),
      scope: false
    }
  })
  .directive('layoutGrid', function () {
    return {
      template: require('./grid.html'),
      scope: false
    }
  })
  .name
import optionTpl from './option.html'
function crtl($scope) {
    // console.log("======="+$scope.widget)
    $.getJSON('/share/FineMap/mapdata/citycode.json', function (data) {
        $scope.provinces = data.provinces;
    });
    $scope.setCities = function () {
        $scope.cities = [];
        var province;
        //新建图表没有province
        if($scope.curWidget.config.province) {
            province = _.find($scope.provinces, function (e) {
                return e.code == $scope.curWidget.config.province.code;
            });
        }
        if (province && province.cities) {
            $scope.cities = province.cities;
        } else if ($scope.curWidget.config.city && $scope.curWidget.config.city.code) {
            $scope.curWidget.config.city.code = "";
        }
    }
}
crtl.$inject = ['$scope']
var option = {
    crtl: crtl,
    template: optionTpl
}
export default option;
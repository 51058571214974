import KpiRenderEngine from "../../rengine/KpiRenderEngine";
import * as util from '../../util/util';

class Kpi4Render {
    //默认高度
    height = 190;

    render = function (containerDom, option, scope, persist) {
        var render = new KpiRenderEngine(containerDom, option);
        var html = render.html(persist);
        if (scope) {
            containerDom.append($compile(html)(scope));
        } else {
            containerDom.html(html);
        }
        return render.realTimeTicket();
    };

    parseOption = function (data) {
        var option = {};
        var config = data.chartConfig || {};
        // var casted_keys = data.keys;
        // var casted_values = data.series;
        var aggregate_data = data.data || [];
        // var newValuesConfig = data.seriesConfig;

        //获取指标数组
        var vList = [];
        _.each(aggregate_data, (v, i)=>{
          if(v && v[0]) {
            var vv = v[0], col = config.values[0].cols[i] || {};
            if(col.unit || col.symbol ||col.dividend || col.mantissa) {
              vv = util.convertEchartUnit(col, vv);
            }
            vList.push(vv);
          }
        });
        var kpiValueArr = String(config.option.format || '{value}').split('{value}'),
            kpiValue = kpiValueArr[0] == null ? '' : kpiValueArr[0],
            vLength = vList.length || 1;
        for(var i = 1, l = kpiValueArr.length; i < l; i++) {
          kpiValue = `${kpiValue}${vList[(i-1)%vLength] == null ? '' : vList[(i-1)%vLength]}${kpiValueArr[i]}`;
        }

        option.kpiValue = {
            value: kpiValue.split(' ').join('&nbsp;'),
            unit: "",
            unitFontSize: "1em"
        };
        option.valueScale = ((config.option.valueSize || 32) / 32) + 'em';
        option.kpiName = config.option.name || '';
        option.hasKpiName = option.kpiName || option.kpiName === 0 ? 'block' : 'none';
        option.bodyClass = option.kpiName || option.kpiName === 0 ? 'new_kpi_body' : 'new_kpi_body_noheader';
        option.style = config.option.style || 'bg-aqua';
        option.edit = 'edit';
        option.refresh = 'refresh';
        return option;
    };
}

export { Kpi4Render }



  /** @ngInject */
  export default  function baPanel(baPanel) {
    const  blur=false;
    return angular.extend({}, baPanel, {
      template: function(el, attrs) {
        var id = el.context.id || 'panelId';
        var res = '<div id="'+ id + '" class = " panel ' + (blur ? 'panel-blur' : '') + ' full-invisible ' + (attrs.baPanelClass || '');
        res += '" zoom-in ' + ( blur ? 'ba-panel-blur' : '') + '>';
        res += baPanel.template(el, attrs);
        res += '</div>';
        return res;
      }
    });
  }

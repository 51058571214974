import PubSuber from "../../../utils/PubSub"
export  default  function hasPermsService($rootScope, $window) {
    var permissionList = [];
    return {
        setPermissions: function (permissions) {
            permissionList = permissions;
            //需要观察是否影响页面性能
            $rootScope.$broadcast('permissionsChanged')
        },
        hasPermission: function (permission) {
            // if (permissionList.length == 0) {
            //     $window.open('login.html', '_self');
            //     return false;
            // }
            permission = permission.trim();
            var flag = false;
            for (var i = 0; i < permissionList.length; i++) {
                if (permission === permissionList[i]) {
                    flag = true;
                    break;
                }
            }
            return flag;
        }
    };
}


/**
 * @author jaray
 *   
 */

  /** @ngInject */
  export  default   function includeWithScope() {
    return {
      restrict: 'AE',
      templateUrl: function(ele, attrs) {
        return attrs.includeWithScope;
      }
    };
  }


import { userAdminService,accessControlService } from '../../../server'
import windowTpl from '../../../common/theme/components/modal/window.html'
import { util } from  '../../../common/utils/util'
import userSelectedListTpl from '../user/userSelectedList.html'
export default  function accessControlCtrl( $scope, $uibModal, toastr) {

    //获取信息
    $scope.getAccessControl = function(){
        accessControlService.getAccessControl().then(function(response){
            $scope.acData = response;
            $scope.acData.whitelistUser =response.whitelistUser ? angular.fromJson(response.whitelistUser) :[];
            $scope.acData.blacklistUser =response.blacklistUser ? angular.fromJson(response.blacklistUser) :[];
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg || "获取信息失败!")
        })
    }

    $scope.getAccessControl();

    $scope.removeUser =function(type,userId,acUsers){
        if(type=="0"){
            $scope.acData.whitelistUser= _.filter(acUsers,function(e){return e!=userId})
        }else{
            $scope.acData.blacklistUser= _.filter(acUsers,function(e){return e!=userId})
        }
    }

    $scope.save = function(){
        accessControlService.saveAccessControl({acJson:angular.toJson($scope.acData)}).then(function(){
            toastr.success("保存成功!")
            $scope.getAccessControl();
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg || "获取信息失败!");
        })
    }

    //获取用户信息
    userAdminService.postUsers({pageNo:1,pageSize:999}).then(function (response) {
        $scope.userList = response.listdata;
        $scope.$apply();
    },err=>{
        toastr.error(err.head.errorMsg)
    });
   
    $scope.openUserList = function (type,selectIds) {
        
        $uibModal.open({ 
            template: userSelectedListTpl,
            windowTemplate:windowTpl,
            size: 'lg',
            windowClass: 'pw-user-modal',
            resolve: {
                selectIds: function () {
                    return selectIds;
                },
                ok: function () {
                    return function (param) {
                        // console.log("param",param)
                        if(type=="0"){
                            $scope.acData.whitelistUser = param;
                        }else{
                            $scope.acData.blacklistUser = param;
                        }
                    }
                }
            },
            controller: ['$scope', '$uibModalInstance',   'selectIds', 'ok',function ($scope, $uibModalInstance,   selectIds, ok) {
                $scope.selectIds = angular.copy(selectIds) || [];
                // $scope.userList = userList;

                $scope.paginationConf = {
                    currentPage: 1,
                    totalItems: 0,
                    itemsPerPage: 15,
                    onChange: function () {
                        $scope.getUserList(); //重新加载
                    }
                };

                $scope.getUserList =function(){
                    var params = {
                        "pageNo": $scope.paginationConf.currentPage,
                        "pageSize": $scope.paginationConf.itemsPerPage,
                        "name":$scope.search
                    }
                    userAdminService.postUsers(params).then(function (response) {
                        $scope.userList = response.listdata;
                        $scope.paginationConf.totalItems = response.totolCount;
                        $scope.ifCheckedAll();
                        $scope.$apply();
                    },err=>{
                        toastr.error(err.head.errorMsg)
                    });
                }
                         

                //更新选择的用户
                $scope.updateSelection = function ($event, id) {
                    //只有被选中的时候才添加到ids集合中 
                    if ($event.target.checked) {
                        $scope.selectIds.push(id); //push向集合中添加元素
                    } else {
                        //如果没被选中则删除集合中的元素
                        var index = $scope.selectIds.indexOf(id); //查找值的位置
                        $scope.selectIds.splice(index, 1); //参数1:移除的位置 参数2:移除的个数
                    }
                }

               

                //判断是否选择好了
                $scope.ifChecked = function (id) {
                    //如果Id出现在数组中，则需要返回true
                    var index = $scope.selectIds.indexOf(id);
                    if (index == -1) {
                        return false;
                    } else { //如果id没有出现在selectIds数组中需要返回false
                        return true;
                    }
                };

                //判断是否全选了
                $scope.ifCheckedAll = function () {
                    //$scope.userList中的对象的id 是否都在 $scope.selectIds中
                    //如果全中，则返回true
                    //如果有一个还在，就返回false
                    if(!$scope.userList) return false;
                    for (var i = 0; i < $scope.userList.length; i++) {
                        if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                            return false;
                        }
                    }
                    return true;
                };

                //全选与取消全选
                $scope.selectAll = function ($event) {
                    if ($event.target.checked) {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            //当前页面的数据的id放到数组中
                            if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                                $scope.selectIds.push($scope.userList[i].id);
                            }
                        }
                    } else {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            var index = $scope.selectIds.indexOf($scope.userList[i].id);
                            $scope.selectIds.splice(index, 1);
                        }
                    }
                };

                //关闭
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                //确定
                $scope.ok = function () {
                    $uibModalInstance.close();
                    ok($scope.selectIds);
                };
            }]
        });
    }
   
};
import accessControlTpl from './accessControl.html'
import accessControlCtrl from './accessControlCtrl'

export default angular.module('borepc.pages.accessControl', [])
    .controller('accessControlCtrl', ['$scope', '$uibModal', 'toastr',accessControlCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('accessControl', {
            url: '/accessControl',
            title: '我的账户',
            controller: 'accessControlCtrl',
            template: accessControlTpl
        })
}
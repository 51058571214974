import PubSuber from '../common/utils/PubSub';
import { dataService } from '../server'
import * as util from './util/util'
class BoardContext {
    //初始化看板上下文
    initBoardContext(boardId) {
        if (!boardId) {
            boardId = this.getBoardId();
        }
        window.BOARD = [];
        window.BOARD[boardId] = [];
        window.BOARD[boardId].widgetFilters = [];       //根据图表的widgetId存入的联动切片条件
        window.BOARD[boardId].widgetAllFilters = [];    //根据图表的renderId存入当前图表所有的切片条件
        window.BOARD[boardId].boardFilters = [];
        window.BOARD[boardId].loaddingWidgets = {status:0,count:0};
    }
    getBoardId = () => {
        if (window.CURBOARDID) {
            return window.CURBOARDID;
        } else {
            return 'CURBOARDID';
        }
    }
    setBoardId = (boardId) => {
        window.CURBOARDID = boardId;
    }
    // 注册广播----使用的是renderId----也可以使用widgetId进行匹配刷新
    regWidget(widgetId,callback) {
        let boardId = this.getBoardId();
        let regId = boardId + '.' + widgetId;
        if (!window.BOARD) {
            window.BOARD = [];
        }
        if (_.isUndefined(window.BOARD[boardId])) {
            window.BOARD[boardId] = []
        }
        // 全体广播时遍历的数组, 防止重复广播-------该对象同时作为数组和对象使用，保留--------2021.04.12
        // if(window.BOARD[boardId].indexOf(regId) > -1) {
            window.BOARD[boardId].push(regId)
        // }
        if(PubSuber.hasSubscribers(regId)){
            PubSuber.unsubscribe(regId)
        } 
        PubSuber.subscribe(regId,callback)
        // return regId
    }
    // 注销广播----renderId-----包括widget和chartId
    unRegWidget(renderId) {
        let boardId = this.getBoardId();
        let regId = boardId + '.' + renderId;
        if (!window.BOARD) {
            window.BOARD = [];
        }
        if (_.isUndefined(window.BOARD[boardId])) {
            window.BOARD[boardId] = []
        }
        if(PubSuber.hasSubscribers(regId)){
            PubSuber.unsubscribe(regId);
            // 该对象同时作为数组和对象使用，保留----------------------------------2021.04.12
            // let index = window.BOARD[boardId].indexOf(regId);
            // if(index > -1) {
            //     window.BOARD[boardId].splice(index, 1);
            // }
        } 
        // return regId
    }
    // 未使用方法
    getWidgetSubId(widgetId) {
        let boardId = this.getBoardId();
        return boardId + '.' + widgetId;
    }
    // 看板boardView初始化和销毁时调用
    clearBoardData() {
        let boardId = this.getBoardId();
        // PubSuber.unsubscribe(boardId)
        window.BOARD = [];
        window.BOARD[boardId] = [];
        window.BOARD[boardId].widgetFilters = [];
        window.BOARD[boardId].widgetAllFilters = [];
        window.BOARD[boardId].boardFilters = [];
        window.BOARD[boardId].loaddingWidgets = {status:0,count:0};
    }
    // 重载图表----根据widgetId----即所有该id的图表
    refreshWidget = (widgetId) => {
        let boardId = this.getBoardId();
        // console.log(PubSuber.subscribers())
        let subscribers=PubSuber.subscribers();
        let keys=Object.keys(subscribers)
        if(keys.length==0){
            return ;
        }
        _.each(keys,function(key){
            // console.log(key)
            let prefix=boardId + '.' + widgetId
            if(key.indexOf(prefix)==0){
                // console.log(key)
                PubSuber.publish(key, '')
            } 
        })
        
    }

    // 重载图表----根据renderId----1个
    refreshWidgetRenderId = (renderId) => {
        let boardId = this.getBoardId();
        PubSuber.publish(boardId + '.' + renderId, '')
    }


    //刷新所有图表
    refreshAll = () => {
        let boardId = this.getBoardId();
        if (window.BOARD[boardId]) {
            _.each(window.BOARD[boardId], (item) => {
                // console.log(item)
                PubSuber.publish(item, '')
            })
        }
        // PubSuber.publish(boardId+'.'+widgetId, '')
    }
    //设置图表筛选器，只对单个图标有效----未使用
    setWidgetFilters(widgetFilters) {
        let boardId = this.getBoardId();
        let _this = this;
        _.each(widgetFilters, function(filter){
            _this.updateFilterTitle(filter, "filter-lable");
        });
        window.BOARD[boardId].widgetFilters = widgetFilters;
    }

    //设置看板筛选器 ，对所有看板有效
    setBoardFilters(boardFilters) {
        let boardId = this.getBoardId();
        let _this = this;
        _.each(boardFilters, function(filter){
            _this.updateFilterTitle(filter, "filter-lable");
        });
        if(_.isUndefined(window.BOARD[boardId])){
            window.BOARD[boardId]=[]
        }
        window.BOARD[boardId].boardFilters = boardFilters;
    }

    // 已被getWidgetFilterTitle取代----看板中图表显示过滤条件
    getWidgetFilter(widget) {
        const widget_dsId = widget.data.datasetId
        let boardFilters = []
        let boardId = this.getBoardId();
        if (window.BOARD[boardId].boardFilters) {
          
            // boardFilters = util.clone(window.BOARD[boardId].boardFilters)
            const g_boardFilters = window.BOARD[boardId].boardFilters;
            _.each(g_boardFilters, (item) => {
                if (!_.isUndefined(item.datasetId)) {
                    if (item.datasetId == widget_dsId) {
                        boardFilters.push({
                            col: item.col,
                            type: item.type,
                            values: item.values
                        })
                    }
                } else {
                    boardFilters.push({
                        col: item.col,
                        type: item.type,
                        values: item.values
                    })
                }
            })
        }
        if (!window.BOARD[boardId].widgetFilters) {
            window.BOARD[boardId].widgetFilters = []
        }
        if (window.BOARD[boardId].widgetFilters[widget.id]) {
            _.each(window.BOARD[boardId].widgetFilters[widget.id], (item) => {
                var col = "<i class='fa fa-link'></i>&nbsp;" + item.col;
                boardFilters.push({
                    col: col,
                    type: item.type,
                    values: item.values
                });
            })
        }
        // console.log(boardFilters)
        return boardFilters;
    }
    // 取代getWidgetFilter--------看板中图表显示过滤条件
    getWidgetFilterTitle(widget, havSlicerRender) {
        const widget_dsId = widget.data.datasetId
        let boardFilters = []
        let boardId = this.getBoardId();
        // 图表受看板切片器影响----默认受影响
        if (window.BOARD[boardId].boardFilters && havSlicerRender ) {
          
            // boardFilters = util.clone(window.BOARD[boardId].boardFilters)
            const g_boardFilters = window.BOARD[boardId].boardFilters;
            _.each(g_boardFilters, (item) => {
                if (!_.isUndefined(item.datasetId)) {
                    if (item.datasetId == widget_dsId) {
                        boardFilters.push({
                            title: item.title,
                            col: item.col,
                            type: item.type,
                            values: item.values
                        })
                    }
                } else {
                    boardFilters.push({
                        title: item.title,
                        col: item.col,
                        type: item.type,
                        values: item.values
                    })
                }
            })
        }
        if (!window.BOARD[boardId].widgetFilters) {
            window.BOARD[boardId].widgetFilters = []
        }
        if (window.BOARD[boardId].widgetFilters[widget.id]) {
            _.each(window.BOARD[boardId].widgetFilters[widget.id], (item) => {
                var col = "<i class='fa fa-link'></i>&nbsp;" + item.col;
                boardFilters.push({
                    title: item.title,
                    col: col,
                    type: item.type,
                    values: item.values
                });
            })
        }
        // console.log(boardFilters)
        return boardFilters;
    }
    clearAllWidgetFilters() {
        let boardId = this.getBoardId();
        window.BOARD[boardId].widgetFilters = []
    }
    //根据widgetId清除widgetFilters
    clearWidgetFiltersById(widgetId) {
        let boardId = this.getBoardId();
        window.BOARD[boardId].widgetFilters[widgetId] = [];
    }
    //chartContent给chartConfig设置过滤器
    wrapWidgetFilters(widget) {
        // console.log(widget.data.datasetId)
        const widget_dsId = widget.data.datasetId
        const havSlicerRender = widget.data.config.havSlicerRender === false ? false : true;
        const renderId = widget.data.config.renderId || widget.id;  //renderId可能不存在
        let boardFilters = []
        let boardId = this.getBoardId();
        // havSlicerRender为true说明该图表受看板切片器影响
        if (window.BOARD[boardId].boardFilters && havSlicerRender ) {
            // boardFilters = util.clone(window.BOARD[boardId].boardFilters)
            const g_boardFilters = window.BOARD[boardId].boardFilters;
            _.each(g_boardFilters, (item) => {
                if (!_.isUndefined(item.datasetId)) {
                    if (item.datasetId == widget_dsId) {
                        boardFilters.push({
                            col: item.col,
                            type: item.type,
                            values: item.values
                        })
                    }
                } else {
                    boardFilters.push({
                        col: item.col,
                        type: item.type,
                        values: item.values
                    })
                }
            })
        }
        // 联动图表是根据widgetId判断的
        if (!window.BOARD[boardId].widgetFilters) {
            window.BOARD[boardId].widgetFilters = []
        }
        if (window.BOARD[boardId].widgetFilters[widget.id]) {
            _.each(window.BOARD[boardId].widgetFilters[widget.id], (item) => {
                boardFilters.push(item)
            })
        }
        // 将当前图表所有的切片条件存入全局----使用renderId
        if (window.BOARD[boardId].widgetAllFilters) {
            window.BOARD[boardId].widgetAllFilters[renderId] = boardFilters;
        } else {
            window.BOARD[boardId].widgetAllFilters = [];
            window.BOARD[boardId].widgetAllFilters[renderId] = boardFilters;
        }
        widget.data.config.boardFilters = boardFilters;
        // console.log(boardFilters)
        return widget;

    }

    // 未使用
    injectWidgetFilter(widgetId, filter) {
        let boardId = this.getBoardId();
        if (!window.BOARD[boardId].widgetFilters[widgetId]) {
            window.BOARD[boardId].widgetFilters[widgetId] = []
        }
        this.updateFilterTitle(filter, "filter-lable");
        window.BOARD[boardId].widgetFilters[widgetId].push(filter)
        this.refreshWidget(widgetId)
    }

    //清空所有筛选----boredv中引用
    clearAllFilters() {
        let boardId = this.getBoardId();
        // if(!window.BOARD ){
        //     window.BOARD = [];
        // }else{
        //     window.BOARD[boardId]=[];
        //     window.BOARD[boardId].widgetFilters = []
        //     window.BOARD[boardId].boardFilters = [];
        // }
        if(!window.BOARD) {
            window.BOARD = [];
        }
        if(!window.BOARD[boardId]) {
            window.BOARD[boardId]=[];
        }
        window.BOARD[boardId].widgetFilters = [];
        window.BOARD[boardId].boardFilters = [];
    }

    // 看板中图表联动----获取源图表的联动图表过滤器
    getWidgetFilterById(widgetId){
        let boardId = this.getBoardId();
        if( window.BOARD[boardId].widgetFilters[widgetId]){
            return window.BOARD[boardId].widgetFilters[widgetId]
        }else{
            return []
        }   
    }
    // 获取图表所有的看板相关切片器----未使用
    getWidgetFilterAllById(widgetId){
        let boardFilters = []
        let boardId = this.getBoardId();
        if (window.BOARD[boardId].boardFilters) {
              boardFilters = util.clone(window.BOARD[boardId].boardFilters)
            } 
        if (!window.BOARD[boardId].widgetFilters) {
            window.BOARD[boardId].widgetFilters = []
        }
        if (window.BOARD[boardId].widgetFilters[widgetId]) {
            _.each(window.BOARD[boardId].widgetFilters[widgetId], (item) => {
                boardFilters.push(item)
            })
        }
        // console.log(boardFilters)
        return boardFilters;

    }
    // 获取图表所有的切片条件-----渲染图表无法使用(渲染时更新目标数组)----图表联动看板使用
    getWidgetAllFilterById(renderId){
        let widgetFilters = [];
        let boardId = this.getBoardId();
        if(window.BOARD[boardId].widgetAllFilters) {
            widgetFilters = window.BOARD[boardId].widgetAllFilters[renderId];
        }
        return widgetFilters;
    }
    // chartContent中作为图表渲染进度的判断依据1
    addLoadingWidget(){
        let boardId = this.getBoardId();
        if(!window.BOARD){
            this.initBoardContext();
        }
        if(!window.BOARD[boardId].loaddingWidgets){
            window.BOARD[boardId].loaddingWidgets = {status:0,count:0};
        }
        PubSuber.publish("loading_"+boardId,1);
        window.BOARD[boardId].loaddingWidgets.status = 1;
        window.BOARD[boardId].loaddingWidgets.count++;
    }
    // chartContent中作为图表渲染进度的判断依据2
    removeLoadingWidget(){
        let boardId = this.getBoardId();
        window.BOARD[boardId].loaddingWidgets.count--;
        if(window.BOARD[boardId].loaddingWidgets.count==0){
            window.BOARD[boardId].loaddingWidgets.status = 2;
            PubSuber.publish("loading_"+boardId,2);
        }
    }
 
    // 联动图表----注入图表过滤器
    injectWidgetFilters(widgetId, filters) {
        let boardId = this.getBoardId();
        let _this = this;
        if (!window.BOARD[boardId].widgetFilters[widgetId]) {
            window.BOARD[boardId].widgetFilters[widgetId] = []
        }
        let w_fitlers=window.BOARD[boardId].widgetFilters[widgetId]; //widget filter 
        _.each(filters,(filter)=>{
            if(w_fitlers.length>0){
                var flag = false; //表示col没有出现相等的情况
                _.each(w_fitlers,w_filter=>{
                    if(w_filter.col==filter.col){
                        _.each(filter.values,value=>{
                            w_filter.values.push(value)
                        })
                        w_filter.values=_.uniq(w_filter.values);
                        w_filter.values.sort(function (a, b) {
                            //同时为非数字时排序
                            if(isNaN(a) && isNaN(b)){
                                //循环比较字符--第一个开始
                                for(var i= 0; i < a.length; i++){
                                    if(a[i] == b[i]){
                                        continue;
                                    }
                                    //同时为非数字或同为数字
                                    if( (isNaN(a[i]) && isNaN(b[i]) ) || (!isNaN(a[i]) && !isNaN(b[i])) ) {
                                        return a[i] < b[i] ? -1 : 1;
                                    } else if( isNaN(a[i]) && !isNaN(b[i]) ){
                                        return 1;
                                    } else if( !isNaN(a[i]) && isNaN(b[i]) ){
                                        return -1;
                                    }

                                }
                                return 0;
                            } else if(isNaN(a) && !isNaN(b)) {
                                return 1;
                            //非数字在数字后面
                            } else if(!isNaN(a) && isNaN(b)) {
                                return -1;
                            } else {
                                return a - b; //数字大的在后面
                            }
                        });
                        flag = true;
                        _this.updateFilterTitle(w_filter, "filter-lable", "<i class='fa fa-link'></i>&nbsp;" + filter.col);
                    }
                });
                if(!flag){
                    _this.updateFilterTitle(filter, "filter-lable", "<i class='fa fa-link'></i>&nbsp;" + filter.col);
                    w_fitlers.push(filter)
                }
            }else{
                _this.updateFilterTitle(filter, "filter-lable", "<i class='fa fa-link'></i>&nbsp;" + filter.col);
                w_fitlers.push(filter)
            }
        })
        window.BOARD[boardId].widgetFilters[widgetId]=w_fitlers;
        // 当前看板所有数据均已经加载完毕，等待渲染的前的节点(存在已经渲染完的图表)
        if(window.BOARD[boardId].loaddingWidgets.count==0) {
            this.refreshWidget(widgetId);
        }
    }
    
    //获取当前看板的缓存设置
    getBoardCache(){
        let boardId = this.getBoardId();
        return window.BOARD[boardId].cache;
    }
    getBoardDataCache(){
        let boardId = this.getBoardId();
        return window.BOARD[boardId].cache.dataCache;
    }
    getBoardDimensionCache(){
        let boardId = this.getBoardId();
        return window.BOARD[boardId].cache.dimensionCache;
    }
    //设置当前看板的缓存设置
    setBoardCache = function(cache){
        let boardId = this.getBoardId();
        if(window.BOARD){
            if(window.BOARD[boardId]) {
                window.BOARD[boardId].cache = cache;
            } else {
                window.BOARD[boardId]=[]
                window.BOARD[boardId].cache =  cache;
            }
        } else {
            window.BOARD = [];
            window.BOARD[boardId]=[]
            window.BOARD[boardId].cache =  cache;
        }
    }
    //置空当前看板的缓存设置
    clearBoardCache = function(){
        let boardId = this.getBoardId();
        if(window.BOARD && window.BOARD[boardId] && window.BOARD[boardId].cache){
            window.BOARD[boardId].cache = {};
        }
    }

    //将看板过滤器中生成供chart使用的title------关联的过滤器col需要加样式---引入col
    updateFilterTitle = function(filter, spanClass, col){

        //没有值时title就是空字符串
        if(!filter.values || filter.values.length <= 0) {
            filter.title = "";
            return;
        }

        //判断是否需要隐藏当前过滤词条
        var flag = false;     //默认不隐藏
  
        var promisecontain = [];
        for(var i = 0; i < filter.values.length; i++) {
          //判断存在不是时间的表达式---存在即终止循环
          if(filter.values[i].indexOf('{') === 0 && filter.values[i].indexOf('{now') !== 0) {
            flag = true;
            break;
          }

          var newPromise = new Promise((resolve, reject) => {
            if(filter.values[i].indexOf('{now') === 0) {
              dataService.getTimeParserExpression(filter.values[i]).then(res => {
                resolve(res);
              });
            } else {
              resolve(filter.values[i]);
            }
          });
          promisecontain.push(newPromise);
  
        }
  
        //存在表达式且不为时间
        if(!flag) {
          Promise.all(promisecontain).then(res=>{
            filter.title = col ? "<span class='" + spanClass + "'>" + col + filter.type + res.toString() + "</span>" : "<span class='" + spanClass + "'>" + filter.col + filter.type + res.toString() + "</span>";

            PubSuber.publish("updataFilterTitle");
          });
        } else {
          filter.title = "";
        }
  
      }

}

const boardContext = new BoardContext();
export default boardContext;


import * as util from '../util/util';
import dataContextUtil from './dataContextUtil';

//根据图表配置项重新排序合并数据
function getSortMergeData(widget,mergeData,datasetIds) {
    // console.log("mergeData-before",util.clone(mergeData))
    //排序列表
    var cfConfig = util.clone(widget.config);
    var sortMergeColumn = [];
    var dataSeries = dataContextUtil.parseDataSeries(cfConfig);
    //根据图表配置文件生成排序列表 groups=>keys=>values
    _.map(cfConfig.groups,function(e){
        sortMergeColumn.push({col:e.alias,dId:e.dId});
    });
    _.map(cfConfig.keys,function(e){
        sortMergeColumn.push({col:e.alias,dId:e.dId});
    });
    _.map(cfConfig.values,function(e){
        _.map(e.cols,function(c){
            if(c.aggregate_type){
                sortMergeColumn.push({col:c.col,dId:c.dId});
            }
        });
    });
    _.map(dataSeries,function(e){
        let arr = e.name.split("-d");
        if(arr.length>1){
            sortMergeColumn.push({col:arr[0],dId:datasetIds[arr[1]-1]});
        }
    });
    sortMergeColumn= _.uniq(sortMergeColumn,function(e){
        return e.dId + e.col;
    });
    //排序索引数组
    var sortIndex=[];
    //根据图表配置文件的排序 重新定义 返回的数据集顺序,并生成索引数组:[4, 0, 1, 2, 5, 6, 7, 8, 3...]
    _.map(sortMergeColumn,function(s,sdx){
        _.map(mergeData.columnList,function(m,mdx){
            if(s.col==m.name&&s.dId==m.dId){
                sortIndex[sdx]=mdx;
            }
        });
    });
    //将合并后的columnList按照索引数组排序,并重新定义index属性
    mergeData.columnList= _.map(_.sortBy(mergeData.columnList, function(data,index){
            return sortIndex.indexOf(index);
        }),function(e,index){
            e.index=index;
            return e;
    });

    mergeData.data= _.map(mergeData.data,function(d){
        return _.sortBy(d,function(e,i){
            return sortIndex.indexOf(i);
        });
    });
    return mergeData;
};
//根据父数据集配置 和看板过滤列别名获得列名和归属索引
function getBelongDatasetByCol(datasetData,aliasName){
    var data = {
        dIndex:"",//归属数据集索引
        columnName:aliasName//归属列名
    };
    var columns = datasetData.columns;
    var aliasColumn = datasetData.aliasColumn;
    for (let index = 0; index < aliasColumn.length; index++) {
        let aIndex = aliasColumn[index].indexOf(aliasName);
        if(aliasColumn[index] && aIndex>-1) {
            data.dIndex = index;
            data.columnName = columns[index][aIndex];
        }
    }
    return data;
}
//获取图表配置中所有过滤以及过滤条件
function getWidgetAllFilter(chartConfig,datasetIds,datasetData) {
    var result = [];
    if (chartConfig.groups) {
        util.each(chartConfig.group, function (e) {
            if(e.type && e.values && e.values.length>0){
                result.push({
                    columnName: e.col,
                    filterType: e.type,
                    values: e.values,
                    dId:e.dId
                });
            }
        });
    }
    if (chartConfig.keys) {
        util.each(chartConfig.keys, function (e) {
            if(e.type && e.values && e.values.length>0){
                result.push({
                    columnName: e.col,
                    filterType: e.type,
                    values: e.values,
                    dId:e.dId
                });
            }
        });
    }
    if (chartConfig.filters) {
        util.each(chartConfig.filters, function (e) {
            util.each(e.filters, function (f) {
                if(f.type && f.values && f.values.length>0){
                    result.push({
                        columnName: f.col,
                        filterType: f.type,
                        values: f.values,
                        dId:f.dId
                    });
                }
            });
        });
    }
    if (chartConfig.boardFilters) {
        _.map(chartConfig.boardFilters, function (e) {
            let colData = getBelongDatasetByCol(datasetData,e.col);
            result.push({
                values:e.values,
                col:colData.columnName,
                type:e.type,
                dId:datasetIds[colData.dindex]
            });
        });
    }
    return result;
}
//依据图表配置的筛选条件获取对应的数据集Id
function getFilterDids(data){
    var filterIds = [];
    _.map(_.groupBy(data,"dId"),function(d,key){
      if(!util.isEmpty(key))filterIds.push(key);
    })
    return filterIds;
  }
//判断两行数据是否可以合并,array关联字段的索引,ed第一行数据,ad第二行数据,i:数据表在结果集中索引
function isAssionColumn(array, ed, ad, i) {
  let isAs = true;
  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    if (ed[element[i]] != ad[element[i + 1]]) {
      isAs = false;
    }
  }
  return isAs;
}

class DataContextMerge {
    constructor() {}

    /**1. 获取合并后的数据
     *
     * @param {*} results 子数据集数据数组
     * @param {*} dataset 父数据集详情
     * @param {*} widget 图表配置
     * @param {*} datasetIds 子数据集id数组
     * */
    getMergeData = function (results, dataset, widget, datasetIds) {
        var mergeData = {
            columnList: [],
            data: []
        };
        var assColIndex = [];         //关联列在结果集中对应的索引
        var reData = [];              //合并后的数据
        var associate = dataset.data.associate;//子数据集关联关系
        var assFields = dataset.data.assFields;//子数据集关联字段
        //根据关联关系(assFields),获取关联字段在两个结果集中分别对应的列索引,并组成二维数组
        _.map(assFields, function (f, fdx) {
            let assFirstArr = [];
            _.map(datasetIds, function (d, i) {
                var dIndex = "d" + (i + 1); //关联关系中用d1 d2...区分数据集
                // console.log(f.alias)
                var fData = f[dIndex];
                if (!_.isUndefined(fData) && results[i]) {
                    if (!results[i]) return;
                    let columnList = results[i].columnList;
                    let data = _.filter(columnList, function (e) {
                        return e.name == fData;
                    });
                    let index = columnList.indexOf(data[0])
                    assFirstArr[i] = index;
                }
            });
            assColIndex.push(assFirstArr);
        });

        //多源数据集中每个结果集中数据是根据对应的数据集筛选条件进行筛选的,
        //合并后进行外连接或左右连接时,如果该子结果集有筛选条件且子数据为空,那么该条合并数据不应该放入meregeData中

        //获取所有筛选条件
        var allFilter = getWidgetAllFilter(widget.config,datasetIds,dataset.data);
        //获取筛选条件对应的数据集Id
        var filterDIds = getFilterDids(allFilter,datasetIds);
        //合并数据
        for (let m = results.length-1; m >0; m--) {
            _.map(results[m].columnList,function(c){
                c.dId=datasetIds[m];
                let cnames = _.pluck(results[m-1].columnList,"name");
                if(m>0&&cnames.indexOf(c.name)>-1){
                    c.name=c.name+"_"+(m);
                }
            });
        }
        for (let i = 0; i < results.length; i++) {
            if (!results[i]) continue;
            mergeData.columnList = mergeData.columnList.concat(results[i].columnList);
            if (i < results.length - 1) {         //最后1个不会执行
                //表数据
                let eData = results[i].data;      //当前的
                let aData = results[i + 1].data;  //下一个的
                //创建空字符串数组以拼接空白处
                let eEmptyArr = new Array(results[i].columnList.length).fill("");
                let aEmptyArr = new Array(results[i+1].columnList.length).fill("");
                //ed ad:行数据
                // var dIndex = 0;
                var aIndexArr = [];
                //循环表中行数据进行匹配
                for (let eix = 0; eix < eData.length; eix++) {
                    //是否匹配上
                    let isMatchingRow = false;
                    for (let dix = 0; dix < aData.length; dix++) {
                        let ed = eData[eix];
                        let ad = aData[dix];
                        //当第一张表中的第x列等于第二张表中的第y列时 将两行合并放入
                        if (isAssionColumn(assColIndex, ed, ad, i)) {
                            reData.push(eData[eix].concat(aData[dix]));
                            isMatchingRow = true;
                            aIndexArr.push(dix);
                            // break;
                        }
                    }
                    //设置为左或全连接时,如果第一张表中第eix行数据没有匹配上,也放入数组中
                    //设置为右或全连接时,统计第二张表中所有没有匹配上第一张表中数据的行,将这些行放入数组中
                    //只有当该数据集没有过滤条件时,才能将空数据与另一个数据拼接
                    switch (associate) {
                        case "left":
                            if (!isMatchingRow && filterDIds.indexOf(datasetIds[i+1])<0) reData.push(eData[eix].concat(aEmptyArr));
                            break;
                        case "right":
                            if (eix == eData.length - 1  && filterDIds.indexOf(datasetIds[i])<0) {
                                let aArr = _.map(_.filter(aData, function (e, index) {
                                        return aIndexArr.indexOf(index) < 0;
                                    }), function (e) {
                                        return eEmptyArr.concat(e);
                                });
                                reData = reData.concat(aArr);
                            }
                            break;
                        case "all":
                            if (!isMatchingRow  && filterDIds.indexOf(datasetIds[i+1])<0) reData.push(eData[eix].concat(aEmptyArr));
                            if (eix == eData.length - 1  && filterDIds.indexOf(datasetIds[i])<0) {
                                let aArr = _.map(_.filter(aData, function (e, index) {
                                        return aIndexArr.indexOf(index) < 0;
                                    }), function (e) {
                                        return eEmptyArr.concat(e);
                                });
                                reData = reData.concat(aArr)
                            }
                            break;
                        default:
                            break;
                    } //switch
                }
            }
        }
        mergeData.data = reData;
        //根据图表配置项重新排序合并数据
        mergeData = getSortMergeData(widget,mergeData,datasetIds);
        //   console.log("mergeData",mergeData)
        return mergeData;
    };
    // 2. 后台数据==>BI图表需要的数据
    castMergeRawData2Series = function(aggData, chartConfig) {
      return dataContextUtil.aggDataToBIData(aggData, chartConfig, 'merge');
    };
    // 3. 判断图表配置文件是否包含关联字段
    configHasAssColumn = function(widgetConfig,datasetData){
        var assFields = datasetData.assFields;
        var datasetIds = datasetData.datasetIds;
        var configColumn = [];
        //根据图表配置文件生成列名数组
        _.map(widgetConfig.groups,function(e){
            configColumn.push({col:e.col,dId:e.dId});
        });
        _.map(widgetConfig.keys,function(e){
            configColumn.push({col:e.col,dId:e.dId});
        });
        //依据dId分组
        let groupColumn = _.groupBy(configColumn,"dId");
        // console.log("groupColumn",groupColumn)
        //判断关联字段中是否包含列名数组中元素,并获取关联字段在关联关系中隶属的索引
        //隶属的索引数组
        let assIndexArr = [];
        for (let j = 0; j < assFields.length; j++) {
            let fild = assFields[j];
            for (let i = 0; i < datasetIds.length; i++) {
                let dId = datasetIds[i];
                //归属于第i个数据集的图表配置列名
                let columnArr = _.pluck(groupColumn[dId],"col");
                let dKey = "d"+(i+1);
                //fild[dKey]:第i个数据集在关联字段中对应的列名
                //判断第i个数据集的图表配置,是否包含关联字段,包含的话将索引放入索引数组,并跳出内循环
                if(columnArr.indexOf(fild[dKey])>-1){
                    assIndexArr.push(j)
                    break;
                }
            }
        }
        // console.log("assIndexArr",assIndexArr)
        return assIndexArr;
    };

    /**4. 获取没有在配置文件中的关联字段
     *
     * @param {*} assFields 关联字段
     * @param {*} cfg 单数据集配置
     * @param {*} index 数据集索引
     * @param {*} assIndexArr 在图表配置中的关联字段索引
     * */
    getAssColumnsNoInConfig = function(assFields,cfg,index,assIndexArr){
        var columns = [];
        _.each(cfg.rows,function(e){
            columns.push(e.columnName);
        });
        _.each(cfg.columns,function(e){
            columns.push(e.columnName);
        });
        columns = _.uniq(columns);
        var addColumns = [];
        _.map(assFields, function (field,i) {
            var dIndex = "d" + (index + 1); //关联关系中用d1 d2...区分数据集
            var col = field[dIndex];
            if(columns.indexOf(col)<0&&assIndexArr.indexOf(i)>-1){
                let column = {
                    columnName:col,
                    filterType: "eq",
                    values: []
                };
                addColumns.push(column);
            }
        });
        return addColumns;
    };
    // 5. 移除多源数据集中可选表达式的-d标识符
    removeExpSign = function (values, datasetIds) {
        var valData = _.map(values, function (v) {
            v.cols = _.map(v.cols, function (e) {
                // let exp = e.exp;
                for (let m = 0; m < datasetIds.length; m++) {
                    let cdId = "-d" + (m + 1);
                    if (e.exp) {
                        e.exp = e.exp.toString().replace(new RegExp(cdId, "gm"), '');
                    }
                }
                return e;
            });
            return v;
        });
        return valData;
    };
    // 6. 依据子数据集Id拆分图表配置,获取归属子数据集的配置
    getChildrenDatasetConfig = function (datasetIds, widgetConfig,parentId,datasetData) {
        var childrensConfig = [];
        _.map(datasetIds, function (e) {
            var config = {
                dId: e,
                selects: [],
                chart_type: widgetConfig.chartType,
                boardFilters:[],
                filters: [],
                groups: [],
                keys: [],
                values: [],
                option: widgetConfig.option,
            };
            childrensConfig.push(config);
        });

        //分割过滤器
        // if (widgetConfig.filters) {
        //     _.map(widgetConfig.filters, function (e) {
        //         var copyE = util.clone(e);
        //         var arr = _.groupBy(e.filters, 'dId');
        //         _.map(datasetIds, function (a, index) {
        //             var f = util.clone(arr[a]);
        //             if (f && f.length > 0) {
        //                 copyE.filters = util.clone(arr[a]);
        //                 childrensConfig[index].filters.push(util.clone(copyE));
        //             }
        //         });
        //     });
        // }

        //分割过滤器
        if (widgetConfig.filters) {
            var arr = _.groupBy(widgetConfig.filters, 'dId');
            _.map(datasetIds, function (a, index) {
                var f = util.clone(arr[a]);
                if (f && f.length > 0) {
                    childrensConfig[index].filters = f;
                }
            });
       }
        //分割行维
        if (widgetConfig.groups) {
            var arr = _.groupBy(widgetConfig.groups, 'dId');
            _.map(datasetIds, function (e, index) {
                var a = arr[e]
                if (arr[e] && arr[e].length > 0) {
                    childrensConfig[index].groups = childrensConfig[index].groups.concat(arr[e]);
                }
            });
        }
        //分割列维
        if (widgetConfig.keys) {
            var arr = _.groupBy(widgetConfig.keys, 'dId');
            _.map(datasetIds, function (e, index) {
                if (arr[e] && arr[e].length > 0) {
                    childrensConfig[index].keys = childrensConfig[index].keys.concat(arr[e]);
                }
            });
        }
        //分割指标和可选表达式
        if (widgetConfig.values) {
            _.map(widgetConfig.values, function (e) {
                var copyE = util.clone(e);
                var arr = _.groupBy(e.cols, 'dId');
                _.map(datasetIds, function (a, index) {
                    var f = util.clone(arr[a]);
                    if (f && f.length > 0) {
                        //arr[-1]:类型为表达式
                        copyE.cols = util.clone(arr[-1] == undefined || arr[-1].length == 0 ? arr[a] : arr[a].concat(arr[-1]));
                        childrensConfig[index].values.push(util.clone(copyE));
                    } else if (arr[-1] && arr[-1].length > 0) {
                        copyE.cols = util.clone(arr[-1]);
                        childrensConfig[index].values.push(util.clone(copyE));
                    }
                });
            });
        }
        //分割看板过滤器
        if (widgetConfig.boardFilters) {
            _.map(widgetConfig.boardFilters, function (e) {
                let colData = getBelongDatasetByCol(datasetData,e.col);
                let bfCol = {
                    values:e.values,
                    col:colData.columnName,
                    type:e.type
                };
                childrensConfig[colData.dIndex].boardFilters.push(bfCol);
            });
        }
        // console.log("childrensConfig",JSON.stringify(childrensConfig));
        // console.log("datasetData",JSON.stringify(datasetData));
        // console.log("widgetConfig",JSON.stringify(widgetConfig));
        // console.log("childrensConfig",childrensConfig);
        // console.log("widgetConfig",widgetConfig);
        // console.log("datasetData",datasetData)
        return childrensConfig;
    };
    /**7. 将values中的指标 以及归属于第dIndex个数据集的表达式返回
     * ------------多源数据集中表达式所属数据集用-d1 -d2来进行标志
     * */
    getCfgFilter = function (data, dIndex) {
        dIndex += 1;
        var result = _.filter(data, function (e) {
            var arr = e.column.split("-d");
            //不包含-d则是指标
            if (arr.length == 1) {
                return e;
            } else if (arr.length == 2 && arr[1] == dIndex) {
                e.column = arr[0];
                return e;
            }
        });
        //去重
        var unqFilter =_.uniq(result,function(e){
            return e.column+e.aggType;
        });
        return unqFilter;
    }
}

var dataContextMerge = new DataContextMerge();
export default dataContextMerge;

/**
 * Created by  jaray  2018-11-28
 */
import { dataService, boardService, widgetService,userService,behaviorLogService } from '../../../../server';
import './_layout.scss';
export default function oldLayoutCrtl($rootScope,  $state,$scope, ModalUtils, $filter, ParamFilterModal,$stateParams, toastr, widgetListModal, boardListModal) {
    var translate = $filter('translate');
    // $scope.optFlag = 'none';//操作方式 新增修改查看。。
    $scope.board = { layout: { rows: [] } };
    $scope.board.layout.cache = {
        dataCache: {
            type: 'board',
            isCache: false,
            expireTime: 0
        },
        dimensionCache: {
            type: 'board',
            isCache: true,
            expireTime: '1,天'
        }
    };
    $scope.board.layout.watermark = {
        isShow: false,
        customText: '',
        isShowDate: true,
        isShowUsername: true
    };
    $scope.loading = false; //加载board-view时board已经加载完
    $scope.alerts = [];
    $scope.hasChildern = false;
    $scope.curCategory = {};
    $scope.verifyBoard = { boardName: true };
    $scope.verifyCategory = { categoryName: true };
    $rootScope.freeLayout = false;//暂时无
    $scope.treeData = [];
    //通过参数初始化
    $scope.caseType = $stateParams.caseType;
    $scope.optFlag = $stateParams.optFlag || 'edit';
    var paramBoardId = $stateParams.boardId;

    $scope.selectOption = "widget";
    $scope.boardDisplayType = 'layout';//
    $scope.intervalGroup = {};
    // $scope.showLayout = "row";//左侧展示行还是列
    //=========================右侧渲染区域开始=================================//


    $scope.editBoard = function (board) {
        //updateService.updateBoard(b);
        if (board.layout === undefined) return;
        _.each(board.layout.rows, function (row) {
            if (!row.type) {
                row.type = 'widget';
            }
        });
        $scope.optFlag = 'edit';
    };
    var initOther = function () {
        dataService.getDataSetList()
            .then(function (response) {
                $scope.datasetList = response;
            })
        boardService.getCategoryList().then(function (data) {
            $scope.categoryFolderList = data;
        })
        widgetService.getWidgetList().then(function (response) {
            $scope.widgetList = response;
            //拼接,根据图表中数据集id,获取数据集名称,并作为dataset拼入图表中
            $scope.widgetList = $scope.widgetList.map(function (w) {
                if (w.data.datasetId != null) {
                    var dataset = _.find($scope.datasetList, function (ds) {
                        return ds.id == w.data.datasetId;
                    });
                    w.dataset = dataset == null ? 'Lost DataSet' : dataset.name;
                } else {
                    w.dataset = "Query";
                }
                return w;
            });
        });
        boardService.getBoardList().then(function (data) {
            $scope.boardList = data
        }, err => { })
    };
    initOther();

    var loadDataSet = function (params) {
        dataService.getDataSetList().then(function (dataset) {
            $scope.datasetList = dataset
            // getOriginBoardData();
        })
    }
    loadDataSet();
    var groupTimeline = function () {
        var timeline = [];
        var group = undefined;
        _.each($scope.board.layout.rows, function (row, idx) {
            if (idx == 0) {
                $scope.timelineFilter = row;
                return;
            }
            if (row.type != 'widget') {
                return;
            }
            row.show = false;
            if (!row.rdx) row.rdx = createRandomId();
            if (row.node == 'parent') {
                if (group) {
                    timeline.push(group);
                }
                group = [];
                row.show = true;
            }
            group.push(row);
        });
        timeline.push(group);
        $scope.timeline = timeline;
    };
    var loadBoard = function (reload) {
        //如果是timeline
        if (!reload && $scope.board.layout.type == 'timeline') {
            groupTimeline();
        }
    }

    $scope.mouseoverColumn = function ($event) {
        $($event.target).children("*").addClass("selected-hover");
        // console.log("mouseoverColumn",$event.target)
        $(".layout-page").find("*").removeClass("selected-data");
        // $($event.target).find(".chart-panel").addClass("select-background");
    }

    $scope.scrollIntoView = function (rdx, wdx) {

    }

    $scope.mouseleaveRow = function (rdx, $event) {
        var rowId = "layoutRow_" + rdx;
        $(".layout-page #" + rowId).removeClass("selected-data")
        $(".layout-page #" + rowId).find(".panel ,.chart-panel ,#selected-data ").removeClass("selected-data")
    }

    $scope.mouseoverRow = function (rdx, $event) {
        var rowId = "layoutRow_" + rdx;
        $(".layout-page #" + rowId).addClass("selected-data")
        $(".layout-page #" + rowId).find(".panel ,.chart-panel ,#selected-data").addClass("selected-data")
    }
    //=========================右侧渲染区域结束=================================//


    var initOther = function () {
        dataService.getDataSetList()
            .then(function (response) {
                $scope.datasetList = response;
            })
        boardService.getCategoryList().then(function (data) {
            $scope.categoryFolderList = data;
        })
        widgetService.getWidgetList().then(function (response) {
            $scope.widgetList = response;
            //拼接,根据图表中数据集id,获取数据集名称,并作为dataset拼入图表中
            $scope.widgetList = $scope.widgetList.map(function (w) {
                if (w.data.datasetId != null) {
                    var dataset = _.find($scope.datasetList, function (ds) {
                        return ds.id == w.data.datasetId;
                    });
                    w.dataset = dataset == null ? 'Lost DataSet' : dataset.name;
                } else {
                    w.dataset = "Query";
                }
                return w;
            });
        });
        boardService.getBoardList().then(function (data) {
            $scope.boardList = data;
        }, err => { })
    };
    initOther();


    var boardChange = function () {
        $scope.verifyBoard = { boardName: true };
        $scope.$emit("boardChange");
    };



    $scope.newOperate = function () {
        $('div.newBoard').toggleClass('hideOperate');
    };

    //网格布局
    $scope.newGridLayout = function () {
        // $scope.caseType = 'file';
        $rootScope.freeLayout = false;
        $scope.optFlag = 'new';
        $scope.board = { layout: { rows: [] } };
        $scope.board.layout.cache = {
            dataCache: {
                type: 'board',
                isCache: false,
                expireTime: 0
            },
            dimensionCache: {
                type: 'board',
                isCache: true,
                expireTime: '1,天'
            }
        };
        $scope.board.layout.watermark = {
            isShow: false,
            customText: '',
            isShowDate: true,
            isShowUsername: true
        };
        $scope.board.categoryId = $scope.categoryId;
        $('div.newBoard').addClass('hideOperate');
    };

    //时间轴布局
    $scope.newTimelineLayout = function () {
        $scope.optFlag = 'new';
        $scope.board = {
            layout: {
                type: 'timeline',
                rows: [{
                    height: '',
                    params: [],
                    type: 'param'
                }]
            }
        };
        $scope.board.layout.cache = {
            dataCache: {
                type: 'board',
                isCache: false,
                expireTime: 0
            },
            dimensionCache: {
                type: 'board',
                isCache: true,
                expireTime: '1,天'
            }
        };
        $scope.board.layout.watermark = {
            isShow: false,
            customText: '',
            isShowDate: true,
            isShowUsername: true
        };
        $scope.board.categoryId = $scope.categoryId;
        $('div.newBoard').addClass('hideOperate');
    };

    // 展示文件信息详情
    $scope.showInfo = function () {
        //目录无法展示
        if ($scope.editType != 'file') {
            ModalUtils.alert('请选择文件', "modal-warning", "sm");
            return;
        }
        if (!checkTreeNode("info")) return;
        var content = getSelectedBoard();
        ModalUtils.info(content, "modal-info", "lg");
    };

    //添加图表
    $scope.addWidget = function (row) {
        var w = {};
        w.name = translate('CONFIG.DASHBOARD.CHART_NAME');
        w.width = 12;
        w.widgetId = $scope.widgetList[0].id;
        w.widget = $scope.widgetList[0];
        w.show = true;
        row.widgets.push(w);
        $scope.enterWidgetdetail(row.widgets.length - 1);
        loadBoard();
    };

    //添加行
    $scope.addRow = function () {
        var row = { type: 'widget', widgets: [] };
        $scope.board.layout.rows.push(row);
        var length = $scope.board.layout.rows.length - 1;
        $scope.selectedLayoutRdx = length > 0 ? $scope.board.layout.rows.length - 1 : 0;
        loadBoard();
        return row;
    };

    $scope.delRow = function (index) {
        $scope.board.layout.rows.splice(index, 1);
        $scope.selectedLayoutRdx = null;
        loadBoard();
    }
    $scope.delRows = function () {
        $scope.board.layout.rows = [];
        $scope.selectedLayoutRdx = null;
        loadBoard();
    }

    //添加节点
    $scope.addNode = function (node) {
        var rdx = createRandomId();
        $scope.board.layout.rows.push({ node: node, type: 'widget', rdx: rdx, widgets: [] });

        loadBoard();
        $scope.selectedLayoutRdx = rdx;
        $scope.showLayout='row';
    };

    $scope.delNode = function (row, index) {
        $scope.board.layout.rows.splice(index, 1);
        $scope.selectedLayoutRdx = null;
        loadBoard();
    }

    //节点布局选择列
    $scope.selectColumn = function(index){
        $scope.showLayout = 'column';
        $scope.selectedColumn = index;
    }

    //添加参数行
    $scope.addPramRow = function () {
        $scope.board.layout.rows.unshift({ type: 'param', params: [] });
        $scope.selectedLayoutRdx =null;
        loadBoard();
    };

    $scope.addRelations = function (widget) {
        widget.relations = {};
        widget.relations.relations = [];
        $scope.changeSourceCol(widget, widget.widgetId);
        loadBoard();
    };

    $scope.enterWidgetdetail = function (index) {
        $scope.showLayout = 'column';
        $scope.selectedColumn = index;
    }

    $scope.enterRowdetail = function ( index) {
        $scope.showLayout = 'row';
        $scope.selectedColumn=index;
        // console.log(index)
    }

    $scope.selectRow = function (index) {
        $scope.showLayout = 'row';
    }

    $scope.rowList = function () {
        $scope.showLayout = 'boardrow';
        $scope.selectedLayoutRdx=null;
    }
    $scope.rowList()


    $scope.delRelations = function (widget) {
        if (widget.relations) {
            delete widget.relations;
        }
        loadBoard();
    };

    //校验看板是否正确
    var validateBoard = function () {
        if (!$scope.board.name || !$scope.board.categoryId) {
            ModalUtils.alert("目录和文件不能为空", "modal-warning", "sm");
            return false;
        }
        return true;
    };

    //保存看板后执行事件
    function saveBoardCallBack(serviceStatus) {
        if (serviceStatus.status == '1') {
            // getBoardList();
            if (!$scope.board.id) {
                $scope.board.id = serviceStatus.id;
            }
            $scope.optFlag = 'edit';
            toastr.success(serviceStatus.msg)
            // ModalUtils.alert(serviceStatus.msg, "modal-success", "sm");
            // boardChange();
        } else {
            toastr.error(serviceStatus.msg)
            // ModalUtils.alert(serviceStatus.msg, "modal-warning", "sm");
        }
    }

    //保存看板
    $scope.saveBoard = function (callback_fun) {
        if (!validateBoard()) {
            return;
        }
        if ($scope.board.layout && $scope.board.layout.rows && $scope.board.layout.type == 'carouse') {
            setCarouseBoardIds();
        }
        var sboard = angular.copy($scope.board)
        delete sboard.timeline;
        clearDirty(sboard);
        var callBack =callback_fun;
        if (!callBack) {
            callBack = saveBoardPreview;
        }
        if ($scope.optFlag == 'new') {
            return boardService.saveNewBoard({ json: angular.toJson(sboard) }).then(callBack);
        } else if ($scope.optFlag == 'edit') {
            userService.judgePermission({resType:'board',resId:sboard.id,option:'update'}).then(function(data){
                if(data){
                    return boardService.updateBoard({ json: angular.toJson(sboard) }).then(callBack);
                }else{
                    toastr.error("您没有修改数据的权限！");
                }
            },err=>{
                toastr.error("您没有修改数据的权限！");
            });
        }
    };

    $scope.viewBoard=function(){
        $state.go('display', {id:  $scope.board.id});
    }
    function saveBoardPreview(serviceStatus) {
        if (serviceStatus.status == '1') {
            if (!$scope.board.id) {
                $scope.board.id = serviceStatus.id;
            }
            $scope.optFlag = "edit";
            toastr.success(serviceStatus.msg)
        } else {
            toastr.error(serviceStatus.msg)
        }
    }

    var setCarouseBoardIds = function () {
        $scope.board.layout.boardIds = new Array;
        _.each($scope.board.layout.rows, function (e) {
            $scope.board.layout.boardIds.push(e.carouses[0].boardId);
        });
    }


    var clearDirty = function (curBoard) {
        _.each(curBoard.layout.rows, function (row) {
            _.each(row.widgets, function (widget) {
                delete widget.sourceId;
                if (!_.isUndefined(widget.relations)) {
                    delete widget.relations.sourceFields;
                    _.each(widget.relations.relations, function (relation) {
                        delete relation.targetFields;
                    });
                }
            });
        })
    };

    $scope.delGridParam = function (index) {
        $scope.board.layout.rows.splice(index, 1)
        $scope.selectedLayoutRdx = null;
        loadBoard();
    }

    $scope.editParam = function (row, index) {
        var ok;
        var param;

        if (_.isUndefined(index)) {
            param = { col: [] };
            ok = function (p) {
                if (!row.params) {
                    row.params = [];
                }
                row.params.push(p);
            };
        } else {
            param = angular.copy(row.params[index]);
            ok = function (p) {
                row.params[index] = p;
                // console.log("p",p)
            };
        }
        var params = {}
        params.param = param
        params.curBoard = $scope.board;
        params.widgetList = $scope.widgetList;
        params.datasetList = $scope.datasetList;

        ParamFilterModal.openSetter(params, ok);
        loadBoard();
    };
    $scope.editParamValue = function (row, index) {
        var ok;
        var param;
        if (_.isUndefined(index)) {
            param = { col: [] };
            ok = function (p) {
                if (!row.params) {
                    row.params = [];
                }
                row.params.push(p);
            };
        } else {
            param = angular.copy(row.params[index]);
            ok = function (p) {
                row.params[index] = p;
            };
        }
        ParamFilterModal.openSelector(param, ok);
    };

    //选择图表列
    $scope.changeWidget = function (selected, widget) {
        widget.widget = selected;
        widget.name = selected.name;
        widget.widgetId = selected.id;
        // $scope.$apply()
        loadBoard();
    }

    $scope.getBoardData =function(){
        $scope.loading = true;
        userService.judgePermission({resType:'board',resId:paramBoardId,option:'query'}).then(function(data){
            if(!data){
                toastr.error("您没有查看看板的权限！");
                return;
            }
            boardService.getBoardData(paramBoardId).then(function (board) {
                if (board) {
                    $scope.board = board;
                    if(!$scope.board.layout.cache) {
                        $scope.board.layout.cache = {
                            dataCache: {
                                type: 'board',
                                isCache: false,
                                expireTime: 0
                            },
                            dimensionCache: {
                                type: 'board',
                                isCache: true,
                                expireTime: '1,天'
                            }
                        };
                    }
                    if(!$scope.board.layout.watermark) {
                        $scope.board.layout.watermark = {
                            isShow: false,
                            customText: '',
                            isShowDate: true,
                            isShowUsername: true
                        };
                    }
                    $scope.caseType = 'file';
                    loadBoard();
                    $scope.editBoard(board);
                    $scope.loading = false;
                    try {behaviorLogService.insertLog({module:"board",function:"查看看板",name:$scope.board.name})} catch (error) {}
                }
                if (!board || !board.id) {
                    ModalUtils.alert("看板已被删除或未被赋予权限!", "modal-warning", "sm");
                    return;
                }
                $scope.$apply();
            });
        })

    }

    var getOriginBoardData = function () {
        if ($scope.optFlag != 'new') {//通过paramBoardId  判断是新增还是修改
            $scope.getBoardData();
        } else {
            if (!_.isUndefined($stateParams.categoryId)) {
                $scope.categoryId = $stateParams.categoryId;
            }
            var type = 'grid' //默认网格布局
            if (!_.isUndefined($stateParams.layoutType)) {
                type = $stateParams.layoutType
            }
            switch (type) {
                case 'grid':
                    $scope.newGridLayout()
                    break;
                case 'carouse':
                    $scope.newCarouselLayout()
                    break;
                case 'cockpit':
                    //$state.go("config.cockpit");
                    return
                case 'timeline':
                    $scope.newTimelineLayout();
                    break;
            }
            // loadBoard();
        }
    }
    getOriginBoardData();

    $scope.changeSourceCol = function (e, widgetId) {
        if (!e.relations) {
            return;
        }
        //源表字段默认为原表的group key指定字段
        // $http.get("api/bcp/dashboard/dashboardWidget?id=" + e.widgetId)
        boardService.getBoardWidget(e.widgetId)
            .then(function (response) {
                if (!response) {
                    return false;
                }
                var config = response.data.config;
                var fields = [];
                _.each(config.groups, function (e) {
                    fields.push(e.col);
                });
                _.each(config.keys, function (e) {
                    fields.push(e.col);
                });
                if (!e.relations.sourceField || e.relations.sourceField.length <= 0) {
                    e.relations.sourceField = fields;
                }
                e.relations.sourceFields = fields;
            });
    };

    $scope.addWidgetRelation = function (widget) {
        widget.relations.relations.push({ "type": "widget" });
        // $scope.getCurWidgets();
        $('div.newRelation').addClass('hideOperate');
    };

    $scope.addBoardRelation = function (widget) {
        widget.relations.relations.push({ "type": "board" });
        $('div.newRelation').addClass('hideOperate');
    };

    //
    $scope.toggleRelationSelectType = function (relation) {
        if(!relation.selectType || relation.selectType == '单选') {
            relation.selectType = '多选';
        }else{
            relation.selectType = '单选';
        }
    }

    $scope.changeActive = function (rowIndex, widgetIndex, index) {
        var prefixId = rowIndex + "_" + widgetIndex + "_";
        var list = $('li[id^=' + prefixId + '].active');
        if (list.length > 0 && list[0].id.split("_")[2] != index) {
            return;
        }
        if (index - 1 < 0) {
            index = 0;
        } else {
            index = index - 1;
        }
        $("#" + prefixId + index + "_" + "tab").addClass('active');
        $("#" + prefixId + index + "_" + "content").addClass('active');
    };

    // ===========layoutit===========
    $scope.selectLayoutRow = function (row, rdx) {
        $scope.selectedLayoutRdx = rdx;
        $scope.showLayout = 'row';
    }

    $scope.selectLayoutColumn = function (rdx, cdx) {
        $scope.selectedLayoutRdx = rdx;
        $scope.enterWidgetdetail(cdx);
    }

    $scope.getCurWidgets=()=>{
      let widgets =[]
      _.each($scope.board.layout,(row)=>{
          _.each(row,(item)=>{
             if(item.type=='widget'){
                widgets=  widgets.concat(item.widgets);
                //  widgets.push(item.widgets)
             }
          })
      })
      $scope.curWidgets=widgets;
    //   return widgets;
    }

    //生成唯一随机数
    var createRandomId = function () {
        return (Math.random() * 10000000).toString(16).substr(0, 4) + '_' + (new Date()).getTime() + '_' + Math.random().toString().substr(2, 5);
    };
    //清除事件冒泡的方法
    $scope.stopBubbling = function(e){
        e.stopPropagation();
    };

    //开始拖拽--将$scope.board.layout.rows的组件元素拉到当前组件的前面
    $scope.startDragRow =function(index){
        //if($scope.board.layout.rows[index].type != "param") {
            $scope.movingRowIndex = index;
        //} else {
            //$scope.movingRowIndex = "";
        //}
    };
    //完成拖拽;
    $scope.onDropRowComplete =function(index){
        //if($scope.board.layout.rows[index].type != "param") {
            if($scope.movingRowIndex >= 0 && $scope.movingRowIndex != index){
                var data = $scope.board.layout.rows.splice($scope.movingRowIndex, 1)[0];
                if(index < $scope.movingRowIndex) {
                    $scope.board.layout.rows.splice(index, 0, data);
                } else if(index > $scope.movingRowIndex) {
                    $scope.board.layout.rows.splice(index-1, 0, data);
                }
            }
        //}
    };

    //完成拖拽----通用方法--需传入列表
    $scope.onDropRowCompleteCommon =function(list,index){
            if($scope.movingRowIndex >= 0 && $scope.movingRowIndex != index){
                var data = list.splice($scope.movingRowIndex, 1)[0];
                if(index < $scope.movingRowIndex) {
                    list.splice(index, 0, data);
                } else if(index > $scope.movingRowIndex) {
                    list.splice(index-1, 0, data);
                }
            }
    };

    //选择看板图表组的图表
    $scope.selectWidget = function(widget, w_selected) {
        var func_ok = function(data) {
            $scope.changeSourceCol(widget, data.id);
            $scope.changeWidget(data, widget);
            w_selected.selected = data.id;
        };
        widgetListModal.openWidgetList(widget.widgetId, func_ok, $scope.widgetList);
    };

    //关联图表选择被关联的图表
    $scope.selectRelationWidget = function(widget, relation, index, row) {
        var func_ok = function(data) {
            $scope.changeTargetCol(widget, data.id, index, row);
            relation.name = data.name;
            relation.targetId = data.id;
        };

        widgetListModal.openWidgetList(relation.targetId, func_ok, $scope.widgetList);
    };

    $scope.changeTargetCol = function (e, widgetId, index, row) {
        if (!e.relations) {
            return;
        }
        var w = _.find($scope.widgetList, function (w) {
            return w.id == widgetId;
        });
        if (!w) {
            return;
        }
        var dataSet = _.find($scope.datasetList, function (e) {
            return w.data.datasetId === e.id;
        });
        var cols = [];
        _.each(dataSet.data.schema.dimension, function (e) {
            if (e.type == "column") {
                if ($.inArray(e, cols) == -1) {
                    cols.push(e.column);
                }
            } else if (e.type == "level") {
                _.each(e.columns, function (e) {
                    if ($.inArray(e, cols) == -1) {
                        cols.push(e.column);
                    }
                });
            }
        });
        // 数组去重
        var oset = new Set(cols);
        var uniqueCols = [...oset];
        e.relations.relations[index].targetFields = uniqueCols;
        if (cols.length == 0) {
            dataService.getColumns({
                datasource: null,
                query: null,
                datasetId: w.data.datasetId,
                callback: function (dps) {
                    $scope.alerts = [];
                    if (dps.msg == "1") {
                        e.relations.relations[index].targetFields = dps.columns;
                    } else {
                        $scope.alerts = [{ msg: dps.msg, type: 'danger' }];
                    }
                }
            });
        }

        //row参数不存在，表示是初始化,不执行之后的代码
        if(_.isUndefined(row)) {
            return;
        }
        //row存在,判断上次操作是否有添加,有就删掉---不考虑同组中图表重复出现且均设置关联且序号相同的情况
        row.widgets = _.filter(row.widgets, function(item) {
            //以下两项键满足其一，保留在看板对象中，键值对不会保留在后头数据中----前者标识当前图表，后者为开启的第n个关联对象
            return item.sourceId !== e.widgetId || item.index !== index;
        });
        //将选中的维度清掉
        e.relations.relations[index].targetField = [];
        //判断是否在当前看板中---flag增加了表示搜索到
        var flag = 0;
        _.each($scope.board.layout.rows, function (row) {
            if(row.widgets) {
                _.each(row.widgets, function(widget){
                    if(widget.widgetId == widgetId) {
                        flag++;
                    }
                });
            }
        });
        if(flag > 0) {
            return;
        }

        var wc = {};
        wc.name = w.name;
        wc.widget = w;
        wc.width = 12;
        wc.widgetId = widgetId;
        wc.sourceId = e.widgetId;
        wc.index = index;
        row.widgets.push(wc);
        loadBoard();
    };

    //关联图表选择被关联的图表
    $scope.selectRelationBoard = function(widget, relation, index) {
        var func_ok = function(data) {
            $scope.changeTargetParam(widget, data.id, index, true);
            relation.name = data.name;
            relation.targetId = data.id;
        };

        boardListModal.openBoardList(relation.targetId, func_ok);
    };

    $scope.changeTargetParam = function (e, boardId, index, isNoInit) {
        if (!e.relations) {
            return;
        }
        if(_.isUndefined(boardId)) return;
        boardService.getBoardData(boardId).then(function (w) {
            if (!w) {
                return;
            }
            var cols = [];
            _.each(w.layout.rows, function (row) {
                if (row.type == "param") {
                    _.each(row.params, function (param) {
                        _.each(param.col, function (col) {
                            if ($.inArray(param.name, cols) == -1) {
                                cols.push(param.name); //col.column+"("+col.datasetId+")"
                            }
                        });
                    });
                }
            });
            //e.relations.relations[index].targetField = [];
            e.relations.relations[index].targetFields = cols;
            //将选中的维度清掉
            if(isNoInit) {
                e.relations.relations[index].targetField = [];
            }
            $scope.$apply();
        })
    };

    $scope.$on('$destroy', function(){
        // 离开时还原网页标题
        document.title = 'ourbore分析云';
    });
    $scope.$watch('board.name', (newValue)=>{
      if(newValue) {
        document.title = newValue;
      } else {
        if ( $scope.optFlag == 'new' ) {
          document.title = '新建看板';
        } else {
          document.title = '编辑看板';
        }
      }
    });
}

import { dataService, collectionService,boardService, userService } from '../../../../server'
import { storage } from '../../../../common/utils';
import timerEditTpl from '../../timerPush/timerEdit/timerEdit.html'
import timerEditCtrl from '../../timerPush/timerEdit/timerEditCtrl'
import windowTpl from '../../../../common/theme/components/modal/window.html'
import './_boardList.scss'
import { fullCalendar } from 'jquery';
export default function baordListCtrl($scope, $rootScope, $filter, $http, ModalUtils,  toastr,$state,$uibModal,$stateParams) {
    var translate = $filter('translate');
    //当前所在位置
     $scope.curPage = [];
     $scope.categoryId = null;
     $scope.showCardFrame = true;
     $scope.categoryArr = [];
     $scope.pageNo = 1;
     $scope.pageSize = 35;
     $scope.isLoading = true;
     $scope.search = {value:""}

    var getCategoryList = function () {
        $scope.childrenCategory = [];
        $scope.categoryId = null;
        boardService.getCategoryList().then(function(response) {
            $scope.categoryList = response;
            $scope.curCategory = [];
            $scope.childrenCategory = _.filter($scope.categoryList, function (e) {
                return e.parentId === "0"
            })
            if($stateParams.category){
                var category = _.find($scope.categoryList, function (e) {
                    return e.id === $stateParams.category
                })
                $scope.selectCategory(category)
            }
            $scope.$apply();
        })
    }
    getCategoryList();
    //选择目录
    $scope.selectCategory = function(category){
        $scope.curCategory = [];
        $scope.childrenCategory = [];
        if(category){
            var curParentIds = category.parentIds.split(",");
            _.each($scope.categoryList, function (e) {
                if (e.parentId && e.parentId == category.id) {
                    $scope.childrenCategory.push(e);
                }
                if(curParentIds.indexOf(e.id) > -1){
                    $scope.curCategory.push(e);
                }
            })
            $scope.curCategory.push(category);
            $scope.categoryId = category.id;
            $scope.selectedCategory = category;
            $scope.getBoardList();
        }else{
            $scope.curCategory = [];
            $scope.childrenCategory = _.filter($scope.categoryList, function (e) {
                return e.parentId === "0"
            })
            $scope.categoryId =null;
            $scope.selectedCategory = null;
            $scope.getBoardList();
        }
    }



     //前往展示页面
     $scope.gotoDisplay = function(board){
        userService.judgePermission({
            option:"query",
            resType:"board",
            resId:board.id
        }).then(function(data){
            if(data){
                setBrowseRecords(board)
                $state.go('display', {id: board.id});
            }else{
                toastr.error("您没有查看该看板权限！")
            }
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })

    }


    //记录浏览历史记录
    var setBrowseRecords = function (board) {
        if (!board) return;
        var userInfo = JSON.parse(window.localStorage.getItem("userInfo"))

        var browseRecord = {
            type:'board',
            id:board.id,
            name:board.name,
            categoryId:$scope.categoryId,
            categoryName:$scope.categoryName,
            userId:userInfo.id,
            companyId:userInfo.companyId,
            dataTime:Date.parse(new Date())
        }
        var browseRecords = storage.get('browseRecords') || [];
        browseRecords.unshift(browseRecord);
        // if(browseRecords.length >20)browseRecords = browseRecords.slice(0,20);
        storage.set("browseRecords",browseRecords)
    }

    $scope.editBoard = function (id, boardType) {
        if (boardType === 'cockpit') {
            // $state.go('config.cockpit', {
            //     boardId: id
            // });
            toastr.error("看板类型错误！")
        }else if(boardType == 'carouse') {
            // $state.go('config.board', {
            //     boardId: id
            // });
            toastr.error("看板类型错误！")
        }else {
            userService.judgePermission({option:"update",resType:"board",resId:id}).then(function(data){
                if(!data){
                    toastr.error("您没有编辑该看板权限！")
                    return;
                }
                $state.go('layout', {boardId: id});
            },err=>{
                toastr.error(err.head.errorMsg || '获取权限失败！')
            })

        }
    }

    // $scope.copyBoard = function (board) {
    //     var o = angular.copy(board);
    //     o.name = o.name + '_copy';
    //     if (o.layout && o.layout.cockpitConf && o.layout.cockpitConf.viewName) {
    //         o.layout.cockpitConf.viewName = o.layout.cockpitConf.viewName + '_copy';
    //     }
    //     boardService.saveNewBoard( { json: angular.toJson(o) }).then(saveBoardCallBack);
    // };

    var getSaveBoard =function(board){
        boardService.getBoardData(board.id).then(function(response){
            var o = angular.copy(response);
            o.name = o.name + '_copy';
            if (o.layout && o.layout.cockpitConf && o.layout.cockpitConf.viewName) {
                o.layout.cockpitConf.viewName = o.layout.cockpitConf.viewName + '_copy';
            }
            boardService.saveNewBoard( { json: angular.toJson(o) }).then(function(response){
                $scope.getBoardList();
                if(response.status!="1"){
                  toastr.error(response.msg||"复制看板失败!")
                  return;
                }
                toastr.success("复制看板成功！")
            },err=>{
                toastr.error(err && err.head.errorMsg || "复制看板失败！")
            });
        },err=>{
            toastr.error(err.head.errorMsg || '获取数据失败')
        })
    }

    $scope.copyBoard = function(board){
        userService.judgePermission({option:"update",resType:"board",resId:board.id}).then(function(data){
            if(!data){
                toastr.error("您没有编辑该看板权限！")
                return;
            }
            getSaveBoard(board);
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
    }



    $scope.deleteBoard = function (board) {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-warning", "lg", function () {
            userService.judgePermission({option:"delete",resType:"board",resId:board.id}).then(function(data){
                if(!data){
                    toastr.error("您没有删除该看板权限！")
                    return;
                }
                boardService.deleteBoard( { id: board.id }).then(function (res) {
                    $scope.getBoardList();
                    toastr.success(res);
                    $scope.optFlag == 'none';
                },err=>{
                    toastr.error(err.head.errorMsg || '删除看板失败')
                });
            },err=>{
                toastr.error(err.head.errorMsg || '获取权限失败！')
            })

        });
    };

    $scope.deleteCategory = function (){
        if (!$scope.categoryId) {
            ModalUtils.alert("请选择要删除的目录", "modal-warning", "lg");
            return
        }else {
            ModalUtils.confirm("确定要删除"+$scope.selectedCategory.name+"目录吗？", "modal-warning", "lg", function() {
                if (deleteValidate($scope.categoryId)) {
                    boardService.deleteCategory({ id: $scope.categoryId }).then(function() {
                        $scope.optFlag = 'none';
                        getCategoryList();
                        toastr.success("删除成功!");
                    },err=>{
                        toastr.error(err.head.errorMsg || "删除失败!")
                    });
                }
            })
        }
    }
     //新建目录设定参数
     $scope.resetCurCategory = function (isEdit) {
        if(isEdit){
            if(!$scope.selectedCategory){
                toastr.warning("请选择要修改的目录!")
                return;
            }
            $('#boardCategoryModel').modal('show');
            $scope.boardCategory = angular.copy($scope.selectedCategory)
            return;
        }
        $scope.boardCategory = {
            parentId: $scope.categoryId || '',
            name: ''
        }
    }

    var checkBoardCategory = function () {
        //编辑时
        if($scope.boardCategory.id && !$scope.boardCategory.name){
            toastr.warning('目录名称不能为空!');
            return false
        }
        //新增时
        if (!$scope.boardCategory.id &&(!$scope.boardCategory.name || !$scope.boardCategory.parentId)) {
            toastr.warning('所属目录和目录名称不能为空!');
            return false;
        }
        return true;
    }

    $scope.saveCategory = function(){
        if(!checkBoardCategory()){
            return;
        }
        boardService.saveCategory($scope.boardCategory).then(function(response) {
            if (response.status == '1') {
                $('#boardCategoryModel').modal('hide');
                $scope.selectedCategory = null;
                // $scope.boardCategory = {id: $scope.categoryId || '',name: ''};
                getCategoryList();
                toastr.success(translate("COMMON.SUCCESS"));
            } else {
                $scope.alerts = [{ msg: response.msg, type: 'danger' }];
            }
        },err=>{
            toastr.error(err.head.errorMsg || "保存目录失败！")
        });
    }

     //新增看板
     $scope.addNewBoard = function (type) {
        // var curBoard = null;
        // var categoryId =$scope.categoryId || '';
        // switch (type) {
        //     case 'grid':
        //         curBoard = { categoryId:'', layout: { rows: [] }, type: 'grid' };
        //         break;
        //     case 'carouse':
        //         curBoard = { categoryId:'', layout: { type:'carouse',rows: [] } };
        //         break;
        //     case 'cockpit':
        //         $state.go("config.cockpit");
        //         return
        //     case 'timeline':
        //         curBoard = {
        //             categoryId:'',
        //             layout: {
        //                 type: 'timeline',
        //                 rows: [{
        //                     height: '',
        //                     params: [],
        //                     type: 'param'
        //                 }]
        //             }
        //         };
        //         break;
        // }
        // if(type=='timeline' || type=='grid'){

        //     // $state.go('layout', {id:null,
        //     //     layoutType:type, caseType:'file', optFlag: 'new', showSideList: false
        //     // })
        // }
        $state.go('layout', {optFlag: 'new', layoutType: type})
    }

    var deleteValidate = function(categoryId) {
        if ($scope.childrenCategory.length !== 0) {
            ModalUtils.confirm("该目录下含有子目录，无法删除", "modal-warning", "lg");
            return false;
        }
        if (!categoryId) {
            ModalUtils.confirm("请选择要删除的目录", "modal-warning", "lg");
            return false;
        }
        //三大固定目录无法删除
        var deleteList = ["0", "0001", "0002"];
        if (categoryId in deleteList) {
            ModalUtils.confirm("该目录无法删除", "modal-warning", "lg");
            return false;
        }
        if ($scope.curBoardList.length !== 0) {
            ModalUtils.confirm("该目录下含有看板，无法删除", "modal-warning", "lg");
            return false;
        }
        return true;
    }

    //获取当前目录下的所有子目录包括自己的id---根目录0不在$scope.categoryList列表里,已排除
    var getAllCategoryIdById = function(categoryId){
        var allCategoryIdById = [];
        _.each($scope.categoryList, function(category){
            if(categoryId == category.id) {
                allCategoryIdById.push(category.id);
            } else {
                var parentIds = [];
                parentIds = category.parentIds.split(",");
                if(parentIds.indexOf(categoryId) != -1){
                    allCategoryIdById.push(category.id);
                }
            }
        });
        return allCategoryIdById;
    };

    //获取包含子目录的看板
    $scope.getBoardList = function(search) {
        $scope.isLoading = true;
        var params = {
            // "pageNumber": $scope.paginationConf.currentPage,
            // "pageSize": $scope.paginationConf.itemsPerPage,
            // "categoryId":$scope.categoryId,
            //需要所有的子看板，先获取所有看板再分配
            "pageNumber": 1,
            "pageSize": 999,
            "categoryId": null,
            "search":$scope.search.value
        }
        //此获取的response所含看板只对搜索进行筛选
        boardService.getBoardColList(params).then(function(response) {
            var lastBoardIndex = $scope.paginationConf.currentPage * $scope.paginationConf.itemsPerPage;  //计划显示的最后一个看板的序号
            var firstBoardIndex = lastBoardIndex - $scope.paginationConf.itemsPerPage;                  //第一个
            //根目录包括所有获取的看板
            if ($scope.categoryId == null) {
                $scope.paginationConf.totalItems = response.totolCount;
                //人工分页
                $scope.curBoardList = response.listdata.slice(firstBoardIndex, lastBoardIndex);
            } else {
                //其它则需要计算
                var allBoard = response.listdata;
                var allCategoryIdById = getAllCategoryIdById($scope.categoryId); //当前及子目录id列表
                var selectedBoardList = [];                                      //存放以上目录的看板列表
                $scope.curBoardList =[];
                _.each(allBoard, function(board){
                    if(allCategoryIdById.indexOf(board.categoryId) != -1){
                        selectedBoardList.push(board);
                    }
                });
                //此时总数为以上目录的看板列表的长度
                $scope.paginationConf.totalItems = selectedBoardList.length;
                //人工分页
                $scope.curBoardList = selectedBoardList.slice(firstBoardIndex, lastBoardIndex);
            }
            // $scope.curBoardList = response.listdata;
            // $scope.paginationConf.totalItems = response.totolCount;
            $scope.isLoading = false;
            $scope.$apply();
        });
    };

    $scope.delCollection = function(param){
        collectionService.delCollection({
            collectionId:param.id,
            type:'board'
        }).then(function(response){
            // $scope.getCollectionList();
            $scope.getBoardList();
            $scope.$apply();
            toastr.success("取消收藏成功!")
        },err =>{
            toastr.error(err.head.errorMsg || '取消收藏失败')
        })
    }

    $scope.saveCollection = function(param){
        collectionService.saveCollection({
            collectionId:param.id,
            type:'board'
        }).then(function(response){
            $scope.getBoardList();
            $scope.$apply();
            toastr.success("收藏成功!")
        },err =>{
            toastr.error(err.head.errorMsg || '收藏失败')
        })
    }

    $scope.paginationConf = {
        currentPage: 1, //第几页
        totalItems: 0, //共几条
        itemsPerPage: 24, //每页最多几条
        onChange: function () {
            $scope.getBoardList();
        }
    };

    //看板推送
    $scope.timerPush = function (board) {
        userService.judgePermission({
            option:"update",
            resType:"board",
            resId:board.id
        }).then(function(data){
            if(data){
                $scope.openTimer(board);
            }else{
                toastr.error("您没有分享该看板权限！")
            }
        },err=>{
            toastr.error(err.head.errorMsg || '获取看板权限失败！')
        })
    };

    $scope.openTimer = function(board) {
        $uibModal.open({
            template: timerEditTpl,
            windowTemplate:windowTpl,
            size: 'lg',
            windowClass: 'pw-modal',
            resolve: {
                ok: function () {
                    return function () {

                    }
                },
                timer:function(){
                    return {
                        "shareDataId":board.id,
                        "shareDataName":board.name,
                        "type":"board",
                        "title": board.name+"看板推送",
                        "content":"您收到一个"+board.name+"看板推送,"+"点击${url}查看详情."
                    }
                },
                userList:function(){
                    return $scope.userList || null;
                }
            },
            controller: ['$scope', '$uibModal', 'toastr','ok', 'userList', '$uibModalInstance', 'ModalUtils', 'timer',timerEditCtrl]
        });
    }
}

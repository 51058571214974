import { userResService,boardService,userAdminService,appService,reportService,behaviorLogService,userService} from '../../../server'
import windowTpl from '../../../common/theme/components/modal/window.html'
import userSelectedNoPageTpl from '../user/userSelectedNoPage.html'
import PubSub from '../../../common/utils/PubSub'
import './userRes.scss'

export default function ($scope, $uibModal,  ModalUtils, $filter,toastr) {
    var translate = $filter('translate');
    $scope.optFlag;
    $scope.curUser;
    $scope.filterByRole = false;
    $scope.userKeyword = '';
    $scope.tab = 'board';
    $scope.search = {};
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 9999,
        onChange: function () {
            getUserList();//重新加载
        }
    };
    // userAdminService.isAdmin().then(response => {
    //     $scope.isAdmin = response;
    // })

    var getUserList = function () {

        var params = {
            "pageNo": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "loginName": $scope.searchLoginName,
            "name": $scope.searchName,
        }
        userAdminService.postUserList(params).then(response => {
            $scope.paginationConf.totalItems = response.totolCount;
            $scope.userList = response.listdata;
        })
    };


    getUserList();

    var getRoleList = function () {
        userResService.getRoleList().then(response => {
            $scope.roleList = response;
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg);
        })
    };

    var getUserRoleList = function () {
        userResService.getUserRoleList().then(response => {
            $scope.userRoleList = response;
        },err=>{
            toastr.error(err.head.errorMsg);
        })
    };
    getUserRoleList();

    $scope.tree = {
        menu: {},
        board: {},
        datasource: {},
        dataset: {},
        widget: {},
        job: {},
        app: {},
        report:{}
    };
    // $scope.tree.menu.resList = [{
    //     id: 'Menu',
    //     text: translate('ADMIN.MENU'),
    //     parent: '#',
    //     icon: 'fa fa-folder-open',
    //     state: {
    //         disabled: true
    //     }
    // }];
    $scope.tree.board.resList = [{
        id: 'Dashboard',
        text: "看板",
        parent: '#',
        icon: 'fa fa-folder-open',
        state: {
            disabled: true
        }
    }];
    $scope.tree.datasource.resList = [{
        id: 'Datasource',
        text: '数据源',
        parent: '#',
        icon: 'fa fa-folder-open',
        state: {
            disabled: true
        }
    }];
    $scope.tree.dataset.resList = [{
        id: 'Dataset',
        text: '数据集',
        parent: '#',
        icon: 'fa fa-folder-open',
        state: {
            disabled: true
        }
    }];
    $scope.tree.widget.resList = [{
        id: 'Widget',
        text: '图表',
        parent: '#',
        icon: 'fa fa-folder-open',
        state: {
            disabled: true
        }
    }];
    $scope.tree.job.resList = [{
        id: 'Job',
        text: '任务',
        parent: '#',
        icon: 'fa fa-folder-open',
        state: {
            disabled: true
        }
    }];
    $scope.tree.app.resList = [{
        id: 'App',
        text: "应用",
        parent: '#',
        icon: 'fa fa-folder-open',
        state: {
            disabled: true
        }
    }];
    $scope.tree.report.resList = [{
        id: 'Report',
        text: "报表",
        parent: '#',
        icon: 'fa fa-folder-open',
        state: {
            disabled: true
        }
    }];
    var getBoardList = function () {
        return boardService.getBoardList().then(function (response) {

            boardService.getCategoryList().then(function (categoryFolder) {
                var folderList = categoryFolder.concat(response);
                var boardFolderList = folderList.map(function (ds) {
                    var categoryName = ds.categoryName == null ? translate('CONFIG.DASHBOARD.MY_DASHBOARD') : ds.categoryName;
                    return {
                        "id": ds.id,
                        "name": ds.name,
                        "categoryId": ds.categoryId,
                        "parentId": ds.parentId
                    };
                });
                _.each(buildBoardByCategory(boardFolderList, 'Dashboard', 'board', 'fa fa-dashboard'), function (e) {
                    $scope.tree.board.resList.push(e);

                })
                $scope.$apply();
            })
        });
    };

    $scope.inserBehaviortLog =function(){
        try {
            behaviorLogService.insertLog({module:"user",function:"查看",name:"资源管理"})
        } catch (error) {
            // console.log("插入日志失败:"+error)
        }
    }
    $scope.inserBehaviortLog();

    var buildBoardByCategory = function (listIn, rParent, type, icon) {
        var newParentId = "Dashboard";
        var listOut = [];
        for (var i = 0; i < listIn.length; i++) {
            if (listIn[i].parentId) {
                listOut.push({
                    "id": listIn[i].id.toString(),
                    "parent": listIn[i].parentId == "0" || !listIn[i].parentId ? rParent : listIn[i].parentId,
                    "text": listIn[i].name,
                    "icon": "fa fa-folder-open"
                })
            } else {
                listOut.push({
                    "id": listIn[i].id.toString(),
                    "parent": listIn[i].categoryId == "0" || !listIn[i].categoryId ? rParent : listIn[i].categoryId,
                    "text": listIn[i].name,
                    resId: listIn[i].id.toString(),
                    type: type,
                    icon: icon,
                    name: listIn[i].name
                })
            }
        }
        return listOut;
    }

    // var getMenuList = function () {
    //     return userResService.getMenuList().then(function (response) {
    //         $scope.menuList = response;
    //         _.each(response, function (e) {
    //             $scope.tree.menu.resList.push({
    //                 id: 'menu_' + e.menuId,
    //                 text: translate(e.menuName),
    //                 parent: e.parentId == -1 ? 'Menu' : ('menu_' + e.parentId),
    //                 resId: e.menuId,
    //                 type: 'menu',
    //                 icon: 'fa fa-cog',
    //                 name: translate(e.menuName)
    //             });
    //         });
    //     });
    // };

    var getDatasourceList = function () {
        return userResService.getDatasourceList({ "notEdit": "0" }).then(function (response) {
            _.each(buildNodeByCategory(response, 'Datasource', 'datasource', 'fa fa-database'), function (e) {
                $scope.tree.datasource.resList.push(e);
            });
        });
    };

    var getDatasetList = function () {
        return userResService.getDatasetList({ "notEdit": "0" }).then(function (response) {
            _.each(buildNodeByCategory(response, 'Dataset', 'dataset', 'fa fa-table'), function (e) {
                $scope.tree.dataset.resList.push(e);
            });
            $scope.$apply();
        });

    };

    var getWidgetList = function () {
        return userResService.getWidgetList().then(function (response) {
            _.each(buildNodeByCategory(response, 'Widget', 'widget', 'fa fa-line-chart'), function (e) {
                $scope.tree.widget.resList.push(e);
            });
            $scope.$apply();
        });
    };

    var getJobList = function () {
        return userResService.getJobList().then(function (response) {
            _.each(buildNodeByCategory(response, 'Job', 'job', 'fa fa-clock-o'), function (e) {
                $scope.tree.job.resList.push(e);
            });
        });
    };

    var getAppList = function () {
        return appService.getAuthList().then(function(response){
            _.each(buildNodeByCategory(response, 'App', 'app', 'fa fa-clock-o'), function (e) {
                $scope.tree.app.resList.push(e);
            });
            $scope.$apply();
        })
    };

    var getReportList = function () {
        return reportService.getAuthList().then(function(response){
            _.each(buildNodeByCategory(response, 'Report', 'report', 'fa fa-clock-o'), function (e) {
                $scope.tree.report.resList.push(e);
            });
            $scope.$apply();
        })
    };

    var getCUDRlabel = function (e, d) {
        var a = ['查'];
        if (e) {
            a.push('编');
        }
        if (d) {
            a.push('删');
        }
        return ' (' + a.join(',') + ')';
    };

    var buildNodeByCategory = function (listIn, rParent, type, icon) {
        var newParentId = 1;
        var listOut = [];
        if (listIn) {
            for (var i = 0; i < listIn.length; i++) {
                var arr = [];
                if (listIn[i].categoryName) {
                    arr = listIn[i].categoryName.split('/');
                    arr.push(listIn[i].name);
                } else {
                    arr.push(listIn[i].name);
                }
                var parent = rParent;
                for (var j = 0; j < arr.length; j++) {
                    var flag = false;
                    var a = arr[j];
                    for (var m = 0; m < listOut.length; m++) {
                        if (listOut[m].text == a && listOut[m].parent == parent && listOut[m].id.substring(0, 6) == 'parent') {
                            flag = true;
                            break;
                        }
                    }
                    if (!flag) {
                        if (j == arr.length - 1) {
                            listOut.push({
                                "id": type + '_' + listIn[i].id.toString(),
                                "parent": parent,
                                "text": a, //+ getCUDRlabel(true, true),
                                resId: listIn[i].id,
                                type: type,
                                icon: icon,
                                name: a
                            });
                        } else {
                            listOut.push({
                                "id": 'parent' + '_' + type + '_' + newParentId,
                                "parent": parent,
                                "text": a,
                                "icon": 'fa fa-folder-open'
                            });
                        }
                        parent = 'parent' + '_' + type + '_' + newParentId;
                        newParentId++;
                    } else {
                        parent = listOut[m].id;
                    }
                }
            }
        }
        if (rParent == 'Dashboard') {
            // console.log("listoutput", listOut);
        }
        return listOut;
    };

    var getContextMenu = function ($node) {
        function toggleACL(attr) {
            return function (obj) {
                $node.original[attr] = $node.original[attr] == undefined ? false : !$node.original[attr];
                _.each($node.children_d, function (e) {
                    var tree = $(obj.reference).jstree(true);
                    var node = tree.get_node(e);
                    if (node.children.length == 0) {
                        node.original[attr] = $node.original[attr];
                        tree.rename_node(node, node.original.name + getCUDRlabel(node.original.edit, node.original.delete));
                    }
                });
            }
        }

        if (_.isUndefined($node.original.resId)) {
            if ($node.parent != '#') {
                return {
                    edit: {
                        label: function () {
                            return translate('ADMIN.TOGGLE_UPDATE');
                        },
                        action: toggleACL('edit')
                    },
                    delete: {
                        label: function () {
                            return translate('ADMIN.TOGGLE_DELETE');
                        },
                        action: toggleACL('delete')
                    }
                };
            } else {
                return;
            }
        } else {
            return {
                edit: {
                    label: function () {
                        return $node.original.edit ? '√ 编辑' : '× 编辑';
                    },
                    action: function (obj) {
                        $node.original.edit = !$node.original.edit;
                        $(obj.reference).jstree(true).rename_node($node, $node.original.name + getCUDRlabel($node.original.edit, $node.original.delete));
                    }
                },
                delete: {
                    label: function () {
                        return $node.original.delete ? '√ 删除' : '× 删除';
                    },
                    action: function (obj) {
                        $node.original.delete = !$node.original.delete;
                        $(obj.reference).jstree(true).rename_node($node, $node.original.name + getCUDRlabel($node.original.edit, $node.original.delete));
                    }
                }
            };
        }
    };

    var loadResData = function () {
        getBoardList()
        // .then(function () {
        //     return getMenuList();
        // })
        // .then(function () {
        //     return getDatasourceList();
        // })
        .then(function () {
            return getDatasetList();
        }).then(function () {
            return getWidgetList();
        }).then(function () {
            return getAppList();
        }).then(function(){
            return getRoleList();
        }).then(function(){
            return getReportList();
        })
        // .then(function () {
        //     return getJobList();
        // })
        .then(function () {
            var config = {
                core: {
                    multiple: true,
                    animation: true,
                    error: function (error) {
                        // console.log(error)
                    },
                    check_callback: true,
                    worker: true
                },
                checkbox: {
                    three_state: true
                },
                contextmenu: {
                    items: getContextMenu,
                    select_node: false
                },
                version: 1,
                plugins: ['types', 'checkbox', 'unique', 'contextmenu']
            };
            var configMenu = angular.copy(config);
            configMenu.checkbox.three_state = false;
            delete configMenu.contextmenu;
            configMenu.plugins = ['types', 'checkbox', 'unique'];
            $scope.treeConfig = config;
            $scope.treeMenuConfig = configMenu;

        });
    };
    loadResData();


    var getRoleResList = function () {
        // $http.get("api/bcp/admin/getRoleResList")
        userResService.getRoleResList().then(function (response) {
            $scope.roleResList = response;
        });
    };
    getRoleResList();

    $scope.onRoleFilter = function (item) {
        $scope.roleFilter = _.map(_.filter($scope.userRoleList, function (e) {
            return e.roleId == item.roleId;
        }), function (u) {
            return u.userId;
        });
    };



    $scope.searchUserByRole = function (user) {
        if (!$scope.filterByRole) {
            return true;
        }
        return !_.isUndefined(_.find($scope.roleFilter, function (e) {
            return e == user.userId;
        }))
    };

    $scope.searchUserByName = function (user) {
        getUserList();

    };

    $scope.changeRoleSelect = function () {
        changeRoleSelect();
    }

    //数据包管理点击
    $scope.showUserRole = false;
    $scope.roleSelect = function ($event, role) {
        $($event.target).parent().siblings().removeClass('liBackground');
        $($event.target).parent().addClass('liBackground');
        $scope.showUserRole = true;
        $scope.selectRole = role;
        var userRole = _.filter($scope.userRoleList, function (e) {
            return e.roleId == $scope.selectRole.roleId;
        });
        $scope.selectUser = _.filter($scope.userList, function (e) {
            return _.find(userRole, function (ur) {
                return ur.userId == e.id;
            })
        });
        clickRoleToChangeTree($scope.tree.board);
        clickRoleToChangeTree($scope.tree.menu);
        clickRoleToChangeTree($scope.tree.dataset);
        // clickRoleToChangeTree($scope.tree.datasource);
        clickRoleToChangeTree($scope.tree.app);
        clickRoleToChangeTree($scope.tree.report);
        // clickRoleToChangeTree($scope.tree.job);
        clickRoleToChangeTree($scope.tree.widget);
        $scope.search.value = undefined;  //切换时将搜索框清掉
    }

    var changeRoleSelect = function () {
        if ($scope.selectUser && $scope.selectUser.length == 1) {
            var userRole = _.filter($scope.userRoleList, function (e) {
                return e.userId == $scope.selectUser[0].id;
            });
            $scope.selectRole = _.filter($scope.roleList, function (e) {
                return _.find(userRole, function (ur) {
                    return ur.roleId == e.roleId;
                })
            });
            $scope.userRoles = angular.copy($scope.selectRole);
            $scope.changeResSelect();
        }
    };

    $scope.newUser = function () {
        $scope.selectUser = [];
        // if ($scope.selectUser && $scope.selectUser.length == 1) {
        //     var userRole = _.filter($scope.userRoleList, function (e) {
        //         return e.userId == $scope.selectUser[0].id;
        //     });
        //     $scope.selectRole = _.filter($scope.roleList, function (e) {
        //         return _.find(userRole, function (ur) {
        //             return ur.roleId == e.roleId;
        //         })
        //     });
        //     $scope.userRoles = angular.copy($scope.selectRole);
        // }
        $scope.selectRole = []

        $scope.optFlag = 'newUser';
        $scope.curUser = {};
    };

    $scope.cancel = function () {
        $scope.optFlag = 'none';
        $scope.curUser = {};
    };

    $scope.editUser = function (user) {
        $scope.optFlag = 'editUser';
        $scope.curUser = angular.copy(user);
        $scope.curUser.oldLoginName = user.loginName;
    };

    $scope.editUserInfo = function (userInfo) {
        $scope.selectUser = [userInfo];
        if ($scope.selectUser && $scope.selectUser.length == 1) {
            var userRole = _.filter($scope.userRoleList, function (e) {
                return e.userId == $scope.selectUser[0].id;
            });
            $scope.selectRole = _.filter($scope.roleList, function (e) {
                return _.find(userRole, function (ur) {
                    return ur.roleId == e.roleId;
                })
            });
            $scope.userRoles = angular.copy($scope.selectRole);
        }
        $scope.optFlag = 'editUser';
        $scope.curUser = angular.copy(userInfo);
        $scope.curUser.oldLoginName = userInfo.loginName;
    };

    $scope.showUserInfo = function (userInfo) {
        $scope.optFlag = 'showUser';
        $scope.selectUser = [userInfo];
        if ($scope.selectUser && $scope.selectUser.length == 1) {
            var userRole = _.filter($scope.userRoleList, function (e) {
                return e.userId == $scope.selectUser[0].id;
            });
            $scope.selectRole = _.filter($scope.roleList, function (e) {
                return _.find(userRole, function (ur) {
                    return ur.roleId == e.roleId;
                })
            });
            $scope.userRoles = angular.copy($scope.selectRole);
        }
        $scope.curUser = angular.copy(userInfo);
        $scope.curUser.oldLoginName = userInfo.loginName;
    };

    $scope.newRole = function () {
        $scope.optFlag = 'newRole';
        $scope.curRole = {};
    };

    $scope.editRole = function (role) {
        $scope.optFlag = 'editRole';
        $scope.curRole = angular.copy(role);
    };

    var validate = function (type) {
        if (type == 'user') {
            if (!$scope.curUser.loginName || !$scope.curUser.name || !$scope.curUser.confirmNewPassword || !$scope.curUser.newPassword) {
                // ModalUtils.alert("账号、姓名、密码不能为空", "modal-warning", "sm");
                alert("姓名、登录名、密码不能为空")
                return false;
            }
            if ($scope.curUser.confirmNewPassword != $scope.curUser.newPassword) {
                alert("两次密码输入不一致")
                return false;
            }
            //校验邮箱
            var regEmail = /^\w+@[a-z0-9]+\.[a-z]+$/i;
            if ($scope.curUser.email !== '' && $scope.curUser.email !== undefined) {
                if (!regEmail.test($scope.curUser.email)) {
                    alert("输入的邮箱有误，请重新输入");
                    return false;
                }
            }
            //校验手机
            var regMobile = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
            if ($scope.curUser.mobile !== '' && $scope.curUser.mobile !== undefined) {
                if (!regMobile.test($scope.curUser.mobile)) {
                    alert("输入的手机号码有误，请重新输入");
                    return false;
                }
            }
        } else {
            if (!$scope.curRole.roleName) {
                alert("数据包名和管理员不能为空")
                return false
            }
        }
        return true;
    }

    $scope.saveUser = function () {
        if (!validate('user')) {
            return;
        }
        if ($scope.optFlag == 'newUser') {
            userResService.saveNewUser($scope.curUser).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    $scope.optFlag = 'none';
                    var newUserId = [];
                    var newRoleIds = [];
                    _.each($scope.selectRole, function (value) {
                        newRoleIds.push(value.roleId);
                    })
                    var oldUserIds = _.map(angular.copy($scope.userList), function (a) {
                        return a.id;
                    });
                    toastr.success("保存用户信息成功！");
                    userResService.getUserList().then(function (response) {
                        $scope.userList = response.listdata;
                        var curUserIds = _.map(response.listdata, function (a) {
                            return a.id;
                        });
                        _.each(curUserIds, function (value) {
                            if (oldUserIds.indexOf(value) == -1) {
                                newUserId.push(value)
                            }
                        })

                        userResService.updateUserRole({
                            userIdArr: angular.toJson(newUserId),
                            roleIdArr: angular.toJson(newRoleIds)
                        }).then(function (res) {
                            if (res == '1') {
                                $scope.selectUser = null;
                                $scope.selectRole = null;
                                getUserRoleList();
                                getUserList();
                                toastr.success("更新数据权限成功！");
                            } else {
                                toastr.error("更新数据权限失败！");
                            }
                        },err=>{

                        });
                    })
                } else {
                    toastr.error("保存用户信息失败！");
                }
            })
        } else {
            userResService.editUser($scope.curUser).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    $scope.optFlag = 'none';
                    getUserList();
                    $scope.verify = {
                        dsName: true
                    };
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
            }).catch(function () {
                toastr.error(serviceStatus.msg);
            });
        }
        $('#newUserModal').modal('hide');
    };

    $scope.saveRole = function () {
        if (!validate('role')) {
            return;
        }
        if ($scope.optFlag == 'newRole') {
            userResService.checkExistRoleName({
                role: angular.toJson($scope.curRole)
            }).then(function (response) {
                if (response == '0' || !response) {
                    userResService.saveRole({
                        role: angular.toJson($scope.curRole)
                    }).then(function (serviceStatus) {
                        if (serviceStatus == '1') {
                            $scope.optFlag = 'none';
                            getRoleList();
                            $scope.verify = {
                                dsName: true
                            };
                            toastr.success(translate("COMMON.SUCCESS"));
                        } else {
                            toastr.error(serviceStatus.msg);
                        }
                    });
                } else {
                    toastr.success('数据包名已存在');
                }
            });
        } else {
            userResService.updateRole({
                role: angular.toJson($scope.curRole)
            }).then(function (serviceStatus) {
                if (serviceStatus == '1') {
                    $scope.optFlag = 'none';
                    getRoleList();
                    $scope.verify = {
                        dsName: true
                    };
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
            });
        }

        $('#newRoleModal').modal('hide');
    };

    $scope.grantRole = function () {

        var userIds = _.map($scope.selectUser, function (e) {
            return e.id;
        });

        // var roleIds = _.map($scope.selectRole, function(e) {
        //     return e.roleId;
        // });
        var userRoleIds = new Array;
        var selectRoleArr = $scope.selectRole;
        //当用户数据包组里有数据时 对比已经选择的数据包 将不重复的添加进用户数据包组
        if ($scope.userRoles && $scope.userRoles.length > 0) {
            userRoleIds = _.map($scope.userRoles, function (a) {
                return a.roleId;
            });
            _.each($scope.selectRole, function (e) {
                if (userRoleIds.indexOf(e.roleId) == -1) {
                    userRoleIds.push(e.roleId)
                }
            })
        } else {
            //没有数据时 则已选择的数据就是用户数据包组
            userRoleIds = _.map($scope.selectRole, function (e) {
                return e.roleId;
            });
        }
        userResService.updateUserRole({
            userIdArr: angular.toJson(userIds),
            roleIdArr: angular.toJson(userRoleIds)
        }).then(function (serviceStatus) {
            if (serviceStatus == '1') {
                $scope.selectUser = null;
                $scope.selectRole = null;
                getUserRoleList();
                getUserList();
                toastr.success(translate("COMMON.SUCCESS"));
            } else {
                toastr.error(serviceStatus.msg);
            }
        },err=>{
            toastr.error(err.head.errorMsg)
        });
        $('#newUserModal').modal('hide');
    };

//添加用户的弹窗start
    //点击添加用户
    // $scope.showSelectUser = function () {
    //     $scope.selectToRoleUser = $scope.selectUser;
    // }
    //添加用户数据包
    $scope.addUserToRole = function () {
        var userRoleId = [$scope.selectRole.roleId];
        var userRoleName = [$scope.selectRole.roleName];
        var userIds = [];
        if ($scope.selectUser.length > 0) {
            var uniqueUsers = Array.from(new Set($scope.selectUser.concat($scope.selectToRoleUser)));
            _.each(uniqueUsers, function (e) {
                userIds.push(e.id);
            })
        } else {
            //没有数据时 则已选择的数据就是用户数据包组
            userIds = _.map($scope.selectToRoleUser, function (e) {
                return e.id;
            });
        }
        var addedUserNames = _.map($scope.selectToRoleUser, function (e) {
            return e.name;
        });
        userResService.updateRoleUser({
            userIdArr: angular.toJson(userIds),
            roleIdArr: angular.toJson(userRoleId),
            addedUserNames:addedUserNames.toString(),
            roleName:userRoleName.toString()
        }).then(function (serviceStatus) {
            if (serviceStatus == '1') {
                getUserRoleList();
                $scope.selectUser = Array.from(new Set($scope.selectUser.concat($scope.selectToRoleUser)))
                toastr.success(translate("COMMON.SUCCESS"));
            } else {
                toastr.error(serviceStatus.msg);
            }
        });
        $('#newUserToRoleModal').modal('hide');
    }

    //不改变共用模板userSelectedListTpl，来源timerEditVtrl
    var getBindReceiptIds = function (callback) {
        userService.getBindUserIdByCompany({type:'1'}).then(function (response) {
            if(response.status=='1'){
                $scope.bindUsers = response.data;
            }
            // $scope.isBindWechat =function(userId){
            //     if($scope.bindUsers && $scope.bindUsers.indexOf(userId)!=-1)return true;
            //     return false;
            // }
            // if (callback) {
            //     callback();
            // }
        },err=>{
            toastr.error(err.head.errorMsg)
        });
    }
    getBindReceiptIds();

    //前往新增用户页面
    $scope.gotoUserPage = function () {
        if (!$scope.userList) {
            ModalUtils.alert("获取用户列表失败!", "modal-warning", "lg");
            return;
        }
        $uibModal.open({
            template: userSelectedNoPageTpl,
            windowTemplate:windowTpl,
            size: 'lg',
            windowClass: 'pw-user-modal',
            resolve: {
                selectIds: function () {
                    return _.map($scope.selectUser, function(e){
                        return e.id;
                    })
                },
                bindUsers:function(){
                    return $scope.bindUsers;
                },
                ok: function () {
                    return function (param) {
                        //预防为空的错误
                        if(param && param.length > 0) {
                            $scope.selectToRoleUser = [];
                            $scope.userList = !!$scope.userList?$scope.userList:[];
                            $scope.selectUser = !!$scope.selectUser?$scope.selectUser:[];
                            _.each($scope.userList, function(e){
                                //去除原有的，只包括新增的user
                                if(param.indexOf(e.id) != -1){
                                    if($scope.selectUser.indexOf(e) == -1){
                                        $scope.selectToRoleUser.push(e);
                                    }
                                }
                            });

                            $scope.addUserToRole();
                        }
                    }
                }
            },
            controller: ['$scope', '$uibModalInstance', 'selectIds', 'ok', 'bindUsers', function ($scope, $uibModalInstance, selectIds, ok, bindUsers) {
                $scope.selectIds = angular.copy(selectIds) || [];
                $scope.bindUsers = angular.copy(bindUsers);
                $scope.userTitle = "用户列表(不包含已选择用户)"
                // $scope.paginationConf = {
                //     currentPage: 1,
                //     totalItems: 0,
                //     itemsPerPage: 15,
                //     onChange: function () {
                //         $scope.getUserList(); //重新加载
                //     }
                // };

                $scope.getUserList =function(){
                    var params = {
                        "pageNo": 1,
                        "pageSize": 9999,
                        "name":$scope.search
                    }
                    userAdminService.postUserList(params).then(function (response) {
                        // $scope.userList = response.listdata;
                        // $scope.paginationConf.totalItems = response.totolCount;
                        $scope.userList = _.filter(response.listdata,function(e){
                            return  $scope.selectIds.indexOf(e.id)<0;
                        })
                        $scope.ifCheckedAll();
                        $scope.$apply();
                    },err=>{
                        toastr.error(err.head.errorMsg)
                    });
                }
                $scope.getUserList();
                //获取已经绑定了微信的用户
                $scope.isBindWechat =function(userId){
                    if($scope.bindUsers && $scope.bindUsers.indexOf(userId)!=-1)return true;
                    return false;
                }

                //更新选择的用户
                $scope.updateSelection = function ($event, id) {
                    //只有被选中的时候才添加到ids集合中
                    if ($event.target.checked) {
                        $scope.selectIds.push(id); //push向集合中添加元素
                    } else {
                        //如果没被选中则删除集合中的元素
                        var index = $scope.selectIds.indexOf(id); //查找值的位置
                        $scope.selectIds.splice(index, 1); //参数1:移除的位置 参数2:移除的个数
                    }
                }


                //判断是否选择好了
                $scope.ifChecked = function (id) {
                    //如果Id出现在数组中，则需要返回true
                    var index = $scope.selectIds.indexOf(id);
                    if (index == -1) {
                        return false;
                    } else { //如果id没有出现在selectIds数组中需要返回false
                        return true;
                    }
                };

                //判断是否全选了
                $scope.ifCheckedAll = function () {
                    //$scope.userList中的对象的id 是否都在 $scope.selectIds中
                    //如果全中，则返回true
                    //如果有一个还在，就返回false
                    if(!$scope.userList) return false;
                    for (var i = 0; i < $scope.userList.length; i++) {
                        if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                            return false;
                        }
                    }
                    return true;
                };

                //全选与取消全选
                $scope.selectAll = function ($event) {
                    if ($event.target.checked) {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            //当前页面的数据的id放到数组中
                            if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                                $scope.selectIds.push($scope.userList[i].id);
                            }
                        }
                    } else {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            var index = $scope.selectIds.indexOf($scope.userList[i].id);
                            $scope.selectIds.splice(index, 1);
                        }
                    }
                };

                //关闭
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                //确定
                $scope.ok = function () {
                    $uibModalInstance.close();
                    ok($scope.selectIds);
                };
            }]
        });
    }
//添加用户的弹窗end

    $scope.revokeRole = function () {

        if (!$scope.selectUser || $scope.selectUser.length < 0) {
            ModalUtils.alert("请选择要移除用户", "modal-warning", "sm");
        }
        if (!$scope.selectRole || $scope.selectRole.length < 0) {
            ModalUtils.alert("请选择要移除数据包", "modal-warning", "sm");
        }
        var userRoleIds = _.map($scope.userRoles, function (e) {
            return e.roleId;
        });
        var roleIds = new Array;
        _.each($scope.selectRole, function (e) {
            if (userRoleIds.indexOf(e.roleId) == -1) {
                ModalUtils.alert("无法移除非用户数据包: " + e.roleName, "modal-warning", "sm");
                return;
            } else {
                roleIds.push(e.roleId);
            }
        })
        var userIds = _.map($scope.selectUser, function (e) {
            return e.id;
        });

        userResService.deleteUserRole({
            userIdArr: angular.toJson(userIds),
            roleIdArr: angular.toJson(roleIds)
        }).then(function (serviceStatus) {
            if (serviceStatus == '1') {
                $scope.selectUser = null;
                $scope.selectRole = null;
                getUserRoleList();
                getUserList();
                toastr.success(translate("COMMON.SUCCESS"));
            } else {
                toastr.error(serviceStatus.msg);
            }
        });
        $('#newUserModal').modal('hide');
    };

    //移除用户数据包
    $scope.removeUserRole = function (index, u) {
        var userId = [],
            roleId = [];
        userId.push(u.id);
        roleId.push($scope.selectRole.roleId);
        ModalUtils.confirm('真的从该数据包中移除用户吗', "modal-info", "md", function () {
            userResService.deleteUserRole({
                userIdArr: angular.toJson(userId),
                roleIdArr: angular.toJson(roleId)
            }).then(function (serviceStatus) {
                if (serviceStatus == '1') {
                    //$scope.selectUser.splice(index, 1);
                    $scope.selectUser.splice($scope.selectUser.indexOf(u), 1); //index和此无关
                    getUserRoleList();
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
            });
        });
    };

    $scope.changeResSelect = function () {
        changeResSelectByTree($scope.tree.menu);
        changeResSelectByTree($scope.tree.board);
        changeResSelectByTree($scope.tree.dataset);
        // changeResSelectByTree($scope.tree.datasource);
        // changeResSelectByTree($scope.tree.job);
        changeResSelectByTree($scope.tree.widget);
        changeResSelectByTree($scope.tree.app);
        changeResSelectByTree($scope.tree.report);
    };


    var changeResSelectByTree = function (tree) {
        $scope.optFlag = 'selectRes';
        tree.treeInstance.jstree(true).open_all();
        if ($scope.selectRole.length > 0) {
            var roleRes = _.filter($scope.roleResList, function (e) {
                return !_.isUndefined(_.find($scope.selectRole, function (r) {
                    return e.roleId == r.roleId;
                }));
            });

            tree.treeInstance.jstree(true).uncheck_all();

            _.each(tree.resList, function (e) {
                var f = _.find(roleRes, function (rr) {
                    return rr.resId == e.resId && rr.resType == e.type;
                });
                var _n = tree.treeInstance.jstree(true).get_node(e);
                if (!_.isUndefined(f)) {
                    tree.treeInstance.jstree(true).check_node(e);
                    if (e.name) { //菜单节点不需要更新权限标记
                        _n.original.edit = f.edit;
                        _n.original.delete = f.delete;
                    }
                } else {
                    if (e.name) { //菜单节点不需要更新权限标记
                        _n.original.edit = true;
                        _n.original.delete = true;
                    }
                }
                if (e.name && _n.original) {
                    tree.treeInstance.jstree(true).rename_node(e, e.name + getCUDRlabel(_n.original.edit, _n.original.delete));
                }
            });
        }
    };

    var clickRoleToChangeTree = function (tree) {
        if (tree.treeInstance) {
            // tree.treeInstance.jstree(true).open_all();
            if ([$scope.selectRole].length > 0) {
                var roleRes = _.filter($scope.roleResList, function (e) {
                    return !_.isUndefined(_.find([$scope.selectRole], function (r) {
                        return e.roleId == r.roleId;
                    }));
                });
                // console.log("tree.treeInstance.jstree(true)",tree.treeInstance.jstree(true).settings.contextmenu.select_node)

                // $("#js_tree").on("ready.jstree", function (e, data) {
                    //     //data.instance.open_all();//打开所有节点
                    //     data.instance.open_node("Dashboard_anchor");//打开根节点
                    // });
                tree.treeInstance.jstree(true).open_node("Dashboard_anchor");
                tree.treeInstance.jstree(true).open_node("Dataset_anchor");
                tree.treeInstance.jstree(true).open_node("Widget_anchor");
                tree.treeInstance.jstree(true).open_node("App_anchor");
                tree.treeInstance.jstree(true).open_node("Report_anchor");
                tree.treeInstance.jstree(true).uncheck_all();

                _.each(tree.resList, function (e) {
                    var f = _.find(roleRes, function (rr) {
                        return rr.resId == e.resId && rr.resType == e.type;
                    });
                    var _n = tree.treeInstance.jstree(true).get_node(e);
                    if (!_.isUndefined(f)) {
                        tree.treeInstance.jstree(true).check_node(e);
                        if (e.name) { //菜单节点不需要更新权限标记
                            _n.original.edit = f.edit;//编辑权限
                            _n.original.delete = f.delete;//删除权限
                        }
                    } else {
                        try {
                            if (e.name&&_n.original) { //菜单节点不需要更新权限标记
                                _n.original.edit = true;
                                _n.original.delete = true;
                            }
                        } catch (err) {
                             }
                    }
                    if (e.name&& _n.original) {
                        tree.treeInstance.jstree(true).rename_node(e, e.name + getCUDRlabel(_n.original.edit, _n.original.delete));
                    }
                });
            }
        }
    };

    $scope.grantRes = function () {
        var roleIds = _.map($scope.selectRole, function (e) {
            return e.roleId;
        });
        var roleNames = _.map([$scope.selectRole], function (e) {
            return e.roleName;
        });
        var resIds = [];
        for (var key in $scope.tree) {
            _.each(_.filter($scope.tree[key].treeInstance.jstree(true).get_checked(true), function (e) {
                return !_.isUndefined(e.original.resId);
            }), function (e) {
                resIds.push({
                    resId: e.original.resId,
                    resType: e.original.type,
                    edit: e.original.edit,
                    delete: e.original.delete
                });
            });
        }
        userResService.updateRoleRes({
            roleIdArr: angular.toJson(roleIds),
            resIdArr: angular.toJson(resIds),
            roleNames:roleNames.toString()
        }).then(function (serviceStatus) {
            if (serviceStatus == '1') {
                $scope.selectRole = null;
                $scope.selectRes = null;
                getRoleResList();
                getRoleList();
                changeRoleSelect();
                toastr.success("更新数据包成功");
            } else {
                toastr.error("更新数据包成功");
            }
        },err=>{
            toastr.error(err.head.errorMsg||"更新数据包失败");
        });

    };

    //更新权限
    $scope.updateRoleRes = function () {
        if ($scope.selectRole === undefined) {
            ModalUtils.alert('请选择数据包！', "modal-warning", "sm");
            return
        }
        var roleIds = _.map([$scope.selectRole], function (e) {
            return e.roleId;
        });
        var roleNames = _.map([$scope.selectRole], function (e) {
            return e.roleName;
        });
        var resIds = [];
        for (var key in $scope.tree) {
            if ($scope.tree[key].treeInstance) {
                _.each(_.filter($scope.tree[key].treeInstance.jstree(true).get_checked(true), function (e) {
                    return !_.isUndefined(e.original.resId);
                }), function (e) {
                    resIds.push({
                        resId: e.original.resId,
                        resType: e.original.type,
                        edit: e.original.edit,
                        delete: e.original.delete
                    });
                });
            }
        }
        userResService.updateRoleRes({
            roleIdArr: angular.toJson(roleIds),
            resIdArr: angular.toJson(resIds),
            roleNames:roleNames.toString()
        }).then(function (serviceStatus) {
            if (serviceStatus == '1') {
                // $scope.selectRole = null;
                $scope.selectRes = null;
                getRoleResList();
                // getRoleList();
                // changeRoleSelect();
                toastr.success("更新资源包成功!");
            } else {
                toastr.error("更新资源包失败!");
            }
        },err=>{
            toastr.error(err.head.errorMsg || "更新资源包失败!");
        });

    };

    $scope.deleteRole = function () {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "lg", function () {
            userResService.deleteRole({
                roleId: $scope.selectRole[0].roleId
            }).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    $scope.selectRole = null;
                    $scope.selectRes = null;
                    getRoleList();
                    getRoleResList();
                    $scope.optFlag = 'none';
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
            });
        });
    }

    $scope.deleteRoleInfo = function () {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "md", function () {
            userResService.deleteRole({
                roleId: $scope.selectRole.roleId
            }).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    $scope.selectRole = null;
                    $scope.selectRes = null;
                    getRoleList();
                    getRoleResList();
                    $scope.optFlag = 'none';
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
            });
        });
    }

    $scope.deleteUser = function () {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "lg", function () {
            userResService.deleteUser($scope.selectUser[0].id).then(function (serviceStatus) {
                if (serviceStatus == '1') {
                    $scope.selectUser = null;
                    $scope.selectRole = null;
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
                getUserList();
            }).catch(function (serviceStatus) {
                toastr.error(serviceStatus.msg);
            });
        });
    }

    $scope.deleteUserInfo = function (userInfo) {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-info", "md", function () {
            userResService.deleteUser(userInfo.id).then(function (serviceStatus) {
                if (serviceStatus == '1') {
                    $scope.selectUser = null;
                    $scope.selectRole = null;
                    toastr.success(translate("COMMON.SUCCESS"));
                } else {
                    toastr.error(serviceStatus.msg);
                }
                getUserList();
            }).catch(function (serviceStatus) {
                toastr.error(serviceStatus.msg);
            });
        });
    }

    //用户列表分页
    $scope.paginationConf1 = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 20,
        onChange: function () {

        }
    };
    var getPaginationConf1Total = function(){
        $scope.paginationConf1.totalItems = $scope.selectUserShow ? $scope.selectUserShow.length : 0;
    };

    // $scope.$watch(function() {
    //     var newValue = $scope.selectUser + "";
    //     return newValue;
    // }, function(newValue, oldValue){
    //     $scope.selectUserBySearch($scope.search.value);
    //     getPaginationConf1Total();
    // });
    $scope.$watch('selectUser', ()=>{
        $scope.selectUserBySearch();
        getPaginationConf1Total();
    }, true);

    $scope.getShowSelectUserMax = function(){
        return $scope.paginationConf1.currentPage * $scope.paginationConf1.itemsPerPage - 1;
    };
    $scope.getShowSelectUserMin = function(){
        return $scope.paginationConf1.currentPage * $scope.paginationConf1.itemsPerPage - $scope.paginationConf1.itemsPerPage;
    };
    //用户列表搜索显示
    $scope.selectUserBySearch = function(){
        $scope.selectUserShow = [];
        if(_.isUndefined($scope.search.value)){
            $scope.selectUserShow = $scope.selectUser;
            getPaginationConf1Total();
        } else {
            _.each($scope.selectUser, function(e){
                if(e.name.search($scope.search.value) != -1){
                    $scope.selectUserShow.push(e);
                };
            });
            getPaginationConf1Total();
        }
    };

};

import office from './office.html'
import officeCtrl from './officeCtrl'

export default angular.module('borepc.pages.office', [])
    .controller('officeCtrl', ['$scope', 'ModalUtils','toastr',officeCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('office', {
            url: '/office',
            title: '企业信息',
            controller: 'officeCtrl',
            template: office
        })
}
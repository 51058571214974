import profileModalTpl from './profileModal.html'
import { userService, boardService } from '../../../server'

import { chartContext, dataContext } from '../../../charts'
import  './_profile.scss'
import pic from './test.png'
import PubSub from "../../../common/utils/PubSub"
/** @ngInject */
export default function ProfilePageCtrl($scope, fileReader, $filter, $uibModal, widgetModal) {
  PubSub.subscribe("msg.a", (msg,data)=> {
    console.log(msg,data)
  })
  PubSub.subscribe("msg.a.b", (msg,data)=> {
    console.log(msg,data)
  })
  PubSub.subscribe("msg.b", (msg,data)=> {
    console.log(msg,data)
  })
  $scope.logout = function login() {
    // for( var  i =0;i<1000;i++)
    PubSub.unsubscribe('msg.a');
    PubSub.publish("msg.a.b","频道发布的消息")
    // userService.logout()
  }


  $scope.widget = { "config": { "option": {}, "chart_type": "wordCloud", "keys": [{ "col": "年", "values": [], "sort": "asc", "id": "18a3ebdc-e9b1-489f-901f-71599527de85", "type": "eq" }], "groups": [], "values": [{ "name": "", "cols": [{ "col": "金额_原币含税", "aggregate_type": "sum" }] }], "filters": [] }, "datasource": null, "dtpId": null, "isDtpManager": false, "datasetId": "d1787418cf694c239f5e1a43a9cbce70" }
  // $scope.widget=widget;

  $scope.widget = function () {
    //柱状图
    // widgetModal.open("257cb7fd-a3c4-4748-9cc1-e58b00f3ac7e");
    //散点图
    // widgetModal.open("58f70009-eeed-427f-82e6-0d06e4867a39");
    //饼状图
    widgetModal.open("8d753827-ad4c-491c-a31f-28e382597e18");
    //指标卡 kpi
    // widgetModal.open("5c0c80c4-6921-4954-82ec-d9f1a514d467");
    //指标卡2 kpi2
    // widgetModal.open("a5a0738f-4580-4f59-af93-5391be72c439");
    //漏斗图 
    // widgetModal.open("32dae5bc-a2f0-4e76-9625-9cd8c051457e");
    //百度中国地图
    // widgetModal.open("0fe50496-26f4-456a-a567-901f3a3c1646");
    //交叉表
    // widgetModal.open("73c379e9-d457-4560-85fa-cbc8974c7de6");
    // .then(function  (data) {
    //      console.log(data)
    // })
  }
    $scope.widget()

  $scope.pic = pic
  console.log(pic)

  var param = {
    "loginName": 'admin',
    "password": 'ourbore@2019',
    // "loginName": 'lhzc',
    // "password": 'lhzc',
    "openid": '',
    "type": '',
    "accessToken": ''
  };

  $scope.login = function login() {
    console.log("test======")
    userService.login(param).then(function (res) {
      let { data } = res;
      console.log(data)
    })

    // userService.getUserInfo().then(function(res){
    //   console.log(res)
    // })
    // boardService.getBoardList().then(function(res){
    //   console.log(res)
    // })
  }
 
  $scope.test2 = function test2() {
    userService.getMenuList().then((data) => {
      console.log(data)
      let menuList = [];
      const root = _.find(data, (item) => { return item.parentId === '1' }) //根节点
      _.forEach(data, (item) => { //一级菜单
        if (item.parentId == root.id) {
          let subMenu = []
          _.forEach(data, (subitem) => { //二级菜单
            if (subitem.parentId == item.id) {
              subMenu.push({
                title: subitem.name,
                stateRef: subitem.href,
              })
            }
          })
          menuList.push({
            title: item.name,
            icon: item.icon,
            subMenu: subMenu
          })
        }
      })
      console.log(menuList)
    })
    userService.getPermissionList().then((data) => {
      console.log(data)
    })
  }




  $scope.test = function test() {
    //  dataContext.getDatasetDetailNoDs('6086fe9ea5fd4801b2a6f06b1f48dccb').then(function(data){
    //    console.log(data)
    //  })
    // var widget = {
    //   "firstShowAnalysis": false,
    //   "analysisText": "",
    //   "datasetId": "d1787418cf694c239f5e1a43a9cbce70",
    //   "config": {
    //     "chart_type": "kpi2",
    //     "keys": [{
    //       "col": "年",
    //       "values": [],
    //       "sort": "asc",
    //       "id": "18a3ebdc-e9b1-489f-901f-71599527de85",
    //       "type": "eq"
    //     }, {
    //       "col": "月",
    //       "values": [],
    //       "sort": "asc",
    //       "id": "b2f6fda6-fe1f-4b0e-b03d-cbe322690eba",
    //       "type": "eq"
    //     }
    //     ],
    //     "values": [{
    //       "name": "",
    //       "style": "bg-aqua",
    //       "cols": [{
    //         "col": "金额_原币含税",
    //         "aggregate_type": "sum"
    //       }
    //       ]
    //     }
    //     ],
    //     "groups": [],
    //     "filters": [],
    //     "option": {},
    //     "boardFilters": []
    //   },
    //   "expressions": [],
    //   "filterGroups": []
    // }
    //fun
    //var widget ={"config":{"option":{},"chart_type":"funnel","keys":[{"col":"年","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85","type":"eq"},{"col":"月","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba","type":"eq"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    //  var  widget={"config":{"option":{},"chart_type":"areaMap","keys":[{"col":"年","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85","type":"eq"},{"col":"月","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba","type":"eq"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    //  var  widget={"config":{"option":{},"chart_type":"budget","keys":[{"col":"年","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85","type":"eq"},{"col":"月","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba","type":"eq"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    //var widget ={"config":{"option":{},"chart_type":"chinaMap","keys":[{"col":"年","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85","type":"eq"},{"col":"月","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba","type":"eq"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}],"series_type":"scatter","type":"value"}],"filters":[],"valueAxis":"vertical"},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    //var  widget={"config":{"option":{},"chart_type":"chinaMapBmap","keys":[{"col":"年","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85","type":"eq","$$hashKey":"object:3320"},{"col":"月","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba","type":"eq","$$hashKey":"object:3321"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum","$$hashKey":"object:3317"}],"series_type":"scatter","type":"value","$$hashKey":"object:3337"}],"filters":[],"valueAxis":"vertical"},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var widget={"config":{"option":{},"chart_type":"contrast","keys":[{"col":"年","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85","type":"eq"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"},{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    //var widget={"config":{"option":{},"chart_type":"gauge","keys":[],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[],"styles":[{"proportion":"0.2","color":"#228b22"},{"proportion":"0.8","color":"#48b"},{"proportion":"1","color":"#ff4500"}]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    //var widget={"config":{"option":{},"chart_type":"heatMapCalendar","keys":[{"col":"月","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba","type":"eq"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}],"dateFormat":"yyyy-MM-dd","style":"blue"}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var widget={"config":{"option":{},"chart_type":"heatMapTable","keys":[{"col":"月","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba","type":"eq"}],"groups":[{"col":"年","type":"eq","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85"}],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}],"style":"blue"}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var widget={"config":{"option":{},"chart_type":"kpi","keys":[],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}],"style":"bg-aqua"}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var widget={"config":{"option":{},"chart_type":"line","keys":[{"col":"月","type":"eq","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}],"series_type":"line","type":"value"}],"filters":[],"valueAxis":"vertical"},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var widget={"config":{"option":{},"chart_type":"liquidFill","keys":[],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}],"style":"circle"}],"filters":[],"animation":"static"},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    //  var  widget ={"config":{"option":{},"chart_type":"map","keys":[{"col":"年","type":"eq","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"} 
    //  var widget={"config":{"option":{},"chart_type":"pie","keys":[{"col":"年","type":"eq","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}],"series_type":"pie","type":"value"}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var  widget ={"config":{"option":{},"chart_type":"radar","keys":[{"col":"年","type":"eq","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var  widget ={"config":{"option":{},"chart_type":"relation","keys":[{"col":"年","type":"eq","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85"}],"groups":[{"col":"月","type":"eq","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba"}],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var  widget ={"config":{"option":{},"chart_type":"sankey","keys":[{"col":"年","type":"eq","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85"}],"groups":[{"col":"月","type":"eq","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba"}],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}

    // var  widget ={"config":{"option":{},"chart_type":"scatter","keys":[{"col":"年","type":"eq","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85"}],"groups":[{"col":"月","type":"eq","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba"}],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]},{"name":"","cols":[]},{"name":"","cols":[]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var widget ={"config":{"option":{},"chart_type":"table","keys":[{"col":"年","type":"eq","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85"}],"groups":[{"col":"月","type":"eq","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba"}],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // var widget={"config":{"option":{},"chart_type":"treeMap","keys":[{"col":"年","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85","type":"eq"},{"col":"月","values":[],"sort":"asc","id":"b2f6fda6-fe1f-4b0e-b03d-cbe322690eba","type":"eq"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    var widget = { "config": { "option": {}, "chart_type": "wordCloud", "keys": [{ "col": "年", "values": [], "sort": "asc", "id": "18a3ebdc-e9b1-489f-901f-71599527de85", "type": "eq" }], "groups": [], "values": [{ "name": "", "cols": [{ "col": "金额_原币含税", "aggregate_type": "sum" }] }], "filters": [] }, "datasource": null, "dtpId": null, "isDtpManager": false, "datasetId": "d1787418cf694c239f5e1a43a9cbce70" }
    //var widget={"config":{"option":{},"chart_type":"worldMap","keys":[{"col":"年","values":[],"sort":"asc","id":"18a3ebdc-e9b1-489f-901f-71599527de85","type":"eq"}],"groups":[],"values":[{"name":"","cols":[{"col":"金额_原币含税","aggregate_type":"sum"}]}],"filters":[]},"datasource":null,"dtpId":null,"isDtpManager":false,"datasetId":"d1787418cf694c239f5e1a43a9cbce70"}
    // $scope.widget=widget; 
    chartContext.render($('#chart1'), widget);



    // dataContext.getWidgetDataSeries(widget.datasource, widget.query, widget.dtpId, widget.isDtpManager, widget.datasetId, widget.config, function (data) {
    //     console.log(data)

    // })

    //   var chartRender = chartContext.render($('#chart'), {
    //     config: widget.data.config,
    //     datasource: $scope.datasource ? $scope.datasource.id : null,
    //     query: widget.data.query,
    //     dtpId:$scope.dtp ? $scope.dtp.id : null,
    //     isDtpManager:$scope.dtp ? $scope.dtp.isDtpManager : false,
    //     datasetId: $scope.customDs ? undefined : widget.data.datasetId
    // });



  }

  function clums() {
    var req = {
      'datasource': '304413a0658a4cec8508bc7c00edb048',
      'isDtpManager': true,
      'dtpId': 'C4CA4238A0B923820DCC509A6F75849B',
      'query': { "sql": "select sfb81 日期,\n       to_char(sfb81, 'YYYY') as 年,\n       to_char(sfb81, 'Q') as 季,\n       to_char(sfb81, 'MM') as 月,\n       to_char(sfb81, 'DD') as 日,\n       sfb05 产品编码,\n       ima02 产品名称,\n       ima021 产品描述,\n       sfb08 计划产量,\n       sfb09 实际产量,\n       nvl(sfv09,0) 准时完工数量,\n       sfb02 返工数量\n from sfb_file\n left join ima_file on ima01 = sfb05\n left join (select sfv11, sum(sfv09) sfv09\n  from sfv_file\n inner join sfu_file\n on sfu01 = sfv01\n left join sfb_file\n on sfb01 = sfv11\nwhere sfupost = 'Y'\n  and sfu02 <= sfb15\ngroup by sfv11) on sfv11 = sfb01\n where sfb87 = 'Y'\n   and sfb02 in ('1','5')" },
      'reload': false,
      'callback': function (data) {
        console.log(data)
      }
    }
    dataContext.getColumns(req)
  }






  $scope.picture = $filter('profilePicture')('Nasta');

  $scope.removePicture = function () {
    $scope.picture = $filter('appImage')('theme/no-photo.png');
    $scope.noPicture = true;
  };

  $scope.uploadPicture = function () {
    var fileInput = document.getElementById('uploadFile');
    fileInput.click();

  };

  $scope.socialProfiles = [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/akveo/',
      icon: 'socicon-facebook'
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/akveo_inc',
      icon: 'socicon-twitter'
    },
    {
      name: 'Google',
      icon: 'socicon-google'
    },
    {
      name: 'LinkedIn',
      href: 'https://www.linkedin.com/company/akveo',
      icon: 'socicon-linkedin'
    },
    {
      name: 'GitHub',
      href: 'https://github.com/akveo',
      icon: 'socicon-github'
    },
    {
      name: 'StackOverflow',
      icon: 'socicon-stackoverflow'
    },
    {
      name: 'Dribbble',
      icon: 'socicon-dribble'
    },
    {
      name: 'Behance',
      icon: 'socicon-behace'
    }
  ];

  $scope.unconnect = function (item) {
    item.href = undefined;
  };

  $scope.showModal = function (item) {
    $uibModal.open({
      animation: false,
      controller: 'ProfileModalCtrl',
      template: profileModalTpl
    }).result.then(function (link) {
      item.href = link;
    });
  };

  $scope.getFile = function () {
    fileReader.readAsDataUrl($scope.file, $scope)
      .then(function (result) {
        $scope.picture = result;
      });
  };

  $scope.switches = [true, true, false, true, true, false];
}

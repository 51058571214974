/**
 * 
 * @param {*} $scope 
 * @param {*} $uibModalInstance 
 * @param {Object} archives ｛id? , alias, url, source, tagId, remarks?｝  无id则为新增，有为修改
 * @param {Function} ok 
 */
import './_archivesDetail.scss';
import { archivesService } from '../../../server';

export default function ($scope, $uibModalInstance, $timeout, toastr, archives, ok) {
    $scope.isNew = archives.id ? false : true;      //服务的状态-----是否为新增
    $scope.archives = archives;                     //当前档案
    $scope.curTag = {};                             //当前档案的标签
    $scope.newTag = {name: ''};                     //新增的标签
    $scope.isNewTaging = false;                     //下拉框是否开启新增标签
    $scope.tagList = [];                            //标签列表
    $scope.isEditTitle = false;                     //标题编辑状态
    $scope.isSelectTag = false;                     //标签选择状态
    $scope.isShowFull = false;                      //全屏展示

    // 获取标签列表----载入当前档案的标签----无则取第一个
	var getTagList = function(){
		archivesService.getTagList({
			pageNum: 1,
			pageSize: 999
		}).then(data=>{
			if(data){
				$scope.tagList = data.listdata;
                if(archives.tagId) {
                    $scope.curTag = _.find(data.listdata, (item)=>{
                        return archives.tagId == item.id;
                    }) || {};
                }
			}
			$scope.$apply();
		}, err=>{
			// console.log(err);
		});
	};
    var init = function(){
        // 不是新增的情况重新获取详情
        if(!$scope.isNew) {
            archivesService.getFile(archives.id).then(data=>{
                $scope.archives = data;
                $scope.$apply();
            }, err=>{
    
            });
        }
        // 获取标签列表
        getTagList();
    };
    init();

    // 关闭弹窗
    $scope.close = function(){
        $uibModalInstance.close();
    };
    // 保存信息----同时关闭弹窗
    $scope.save = function(){
        if(!$scope.archives.alias) {
            toastr.error('名称不能为空');
            return;
        }
        if(!$scope.archives.source) {
            toastr.error('来源不能为空');
            return;
        }
        // base64转file
        var base64ToFile = function(dataurl, filename){
            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename + '.jpeg', {type: mime});
        };
    
        var formData = new FormData();
        formData.append("alias", $scope.archives.alias);
        formData.append("tagId", $scope.curTag.id ? $scope.curTag.id : '');
        formData.append("source", $scope.archives.source);
        formData.append("remarks",  $scope.archives.remarks ? $scope.archives.remarks : '');
        if($scope.isNew) {
            formData.append('file', base64ToFile($scope.archives.url, $scope.archives.alias));
        } else {
            formData.append('id', $scope.archives.id);
        }
        archivesService.uploadImage(formData).then(data=>{
            // toastr.success(data ? data : '操作成功');
            if($scope.isNew) {
                toastr.success('上传成功');
            } else {
                toastr.success('操作成功');
            }
            ok();
        }, err=>{
            try {
                toastr.error(err.head.errorMsg ? err.head.errorMsg : '操作失败');
            } catch {
                toastr.error('操作失败');
            }
        });
        $scope.close();
    };

    // 切换标题的编辑状态
    $scope.changeEditTitle = function(e){
        e.stopPropagation();
        if($scope.isEditTitle) {
            $scope.isEditTitle = false;
        } else {
            $scope.isEditTitle = true;
            $timeout(()=>{$('#boreArchivesDetailTitleInput').focus();});
        }
    };
    // 切换选择标签
    $scope.selectTag = function(e){
        e.stopPropagation();
        $scope.isSelectTag = $scope.isSelectTag ? false : true;
        $scope.isSelectTag ? null : $scope.isNewTaging = false;     //关闭选择标签时, 新建标签的状态去掉
    };
    // 选择标签
    $scope.changeTag = function(tag){
        $scope.curTag = {
            id: tag.id,
            name: tag.name
        };
        $scope.isSelectTag = false;
        $scope.isNewTaging = false;                                 //选择标签后, 新建标签的状态去掉
    };
	//增加一个标签
	$scope.addTag = function(e){
		$scope.newTag = {name: ''};
        e.stopPropagation();
        if($scope.isNewTaging) {
            $scope.isNewTaging = false;
        } else {
            $scope.isNewTaging = true;
            $timeout(()=>{$('#boreArchivesDetailSelectInput').focus();});
        }
	};
	// 保存标签
	$scope.saveTag = function(e){
        e.stopPropagation();
		if(!$scope.newTag.name) {
			toastr.error('标签名称不能为空');
			return;
		}
		var tag = {name: $scope.newTag.name};
		archivesService.updateTag(tag).then(data=>{
			toastr.success(data ? data : '新增标签成功');
			getTagList();
		}, err=>{
			toastr.error('新增标签失败');
		});
		$scope.isNewTaging = false;
	};

    // 开启全屏
    $scope.openFull = function(){
        $scope.isShowFull = true;
    };
    //关闭全屏
    $scope.closeFull = function(){
        $scope.isShowFull = false;
    };

} 
import BasicService from './BasicService';
import { PREWARNINGAPI } from './ApiServer';

class PrewarningService extends BasicService {
  constructor() {
    super();
  }

  savePrewarning(params){
    return this.post(PREWARNINGAPI.SAVEPREWARNING,params)
  }
  getPwList(params){
    return this.post(PREWARNINGAPI.GETPWLIST,params);
  }
  updateWarnStatus(params){
    return this.post(PREWARNINGAPI.UPDATEWARNSTATUS,params);
  }
  delPrewarning(params){
    return this.post(PREWARNINGAPI.DELPREWARNING,params);
  }
  getLogPageList(params){
    return this.post(PREWARNINGAPI.GETLOGPAGELIST,params);
  }
  delPwLog(params){
    return this.post(PREWARNINGAPI.DELPWLOG,params);
  }
  getPwLogDetail(params){
    return this.post(PREWARNINGAPI.GETPWLOGDETAIL,params);
  }
}
export const prewarningService = new PrewarningService();


// import BasicService from './BasicService';
// import { PREWARNING_API } from './ApiServer';

// class PrewarningService extends BasicService {
//   constructor() {
//     super();
//   }

//   savePrewarning(params){
//     return this.post(PREWARNING_API.SAVE_PREWARNING,params)
//   }
//   getPwList(params){
//     return this.post(PREWARNING_API.GET_PW_LIST,params);
//   }
//   updateWarnStatus(params){
//     return this.post(PREWARNING_API.UPDATE_WARN_STATUS,params);
//   }
//   delPrewarning(params){
//     return this.post(PREWARNING_API.DEL_PREWARNING,params);
//   }
//   getLogPageList(params){
//     return this.post(PREWARNING_API.GET_LOG_PAGE_LIST,params);
//   }
//   delPwLog(params){
//     return this.post(PREWARNING_API.DEL_PW_LOG,params);
//   }
//   getPwLogDetail(params){
//     return this.post(PREWARNING_API.GET_PW_LOG_DETAIL,params);
//   }
// }
// export const prewarningService = new PrewarningService();

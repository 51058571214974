 
import profile from './profile/profile.module'
import dtpManager from './dtpManager/dtpManager.module'
import prewarning from './prewarning/prewarning.module'
import dashboard from './dashboard/Dashboard.module'
import chartwidget from './chartwidget/chartwidget.module'
import DbLayout from './dashboard/layout/DbLayout.module'
import userAdmin from './userAdmin/userAdminCtrl.module'
import dataset from './dataset/datasetCtrl.module'
import userRes from './userRes/userResCtrl.module'
import userRoleAdmin from './userRoleAdmin/userRoleAdminCtrl.module'
import boardList from './dashboard/list/boardList.module'
import modelCenter from './modelCenter/modelCenter.module'
import orderCenter from './orderCenter/orderCenter.module'
import accountCenter from './accountCenter/accountCenter.module'
import userPanel from './userPanel/userPanel.module'
import dataApp from './dataApp/dataAppCtrl.module'
import userprofile  from './userProfile/userProfile.module'
import report  from './report/report.module'
import dataSupport from './dataSupport/dataSupportCtrl.module'
import office from './office/officeCtrl.module'
import collection  from './collection/collection.module'
import message  from './message/message.module'
import redirect  from './redirect/redirect.module'
import timerPush  from './timerPush/timerPush.module'
import boreProperty  from './boreProperty/boreProperty.module'
import licenseInfo from  './licenseInfo/licenseInfo.module'
import behaviorLog from './behaviorLog/behaviorLog.module'
import accessControl from './accessControl/accessControl.module'
import archives from './archives/archives.module';

var  angularPage= angular.module('pcbore.pages', [
  profile,dtpManager,dashboard,chartwidget,DbLayout,prewarning,userAdmin,
  dataset, userRes, modelCenter, orderCenter,accountCenter,userRoleAdmin,
  userprofile,boardList,userPanel,dataApp,report, dataSupport, office,collection,message,redirect,timerPush
  ,boreProperty,licenseInfo,behaviorLog,accessControl, 
  archives
])

export default  angularPage.name
  
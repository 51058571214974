class ChartManager {
    constructor() {
        window.BORECHARTS = {};
        window.BORECHARTS.charts = [];
    }
    mountChart(name, chart) {
        // console.log(name)
        // console.log(chart)
        window.BORECHARTS.charts[name] = chart;
    }
    getChart(name) {
        return window.BORECHARTS.charts[name];
    }
    getChartIcon(name) {
        return window.BORECHARTS.charts[name].config.info.icon;
    }
    getAllCharts() {
        return window.BORECHARTS.charts;
    }
    getChartRender(name) {
        // console.log(window.BORECHARTS.charts[name].render)
        return window.BORECHARTS.charts[name].render;
    }
    getChartInfos(translate) {
        var infos = []
        var charts = this.getAllCharts()
        Object.keys(charts).forEach(function (key) {
            if (!_.isUndefined(charts[key].config) && !_.isUndefined(charts[key].config.info)) {
                // console.log(key, charts[key]);
                var info = charts[key].config.info;
                if (info.hasOwnProperty('index')) {
                    infos.splice(info.index, 0, {
                        name: translate(info.name),
                        value: info.value,
                        icon: info.icon,
                        row: translate(info.row),
                        column: translate(info.column),
                        measure: translate(info.measure)
                    })
                } else {
                    infos.push({
                        name: translate(info.name),
                        value: info.value,
                        icon: info.icon,
                        row: translate(info.row),
                        column: translate(info.column),
                        measure: translate(info.measure)
                    })
                }
            }
        })
        return infos;
    }
}

const chartManager = new ChartManager();
export default chartManager;
import collectionTpl from './collection.html'
import collectionCtrl  from './collectionCtrl'
  
export default angular.module('borepc.pages.collection', [])
.controller('collectionCtrl', ['$scope', 'toastr',  'ModalUtils',   '$filter', '$state',collectionCtrl])
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('collection', {
      url: '/collection',
      title: 'collection',
      template: collectionTpl,
      title: '收藏列表',
      controller: 'collectionCtrl',
    })
}


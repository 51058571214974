import { datasetService,userService,datasetMetaTableService,behaviorLogService,widgetService } from '../../../server'
import {util} from '../../../common/utils/util';
import dataContext from  '../../../charts/DataContext'
import  PubSub from '../../../common/utils/PubSub'
export default function DsEditorModelCrtl($scope, $filter, toastr, dataSetId, categoryName,$uibModal, uuid4, ModalUtils) {
    var translate = $filter('translate');
    $scope.optFlag = 'none';
    $scope.verify = {
        dsName: true
    };
    $scope.queryAceOpt = cbAcebaseOption;
    $scope.hierarchy = translate("CONFIG.DATASET.HIERARCHY");
    $scope.uuid4 = uuid4;
    var trash = {};
    $scope.curDataset = {
        categoryName:categoryName,
        name:categoryName?categoryName + '/':'',
        data: {
            id:null,
            expressions: [],
            filters: [],
            schema: {
                dimension: [],
                measure: []
            }
        }
    };
    $scope.getAceMetaNotice = function(){
        datasetMetaTableService.getAllList().then(function(response){
            $scope.AceKeys = ["Table.sql('')","${Table.sql('')}","SELECT","FROM","WHERE"];
            if(response && response.length>0){
                angular.forEach(response,function(data){
                    $scope.AceKeys.push(data.code);
                })
            }
            $scope.metaAceOpt = expEditorOptions($scope.AceKeys, [], function (_editor) {
                _editor.focus();
            });
        },err=>{
            console.log("err",err.head.errorMsg)
        })
    }
   
    $scope.getAceMetaNotice();
    var getDatasourceList = function (callback) {
        if ($scope.dtp.isDtpManager) {
            datasetService.getDataSourceList({
                "dataJson": angular.toJson($scope.dtp)
            }).then(function (response) {
                if (response.status != '1') {
                    toastr.error("获取数据源列表失败,请检查您的DTP配置!");
                    if (callback) {
                        callback();
                    }
                } else {
                    $scope.datasourceList = response.data;
                    if (callback) {
                        callback();
                    }
                }
            })
        } else {
            // $http.get("api/bcp/dashboard/getDatasourceList")
            datasetService.getDataSourceListForDashboard().then(function (response) {
                $scope.datasourceList = response;
                if (callback) {
                    callback();
                }
            });
        }
    }

    $scope.changeSql = function(sql){
        datasetService.getCompleteDataSql({sql:sql,exchangeUser:false}).then(function(data){
            $scope.curDataset.data.query.sql = data;
            toastr.success("替换成功！")
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg||"获取完整SQL失败！")
        })
    }

    //查看图表数据
    $scope.viewData =function(dataset,dtp,datasource){
        if(!dtp ||!dtp.isDtpManager){
            toastr.warning("操作失败，该功能暂不支持【无数据节点】方式!");
            return;
        }
        $uibModal.open({
            animation: true,
            backdrop: false,
            template: require('./template/datasetData.html'),
            windowClass: 'dataset-data-model',
            resolve: {
                datasetPreview: function () {
                    return function (params,callback) {
                         dataContext.previewDatasetData(params,function(response){
                            if(response.status=="0"){
                                toastr.error(response.errorMsg || '获取引用图表列表失败！')
                                return;
                            }
                            callback(response)
                        },err=>{
                            toastr.error(err.head.errorMsg || '获取引用图表列表失败！')
                        })                                          
                    };
               },
               judgePermission: function () {
                return function (params,callback) {
                    userService.judgePermission(params).then(function(response){
                        callback(response)
                    },err=>{
                        toastr.error(err.head.errorMsg || '您没有数据集查看权限！')
                    })                                          
                };
               },
            },
            controller: ['$scope','toastr', '$uibModal', 'datasetPreview','judgePermission','widgetModal','$uibModalInstance',function( $scope,toastr, $uibModal,datasetPreview,judgePermission,widgetModal,$uibModalInstance ){                    
                $scope.paginationConf = {
                    currentPage: 1, //第几页
                    totalItems: 0, //共几条
                    itemsPerPage: 20, //每页最多几条
                    onChange: function () {
                        $scope.previewDatasetData();
                    }
                };
                $scope.previewDatasetData = function(){
                    var params = {
                        query:JSON.stringify(dataset.data.query),
                        isDtpManager:dtp.isDtpManager,
                        dtpId:dtp.id,
                        datasourceId:datasource.id,
                        pageNum:$scope.paginationConf.currentPage, 
                        pageSize:$scope.paginationConf.itemsPerPage
                    }
                    $scope.tableId = "dataset-table"+util.randomID();
                    datasetPreview(params,function(response){
                        $scope.datasetData = response.data;
                        $scope.paginationConf.totalItems = response.total;
                        $scope.pageTotal = getPageTotal($scope.paginationConf.currentPage,$scope.paginationConf.itemsPerPage)
                        $scope.orginazeDataTable(response.data,$scope.tableId);
                        $scope.$apply();
                    })
                }
                $scope.orginazeDataTable =function(data,tableId){
                    // jQuery("#jqGrid")
                    
                    $("#datasetDataId").jqGrid('GridUnload');
                    var colNames=[]
                    var colModel=[]
                    $.each(data[0],function(key,value){
                        colNames.push(key);
                        colModel.push({
                            name:key,
                            align: "left",
                            editable: true,
                            editoptions: { size: 50 },
                            index:key
                        });
                    })
                    jQuery('#datasetDataId').jqGrid(
                        {
                            data: data,
                            datatype: "local",
                            mtype: "POST",
                            styleUI: 'Bootstrap',//设置jqgrid的全局样式为bootstrap样式 
                            colNames: colNames,
                            colModel: colModel,
                            rowNum : $scope.pageSize,
                            rowList: [20, 30, 40],
                            rownumbers: true,
                            sortname: 'id', //默认的排序列。可以是列名称或者是一个数字，这个参数会被提交到后台
                            height: "auto",
                            autowidth: true,
                            shrinkToFit:colNames.length>10?false:true,
                            autoScroll: true,
                            hidegrid:false,//隐藏收缩按钮
                        });
                }
                $scope.previewDatasetData();

                $scope.close = function(){
                    $uibModalInstance.close();
                }
                var getPageTotal = function(pageSize,total){
                    if (total == null || total == "") {
                        return 0;
                    } else {
                        if (pageSize != 0 && total % pageSize == 0) {
                            return parseInt(total / pageSize);
                        }
                        if (pageSize != 0 && total % pageSize != 0) {
                            return parseInt(total / pageSize) + 1;
                        }
                    }
                }

                $scope.goEditWidget=function(widgetId) {
                    judgePermission({option:"update",resType:"widget",resId:widgetId},function(data){
                         if(!data){
                             toastr.error("您没有编辑该图表权限！")
                             return;
                         }
                         var modalInstances = widgetModal.open(widgetId);
                         if(modalInstances.result){
                              modalInstances.result.then(function(){
                                    $scope.getWidgetList();
                              })
                         }
                     })
                }
            }]
        });
    }

    $scope.viewSql = function(sql){
        $uibModal.open({
            animation: true,
            template: require('./template/completeSql.html'),
            backdrop: false,
            size: 'lg',
            windowClass: 'complete-sql-model',
            resolve: {
                sql: function () {
                    return sql;
                },
                metaAceOpt:function(){
                    return $scope.metaAceOpt;
                },
                
                getCompleteDataSql:function(){
                    return function (callback) {
                         datasetService.getCompleteDataSql({sql:sql}).then(function(data){
                            callback(data);
                            $scope.$apply();
                        },err=>{
                            toastr.error("获取完整SQL失败！")
                        })
                    };
               },
            },
            controller: ['metaAceOpt','$uibModalInstance','$scope', 'toastr','sql','getCompleteDataSql', function(metaAceOpt,$uibModalInstance,$scope,toastr,sql,getCompleteDataSql){
                $scope.sql = sql;
                // $scope.metaAceOpt = angular.copy(metaAceOpt)
                getCompleteDataSql(function(data){
                    $scope.completeSql = data;
               })
               $scope.close = function () {
                $uibModalInstance.close();
                };
            //    $scope.copySql = function () {
            //         var copySql = document.getElementById("completeSqlId");
            //         copySql.select(); // 选择对象  
            //         document.execCommand("Copy"); // 执行浏览器复制命令  
            //         toastr.success("复制成功")
            //     }
            }],
        });
       
    }

    var initDataSet = () => {
        if (dataSetId) {
            datasetService.getDatasetDetail({
                "id": dataSetId
            }).then(function (response) {
                if (response.dataset) {
                    $scope.dataset = response.dataset;
                    $scope.dataset.data = angular.fromJson(response.dataset.data);
                    $scope.datasource = response.datasource;
                    if ($scope.datasource) {
                        $scope.datasource.config = angular.fromJson(response.datasource.config)
                    } else {
                        toastr.error("获取数据源信息失败,请检查您的数据源连接!");
                    }
                    $scope.dtp = response.dtp || {
                        id: "-1",
                        label: "无",
                        isDtpManager: false
                    };
                    getDatasourceList(function () {
                        // if ($scope.datasource) {
                        //     $scope.editDs($scope.dataset)
                        //     return;
                        // };
                        doEditDs($scope.dataset);
                    })
                    // doEditDs($scope.dataset);
                    $scope.inserBehaviortLog();
                } else {
                    toastr.error("获取数据集信息失败!");
                }
            }).catch(function () {
                toastr.error("获取数据集信息失败!");
            });
        } else {
            //getDatasourceList(()=>{})
        }
    }
    initDataSet()
    $scope.inserBehaviortLog =function(){
        try {
            behaviorLogService.insertLog({module:"dataset",function:"查看数据集",name:$scope.dataset.name})
        } catch (error) {
            // console.log("插入日志失败:"+error)
        }
    }
    var doEditDs = function (ds) {
        $scope.optFlag = 'edit';
        $scope.curDataset = angular.copy(ds);
        $scope.curDataset.name = $scope.curDataset.categoryName + '/' + $scope.curDataset.name;
        if (!$scope.curDataset.data.expressions) {
            $scope.curDataset.data.expressions = [];
        }
        if (!$scope.curDataset.data.filters) {
            $scope.curDataset.data.filters = [];
        }
        if (!$scope.curDataset.data.schema) {
            $scope.curDataset.data.schema = {
                dimension: [],
                measure: []
            };
        }
        // //当dtpId为空时默认给-1
        // if(!$scope.curDataset.data.dtpId)$scope.curDataset.data.dtpId='-1';
        // //根据加载的数据集匹配数据集所对应的dtp
        // $scope.dtp = _.find($scope.dtpList, function (e) {
        //     return e.id == $scope.curDataset.data.dtpId;
        // });
        // //当dtp没匹配上的时候,说明并非dtp管理,给一个默认的dtp
        // if(!$scope.dtp)$scope.dtp ={"id":"-1","label":"无","isDtpManager":false};
        //加载并匹配数据源
        // console.log($scope.curDataset.data)
        // $scope.curWidget.query = $scope.curDataset.data.query;
        $scope.selects = ds.data.selects;
        $scope.$apply();
        // $scope.showEditor()
        //$scope.loadData();
    };

     
    $scope.loadData = function (reload) {
        if (reload != true) {
            reload = false;
        }
        $scope.loading = true;
        dataContext.getColumns({
            isDtpManager: $scope.dtp.isDtpManager,
            dtpId: $scope.dtp.id,
            datasource: $scope.datasource.id,
            query: $scope.curDataset.data.query,
            datasetId: null,
            reload: reload,
            callback: function (dps) {
                $scope.loading = false;
                $scope.toChartDisabled = false;
                if (dps.msg == "1") {
                    $scope.alerts = [];
                    $scope.selects = dps.columns;
                } else {
                    $scope.alerts = [{
                        msg: dps.msg,
                        type: 'danger'
                    }];
                }

                // var widget = {
                //     chart_type: "table",
                //     filters: [],
                //     groups: [],
                //     keys: [],
                //     selects: [],
                //     values: [{
                //         cols: []
                //     }]
                // };
                // _.each($scope.selects, function (c) {
                //     widget.keys.push({
                //         col: c,
                //         type: "eq",
                //         values: []
                //     });
                // });
                var oldSelects = $scope.curDataset.data.selects;
                $scope.curDataset.data.selects = $scope.selects;
                //记录丢失的维度
                $scope.lostDimension = [];
                $scope.lostDimension = _.filter(oldSelects, function(value){
                    return $scope.curDataset.data.selects.indexOf(value) == -1;
                });
                if($scope.lostDimension.length > 0) {
                    $scope.hasLostDimension = true;
                }

                $scope.$apply()
            }
        });
    };
    $scope.hasLostDimension = false;
    //清除丢失维度的方法
    $scope.deleteLostDimension = function(){

        //去掉指标列中丢失的维度值
        $scope.curDataset.data.schema.measure = _.filter($scope.curDataset.data.schema.measure, function(value){
            return  $scope.curDataset.data.selects.indexOf(value.column) !== -1;
        });
        //去掉维度列中丢失的维度值
        for (var i = 0; i< $scope.curDataset.data.schema.dimension.length; i++) {
            //是值的情况
            if( $scope.curDataset.data.schema.dimension[i].type == 'column' && $scope.curDataset.data.selects.indexOf($scope.curDataset.data.schema.dimension[i].column) == -1) {
                $scope.curDataset.data.schema.dimension.splice(i, 1);
                i--;
            }
            //出现分支的情况
            if( $scope.curDataset.data.schema.dimension[i].type == 'level') {
                $scope.curDataset.data.schema.dimension[i].columns = _.filter($scope.curDataset.data.schema.dimension[i].columns, function(value){
                    return  $scope.curDataset.data.selects.indexOf(value.column) !== -1;
                });
            }
        }
        //表达式
        var lostExp = [];       //无效的表达式的名称数组
        for(var i= 0; i< $scope.lostDimension.length; i++){
            for (var j = 0; j < $scope.curDataset.data.expressions.length; j++) {
                //丢失的维度是否出现在表达式中
                var index = $scope.curDataset.data.expressions[j].exp.indexOf($scope.lostDimension[i]);
                //去掉失效的表达式
                if(index > -1){
                    lostExp.push($scope.curDataset.data.expressions[j].alias);
                    $scope.curDataset.data.expressions.splice(j, 1);
                    j--;
                }
                // if(index > -1 && isExpString($scope.curDataset.data.expressions[j].exp, $scope.lostDimension[i], index)){
                //     lostExp.push($scope.curDataset.data.expressions[j].alias);
                //     $scope.curDataset.data.expressions.splice(j, 1);
                //     j--;
                // }
            }
        }
        if(lostExp.length > 0) {
            deleteUselessExp(lostExp);  //去掉引入了失效表达式的表达式
        }

        //过滤器
        for(var i = 0 ; i < $scope.curDataset.data.filters.length; i++){
            $scope.curDataset.data.filters[i].filters = _.filter($scope.curDataset.data.filters[i].filters, function(value){
                return $scope.curDataset.data.selects.indexOf(value.col) !== -1;
            });
        }
        $scope.hasLostDimension = false;
    };

    // //表达式中找到的一个子字符串判断是不是一个完整的维度-----------枚举可能性过多--废置
    // function isExpString(exp, string, index){
    //     var a = index - 1;
    //     var b = index + string.length;
    //     //维度值引入的可能情况----sql(维度)、sql(维度,...)、sql(...,维度)、sql(..., 维度,...) 空格等
    //     if(exp[a] == '(') {
    //         if(exp[b] == ')' || exp[b] == ',') {
    //             return true;
    //         }
    //     } else if(exp[a] == ',') {
    //         if(exp[b] == ')' || exp[b] == ',') {
    //             return true;
    //         }
    //     }
    //     return false
    // }

    //递归去掉无效的表达式
    function deleteUselessExp(useless){
        var lostExp = []; 
        for(var i= 0; i< useless.length; i++){
            for (var j = 0; j < $scope.curDataset.data.expressions.length; j++) {
                //无效的表达式是否出现在表达式中
                var index = $scope.curDataset.data.expressions[j].exp.indexOf(useless[i]);
                //去掉失效的表达式-----引入方式为｛表达式｝
                if(index > -1 && $scope.curDataset.data.expressions[j].exp[index - 1] == '{' && $scope.curDataset.data.expressions[j].exp[index + useless[i].length] == '}'){
                    lostExp.push($scope.curDataset.data.expressions[j].alias);
                    $scope.curDataset.data.expressions.splice(j, 1);
                    j--;
                }
            }
        }
        if(lostExp.length > 0) {
            deleteUselessExp(lostExp);
        }
    }






    $scope.toTrash = function (array, index) {
        var o = array[index];
        if (o.type == 'column') {
            if (!trash[o.column]) {
                trash[o.column] = [];
            }
            trash[o.column].push(o);
        }
        array.splice(index, 1);
    };

    $scope.dndTransfer = {
        dimension: function (list, index, item, type) {
            if (type == 'column') {
                list[index] = {
                    type: 'column',
                    column: item
                };
            }
        },
        measure: function (list, index, item, type) {
            if (type == 'column') {
                list[index] = {
                    type: 'column',
                    column: item
                };
            }
        }
    };
    var getDtpList = function (callback) {
        datasetService.getDtpList().then(function (response) {
            $scope.dtpList = [{
                id: "-1",
                label: "无",
                isDtpManager: false
            }];
            angular.forEach(response.data.listdata, function (item) {
                $scope.dtpList.push(item)
            })
            if (callback) {
                callback();
            }
        });
    }
    getDtpList();
    //改变选择的dtp
    $scope.changeDtp = function (dtp) {
        if (dtp) $scope.dtp = dtp;
        getDatasourceList();
        $scope.datasource = null; //将数据源置空
        $scope.datasourceList = []
    }
    var getDatasourceList = function (callback) {
        if ($scope.dtp.isDtpManager) {
            datasetService.getDataSourceList({
                "dataJson": angular.toJson($scope.dtp)
            }).then(function (response) {
                if (response.status != '1') {
                    ModalUtils.alert("获取数据源列表失败,请检查您的DTP配置!", "modal-warning", "lg");
                } else {
                    $scope.datasourceList = response.data;
                }
                if (callback) {
                    callback();
                }
            })
        } else {
            // $http.get("api/bcp/dashboard/getDatasourceList")
            datasetService.getDataSourceListForDashboard().then(function (response) {
                $scope.datasourceList = response;
                if (callback) {
                    callback();
                }
            });
        }
    }

    $scope.checkExist = function (column) {
        var find = _.find($scope.curDataset.data.schema.measure, function (e) {
            return e.column == column;
        });
        if (!_.isUndefined(find)) {
            return true;
        }
        find = _.find($scope.curDataset.data.schema.dimension, function (e) {
            if (e.type == 'level') {
                var _find = _.find(e.columns, function (_e) {
                    return _e.column == column;
                });
                return !_.isUndefined(_find);
            } else {
                return e.column == column;
            }
        });
        return !_.isUndefined(find);
    };
    var validate = function () {
        $scope.alerts = [];
        if (!$scope.curDataset.name) {
            $scope.alerts = [{
                msg: translate('CONFIG.DATASET.NAME') + translate('COMMON.NOT_EMPTY'),
                type: 'danger'
            }];
            $scope.verify = {
                dsName: false
            };
            $("#DatasetName").focus();
            return false;
        }
        for (i in $scope.params) {
            var name = $scope.params[i].name;
            var label = $scope.params[i].label;
            var required = $scope.params[i].required;
            var value = $scope.curWidget.query[name];
            if (required == true && value != 0 && (value == undefined || value == "")) {
                var pattern = /([\w_\s\.]+)/;
                var msg = pattern.exec(label);
                if (msg && msg.length > 0)
                    msg = translate(msg[0]);
                else
                    msg = label;
                $scope.alerts = [{
                    msg: "[" + msg + "]" + translate('COMMON.NOT_EMPTY'),
                    type: 'danger'
                }];
                $scope.verify[name] = false;
                return false;
            }
        }
        return true;
    };
    $scope.save = function () {
        $scope.datasource ? $scope.curDataset.data.datasource = $scope.datasource.id : null;
        // $scope.curDataset.data.query = $scope.curWidget.query;
        if (!validate()) {
            return;
        }
        var ds = angular.copy($scope.curDataset);
        var index = ds.name.lastIndexOf('/');
        ds.categoryName = $scope.curDataset.name.substring(0, index).trim();
        ds.name = $scope.curDataset.name.slice(index + 1).trim();
        ds.data.name = $scope.curDataset.name.slice(index + 1).trim();
        ds.data.dtpId = $scope.dtp.id;
        ds.data.isDtpManager = $scope.dtp.isDtpManager;
        ds.data.mappingName = $scope.datasource.dbIdentifier || '';
        if (ds.categoryName == '') {
            ds.categoryName = translate("COMMON.DEFAULT_CATEGORY");
        }
        if (!$scope.curDataset.id) {
            datasetService.saveNewDataset({
                json: angular.toJson(ds)
            }).then(function (serviceStatus) {
                if (serviceStatus.status == '1') {
                    // getCategoryList();
                    // getDatasetList();
                    $scope.verify = {
                        dsName: true
                    };
                    $scope.curDataset.id = serviceStatus.id;
                     toastr.success("保存数据集成功!");
                    $scope.$dismiss();
                    //去除遮盖
                    $('.modal-backdrop').css("z-index","-1");
                    PubSub.publish('DATASETCHANGE','') 
                } else {
                    $scope.alerts = [{
                        msg: serviceStatus.msg,
                        type: 'danger'
                    }];
                }
            });
        } else {
            userService.judgePermission({option:"update",resType:"dataset",resId:$scope.curDataset.id}).then(function(data){
                if(!data){
                    toastr.error("您没有编辑该数据集权限！")
                    return;
                }
                datasetService.updateDataset({
                    json: angular.toJson(ds)
                }).then(function (serviceStatus) {
                    if (serviceStatus.status == '1') {
                        $scope.optFlag = 'edit';
                        $scope.verify = {
                            dsName: true
                        };
                        toastr.success("保存数据集成功!");
                        $scope.$dismiss();
                        PubSub.publish('DATASETCHANGE','')
                    } else {
                        $scope.alerts = [{
                            msg: serviceStatus.msg,
                            type: 'danger'
                        }];
                    }
                });
            },err=>{
                toastr.error(err.head.errorMsg || '获取权限失败！');
            })
        }

    };
    $scope.editFilterGroup = function (col) {
        var columnObjs = schemaToSelect($scope.curDataset.data.schema);
        $uibModal.open({
            // template: filterGroupHtml,
            template: require('./filterGroup.html'),
            // windowTemplate: require('../../../public/util/modal/window.html'),
            backdrop: false,
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                var oldFilters;
                if (col) {
                    $scope.data = angular.copy(col);
                    oldFilters = _.filter($scope.$parent.curDataset.data.filters, function(filter){
                        return filter.group != $scope.data.group;
                    });
                } else {
                    $scope.data = {
                        group: '',
                        filters: [],
                        id: uuid4.generate()
                    };
                    oldFilters = $scope.$parent.curDataset.data.filters;
                }
                $scope.columnObjs = columnObjs;
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                $scope.addColumn = function (str) {
                    $scope.data.filters.push({
                        col: str,
                        type: '=',
                        values: []
                    })
                };
                $scope.ok = function () {
                    console.log($scope.$parent.curDataset.data.filters)
                    if (!$scope.data.group) {
                        toastr.error("别名不能为空！")
                        return;
                    }
                    var flag = false;
                    for(var i = 0; i < oldFilters.length ; i++) {
                        if(oldFilters[i].group === $scope.data.group) {
                            flag = true;
                            break;
                        }
                    }
                    if(flag) {
                        toastr.error("别名不能重复！")
                        return;
                    }
                    if (col) {
                        col.group = $scope.data.group;
                        col.filters = $scope.data.filters;
                    } else {
                        if ($scope.$parent.curDataset.data.filters == null) {
                            $scope.$parent.curDataset.data.filters = [];
                        }
                        $scope.$parent.curDataset.data.filters.push($scope.data);
                    }
                    $uibModalInstance.close();
                };
                $scope.editFilter = function (filter) {
                    $uibModal.open({
                        template: require('./param.html'),
                        // windowTemplate: require('../../../public/util/modal/window.html'),
                        backdrop: false,
                        size: 'lg',
                        resolve: {
                            param: function () {
                                return angular.copy(filter);
                            },
                            filter: function () {
                                return false;
                            },
                            getSelects: function () {
                                return function (byFilter, column, callback) {
                                    dataContext.getDimensionValues($scope.datasource.id, $scope.curDataset.data.query, $scope.dtp.id, $scope.dtp.isDtpManager, undefined, column, undefined, function (filtered) {
                                        callback(filtered);
                                    });
                                };
                            },
                            ok: function () {
                                return function (param) {
                                    filter.type = param.type;
                                    filter.values = param.values;
                                }
                            }
                        },
                        controller: 'paramSelector'
                    });
                };
            }]
        });
    };

    $scope.deleteFilterGroup = function (index) {
        ModalUtils.confirm(translate("COMMON.FILTER_GROUP") + ": [" + $scope.curDataset.data.filters[index].group + "], " +
            translate("COMMON.CONFIRM_DELETE"), "modal-warning", "lg",
            function () {
                $scope.curDataset.data.filters.splice(index, 1)
            }
        );
    };

    var schemaToSelect = function (schema, rawSelects) {
        if (schema.selects) {
            return angular.copy(schema.selects);
        } else {
            var selects = [];
            selects = selects.concat(schema.measure);
            _.each(schema.dimension, function (e) {
                if (e.type == 'level') {
                    _.each(e.columns, function (c) {
                        selects.push(c);
                    });
                } else {
                    selects.push(e);
                }
            });
            _.each(rawSelects, function (col) {
                if (_.find(selects, function (o) {
                    return col == o.column;
                }) === undefined) {
                    selects.push({
                        column: col
                    });
                }
            });
            return angular.copy(selects);
        }
    };

    $scope.editExp = function (col) {
        var aggregate = [{
            name: 'sum',
            value: 'sum'
        },
        {
            name: 'count',
            value: 'count'
        },
        {
            name: 'avg',
            value: 'avg'
        },
        {
            name: 'max',
            value: 'max'
        },
        {
            name: 'min',
            value: 'min'
        },
        {
            name: 'distinct',
            value: 'distinct'
        }
        ];
        var ok;
        var data = {
            expression: ''
        };
        if (!col) {
            ok = function (exp, alias) {
                $scope.curDataset.data.expressions.push({
                    type: 'exp',
                    exp: data.expression,
                    alias: data.alias,
                    id: uuid4.generate()
                });
            }
        } else {
            data.expression = col.exp;
            data.alias = col.alias;
            ok = function (data) {
                col.exp = data.expression;
                col.alias = data.alias;
            }
        }
        var columnObjs = schemaToSelect($scope.curDataset.data.schema, $scope.selects);
        var expressions = $scope.curDataset.data.expressions;
        $uibModal.open({
            template: require('./exp.html'),
            // windowTemplate: require('../../../public/util/modal/window.html'),
            backdrop: false,
            size: 'lg',
            scope: $scope,
            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                $scope.data = data;
                $scope.columnObjs = columnObjs;
                $scope.aggregate = aggregate;
                //将自己去掉，防止自己调用自己
                if($scope.data.alias) {
                    $scope.expressions = _.filter(expressions, function(exp){
                        return exp.alias !== $scope.data.alias;
                    });
                } else {
                    $scope.expressions = expressions;
                }
                $scope.alerts = [];
                $scope.expAceOpt = expEditorOptions($scope.selects, aggregate, function (_editor) {
                    $scope.expAceEditor = _editor;
                    $scope.expAceSession = _editor.getSession();
                    _editor.focus();
                });
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                $scope.addToken = function (str, agg) {
                    var editor = $scope.expAceEditor;
                    editor.session.insert(editor.getCursorPosition(), str);
                    editor.focus();
                    if (agg) editor.getSelection().moveCursorLeft();
                };
                $scope.verify = function () {
                    $scope.alerts = [];
                    var v = verifyAggExpRegx($scope.data.expression);
                    $scope.alerts = [{
                        msg: v.isValid ? translate("COMMON.SUCCESS") : v.msg,
                        type: v.isValid ? 'success' : 'danger'
                    }];
                };
                $scope.ok = function () {
                    if (!$scope.data.alias) {
                        // ModalUtils.alert(translate('CONFIG.WIDGET.ALIAS') + translate('COMMON.NOT_EMPTY'), "modal-warning", "lg");
                        toastr.error("别名不能为空！")
                        return;
                    }
                    var flag = false;
                    for(var i = 0; i < $scope.expressions.length ; i++) {
                        if($scope.expressions[i].alias === $scope.data.alias) {
                            flag = true;
                            break;
                        }
                    }
                    if(flag) {
                        toastr.error("别名不能重复！")
                        return;
                    }
                    $scope.data.expression = $scope.expAceSession.getValue();
                    ok($scope.data);
                    $uibModalInstance.close();
                };
            }]
        });
    };

    $scope.deleteExp = function (index) {
        ModalUtils.confirm(translate("CONFIG.COMMON.CUSTOM_EXPRESSION") + ": [" + $scope.curDataset.data.expressions[index].alias + "], " +
            translate("COMMON.CONFIRM_DELETE"), "modal-warning", "lg",
            function () {
                $scope.curDataset.data.expressions.splice(index, 1)
            }
        );
    };
    $scope.createNode = function (item) {
        if (trash[item.column]) {
            var _i = trash[item.column].pop();
            if (_i) {
                return _i;
            }
        }
        item.id = uuid4.generate();
        return item;
    };

    $scope.measureToDimension = function (index, o) {
        $scope.curDataset.data.schema.measure.splice(index, 1);
        $scope.curDataset.data.schema.dimension.push(o);
    };

    $scope.toDimension = function (o) {
        $scope.curDataset.data.schema.dimension.push($scope.createNode(o));
    };

    $scope.custom = function (o) {
        var selects = $scope.selects;
        var datasource = $scope.datasource;
        $uibModal.open({
            template: require('./custom.html'),
            // windowTemplate: require('../../../public/util/modal/window.html'),
            backdrop: false,
            size: 'lg',
            controller: ['$scope', '$uibModalInstance', function ($scope, $uibModalInstance) {
                $scope.c = o;
                $scope.ok = function () {
                    $uibModalInstance.close();
                };
                // $scope.customAceOpt = schemaCustomOpt(selects, datasource.type);
            }]
        });
    };
    $scope.clickDs = function () {
        // if(!$scope.dtp){
        //     ModalUtils.alert("请先选择DTP", "modal-warning", "lg");
        //     return;
        // }
        if (!$scope.dtp) {
            $scope.dtp = {
                id: "-1",
                label: "无",
                isDtpManager: false
            }
            getDatasourceList();
        }
    }

    $scope.changeDs = function (datasource) {
        if (!$scope.dtp) {
            ModalUtils.alert("请先选择DTP", "modal-warning", "lg");
            return;
        }
        if (datasource) $scope.datasource = datasource;
        // $scope.curWidget.query = {};
        if ($scope.dtp.isDtpManager) {
            $scope.datasource.type = 'ODTP'
        }
        // $http.get('api/bcp/dashboard/getConfigParams', {
        //     params: {
        //         type: $scope.datasource.type,
        //         datasourceId: $scope.datasource.id,
        //         dtpId: $scope.dtp.id, 
        //         page: 'dataset.html',
        //         isDtpManager: $scope.dtp.isDtpManager
        //     }
        // })
        datasetService.getConfigParams({
            type: $scope.datasource.type,
            datasourceId: $scope.datasource.id,
            dtpId: $scope.dtp.id,
            page: 'dataset.html',
            isDtpManager: $scope.dtp.isDtpManager
        }).then(function (response) {
            $scope.params = response.data;
            for (i in $scope.params) {
                var name = $scope.params[i].name;
                var value = $scope.params[i].value;
                var checked = $scope.params[i].checked;
                var type = $scope.params[i].type;
                if (type == "checkbox" && checked == true) {
                    $scope.curWidget.query[name] = true;
                }
                if (type == "number" && value != "" && !isNaN(value)) {
                    $scope.curWidget.query[name] = Number(value);
                } else if (value != "") {
                    $scope.curWidget.query[name] = value;
                }
            }
        });
    };
    /** Ace Editor Starer... **/
    $scope.queryAceOpt = datasetEditorOptions();
    //编辑弹窗最大化
    $scope.editFullScreenFlag = false;
    $scope.toEditFullScreen = function () {
        if (!$scope.editFullScreenFlag) {
            $('.widgetmodal .modal-dialog').removeClass('modal-lg').addClass('modal-fullScreen');
            $('.widgetmodal .modal-header button span i').removeClass().addClass('fa fa-minus');
            $scope.editFullScreenFlag = true;
        } else {
            $('.widgetmodal .modal-dialog').removeClass('modal-fullScreen').addClass('modal-lg');
            $('.widgetmodal .modal-header button span i').removeClass().addClass('fa fa-square-o');
            $scope.editFullScreenFlag = false;
        }
    }
}
import { apiUrl } from './config';
import axios from 'axios';
import { util, storage } from '../common/utils';

const globalOptions = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
    Authorization: 'Basic 123456'
  },
  isForm: true
};

export default class BasicService {
   constructor(baseURL = apiUrl) {
    this.baseURL = baseURL;
    this.apiVersion = baseURL;

    axios.defaults.timeout = 300000
    // 配置默认 url  baseURL不用这个
    // axios.defaults.baseURL = baseURL
    axios.defaults.withCredentials = true// 允许跨域
    // http request 拦截器
    axios.interceptors.request.use(
      config => {
        var token= util.getCookie('token')
        if(token)storage.set("x-token",token);
        if (null == token) {
          try {
            token= storage.get('x-token');
            // token= util.getCookie('token');
          } catch (error) {
          }
        }
        if (null == token) {
          try {
            token= util.getUrlPara("token");
            if(token)storage.set("x-token",token);
          } catch (error) {

          }
        }
        config.headers['Authorization'] = token;
        // config.data = JSON.stringify(config.data)
        // config.paramsSerializer = (params) => {
        //   return QS.stringify(params, { indices: false })
        // }
        return config
      },
      error => {
        return Promise.reject(error)
      }
    )



  }
 
  get(url, data, head, options) {
    return this._request('GET', url, data, head, options);
  }

  post(url, data, head, options) {
    return this._request('POST', url, data, head, options);
  }

  post_file(url, data, head, onProgress) {
    return this._file_request(url, data, head, onProgress);
  }

  put(url, data, head, options) {
    return this._request('PUT', url, data, head, options);
  }

  delete(url, head, options) {
    return this._request('DELETE', url, null, head, options);
  }

  setToken(token) {
    globalOptions.headers['Authorization'] = token;
    storage.set('x-token', token);
  }

  getUrlPara(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
    var param = window.location.search.substr(1) || window.location.hash.split("?")[1];
    if(!param)return null;
    var para = param.match(reg); //search,查询？后面的参数，并匹配正则
    if (para != null) return unescape(para[2]);
    return null
  }

  _request(method, url, params, head, options = {}) {
    var baseHeaders = method === 'PUT' ? { 'Content-Type': 'application/json;charset=UTF-8' } : { 'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8' }
    var gateWayHearder={}
    var invokeUrl=''
    if(window.ISGATEWAY){
      gateWayHearder={
        authId: window.GATEWAY_PARAM.appId,
        apiUrl:this.apiVersion +url,
        authCode: window.GATEWAY_PARAM.authCode
      }
      invokeUrl='/be/gateway/share';
    }else{
      invokeUrl=this.apiVersion +url;
    }
    const headers = Object.assign({}, globalOptions.headers, options.headers, baseHeaders,gateWayHearder);
   
    return new Promise((resolve, reject) => {
      axios({
        method: method,
        url: invokeUrl,
        data: method === 'POST' || method === 'PUT' ? util.json2form(params) : null,
        params: method === 'GET' || method === 'DELETE' ? params : null,
        headers: headers
      }).then(function (response) {
        // console.log("url"+url,response.data)
        // alert("=======234"+url+"123")
        if (response.data.head != null) {
          if (response.data.head.status == 0 && response.data.head.errorCode == "0001") {
            //跳转页面时假如未登录，那么记录原先要跳转的链接，这样登录后就可以自动跳转过去
            var localhref = window.location.href;
            if(localhref.indexOf("login.html")==-1){
              storage.set('loginRedirectHtml', localhref);
            }
            localStorage.removeItem("x-token");
            // util.delCookie("token");
            console.log('当前用户未登陆！！！！',window.location.href);
            window.open('/login.html', '_self');
            // if(localhref.indexOf("openid")>-1){
            //   window.open('/login.html'+window.location.hash, '_self');
            // }else{
            // }
            
            //TODO 处理未登陆场景
          } else {

            // if (response.data.body == undefined) {
            //   reject(response.data);
            // }
            if (head) {
              resolve({ head: response.data.head, data: response.data.body });
            } else {
              if (response.data.head.status == 0) {
                reject(response.data);
              } else {
                if(response.data.body){
                  resolve(response.data.body);
                }else{
                  resolve(null);
                }
               
              }

            }

          }
        }
      }).catch(function (err) {
        console.log(err)
        reject('数据请求发生异常！')
      })
    })
  }

  _file_request(url, formData, head, onProgress) {
    var baseHeaders = { "Content-Type": "multipart/form-data" }
    const headers = Object.assign({}, globalOptions.headers, baseHeaders);
    return new Promise((resolve, reject) => {
      // console.log(this.apiVersion + url)
      axios.post(this.apiVersion + url, formData, {
        headers,
        onUploadProgress: ({ total, loaded }) => {
          if (onProgress) {
            onProgress({ percent: Math.round(loaded / total * 100).toFixed(2) });
          }

        },
      }).then(function (response) {
        if (response.data.head != null) {
          if (response.data.head.status == 0 && response.data.head.errorCode == "0001") {
            //跳转页面时假如未登录，那么记录原先要跳转的链接，以期登录后可以自动跳转过去
            // var loginRedirectURL = response.request.responseURL;
            // storage.set('loginRedirectURL', loginRedirectURL);
            // window.open(url, '_self');
            //TODO 处理未登陆场景
          } else {
            // if (response.data.head.status == 0) {
            //   resolve(response);
            // }
            if (response.data.body == undefined) {
              reject(response.data);
            }
            if (head) {
              resolve({ head: response.data.head, data: response.data.body });
            } else {
              if (response.data.head.status == 0) {
                reject(response.data);
              } else {
                resolve(response.data.body);
              }
            }

          }
        }
      }).then(function (err) {
        reject(err)
      })
    })
  }
}

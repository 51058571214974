import dtpDatasourceDetailTpl from './dtpDatasourceDetail.html'
import {dtpManagerService,behaviorLogService} from '../../../../server'
import windowTpl from '../../../../common/theme/components/modal/window.html'
import '../_dtpManager.scss'
export default  function dtpDatasourceCtrl (  $stateParams, $scope,$uibModal,toastr,ModalUtils) {
    // var translate = $filter('translate');
    $scope.isLoading = true;
    $scope.dtpId = angular.copy($stateParams.dtpJson);
    //获取Dtp列表
    $scope.getDataSourceList = function (data) {
        $scope.isLoading = true;
        dtpManagerService.getDataSourceListByDtpId({"dtpId":$scope.dtpId}).then(function (response) {
            if (response.status != '1') {
                $scope.isLoading = false;
                toastr.error("获取数据源列表失败,请检查您的DTP配置!");
            } else {
                $scope.dataSourceList = response.data.dataSourceList;
                $scope.dtp = response.data.dtp;
                $scope.isLoading = false;
                $scope.$apply();
            }
        })
    }
    $scope.getDataSourceList();

    //测试连接
    $scope.connectTestSource = function (data) {
        var dataJson = angular.copy($scope.dtp);
        dataJson.dtpDbConfigModel = data;
        
        dtpManagerService.connectTestSource({
            "dataJson": angular.toJson(dataJson)
        }).then(function (response) {
            if (response.status != '1') {
                toastr.error(response.msg);
                return;
            } else {
                toastr.success("成功");
            }
        })
    }

    //删除
    $scope.delDataSource = function (data) {
        ModalUtils.confirm("确认删除吗?", "modal-warning", "lg", function () {
            var dataJson = angular.copy($scope.dtp);
            dataJson.dtpDbConfigModel = data;
            dtpManagerService.delDataSource({
                "dataJson": angular.toJson(dataJson)
            }).then(function (response) {
                if (response.status != '1') {
                    toastr.error(response.msg);
                    return;
                } else {
                    toastr.success("成功");
                    $scope.getDataSourceList();
                }
            })
        })
    }


    $scope.gotoDetail = function (option, dataSource) {
        var uibModal = $uibModal.open({
            template: dtpDatasourceDetailTpl,
            windowTemplate: windowTpl,
            backdrop: false,
            windowClass: 'dtp-datasource-detail',
            size: 'lg',
            resolve: {
                // totalItems:$scope.paginationConf.totalItems,
                dataSourceList: function () {
                    return $scope.dataSourceList;
                },
                params: function () {
                    return $scope.dtp;
                },
                insertBehaviroLog:function(){
                    return function (label,dbIdentifier,callback) {
                        try { 
                            var name = "数据节点:"+label+" 下的数据源:"+dbIdentifier;
                            behaviorLogService.insertLog({module:"dtp",function:"查看数据源",name:name}).then(function(){
                            callback();
                        })}catch(error){};
                    };
                }
            },
            controller: ['$scope', '$uibModalInstance', 'dataSourceList', 'params','toastr','insertBehaviroLog',function ($scope, $uibModalInstance, dataSourceList, params,toastr,insertBehaviroLog) {
                $scope.option = angular.copy(option);
                $scope.dataSource = angular.copy(dataSource);
                $scope.buttonClick = true;
                try {
                    if($scope.dataSource)insertBehaviroLog(params.label,$scope.dataSource.dbIdentifier,function(){});
                } catch (error) {}
                
                //测试连接
                $scope.connectTestSource = function (data) {
                    var dataJson = angular.copy(params);
                    dataJson.dtpDbConfigModel = data;
                   
                    dtpManagerService.connectTestSource({
                        "dataJson": angular.toJson(dataJson)
                    }).then(function (response) {
                        if (response.status != '1') {
                            toastr.error(response.msg);
                            return;
                        } else {
                            toastr.success("成功");
                        }
                    })
                }

                $scope.editDtp = function (data) {
                    $scope.buttonClick = true;
                    if (!data || !data.dbIdentifier || !data.dbName || !data.dbType || !data.dbVersion || !data.jdbcDriverclassname || !data.jdbcPassword || !data.jdbcUrl || !data.jdbcUsername || !data.status) {
                        ModalUtils.alert("必填项不能为空", "modal-warning", "lg");
                        return;
                    }
                    saveDatasource(data);
                };

                //编辑/新增
                var saveDatasource = function (data) {
                    var dataJson = angular.copy(params);
                    dataJson.dtpDbConfigModel = data;
                   
                    dtpManagerService.saveDataSource({
                        "dataJson": angular.toJson(dataJson)
                    }).then(function (response) {
                        $scope.buttonClick = false;
                        if (response.status != '1') {
                            toastr.error(response.msg||"失败!");
                            return;
                        }else{
                            toastr.success("保存成功");
                            getDataSourceList();
                        }
                    })
                }

                var getDataSourceList = function () {
                    var dataJson = angular.copy(params);
                    dtpManagerService.getDataSourceList({
                        "dataJson": angular.toJson(dataJson)
                    }).then(function (response) {
                        if (response.status != '1') {
                            toastr.error("获取数据源列表失败,请检查您的DTP配置!");
                            return;
                        }else{
                            dataSourceList = response.data
                            $scope.$apply();
                            $scope.close(true);
                        } 
                    })
                }

                $scope.close = function (isEdit) {
                    //传递参数给原页面
                    $uibModalInstance.close({
                        "isEdit": isEdit,
                        "dataSourceList": dataSourceList
                    });
                };
            }]
        })

        //接收窗口传递的参数
        uibModal.result.then(function (result) {
            //单纯的关闭窗口不需要重新给list赋值,否则会导致页面显示闪烁问题
            if (result && result.isEdit) {
                $scope.dataSourceList = result.dataSourceList;
            }
        });
    }
};
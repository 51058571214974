
/** 
 * css文件放在settool里面了
 * 
 *category: 对象---两个键
 *              --current---对象
 *                  --name
 *                  --id
 *              --children---数组
 *                  --category
 *
 * html基本结构
    <div class="category-tree-item">
        <div class="current-box clearfix">
            <i class='circle'>·</i>
            <div class='fold'>
                <i class="fa fa-folder"></i>
            </div>
            <div class="title">categoryname</div>
        </div>
        <div class="children-box">
            <div class="category-tree-item">存在的子项</div>
        </div>
    </div>
 *
*/

//生成html的函数---含自调逻辑
function createElement(targetDomElement, category, index){
    var categoryTreeItem = $('<div></div>').appendTo($(targetDomElement));
    categoryTreeItem.addClass("category-tree-item");

    var currentBox = $('<div></div>').appendTo(categoryTreeItem);
    currentBox.addClass("current-box clearfix");
    
    var circle = [];
    for( var i = 0; i < index; i++) {
        circle[i] = $('<i></i>').appendTo(currentBox);
        circle[i].addClass("circle");
    }
    if(category.children && category.children.length > 0) {
        var fold = $('<div></div>').appendTo(currentBox);
        fold.addClass("fold");
        var foldIcon = $('<i></i>').appendTo(fold);
        foldIcon.addClass("fa fa-folder");
    }

    var title = $('<div></div>').appendTo(currentBox);
    title.addClass("title").text(category.current.name).attr("category-name", category.current.name).attr("category-id", category.current.id);

    if(category.children && category.children.length > 0) {
        var childrenBox = $('<div></div>').appendTo(categoryTreeItem);
        childrenBox.addClass("children-box");

        for(var j = 0; j < category.children.length; j++) {
            createElement(childrenBox, category.children[j], (index + 1));
        }
    }
    
}

export default function myCategoryTree () {
    return {
        restrict: 'EA',
        template: `<div class="my-category-tree" id="{{id}}"></div>`,
        scope: {
            categoryList: '=',
            curCategoryId: '='
        },
        replace: true,
        link: function(scope, element, attrs){

            scope.id = 'myCategoryTree' + Math.ceil(Math.random()*10000);

            //生成html以及绑定方法
            var createTree = function(){
                $('#' + scope.id).empty();
                createElement($('#' + scope.id), scope.categoryList, 0);

                $('#' + scope.id + ' .title').on("click", null, function(){
                    scope.curCategoryId = $(this).attr("category-id").toString();
                    // scope.show = !scope.show;
                    scope.$apply();
                });

    
                $('#' + scope.id + ' .fold').on("click", null, function(){
                    if( $( $(this).children()[0] ).hasClass("fa-folder") ) {
                        $(this).children().removeClass("fa-folder");
                        $(this).children().addClass("fa-folder-open");
                        $(this).parent().next().addClass("open");
                    } else {
                        $(this).children().removeClass("fa-folder-open");
                        $(this).children().addClass("fa-folder");
                        $(this).parent().next().removeClass("open");
                    }
    
                });
            };
            createTree();

            scope.$watch('categoryList.children', function(){
                createTree();
            });


        }
    };
  
};
import { dataService, userService,boardService,} from '../../../../server'
import PubSub from '../../../../common/utils/PubSub'
import boardContext from '../../../../charts/BoardContext';
import { util } from '../../../../common/utils';
export default function fullScreenBoardCtrl($scope, toastr, $rootScope, $window, $state, $stateParams, ModalUtils,$location) {
    //初始化 初始化数据集配置loadDataSet-》初始化看板数据loadBoard -》初始化图表数据
    $scope.board = {};
    $scope.loading = true;
    $scope.datasetList = {};
    $scope.boardId = util.getUrlPara("id");
    $scope.hasPerm = true;
    $scope.isFullScreen = true;

    // PubSub.subscribe('loading_'+$scope.boardId,function(data,topic){
    //     if(data==2){
    //         // setTimeout(() => {
    //             angular.element("#chromeHeadlessId").addClass("chrome-headless")
    //         // },200);
    //     }
    // });

    //看板跳转参数处理
    if (util.getUrlPara("param")) {
        $scope.urlParam = {
            param: JSON.parse(decodeURI(util.getUrlPara("param"))),
            boardId: $scope.boardId
        }
    }

    if (PubSub.hasSubscribers('widgetChange')) {
        PubSub.unsubscribe('widgetChange')
    }
    //看板关联 跳转
    PubSub.subscribe('widgetChange', (data, topic) => {
        loadBoard()
    });
    if (PubSub.hasSubscribers('boardLink')) {
        PubSub.unsubscribe('boardLink')
    }
    //看板关联 跳转
    PubSub.subscribe('boardLink', (data, topic) => {
        // console.log(data)
        var param = JSON.stringify(data.param);
        var url = $state.href('display', { id: data.targetId, param: encodeURI(param) });
        $window.open(url, '_blank');
    });

    var loadDataSet = function (params) {
        dataService.getDataSetList().then(function (dataset) {
            $scope.datasetList = dataset;
            loadBoard()
        })
    }
    loadDataSet();

    //加载看板
    var loadBoard = function (reload) {
        $scope.loading = true;
        userService.judgePermission({resType:'board',resId:$scope.boardId,option:'query'}).then(function(data){
            if(data){
                $scope.getBoardData(reload)
            }else{
                toastr.error("您没有查看看板权限!");
                angular.element("#chromeHeadlessId").addClass("chrome-headless")
                $scope.loading = false;
                $scope.hasPerm = false;
            }
        },err=>{
            toastr.error("您没有查看看板权限!");
            angular.element("#chromeHeadlessId").addClass("chrome-headless")
            $scope.loading = false;
            $scope.hasPerm = false;
        })
    }

    $scope.getBoardData =function(reload){
        boardService.getBoardData($scope.boardId).then(function (boardData) {
            boardContext.initBoardContext($scope.boardId)
            $scope.board = boardData;
            //原有看板没有这些设置
            if(!$scope.board.layout.cache){
                $scope.board.layout.cache = {
                    dataCache: {
                        type: 'board',
                        isCache: false,
                        expireTime: 0
                    },
                    dimensionCache: {
                        type: 'board',
                        isCache: true,
                        expireTime: '1,天'
                    }
                };
            }
            if(!$scope.board.layout.watermark) {
                $scope.board.layout.watermark = {
                    isShow: false,
                    customText: '',
                    isShowDate: true,
                    isShowUsername: true
                };
            }
            if ($scope.urlParam && $scope.urlParam.boardId == $scope.boardId) {
                let urlParam = $scope.urlParam;
                _.each($scope.board.layout.rows, function (row) {
                    _.each(row.params, function (param) {
                        _.each(urlParam.param, (url_param) => {
                            if (param.name == url_param.col){
                                if (param.values.length == 0) {
                                    param.values = url_param.values;
                                }
                                if (param.values.length > 0 && url_param.type == '=') {
                                    _.each(url_param.values, (value) => {
                                        param.values.push(value);
                                    })
                                    param.values = _.uniq(param.values)
                                }
                            }
                        })
                    });
                });
            }
            $scope.loading = false;
            document.title = $scope.board.name;
            $scope.categoryId = $scope.board.categoryId;
            // $scope.fullScreen();
            $scope.$apply()
        }, err => {
            toastr.error(err.head.errorMsg)
        });
    }

    $scope.$on('$destroy', function(){
        // 离开时还原网页标题
        document.title = 'Ourbore';
    });
}

import BasicService from './BasicService';
import { REPORTAPI } from './ApiServer';


class ReportService extends BasicService {
  constructor() {
    super();
  }
  getReportList(params){
    return this.post(REPORTAPI.GETREPORTLIST,params)
  }
  showReport(params){
    return this.post(REPORTAPI.SHOWREPORT,params)
  }
  printReport(params){
    return this.post(REPORTAPI.PRINTREPORT,params)
  }
  exportPdf(params){
    return this.post(REPORTAPI.EXPORTPDF,params)
  }
  exportExcel(params){
    return this.post(REPORTAPI.EXPORTEXCEL,params)
  }
  add(params){
    return this.post(REPORTAPI.ADDREPORT,params)
  }

  copy(params){
    return this.post(REPORTAPI.COPYREPORT,params)
  }

  get(params){
    return this.post(REPORTAPI.GETREPORT,params)
  }

  getPageList(params){
    return this.post(REPORTAPI.GETPAGEREPORTLIST,params)
  }

  getAuthList(params){
    return this.post(REPORTAPI.GETAUTHPAGELIST,params)
  }

  loadReport(params){
    return this.post(REPORTAPI.LOADREPORT,params)
  }

  save(params){
    return this.post(REPORTAPI.SAVEREPORT,params)
  }

  delete(params){
    return this.post(REPORTAPI.DELETEREPORT,params)
  }

  buildDatabaseTables(params){
    return this.post(REPORTAPI.BUILDDATABASETABLES,params)
  }

  buildFields(params){
    return this.post(REPORTAPI.BUILDFIELDS,params)
  }

  buildPreviewData(params){
    return this.post(REPORTAPI.BUILDPREVIEWDATA,params)
  }

  showReport(params){
    return this.post(REPORTAPI.SHOWREPORT,params)
  }

  previewReport(params){
    return this.post(REPORTAPI.PREVIEWREPORT,params)
  }

  filterPreviewData(params){
    return this.post(REPORTAPI.FILTERPREVIEWDATA,params)
  }

  scriptValidation(params){
    return this.post(REPORTAPI.SCRIPTVALIDATION,params)
  }

  conditionScriptValidation(params){
    return this.post(REPORTAPI.CONDITIONSCRIPTVALIDATION,params)
  }

  parseDatasetName(params){
    return this.post(REPORTAPI.PARSEDATASETNAME,params)
  }
  

  savePreviewData(params){
    return this.post(REPORTAPI.SAVEPREVIEWDATA,params)
  }

  loadData(params){
    return this.post(REPORTAPI.LOADDATA,params)
  }

  getAuthList(params){
    return this.post(REPORTAPI.GETAUTHLIST,params)
  }

  getReportCategoryList(params){
    return this.post(REPORTAPI.GETREPORTCATEGORYLIST,params)
  }

  delReportCategory(params){
    return this.post(REPORTAPI.DELREPORTCATEGORY,params)
  }

  saveReportCategory(params){
    return this.post(REPORTAPI.SAVEREPORTCATEGORY,params)
  }

  loadSearchForm(params){
    return this.post(REPORTAPI.LOADSEARCHFORM,params)
  }

  loadSheetConfig(params){
    return this.post(REPORTAPI.LOADSHEETCONFIG,params)
  }

}

export const reportService = new ReportService();

import optionTpl from './option.html'
function  crtl($scope) {

    //引入视觉映射组件时出现的问题----配置项无法增加颜色
    if( !$scope.curWidget.config.option.visualMap ) {
        $scope.curWidget.config.option.visualMap = {
            inRange : { color: ['lightskyblue', 'yellow', 'orangered'] }
        };
    } else {
        if( !$scope.curWidget.config.option.visualMap.inRange ) {
            $scope.curWidget.config.option.visualMap.inRange = { color: ['lightskyblue', 'yellow', 'orangered'] };
        } else {
            !$scope.curWidget.config.option.visualMap.inRange.color ? $scope.curWidget.config.option.visualMap.inRange.color = ['lightskyblue', 'yellow', 'orangered'] : null ;
            
        }
    }
    
}
crtl.$inject = ['$scope']
var   option ={
    crtl:crtl,
    template:optionTpl
}
export  default option;
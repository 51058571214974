
import progressBarRoundTpl from './progressBarRound.html'
import  './_progressRound.scss'   
/** @ngInject */
function progressBarRound(baProgressModal) {
    return {
        restrict: 'E',
        template: progressBarRoundTpl,
        link: function ($scope, element, attrs) {
            $scope.baProgressDialog = baProgressModal;
            $scope.$watch(function () {
                return baProgressModal.getProgress();
            }, animateBar);

            function animateBar() {
                var circle = element.find('#loader')[0];
                circle.setAttribute("stroke-dasharray", baProgressModal.getProgress() * 180 * Math.PI / 100 + ", 20000");
                $scope.progress = baProgressModal.getProgress();
            }
        }
    }
}


export  default  angular.module('BlurAdmin.theme.components.progressBarRound', [])
    .directive('progressBarRound', ['baProgressModal',progressBarRound]).name;

import licenseInfoTpl from './licenseInfo.html'
import licenseInfoCtrl from './licenseInfoCtrl'
import './licenseInfo.scss'
export default angular.module('borepc.pages.licenseInfo', [])
    .controller('licenseInfoCtrl', ['$scope', 'toastr',licenseInfoCtrl])
    .config(['$stateProvider',routeConfig]).name;
function routeConfig ($stateProvider) {
    $stateProvider
        .state('licenseInfo', {
            url: '/licenseInfo',
            title: '授权信息',
            controller: 'licenseInfoCtrl',
            template: licenseInfoTpl
        })
}
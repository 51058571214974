import appImage from './image/appImage';
import kameleonImg from './image/kameleonImg';
import profilePicture from './image/profilePicture';
import plainText from './text/removeHtml';
import textLengthSet from './text/textLengthSet';
import selectedUserFilter from './data/selectedUserFilter';
import datasetListFilter from './data/datasetListFilter';
import boardArrayFilter from './data/boardArrayFilter';
import bigDataFilter from './data/bigDataFilter';
import widgetTypeFilter from './data/widgetTypeFilter';

import htmlTrusted from './htmlTrusted'

export default angular.module('BlurAdmin.theme.filters', [])
    .filter('appImage', ['layoutPaths',appImage])
    .filter('kameleonImg', ['layoutPaths',kameleonImg])
    .filter('profilePicture', ['layoutPaths',profilePicture])
    .filter('textLengthSet', textLengthSet)
    .filter('selectedUserFilter', selectedUserFilter)
    .filter('htmlTrusted', ['$sce',htmlTrusted])
    .filter('datasetListFilter', datasetListFilter)
    .filter('boardArrayFilter', boardArrayFilter)
    .filter('bigDataFilter', bigDataFilter)
    .filter('widgetTypeFilter', widgetTypeFilter)
    .filter('plainText', plainText).name;

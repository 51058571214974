//用户
const USERAPI = {
    LOGIN: '/bcp/app/loginBind',
    LOGINOUT: '/loginout',
    USERDETAIL: '/bcp/commons/getUserDetail',
    GETUSERLIST: '/bcp/admin/getUserList',
    GETUSERS: '/bcp/admin/getUsers',
    GETBINDUSERIDBYCOMPANY: '/bcp/app/getBindUserIdByCompany',
    USERMENULIST: '/bcp/auth/manage/getPageShowMenuList', //用户菜单列表
    USERPERMISSIONLIST: '/bcp/auth/manage/getPermissionList', //获取权限码列表
    UPLOADAVATAR: '/bcp/dashboard/uploadAvatar',
    ISADMIN: '/bcp/admin/isAdmin',
    REMOVEBIND: '/bcp/app/removeBind',
    GETBINDSBYUSER: '/bcp/app/getBindsByUser',
    GETUSERBYUSERIDS: '/bcp/admin/getUserByUserIds',
    GETPERMISSION:'/bcp/admin/getPermission',
    JUDGEPERMISSION:'/bcp/admin/judgePermission'

}

const USERROLEADMIN = {
    GETROLE: '/bcp/role/getRole',
    GETROLELIST: '/bcp/role/getRoleList',
    SAVE: '/bcp/role/save',
    DELETE: '/bcp/role/delete',
    GETALLMENULIST: '/bcp/auth/manage/getAllMenuList',
    GETAUTHMENULIST: '/bcp/auth/manage/getAuthMenuList',
    GETROLEUSERLIST: '/bcp/role/getRoleUserList',
    DELETEROLEUSER: '/bcp/role/deleteRoleUser',
    SAVEROLEUSER: '/bcp/role/saveRoleUser',
    HASPERMISSION: '/bcp/auth/manage/hasPermission'
}



//数据
const DATAAPI = {
    DATASETLIST: '/bcp/dashboard/getDatasetList', // 数据集列表
    DATASETLISTBYIDS: '/bcp/dashboard/getDatasetListByIds', // 数据集列表
    DIMENSIONVALUES: '/bcp/dashboard/getDimensionValues', //获取维度信息
    AGGREGATEDATA: '/bcp/dashboard/getAggregateData', // 获取聚合数据
    GETPAGEAGGREGATEDATA: '/bcp/dashboard/getPageAggregateData', // 获取聚合数据
    AGGDATAQUERY: '/bcp/dashboard/viewAggDataQuery', // 查看聚合sql
    COLUMNS: '/bcp/dashboard/getColumns',
    GETCOLUMNSBYIDS: '/bcp/dashboard/getColumnsByIds',
    DATASETPAGELIST:'/bcp/dashboard/getDatasetPageList',
    DATASETDETAILNODS: '/bcp/dashboard/getDatasetDetailNoDs',
    GETCOMPLETEDATASQL: '/bcp/dashboard/getCompleteDataSql',
    PARSEREXPRESSION: '/bcp/dashboard/parserExpression', //时间表达式解析后的字符串
    PREVIEWDATASETDATA: '/bcp/dashboard/datasetPreview'

}
//看板
const BOARDAPI = {
    BOARDLIST: '/bcp/dashboard/getBoardList',
    GETBOARDBYWIDGET: '/bcp/dashboard/getBoardByWidget',
    GETBOARDCOLLIST: '/bcp/dashboard/getBoardColList',
    BOARDPARAM: '/bcp/dashboard/getBoardParam',
    BOARDDATA: '/bcp/dashboard/getBoardData',
    BOARDUPDATE: '/bcp/dashboard/updateBoard',
    BOARDSAVE: '/bcp/dashboard/saveNewBoard',
    BOARDDEL: '/bcp/dashboard/deleteBoard',
    BOARDCATEGORY: '/bcp/category/getCategoryList',
    BOARDWIDGET: '/bcp/dashboard/dashboardWidget',
    GETDATASOURCELIST: '/bcp/dashboard/getDatasourceList',
    GETDATASETCATEGORYLIST: '/bcp/dashboard/getDatasetCategoryList',
    CHECKDATASOURCE: '/bcp/dashboard/checkDatasource',
    GETALLWIDGETLIST: '/bcp/dashboard/getAllWidgetList',
    DELETEDATASET: '/bcp/dashboard/deleteDataset',
    SAVENEWDATASET: '/bcp/dashboard/saveNewDataset',
    GETDATASETDETAIL: '/bcp/dashboard/getDatasetDetail',
    UPDATEDATASET: '/bcp/dashboard/updateDataset',
    SAVECATEGORY: '/bcp/category/saveCategory',
    DELETECATEGORY: '/bcp/category/deleteCategory',
    COPYCATEGORY: '/bcp/category/copyCategory',

}
//DTP管理
const DTPMANAGERAPI = {
    GETDTPCONNECTLIST: '/bcp/dtp/manager/getDtpConnectList',
    JUDGEISCONNECT: '/bcp/dtp/manager/judgeIsConnect',
    DELETEDTP: '/bcp/dtp/manager/deleteDtp',
    GETDTPBYAPPKEY: '/bcp/dtp/manager/getDtpByAppkey',
    SAVEDTP: '/bcp/dtp/manager/saveDtp',
    GETDATASORCEBYDTPID: '/bcp/dtp/manager/getDataSourceListByDtpId',
    CONNECTTESTSOURCE: '/bcp/dtp/manager/connectTestSource',
    DELDATADTPSOURCE: '/bcp/dtp/manager/delDataSource',
    SAVEDATASOURCE: '/bcp/dtp/manager/saveDataSource',
    GETDATASOURCELIST: '/bcp/dtp/manager/getDataSourceList',
    GETDTPLIST: '/bcp/dtp/manager/getDtpList',

}
//widget
const WIDGETAPI = {
    GETCONFIGPARAMS: '/bcp/dashboard/getConfigParams',
    GETWIDGETDETAIL: '/bcp/dashboard/getWidgetDetail',
    CHECKWIDGET: '/bcp/dashboard/checkWidget',
    GETWIDGETLIST: '/bcp/dashboard/getWidgetList',
    GETWIDGETCATEGORYLIST: '/bcp/dashboard/getWidgetCategoryList',
    SAVENEWWIDGET: '/bcp/dashboard/saveNewWidget',
    UPDATEWIDGET: "/bcp/dashboard/updateWidget",
    DELETEWIDGET: '/bcp/dashboard/deleteWidget',
    GETCONFIGVIEWTYPE: '/bcp/dashboard/getConfigViewtype',  //='+ $scope.datasource.type;   //='+ $scope.datasource.type;
    GETWIDGETLISTBYIDS:'/bcp/dashboard/getWidgetListByIds',
    GETWIDGETSBYDATASETID:'/bcp/dashboard/getWidgetsByDatasetId'
}
//prewarning预警
// const PREWARNINGAPI = {
//     GETPWLIST: '/bcp/prewarning/getPwList',
//     SAVEPREWARNING: '/bcp/prewarning/savePrewarning',
//     UPDATEWARNSTATUS: '/bcp/prewarning/updateWarnStatus',
//     DELPREWARNING: '/bcp/prewarning/delPrewarning',
// }

//prewarning预警
const PREWARNINGAPI = {
    GETPWLIST: '/bcp/prewarning/getPageList',
    SAVEPREWARNING: '/bcp/prewarning/savePrewarning',
    UPDATEWARNSTATUS: '/bcp/prewarning/updateWarnStatus',
    DELPREWARNING: '/bcp/prewarning/delPrewarning',
    GETLOGPAGELIST: '/bcp/pwLog/getLogPageList',
    DELPWLOG: '/bcp/pwLog/deleteLog',
    GETPWLOGDETAIL: '/bcp/pwLog/getPwLogDetail'
}

//userAdmin 用户管理
const USERRESAPI = {
    ISADMIN: '/bcp/admin/isAdmin',
    GETROLELIST: '/bcp/admin/getRoleList',
    GETUSERROLELIST: '/bcp/admin/getUserRoleList',
    GETMENULIST: '/bcp/admin/getMenuList',
    GETDATASOURCELIST: '/bcp/admin/getDatasourceList',
    GETDATASETLIST: '/bcp/admin/getDatasetList',
    GETWIDGETLIST: '/bcp/admin/getWidgetList',
    GETJOBLIST: '/bcp/admin/getJobList',
    GETROLERESLIST: '/bcp/admin/getRoleResList',
    CHECKEXISTROLENAME: '/bcp/admin/checkExistRoleName',
    SAVEROLE: '/bcp/admin/saveRole',
    UPDATEROLE: '/bcp/admin/updateRole',
    UPDATEUSERROLE: '/bcp/admin/updateUserRole',
    UPDATEROLEUSER: '/bcp/admin/updateRoleUser',
    DELETEUSERROLE: '/bcp/admin/deleteUserRole',
    UPDATEROLERES: '/bcp/admin/updateRoleRes',
    DELETEROLE: '/bcp/admin/deleteRole',
    DELETEUSER: '/bcp/admin/deleteUser',
    SAVENEWUSER: '/bcp/admin/saveNewUser',
    EDITUSER: '/bcp/admin/editUser',
    GETUSERROLELISTBYID: '/bcp/admin/getUserRoleListById',
    SAVEUSERROLE: '/bcp/admin/saveUserRole',
    BATCHDELETEUSERROLE: '/bcp/admin/batchDeleteUserRole',
    ATTUPLOADIMAGE: '/bcp/admin/uploadImage',
}
const FILEAPI = {
    FILEUPLOAD: '/bv/file/upload',
    IMAGEUPLOAD: '/bv/file/uploadImage',
    FILELIST: '/bv/file/getList',
    IMAGELIST: '/bv/file/imageList',
    FILEDEL: '/bv/file/delete',
    TAGLIST: '/bv/tag/getList',
    TAGSAVE: '/bv/tag/save',
    TAGDEL: '/bv/tag/delete'
}
const APPAPI = {
    APPLIST: '/bv/app/getList',
    APPSAVE: '/bv/app/save',
    APPPUBLISH: '/bv/app/publish',
    APPUNPUBLISH:'/bv/app/unPublish',
    APPDEL: '/bv/app/delete',
    APPGET: '/bv/app/getApp',
    APPPUBDATA: '/open/bv/app/getData',
    NODATALIST: '/bv/app/getNoDataList',
    GETAUTHLIST: '/bv/app/getAuthList',
    DATADRAFT: '/bv/app/getDataDraft',
    GETSHARE: '/bcp/share/getShare',
    SHARELIST: '/bcp/share/getList',
    SHARESAVE: '/bcp/share/save',
    SHAREDEL: '/bcp/share/delete',
    SHAREREFRESH: '/bcp/share/refresh',
    SHARE: '/gateway/share',
    APPVISUAL: '/bv/app/share',
    GETSHAREBYAPPID:'/bcp/share/getByObjectId',
    SHAREDELBYAPPID:'/bcp/share/deleteByObjectId',
    GETSHORTURL:'/bcp/shorturl/getShortUrl'
}

// modelCenter模型市场
const MODELCENTERAPI = {
    GETDATAMARKETLIST: '/odm/dm/getDataMarketList',
    RESETDATAMARKET: '/odm/dm/resetDataMarket',
    GENERATEDATAMARKET: '/odm/dm/generateDataMarket'
}
const DTPAPI = {
    DSAPILIST: "/dtp/dtpDsApi/getList",
    DSINVOKE: "/open/dtp/dsApi",
    DTPDSAPILIST: "/bcp/dtp/dsapi/getList",
    DTPDSINVOKE: "/bcp/dtp/dsapi/invoke",
}

//orderCenter 我的订单
const ORDERCENTERAPI = {
    GETMARKETORDERLIST: '/odm/dm/getMarketOrderList'
}

//accountCenter 我的账户
const ACCOUNTCENTERAPI = {
    GETOSSCOMPANYINFO: '/odm/dm/getOssCompanyInfo',
    GETAPPORDER:'/odm/app/order/getAppOrder',
    SAVE: '/authority/validate/save'
}

//我的报表
const REPORTAPI = {
    GETREPORTLIST: '/bcp/ureport/getReportList',
    SHOWREPORT: '/bcp/ureport/showReport',
    PRINTREPORT: '/bcp/ureport/download/printReport',
    EXPORTPDF: '/ureport/bcp/ureport/download/exportPdf',
    EXPORTEXCEL: '/bcp/ureport/download/exportExcel',

    ADDREPORT: '/bcp/report/add',
    COPYREPORT: '/bcp/report/copy',
    GETREPORT: '/bcp/report/get',
    GETPAGEREPORTLIST: '/bcp/report/getPageList',
    GETAUTHPAGELIST: '/bcp/report/getAuthList',
    LOADREPORT: '/bcp/report/loadReport',
    SAVEREPORT: '/bcp/report/save',
    DELETEREPORT: '/bcp/report/delete',
    BUILDDATABASETABLES: '/bcp/report/buildDatabaseTables',
    BUILDFIELDS: '/bcp/report/buildFields',
    BUILDPREVIEWDATA: '/bcp/report/buildPreviewData',
    SHOWREPORT: '/bcp/report/showReport',
    PREVIEWREPORT: '/bcp/report/previewReport',
    FILTERPREVIEWDATA: '/bcp/report/filterPreviewData',
    SCRIPTVALIDATION: '/bcp/report/scriptValidation',
    CONDITIONSCRIPTVALIDATION:'/bcp/report/conditionScriptValidation',
    PARSEDATASETNAME:'/bcp/report/parseDatasetName',
    SAVEPREVIEWDATA:'/bcp/report/savePreviewData',
    LOADDATA:'/bcp/report/loadData',
    GETAUTHLIST:'/bcp/report/getAuthList',
    SAVEREPORTCATEGORY:'/bcp/report/category/saveCategory',
    DELREPORTCATEGORY:'/bcp/report/category/deleteCategory',
    GETREPORTCATEGORYLIST:'/bcp/report/category/getCategoryList',
    LOADSEARCHFORM:'/bcp/report/loadSearchForm',
    LOADSHEETCONFIG:'/bcp/report/loadSheetConfig'
}

//数据填报
const DATASUPPORTAPI = {
    GETDATASOURCELIST: '/bcp/dataSupport/getDataSourceList',
    GETALLTABLENAME: '/bcp/dataSupport/getAllTableName',
    GETSCHEMADATA: '/bcp/dataSupport/getSchemaData',
    GETTABLEDATA: '/bcp/dataSupport/getTableData',
    SAVE: '/bcp/dataSupport/save',
    DELETE: '/bcp/dataSupport/delete'
}

const COLLECTIONAPI = {
    GETCOLLECTIONLIST: '/bcp/collection/getCollectionList',
    DELCOLLECTION: '/bcp/collection/delCollection',
    GETCOLLECTIONDATA: '/bcp/collection/getCollectionData',
    SAVECOLLECTION: '/bcp/collection/saveCollection',
    GETHOMEPAGE: '/bcp/collection/getHomePage',
    SETHOMEPAGE: '/bcp/collection/setHomePage',
    CANCELHOMEPAGE: '/bcp/collection/cancelHomePage',
}
//企业信息
const OFFICEAPI = {
    GETOFFICE: '/bcp/admin/getOffice',
    UPDATEOFFICE: '/bcp/admin/updateOffice',
    LICENSEINFO:'/bcp/license/getLiceseInfo',
    REFRESHLIC:'/bcp/license/refreshLic'

}

//消息
const MESSAGEAPI = {
    GETMESSAGElLIST: '/message/list',
    UPDATESTATUS: '/message/updateStatus',
    GETMESSAGEDETAIL: '/message/detail'
}

//定时推送
const TIMERPUSHAPI = {
    SAVEBORETIMER: '/bcp/timer/saveBoreTimer',
    GETTIMERPAGELIST: '/bcp/timer/getPageList',
    UPDATETIMERSTATUS: '/bcp/timer/updateTimerStatus',
    DELBORETIMER: '/bcp/timer/delBoreTimer',
    GETBTLOGLIST: '/bcp/btLog/getBtLogList',
    DELETELOG: '/bcp/btLog/deleteLog',
    GETBTLOGDETAIL: '/bcp/btLog/getBtLogDetail',
    CALCRUNTIME: '/bcp/timer/calcRunTime',
}

//系统配置
const PROPERTYAPI = {
    SAVEPROPERTY: '/bcp/properties/saveProperty',
    GETPROPERTYLIST: '/bcp/properties/getPropertyList',
    GETPROPERTYBYKEY: '/bcp/properties/getPropertyByKey',
    SAVEPROPERTIES: '/bcp/properties/saveProperties',
    DELPROPERTY: '/bcp/properties/delProperty',
}
const FORWARDAPI = {
    FORWARD: '/open/bv/forward',
}
const BEHAVIORLOGAPI = {
    INSERTLOG: '/bcp/log/behavior/insertLog',
    BVINSERTLOG: '/bv/log/behavior/insertLog',
    GETBEHAVIORLOGLIST: '/sys/log/business/getList',
    DELBEHAVIORLOG: '/sys/log/business/delete',

}

const DATASETMETATABLEAPI = {
    GETDETAIL: '/bcp/dataset/metatable/getDetail',
    GETDETAILBYCODE: '/bcp/dataset/metatable/getDetailByCode',
    GETPAGELIST: '/bcp/dataset/metatable/getPageList',
    DELMETATABLE: '/bcp/dataset/metatable/del',
    SAVEMETATABLE: '/bcp/dataset/metatable/save',
    GETALLLIST: '/bcp/dataset/metatable/getAllList',

}

const ACCESSCONTROLAPI = {
    SAVE: '/bcp/accessControl/save',
    GET: '/bcp/accessControl/get',
}

const GLOBALSETTINGAPI = {
    GETINFO: '/bcp/globalset/get',
    SAVE: '/bcp/globalset/save',
}
//boredv的chartmarket
const BOREDVCHARTMARKET = {
    GETLIST: '/bv/chart/market/getList',
    GETCHARTBYID: '/bv/chart/market/get',
    SYNCCHART: '/bv/chart/market/sync',
}
// 归档
const ARCHIVES = {
    GETFILELIST: '/bcp/archive/file/getList',       //归档列表
    GETFILE: '/bcp/archive/file/get',               //获取归档详情
    DELETEFILE: '/bcp/archive/file/delete',         //删除一个归档
    UPLOADIMG: '/bcp/archive/file/uploadImg',       //新增或修改一个

    GETTAGLIST: '/bcp/archive/file/tag/getList',    //获取标签列表
    UPDATETAG: '/bcp/archive/file/tag/save',        //新增或修改标签
    DELETETAG: '/bcp/archive/file/tag/delete',      //删除一个标签
}

export {
    USERAPI, DATAAPI, BOARDAPI, DTPMANAGERAPI, WIDGETAPI, PREWARNINGAPI, USERRESAPI, MODELCENTERAPI, ORDERCENTERAPI, ACCOUNTCENTERAPI, FILEAPI, APPAPI, DTPAPI, USERROLEADMIN, REPORTAPI,
        DATASUPPORTAPI,COLLECTIONAPI,OFFICEAPI,MESSAGEAPI,TIMERPUSHAPI,PROPERTYAPI,FORWARDAPI,BEHAVIORLOGAPI,DATASETMETATABLEAPI,ACCESSCONTROLAPI,GLOBALSETTINGAPI,BOREDVCHARTMARKET,
        ARCHIVES
    }

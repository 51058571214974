/**
 * @author jaray
 *   
 */
  import  baSwitcherTpl from './baSwitcher.html'
  /** @ngInject */
  export default  function baSwitcher() {
    return {
      template: baSwitcherTpl,
      scope: {
        switcherStyle: '@',
        switcherValue: '=',
        switcherChange:'='
      },
      // controller: function($scope){
      //   console.log($scope.switcherValue)
      //   console.log($scope.switcherChange)
      //   $scope.valueChange=(value)=>{
      //     console.log(value)
      //   }
          
      // }

    };
  }


import * as util from '../util/util'
var Kpi3RenderEngine = function (jqContainer, options) {
    this.container = jqContainer; // jquery object
    this.options = options;
};

Kpi3RenderEngine.prototype.html = function (persist) {
    var self = this;
    var temp = "" + self.template;
    var html = util.StringRender(temp, self.options)
    if (persist) {
        setTimeout(function () {
            self.container.css('background', '#fff');
            html2canvas(self.container, {
                onrendered: function (canvas) {
                    persist.data = canvas.toDataURL("image/jpeg");
                    persist.type = "jpg";
                    persist.widgetType = "kpi";
                }
            });
        }, 1000);
    } 
    return html;
};

Kpi3RenderEngine.prototype.realTimeTicket = function () {
    var self = this;
    // console.log($(self.container).find('#kpi3Echart').get(0))

    var kpi3Chart = echarts.init($(self.container).find('#kpi3Echart').get(0));
    kpi3Chart.setOption(self.options.echartOption);
    return function (o) {
        // $(self.container).find('h3').html(o.kpiValue);
        // $(self.container).find('h4').html(o.kpiValueTarget);
        // $(self.container).find('h5').html(o.kpiPercent);
      
    }
};

Kpi3RenderEngine.prototype.do = function () {
    var self = this;
    $(self.container).html(self.rendered());
};

// Kpi3RenderEngine.prototype.template =
//     `<div class='kpi3_wrapper'>
//             <div class='kpi3_data'>
//                 <div class="kpi3_name" title='{kpiName}'>{kpiName}</div>
//                 <div class='kpi3_kpiValue'>
//                     <span style="vertical-align: middle; ">{kpiValue.value}</span><span style="vertical-align: middle; font-size: {kpiValue.unitFontSize};" >{kpiValue.unit}</span>
//                 </div>
//                 <div class='kpi3_targetValue' style="padding-bottom: 1em;">
//                     <span class='target' style="vertical-align: middle; ">目标</span>
//                     <span class='target' style="vertical-align: middle; ">
//                         <span style="vertical-align: middle; ">{kpiValueTarget.value}</span><span style="vertical-align: middle; font-size: {kpiValueTarget.unitFontSize};" >{kpiValueTarget.unit}</span>
//                     </span>
//                 </div>
//             </div>
//             <div class='kpi3_echarts'>
//                 <div id='kpi3Echart'></div>
//             </div>
//     </div>`;
Kpi3RenderEngine.prototype.template =`
    <div class='new_kpi_container'>
        <div class="kpi_bgImg kpi_bgStyle new_kpi_content">
            <div class="kpi_header_container" style="display: {hasKpiName}">
                <div class="kpi_name" title='{kpiName}'>{kpiName}</div>
            </div>
            <div class="kpi3_wrapper {bodyClass} clearfix">
                <div class='kpi3_data'>
                    <div class='kpi3_kpiValue new_kpi_value'>
                        <span style="vertical-align: middle; ">{kpiValue.value}</span><span style="vertical-align: middle; font-size: {kpiValue.unitFontSize};" >{kpiValue.unit}</span>
                    </div>
                    <div class="new_kpi3_target">
                        <span class='target' >目标</span>
                        <span>
                            <span style="vertical-align: middle; ">{kpiValueTarget.value}</span>
                            <span style="vertical-align: middle; font-size: {kpiValueTarget.unitFontSize};" >{kpiValueTarget.unit}</span>
                        </span>
                    </div>
                </div>
                <div class = "kpi3_splitline"></div>
                <div class='kpi3_echarts'>
                    <div id='kpi3Echart'></div>
                </div>
            </div>
        </div>
    </div>`;
export default Kpi3RenderEngine;
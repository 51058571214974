import BasicService from './BasicService';
import { ORDERCENTERAPI, ACCOUNTCENTERAPI } from './ApiServer';
/**
 */
class OrderCenterService extends BasicService {
    getMarketOrderList (params) {
        return this.post( ORDERCENTERAPI.GETMARKETORDERLIST, params)
    }  

    getOssCompanyInfo (params) {
        return this.post(ACCOUNTCENTERAPI.GETOSSCOMPANYINFO, params);
    }

    getAppOrder (params) {
        return this.post(ACCOUNTCENTERAPI.GETAPPORDER, params);
    }

}

export const orderCenterService = new OrderCenterService();
/**

 */
import './_.boreCronInput.scss';
import {timerPushService} from '../../../../server'

function boreCronInput(){
    return {
        restrict: 'EA',
        template: `<div class="row bore-cron">
                        <label class="bore-cron-label" ng-show="boreCronIsextend"  for="cron">推送频率：</label>
                        <div class="bore-cron-design input-group" ng-show="boreCronIsextend" data-ng-init="loadCron()">
                            <input style="height: 33px;"  id="boreCronId" value="boreCronExpression"ng-model="boreCronExpression" class="form-control " />
                        </div>
                    </div>
                    <div style="margin-left: 60px;">
                        <button ng-if="boreCronIsextend" type=button  ng-click="getExpresPas()" class="btn">表达式解析</button>
                    </div>`,
        replace: false,
        scope: {
            boreCronExpression: '=',
            boreCronIsextend:'='
        },
        // controllerAs:'vm',
        controller: ['$scope','$element','$attrs','toastr','ModalUtils',function($scope,$element,$attrs,toastr,ModalUtils) {
            // console.log("boreCronExpression",$scope.boreCronExpression)
            // $scope.boreCronId = $attrs.boreCronId ? $attrs.boreCronId : "cronId";
            $scope.loadCron =function(){
                $("#boreCronId").cronGen({
                    direction : 'right'
                });
            }
            $scope.getExpresPas =function () {  
                var cronExpression = $scope.boreCronExpression;
                console.log("boreCronExpression",$scope.boreCronExpression)
                if (!cronExpression) {
                    toastr.warning("内容不能为空.");
                    return;
                }
                timerPushService.calcRunTime({cronExpression:$scope.boreCronExpression}).then(function (response) {              
                    var html = "";
                    for (var i = 0; i < response.length; i++) {
                        html+=(response[i]+"</br>");
                    }
                    if(!html)html="获取失败,请检查您的表达式!";
                    ModalUtils.showMsg("最近五次触发时间",html);
                    // alert(html)
                },err=>{
                    toastr.error(err.head.errorMsg)
                })
                return false;
            }
        }]
    }
}


export  default  angular.module('BlurAdmin.theme.components.CronExpre', [])
    .directive('boreCronInput',boreCronInput).name;
/**
 * Created by  jaray  2018-11-28
 */
import './_.Datatables.scss'
import * as util from '../../util/util'
import numbro from 'numbro'
import {
  userRoleAdminService,behaviorLogService
} from "../../../server";
var dataTable = {
  table: function (args) {
    var data = args.data,
      chartConfig = args.chartConfig,
      tall = args.tall,
      random = Math.random().toString(36).substring(2),
      container = args.container,
      chartName = args.name ? args.name : "dataTable",
      tableId = "table_" + random;
    var datatableId = "datatablesWrapperId_" + random;
    //转换数据单位
    data = this.changeDataUnit(chartConfig.values[0].cols, data);
    //拼接表格
    var containerHtml = this.stitchingPage(tall, tableId, datatableId, data)
    $(container).html(containerHtml);
    //设置操作属性
    this.setOptions(datatableId, tableId, chartConfig,data,chartName);
    //自动翻页
    chartConfig.option.tableIsPage ? this.carousePage(datatableId, chartConfig.option) : this.rollPage(tableId, chartConfig.option);
  },

  //拼接页面
  stitchingPage: function (tall, tableId, datatableId, data) {
    var html = "<table  id='" + datatableId + "' class='bore_datatable'><thead class='fixedHeader'>",
      colContent = "<tr>";
    for (var i = 0; i < data.length; i++) {
      colContent += "<tr>"
      for (var j = 0; j < data[i].length; j++) {
        colContent += "<th class='" + data[i][j].property + "'><div>" + data[i][j].data + "</div></th>";
      }
      //第一行为头部信息
      if (i == 0) {
        colContent += "</tr></thead><tbody class='scrollContent'>"
      } else {
        colContent += "</tr>"
      }
    }
    html += colContent + "</tbody></body>";
    return "<div id='" + tableId + "' style='width:99%;max-height:" + (tall ? (tall - 82) + "px" : " calc(100% - 82px);") + "'>" + html + "</div>";
  },

  //配置属性
  setOptions: function (datatableId, tableId, chartConfig,data,chartName) {
    var option = chartConfig.option;
    var cols = chartConfig.values[0].cols;
    $('#' + datatableId).removeClass('display').addClass('table table-hover table-bordered')
    var dTable = $('#' + datatableId).DataTable({
      "paging": option.tableIsPage == false ? false : true, //分页
      "ordering": true, //是否启用排序
      "searching": true, //搜索
      "scrollY": "300px",
      "scrollX": true,
      "scrollCollapse": true,
      "bProcessing": true,
      "bPaginate": true,
      // "fixedColumns": {
      //   //固定列的配置项
      //   "leftColumns": 2,//-1第一列不固定，默认固定第一列
      //   "rightColumns": 2 //固定右边第一列
      // },
      "pageLength": option.initDataNum || 10,
      "language": {
        "search": "搜索:",
        "paginate": { //分页的样式内容。
          "previous": "上一页",
          "next": "下一页",
          "first": "第一页",
          "last": "最后"
        },
        "zeroRecords": "数据为空", //table tbody内容为空时，tbody的内容。
        //下面三者构成了总体的左下角的内容。
        "info": option.hiddenPageCount ? "":" 共 _TOTAL_ 条", //左下角的信息显示，大写的词为关键字。
        "infoEmpty": "0条记录", //筛选为空时左下角的显示。
        "infoFiltered": "" //筛选之后的左下角筛选提示，
      },
      "dom": 'Bfrtip',
      "buttons": [{
        'extend': 'excel',
        "tag": 'span',
        'class': 'buttons-excel ',
        'title':chartName,
        'text': '<img style="cursor: pointer;width:15px;margin-bottom:3px;"src="/assets/imgs/excel.svg" title="导出Excel"/>', //定义导出excel按钮的文字
        'exportOptions': {
          'modifier': {
            'page': 'all'
          }
        }
      },{
        'extend': 'csv',
        "tag": 'span',
        'class': 'buttons-csv ',
        'title':chartName,
        "sCharSet": "utf8",
        "bBomInc": true,
        "bom":true,
        'text': '<img style="cursor: pointer;width:15px;margin-bottom:3px;"src="/assets/imgs/csv.svg" title="导出Csv"/>', //定义导出excel按钮的文字
        'exportOptions': {
          'modifier': {
            'page': 'all'
          }
        }
      }],
      "columnDefs": this.getColumnData(option,cols)
    });
    //增加导出日志监控
    $("#" + tableId + " .buttons-csv").on('click', function () {
      behaviorLogService.insertLog({module:"widget",function:"导出Excel",name:chartName})
    })
    //判断是否有导出权限
    $("#" + tableId + " .buttons-excel").hide();
    userRoleAdminService.hasPermission("bore:widget:export").then((data) => {
      if (data == 1) {
        $("#" + tableId + " .buttons-excel").show();
        $("#" + tableId + " .buttons-excel").on('click', function () {
          behaviorLogService.insertLog({module:"widget",function:"导出Excel",name:chartName})
        })
      } else {
        $("#" + tableId + " .buttons-excel").hide();
      }
    });

    $(dTable.table().header()).css({
      // "background": "rgb(102, 201, 204)",
      "color": "white"
    })
    if (!option.tableStyleConfig) return;
    if (option.tableStyle && option.tableStyle == 'styleA') {
      $('#' + datatableId + " .data").css({
        "color": "red"
      })
    }
    //表头字体
    if(option.tableFontSize){
      $("#" + tableId + " .dataTables_wrapper .bore_datatable > thead > tr > th").css({"font-size":option.tableFontSize+"px"})
    }
    //表身字体
    if(option.tableContentFontSize){
      $("#" + tableId + " .dataTables_wrapper .bore_datatable > tbody > tr > th").css({"font-size":option.tableContentFontSize+"px"})
    }

    //设置表头样式
    $(dTable.table().header()).css({
      "color": option.tableColor || "#FFFFFF",
      // "font-size":option.tableFontSize+"px",
      "background-color": option.tableBackgroundColor || "rgb(102, 201, 204)"
    })
    //设置表身样式
    $(dTable.table().body()).css({
      "color": option.tableContentColor || "#000000",
      // "font-size":option.tableContentFontSize+"px" || "25px",
      "background-color": option.tableContentBackgroundColor || "#FFFFFF"
    })
    //设置最大高度,由于datatables已存在max-height,所以需要cssText重新赋值
    if(option.maxHeight){
      var cssText = $('#' + tableId + ' .dataTables_scrollBody').attr("style")+"max-height:"+option.maxHeight+"px !important";
      $('#' + tableId + ' .dataTables_scrollBody').css("cssText",cssText)
    }
  },

  getColumnData: function (option, cols) {
    var columnArr = [{}]
    if (!option.tableStyleConfig || !option.warnRow) return columnArr;
    // 设置多个警示列----逗号间隔
    var warnRow = option.warnRow.replace(/，/g, ',').split(',');
    // 索引0开始
    for(let i = 0; i < warnRow.length; i++) {
      warnRow[i]--;
      if(warnRow[i] < 0) {
        warnRow.splice(i, 1);
        i--;
      }
    }
    var targets = warnRow,
        units = [],
        wSymbol = option.warnSymbol,
        wValue = option.warnValue;

    //获取指标单位数组并对其去重
    _.each(_.filter(cols, function (e) {
      return e.unit
    }), function (key) {
      units.push(key.unit)
    });
    units = _.uniq(units);
    columnArr=[{
      "targets": targets,
      "createdCell": function (td, cellData, rowData, row, col) {
              if(!wSymbol || wValue==null || wValue==undefined){
                  $(td).css('color', 'red')
                  return;
              }
              var data = $(cellData).html();
              _.each(units,function(u){
                  data = data.replace(u,"")
              })
              data = data.replace(",","").replace("，","");
              if(util.compareSize(data,wSymbol,wValue)){
                  $(td).css('color', 'red');
              }
        }
    }]
    return columnArr;
  },
  //对指标数据进行单位转换,col:指标列表,data:需要转换数据(包含非data类型数据)
  changeDataUnit: function (col, data) {
    var numberData = [];
    //头部第一行数据不需要转换单位,将其单独拿出,其余进入循环
    numberData.push(data[0]);
    for (var i = 1; i < data.length; i++) {
      var temData = data[i];
      var unitData = [];
      var count = 0;
      for (var j = 0, lans = temData.length; j < lans; j++) {
        var res = temData[j];
        //数据类型为data才需要转换单位，
        //count记录非data类型的数据量,用以计算出data数据在指标数组中对应索引j-count
        if (res.property != 'data') {
          unitData.push(res);
          count++;
          continue;
        }

        var colNum = (j - count) % (col.length)
        if (col[colNum] && (col[colNum].symbol || col[colNum].mantissa)) {
          res.data = isNaN(res.data) ? "":util.convertEchartUnit(col[colNum], res.data);
          res.raw = isNaN(res.raw) ? "":util.convertEchartUnit(col[colNum], res.raw);
        } else {
          res.data = isNaN(res.data) ? "" : numbro(Number(res.data)).format({
            thousandSeparated: true,
            mantissa: 6
          });
          res.raw = isNaN(res.raw) ? "" : numbro(Number(res.raw)).format({
            thousandSeparated: true,
            mantissa: 6
          });
        }
        unitData.push(res);
      };
      numberData.push(unitData);
    }
    return data
  },

  //自动翻页
  carousePage: function (datatableId, option) {
    clearInterval(window.timer)
    clearInterval(window.rolltimer)
    if (option.tableStyleConfig && option.changeTableTime) {
      var changeTableTime = option.changeTableTime * 1000
      var page = 0;
      window.timer = setInterval(function () {
        var setings = $('#' + datatableId).dataTable().fnSettings();
        //如果是第一页 或者还没有到最后一页则 next,否则 转到第一页
        if (page == 0 || (setings && setings._iDisplayStart && page != setings._iDisplayStart)) {
          if (setings && setings._iDisplayStart) {
            page = setings._iDisplayStart;
          }
          $('#' + datatableId).dataTable().fnPageChange('next');
        } else {
          $('#' + datatableId).dataTable().fnPageChange('first');
        }
      }, changeTableTime);
    }
  },
  //自动滚动
  rollPage: function (tableId, option) {
    clearInterval(window.timer)
    clearInterval(window.rolltimer)
    if (!option.tableStyleConfig || !option.changeTableTime) return;

    var table = $("#" + tableId + " .dataTables_wrapper");
    window.rolltimer = setInterval(rollStart, option.changeTableTime);
    // 鼠标移入时暂停滚动
    table.mouseover(function () {
      clearInterval(window.rolltimer);
    })
    // 鼠标移出后继续滚动
    table.mouseout(function () {
      window.rolltimer = setInterval(rollStart, option.changeTableTime);
    })

    function rollStart() {
      // $('#'+tableId+' .dataTables_scrollBody')
      var dbody = $('#' + tableId + ' .dataTables_scrollBody');
      dbody.scrollTop(dbody.scrollTop() + 1);
      if (dbody.scrollTop() + dbody.height() >= dbody.get(0).scrollHeight) {
        dbody.scrollTop(0);
      }
    }
  }

};

export default dataTable;

import redirectTpl from './redirect.html'
import redirectCtrl  from './redirectCtrl'
require ('./_redirect.scss')
export default angular.module('borepc.pages.redirect', [])
.controller('redirectCtrl', ['$state','$location','$scope',redirectCtrl])
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('redirect', {
      url: '/redirect',
      title: 'redirect',
      template: redirectTpl,
      controller: 'redirectCtrl',
    })
}


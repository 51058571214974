import {
  messageService
} from '../../../../server'
import '../_message.scss'

export default function messageDetailCtrl($rootScope, $stateParams, $sce, $scope, $http, $state) {

  $scope.getMessageDetail = function () {
    messageService.getMessageDetail({
      id: $stateParams.id
    }).then(function (data) {
      $scope.message = data;
      $scope.message.content = $sce.trustAsHtml(data.content);
      //调用详情接口会自动更新读取状态
      //   $scope.updateStatus();
      $scope.getMessageList();
      $scope.$apply();
    })
  }

  $scope.getMessageDetail();
  $scope.gotoMessageList = function () {
    $state.go('message');
  }
  $scope.getTypeValue = function (value) {
    if (value == "0")
      return "普通消息";
    if (value == "1")
      return "系统消息";
    if (value == "2")
      return "预警消息";
    if (value == "3")
      return "定时推送消息";
  };

  //更新信息状态
  $scope.getMessageList = function () {
    //刷新未读消息列表
    messageService.getMessageList({
      pageNumber: 1,
      pageSize: 999,
      status: '0'
    }).then(function (response) {
      $rootScope.unreadMessageLists = response.list;
      $rootScope.unreadMessageCount = response.count;
      if ($rootScope.unreadMessageCount > 99) {
        $rootScope.unreadMessageCount = "99+";
      }
      $rootScope.$apply();
    }, err => {
      toastr.error(err.head.errorMsg || "获取消息列表失败!");
    })
  }



}

/**
 * @author jaray
 *   
 */

  /** @ngInject */
  export default  function kameleonImg(layoutPaths) {
    return function(input) {
      return layoutPaths.images.root + 'theme/icon/kameleon/' + input + '.svg';
    };
  }


import { modelCenterService, modelBuyService } from '../../../server'
import  {jsTreeUtils} from '../../../common/utils/JsTreeUtils'
// import * as jsTreeUtil from '../../../common/utils/JsTreeUtils'
export default function ($rootScope, dmData, isOrder, $scope, $uibModalInstance, $filter,ModalUtils, $state) {
    var translate = $filter('translate');
    $scope.buttonClick = false;
    $scope.isOrder = isOrder;
    $scope.showDtp=true;

    if (isOrder) {
        $scope.marketOrder = dmData;
        $scope.dataMarket = dmData.dataMarket;
        if($scope.dataMarket.sourceType == '3') $scope.showDtp=false;
    } else {
        $scope.dataMarket = dmData;
    }
    if($scope.dataMarket.sourceType == '3') {
        $scope.showDtp=false
        $scope.dtp={id: "-1",label: "无",isDtpManager: false};
    };
    $scope.close = function () {
        $uibModalInstance.close();
    };
    
    var originalData = [];
    var treeID = "folderTreeID";
    /**  加载jstree **/
    $scope.treeConfig = jsTreeUtils.jsTreeConfig1;

    //获取数据源列表
    // var getDatasourceList = function () {
    //     $http.get("api/bcp/dashboard/getDatasourceList").success(function (response) {
    //         $scope.datasourceList = response;
    //     });
    // };
    // getDatasourceList();

    var getDatasourceList = function (callback) {
        if ($scope.dtp.isDtpManager) {
            modelBuyService.getDataSourceList({
                "dataJson": angular.toJson($scope.dtp)
            }).then(function (response) {
                if (response.status != '1') {
                    ModalUtils.alert("获取数据源列表失败", "modal-warning", "lg");
                } else {
                    $scope.datasourceList = response.data;
                    if(callback){
                        callback();
                    }
                }
            })
        } else {
            modelBuyService.getDataSourceList().then(function (response) {
                $scope.datasourceList = response;
                if(callback){
                    callback();
                }
            });
        }
    }

    var getDtpList = function () {
        modelBuyService.getDtpList().then(function (response) {
            $scope.dtpList = [{id: "-1",label: "无",isDtpManager: false}];
            angular.forEach(response.data.listdata, function (item) {
                $scope.dtpList.push(item)
            })
        });
    }
    getDtpList();
    //选中数据源
    $scope.checkSource = function (data) {
        $scope.dataMarket.datasource = data.id;
        $scope.dataMarket.mappingName = data.dbIdentifier;
    }

    $scope.checkDtp =function(data){
        $scope.dtp = data;
        if(!$scope.dtp) $scope.dtp={id: "-1",label: "无",isDtpManager: false};
        getDatasourceList(function(){
            $scope.showDtp=false;
        })
    }

    $scope.goBackDtp =function(){
        $scope.showDtp=true;
        $scope.dataMarket.datasource =null;
    }

    //获取数据集文件夹列表
    var getDatasetCategoryList = function () {
        modelBuyService.getDatasetCategoryList().then(function (response) {
            $scope.folderList = response;
            $scope.searchNode();
        });
    };

    //获取图表文件夹列表
    var getWidgetCategoryList = function () {
        modelBuyService.getWidgetCategoryList().then(function (response) {
            $scope.folderList = response;
            $scope.searchNode();
        });
    };

    //获取看板文件夹列表
    var getBoardCategoryList = function () {
        modelBuyService.getCategoryList().then(function (categoryFolder) {
            originalData = jsTreeUtils.jstree_CvtVFolderTreeData(
                categoryFolder.map(function (ds) {
                    var categoryName = ds.categoryName == null ? translate('CONFIG.DASHBOARD.MY_DASHBOARD') : ds.categoryName;
                    return {
                        "id": ds.id,
                        "name": ds.name,
                        "parentId": ds.parentId
                    };
                })
            );
            $scope.folderList = categoryFolder;
            $scope.searchNode();
        })
    };

    //获取文件夹列表
    var getFolderList = function () {
        if (!$scope.dataMarket || !$scope.dataMarket.dataType) alert("获取数据集失败")
        if ($scope.dataMarket.dataType == '1') {
            getDatasetCategoryList();
        } else if ($scope.dataMarket.dataType == '2') {
            getWidgetCategoryList();
        } else {
            getBoardCategoryList();
        }
    }
    getFolderList();
    //显示树形文件夹
    $scope.searchNode = function () {
        //组装数据成jstree需要的形式
        var list = $scope.folderList.map(function (data, index) {
            if ($scope.dataMarket.dataType == '3') {
                return {
                    "id": data.id,
                    "parentId": data.parentId,
                    "name": data.name
                };
            } else {
                return {
                    id: "-1",
                    // parent: "#", //有parentId为目录,没有为文件
                    categoryName: data, //文件夹名,ourboreJsTree会将他拆分,很坑爹!
                    intactCategory: data, //文件夹名,自定义不拆分的文件夹
                }
            }
        })
        //设置配置文件夹
        //过滤文件夹,用于支持模糊搜索
        if ($scope.dataMarket.dataType == '3') {
            originalData = jsTreeUtils.jstree_CvtVFolderTreeData(
                $filter('filter')(list, {
                    name: $scope.keywords
                })
            );
        } else {
            originalData = jsTreeUtils.jstree_CvtVPath2TreeData(
                $filter('filter')(list, {
                    categoryName: $scope.keywords
                })
            );
        }
        jsTreeUtils.jstree_ReloadTree(treeID, originalData);
    };

    //生成订单
    $scope.generateOrder = function (dataMarket) {
        $scope.buttonClick = true;
        if (!$scope.dtp) {
            ModalUtils.confirm(translate("请选择DTP"), "modal-warning", "sm");
            $scope.buttonClick = false;
            return;
        }
        if (!$scope.dataMarket.datasource && $scope.dataMarket.sourceType!='3') {
            ModalUtils.confirm(translate("请选择数据源"), "modal-warning", "sm")
            $scope.buttonClick = false;
            return;
        };
        var selectedFolder = jsTreeUtils.jstree_GetSelectedNodes(treeID)[0] && jsTreeUtils.jstree_GetSelectedNodes(treeID)[0].original ;
        if (!selectedFolder) {
            ModalUtils.confirm(translate("请选择文件夹"), "modal-warning", "sm");
            $scope.buttonClick = false;
            return;
        }

        // return;
        if ($scope.dataMarket.dataType == '3') {
            $scope.dataMarket.categoryId = selectedFolder.id;
            $scope.dataMarket.categoryName = selectedFolder.text;
        } else {
            $scope.dataMarket.categoryName = selectedFolder.intactCategory
        }
        $scope.dataMarket.isDtpManager = $scope.dtp.isDtpManager;
        $scope.dataMarket.dtpId = $scope.dtp.id;
        //生成数据集
        if (isOrder) {
            resetDataMarket();
        } else {
            generateData();
        }

    }

    //订单重置数据模型(重新生成和强制更新)
    var resetDataMarket = function () {
        $scope.marketOrder.dataMarket = $scope.dataMarket;
        modelCenterService.resetDataMarket({
            "orderJson": angular.toJson($scope.marketOrder)
        }).then(function (response) {
            $scope.buttonClick = false;
            if (response.status != "1" && response.status != "3") {
                ModalUtils.confirm(response.msg || response.head.errorMsg, "modal-warning", "sm");
                if(response.head && response.head.errorCode && response.head.errorCode=="0002"){
                    $scope.close()
                    $state.go("model.account");
                }
                return;
            }
            ModalUtils.confirm(response.msg, "modal-success", "sm");
             //当生成成功看板时,刷新看板列表
            // if($scope.dataMarket.dataType=='3')getBoardList();
        })
    }

    //生成数据模型
    var generateData = function () {
        modelCenterService.generateDataMarket($scope.dataMarket).then(function (response) {
            $scope.buttonClick = false;
            if (response.status != "1" && response.status != "3") {
                ModalUtils.confirm(response.msg || response.head.errorMsg, "modal-warning", "sm");
                if(response.head && response.head.errorCode && response.head.errorCode=="0002"){
                    $scope.close();
                    $state.go("model.account");
                }
                return;
            }
            ModalUtils.confirm(response.msg, "modal-success", "sm");
            //当生成成功看板时,刷新看板列表
            // if($scope.dataMarket.dataType=='3')getBoardList();
        })
    }

    //刷新看板列表
    // var getBoardList = function () {
    //     modelBuyService.getBoardList().then(function (response) {
    //         $rootScope.showBoardList = response;
    //     });
    // };
};
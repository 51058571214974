import behaviorLogListTpl from './behaviorLogList.html'
import behaviorLogListCtrl  from './behaviorLogListCtrl'
  
export default angular.module('borepc.pages.behaviorLog', [])
.controller('behaviorLogListCtrl', ['toastr','$scope', '$state', 'ModalUtils',behaviorLogListCtrl])
.config(['$stateProvider',routeConfig]).name ;

function routeConfig($stateProvider) {
  $stateProvider
    .state('behaviorLog', {
      url: '/behaviorLog',
      title: '用户行为日志',
      template: behaviorLogListTpl,
      controller: 'behaviorLogListCtrl'
    })
}


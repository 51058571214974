/**
 * Created by  jaray  2018-11-28
 */
'use strict';

import EchartRenderEngine from "../../rengine/EchartRenderEngine";
import * as util from '../../util/util'
class RadarRender{
    //默认高度
    height = 300;

     render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        // console.log("render randar =======")
        var render = new  EchartRenderEngine(containerDom, option);
        // render.addClick(chartConfig, relations, $state, $window);
        render.addClick(chartConfig, relations);
        return render.chart(null, persist);
    };

    parseOption = function (_data) {
        var chartConfig = _data.chartConfig;
        var casted_keys = _data.keys;
        var casted_values = _data.series;
        var aggregate_data = _data.data;
        var colFormatter = [];
        //获取指标数组
        for (var i = 0; i < chartConfig.values.length; i++) {
            for (var j = 0; j < chartConfig.values[i].cols.length; j++) {
                var col = chartConfig.values[i].cols[j];
                colFormatter.push(col);
            }
        }
        var string_keys = _.map(casted_keys, function (key) {
            return key.join('-');
        });
        var string_values = _.map(casted_values, function (value) {
            return value.join('-');
        });
        var data = [];
        var max;
        var indicator = [];
        if (chartConfig.drawBy === 'row') {
            for (var i = 0; i < string_keys.length; i++) {
                var d = {value: [], name: string_keys[i]};
                for (var j = 0; j < casted_values.length; j++) {
                    d.value[j] = aggregate_data[j][i];
                    var n = Number(aggregate_data[j][i]);
                    if (_.isUndefined(max) || n > max) {
                        max = n;
                    }
                }
                data.push(d);
            }
            for (var j = 0; j < string_values.length; j++) {
                indicator.push({name: string_values[j], max: max * 1.05});
            }
        } else {
            for (var i = 0; i < string_values.length; i++) {
                var d = {value: [], name: string_values[i]};
                for (var j = 0; j < string_keys.length; j++) {
                    d.value[j] = aggregate_data[i][j];
                    var n = Number(aggregate_data[i][j]);
                    if (_.isUndefined(max) || n > max) {
                        max = n;
                    }
                }
                data.push(d);
            }
            for (var j = 0; j < string_keys.length; j++) {
                indicator.push({name: string_keys[j], max: max * 1.05});
            }
        }


        var echartOption = {
            tooltip: {
                trigger: 'item',
                formatter:function(params){
                    var s = params.name + "</br>";
                    if(chartConfig.drawBy === 'row') {
                        for (var i = 0; i < params.value.length; i++) {
                            var value = util.convertEchartUnit(colFormatter[i % colFormatter.length],params.value[i]);
                            _.isUndefined(value) ? value = '' : null;
                            s += string_values[i]+":"+value+"</br>"
                        }
                    } else {
                        for (var i = 0; i < params.value.length; i++) {
                            var value = util.convertEchartUnit(colFormatter[params.dataIndex % colFormatter.length],params.value[i]);
                            _.isUndefined(value) ? value = '' : null;
                            s += string_keys[i]+":"+value+"</br>"
                        }
                    }

                    return s;
                }
            },
            toolbox: false,
            legend: {
                orient: 'vertical',
                left: 'left',
                data: chartConfig.drawBy === 'row' ? string_keys : string_values
            },
            radar: {
                indicator: indicator
            },
            series: [{
                name: 'radar',
                type: 'radar',
                // itemStyle: {
                //     emphasis: {
                //         areaStyle: {color: 'rgba(0,250,0,0.3)'}
                //     }
                // },
                // symbol: 'emptyCircle',  //5.0.2 radar不支持空心圆
                symbolSize: 4,
                areaStyle: {},
                emphasis: {
                    areaStyle: {
                        color: 'rgba(0,250,0,0.3)'
                    }
                },
                data: data
            }]
        };

        util.updateEchartOptions(chartConfig.option, echartOption);
        util.updateEchartsTooltipPosition(echartOption);
        return echartOption;
    };
}
export  {RadarRender}

import BasicService from './BasicService';
import { DATASETMETATABLEAPI } from './ApiServer';

class DatasetMetaTable extends BasicService {
  constructor() {
    super();
  }
  delMetaTable(params){
    return this.post( DATASETMETATABLEAPI.DELMETATABLE,params)
  }
  saveMetaTable(params){
    return this.post( DATASETMETATABLEAPI.SAVEMETATABLE,params)
  }
  getDetail(params){
    return this.post( DATASETMETATABLEAPI.GETDETAIL,params)
  }
  getDetailByToken(params){
    return this.post( DATASETMETATABLEAPI.GETDETAILBYCODE,params)
  }
  getPageList(params){
    return this.post( DATASETMETATABLEAPI.GETPAGELIST,params)
  }
  getAllList(params){
    return this.post( DATASETMETATABLEAPI.GETALLLIST,params)
  }
}

export const datasetMetaTableService = new DatasetMetaTable();

import EchartRenderEngine from "../../rengine/EchartRenderEngine";

/**
 * Created by  jaray  2018-11-28
 */
class  LiquidFillRender {
    //默认高度
    height = 300;

    render = function (containerDom, option, scope, persist) {
        if (option == null) {
            containerDom.html("<div class=\"alert alert-danger\" role=\"alert\">No Data!</div>");
            return;
        }
        var height;
        scope ? height = scope.myheight - 20 : null;
        return new  EchartRenderEngine(containerDom, option).chart(height, persist);
    };
   parseOption = function (data) {
        var config = data.chartConfig;
        var maxValue = config.values[0].maxValue ? config.values[0].maxValue : 100;
        maxValue = Number(maxValue);

        var animation = true;
        if (config.animation == 'static') {
            animation = false;
        }

        var style = config.values[0].style ? config.values[0].style : "circle";

        var datas = [];
        var value = data.data.length > 0 ? data.data[0][0] : 'N/A';
        if (value != 'N/A' && value < maxValue) {
            for (var i = 1; i < 5; i++) {
                var percent = 1;
                for (var j = 1; j < i; j++) {
                    percent = percent * 0.95;
                }
                datas.push(value * percent / maxValue);
            }
        }
        if(value != 'N/A' && value >= maxValue){
            for( var i = 0;i < 5;i ++){
                datas.push(1)
            }
        }

        var option = {
            series: [{
                type: 'liquidFill',
                shape: style,
                data: datas,
                waveAnimation: animation,
                radius: '70%',
                backgroundStyle: {
                    borderWidth: 2,
                    borderColor: '#156ACF'
                },
                outline: {
                    show: false
                },
                label: {
                    // normal: {
                    //     textStyle: {
                            color: '#294D99',
                            fontSize: 45
                        // }
                    // }
                }
            }],
            // label:{
            //     textStyle: {
            //         fontSize: 35,
            //         fontFamily: 'Lobster Two',
            //         color:"#000"
            //     }
            // },
        };

        option.label = {};
        if(datas[0] == 1){
            option.label.normal = {
                formatter:"I'm full."
            }
        }

        //调整字符大小
        if ( config.option.isOption && config.option.liquidFillFontSize) {
            // var curFontSize = Number(config.option.liquidFillFontSize.trim());
            var curFontSize = config.option.liquidFillFontSize;
            if (!isNaN( curFontSize )) {
                // option.series[0].label.normal.textStyle.fontSize = curFontSize;
                option.series[0].label.fontSize = curFontSize;
            }
        }
        //调整字符颜色
        if ( config.option.isOption && config.option.liquidFillColor) {
            // option.series[0].label.normal.textStyle.color = config.option.liquidFillColor;
            option.series[0].label.color = config.option.liquidFillColor;
        }
        return option;
    };
} 

export {LiquidFillRender}
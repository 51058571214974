import {borePropertyService} from '../../../server'
import propertyEditTpl from './propertyEdit.html'
import windowTpl from '../../../common/theme/components/modal/window.html'
import './_boreProperty.scss'


export default  function borePropertyCtrl(ModalUtils,toastr,$scope,$uibModal) {
    $scope.isLoading = true;

    $scope.getPropertyList = function(key){
        $scope.isLoading = true;
        borePropertyService.getPropertyList({key:key}).then(function(response){
            $scope.propertyList = response;
            $scope.isLoading = false;
            $scope.$apply();
        },err=>{
            $scope.isLoading = false;
            $scope.$apply();
            toastr.error(err.head.errorMsg || '获取系统变量列表失败')
        })
    }
    $scope.getPropertyList();
    $scope.delProperty = function(id){
        ModalUtils.confirm("确定要删除系统变量吗?", "modal-warning", "lg", function () {
            borePropertyService.delProperty({id:id}).then(function(response){
                toastr.success("删除系统变量成功!")
                $scope.getPropertyList();
            },err=>{
                toastr.error(err.head.errorMsg || '删除系统变量失败!');
            })
        })
       
    }

     //编辑预警
     $scope.gotoEdit = function (property,option) {       
        $uibModal.open({
            template: propertyEditTpl,
            windowTemplate:windowTpl,
            size: 'lg',
            windowClass: 'bProperty-modal',
            resolve: {
                ok: function () {
                    return function () {
                        $scope.isLoading = true;
                        borePropertyService.getPropertyList().then(function(response){
                            $scope.propertyList = response;
                            $scope.isLoading = false;
                        },err=>{
                            $scope.isLoading = false;
                            toastr.error(err.head.errorMsg || '获取系统变量列表失败')
                        })  
                    }
                },
                property:function(){
                    return property;
                },
                option:function(){
                    return option;
                }
            },
            controller:  ['$scope', '$uibModalInstance', 'toastr','ok','property','option',function ($scope, $uibModalInstance, toastr,ok,property,option) {
                $scope.property = angular.copy(property);
                $scope.option = option;
                var validateData = function(){
                    if(!$scope.property.value || !$scope.property.key){
                        ModalUtils.alert("必填项不能为空!", "modal-warning", "lg")
                        return false;
                    }
                    return true;
                }

                $scope.saveProperty =function(){
                    if(!validateData()) return;
                    var params = {
                        id:$scope.property.id,
                        key:$scope.property.key,
                        value:$scope.property.value,
                        remark:$scope.property.remark,
                        modifyBy:$scope.property.modifyBy,
                    }
                    borePropertyService.saveProperty(params).then(function (response) {
                        toastr.success("保存变量成功");
                        $uibModalInstance.close();
                        ok();
                    },err=>{
                        toastr.error(err.head.errorMsg || '修改系统变量失败')
                    })
                }

                $scope.close =function(){
                    $uibModalInstance.close();
                }
                
            }]
        });
    };
}

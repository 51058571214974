import icon_pic from './budget.png'
import icon_pic_active from './budget-active.png'
var config = {
    rule: {
        keys: 0,
        groups: 0,
        filters: -1,
        values: -1
    },
    info:{
        name: '预算',
        value: 'budget',
        // class: 'cBudget',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        measure:'CONFIG.WIDGET.TIPS_DIM_NUM_0_MORE'
    }
}
export default config;
import boardContext from '../../../../charts/BoardContext'
import {userService,behaviorLogService} from '../../../../server';

export default  function($scope,widgetModal,ModalUtils,toastr){
    $scope.reload=function(widget){
        var relationIds = [];
        try {
            var relations = widget.relations ? widget.relations.relations : [];
            for(let i = 0, l = relations.length; i< l; i++) {
                if(relations[i].type == 'widget' && !!relations[i].targetId) {
                    relationIds.push(relations[i].targetId);
                }
            }
        } catch {

        }

        boardContext.clearAllWidgetFilters();
        boardContext.refreshWidget(widget.renderId || widget.widgetId);
        for(let i = 0, l = relationIds.length; i < l; i++){
            boardContext.refreshWidget(relationIds[i]);
        }
        // PubSub.publish(widget.widgetId, '')
        // widget.reload();
    }
   $scope.modalChart=function(widget){
       widgetModal.show(widget)
   }
   $scope.editWidget=function(widget){
        userService.judgePermission({resType:'widget',resId:widget.widgetId,option:'update'}).then(function(data){
            if(data){
                // widgetModal.open(widget.widgetId);

                var modalInstances = widgetModal.open(widget.widgetId);
                if(modalInstances.result){
                    modalInstances.result.then(function(result){
                        widget = angular.copy(result);
                    })
                }
                return;
            }else{
                toastr.error("您没有编辑权限");
            }
       })
   }
   $scope.showHelp=function(widget){
      var tips= widget.widget.data.analysisText || '无';
       ModalUtils.showMsg('Tips！',tips)
   }
   $scope.showFilter=function(widget){
    ModalUtils.showFilter( widget)
   }
//    $scope.$on('timelineview', function(event,data){
//     //    console.log("datadata",board)
//         $scope.timeline = data
//         $scope.$apply()
//    });
//    console.log("$scope.timelineqwerwqer",$scope.timeline)
}
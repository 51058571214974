import EchartRenderEngine from "../../rengine/EchartRenderEngine";
import numbro from 'numbro'
import * as util from '../../util/util'

/**
 * Created by  jaray  2018-11-28
 */
class WorldMapRender {
    //默认高度
    height = 500;

    render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        var render = new  EchartRenderEngine(containerDom, option);
        // render.addClick(chartConfig, relations, $state, $window);
        render.addClick(chartConfig, relations);
        return render.chart(null, persist);
    };

    parseOption = function (data) {
        var chartConfig = data.chartConfig;
        var casted_keys = data.keys;
        var casted_values = data.series;
        var aggregate_data = data.data;
        var tunningOpt = chartConfig.option;

        var code = 'world';
        var mapData;
        if (chartConfig.option.lang) {
            mapData = code + '-' + chartConfig.option.lang + '.json';
        } else {
            mapData = code + '-' + 'cn' + '.json';
        }
        var url = '/share/FineMap/mapdata/' + mapData;
        var mapOption = null;
        var groups = _.map(casted_values, function (val) {
            return val.join("-")
        });

        //地图区域颜色设置
        var areaSetting = {
            areaColor: '#EFF0F0',
            heightlightColor: '#FFD664',
            borderColor: '#B5B5B5',
            borderWidth: '1'
        };
        if(!!tunningOpt.areaSetting) {
            areaSetting = tunningOpt.areaSetting;
        }

        //文本标签设置
        var seriesLabel = {
                    normal: {
                        show: false,
                        position: 'left',
                        lineHeight: 20,
                        fontSize: 12,
                        align: 'left'
                    },
                    emphasis: {
                        show: true,
                        backgroundColor: '#838a9d',
                        color: '#fff'
                    }
        };
        if(!!tunningOpt.seriesLabel) {
            var labelOpt = tunningOpt.seriesLabel;
            if(labelOpt.normalShow) {
                seriesLabel.normal.show = labelOpt.normalShow;
                seriesLabel.normal.color = labelOpt.normalColor;
                seriesLabel.normal.backgroundColor = labelOpt.normalBackColor;
            }
            seriesLabel.emphasis.backgroundColor = labelOpt.heightlightBackColor;
            seriesLabel.emphasis.color = labelOpt.heightlightColor;
            if(labelOpt.fontSize > 0) {
                seriesLabel.normal.fontSize = labelOpt.fontSize;
                seriesLabel.normal.lineHeight = labelOpt.fontSize + 8;
            }

            //数值
            if(labelOpt.valueShow) {
                seriesLabel.normal.formatter = function(params){
                    if( isNaN(params.value) ) {
                        return "";
                    } else {
                        var value = !!tunningOpt.visualMap ? util.convertEchartUnit(tunningOpt.visualMap, params.value) : params.value;

                        switch( labelOpt.valueOrient ) {
                            case "right"  :
                                return " " + params.name + " : " + value + " ";
                            case "bottom" :
                                return " " + params.name + " \n " + value + " ";
                            case "only"   :
                                return " " + value + " ";
                        }
                    }
                };
            } else {
                seriesLabel.normal.formatter = function(params){
                    if( isNaN(params.value) ) {
                        return "";
                    } else {
                        return " " + params.name + " ";
                    }
                };
            }

        } else {
            seriesLabel.normal.formatter = " {b} ";
        }

        var series = [];
        var nameAll = [];   //用于计算最大值
        for (var i = 0; i < groups.length; i++) {
            var sData = [];
            for (var j = 0; j < aggregate_data[i].length; j++) {
                var rawName = casted_keys[j][chartConfig.keys.length - 1];
                var e = {
                    "name": rawName,
                    "value": aggregate_data[i][j] ? aggregate_data[i][j] : 0
                };
                sData.push(e);
                nameAll.indexOf(rawName) == -1 ? nameAll.push(rawName) : null;
            }
            var e = {
                name: groups[i],
                type: 'map',
                map: code,
                // roam: true,
                // tooltip: {
                //     trigger: 'item'
                // },
                label: seriesLabel,
                showLegendSymbol: tunningOpt.showLegendSymbol === false ? false : true,
                itemStyle: {
                    normal: {
                        areaColor: areaSetting.areaColor,
                        borderColor: areaSetting.borderColor,
                        borderWidth: areaSetting.borderWidth > 0 ? areaSetting.borderWidth : 1
                    },
                    emphasis: {
                        show: true,
                        areaColor: areaSetting.heightlightColor
                    }
                    // emphasis: {
                    //     label: {
                    //         show: true
                    //     }
                    // }
                },
                data: sData
            };
            series.push(e);
        }

        //图例自定义设置
        var legend = {
            data: groups,
            left: 'left',
            top: 'top',
            orient: 'vertical'
        };
        if (tunningOpt.legendShow === false) {
            legend.show = false;
        } else {
            legend.show = true;
            legend.type = tunningOpt.legendType ? tunningOpt.legendType : 'plain';                 //自定义
            legend.left = tunningOpt.legendX ? tunningOpt.legendX : 'left';                        //自定义
            legend.top  = tunningOpt.legendY ? tunningOpt.legendY : 'top';                         //自定义
            legend.orient = tunningOpt.legendOrient ? tunningOpt.legendOrient : 'horizontal';      //自定义
            if(tunningOpt.legendOrient == 'horizontal') {
                tunningOpt.legendWidth > 0 ? legend.width = tunningOpt.legendWidth : null;         //自定义
            }
            if(tunningOpt.legendOrient == 'vertical') {
                tunningOpt.legendHeight > 0 ? legend.height = tunningOpt.legendHeight : null;      //自定义
            }
            legend.tooltip = {
                show: tunningOpt.legendTooltipShow ? tunningOpt.legendTooltipShow : false                 //自定义
            };
            legend.padding = tunningOpt.legendPadding ? tunningOpt.legendPadding : [6, 10];
            legend.backgroundColor = tunningOpt.legendBackgroundColor ? tunningOpt.legendBackgroundColor : 'transparent';  //自定义
            legend.borderRadius = tunningOpt.legendBorderRadius > 0 ? tunningOpt.legendBorderRadius : 0;
            legend.textStyle = {
                color: function(){
                    // console.log(arguments)
                },
                fontSize: tunningOpt.legendFontSize > 0 ? tunningOpt.legendFontSize : 12,                 //自定义
                padding: [2,0,0,0]
            };
            legend.inactiveColor = '#ccc';
            legend.formatter = function(name){                                           //自定义了最大字数
                var nameStr;
                if(tunningOpt.legendFontMaxMount && tunningOpt.legendFontMaxMount > 0) {
                    if(name && name.length > tunningOpt.legendFontMaxMount) {
                        nameStr = name.slice(0, tunningOpt.legendFontMaxMount) + '...';
                    } else {
                        nameStr = name;
                    }
                } else {
                    nameStr = name;
                }
                return nameStr;
            };
            // 图例块
            legend.icon = tunningOpt.legendIcon ? tunningOpt.legendIcon : 'rect';                      //自定义
            legend.itemGap = tunningOpt.legendItemGap || tunningOpt.legendItemGap === 0 ? tunningOpt.legendItemGap : 10;
            legend.itemWidth = tunningOpt.legendItemWidth > 0 ? tunningOpt.legendItemWidth : 15;       //自定义
            legend.itemHeight = tunningOpt.legendItemHeight > 0 ? tunningOpt.legendItemHeight : 10;    //自定义

            // 滚动相关样式
            if(tunningOpt.legendType == 'scroll') {
                legend.pageButtonPosition = 'end';
                legend.pageIconColor = tunningOpt.legendPageIconColor ? tunningOpt.legendPageIconColor : '#136efa';    //自定义
                legend.pageIconInactiveColor = tunningOpt.legendPageIconInactiveColor ? tunningOpt.legendPageIconInactiveColor : '#a6aab8';    //自定义
                legend.pageIconSize = tunningOpt.legendPageIconSize > 0 ? tunningOpt.legendPageIconSize : 14;    //自定义
                legend.pageTextStyle = {
                    fontSize: tunningOpt.legendPageTextFontSize > 0 ? tunningOpt.legendPageTextFontSize : 14,     //自定义
                    color: tunningOpt.legendPageTextColor ? tunningOpt.legendPageTextColor : '#333',              //自定义
                    lineHeight: tunningOpt.legendPageTextFontSize > 0 ? tunningOpt.legendPageTextFontSize : 14    //同字体大小
                };
                // 控件和图例间的间距
                legend.pageButtonGap = tunningOpt.legendPageButtonGap || tunningOpt.legendPageButtonGap === 0 ? tunningOpt.legendPageButtonGap : 15;
                legend.animation = true;
            }

        }

        //提示悬浮窗设置
        var tipset = {};
        if(!!tunningOpt.tip) {
            tipset = tunningOpt.tip;
        } else {
            tipset = {
                backColor: '#666',
                fontColor: '#fff',
                fontSize: 12
            };
        }

        //计算最大值
        var max = 0;
        for(var i = 0; i < nameAll.length; i++){
            //遍历series找到该区域所有的值相加
            var iMax = 0;
            for(var j = 0; j < series.length; j++) {
                var singeSeries = series[j].data.filter(function(item){
                    return item.name == nameAll[i];
                });
                iMax += Number(singeSeries[0].value);
            }
            max > iMax ? null : max = iMax;
        }
        // var totals = [];
        // for (var i = 0; i < casted_values.length; i++) {
        //     var total = 0;
        //     for (var j = 0; j < aggregate_data[i].length; j++) {
        //         total += parseFloat(!isNaN(aggregate_data[i][j]) ? aggregate_data[i][j] : 0);
        //     }
        //     totals.push(total);
        // }
        // totals.sort(function (a, b) {
        //     return a - b;
        // });
        // var max = totals[totals.length - 1];

        //标题设置
        var widgetTitle = {};
        if(tunningOpt.title) {
            widgetTitle = tunningOpt.title;
            widgetTitle.padding = [7 , 14];
        }

        //视觉映射组件自定义设置
        var visualMap = {
                type: 'continuous',
                show: true,
                min: 0,
                max: max,
                left: "right",
                top: "bottom",
                orient: "vertical",
                realtime: false,
                padding: 10,
                inRange: {
                    color:  ['lightskyblue', 'yellow', 'orangered']
                },
                calculable: true,
        };
        if(!!tunningOpt.visualMap) {
            var vOpt = tunningOpt.visualMap;
            visualMap.type = vOpt.type;
            visualMap.show = vOpt.show;
            visualMap.left = vOpt.left;
            visualMap.top = vOpt.top;
            visualMap.orient = vOpt.orient;
            vOpt.min === undefined ? null : visualMap.min = vOpt.min;
            vOpt.max === undefined ? null : visualMap.max = vOpt.max;
            visualMap.backgroundColor = vOpt.backgroundColor;
            visualMap.textStyle = {
                fontSize: vOpt.textStyle.fontSize > 0 ? vOpt.textStyle.fontSize : 12,
                color: vOpt.textStyle.color
            };
            visualMap.inRange = vOpt.inRange;

            if(visualMap.type === "continuous") {
                visualMap.formatter = function(value){
                    var num = numbro(value).format({
                            thousandSeparated: false,
                            mantissa: 0
                        });

                    num = util.convertEchartUnit(vOpt, num);
                    return num;
                }
            }
            if(visualMap.type === "piecewise") {
                visualMap.splitNumber = visualMap.inRange.color.length;

                var length = (visualMap.max - visualMap.min) / visualMap.splitNumber;   //一段的长度
                visualMap.formatter = function(value){
                    var max = numbro( value + length).format({
                        thousandSeparated: false,
                        mantissa: 0
                    });
                    var min = numbro(value).format({
                            thousandSeparated: false,
                            mantissa: 0
                        });
                    min = util.convertEchartUnit(vOpt, min);
                    max = util.convertEchartUnit(vOpt, max);
                    return min + "-" + max;
                }
            }

        }



        $.ajax({
            type: "get",
            url: url,
            async: false,
            success: function (worldJson) {
                echarts.registerMap(code, worldJson);
                mapOption = {
                    title: widgetTitle,
                    backgroundColor: tunningOpt.backgroundColor ? tunningOpt.backgroundColor : 'rgba(0,0,0,0)',
                    legend: legend,
                    roam: tunningOpt.roam ? tunningOpt.roam : false,
                    // toolbox: {
                    //     show: false,
                    //     orient: 'vertical',
                    //     left: 'right',
                    //     top: 'center',
                    //     feature: {
                    //         dataView: {
                    //             readOnly: false
                    //         },
                    //         restore: {},
                    //         saveAsImage: {}
                    //     }
                    // },
                    // visualMap: {
                    //     min: 0,
                    //     max: max,
                    //     realtime: false,
                    //     calculable: true,
                    //     inRange: {
                    //         color: ['lightskyblue', 'yellow', 'orangered']
                    //     }
                    // },
                    visualMap: visualMap,
                    tooltip: {
                        trigger: 'item',
                        backgroundColor: tipset.backColor,
                        padding: [5, 10],
                        textStyle: {
                            color: tipset.fontColor,
                            fontSize: tipset.fontSize > 0 ? tipset.fontSize : 12
                        },
                        formatter: function(params){
                            var tipString = params.name + "<br/>";
                            //遍历所有指标
                            for(var i = 0; i < series.length; i++) {
                                var flag = false;                       //默认区域不在系列里
                                //与if配合查找对应的区域
                                for(var j = 0; j < series[i].data.length; j++) {
                                    if(series[i].data[j].name == params.name) {
                                        //获取指标的名称---对应col
                                        var names = series[i].name.split("-");
                                        var name = names[names.length - 1];
                                        var col = chartConfig.values[0].cols.filter(function(col){
                                            return col.col == name;
                                        })
                                        //引入指标的单位设置
                                        var numValue = util.convertEchartUnit(col[0], series[i].data[j].value);
                                        tipString += series[i].name + ": " + numValue + "<br/>";
                                        flag = true;
                                    }
                                }
                                if(!flag) {
                                    tipString += series[i].name + ": -<br/>";
                                }
                            }
                            return tipString;
                        }
                    },
                    series: series
                };
            }
        });
        util.updateEchartsTooltipPosition(mapOption);
        return mapOption;
    };
}
export {WorldMapRender}

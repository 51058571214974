import {reportService,userService,behaviorLogService} from '../../../server'

export default  function reportListCtrl($scope, $rootScope,  ModalUtils, $window, $filter, $state,toastr ) {
	$scope.isLoading = true;
	$scope.search = {};
	$scope.showCardFrame = true;
	// currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
	$scope.paginationConf = {
		currentPage: 1,
		totalItems: 0,
		itemsPerPage: 24,
		onChange: function () {
			getReportList(); //重新加载
		}
	};



	//获取当前的分辨率,根据分辨率调整每页数量
	$scope.$watch('window.innerWidth', function () {
		if ($window.innerWidth > 1800) {
			$scope.paginationConf.itemsPerPage = 24;
		}
		if (1500 < $window.innerWidth &&  $window.innerWidth<= 1800) {
			$scope.paginationConf.itemsPerPage = 24;
		}
		if ($window.innerWidth <= 1500) {
			$scope.paginationConf.itemsPerPage = 24;
		}
	});

	//获取report列表
	var getReportList = function () {
		var params = {
			pageNum: $scope.paginationConf.currentPage,
			pageSize: $scope.paginationConf.itemsPerPage,
			name: $scope.search.value,
			categoryPId:$scope.categoryId
		}
		reportService.getPageList(params).then(function (response) {
			$scope.reportList = response.listdata;
			$scope.paginationConf.totalItems = response.totolCount;
			$scope.isLoading = false;
			$scope.$apply()
		},err=>{
			$scope.paginationConf.totalItems=0;
			$scope.isLoading = false;
			$scope.$apply()
			if(err&&err.head&&err.head.errorMsg){
				toastr.error(err.head.errorMsg)
			}else{
				toastr.error("服务器异常!获取报表列表失败!")
			}
		})
	}

	var getCategoryList = function(){
		reportService.getReportCategoryList().then(function(response){
			$scope.categoryList = response;
			$scope.categoryList.unshift({id:"0","name":"根目录"})
            $scope.curCategory = [];
            $scope.childrenCategory = _.filter($scope.categoryList, function (e) {
                return e.parentId === "0"
            })
			$scope.$apply();
		},err=>{
			toastr.error(err.head.errorMsg || "获取报表目录失败！")
		})
	}
	getCategoryList();

	//选择目录
    $scope.selectCategory = function(category){
        $scope.curCategory = [];
        $scope.childrenCategory = [];
        if(category){
            var curParentIds = category.parentIds.split(",");
            _.each($scope.categoryList, function (e) {
				if(e.id=="0")return;
                if (e.parentId && e.parentId == category.id) {
                    $scope.childrenCategory.push(e);
                }
                if(curParentIds.indexOf(e.id) > -1){
                    $scope.curCategory.push(e);
                }
            })
            $scope.curCategory.push(category);
            $scope.categoryId = category.id;
            $scope.selectedCategory = category;
            $scope.paginationConf.onChange();
        }else{
            $scope.curCategory = [];
            $scope.childrenCategory = _.filter($scope.categoryList, function (e) {
                return e.parentId === "0"
            })
            $scope.categoryId =null;
            $scope.selectedCategory = null;
            $scope.paginationConf.onChange();
        }
    }

	//新建目录设定参数
	$scope.resetCurCategory = function (isEdit) {
        if(isEdit){
            if(!$scope.selectedCategory){
                toastr.warning("请选择要修改的目录!")
                return;
            }
            $('#reportCategoryModel').modal('show');
            $scope.reportCategory = angular.copy($scope.selectedCategory)
            return;
        }
        $scope.reportCategory = {
            parentId: $scope.categoryId || '',
            name: ''
        }
    }

	$scope.saveCategory = function(){
        reportService.saveReportCategory($scope.reportCategory).then(function(response) {
			$('#reportCategoryModel').modal('hide');
			$scope.selectedCategory = null;
			$scope.categoryId =null;
			getCategoryList();
			getReportList();
			toastr.success("保存目录成功！");
        },err=>{
            toastr.error(err.head.errorMsg || "保存目录失败！")
			$scope.alerts = [{ msg: response.msg, type: 'danger' }];
        });
    }


	$scope.deleteCategory = function (){
        if (!$scope.categoryId) {
            ModalUtils.alert("请选择要删除的目录", "modal-warning", "lg");
            return
        }else {
            ModalUtils.confirm("确定要删除"+$scope.selectedCategory.name+"目录吗？", "modal-warning", "lg", function() {
				reportService.delReportCategory({ id: $scope.categoryId }).then(function() {
					$scope.optFlag = 'none';
					$scope.categoryId =null;
					getCategoryList();
					getReportList();
					toastr.success("删除成功!");
				},err=>{
					toastr.error(err.head.errorMsg || "删除失败!")
				});
            })
        }
    }

	//新建目录设定参数
	$scope.resetCurReport = function () {
        $scope.curReport = {
            categoryId: $scope.categoryId || '',
            name: '新建报表'
        }
    }

	//新增报表并进入报表设计器
	$scope.addReport = function () {
		reportService.add($scope.curReport).then(function(report){
			toastr.success("新增报表成功!");
			$('#reportModel').modal('hide');
			$scope.paginationConf.onChange();
			$scope.$apply();
			$scope.editReport(report);
			behaviorLogService.insertLog({module:"report",function:"新增报表",name:"报表"})
		},err=>{
			if(err&&err.head&&err.head.errorMsg){
				toastr.error(err.head.errorMsg)
			}else{
				toastr.error("服务器异常!获取报表列表失败!")
			}
		})
	}

	//新增报表并进入报表设计器
	$scope.copyReport = function (report) {
		userService.judgePermission({option:"update",resType:"report",resId:report.id}).then(function(data){
			if(!data){
				toastr.error("您没有编辑该报表权限！")
				return;
			}
			reportService.copy({id:report.id}).then(function(){
				toastr.success("复制报表成功!");
				$scope.paginationConf.onChange();
				$scope.$apply();
				behaviorLogService.insertLog({module:"report",function:"复制报表",name:report.name})
			},err=>{
				if(err&&err.head&&err.head.errorMsg){
					toastr.error(err.head.errorMsg)
				}else{
					toastr.error("服务器异常!复制报表失败!")
				}
			})
		})
	}

	$scope.exportReport = function (report) {
		userService.judgePermission({option:"query",resType:"report",resId:report.id}).then(function(data){
			if(!data){
				toastr.error("您没有查看并导出该报表权限！")
				return;
		   	}
			const token =window.localStorage.getItem("x-token").replace(/\"/g, "");
			var url = "/be/api/bcp/report/export2Excel?id="+report.id+"&token="+token;
			window.open(url,"_blank")
      behaviorLogService.insertLog({module:"report",function:"导出报表",name:report.name})
		})
	}

	//获取report详情
	$scope.editReport = function (report) {
		userService.judgePermission({option:"update",resType:"report",resId:report.id}).then(function(data){
			if(!data){
				toastr.error("您没有编辑该报表权限！")
				return;
		   	}
			window.open("/designer.html?id="+report.id+"&name="+report.name)
		})
	}

	$scope.showReport = function (report,type) {
		if(!report||!report.dtpId){
			toastr.warning("数据节点为空,请前往编辑页面设置报表数据节点!")
			return;
		}
		userService.judgePermission({option:"query",resType:"report",resId:report.id}).then(function(data){
			if(!data){
				toastr.error("您没有查看该报表权限！")
				return;
		   	}
			const name = encodeURIComponent(report.name)
			behaviorLogService.insertLog({module:"report",function:"查看报表",name:report.name})
			if(type=="excel"){
				window.open("/excel-preview.html?isPreview=false&pageNum=1&id="+report.id+"&name="+name)
				return;
			}
			window.open("/html-preview.html?isPreview=false&pageNum=1&id="+report.id+"&name="+name)
		})
	}

	$scope.delReport = function (report) {
        ModalUtils.confirm("确定要删除报表"+report.name+"吗?", "modal-warning", "lg", function () {
			userService.judgePermission({option:"delete",resType:"report",resId:report.id}).then(function(data){
				if(!data){
                    toastr.error("您没有删除该报表权限！")
                    return;
			   }
			   reportService.delete({id:report.id}).then(function(response){
					$scope.paginationConf.onChange();
					toastr.success("删除报表:"+report.name+"成功!")
					$scope.$apply();
					behaviorLogService.insertLog({module:"report",function:"删除报表",name:report.name})
				},err=>{
					$scope.isLoading = true;
					$scope.$apply()
					if(err&&err.head&&err.head.errorMsg){
						toastr.error(err.head.errorMsg)
					}else{
						toastr.error("服务器异常!获取报表列表失败!")
					}
				})
			})
		})
	}
};

import EchartRenderEngine from "../../rengine/EchartRenderEngine";
import * as util from '../../util/util'


class WordCloudRender{
    //默认高度
    height = 300;

     render = function (containerDom, option, scope, persist, drill, relations, chartConfig) {
        if (option == null) {
            containerDom.html("<div class=\"alert alert-danger\" role=\"alert\">No Data!</div>");
            return;
        }
        var height;
        scope ? height = scope.myheight - 20 : null;
        var render = new EchartRenderEngine(containerDom, option);
        // render.addClick(chartConfig, relations, $state, $window);
        render.addClick(chartConfig, relations);
        return render.chart(height, persist);
    };

    parseOption = function (data) {
        var names = data.keys;
        var values = data.data;
        var chartConfig = data.chartConfig;
        var tunningOpt = chartConfig.option;

        var datas = [];
        for (var i in names) {
            datas.push({
                name: names[i].join("-"),
                value: values[0][i]
            })
        }
        //提示悬浮窗设置
        var tipset = {};
        if(!!tunningOpt.tip) {
            tipset = tunningOpt.tip;
        } else {
            tipset = {
                backColor: '#666',
                fontColor: '#fff',
                fontSize: 12
            };
        }
        //标题设置
        var widgetTitle = {};
        if(tunningOpt.title) {
            widgetTitle = tunningOpt.title;
            widgetTitle.padding = [7 , 14];
        }
        //词设置
        var wordset = {
            max: 50,
            min: 12,
            fontWeight: "normal"
        };
        if(tunningOpt.wordset) {
            tunningOpt.wordset.max ? wordset.max = tunningOpt.wordset.max : null;
            tunningOpt.wordset.min ? wordset.min = tunningOpt.wordset.min : null;
            tunningOpt.wordset.fontWeight ? wordset.fontWeight = tunningOpt.wordset.fontWeight : null;
        }

        var option = {
            title: widgetTitle,
            backgroundColor: tunningOpt.backgroundColor ? tunningOpt.backgroundColor : 'rgba(0,0,0,0)',
            tooltip: {
                trigger: 'item',
                backgroundColor: tipset.backColor,
                padding: [5, 10],
                textStyle: {
                    color: tipset.fontColor,
                    fontSize: tipset.fontSize > 0 ? tipset.fontSize : 12
                },
                formatter: function(params){
                    var numValue = util.convertEchartUnit(chartConfig.values[0].cols[0], params.value);
                    return params.name + ": " + numValue;
                }
            },
            // toolbox: {
            //     show : true,
            //     feature : {
            //         mark : {show: false},
            //         dataView : {show: true, readOnly: true},
            //         //magicType : {show: true, type: ['line', 'bar']},
            //         restore : {show: false},
            //         saveAsImage : {show: true}
            //     }
            // },
            series: [{
                type: 'wordCloud',
                gridSize: 5,
                sizeRange: [wordset.min, wordset.max],
                rotationRange: [-90, 90],
                rotationStep: 45,
                shape: 'circle',
                textStyle: {
                    fontWeight: wordset.fontWeight,
                    color: function() {
                        return 'rgb(' + [
                                Math.round(Math.random() * 160),
                                Math.round(Math.random() * 160),
                                Math.round(Math.random() * 160)
                            ].join(',') + ')';
                    }
                },
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: '#333'
                },
                data: datas
            }]
        };
        util.updateEchartsTooltipPosition(option);
        return option;
    };

}


export  {WordCloudRender}

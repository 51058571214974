import { appService, fileService } from '../../../server'
import './appshare.scss'
export default function ($scope, appId,appName, close, toastr) {
    $scope.appId = appId;
    $scope.appName = appName;
    $scope.close = close;
    function getRootPath_dc() {
        var pathName = window.location.pathname.substring(1);
        var webName = pathName == '' ? '' : pathName.substring(0, pathName.indexOf('/'));
        if (webName == "") {
         return window.location.protocol + '//' + window.location.host;
        }
        else {
         return window.location.protocol + '//' + window.location.host + '/' + webName;
        }
       }

    var appInfo = () => {
        appService.appGet({ id: $scope.appId }).then((app) => {
            $scope.appdata = app;
            $scope.appdata.levelvalue =app.level=='1'? true:false
            $scope.appdata.url = getRootPath_dc()+'/display.html?appId='+$scope.appId;
            $scope.getShortUrl(angular.copy($scope.appdata.url),false);
            if (app.status == '1') {
                $scope.isPublish = true;
            } else {
                $scope.isPublish = false;
            }
            $scope.appdata.dataDraft = angular.fromJson($scope.appdata.dataDraft);
            if(!$scope.appdata.dataDraft.cache) {
                $scope.appdata.dataDraft.cache = {
                    dataCache: {
                        type: 'app',
                        isCache: false,
                        expireTime: 0
                    },
                    dimensionCache: {
                        type: 'app',
                        isCache: false,
                        expireTime: 0
                    }
                };
            }

            $scope.$apply();
        })
    }
    $scope.getShortUrl = function(url,isopen){
        appService.getShortUrl({
            url: url,
        }).then((data)=>{
            if(isopen){
                $scope.sharedata.url=getRootPath_dc()+'/be/os/'+data;
            }else{
                $scope.appdata.url=getRootPath_dc()+'/be/os/'+data;                
            }
            $scope.$apply();
        })
    }
    appInfo();
    $scope.$watch("isPublish", function (newValue, oldValue) {
        if (newValue == true && oldValue == false) {
            appService.appPublish({
                id: $scope.appId,//分享的对象ID，如应用ID
            }).then((data) => {
                toastr.success(data)
                appInfo();
            }, err => {
                console.log(err)
            }
            )
        } else if (newValue == false && oldValue == true) {
            appService.appUnPublish({
                id: $scope.appId,
            }).then((data)=>{
                toastr.success(data)
            })
        }
    })
    $scope.appLevel = (level) => {
        if (level) {
            appService.appVisual({
                id: appId,
                level: 1
            }).then((data) => { toastr.success(data) })
        } else {
            appService.appVisual({
                id: appId,
                level: 0
            }).then((data) => { toastr.success(data) })
        }
        // appInfo();
    } 
    var shareInfo = () => {
        appService.getShareByAppId({
            objectId: $scope.appId,
            type: '0' // 0 表示分享的是应用
        }).then((data) => {
            if (data == null) {
                $scope.isOpen = false
            } else {
                $scope.isOpen = true
                $scope.sharedata = data;
                $scope.sharedata.url=getRootPath_dc()+'/display.html?appId='+$scope.appId+'&authCode='+data.authCode;
                $scope.getShortUrl(angular.copy($scope.sharedata.url),true);
            }
            $scope.$apply()
        }, err => {
            console.log(err)
        })
    }
    shareInfo()
    $scope.$watch("isOpen", function (newValue, oldValue) {
        if (newValue == true && oldValue == false) {
            appService.shareSave({
                objectId: $scope.appId,//分享的对象ID，如应用ID
                type: '0', //0-应用，1-看板
                objectName: $scope.appName,
            }).then((data) => {
                shareInfo()
            }
            )
        } else if (newValue == false && oldValue == true) {
            if ($scope.sharedata.id) {
                appService.delShareByAppId({
                    objectId: $scope.appId,
                    authCode: $scope.sharedata.authCode,
                    objectName: $scope.sharedata.objectName,
                })
            }
        }

    })
    $scope.copy = function () {
        var Url = document.getElementById("inner_share");
        Url.select(); // 选择对象  
        document.execCommand("Copy"); // 执行浏览器复制命令  
        toastr.success("复制成功")
    }
    $scope.copyOpen = function () {
        var Url = document.getElementById("open_share");
        Url.select(); // 选择对象  
        document.execCommand("Copy"); // 执行浏览器复制命令  
        toastr.success("复制成功")
    }
    $scope.changeExpareDate=(date)=>{
        appService.shareSave($scope.sharedata).then((data)=>{
            toastr.success("更新成功")
        })
    };

    //获取我的预览图列表
    $scope.pictureList = [];
    $scope.getMyPicList = function(){
        fileService.fileList({
            createType: 0,
            fileTagId: null, //null全部我的图片，1上传的图，对应上传功能
            type: 'image',
            pageNum: 1,
            pageSize: 999
        }).then((data) => {
            _.each(data.listdata, function(data, index){
                $scope.pictureList[index] = "/be/api/open/bv/show/" + data.url;
            });
            $scope.$apply();
        });
    };
    $scope.getMyPicList();
    //点击列表选择预览图
    $scope.selectPic = function(url){
        $scope.appdata.pic= url;
        $scope.appdata.dataDraft.img = $scope.appdata.pic;
    };

    //上传预览图
    $scope.uploadPicture = function () {
        var fileInput = document.getElementById('uploadFile');
        fileInput.click();
    };
    $scope.uploadAvatar = (files) => {
        const formData = new FormData();
        formData.append("createType", 0);
        formData.append("tagId", 1);
        formData.append('file', files[0]);
        // console.log(files[0])
        fileService.uploadImage(formData)
        .then((data) => {
            fileService.fileList({
                createType: 0,
                fileTagId: 1,
                type: 'image',
                pageNum: 1,
                pageSize: 1
            }).then((data) => {
              $scope.appdata.pic= "/be/api/open/bv/show/" + data.listdata[0].url;
              $scope.appdata.dataDraft.img = $scope.appdata.pic;
              $scope.getMyPicList();
            });
        }, err => {
            console.log(err);
        });
    };

    //保存设置
    $scope.saveApp = function(){
        var params = angular.copy($scope.appdata);
        delete params.url;
        delete params.levelvalue;
        params.dataDraft.name = params.name;
        params.dataDraft = JSON.stringify(params.dataDraft);
        appService.appSave(params).then(function(id){
            toastr.success('更新成功!');
        }, err=>{
            toastr.error('更新失败!');
        });
    };


}

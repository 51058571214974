/**
 * 发布/订阅模式
 * pubsub.js 必须在require.js 之前载入
 */
import PubSub from 'PubSub';

// 只初始化一次
if (!window.pubSubBore) {
  window.pubSubBore = new PubSub();
  //   window.pubSubEditor.publish = window.pubSubEditor.publishSync;
}
const PubSuber = window.pubSubBore;

export default  PubSuber ;

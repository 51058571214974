export default angular.module('borepc.pages.dblayout.layouttimeline', [])
  .directive('timelineParam', function () {
    return {
      template: require('./param.html'),
      scope: false
    }
  })
  .directive('timelineWidget', function () {
    return {
      template: require('./widget.html'),
      scope: false
    }
  })
  .directive('layoutTimeline', function () {
    return {
      template: require('./timeline.html'),
      scope: false
    }
  })
  .name
import { datasetService,userService,widgetService,boardService } from '../../../server'
import {util} from '../../../common/utils/util';
import './dataset.scss'
import DsEditorModelCrtl from './DsEditorModelCrtl'
import multiSourceModelCrtl from './multiSource/multiSourceModelCtrl'
import  PubSub from '../../../common/utils/PubSub'
import { dataContext } from '../../../charts'
export default function ($scope, $state, widgetModal, toastr, $uibModal, ModalUtils, uuid4, $filter) {
    var translate = $filter('translate');
    $scope.optFlag = 'none';
    $scope.curWidget = {};
    $scope.alerts = [];
    $scope.verify = {
        dsName: true
    };
    $scope.queryAceOpt = cbAcebaseOption;
    $scope.hierarchy = translate("CONFIG.DATASET.HIERARCHY");
    $scope.uuid4 = uuid4;
    $scope.params = [];
    $scope.search = {
        key: null,
        categoryName: null
    };
    $scope.paginationConf = {
        currentPage: 1, //第几页
        totalItems: 0, //共几条
        itemsPerPage: 20, //每页最多几条
        onChange: function () {
            getDatasetList();
        }
    };
    PubSub.subscribe('DATASETCHANGE', () => {
        getDatasetList()
    })


    $scope.onSearchDataset = () => {
        // console.log($scope.search)
        // console.log('search')
        getDatasetList()
    }
    //分析
    $scope.doAnalyse = (datasetId) => {
        if (datasetId) {
            widgetModal.new(datasetId)
        }

    }

    
    $scope.doEditDataSet=(type,dataSetId, dataSetCategoryName)=>{
        if(dataSetId){
            userService.judgePermission({option:"update",resType:"dataset",resId:dataSetId}).then(function(data){
                if(!data){
                    toastr.error("您没有编辑该数据集权限！")
                    return;
                }
                $scope.openDataset(type,dataSetId,dataSetCategoryName);
            },err=>{
                toastr.error(err.head.errorMsg || '获取权限失败！')
            })
        }else{
            $scope.openDataset(type,dataSetId,dataSetCategoryName);
        }
    }
    //查看图表数据
    $scope.viewData =function(dataset){
        $uibModal.open({
            animation: true,
            backdrop: false,
            template: require('./template/datasetData.html'),
            windowClass: 'dataset-data-model',
            resolve: {
                datasetPreview: function () {
                    return function (params,callback) {
                         dataContext.previewDatasetData(params,function(response){
                            if(response.status=="0"){
                                toastr.error(response.errorMsg || '获取引用图表列表失败！')
                                return;
                            }
                            callback(response)
                        },err=>{
                            toastr.error(err.head.errorMsg || '获取引用图表列表失败！')
                        })                                          
                    };
               },
               judgePermission: function () {
                return function (params,callback) {
                    userService.judgePermission(params).then(function(response){
                        callback(response)
                    },err=>{
                        toastr.error(err.head.errorMsg || '您没有数据集查看权限！')
                    })                                          
                };
               },
            },
            controller: ['$scope','toastr', '$uibModal', 'datasetPreview','judgePermission','widgetModal','$uibModalInstance',function( $scope,toastr, $uibModal,datasetPreview,judgePermission,widgetModal,$uibModalInstance ){                    
                $scope.paginationConf = {
                    currentPage: 1, //第几页
                    totalItems: 0, //共几条
                    itemsPerPage: 20, //每页最多几条
                    onChange: function () {
                        $scope.previewDatasetData();
                    }
                };
                $scope.previewDatasetData = function(){
                    var params = {
                        datasetId:dataset.id,
                        pageNum:$scope.paginationConf.currentPage, 
                        pageSize:$scope.paginationConf.itemsPerPage
                    }
                    $scope.tableId = "dataset-table"+util.randomID();
                    datasetPreview(params,function(response){
                        $scope.datasetData = response.data;
                        $scope.paginationConf.totalItems = response.total;
                        $scope.pageTotal = getPageTotal($scope.paginationConf.currentPage,$scope.paginationConf.itemsPerPage)
                        $scope.orginazeDataTable(response.data,$scope.tableId);
                        $scope.$apply();
                    })
                }
                $scope.orginazeDataTable =function(data,tableId){
                    // jQuery("#jqGrid")
                    
                    $("#datasetDataId").jqGrid('GridUnload');
                    var colNames=[]
                    var colModel=[]
                    $.each(data[0],function(key,value){
                        colNames.push(key);
                        colModel.push({
                            name:key,
                            align: "left",
                            editable: true,
                            editoptions: { size: 50 },
                            index:key
                        });
                    })
                    jQuery('#datasetDataId').jqGrid(
                        {
                            data: data,
                            datatype: "local",
                            mtype: "POST",
                            styleUI: 'Bootstrap',//设置jqgrid的全局样式为bootstrap样式 
                            colNames: colNames,
                            colModel: colModel,
                            rowNum : $scope.pageSize,
                            rowList: [20, 30, 40],
                            rownumbers: true,
                            sortname: 'id', //默认的排序列。可以是列名称或者是一个数字，这个参数会被提交到后台
                            height: "auto",
                            autowidth: true,
                            shrinkToFit:colNames.length>10?false:true,
                            autoScroll: true,
                            hidegrid:false,//隐藏收缩按钮
                        });
                }
                // $scope.previewDatasetData();

                $scope.close = function(){
                    $uibModalInstance.close();
                }

                var getPageTotal = function(pageSize,total){
                    if (total == null || total == "") {
                        return 0;
                    } else {
                        if (pageSize != 0 && total % pageSize == 0) {
                            return parseInt(total / pageSize);
                        }
                        if (pageSize != 0 && total % pageSize != 0) {
                            return parseInt(total / pageSize) + 1;
                        }
                    }
                }
            }]
        });
    }
    $scope.viewWidgetList = function(dataset){
        $uibModal.open({
                animation: true,
                backdrop: false,
                template: require('../chartwidget/template/dataCite.html'),
                size: 'lg',
                windowClass: 'data-cite-model',
                resolve: {
                    dataSetId: function () {
                        return dataset.id;
                    },
                    getWidgetsByDatasetId: function () {
                        return function (dataSetId,callback) {
                             widgetService.getWidgetsByDatasetId({datasetId:dataSetId}).then(function(response){
                                callback(response)
                            })                                          
                        };
                   },
                   getBoardByWidget: function () {
                    return function (widgetId,callback) {
                         boardService.getBoardByWidget({widgetId:widgetId}).then(function(response){
                            callback(response)
                        })                                          
                    };
                    },
                   judgePermission: function () {
                    return function (params,callback) {
                        userService.judgePermission(params).then(function(response){
                            callback(response)
                        },err=>{
                            toastr.error(err.head.errorMsg || '获取引用图表列表失败！')
                        })                                          
                    };
                   },
                },
                controller: ['$scope','toastr', 'dataSetId', '$uibModal', 'getWidgetsByDatasetId','judgePermission','widgetModal','$uibModalInstance','getBoardByWidget',function( $scope,toastr, dataSetId, $uibModal,getWidgetsByDatasetId,judgePermission,widgetModal,$uibModalInstance,getBoardByWidget ){                    
                    $scope.type="widget";
                    $scope.getWidgetList = function(){
                        getWidgetsByDatasetId(dataSetId,function(response){
                            $scope.dataList = response;
                            $scope.$apply();
                        })
                    }
                    $scope.getWidgetList();

                    $scope.close = function(){
                        $uibModalInstance.close();
                    }

                    $scope.goEdit=function(widgetId) {
                        judgePermission({option:"update",resType:"widget",resId:widgetId},function(data){
                             if(!data){
                                 toastr.error("您没有编辑该图表权限！")
                                 return;
                             }
                             var modalInstances = widgetModal.open(widgetId);
                             if(modalInstances.result){
                                  modalInstances.result.then(function(){
                                        $scope.getWidgetList();
                                  })
                             }
                         })
                    }

                    $scope.goList =function(widgetId){
                        $uibModal.open({
                            animation: true,
                            backdrop: false,
                            template: require('../chartwidget/template/dataCite.html'),
                            size: 'lg',
                            windowClass: 'data-cite-model',
                            resolve:{
                                getBoardByWidget: function () {
                                    return function (widgetId,callback) {
                                        getBoardByWidget(widgetId,function(response){
                                            callback(response)
                                        },err=>{
                                            toastr.error("获取图表引用的看板失败")
                                        })                                          
                                    };
                               },
                               judgePermission: function () {
                                return function (params,callback) {
                                    judgePermission(params,function(response){
                                        callback(response)
                                    },err=>{
                                        toastr.error("获取权限失败!")
                                    })                                          
                                };
                            },
                            },
                            controller: ['$scope','toastr',  '$uibModal', 'getBoardByWidget','widgetModal','$uibModalInstance','getBoardByWidget','judgePermission',function( $scope,toastr, dataSetId, $uibModal,getWidgetsByDatasetId,$uibModalInstance,getBoardByWidget,judgePermission ){         
                                $scope.getBoardList = function(){
                                    getBoardByWidget(widgetId,function(response){
                                        $scope.dataList = response;
                                        $scope.$apply();
                                    })
                                }
                                $scope.getBoardList();
                                $scope.close = function(){
                                    $uibModalInstance.close();
                                }
                                $scope.goEdit = function(boardId){
                                    judgePermission({option:"query",resType:"board",resId:boardId},function(data){
                                        if(!data){
                                            toastr.error("您没有查看该看板权限！");
                                            return;
                                        }
                                        window.open("/#/display?id="+boardId);
                                    })
                                    
                                }
                                
                            }]           

                        })
                    }
                }]
        });
        
    }

    $scope.openDataset = function(type,dataSetId,dataSetCategoryName){
        if(type && type=='1'){
            var uibModalInstance = $uibModal.open({
                animation: true,
                backdrop: false,
                template: require('./multiSource/multiSourceModel.html'),
                controller: ['$scope', '$filter', 'toastr', 'dataSetId','categoryName', '$uibModal', 'uuid4','ModalUtils', multiSourceModelCrtl],
                size: 'lg',
                windowClass: 'widgetmodal',
                resolve: {
                    dataSetId: function () {
                        return dataSetId;
                    },
                    categoryName:function () {
                        return dataSetCategoryName;
                    }
                }
            });
            return uibModalInstance.result;
        }else {
            var uibModalInstance = $uibModal.open({
                animation: true,
                backdrop: false,
                template: require('./DsEditorModel.html'),
                controller: ['$scope', '$filter', 'toastr', 'dataSetId','categoryName', '$uibModal', 'uuid4','ModalUtils', DsEditorModelCrtl],
                size: 'lg',
                windowClass: 'widgetmodal',
                resolve: {
                    dataSetId: function () {
                        return dataSetId;
                    },
                    categoryName:function () {
                        // return categoryName?categoryName[categoryName.length-1]:null;
                        return dataSetCategoryName;
                    }
                }
            });
            return uibModalInstance.result;
        }
       
    }


    var getAllDatasetData = function (dataId) {
        if (!dataId) return;
        datasetService.getDatasetDetail({
            "id": dataId
        }).then(function (response) {
            if (response.dataset) {
                $scope.dataset = response.dataset;
                $scope.dataset.data = angular.fromJson(response.dataset.data);
                $scope.datasource = response.datasource;
                if ($scope.datasource) {
                    $scope.datasource.config = angular.fromJson(response.datasource.config)
                } else {
                    toastr.error("获取数据源信息失败,请检查您的数据源连接!");
                }
                $scope.dtp = response.dtp || {
                    id: "-1",
                    label: "无",
                    isDtpManager: false
                };
                getDatasourceList(function () {
                    if ($scope.datasource) {
                        $scope.editDs($scope.dataset)
                        return;
                    };
                    doEditDs($scope.dataset);
                })
            } else {
                toastr.error("获取数据集信息失败!");
            }
        }).catch(function () {
            toastr.error("获取数据集信息失败!");
        });
    }

    var getDatasetList = function (callback) {
        datasetService.getDatasetPageList({
            "notEdit": "0",
            searchParams: $scope.search.key,
            categoryName: $scope.search.categoryName,
            pageNum: $scope.paginationConf.currentPage,
            pageSize: $scope.paginationConf.itemsPerPage
        }).then(function (response) {
            // console.log(response)
            $scope.datasetList = response.listdata;
            $scope.paginationConf.totalItems = response.totolCount
            if (callback) {
                callback();
            } else {
                $scope.$apply()
            }
        });
    };

    var getCategoryList = function () {
        // $http.get("api/bcp/dashboard/getDatasetCategoryList")
        datasetService.getDatasetCategoryList().then(function (response) {
            $scope.categoryList = response;
            $scope.curCategoryList = angular.copy($scope.categoryList);
            $("#DatasetName").autocomplete({
                source: $scope.categoryList
            });
        });
    };

    var loadOriginData = function () {
        getCategoryList();
        getDatasetList(function () {
            // getDtpList(function () {
            //     getAllDatasetData();
            // })
        })
    }
    loadOriginData();
    $scope.newDs = function () {
        $scope.optFlag = 'new';
        $scope.curWidget = {};
        $scope.selects = [];
        cleanPreview();
    };

    // $scope.editDs = function (ds) {
    //     datasetService.checkDatasource({
    //         id: $scope.datasource.id,
    //         isDtpManager: $scope.dtp.isDtpManager,
    //         dtpId: $scope.dtp.id
    //     }).then(function (response) {
    //         if (response.status == '1') {
    //             doEditDs(ds);
    //             $scope.doConfigParams();
    //         } else {
    //             ModalUtils.alert(translate("ADMIN.CONTACT_ADMIN") + "：Datasource/" + response.msg, "modal-danger", "lg");
    //         }
    //     }).catch(function (response) {
    //         ModalUtils.alert(translate("ADMIN.CONTACT_ADMIN") + "：Datasource/" + response.msg, "modal-danger", "lg");
    //     });
    // };

    $scope.editDs = function (ds) {
        userService.judgePermission({option:"update",resType:"dataset",resId:ds.id}).then(function(data){
            if(!data){
                toastr.error("您没有编辑该数据集权限！")
                return;
            }
            doEditDs(ds);
            $scope.doConfigParams();
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
    };


    $scope.deleteDs = function (ds) {
        ModalUtils.confirm(translate("COMMON.CONFIRM_DELETE"), "modal-warning", "lg", function () {
            userService.judgePermission({option:"delete",resType:"dataset",resId:ds.id}).then(function(data){
                if(!data){
                    toastr.error("您没有删除该数据集权限！")
                    return;
                }
                datasetService.getAllWidgetList().then(function (response) {
                    if (!response) {
                        return false;
                    }
                    var resDs = [];
        
                    for (var i = 0; i < response.length; i++) {
                        if (response[i].data.datasetId == ds.id) {
                            resDs.push(response[i].name);
                        }
                    }
        
                    if (resDs.length > 0) {
                        var warnStr = translate("CONFIG.WIDGET.WIDGET") + ":[" + resDs.toString() + "]";
                        ModalUtils.alert(translate("COMMON.NOT_ALLOWED_TO_DELETE_BECAUSE_BE_DEPENDENT") + warnStr, "modal-warning", "lg");
                        return false;
                    }
                    datasetService.deleteDataset({
                        id: ds.id
                    }).then(function (serviceStatus) {
                        getDatasetList();
                        toastr.success("删除成功！")
                        $scope.optFlag = 'none';
                    },err=>{
                        toastr.error(err.head.errorMsg || "删除失败!");
                    });
                    
                });
            },err=>{
                toastr.error(err.head.errorMsg || '获取权限失败！')
            })
        });
       
       
    };

    $scope.copyDs = function (ds) {
        userService.judgePermission({option:"update",resType:"dataset",resId:ds.id}).then(function(data){
            if(!data){
                toastr.error("您没有编辑该数据集权限！")
                return;
            }
            datasetService.getDatasetDetail({id:ds.id}).then(function(response){
                var copyDataset = response.dataset;
                copyDataset.data = angular.fromJson(response.dataset.data);
                copyDataset.name = copyDataset.name + "_copy";
                datasetService.saveNewDataset({
                    json: angular.toJson(copyDataset)
                }).then(function (serviceStatus) {
                    if (serviceStatus.status == '1') {
                        $scope.optFlag = 'none';
                        getDatasetList();
                        toastr.success("复制成功");
                    } else {
                        toastr.error("复制失败");
                    }
                });
            })
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
        
    };




    // var cleanPreview = function () {
    //     $('#dataset_preview').html("");
    // };

    /**  js tree related start **/
    // $scope.treeConfig = jsTreeConfig1;



    // $scope.copyNode = function () {
    //     if (!checkTreeNode("copy")) return;
    //     $scope.copyDs(getSelectedDataSet());
    // };


    $scope.searchNode = function () {
        var para = {
            dsName: '',
            dsrName: ''
        };
        //map datasetList to list (add datasourceName)
        // var list = $scope.datasetList.map(function (ds) {
        //     // var dsr = _.find($scope.datasourceList, function (obj) {
        //     //     return obj.id == ds.data.datasource
        //     // });
        //     return {
        //         "id": ds.id,
        //         "name": ds.name,
        //         "categoryName": ds.categoryName,
        //         // "datasourceName": dsr ? dsr.name : ''
        //     };
        // });
        //split search keywords
        // if ($scope.keywords) {
        //     if ($scope.keywords.indexOf(' ') == -1 && $scope.keywords.indexOf(':') == -1) {
        //         para.dsName = $scope.keywords;
        //     } else {
        //         var keys = $scope.keywords.split(' ');
        //         for (var i = 0; i < keys.length; i++) {
        //             var w = keys[i].trim();
        //             if (w.split(':')[0] == 'ds') {
        //                 para["dsName"] = w.split(':')[1];
        //             }
        //             // if (w.split(':')[0] == 'dsr') {
        //             //     para["dsrName"] = w.split(':')[1];
        //             // }
        //         }
        //     }
        // }
    };


    $scope.doConfigParams = function () {
        if ($scope.dtp && $scope.dtp.isDtpManager) {
            $scope.datasource.type = 'ODTP'
        }
        datasetService.getConfigParams({
            type: $scope.datasource.type,
            datasourceId: $scope.datasource.id,
            dtpId: $scope.dtp.id,
            page: 'dataset.html',
            isDtpManager: $scope.dtp.isDtpManager
        }).then(function (response) {
            $scope.params = response.data;
        });
    };


    //点击子目录
    $scope.curCate = [];
    $scope.showDatasetList = function (dsCate) {
        $scope.curDatasetList = _.filter($scope.datasetList, function (curdataset) {
            return curdataset.categoryName === dsCate
        })
        $scope.curCategoryList = [];
        $scope.curCate.push(dsCate);
        $scope.search.categoryName = dsCate;
        getDatasetList();
    }
    //点击根目录
    $scope.showCategoryList = function () {
        $scope.curCategoryList = angular.copy($scope.categoryList);
        $scope.curDatasetList = angular.copy($scope.datasetList);
        $scope.curCate = [];
        $scope.search.categoryName = null;
        getDatasetList();
    }

    $scope.editDataset = function (ds) {
        // $stateParams.id = ds.id;
        getAllDatasetData(ds.id);
    };

}
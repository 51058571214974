import {prewarningService} from '../../../../server'
import '../_prewarning.scss'
export default  function pwLogDetailCtrl(  $scope, $state,  ModalUtils,  $stateParams,toastr) {
    prewarningService.getPwLogDetail({
        id: $stateParams.id
    }).then(function (response) {
        if (response.status == '1') {
            $scope.pwLog = response.data;
            $scope.pwLog.target =angular.fromJson(response.data.target);
            $scope.pwLog.rule =angular.fromJson(response.data.rule);
            $scope.$apply();
        } else {
            toastr.error("获取预警日志失败");
        }
    });

    $scope.goBackLogList = function(){
        $state.go('pwLog');
    }
};
import { userAdminService,timerPushService,userService,dtpManagerService} from '../../../../server'
import windowTpl from '../../../../common/theme/components/modal/window.html'
import userSelectedListTpl from '../../user/userSelectedList.html'
import '../_timerPush.scss'


export default  function timerEditCtrl($scope, $uibModal, toastr,ok, userList, $uibModalInstance, ModalUtils, timer) {
    $scope.types = ['=', '≠', '>', '<', '≥', '≤', '(a,b]', '[a,b)', '(a,b)', '[a,b]'];
    $scope.times = ['分','时', '日', '周','月'];
    $scope.userList = angular.copy(userList);
    $scope.timer = angular.copy(timer);
    if(!$scope.userList){
         //获取用户信息
         userAdminService.postUsers({pageNo:1,pageSize:9999}).then(function (response) {
            $scope.userList = response.listdata;
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg)
        });
    }
   //获取Dtp列表
   $scope.getDtpList = function () {
        var params = {
            "pageNumber": 1,
            "pageSize": 1000
        }
        dtpManagerService.getDtpList(params).then(function (response) {
            if (response.status != '1') {
                ModalUtils.alert(response.msg || '获取dtp列表失败', "modal-warning", "lg");
            } else {
                $scope.dtpManagerList = response.data.listdata;
                if($scope.timer.dtpId){
                    _.filter($scope.dtpManagerList,function(dtp){
                        if(dtp.id== $scope.timer.dtpId){
                            $scope.selectedDtp = dtp;
                            return;
                        }
                    });
                }
                $scope.$apply();
            }
        },err=>{
            toastr.error(err.head.errorMsg)
        })
    }
    $scope.getDtpList();

    $scope.clickSelectedDtp =function(){
        ModalUtils.alert("活跃状态下的推送器无法变更dtp", "modal-warning", "lg");
        return;
    }

    // $scope.loadCron =function(){
    //     $("#boreCronId").cronGen({
    //         direction : 'right'
    //     });
    // }

    var getBindWechatIds = function (callback) {
        userService.getBindUserIdByCompany({type:'1'}).then(function (response) {
            if(response.status=='1'){
                $scope.bindWechatUsers = response.data;
            }
            $scope.isBindWechat =function(userId){
                if($scope.bindWechatUsers && $scope.bindWechatUsers.indexOf(userId)!=-1)return true;
                return false;
            }
            if (callback) {
                callback();
            }
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg)
        });
    }
    getBindWechatIds();

    var getBindEnterpriseWechatIds = function (callback) {
      userService.getBindUserIdByCompany({type:'5'}).then(function (response) {
          if(response.status=='1'){
              $scope.bindEnWechatUsers = response.data;
          }
          $scope.isBindEnWechat =function(userId){
              if($scope.bindEnWechatUsers && $scope.bindEnWechatUsers.indexOf(userId)!=-1)return true;
              return false;
          }
          if (callback) {
              callback();
          }
          $scope.$apply();
      },err=>{
          toastr.error(err.head.errorMsg)
      });
  }
  getBindEnterpriseWechatIds();

    //关闭弹窗
    $scope.close = function () {
        $uibModalInstance.close();
    };
    //确认按钮
    $scope.ok = function (isValidate) {
        if (!$scope.timer.dtpId || !$scope.timer.receiptIds || $scope.timer.receiptIds.length == 0) {
            ModalUtils.alert("请输入必填项!", "modal-warning", "lg");
            return;
        }
        $scope.saveBoreTimer();
    };

    $scope.saveBoreTimer =function () {
        timerPushService.saveBoreTimer({btJson:angular.toJson($scope.timer)}).then(function (response) {
            $scope.isLoading =false;
            if(response.status == '1'){
                toastr.success("定时推送成功", "modal-success", "sm");
                $uibModalInstance.close();
                ok();
            }else{
                toastr.error(response.msg ||"定时推送失败", "modal-warning", "sm");
            }
        })
    }


    //删除用户
    $scope.delUser = function (user) {
        var receiptIds = angular.copy($scope.timer.receiptIds);
        $scope.timer.receiptIds = receiptIds.filter(function(id){
            return id!=user.id
        });
    }

    //前往新增用户页面
    $scope.gotoUserPage = function () {
        if (!$scope.userList) {
            ModalUtils.alert("获取用户列表失败!", "modal-warning", "lg");
            return;
        }
        $uibModal.open({
            template: userSelectedListTpl,
            windowTemplate:windowTpl,
            size: 'lg',
            windowClass: 'pw-user-modal',
            resolve: {
                userList: function () {
                    return $scope.userList;
                },
                selectIds: function () {
                    return $scope.timer.receiptIds;
                },
                bindWechatUsers:function(){
                    return $scope.bindWechatUsers;
                },
                bindEnWechatUsers:function(){
                  return $scope.bindEnWechatUsers;
                },
                ok: function () {
                    return function (param) {
                        $scope.timer.receiptIds = param;
                    }
                }
            },
            controller: ['$scope', '$uibModalInstance',   'selectIds', 'ok','bindWechatUsers','bindEnWechatUsers',function ($scope, $uibModalInstance,   selectIds, ok,bindWechatUsers,bindEnWechatUsers) {
                $scope.selectIds = angular.copy(selectIds) || [];
                // $scope.userList = userList;
                $scope.bindWechatUsers = angular.copy(bindWechatUsers)
                $scope.bindEnWechatUsers = angular.copy(bindEnWechatUsers)

                $scope.paginationConf = {
                    currentPage: 1,
                    totalItems: 0,
                    itemsPerPage: 15,
                    onChange: function () {
                        $scope.getUserList(); //重新加载
                    }
                };

                $scope.getUserList =function(){
                    var params = {
                        "pageNo": $scope.paginationConf.currentPage,
                        "pageSize": $scope.paginationConf.itemsPerPage,
                        "name":$scope.search
                    }
                    userAdminService.postUsers(params).then(function (response) {
                        $scope.userList = response.listdata;
                        $scope.paginationConf.totalItems = response.totolCount;
                        $scope.ifCheckedAll();
                        $scope.$apply();
                    },err=>{
                        toastr.error(err.head.errorMsg)
                    });
                }
                //获取已经绑定了微信的用户
                $scope.isBindWechat =function(userId){
                    if($scope.bindWechatUsers && $scope.bindWechatUsers.indexOf(userId)!=-1)return true;
                    return false;
                }

                //获取已经绑定了微信的用户
                $scope.isBindEnWechat =function(userId){
                  if($scope.bindEnWechatUsers && $scope.bindEnWechatUsers.indexOf(userId)!=-1)return true;
                  return false;
                }

                //更新选择的用户
                $scope.updateSelection = function ($event, id) {
                    //只有被选中的时候才添加到ids集合中
                    if ($event.target.checked) {
                        $scope.selectIds.push(id); //push向集合中添加元素
                    } else {
                        //如果没被选中则删除集合中的元素
                        var index = $scope.selectIds.indexOf(id); //查找值的位置
                        $scope.selectIds.splice(index, 1); //参数1:移除的位置 参数2:移除的个数
                    }
                }


                //判断是否选择好了
                $scope.ifChecked = function (id) {
                    //如果Id出现在数组中，则需要返回true
                    var index = $scope.selectIds.indexOf(id);
                    if (index == -1) {
                        return false;
                    } else { //如果id没有出现在selectIds数组中需要返回false
                        return true;
                    }
                };

                //判断是否全选了
                $scope.ifCheckedAll = function () {
                    //$scope.userList中的对象的id 是否都在 $scope.selectIds中
                    //如果全中，则返回true
                    //如果有一个还在，就返回false
                    if(!$scope.userList) return false;
                    for (var i = 0; i < $scope.userList.length; i++) {
                        if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                            return false;
                        }
                    }
                    return true;
                };

                //全选与取消全选
                $scope.selectAll = function ($event) {
                    if ($event.target.checked) {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            //当前页面的数据的id放到数组中
                            if ($scope.selectIds.indexOf($scope.userList[i].id) == -1) {
                                $scope.selectIds.push($scope.userList[i].id);
                            }
                        }
                    } else {
                        for (var i = 0; i < $scope.userList.length; i++) {
                            var index = $scope.selectIds.indexOf($scope.userList[i].id);
                            $scope.selectIds.splice(index, 1);
                        }
                    }
                };

                //关闭
                $scope.close = function () {
                    $uibModalInstance.close();
                };
                //确定
                $scope.ok = function () {
                    $uibModalInstance.close();
                    ok($scope.selectIds);
                };
            }]
        });
    }
}

import userRoleAdmin from './userRoleAdmin.html'
import userRoleAdminCtrl from './userRoleAdminCtrl'

export default angular.module('borepc.pages.userRoleAdminCtrl', [])
    .controller('userRoleAdminCtrl', ['$scope','toastr', '$uibModal', 'ModalUtils', '$filter',userRoleAdminCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('userRoleAdmin', {
            url: '/userRoleAdmin',
            title: '角色管理',
            controller: 'userRoleAdminCtrl',
            template: userRoleAdmin
        })
}
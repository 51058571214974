import BasicService from './BasicService';
import { ACCESSCONTROLAPI } from './ApiServer';
/**
 */
class AccessControlService extends BasicService {
    saveAccessControl (params) {
        return this.post(ACCESSCONTROLAPI.SAVE, params)
    }
    getAccessControl (params) {
        return this.post(ACCESSCONTROLAPI.GET, params)
    }
}

export const accessControlService = new AccessControlService();
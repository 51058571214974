
import   contentTopTpl from  './contentTop.html';
import  './_contentTop.scss'
  /** @ngInject */
  function contentTop( $state) {
    return {
      restrict: 'E',
      template:contentTopTpl,
      link: function($scope) {
        $scope.$watch(function () {
          $scope.activePageTitle = $state.current.title;
        });
      }
    };  
  }
      
  export  default  angular.module('BlurAdmin.theme.components.contentTop',[])
  .directive('contentTop', ['$state',contentTop]).name;
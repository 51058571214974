
import ParamSetterCrtl from './ParamSetterCrtl'
import paramSelectorCrtl from './ParamSelectorCrtl'
import './_param.scss'
function ParamFilterModal($uibModal) {
  this.openSetter = function (params, func_ok) {
    $uibModal.open({
      animation: true,
      template: require('./paramSetter.html'),
      controller: ['$scope','ModalUtils', '$uibModalInstance','$filter','params','ok',ParamSetterCrtl],
      size: 'lg',
      // windowClass:'widgetmodal',
      resolve: {
        ok: function () {
          return func_ok;
        },
        params: function () {
          return params;
        }
      }
    });
    // return uibModalInstance.result;
  };
  //弹出筛选框
  this.openSelector = function (param, func_ok) {
    var modalInstance = $uibModal.open({
      template: require('./paramSelector.html'),//'com/ourbore/view/dashboard/modal/param.html',
      // windowTemplate: require('./window.html'),//'com/ourbore/view/util/modal/window.html',
      controller: ['$scope', '$uibModalInstance', 'toastr', 'param', 'ok',paramSelectorCrtl],
      backdrop: false,
      size: 'lg',
      windowClass: 'open-selector-modal',
      resolve: {
        param: function () {
          if (param) {
            return angular.copy(param);
          } else {
            return { type: '=' }
          }
        },
        // filter: function () {
        //   return false;
        // },
        // getSelects: function () {
        //   return function (byFilter, column, callback) {
        //     callback($scope.param.selects);
        //   };
        // },
        ok: function () {
          return func_ok;
        }
      },
    });
    //弹框关闭时的方法
    if (modalInstance.result) {
      modalInstance.result.then(function (callback) {
        //隐藏弹框的业务逻辑
        window.removeEventListener('popstate', callback)
      }, function (callback) {
      });
    }

    //弹框打开之后执行的函数
    modalInstance.opened.then(function () {
      window.history.pushState(null, '');
    });
  }
}



export default angular.module('borepc.pages.dblayout.layoutparam', [])
  .directive('lpDatetime', function () {
    return {   
      template: require('./param/datetime.html'),
      scope: false
    }
  })
  .directive('lpCommon', function () {
    return {   
      template: require('./param/common.html'),
      scope: false
    }
  })
  .directive('lpSelector', function () {
    return {
      template: require('./param/selector.html'),
      scope: false
    }
  })
  .directive('lpPull', function () {
    return {
      template: require('./param/pull.html'),
      scope: false
    }
  })
  .directive('lpSlider', function () {
    return {
      template: require('./param/slider.html'),
      scope: false
    }
  })
  .service(
    'ParamFilterModal', ['$uibModal',ParamFilterModal]
  ).name







import echartTpl from './echartTpl.html'
import { chartContext, boardContext } from '../../../../charts'
import { dataService } from '../../../../server'
// import boardContext from '../../../../charts/BoardContext'
import ChartWidgetCrtl from './ChartWidgetCrtl'
import './_widgets.scss'
/** @ngInject */
function widgetView($compile) {
  var renderchart = function (element, widget, cache) {
    // var widget = $scope.$eval(attrs.widget);
    var ndWrapper = $(element).find('#chart');
    chartContext.renderWidget(ndWrapper, widget, cache);
  };
  return {
    restrict: 'EA',
    scope: true,
    link: function link($scope, element, attrs) {
      //初始化图表
     
      // if ($scope.row && $scope.row.height) {
      //   $scope.myheight = $scope.row.height
      // } else {
      //   // $scope.myheight = 300;
      // }
      var link = $compile(echartTpl);
      element.append(link($scope));
      var widget = $scope.$eval(attrs.widget);
      if (_.isUndefined(widget)) {
        return;
      }

      if (attrs.height) {
        $scope.myheight = attrs.height;
      } else {
        //添加图表的默认高度
        var chartDefaultHeight = chartContext.getChartHeight(widget.widget.data);
        if(chartDefaultHeight) {
          $scope.myheight = chartDefaultHeight;
        } else {
          $scope.myheight = 300;
        }
      }

      //console.log(attrs.isBoard)
      //来自于chart-widget----目前只被看板调用--2020.09.22
      var cache = null;
      $scope.initBoardCache = function(){
        var boardCache = boardContext.getBoardCache();
        var boardId = boardContext.getBoardId();
        cache = {
              type : boardCache.dataCache.type + boardId,
              isCache : boardCache.dataCache.isCache,
              expireTime : boardCache.dataCache.expireTime
        };
      }
      if(attrs.isBoard == "board") {
        //判断调用的位置为board，加载缓存配置
        $scope.initBoardCache();
      }
      // 引入图表Id
      $scope.chartId = parseInt(Math.random() * 1000000);
      $scope.initRenderId = function(widgetData){
        if(_.isUndefined(widgetData.renderId) || widgetData.renderId.indexOf(widgetData.widgetId) < 0){
          widgetData.renderId = widgetData.widgetId + '_' + $scope.chartId;
        }
        return widgetData;
      };
      widget = $scope.initRenderId(widget);
      renderchart(element, widget, cache);

      //编辑看板部分改变设置
      $scope.$on('boardCacheChange', function(){
        $scope.initBoardCache();
        widget = $scope.initRenderId(widget);
        renderchart(element, widget, cache);
      });
      //监控option数据变化
      $scope.$watch(attrs['widget'], function (newValue, oldValue) {
        if (newValue.widgetId != oldValue.widgetId) {
          oldValue = $scope.initRenderId(oldValue);
          boardContext.unRegWidget(oldValue.renderId);
          newValue = $scope.initRenderId(newValue);
          renderchart(element, newValue, cache);
        }
      }, true);
      $scope.$on('$destroy', function(){
        widget = $scope.initRenderId(widget);
        boardContext.unRegWidget(widget.renderId);
      });
    }
    // controller: ChartWidgetCrtl  
  }
}

export default angular.module('BlurAdmin.theme.components.chartWidget', [])
  .directive('widgetView', ['$compile', widgetView])
  .directive('chartWidget', function () {
    return {
      restrict: 'EA',
      template: require('./chartWidgetTpl.html'),
      controller: ['$scope', 'widgetModal', 'ModalUtils', 'toastr', ChartWidgetCrtl],
      scope: true,
      controllerAs: 'vm'
    }
  })
  .directive('chartRelationIcon', function(){
    return {
      restrict: 'EA',
      template: `
        <span>
          <i class="fa fa-link" ng-style="{'display': hasWidget && hasBoard ? 'inline-block' : 'none', 'color':'#136efa'}"></i>
          <i class="fa fa-line-chart" ng-style="{'display': hasWidget && !hasBoard ? 'inline-block' : 'none', 'color':'#136efa'}"></i>
          <i class="fa fa-dashboard" ng-style="{'display': !hasWidget && hasBoard ? 'inline-block' : 'none', 'color':'#136efa'}"></i>
        </span>
      `,
      replace: true,
      scope: {
        widget: '='
      },
      link: function link(scope, element, attrs) {
        function init(widget){
          scope.hasWidget = false;  //没有图表类型的关联
          scope.hasBoard = false;   //没有看板类型的关联
          if(widget && widget.relations && widget.relations.relations.length > 0) {
            let r = widget.relations.relations;
            for(var i = 0; i < r.length; i++) {
              if(r[i].type === 'widget') {
                r[i].targetField && r[i].targetField.length > 0 ? scope.hasWidget = true : null;
              } else {
                r[i].targetField && r[i].targetField.length > 0 ? scope.hasBoard = true : null;
              }
              if(scope.hasWidget && scope.hasBoard) {
                break;
              }
            }
          }
        }
        init(scope.widget);
        scope.$watch('widget.relations.relations', () =>{
          init(scope.widget);
        }, true);
      }
    }
  })
  .filter('toFilterLable', ['$sce', function ($sce) {

    //处理单个过滤器--增加title
    var updateFilterTitle = function(filter, spanClass){
      //没有值时title就是空字符串
      if(!filter.values || filter.values.length <= 0) {
        filter.title = "";
        return;
      }

      //判断是否需要隐藏当前过滤词条
      var flag = false;     //默认不隐藏

      var promisecontain = [];
      for(var i = 0; i < filter.values.length; i++) {
        //判断存在不是时间的表达式---存在即终止循环
        if(filter.values[i].indexOf('{') === 0 && filter.values[i].indexOf('{now') !== 0) {
          flag = true;
          break;
        }

        var newPromise = new Promise((resolve, reject) => {
          if(filter.values[i].indexOf('{now') === 0) {
            dataService.getTimeParserExpression(filter.values[i]).then(res => {
              resolve(res);
            });
          } else {
            resolve(filter.values[i]);
          }
        });
        promisecontain.push(newPromise);

      }

      //存在表达式且不为时间
      if(!flag) {
        Promise.all(promisecontain).then(res=>{
          filter.title = "<span class='" + spanClass + "'>" + filter.col + filter.type + res.toString() + "</span>";
        });
      } else {
        filter.title = "";
      }

    };

    return function (widget) {
      // let filterStr = '';
      // //判断是否需要隐藏当前过滤条----存在{now(...)}隐藏
      // var isHidden = function(values){
      //   var flag = false;
      //   _.each(values, item=>{
      //     if(item.indexOf('{') === 0 && item.indexOf('now') !== 1 ) {
      //       flag = true;
      //     }
      //   });
      //   return flag;
      // };
      let widgetFilters = [];
      if (widget.widget.data.config.filters.length > 0) {
        _.each(widget.widget.data.config.filters, (item) => {
          if(item.filters && item.filters.length > 0) {
            _.each(item.filters, (item) => {
              // if (item.values && item.values.length > 0 && !isHidden(item.values))
              // filterStr += "<span class='filter-deflable'>" + item.col + item.type + item.values.toString() + "</span>"
              widgetFilters.push(item);
              updateFilterTitle(item, "filter-deflable");
            });
          };
          // if(item.values && item.values.length > 0 && !isHidden(item.values))
          //   filterStr += "<span class='filter-deflable'>" + item.col + item.type + item.values.toString() + "</span>"
          widgetFilters.push(item);
          updateFilterTitle(item, "filter-deflable");
        });
      }
      if (widget.widget.data.config.groups.length > 0) {
        _.each(widget.widget.data.config.groups, (item) => {
          // if (item.values && item.values.length > 0 && !isHidden(item.values))
          //   filterStr += "<span class='filter-deflable'>" + item.col + item.type + item.values.toString() + "</span>"
          widgetFilters.push(item);
          updateFilterTitle(item, "filter-deflable");
        })
      }
      if (widget.widget.data.config.keys.length > 0) {
        _.each(widget.widget.data.config.keys, (item) => {
          // if (item.values && item.values.length > 0 && !isHidden(item.values))
          //   filterStr += "<span class='filter-deflable'>" + item.col + item.type + item.values.toString() + "</span>"
          widgetFilters.push(item);
          updateFilterTitle(item, "filter-deflable");
        })
      }
      const havSlicerRender = widget.havSlicerRender === false ? false : true;
      let boardFilters = boardContext.getWidgetFilterTitle(widget.widget, havSlicerRender);
      // if (boardFilters) {
      //   _.each(boardFilters, (item) => {
      //     if (item.values && item.values.length > 0 && !isHidden(item.values))
      //       filterStr += "<span class='filter-lable'>" + item.col + item.type + item.values.toString() + "</span>"
      //   })
      // }

        return $sce.trustAsHtml(_.map(widgetFilters, function (e) {
          return e.title;
        }).join('') + _.map(boardFilters, function (e) {
          return e.title;
        }).join(''));

      // return $sce.trustAsHtml(filterStr)
    }
  }]).filter("parseHTML", ['$sce', function ($sce) {
    return function (text) {
      return $sce.trustAsHtml(text);
    }
  }])
  .name;

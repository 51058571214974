import BasicService from './BasicService';
import { BEHAVIORLOGAPI } from './ApiServer';
/**
 */
class BehaviorLogService extends BasicService {
  constructor() {
    super();
  }
  insertLog(params){
    return this.post( BEHAVIORLOGAPI.INSERTLOG,params)
  }
  bvInsertLog(params){
    return this.post( BEHAVIORLOGAPI.BVINSERTLOG,params)
  }
  getBehaviorLogList(params){
    return this.post( BEHAVIORLOGAPI.GETBEHAVIORLOGLIST,params)
  }
  delBehaviorLog(params){
    return this.post( BEHAVIORLOGAPI.DELBEHAVIORLOG,params)
  }
  
}

export const behaviorLogService = new BehaviorLogService();

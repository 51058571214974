import {reportService} from '../../../server'
import { util } from  '../../../common/utils/util'
export default  function  reportDetailCtrl($scope, $location,$stateParams,$sce) {
	$scope.isLoading =true;
	var token =window.localStorage.getItem("x-token").replace(/\"/g, "");
	//获取report详情
	var params = {
		reportId:$stateParams.reportId,
		reportName:$stateParams.reportName,
		dtpId:$stateParams.dtpId,
	}
	var showReport = function () {
		$scope.isLoading =true;
		// var url = "api/bcp/ureport/showReport?"+ $location.url().split("?")[1];
		reportService.showReport(params).then(function (response) {
			var htmlStr=	util.replaceAll(response.data,'src="/ureport/','src="/be/ureport/')
			$scope.reportData = $sce.trustAsHtml(htmlStr);
			$scope.isLoading =false;
			$scope.$apply()
		});
	}
	
	showReport();
	
	$scope.printReport = function () {
		var url = "/be/print/bcp/ureport/download/printReport?"+ $location.url().split("?")[1]+"&token="+token;
		window.open(url,'_blank');
	}

	$scope.exportPdf = function () {
		var url = "/be/print/bcp/ureport/download/exportPdf?"+ $location.url().split("?")[1]+"&token="+token;
		window.open(url,'_blank');
	}
	$scope.exportExcel = function () {
		var url = "/be/print/bcp/ureport/download/exportExcel?"+ $location.url().split("?")[1]+"&token="+token;
		window.open(url,'_blank');
	}
}
import BasicService from './BasicService';
import { BOREDVCHARTMARKET } from './ApiServer';
/**
 * @desc 系统配置
 */
class BoredvChartmarketService extends BasicService {
  constructor() {
    super();
  }
  /**
   * @desc 获取列表
   * @param {object} params {pageNum: '当前页', pageSize: '一页大小', orderbyWay: '排序词', name: '关键词搜索', chartType: '图表类型'}----预留tag-componentType
   */
  getList(params){
    return this.post(BOREDVCHARTMARKET.GETLIST, params);
  }
  /**
   * @desc 依据id获取单个图表
   * @param {Object} params {id: id}
   */
  getChartById(params){
    return this.get(BOREDVCHARTMARKET.GETCHARTBYID, params);
  }
  /**
   * @desc 同步图表-----
   */
  syncChart(){
    return this.get(BOREDVCHARTMARKET.SYNCCHART);
  }

}

export const boredvChartmarketService = new BoredvChartmarketService();


import  baPanel from  './baPanel.directive'
import  baPanels  from './baPanel.service'
import  baPanelBlur from './baPanelBlur.directive';
import baPanelBlurHelper from './baPanelBlurHelper.service';
import baPanelSelf from './baPanelSelf.directive';

export default angular.module('BlurAdmin.theme.components.baPanel',[])
.directive('baPanel', ['baPanel',baPanel])
.factory('baPanel', baPanels)
.directive('baPanelBlur', ['baPanelBlurHelper', '$window', '$rootScope',baPanelBlur])
.service('baPanelBlurHelper', ['$q',baPanelBlurHelper])
.directive('baPanelSelf', ['baPanel',baPanelSelf]).name;


import boardListCrtl from './boardListCrtl'
import './_boardList.scss'
function boardListModal($uibModal) {
    this.openBoardList = function (boardId, func_ok, boardList, categoryList) {
        $uibModal.open({
            template: require('./boardList.html'),
            controller: ['$scope', '$uibModalInstance', 'toastr', 'boardList', 'categoryList', 'boardId', 'ok', boardListCrtl],
            backdrop: false,
            size: 'lg',
            windowClass: 'board-list-modal',
            resolve: {
                boardList: function(){
                    return boardList;
                },
                categoryList: function(){
                    return categoryList;
                },
                boardId: function(){
                    return boardId;
                },
                ok: function () {
                    return func_ok;
                }
            }
        });
    };

}



export default angular.module('borepc.pages.dblayout.layoutBoardList', [])
    .service('boardListModal', ['$uibModal', boardListModal]).name







// Ourbore.service('chartKpi2Service', function (dataService, $compile) {

    import   numbro  from 'numbro'
    import * as util from '../../util/util'
    import Kpi2RenderEngine from '../../rengine/Kpi2RenderEngine'
    class Kpi2Render   {
        //默认高度
        height = 190;

        render = function (containerDom, option, scope, persist) {
            var render = new Kpi2RenderEngine(containerDom, option);
            var html = render.html(persist);
            if (scope) {
                containerDom.append(this.$compile(html)(scope));
            } else {
                containerDom.html(html);
            }
            return render.realTimeTicket();
        };

        parseOption = function (data) {
            var option = {};
            var config = data.chartConfig;
            var casted_keys = data.keys;
            var casted_values = data.series;
            var aggregate_data = data.data;
            var newValuesConfig = data.seriesConfig;
            var dataLen = aggregate_data[0].length;
            var lastKey = data.keys[dataLen - 1];
            var selectNum = {};
            var currentKey = data.currentKey;       //当前的key
            var currentIndex;                       //当前key的index----在casted_keys中匹配
            for (var j = 0; j < casted_keys.length; j++) {
                if (casted_keys[j].toString() === currentKey.toString()) currentIndex = j;
            }
            for (var i = 0; i < config.keys.length; i++) {
                var keyOne = config.keys[i].col;
                selectNum[keyOne] = i;
            }
            var currentYearIndex = selectNum["年"] || 0;
            // var  currentMonthIndex= selectNum["月"] || 1;
            var  currentMonthIndex= selectNum["月"] || selectNum["月"] === 0 ? selectNum["月"] : 1;
            var  currentSeasonIndex = selectNum["季"];
            //当前年月
            // var currentYear = casted_keys[casted_keys.length - 1][currentYearIndex];
            // var  currentMonth= casted_keys[casted_keys.length - 1][currentMonthIndex];
            var currentYear = currentKey[currentYearIndex];
            var currentMonth= currentKey[currentMonthIndex];
            var  currentSeason= casted_keys[casted_keys.length - 1][currentSeasonIndex];

            // currentMonth = currentMonth.toString().replace(/^0/, '');
            option.currentMonth = currentMonth;
            option.currentYear = currentYear;

            // 上月
            // var lastMonth = util.clone(casted_keys[casted_keys.length - 1]);
            var lastMonth = util.clone(currentKey);
            // lastMonth = lastMonth.toString().replace(/^0/, '');
            var lastMonthIndex, lastMonthValue, lastMonthPercent,lastCountMonth;
            lastCountMonth = String(currentMonth - 1);
            lastMonth[currentMonthIndex] = lastCountMonth == "0"?"12":lastCountMonth;
            if(lastCountMonth == "0") lastMonth[currentYearIndex] =String(currentYear -1);
            for (var j = 0; j < casted_keys.length; j++) {
                var castedKValue = _.map(casted_keys[j],function(e){
                    return Number(e).toString();
                })
                if (castedKValue.toString() === lastMonth.toString()) {
                    lastMonthIndex = j;
                }

            }
            if (lastMonthIndex || lastMonthIndex === 0) {
                lastMonthValue = aggregate_data[0][lastMonthIndex];
                if (lastMonthValue !== 0) {
                    // lastMonthPercent = (aggregate_data[0][currentIndex] - lastMonthValue) / lastMonthValue * 100;
                    lastMonthPercent = (aggregate_data[0][currentIndex] - lastMonthValue) / Math.abs(lastMonthValue) * 100;
                    lastMonthPercent = lastMonthPercent.toFixed(2);
                } else {
                    if (aggregate_data[0][currentIndex] === 0) {
                        lastMonthPercent = 0;
                    } else {
                        lastMonthPercent = 100;
                    }
                }
            } else {
                lastMonthValue = 'No Data!';
                lastMonthPercent = 0;
            }
            if (lastMonthPercent < 0) {
                option.monthStyle = "icon-icon_Drop";
            } else if (lastMonthPercent >= 0) {
                option.monthStyle = "icon-icon_Increase";
            }

            //上年
            // var lastYear = util.clone(casted_keys[casted_keys.length - 1]);
            var lastYear = util.clone(currentKey);
            var lastYearIndex, lastYearValue, lastYearPercent;
            lastYear[currentYearIndex] = String(currentYear - 1);
            for (var j = 0; j < casted_keys.length; j++) {
                if (casted_keys[j].toString() === lastYear.toString()) {
                    lastYearIndex = j;
                }
            }
            if (lastYearIndex || lastYearIndex === 0) {
                lastYearValue = aggregate_data[0][lastYearIndex];
                if (lastYearValue !== 0) {
                    // lastYearPercent = (aggregate_data[0][currentIndex] - lastYearValue) / lastYearValue * 100;
                    lastYearPercent = (aggregate_data[0][currentIndex] - lastYearValue) / Math.abs(lastYearValue) * 100;
                    lastYearPercent = lastYearPercent.toFixed(2);
                } else {
                    lastYearPercent = 100;
                }
            } else {
                lastYearValue = 'No Data!';
                lastYearPercent = 0;
            }
            if (lastYearPercent < 0) {
                option.yearStyle = "icon-icon_Drop";
            } else if (lastYearPercent >= 0) {
                option.yearStyle = "icon-icon_Increase";
            }

            //获取指标数组
            // var col = config.values[0].cols[0];
            // var setColFormatter = false;
            // option.kpiValue = aggregate_data.length > 0 ? aggregate_data[0][currentIndex] : 'N/A';
            // if(col && (col.unit || col.symbol ||col.dividend || col.mantissa)){
            //     option.kpiValue = util.convertEchartUnit(col,option.kpiValue);
            //     setColFormatter=true;
            // }else{
            //     if (config.values[0].format) {
            //         option.kpiValue = numbro(option.kpiValue).format(config.values[0].format);
            //         var index = config.values[0].format.lastIndexOf("|");
            //         if (index != -1) {
            //             var unit = config.values[0].format.substring(index + 1, config.values[0].format.length);
            //             option.kpiValue = option.kpiValue + unit;
            //         }

            //     }
            // }

            var setColFormatter = false;
            //设置option.kpiValue为有三个键的对象
            option.kpiValue = {
                // value:"",
                // unit:"",
                // unitFontSize
            };
            //获取指标数组
            var col = config.values[0].cols[0];
            var value = aggregate_data.length > 0 ? aggregate_data[0][currentIndex] : 'N/A';
            if(col && (col.unit || col.symbol ||col.dividend || col.mantissa || col.unitFontSize)){
                option.kpiValue = util.convertEchartUnitToObj(col, value);
                setColFormatter=true;
            }else{
                if (config.values[0].format) {
                    option.kpiValue.value = numbro(value).format(config.values[0].format);
                    var index = config.values[0].format.lastIndexOf("|");
                    if (index != -1) {
                        var unit = config.values[0].format.substring(index + 1, config.values[0].format.length);
                        option.kpiValue.unit = unit;
                    }

                }
            }
            option.kpiValue = {
                // value: option.kpiValue.value || value,
                value: option.kpiValue.value || option.kpiValue.value === 0 ? option.kpiValue.value : value,
                unit: option.kpiValue.unit || "",
                unitFontSize: option.kpiValue.unitFontSize || "1em"
            };

            //设置预警
            if (config.option.isKpi2Warm){
                if (config.option.Kpi2WarmType && config.option.Kpi2WarmNumber) {
                    switch (config.option.Kpi2WarmType) {

                        case '==' :
                            if (Number(aggregate_data[0][currentIndex]) == Number(config.option.Kpi2WarmNumber)){
                                option.kpi2WarmFlag = 'block';
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '!=' :
                            if (Number(aggregate_data[0][currentIndex]) != Number(config.option.Kpi2WarmNumber)){
                                option.kpi2WarmFlag = 'block';
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '>' :
                            if (Number(aggregate_data[0][currentIndex]) > Number(config.option.Kpi2WarmNumber)){
                                option.kpi2WarmFlag = 'block';
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '<' :
                            if (Number(aggregate_data[0][currentIndex]) < Number(config.option.Kpi2WarmNumber)){
                                option.kpi2WarmFlag = 'block';
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '>=' :
                            if (Number(aggregate_data[0][currentIndex]) >= Number(config.option.Kpi2WarmNumber)){
                                option.kpi2WarmFlag = 'block';
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '<=' :
                            if (Number(aggregate_data[0][currentIndex]) <= Number(config.option.Kpi2WarmNumber)){
                                option.kpi2WarmFlag = 'block';
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '(a,b]' :
                            var warmArr = [];
                            var temArr = config.option.Kpi2WarmNumber.trim().split(' ');
                            if (temArr.length != 1) {
                                warmArr.push(temArr.shift());
                                warmArr.push(temArr.pop());
                                var flag = Number(aggregate_data[0][currentIndex]) > Number(warmArr[0])  &&  Number( aggregate_data[0][currentIndex]) <= Number(warmArr[1]);
                                if ( flag ) {
                                    option.kpi2WarmFlag = 'block';
                                }else{
                                    option.kpi2WarmFlag = 'none';
                                }
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '[a,b)' :
                            var warmArr = [];
                            var temArr = config.option.Kpi2WarmNumber.trim().split(' ');
                            if (temArr.length != 1) {
                                warmArr.push(temArr.shift());
                                warmArr.push(temArr.pop());
                                var flag = Number(aggregate_data[0][currentIndex]) >= Number(warmArr[0])  &&  Number( aggregate_data[0][currentIndex]) < Number(warmArr[1]);
                                if ( flag ) {
                                    option.kpi2WarmFlag = 'block';
                                }else{
                                    option.kpi2WarmFlag = 'none';
                                }
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '(a,b)' :
                            var warmArr = [];
                            var temArr = config.option.Kpi2WarmNumber.trim().split(' ');
                            if (temArr.length != 1) {
                                warmArr.push(temArr.shift());
                                warmArr.push(temArr.pop());
                                var flag = Number(aggregate_data[0][currentIndex]) > Number(warmArr[0])  &&  Number( aggregate_data[0][currentIndex]) < Number(warmArr[1]);
                                if ( flag ) {
                                    option.kpi2WarmFlag = 'block';
                                }else{
                                    option.kpi2WarmFlag = 'none';
                                }
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                        case '[a,b]' :
                            var warmArr = [];
                            var temArr = config.option.Kpi2WarmNumber.trim().split(' ');
                            if (temArr.length != 1) {
                                warmArr.push(temArr.shift());
                                warmArr.push(temArr.pop());
                                var flag = Number(aggregate_data[0][currentIndex]) >= Number(warmArr[0])  &&  Number( aggregate_data[0][currentIndex]) <= Number(warmArr[1]);
                                if ( flag ) {
                                    option.kpi2WarmFlag = 'block';
                                }else{
                                    option.kpi2WarmFlag = 'none';
                                }
                            }else{
                                option.kpi2WarmFlag = 'none';
                            }
                        break;
                    }

                }else{
                    option.kpi2WarmFlag = 'none';
                }
            }else{
                option.kpi2WarmFlag = 'none';
            }
            option.kpiName = config.values[0].name || casted_values[0];
            option.style = config.values[0].style;
            option.edit = 'edit'//translate("COMMON.EDIT");
            option.refresh = 'refresh'//translate("COMMON.REFRESH");
            option.monthName = "月";
            option.yearName = "年";
            option.lastMonthPercent = lastMonthPercent;
            option.lastYearPercent = lastYearPercent;
            option.lastMonthValue = lastMonthValue;
            option.lastYearValue = lastYearValue;
            if(setColFormatter){
                option.lastMonthValue = util.convertEchartUnit(col,lastMonthValue);
                option.lastYearValue = util.convertEchartUnit(col,lastYearValue);
            }
            if((option.currentYear && option.currentYear.indexOf('{now') !== -1) || (option.currentMonth && option.currentMonth.indexOf('{now') !== -1)) {
                option.currentYear = " ";
                option.currentMonth = " ";
                option.monthName = " ";
                option.yearName = " ";
            }
            if(option.currentYear === undefined) {
                option.currentYear = " ";
                option.yearName = " ";
            }
            if(option.currentMonth === undefined) {
                option.currentMonth = " ";
                option.monthName = " ";
            }
            return option;
        };
    }


    export {Kpi2Render}

import icon_pic from './gauge.png'
import icon_pic_active from './gauge-active.png'
var config = {
    rule: {
        keys: 0,
        groups: 0,
        filters: -1,
        values: 1
    },
    info:{
        name: 'CONFIG.WIDGET.GAUGE',
        value: 'gauge',
        // class: 'cGauge',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_1'
    }
}
export default config;
import BasicService from './BasicService';
import { WIDGETAPI } from './ApiServer';
import datasetService from './dataset.service'
import { dataService } from './data.service';
/**
 * @desc 测试用
 */

//GETCONFIGPARAMS: '/dashboard/getConfigParams',
//GETWIDGETDETAIL: ' /dashboard/getWidgetDetail',
//GETWIDGETLIST: '/dashboard/getWidgetList',
//GETWIDGETCATEGORYLIST: ' /dashboard/getWidgetCategoryList',
//SAVENEWWIDGET: '/dashboard/saveNewWidget',
//DELETEWIDGET: '/dashboard/deleteWidget',
//GETCONFIGVIEWTYPE: '/dashboard/getConfigViewtype',  //=' + $scope.da

class WidgetService extends BasicService {
  constructor() {
    super();
  }
  getConfigParams(param) {
    return this.post(WIDGETAPI.GETCONFIGPARAMS, param)
  }
  getWidgetDetail(param) {
    return this.post(WIDGETAPI.GETWIDGETDETAIL, param)
  }

  getWidgetDimS(wId) {
    return new Promise((resolve, reject) => {
      this.getWidgetDetail({ id: wId }).then((resp) => {
        var dataSet = JSON.parse(resp.dataset.data)
        var fields = [];
        _.each(dataSet.schema.dimension, function (e) {
          if (e.type == "column") {
            fields.push(e.column);
          }
          if (e.type == "level") {
            _.each(e.columns, function (col) {
              fields.push(col.column);
            })
          }
        })
        resolve(fields)
      })
    })
  }

  getWidgetListByIds(ids) {
    return this.post(WIDGETAPI.GETWIDGETLISTBYIDS, { ids: ids })
  }
  checkWidget(param) {
    return this.post(WIDGETAPI.CHECKWIDGET, param);
  }
  getWidgetList() {
    return this.get(WIDGETAPI.GETWIDGETLIST)
  }
  getWidgetCategoryList(param) {
    return this.post(WIDGETAPI.GETWIDGETCATEGORYLIST, param);
  }
  saveNewWidget(param) {
    return this.post(WIDGETAPI.SAVENEWWIDGET, param);
  }
  updateWidget(param) {
    return this.post(WIDGETAPI.UPDATEWIDGET, param);
  }
  deleteWidget(param) {
    return this.post(WIDGETAPI.DELETEWIDGET, param);
  }
  getConfigViewtype(param) {
    return this.post(WIDGETAPI.GETCONFIGVIEWTYPE, param);
  }
  getWidgetCategoryList(param) {
    return this.post(WIDGETAPI.GETWIDGETCATEGORYLIST, param);
  }
  getWidgetsByDatasetId(param) {
    return this.post(WIDGETAPI.GETWIDGETSBYDATASETID, param);
  }
  

}

export const widgetService = new WidgetService();

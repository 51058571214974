/**
 * Created by  jaray  2018-11-28
 */
import PubSub from "../../common/utils/PubSub";
import boardContext from '../BoardContext';
var echartsBasicOption = {
    title: {},
    grid: {
        left: '80',
        right: '50',
        bottom: '15%',
        top: '15%',
        containLabel: false
    },
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        x: 'left',
        itemWidth: 15,
        itemHeight: 10
    }
};

var EchartRenderEngine = function (jqContainer, options, isDeepSpec) {
    this.container = jqContainer; // jquery object
    $(this.container).attr('_echarts_instance_', ''); //解决不重新渲染问题
    this.ecc = echarts.init(jqContainer.get(0), this.theme);
    this.ecc.clear();
    this.ecc.off('click');//先移除，再点击
    this.isDeppSpec = isDeepSpec;
    this.basicOption = echartsBasicOption;
    this.options = options;
};

EchartRenderEngine.prototype.theme = "theme-fin1"; // 主题

EchartRenderEngine.prototype.chart = function (group, persist) {
    try {
        var self = this;
        var options = this.isDeppSpec == true ? self.options : $.extend(true, {}, self.basicOption, self.options);
        if (options.visualMap != undefined) {
            $(this.container).css({
                // height: 500 + "px",
                width: '100%'
            });
        }
        if (options.legend.data && options.legend.data.length > 35) {
            options.grid.top = '5%';
            options.legend.show = false;
        }
        if (persist) {
            options.animation = false;
        }
        self.ecc.setOption(options);
        // console.log(self.ecc)
        self.ecc.resize();
        self.changeSize(self.ecc);


        // $('.body-bg').resize(function () {
        //   
        // });
        //  self.container.resize(function (e) {
        //   console.log(self.container)
        self.container.resize(function (e) {
            self.ecc.resize();
            self.changeSize(self.ecc);
        }); // 图表大小自适应
        if (group) {
            self.ecc.group = group;
            echarts.connect(group);
        }
        if (persist) {
            setTimeout(function () {
                persist.data = self.ecc.getDataURL({
                    type: 'jpeg',
                    pixelRatio: 2,
                    backgroundColor: '#fff'
                });
                persist.type = "jpg";
                persist.widgetType = "echarts";
            }, 1000);
        }
        return function (o) {
            o = $.extend(true, {}, self.basicOption, o);
            self.ecc.setOption(o, true);
        }
    } catch (e) {
        console.log(e)
    }
};

EchartRenderEngine.prototype.changeSize = function (instance) {
    var o = instance.getOption();
    if ((o.series[0] ? o.series[0].type : null) == 'pie') {
        var oldRadius = o.series[0].radius;
        //radius为有值时 不对饼状图进行默认处理
        if (oldRadius && oldRadius.length > 0) return;
        var l = o.series.length;
        var b = instance.getWidth() / (l + 1 + l * 8)
        for (var i = 0; i < l; i++) {
            var seriesType = o.series[i] ? o.series[i].realType : null;
            if ((b * 8) < (instance.getHeight() * 0.75)) {
                if (seriesType == 'doughnut') {
                    o.series[i].radius = [b * 3, b * 4];
                } else if (seriesType == 'coxcomb') {
                    o.series[i].radius = [b * 0.8, b * 4];
                } else {
                    o.series[i].radius = [0, b * 4];
                }

            } else {
                if (seriesType == 'doughnut') {
                    o.series[i].radius = ['50%', '75%'];
                } else if (seriesType == 'coxcomb') {
                    o.series[i].radius = ['15%', '75%']
                } else {
                    o.series[i].radius = ['0', '75%'];
                }
            }
        }
        instance.setOption(o);
    }

};

//{"sourceField":[],"relations":[{},{}]}
EchartRenderEngine.prototype.addClick = function (chartConfig, relations) {
    // console.log('param', relations); //点击包含内容数据；]
    if (!chartConfig || !relations) {
        return;
    }
    // console.log(_)
    var self = this;
    self.ecc.on('click', function (param) {
        // console.log('param', param); //点击包含内容数据；
        // console.log('chartConfig',chartConfig); //点击对象的一些配置
        // console.log('relations',relations)//所关联的图表信息
        // console.log('inputvalue',$("#relations").val())//关联图表的信息 以及点击param信息
        var sourceField = relations.sourceField;
        var links = relations.relations;
        //[{"targetId":relation.targetId, params:[{"targetField":targetField,"value":param.name},{}]}]
        // var relations_old = JSON.parse($("#relations").val());
        // var relations_new = [];
        // _.each(relations_old, function (relation_old) { //删除已存在的
        //     var exists = false;
        //     _.each(links, function (relation) {
        //         if (relation_old.targetId = relation.targetId) {
        //             exists = true;
        //             return;
        //         }
        //     })
        //     if (!exists) {
        //         relations_new.push(relation_old);
        //     }
        // });

        var groups = _.map(chartConfig.groups, function (group, index) {
            return { "index": index, "name": group.col };
        });
        var keys = _.map(chartConfig.keys, function (key, index) {
            return { "index": index, "name": key.col };
        });

        var paramValues = [];

        switch (chartConfig.chart_type) {
            case 'line':
            case 'contrast':
            case 'scatter':
            case 'pie':
                _.each(sourceField, function (field) {
                    if ($.inArray(field, _.map(groups, function (group) {
                        return group.name
                    })) != -1
                        || $.inArray(field, _.map(keys, function (key) {
                            return key.name
                        })) != -1
                    ) {
                        _.each(groups, function (group) {
                            if (group.name == field) {
                                paramValues.push(param.seriesName.split("-")[group.index]);
                            }
                        });
                        _.each(keys, function (key) {
                            if (key.name == field) {
                                //key.name包含-的情况
                                if (keys.length === 1) {
                                    paramValues.push(param.name);
                                } else {
                                    paramValues.push(param.name.split("-")[key.index]);
                                }
                            }
                        });
                    } else {
                        paramValues.push("noMatch");
                    }
                });
                break;

            case 'funnel':
                _.each(sourceField, function (field) {
                    if ($.inArray(field, _.map(keys, function (key) { return key.name; })) != -1) {
                        _.each(keys, function (key) {
                            if (key.name == field) {
                                //key.name包含-的情况
                                if (keys.length === 1) {
                                    paramValues.push(param.name);
                                } else {
                                    paramValues.push(param.name.split("-")[key.index]);
                                }
                            }
                        });
                    } else {
                        paramValues.push("noMatch");
                    }
                });
                break;

            case 'sankey':
                if (param.dataType == 'edge') {
                    _.each(sourceField, function (field) {
                        if ($.inArray(field, _.map(keys, function (key) {
                            return key.name;
                        })) != -1
                            || $.inArray(field, _.map(groups, function (group) {
                                return group.name;
                            })) != -1) {
                            _.each(keys, function (key) {
                                if (key.name == field) {
                                    //key.name包含-的情况
                                    if (keys.length === 1) {
                                        paramValues.push(param.name);
                                    } else {
                                        paramValues.push(param.name.split("-")[key.index]);
                                    }
                                }
                            });
                            _.each(groups, function (group) {
                                if (group.name == field) {
                                    paramValues.push(param.data.target.split("-")[group.index]);
                                }
                            });
                        } else {
                            paramValues.push("noMatch");
                        }
                    });
                }
                break;

            case 'radar':
                if (chartConfig.asRow) {//雷达行维
                    _.each(sourceField, function (field) {
                        if ($.inArray(field, _.map(keys, function (key) { return key.name; })) != -1) {
                            _.each(keys, function (key) {
                                if (key.name == field) {
                                    //key.name包含-的情况
                                    if (keys.length === 1) {
                                        paramValues.push(param.name);
                                    } else {
                                        paramValues.push(param.name.split("-")[key.index]);
                                    }
                                }
                            });
                        } else {
                            paramValues.push("noMatch");
                        }
                    });
                } else {
                    _.each(sourceField, function (field) {
                        if ($.inArray(field, _.map(groups, function (group) { return group.name; })) != -1) {
                            _.each(groups, function (group) {
                                if (group.name == field) {
                                    paramValues.push(param.name.split("-")[group.index]);
                                }
                            });
                        } else {
                            paramValues.push("noMatch");
                        }
                    });
                }
                break;

            case 'wordCloud':
                _.each(sourceField, function (field) {
                    if ($.inArray(field, _.map(keys, function (key) { return key.name; })) != -1) {
                        _.each(keys, function (key) {
                            if (key.name == field) {
                                //key.name包含-的情况
                                if (keys.length === 1) {
                                    paramValues.push(param.name);
                                } else {
                                    paramValues.push(param.name.split("-")[key.index]);
                                }
                            }
                        });
                    } else {
                        paramValues.push("noMatch");
                    }
                });
                break;

            case 'treeMap':
                _.each(sourceField, function (field) {
                    if ($.inArray(field, _.map(keys, function (key) { return key.name.toUpperCase(); })) != -1) {
                        _.each(keys, function (key) {
                            if (key.name.toUpperCase() == field && param.treePathInfo[key.index + 1]) {
                                paramValues.push(param.treePathInfo[key.index + 1].name);
                            }
                        });
                    } else {
                        paramValues.push("noMatch");
                    }
                });
                break;

            default:
                break;
        }

        // _.each(links, function (relation) {
        //     var record = {};
        //     record.targetId = relation.targetId;
        //     record.params = [];
        //     for (var i = 0; i < relation.targetField.length; i++) {
        //         var e = {};
        //         e.targetField = relation.targetField[i];
        //         e.value = paramValues[i];
        //         record.params.push(e);
        //     }
        //     record.params = _.filter(record.params, function (e) {
        //         return e.value != "noMatch";
        //     });
        //     relations_new.push(record);
        // });
        let sourceId = relations.sourceId;
        //新的图表关联方式 
        /*
        *sourceField的值与paramValues的值为一一对应
        *links数组一个元素表示一个关联的图表或看板
        *links的元素的targetField属性，其值应该与sourceField的值比较，相等且对应的paramValues有值则增加filter
        */
        _.each(links, function (relation) {
            let r_filters = []
            for (var i = 0; i < relation.targetField.length; i++) {
                // for (var j = 0; j < paramValues.length; j++) {
                //     if (paramValues[j] == 'noMatch')
                //         continue;
                //     var filter = {
                //         col: relation.targetField[i],
                //         type: "=",
                //         values: [paramValues[j]]
                //     };
                //     r_filters.push(filter)
                // }
                for (var j = 0; j< sourceField.length; j++){
                    if(relation.targetField[i] == sourceField[j] && paramValues[j] != 'noMatch') {
                        var filter = {
                            col:relation.targetField[i],
                            type:"=",
                            values:[paramValues[j]]
                        };
                        r_filters.push(filter);
                    }
                }
            }
            // console.log(relation)
            if (relation.type == "widget") {
                let w_filters = boardContext.getWidgetFilterById(sourceId)
                _.each(w_filters, (filter) => {
                    r_filters.push(filter)
                })
                //默认或单选的情况--即非多选
                if(relation.selectType != '多选'){
                    boardContext.clearWidgetFiltersById(relation.targetId);
                }
                //将筛选器注入到图表 并且刷新
                boardContext.injectWidgetFilters(relation.targetId, r_filters)
            }
            if (relation.type == "board") {
                let renderId = chartConfig.renderId || sourceId;
                let w_filters = boardContext.getWidgetAllFilterById(renderId);
                let param = [];
                _.each(r_filters, (f)=>{
                    param.push(f);
                });
                _.each(w_filters, (filter) => {
                    let flag = false;   //图表的切片条件的名字是否出现在关联条件中，默认无
                    _.each(r_filters, (f)=>{
                        if(f.col == filter.col) {
                            flag = true;
                            return false;
                        }
                    });
                    if(!flag) {
                        param.push(filter);
                    }
                })

                var boardLink = {
                    param: param,
                    targetId: relation.targetId
                }
                //    console.log('send'+JSON.stringify(boardLink))
                PubSub.publish('boardLink', boardLink)
            }



        });
        // $("#relations").val(JSON.stringify(relations_new));
        // PubSub.publish("msg","频道发布的消息")
        //触发关联图表刷新
        // _.each(_.filter(links, function (e) { return e.type == "widget" }), function (relation) {
        //     boardContext.refreshWidget(relation.targetId)
        // });
        //触发弹出看板
        // _.each(_.filter(links, function (e) { return e.type == "board" }), function (relation) {
        //     // var url = $state.href('mine.view', { id: relation.targetId });
        //     var param = JSON.stringify(_.find(relations_new, function (e) { return e.targetId == relation.targetId }));
        //     console.log(param)
        //     PubSub.publish('boardLink', param)
        //     // $window.open(encodeURI(url + "?" + param), '_blank');
        // });
    });
};


export default EchartRenderEngine;
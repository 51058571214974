import * as util from '../util/util';
import boreFormula from './boreFormula';

const AGGREGATE_REG = /(sum|avg|count|max|min|distinct)\("?.*?"?\)/g;   // 聚合表达式的正则表达式
const FLOAT_REG      = /^\-?\d+(\.\d+)?(\e(\-)?\d+)?$/;                  // 浮点的正则表达式

/**widget的指标卡上的过滤器----判断值是否符合范围
 *
 * @param {Object} cfg {f_type, f_values}
 * @param {String|Number} iv 数值
 * @param {Boolean}
 * */
function filter(cfg, iv) {
    switch (cfg.f_type) {
        case '=':
        case 'eq':
            for (var i = 0; i < cfg.f_values.length; i++) {
                if (iv == cfg.f_values[i]) {
                    return true;
                }
            }
            return cfg.f_values.length == 0;
        case '≠':
        case 'ne':
            for (var i = 0; i < cfg.f_values.length; i++) {
                if (iv == cfg.f_values[i]) {
                    return false;
                }
            }
            return true;
        case '>':
            var v = cfg.f_values[0];
            var params = toNumber(iv, v);
            if (!util.isUndefined(v) && params[0] <= params[1]) {
                return false;
            }
            return true;
        case '<':
            var v = cfg.f_values[0];
            var params = toNumber(iv, v);
            if (!util.isUndefined(v) && params[0] >= params[1]) {
                return false;
            }
            return true;
        case '≥':
            var v = cfg.f_values[0];
            var params = toNumber(iv, v);
            if (!util.isUndefined(v) && params[0] < params[1]) {
                return false;
            }
            return true;
        case '≤':
            var v = cfg.f_values[0];
            var params = toNumber(iv, v);
            if (!util.isUndefined(v) && params[0] > params[1]) {
                return false;
            }
            return true;
        case '(a,b]':
            var a = cfg.f_values[0];
            var b = cfg.f_values[1];
            var params = toNumber(iv, a, b);
            if (!util.isUndefined(a) && !util.isUndefined(b) && (params[0] <= params[1] || params[0] > params[2])) {
                return false;
            }
            return true;
        case '[a,b)':
            var a = cfg.f_values[0];
            var b = cfg.f_values[1];
            var params = toNumber(iv, a, b);
            if (!util.isUndefined(a) && !util.isUndefined(b) && (params[0] < params[1] || params[0] >= params[2])) {
                return false;
            }
            return true;
        case '(a,b)':
            var a = cfg.f_values[0];
            var b = cfg.f_values[1];
            var params = toNumber(iv, a, b);
            if (!util.isUndefined(a) && !util.isUndefined(b) && (params[0] <= params[1] || params[0] >= params[2])) {
                return false;
            }
            return true;
        case '[a,b]':
            var a = cfg.f_values[0];
            var b = cfg.f_values[1];
            var params = toNumber(iv, a, b);
            if (!util.isUndefined(a) && !util.isUndefined(b) && (params[0] < params[1] || params[0] > params[2])) {
                return false;
            }
            return true;
        default:
            return true;
    }
}

// 判断是否为空----与util中的比较少了字符串的undefined
function isEmpty(value) {
    if(value === null || value === undefined || value === '' || value === "") {
        return true;
    }
    return false;
}

// 符合数字格式的转为数字，其它返回原值
function floatConvert(value){
    if(FLOAT_REG.test(value)) {
        return parseFloat(value);
    }
    return value;
}

// 转数字，只有所有都能转为数字才进行转换
function toNumber() {
    var arr = util.isArray(arguments[0]) ? arguments[0] : arguments;
    var result = [];
    for (var i = 0; i < arr.length; i++) {
        var a = Number(arr[i]);
        if (isNaN(a)) {
            return arr;
        } else {
            result.push(a);
        }
    }
    return result;
}

// 比较两个字符串的大小
function getKeySort(a, b, sortType) {
    if (a == b) {
        return 0;
    }
    var params = toNumber(a, b);
    //^位运算符，其一为真则为真
    if ((params[0] < params[1]) ^ sortType == 'asc') {
        return 1;
    } else {
        return -1;
    }
}

//比较两个字符串或数字的大小, 根据sortType判断返回的值0，-1，1-----空的情况，数字的情况，其它类型的情况
function getSortDeep(a, b, sortType, isDeep){
    // 先强类型比较相等返回
    if(a === b) {
        return 0;
    }
    // 两者均为空的情况
    if(isEmpty(a) && isEmpty(b)){
        if ( ( String(a) < String(b) ) ^ sortType == 'asc') {
            return 1;
        } else {
            return -1;
        }
    }
    // 其一为空的情况----空在前
    if(isEmpty(a) ^ isEmpty(b)) {
        if ( isEmpty(a) ^ sortType == 'asc') {
            return 1;
        } else {
            return -1;
        }
    }
    // 浮点数的情况
    let floatA = parseFloat(a),
        floatB = parseFloat(b);
    // 两者均为数字的情况
    if(!isNaN(floatA) && !isNaN(floatB)) {
        //^位运算符，其一为真则为真
        if ( (floatA < floatB) ^ sortType == 'asc') {
            return 1;
        } else {
            return -1;
        }
    }
    // 其一为数字的情况
    if(!isNaN(floatA) ^ !isNaN(floatB)) {
        if(!isNaN(floatA) ^ sortType == 'asc') {
            return 1;
        } else {
            return -1;
        }
    }
    // 两者均不能转为浮点的字符串或其它类型
    let strA = String(a),
        strB = String(b);
    if(isDeep) {
        // 按字符进行比较
        let f = 0, l = strA.length;
        if(strA.length < strB.length){
            f = sortType == 'asc' ? -1 : 1;
        } else if(strA.length > strB.length) {
            l = strB.length;
            f = sortType == 'asc' ? 1 : -1;
        }
        // 按顺序比较每个字符
        for(let i = 0; i < l; i++) {
            if(strA[i] < strB[i]) {
                f = sortType == 'asc' ? -1 : 1;
                break;
            } else if (strA[i] > strB[i]) {
                f = sortType == 'asc' ? 1 : -1;
                break;
            }
        }
        return f;
    } else {
        if ((strA < strB) ^ sortType == 'asc') {
            return 1;
        } else {
            return -1;
        }
    }
}

// 针对value的排序----只有数字或字符串类型的数字----NaN最小
function getValueSort(a, b, sortType){
    if (a == b) return 0;
    //将NaN作为最小值判断
    if(isNaN(a) && isNaN(b)){
        return 0;
    }
    if(isNaN(a)){
        if(sortType == 'asc'){
            return -1;
        } else {
            return 1;
        }
    }
    if(isNaN(b)){
        if(sortType == 'asc'){
            return 1;
        } else {
            return -1;
        }
    }
    //^位运算符，其一为真则为真
    if ((a < b) ^ sortType == 'asc') {
        return 1;
    } else {
        return -1;
    }
}

/**表达式预处理----将表达式中引用其它的表达式替换为表达式的内容
 *
 * @param {Array} expList [{alias: '', exp: ''}]
 * @param {Object} exp  {exp}
 * @return {String} newExp      引用的表达式${表达式名称}被替换为(exp)
 * */
 function replaceVariable(expList, exp) {
    var value = exp.exp;
    var loopCnt = 0;
    var context = {};
    for (var i = 0; i < expList.length; i++) {
        context[expList[i].alias] = expList[i].exp;
    }
    value = util.StringRender2(value, context, function (v) {
        return '(' + v + ')';
    });
    while (value.match(/\$\{.*?\}/g) != null) {
        value = util.StringRender2(value, context, function (v) {
            return '(' + v + ')';
        });
        if (loopCnt++ > 10) {
            throw 'Parser expresion [ ' + exp.exp + ' ] with error';
        }
    }
    return value;
}

/**表达式一次处理----转入列号----所有带聚合的指标被替换为====>列号+{_#rowIndex}
 *
 * @param {String} exp 表达式  其中目标字符串===>聚合方式(指标名)
 * @param {Array} columnList 实例化对象时传入data数据的说明头部
 * [{name: '指标名', aggType: '聚合方式', index: 0}, {name: '列名', index: '序号'}]
 * @return {String}
 * */
function getExpConvertColumn(exp, columnList){
    var columnExp = exp;
    columnExp = boreFormula.expMathconvert(columnExp);   //最开始将Math的常量和max min方法替换掉，防止影响聚合
    var _temp = [];
    columnExp = columnExp.trim().replace(/[\n|\r|\r\n]/g, '');

    _.each(columnExp.match(/".*?"/g), function (qutaText) {
        columnExp = columnExp.replace(qutaText, '_#' + _temp.length);
        _temp.push(qutaText);
    });

    _.each(columnExp.match(AGGREGATE_REG), function (aggUnit) {
        var aggregate = aggUnit.substring(0, aggUnit.indexOf('('));
        var name = aggUnit.substring(aggUnit.indexOf('(') + 1, aggUnit.indexOf(')'));
        if (name.match("_#")) {
            name = _temp[name.replace("_#", "")].replace(/\"/g, "");
        }
        var columnLabel = '';
        _.each(columnList, (c,i)=>{
            if(c.name == name && c.aggType == aggregate) {
                columnLabel = boreFormula.columnIndexToLabel(i);
                return false;
            }
        });
        columnExp = columnExp.replace(aggUnit, columnLabel + "{_#rowIndex}");
    });
    return columnExp;
}

/**表达式二次处理----转入行号
 *
 * @param {String} exp 表达式  其中目标字符串===>{_#rowIndex}
 * @param {Number} rowIndex 行所在的索引
 * @return {String}
 * */
function getExpconvertRow(exp, rowIndex){
    var index = boreFormula.rowIndexToLabel(rowIndex);
    var rowExp = exp.replace(/\{\_\#rowIndex\}/g, index);
    return rowExp;
}

/**表达式中获取其中的指标及其聚合方式
 *
 * @param {String} exp 表达式  其中目标字符串===>聚合方式(指标名)
 * @return {Array} [{name: '指标名', aggregate: '聚合方式'}]
 * */
 function getExpSeries(exp){
    var seriesExp = exp, _temp = [];
    seriesExp = boreFormula.expMathconvert(seriesExp);   //最开始将Math的常量和max min方法替换掉，防止影响聚合
    var aggs = [];  //返回的数组
    seriesExp = seriesExp.trim().replace(/[\n|\r|\r\n]/g, '');
    // 指标名称被双引号括起来的情况
    _.each(seriesExp.match(/".*?"/g), function (qutaText) {
        seriesExp = seriesExp.replace(qutaText, '_#' + _temp.length);
        _temp.push(qutaText);
    });
    _.each(seriesExp.match(AGGREGATE_REG), function (aggUnit) {
        var aggregate = aggUnit.substring(0, aggUnit.indexOf('('));
        var name = aggUnit.substring(aggUnit.indexOf('(') + 1, aggUnit.indexOf(')'));
        if (name.match("_#")) {
            name = _temp[name.replace("_#", "")].replace(/\"/g, "");
        }
        var flag = false;    //默认未出现的聚合方式(指标)
        _.each(aggs, (a,i)=>{
            if(a.name == name && a.aggregate == aggregate) {
                flag = true;
                return false;
            }
        });
        if(!flag){
            aggs.push({
                name: name,
                aggregate: aggregate
            });
        }
    });
    return aggs;
}

/** values的特殊数值处理----null, undefined, '', NaN, Infinity, true, false
 * @param {String|Number|Boolean|Undefined|Null} value
 * @param {Object} option
 * @return {*}
 * option = { convert: 'boolean', emptyConvert: 'boolean', nanConvert: 'boolean', nanStr: 'string', infinityConvert: 'boolean',
 *            infinityStr: 'string', booleanConvert: 'boolean', strConvert: 'boolean' }
 *
 *     'convert': '',  //是否使用自定义----true为自定义，false为默认配置-------------默认全部为原值
 *     'defaultAll': '1/2',  //默认的处理方式----1===BI     2===chartmarket---------无需分类
 *
 *      //以下三都是空值类型的------emptyConvert----------------------------excel只有null和''
 *     --------null的情况                    true处理为0   false处理为''    BI默认parseFloat()处理为NaN  chart为0
 *     --------undefined的情况               true处理为0   false处理为''    BI默认parseFloat()处理为NaN  chart为原值
 *     --------''的情况-----空字符串          true处理为0   false处理为''    BI默认parseFloat()处理为NaN   chart为0
 *
 *      //数字类型的----nanConvert、nanStr、infinityConvert、infinityStr----excel无
 *     --------NaN的情况                     true替换      false原值         BI默认NaN  chart为NaN
 *     'nanStr'******************用于替换NaN的字符串
 *     --------正负Infinity的情况            true替换      false原值         BI默认Infinity  chart为Infinity
 *     'infinityStr'************用于替换Infinity的字符串
 *
 *      //boolean类型的----booleanConvert--------------一般不会出现这个情况----默认之前无js逻辑，即无
 *     --------true的情况                    true原值字符串  false替换(1)      BI默认parseFloat()处理为NaN  chart为1
 *     --------false的情况                   true原值字符串  false替换(0)      BI默认parseFloat()处理为NaN  chart为0
 *
 *      //非数字的字符串------strConvert
 *     --------非数字开头字符串              true原字符串    false处理为NaN      BI默认parseFloat()处理为NaN  chart为原值
 *     --------数字开头字符串                true原字符串    false处理为数字     BI默认parseFloat()处理为数字  chart为原值
 *
 * */
function valueConvert(value, option) {
    if((typeof value) == 'string') {
        value = value.trim();
    }
    // 是一个数字(包括e的写法)的情况，按浮点的方法返回
    if(FLOAT_REG.test(value)) {
        return parseFloat(value);
    }
    if(!option || !(option instanceof Object)) {
        return value;
    }
    // 开启自定义的情况
    if(option.convert) {
        // 空值的情况
        if(value === null || value === undefined || value === '') {
            if( option.emptyConvert ) {
                return 0;
            } else {
                return '';
            }
        }
        // 数字的特殊情况
        if((typeof value) == 'number' ) {
            // NaN
            if(isNaN(value)) {
                if(option.nanConvert) {
                    if(option.nanStr) {
                        return floatConvert(option.nanStr);
                    } else {
                        return '';
                    }
                }
            }
            // Infinity
            if( !(10/value) ){
                if(option.infinityConvert) {
                    if(option.infinityStr) {
                        return floatConvert(option.nanStr);
                    } else {
                        return '';
                    }
                }
            }
            // 其它情况----有限数, 不转换的NaN和Infinity
            // if(isFinite(value)) {
                return value;
            // }
        }
        // boolean类型
        if(value === true || value === false) {
            if(option.booleanConvert) {
                return value.toString();
            } else {
                return Number(value);
            }
        }
        // 非数字的字符串
        if((typeof value) == 'string') {
            if(option.strConvert) {
                return value;
            } else {
                return parseFloat(value);
            }
        }
    }

    if(option.defaultAll == 1) {
        return parseFloat(value);                       // BI图表的默认处理方式-------------数字已返回数字
    } else if (option.defaultAll == 2) {
        return isNaN(value) ? value : Number(value);    // chartMarket图表的默认处理方式----数字已返回数字
    }

    return value;                                       //之外原值返回
}

/**以下处理数据的方法-------通用----后台数据转为图表的head和data结构----jx2021.06
 *
 * @param {Object} aggData 后台数据
 * @param {Object} chartConfig 图表配置----{keys:[], groups: [], values:[{cols:[]}]}
 * @param {String} datasetType 数据集类型------merge多源数据集
 * @param {Object} valueConvertOpt values的数值处理方式
 *  { all: '所有值均处理为number/float', doValueCompute: 'boolean确定是否提前对数值进行换算' }
 * @return {Object}
 * 将后台数据处理为如下格式
 * {
 *       head: [
 *           {type: 'group', name: '图表的列名或别名',   col: '图表该列维的信息'},              //0~n个
 *           {type: 'key',   name: '图表的行名或别名',   col: '图表该行维的信息'},              //0~n个
 *           {type: 'value', name: '图表的指标名或别名', col: '图表该指标的信息',
 *              valueAxisIndex: '多指标组的时的索引', series_type: '该指标所在指标组的类型'},   //0~n个
 *      ],
 *      data: [
 *          ['head[0]的数据', 'head[1]的数据', 'head[2]的数据', '...'],
 *          ['head[0]的数据', 'head[1]的数据', 'head[2]的数据', '...'],
 *          ['head[0]的数据', 'head[1]的数据', 'head[2]的数据', '...'],
 *          '...'
 *      ]
 *  }
 * 1. 获取信息，head的生成，表达式的预处理-----表达式第1次处理，累进方法的第1次处理
 *      1. groups
 *      2. keys
 *      3. values
 *          ----表达式的第1次处理-------'聚合方式{指标名}' ==> '列号{_#rowIndex}', 存入exp1的属性
 *          ----累进方法的第1次处理-----'列号{_#rowIndex}' ==> '列号{_#fromIndex}:列号{_#toIndex}, key的字段替换为索引
 * 2. 对aggData.data进行排序----groups，keys，values----存在累进方法的表达式时强制groups排序，默认asc
 * 3. 实例化对象----数据获取的
 *      1. 将有聚合的data数值转为数字----符合数字的表达式的
 *      2. 实例化
 * 4. 获取一列全部数据的方法----使用值替换方法
 * 5. 生成数据
 *      1. groups的数据
 *      2. keys的数据
 *      3. values的数据
 *          1. 表达式的情况
 *          2. column的情况
 *          3. 数值的处理
 *              ---- 1. 特殊情况的处理----valueConvert方法
 *                      ----默认可转浮点转浮点，其它原值-----------表达式才会有特殊情况
 *              ---- 2. 判断是否需要处理数值换算----只对数字有效----valueConvertOpt.doValueCompute==true----用于chartmarket
 * 6. 去掉head中的colIndex
 * 7. 表达式中存在排序，对返回的data进行再排序，2.中排序再加表达式的排序
 * */
function aggDataToCommonData(aggData, chartConfig, datasetType, valueConvertOpt) {
    var head = [],                                  // 返回数据的head----[{},{}]
        data = [];                                  // 返回数据的data-----[[],[]]
    var colList  = aggData.columnList,
        colData  = aggData.data,
        groups   = chartConfig.groups || [],        // 可能无
        keys     = chartConfig.keys || [],          // 可能无
        values   = chartConfig.values || [],
        // groupIndexInCL  = {},                        // group在colList的索引值----{name:index}
        groupsIndexArr  = [],                        // groups所在索引的数组
        keyIndexInCL    = {},                        // key在colList的索引值---------{name:index}
        // keysIndexArr    = [],                        // keys所在索引的数组
        dataSort        = [],                        // groups、keys和values中的排序----------用于叠加等的情况
        havExpSort      = false,                     // 是否存在表达式的排序-------------------默认无
        expHavOverlay   = false;                     // 判断values中是否存在累进方法的表达式----如叠加

    // 1.1 获取信息----groups-----设置head
    _.each(groups, (g)=>{
        var col = getDimensionCol(colList, g, datasetType);
        // // 这里的name取后台的name-----即数据集字段在colList的索引
        // groupIndexInCL[col.name] = col.index;
        groupsIndexArr.push(col.index);                     // groups索引的数组
        // 一定且默认升序排序
        dataSort.push({
            index: col.index,
            sort: g.sort,
            is: 'group'
        });
        // 返回数据的头部信息----groups相关
        head.push({
            name: g.alias ? g.alias : col.name,  // 有别名使用别名
            type: 'group',
            col: g,
            colIndex: col.index
        });
    });
    // 1.2 获取信息----keys-----设置head
    _.each(keys, (k)=>{
        var col = getDimensionCol(colList, k, datasetType);
        // 这里的name取后台的name-----即数据集字段在colList的索引
        keyIndexInCL[col.name] = col.index;
        // keysIndexArr.push(col.index);                     // keys索引的数组
        dataSort.push({
            index: col.index,
            sort: k.sort,
            is: 'key'
        });
        // 返回数据的头部信息----keys相关
        head.push({
            name: k.alias ? k.alias : col.name,  //有别名使用别名
            type: 'key',
            col: k,
            colIndex: col.index
        });
    });
    // 1.3 获取信息----values-----设置head----提前将指标中的表达式进行第1次处理----记录是否累进方法并进行第1次处理
    _.each(values, (value, vi)=>{
        _.each(value.cols, (c, ci)=>{
            var col = {};
            if(c.type == 'exp'){
                // 表达式的第1次处理
                c.exp1 = getExpConvertColumn(c.exp, colList);
                // 表达式累进的方法的预处理
                _.each(boreFormula.funcOfOverlay, (item)=>{
                    if(item.reg.test(c.exp)) {
                        expHavOverlay = true;
                        // 累进方法的第1次处理
                        c.exp1 = item.do1(c.exp1, keyIndexInCL);
                    }
                });
                // 表达式的排序
                if(!!c.sort) {
                    havExpSort = true;
                }
            } else {
                col = getValueCol(colList, c);
                dataSort.push({
                    index: col.index,
                    sort: c.sort,
                    is: 'value'
                });
            }
            // 返回数据的头部信息----values相关
            head.push({
                name: c.alias ? c.alias : col.name,
                type: 'value',
                col: c,
                valueAxisIndex: vi,
                series_type: value.series_type,
                colIndex: col.index
            });
        });
    });

    // 2. 对后台数据进行维度的排序----groups-keys-values-------存在累进方法表达式的情况，group强制排序，默认asc
    colData.sort(function(a, b){
        let r = 0, sort, index;
        for(let i = 0; i < dataSort.length; i++) {
            sort = dataSort[i].sort;
            dataSort[i].is == 'group' && !sort && expHavOverlay ? sort = 'asc' : null;      //group强制排序的情况判断
            if(sort) {
                index = dataSort[i].index;
                sort == 'dec' ? sort = 'desc' : null;
                if(dataSort[i].is == 'value') {
                    r = getSortDeep(a[index], b[index], sort, false);   // !=0说明大小有差
                } else {
                    r = getKeySort(a[index], b[index], sort);   // !=0说明大小有差
                }
                if(r != 0) {
                    break;
                }
            }
        }
        return r;
    });
    // 3.1 聚合值浮点化
    _.each(colData, (d, di)=>{
        _.each(colList, (c, ci)=>{
            // 聚合数值且满足数字的表达式，转为浮点
            if(!!c.aggType && FLOAT_REG.test(d[ci]) ){
                colData[di][ci] = parseFloat(d[ci]);
            }
        });
    });
    // 3.2 实例化
    var parser = new boreFormula.BoreFormula(colData),
        dataL  = colData.length;                       //数据总行数

    // 4. 表达式中方法的处理----获取一列全部值的情况，替换为值
    _.each(head, (h, hi)=>{
        if(h.type == 'value') {
            let c = h.col;
            if(c.type == 'exp'){
                _.each(boreFormula.funcOfGetColAll, (item)=>{
                    if(item.reg.test(c.exp1)) {
                        h.col.exp1 = item.do(c.exp1, parser, dataL);
                    }
                });
            }
        }
    });

    // 5.0 生成数据前声明一些变量为累进的方法做预备
    let groupSameIndex  = 0,             // 列维与当前行完全相同的最初的一行
        groupSameArr    = [],            // 匹配的相同的列维值的数组
        groupFlag       = false;         // 状态标识
    // 5. 生成数据
    for(let i = 0; i < dataL; i++) {
        data[i] = [];
        let curGroupArr = [];
        // 头部已经生成完毕
        _.each(head, (h, hi)=>{
            if(h.type == 'group') {
            // 5.1 载入列维的数据
                let value = parser.getResultByIndex(i, h.colIndex);
                data[i].push(value);
                curGroupArr.push(value);    //当前行数据的列维值
                groupFlag = true;           //需要重新获取groupSameIndex
            } else if(h.type == 'key') {
            // 5.2 载入行维的数据
                let value = parser.getResultByIndex(i, h.colIndex);
                data[i].push(value);
            } else if(h.type == 'value') {
                // 重新获取与当前行列维值相同的索引
                if(groupFlag) {
                    if(groupSameArr.join('-') != curGroupArr.join('-')) {
                        groupSameArr = curGroupArr;
                        groupSameIndex = i;
                    }
                    groupFlag = false;
                }
            // 5.3 载入指标的数据
                let value, col = h.col;
                if(col.type == 'exp') {
                    // 第2次表达式处理
                    var exp = getExpconvertRow(col.exp1, i);
                    // 累进方法的第2次处理
                    if(expHavOverlay) {
                        _.each(boreFormula.funcOfOverlay, (item)=>{
                            if(item.reg.test(exp)) {
                                exp = item.do2(exp, i, dataL, {
                                    groupsIndexStr: "'" + groupsIndexArr.join('-') + "'",   //groups所在索引组成的数组
                                    // keysIndexStr: "'" + keysIndexArr.join('-') + "'",       //keys所在索引组成的数组
                                    groupSameIndex: groupSameIndex,                         //与当前行列维值相同行的索引
                                    curGroupStr: "'" + curGroupArr.join('-') + "'",         //当前列维的值组成的数组
                                });
                            }
                        });
                    }
                    value = parser.getResult(exp);
                } else {
                    value = parser.getResultByIndex(i, h.colIndex);
                }

                // 以下对数值进行处理----1. 特殊值的处理，2. 数值大小换算
                let vConvertOpt = valueConvertOpt || {};
                if(col.valueConvert && (col.valueConvert instanceof Object) ) {
                    vConvertOpt = Object.assign(vConvertOpt, col.valueConvert);
                }
                // 5.3.1
                value = valueConvert(value, vConvertOpt);
                // 5.3.2
                if( vConvertOpt && vConvertOpt.doValueCompute ) {
                    if( FLOAT_REG.test(value) ) {
                        // 这里提前对values进行数值换算，无单位----之后换算需再次循环
                        value = Number( util.convertEchartUnit({symbol: col.symbol, dividend: col.dividend, mantissa: col.mantissa}, value, true, true) );
                    }
                }
                data[i].push(value);
            }

        });
    }
    // 6. 去掉head中的colIndex
    _.each(head, (h, hi)=>{
        delete head[hi].colIndex;
    });

    // 7. 存在表达式排序时，对data进行重排----此时确定无keys的排序
    if(havExpSort) {
        data.sort(function(a, b){
            let r = 0, sort;
            for(var i = 0; i < head.length; i++) {
                sort = head[i].col.sort;
                head[i].type == 'group' && !sort && expHavOverlay ? sort = 'asc' : null;      //group强制排序的情况判断
                if(sort) {
                    sort == 'dec' ? sort = 'desc' : null;
                    if(head[i].type == 'value') {
                        r = getSortDeep(a[i], b[i], sort, false);   // !=0说明大小有差
                    } else {
                        r = getKeySort(a[i], b[i], sort);   // !=0说明大小有差
                    }
                    if(r != 0) {
                        break;
                    }
                }
            }
            return r;
        });
    }

    return { head: head, data: data };

    /**给定一个列表和一个元素，返回索引----标记col----用于单源数据集的列维和行维
     *
     * @param {Array} colList [{name, index}]
     * @param {Object} d {col/column}
     * @return {Object} colList[index]
     * */
    function getDimensionCol(colList, d, datasetType) {
        var col = _.find(colList, (c, i)=>{
            var column;
            if(datasetType == 'merge') {
                column = d.alias;                           //多源数据集的行维和列维使用alias
            } else {
                column = d.col || d.column;                 //BI图表的单源使用col，大屏图表市场使用column
            }
            return c.name == column && !c.aggType;          //维度无聚合方式
        });
        return col;
    }
    /**给定一个列表和一个元素，返回索引----标记col/column和aggregate_type
     *
     * @param {Array} colList [{name, index, aggType}]
     * @param {Object} d {col/column, aggregate_type}
     * @return {Object} colList[index]
     * */
    function getValueCol(colList, d) {
        var col = _.find(colList, (c, i)=>{
            var column = d.col || d.column;                             //BI图表的单源使用col，大屏图表市场使用column
            return c.name == column && c.aggType == d.aggregate_type;   //聚合方式一致
        });
        return col;
    }
}

class DataContextUtil {
    constructor() {}

    /**将后台获取的数据进行处理，得到图表需要的数据-------Bi图表----jx2021.06
     *
     * @param {Object} aggData
     * @param {Object} chartConfig
     * @param {String} datasetType 数据集的类型----single ，merge-----多源数据集较单源目前仅行维和列维的名称的键不同
     * @return {Object}
     *  {
     *      keys: [
     *          ['行维1的值1', '行维2的值1', '...'],
     *          '...',
     *          ['行维1的值2', '行维2的值2', '...'],
     *          '...'
     *      ],
     *      series:[
     *          ['列维1的值1', '列维2的值1', '...', '指标1的名称或别名'],
     *          ['列维1的值1', '列维2的值1', '...', '指标2的名称或别名'],
     *          '...',
     *          ['列维1的值2', '列维2的值2', '...', '指标1的名称或别名'],
     *          ['列维1的值2', '列维2的值2', '...', '指标2的名称或别名'],
     *          '...'
     *      ],
     *      data: [
     *          ['keys[0]对应的值', 'keys[1]对应的值', 'keys[2]对应的值', '...'],
     *          ['keys[0]对应的值', 'keys[1]对应的值', 'keys[2]对应的值', '...'],
     *          '...'
     *      ],
     *      seriesConfig: {
     *          '列维1的值1-列维2的值1...-指标1的名称或别名': {
     *              type: '指标所在指标组的类型',
     *              valueAxisIndex: '指标所在指标组的索引',
     *              formatter: '该指标的formatter'
     *          },
     *          '...': {}
     *      }
     *  }
     *
     * 1. 将后台数据处理为如下格式-----分离为通用方法aggDataToCommonData
     *
     * 2. 整合keys和groups, 标记values-----数组-------------keys初步生成，未排序
     *          ----生成无重复的数组，标记values，搜集排序信息----keys，groups，values的
     * 3. 对keys, groups进行排序
     *          ----keys-------------------------------groups存在时必须排
     *          ----groups-----------------------------可以不再排序
     *          ----依据values的排序对keys进行重排------groups存在时必须排
     * 4. 生成目标对象的属性---------------------series、data，seriesConfig
     *          ----data多系列的情况
     *              ----指标使用undefined填充key中的缺失值
     *              ----表达式分情况使用NaN、undefined和计算值填充key中的缺失值
     * 5. 处理values的过滤----限定值的范围top，限定每个系列数据的数量，排除的数据的归类为其它进行渲染
     *      ------------将所有数字数据格式化: ‘0.000000’, 0值取‘0’
     *
     * */
    aggDataToBIData = function (aggData, chartConfig, datasetType) {
        // 1.
        var tData = aggDataToCommonData(aggData, chartConfig, datasetType, {defaultAll: 1});

        // 2. 获取key和group的二维数组------无的情况都是一串空的二维数组，标记values，搜集排序信息
        var rGroups         = [],       //无重复的groups--------无时有一个空数组的元素
            signGroups      = {},       //标记group是否出现-----键名时group连接的字符串，boolean值
            rKeys           = [],       //无重复的keys----------无时有一个空数组的元素
            signKeys        = {},       //标记key是否出现-------键名时key连接的字符串，boolean值
            signData        = {},       //标记的values的数据----group值.指标name.key值：value值
            groupsSort      = [],       //groups的排序
            hasGroupsSort   = false,    //groups是否需要排序
            keysSort        = [],       //keys的排序
            hasKeysSort     = false,    //keys是否需要排序
            valueSort       = {};       //values的排序
        _.each(tData.data, (d, di)=>{
            var gName, kName, newGroup = [], newKey = []; //flag标记为第一次的指标
            _.each(d, (v, vi)=>{
                if (tData.head[vi].type == 'key') {
                    newKey.push(v);
                    if(di === 0) {
                        var sort = tData.head[vi].col.sort;
                        keysSort.push(sort);         // 第1组数据搜集排序信息
                        if(sort == 'asc' || sort == 'desc') {
                            hasKeysSort = true;
                        }
                    }
                    // 防止无指标的情况
                    if( (vi + 1) === d.length || ( tData.head[vi + 1] && tData.head[vi + 1].type != 'key' ) ) {
                        kName ? null : kName = newKey.join('-');
                    }
                } else if (tData.head[vi].type == 'group') {
                    newGroup.push(v);
                    if(di === 0) {
                        var sort = tData.head[vi].col.sort;
                        groupsSort.push(sort);       // 第1组数据搜集排序信息
                        if(sort == 'asc' || sort == 'desc') {
                            hasGroupsSort = true;
                        }
                    }
                    // 防止无指标的情况
                    if( (vi + 1) === d.length || ( tData.head[vi + 1] && tData.head[vi + 1].type != 'group' ) ) {
                        gName ? null : gName = newGroup.join('-');
                    }
                } else if (tData.head[vi].type == 'value') {
                    // 每行数据遇到指标，说明后面没有group和key了, ----即当前行的key和group已搜集完毕，可对values进行标记
                    gName ? null : gName = newGroup.join('-');
                    kName ? null : kName = newKey.join('-');
                    var vName = tData.head[vi].name;
                    // values的数据标记
                    util.isUndefined(signData[gName]) ? signData[gName] = {} : null;
                    util.isUndefined(signData[gName][vName]) ? signData[gName][vName] = {} : null;
                    signData[gName][vName][kName] = v;
                    if(di === 0) {                                      // 第1组数据搜集排序信息
                        var sort = tData.head[vi].col.sort;
                        if( sort == 'asc' || sort == 'desc' ) {
                            valueSort = {
                                sort: sort,
                                index: vi,
                                name: vName
                            };
                        }
                    }
                }
            });
            if(!signGroups[gName]) {
                rGroups.push(newGroup);
                signGroups[gName] = true;
            }
            if(!signKeys[kName]) {
                rKeys.push(newKey);
                signKeys[kName] = true;
            }
        });

        // 3.1 rKeys排序
        if(hasKeysSort) {
            rKeys.sort(function(a, b){
                var r = 0;  //返回的值
                for(let i = 0; i < keysSort.length; i++) {
                    if(keysSort[i]) {
                        r = getKeySort(a[i], b[i], keysSort[i]);
                        if(r != 0) {
                            break;
                        }
                    }
                }
                return r;
            });
        }
        // 3.2 rGroups排序
        if(hasGroupsSort) {
            rGroups.sort(function(a, b){
                var r = 0;  //返回的值
                for(let i = 0; i < groupsSort.length; i++) {
                    if(groupsSort[i]) {
                        r = getKeySort(a[i], b[i], groupsSort[i]);
                        if(r != 0) {
                            break;
                        }
                    }
                }
                return r;
            });
        }
        // 3.3 value的排序----rKeys的重排
        if(valueSort.sort) {
            // 插入需要排序的数字
            var valueSortArr = _.map(rKeys, (k, ki)=>{
                var kName = k.join('-');
                return {
                    key: k,
                    value: signData[rGroups[0].join('-')][valueSort.name][kName]
                };
            });
            // 排序
            valueSortArr = valueSortArr.sort(function(a, b){
                return getSortDeep(a.value, b.value, valueSort.sort, false);
            });
            // 回收keys
            rKeys = _.map(valueSortArr, (value)=>{
                return value.key;
            });
        }

        // 4. 生成剩余的series，data，seriesConfig
        var series = [], rData = [], seriesConfig = {};
        _.each(rGroups, (group, gi)=>{
            var gName = group.join('-');
            _.each(tData.head, (h, hi)=>{
                if(h.type == 'value') {
                    var vName = h.name;
                    series.push(group.concat(vName));
                    var sName = series[series.length - 1].join('-');
                    seriesConfig[sName] = {
                        type: h.series_type,
                        valueAxisIndex: h.valueAxisIndex,
                        formatter: h.col.formatter
                    };
                    rData[series.length - 1] = new Array(rKeys.length).fill(undefined);    //填充undefined----填充''会被转为0
                    _.each(rKeys, (key, ki)=>{
                        var keyName = key.join('-');
                        let value = signData[gName][vName][keyName];
                        // 表达式的情况----1.常量计算==>数字，2.单一聚合表达式==>undefined，3.聚合表达式计算==>NaN
                        if(h.col.type == 'exp' && value === undefined ) {
                            // 查找其中的聚合表达式
                            let result = h.col.exp.match(AGGREGATE_REG);
                            if( result ) {
                                // 2.的情况----不用改变
                                // 3.的情况----多个或1个聚合表达式但存在计算
                                if( result.length > 1 || ( result.length == 1 && result[0].length < h.col.exp.length ) ) {
                                    value = NaN;
                                }
                            } else {
                                // 1.的情况
                                let expValue = boreFormula.parser.parse(h.col.exp).result;
                                value = expValue === null ? NaN : expValue;
                            }
                        }
                        rData[series.length - 1][ki] = value;
                    });
                }
            });
        });

        /**
         * 5. 处理指标的过滤-------决定维度的数量
         *  ---指标的值范围限定
         *  ---保留维度的数量
         *  ---去掉的值形成其它项
         * */
        var otherArr = [],              //每个系列被去掉值的和组成的数组
            havKeyTop,                  //保留维度的数量, 即top--------取最小值
            setOther = false,           //是否需要形成其它项-----------取或
            setOtherAlias = '其它';     //其它项的别名----维度可能形成 '其它-其它'-----取最后一个指标设置的值
        // 5.1 获取Top数及是否需要形成其它项
        _.each(tData.head, (h, hi)=>{
            if(h.type == 'value') {
                var c = h.col;
                if( c.f_top && isFinite(c.f_top) && c.f_top > 0 ) {
                    if(havKeyTop === undefined) {
                        havKeyTop = Number(c.f_top);
                    } else {
                        c.f_top < havKeyTop ? havKeyTop = Number(c.f_top) : null;
                    }
                }
                c.f_keep_other ? setOther = true : null;
                c.f_other_alias ? setOtherAlias = c.f_other_alias : null;
            }
        });
        // 5.2 去掉不符合的维度项----指标的值不符合范围以及top
        for(var i = 0; i < rKeys.length; i++) {
            let s = 0,              //当前系列的索引
                f = false;          //是否需要去掉这个维度项
            _.each(rGroups, (g, gi)=>{
                if(f) {
                    return false;   //确定删除就停止循环
                }
                // 保留行维数
                if(havKeyTop && havKeyTop <= i ) {
                    f = true;
                }
                _.each(tData.head, (h, hi)=>{
                    if(h.type == 'value') {
                        if(f) {
                            return false;   //确定删除就停止循环
                        }
                        if( !(filter(h.col, rData[s][i])) ) {
                            f = true;
                        }
                        if(!f) {
                            // 对数值进行格式化处理-----保留6位小数-----字符串
                            var fixedValue = util.dataFormat(rData[s][i]);         //格式化数值
                            fixedValue === '0.000000' ? fixedValue = '0' : null;
                            rData[s][i] = fixedValue;
                        }
                        s++;       //列+col形成一个系列
                    }
                });
            });
            if(f) {
                rKeys.splice(i, 1);
                _.each(rData, (d, di)=>{
                    let dValue = d.splice(i, 1);
                    if(setOther) {
                        otherArr[di] || otherArr[di] === 0 ? null : otherArr[di] = 0;
                        if( isFinite(dValue) ) {
                            otherArr[di] += Number(dValue);
                        }
                    }
                });
                i--;
            }
        }
        // 5.3 形成其它项
        if(setOther) {
            _.each(rData, (d, di)=>{
                var fixedValue = util.dataFormat(otherArr[di]);         //格式化数值
                fixedValue === '0.000000' ? fixedValue = '0' : null;
                rData[di].push(fixedValue);
            });
            try {
                rKeys.push(new Array(rKeys[0].length).fill(setOtherAlias));
            } catch {

            }
        }

        return {
            keys: rKeys,
            series: series,
            data: rData,
            seriesConfig: seriesConfig
        };
    }

    /** 处理后台获取的数据----表达式类型的value获取值----chartmarket----jx2021.06
     *
     * @param  {Object} aggData 后台获取的数据
     * @param  {Object} chartConfig 数据集数据源的图表设置
     *    {
     *       keys: [
     *          { column: '', id: '', sort: '', type: '', alias: '', values:[] }
     *       ],
     *       values: [
     *          cols: [
     *              {type, column, aggregate_type, alias, exp, id, sort, dividend, symbol, mantissa }
     *          ]
     *       ],
     *       filters: [
     *          { group, filters },
     *          { column, type, values: []}
     *       ]
     *    }
     * @return {Object} {head: [{name: '', type: '', valuesIndex: 'num暂无'}],data:[[], [], ...]}
     *
     * 1. 获取数据-----------------分离为通用方法aggDataToCommonData
     * 2. value的数值换算----------1.中已换算
     * 3. 排序----keys和values-----1.中已排序
     * 4. 返回数据时，将head中的col、valueAxisIndex、series_type属性删除
     * */
    aggDataToChartMarketData = function(aggData, chartConfig, datasetType){
        // 1.
        var tData = aggDataToCommonData(aggData, chartConfig, datasetType, {defaultAll: 2, doValueCompute: true});
        // 2. values的数值转换----插入1中处理减少循环次数
        // _.each(tData.data, (d, di)=>{
        //     _.each(d, (v, vi)=>{
        //         if(tData.head(vi).type == 'value') {
        //             var col = tData.head[vi].col || {};
        //             if( !isNaN(parseFloat(v)) ) {
        //                 tData.data[di][vi] = Number( util.convertEchartUnit({symbol: col.symbol, dividend: col.dividend, mantissa: col.mantissa}, v, true, true) );
        //             }
        //         }
        //     });
        // });
        // // 3.1 排序1----keys----可以设置多个
        // tData.data.sort(function(a, b){
        //     var r = 0, sort;
        //     for (var j = 0; j < a.length; j++) {
        //         if(tData.head[j].type == 'key') {
        //             sort = tData.head[j].col.sort;
        //             sort == 'dec' ? sort = 'desc' : null;
        //             if (sort) {
        //                 r = getKeySort(a[j], b[j], sort);   // !=0说明大小有差
        //                 if(r != 0) {
        //                     break;
        //                 }
        //             }
        //         }
        //     }
        //     return r;
        // });
        // // 3.2 排序2----values----只能设置一个且设置后全部keys的sort置undefined, 与排序1不冲突
        // _.each(tData.head, (h, i)=>{
        //     if(h.type == 'value' && (h.col.sort == 'asc' || h.col.sort == 'dec') ) {
        //         var sort = h.col.sort;
        //         sort == 'dec' ? sort = 'desc' : null;
        //         // value排序
        //         tData.data.sort(function(a, b){
        //             return getSortDeep(a[i], b[i], sort, false);
        //         });
        //         return false;
        //     }
        // });
        // 4. 去掉头部中的部分信息
        _.each(tData.head, (h, i)=>{
            delete tData.head[i].col;
            delete tData.head[i].valueAxisIndex;
            delete tData.head[i].series_type;
        });
        return tData;
    }

    /**图表信息同步数据集-----将图表设置中的keys，groups，values的信息更新同步数据集
     *
     * @param {Object} dataset      数据集详情
     * @param {Object} chartConfig  图表配置
     * @param {Boolean} noReplaceExp 是否不替换表达式中的表达式
     * */
    organizeChartConfigByDataset = function (dataset, chartConfig, noReplaceExp) {
        if(!chartConfig || !dataset) {
            return;
        }
        dataset.data = JSON.parse(dataset.dataset.data);
        if(chartConfig.filters) {
            //link filters group----数据集设置的过滤器-----更新别名和内容
            util.each(chartConfig.filters, function (f) {
                if (f.group) {
                    var group = util.find(dataset.data.filters, function (e) {
                        return e.id == f.id;
                    });
                    if (group) {
                        f.filters = group.filters;
                        f.group = group.group;
                    }
                }
            });
        }
        if(chartConfig.values) {
            //link exp-----更新表达式的内容----id
            util.each(chartConfig.values, function (v) {
                util.each(v.cols, function (c) {
                    if (c.type == 'exp') {
                        var exp = util.find(dataset.data.expressions, function (e) {
                            return c.id == e.id;
                        });
                        if (exp) {
                            c.exp = exp.exp;
                            c.alias = exp.alias;
                        }
                        if(!noReplaceExp) {
                            // replace variable in exp
                            c.exp = replaceVariable(dataset.data.expressions, c);
                        }
                    } else if(c.type == 'column') {
                        var col = util.find(dataset.data.schema.measure, function(e){
                            return c.id == e.id;
                        });
                        if(col) {
                            c.alias = col.alias;
                        }
                    }
                });
            });
        }
        //link dimension
        if(chartConfig.keys) {
            util.each(chartConfig.keys, linkFunction);
        }
        if(chartConfig.groups) {
            util.each(chartConfig.groups, linkFunction);
        }

        return chartConfig;

        // 更新维度的别名和分组----id
        function linkFunction(k) {
            if (k.id) {
                var _level;
                var _dimension;
                util.each(dataset.data.schema.dimension, function (e) {
                    if (e.type == 'level') {
                        util.each(e.columns, function (c) {
                            if (c.id == k.id) {
                                _dimension = c;
                                _level = e;
                            }
                        });
                    } else if (k.id == e.id) {
                        _dimension = e;
                    }
                });
                if (_dimension && _dimension.alias) {
                    k.alias = _dimension.alias;
                    if (_level) {
                        k.level = _level.alias;
                    }
                }
            }
        }
    }

    /**图表values转换为一维数组 [{name, aggregate}]
     *
     * @param {Object} chartConfig 图表设置----取chartConfig.values进行处理
     *          {
     *              values: [
     *                  {
     *                      cols: [
     *                          {type: 'exp', alias, exp},
     *                          {type: 'column', col, aggregate_type}
     *                      ]
     *                  },
     *                  {
     *                      cols: [{}]
     *                  }
     *              ]
     *          }
     * @return {Array}
     * */
    parseDataSeries = function (chartConfig) {
        var result = [];
        util.each(chartConfig.values, function (v) {
            util.each(v.cols, function (c) {
                var series = configToDataSeries(c);
                util.each(series, function (s) {
                    if (!util.find(result, function (e) {
                        return JSON.stringify(e) == JSON.stringify(s);
                    })) {
                        result.push(s);
                    }
                });
            });
        });
        return result;

        function configToDataSeries(config) {
            switch (config.type) {
                case 'exp':
                    return getExpSeries(config.exp);
                default:
                    return [{
                        name: config.col || config.column,
                        aggregate: config.aggregate_type
                    }];
            }
        };
    };

    /** 行维，列维，过滤器  转换为后端接口的格式
     *
     * @param {Array} array [{col, type?, values?, id?}, {group, filters:[{col, type, values}]}]
     * @return {Array} [{columnName, filterType?, values?, id?}]
     * */
    getDimensionConfig = function (array) {
        var result = [];
        if (array) {
            util.each(array, function (e) {
                if (util.isUndefined(e.group)) {
                    result.push({
                        columnName: e.col || e.column,
                        filterType: e.type,
                        values: doFilterValues(e.values, e.type),
                        id: e.id
                    });
                } else {
                    util.each(e.filters, function (f) {
                        result.push({
                            columnName: f.col || f.column,
                            filterType: f.type,
                            values: doFilterValues(f.values, f.type)
                        });
                    });
                }
            });
        }
        return result;
        function doFilterValues(values, type){
            let rValues = [];
            if(values instanceof Array) {
                _.each(values, function(value, index){
                    value || value === 0 ? rValues.push(value): null;
                });
            }
            switch(type){
                case '>':
                case '<':
                case '≤':
                case '≥': rValues.length > 1 && (rValues.length = 1); break;
                case '[a,b]':
                case '[a,b)':
                case '(a,b]':
                case '(a,b)': rValues.length > 2 && (rValues.length = 2); break;
            }
            return rValues;
        }
    }

}

var dataContextUtil = new DataContextUtil();
export default dataContextUtil;

/**
 * @desc https://localforage.github.io/localForage/
 */
import localForage from 'localforage';
import { cloneDeep } from 'lodash';
import BaseService from './BasicService'
import { util } from '../common/utils/util'
class CacheService {
  constructor() {
    this.storage = localForage;
    this.server = new BaseService();
  }

  get(url, param, cache,  head, options) {
    if(cache && cache.isCache) {
      let key = util.hashCode(JSON.stringify(param));
      let params = {
        type:"get",
        url: url, param: param, head: head, options: options
      };
      let option = {
        key,
        cache
      };
      return this.cacheCall(params, option);
    } else {
      return this.server.get(url, param, head, options);
    }

  }
  getUseKey(key, url, param, cache, head, options) {
    if(cache && cache.isCache) {
      let params = {
        type: "get",
        url, param, head, options
      };
      let option = {
        key,
        cache
      };
      return this.cacheCall(params, option);
    } else {
      return this.server.get(url, param, head, options);
    }

  }

  postUseKey(key, url, param, cache, head, options) {
    if(cache && cache.isCache) {
      let params = {
        type: "post",
        url, param, head, options
      };
      let option = {
        key,
        cache
      };
      return this.cacheCall(params, option);
    } else {
      return this.server.post(url, param, head, options);
    }

  }

  post(url, param, cache, head, options) {
    if(cache && cache.isCache) {
      let key = util.hashCode(JSON.stringify(param));
      let params = {
        type: "post",
        url, param, head, options
      };
      let option = {
        key,
        cache
      };
      return this.cacheCall(params, option);
    } else {
      return this.server.post(url, param, head, options);
    }

  }

  // cacheCall(type,key, url, param, head, options) {
  cacheCall(req, option) {
    let key = null;
    let type = 'get';
    if (!_.isUndefined(req.type)) {
      type = req.type;
    }
    if (!_.isUndefined(option.key)) {
      key = option.key;
    } else {
      key = util.hashCode(JSON.stringify(req.param));
    }
    let _this = this;
    return new Promise((resolve, reject) => {
      //当缓存有效时
      // console.log(option.cache)
      _this.getItem(key, option.cache).then((cacheData) => {
        if (cacheData != null) {
          // console.log("using cahce ============================>")
          resolve(cacheData);
        } else { //当缓存无效时
          if (type == 'post') { //post 请求
            // console.log("no cahce ============================>")
            this.server.post(req.url, req.param, req.head, req.options).then(rdata => {
              _this.setItem(key, rdata, option.cache)
              resolve(rdata)
            }, err => {
              reject(err)
            })
          } else if (type == 'get') {//get 请求
            // console.log("no cahce ============================>")
            this.server.get(req.url, req.param, req.head, req.options).then(rdata => {
              _this.setItem(key, rdata, option.cache)
              resolve(rdata)
            }, err => {
              reject(err)
            })
          }
        }
      });
    })
    //return callback ;
  }

  //使用window全局变量
  post_window(url, param, cache, head, options) {
      let key = cache.type + util.hashCode(JSON.stringify(param));
      return new Promise((resolve, reject) => {
        let curTime = new Date().getTime();
        if(window[key] && ( window[key].expireTime + cache.expireTime ) > curTime ) {
          resolve(window[key].data);
        } else {
          this.server.post(url, param, head, options).then(res => {
            window[key] = {
              expireTime: curTime,
              data: res
            };
            resolve(res);
          })
        }
      });
  }
  getItem(key, cache) {
    let _this = this;
    let lKey = cache.type + key;
    return new Promise((resolve, reject) => {
      _this.storage.getItem(lKey).then(function (wdata) {
        if (null == wdata) { //没有数据
          resolve(null)
        }
        //两个有效时间取短的-----单位为s
        let expireTime = _this.timeFormat(cache.expireTime);
        expireTime = expireTime < wdata.expireTime ? expireTime : wdata.expireTime;
        //有数据  缓存时间是否超时
        let curTime = new Date().getTime();
        if (curTime - wdata.startTime > expireTime) {//缓存已经超时了
          _this.storage.removeItem(lKey)
          resolve(null);
        } else {
          resolve(wdata.data)
        }
      }).catch(function (err) {
        resolve(null)
      });
    })
  }

  setItem(key, value, option) {
    let startTime = new Date().getTime();//缓存开始时间
    let expireTime =60* 60 * 1000;
    if (!_.isUndefined(option.expireTime)) {
      expireTime = this.timeFormat(option.expireTime);
    }
    let warpedValue = {
      expireTime,
      startTime,
      data: cloneDeep(value)
    }
    let setKey = option.type + key;
    return this.storage.setItem(setKey, warpedValue);
  }

  remove(key) {
    return this.storage.removeItem(key);
  }

  clear() {
    return this.storage.clear();
  }

  //将cache的有效时间转为ms单位
  timeFormat(expireTime){
    if(isNaN(expireTime)){
      if(!expireTime) {
        return 0;
      }
      let num = isNaN(expireTime.split(",")[0]) ? 0 : Number(expireTime.split(",")[0]);
      let unit = expireTime.split(",")[1] ? expireTime.split(",")[1] : "秒";
      switch (unit) {
        case "秒" : return num * 1000;
        case "分" : return num * 1000 * 60;
        case "小时" : return num * 1000 * 60 * 60;
        case "天" : return num * 1000 * 60 * 60 * 24;
        default : return num *1000;
      }
    } else {
      return expireTime * 1000;
    }
  }


}

export const cacheService = new CacheService();

import icon_pic from './heatMapCalendar.png'
import icon_pic_active from './heatMapCalendar-active.png'
var config = {
    rule: {
        keys: 1,
        groups: 0,
        filters: -1,
        values: 1
    },
    info:{
        name: 'CONFIG.WIDGET.HEAT_MAP_CALENDER',
        value: 'heatMapCalendar',
        // class: 'cHeatMapCalendar',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_1',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_1'
    }
}
export default config;
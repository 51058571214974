import {behaviorLogService} from '../../../server'
import './_behaviorLog.scss'
import {util } from '../../../common/utils';

export default  function behaviorLogListCtrl( toastr,$scope, $state, ModalUtils ) {
    $scope.behaviorLogList =[];
    $scope.isLoading = true;
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 25,
        onChange: function () {
            $scope.getBehaviorLogList(); //重新加载
        }
    };
 
    $scope.params = {
        "pageNum": $scope.paginationConf.currentPage,
        "pageSize": $scope.paginationConf.itemsPerPage,
        // "module":$scope.module,
        // "function":$scope.function,
        // "startDate":$scope.startDate,
        // "endDate":$scope.endDate,
        // "createByName":$scope.createByName,
        // "device":$scope.device,
    }

    $scope.gotoDetail = function(id){
        $state.go('behaviorLogDetail', {id: id});
    }

     //获取列表
     $scope.getBehaviorLogList = function(){
        //  console.log("$scope.paginationConf.currentPage",$scope.paginationConf.currentPage)
        // $scope.params = {
        //     "pageNum": $scope.paginationConf.currentPage,
        //     "pageSize": $scope.paginationConf.itemsPerPage,
        //     "module":$scope.module,
        //     "function":$scope.function,
        //     "startDate":$scope.startDate,
        //     "endDate":$scope.endDate,
        //     "createByName":$scope.createByName,
        //     "device":$scope.device,
        // }
        $scope.params.pageNum = $scope.paginationConf.currentPage;
        $scope.params.pageSize = $scope.paginationConf.itemsPerPage;
        behaviorLogService.getBehaviorLogList($scope.params).then(function (response) {
            $scope.isLoading = false;
            $scope.behaviorLogList = response.listdata || [];
            $scope.paginationConf.totalItems = response.totolCount;
            $scope.$apply();
            // toastr.success("获取日志列表成功!");
        },err=>{
            toastr.error("获取日志列表失败!"+err.head.errorMsg)
        })
    }

    $scope.getModuleValue = function(value) {
	    if (value == "app")
	        return "应用";
	    if (value == "dataset")
	        return "数据集";
		if (value == "widget")
			return "图表";
		if (value == "board")
			return "看板";
        if (value == "user")
            return "用户设置";
        if (value == "dtp")
            return "数据节点";
        if (value == "report")
            return "报表";
	};

    // //删除
    // $scope.delBehaviorLog = function(data,index){
    //     ModalUtils.confirm("确认删除日志吗?", "modal-info", "lg", function () {
    //         behaviorLogService.delBehaviorLog({id:data.id}).then(function (response) {
    //             toastr.success("成功");
    //         },err=>{
    //             toastr.error(err.head.errorMsg)
    //         })
    //     });
    // }

}

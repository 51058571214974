import BasicService from './BasicService';
import {
  USERRESAPI,
  USERAPI,
  BOARDAPI
} from './ApiServer';
/**
 */
class UserAdminService extends BasicService {
  constructor() {
    super();
  }

  //获取已绑定微信的用户列表
  getBindUserIdByCompany(params) {
    return this.post(USERAPI.GET_BIND_USERID_BY_COMPANY, params);
  }

  //isAdmin
  isAdmin() {
    return this.get(USERRESAPI.ISADMIN);
  }

  //getUserList
  getUserList() {
    return this.get(USERAPI.GETUSERLIST);
  }

  //postUserList
  postUserList(params) {
    return this.post(USERAPI.GETUSERLIST, params);
  }

  postUsers(params) {
    return this.post(USERAPI.GETUSERS, params);
  }

  //getRoleList
  getRoleList() {
    return this.get(USERRESAPI.GETROLELIST);
  }

  //getUserRoleList
  getUserRoleList() {
    return this.get(USERRESAPI.GETUSERROLELIST);
  }

  //getBindUserIdByCompany?type=1
  getBindUserIdByCompany(options) {
    return this.get(USERAPI.GETBINDUSERIDBYCOMPANY + `?${options}`);
  }

  //getBoardList
  getBoardList() {
    return this.get(BOARDAPI.BOARDLIST);
  }

  //getCategoryList
  getCategoryList() {
    return this.get(BOARDAPI.BOARDCATEGORY);
  }

  //getMenuList
  getMenuList() {
    return this.get(USERRESAPI.GETMENULIST);
  }

  //getDatasourceList
  getDatasourceList(params) {
    return this.post(USERRESAPI.GETDATASOURCELIST, params);
  }

  //getDatasetList
  getDatasetList(params) {
    return this.post(USERRESAPI.GETDATASETLIST, params);
  }

  //getWidgetList
  getWidgetList() {
    return this.get(USERRESAPI.GETWIDGETLIST);
  }

  //getJobList
  getJobList() {
    return this.get(USERRESAPI.GETJOBLIST);
  }

  //getRoleResList
  getRoleResList() {
    return this.get(USERRESAPI.GETROLERESLIST);
  }

  //saveNewUser
  saveNewUser(params) {
    return this.post(USERRESAPI.SAVENEWUSER, params);
  }

  //updateUserRole
  updateUserRole(params) {
    return this.post(USERRESAPI.UPDATEUSERROLE, params);
  }

  //editUser
  editUser(params) {
    return this.post(USERRESAPI.EDITUSER, params);
  }

  //checkExistRoleName
  checkExistRoleName(params) {
    return this.post(USERRESAPI.CHECKEXISTROLENAME, params);
  }

  //saveRole
  saveRole(params) {
    return this.post(USERRESAPI.SAVEROLE, params);
  }

  //updateRole
  updateRole(params) {
    return this.post(USERRESAPI.UPDATEROLE);
  }

  //updateRoleUser
  updateRoleUser(params) {
    return this.post(USERRESAPI.UPDATEROLEUSER, params);
  }

  //deleteUserRole
  deleteUserRole(params) {
    return this.post(USERRESAPI.DELETEUSERROLE, params);
  }

  //updateRoleRes
  updateRoleRes(params) {
    return this.post(USERRESAPI.UPDATEROLERES, params);
  }

  //deleteRole
  deleteRole(params) {
    return this.post(USERRESAPI.DELETEROLE, params);
  }

  //deleteUser
  deleteUser(params) {
    return this.post(USERRESAPI.DELETEUSER,params);
  }

  //getRoleResList
  getUserRoleListById(params) {
    return this.post(USERRESAPI.GETUSERROLELISTBYID, params);
  }

  saveUserRole(params) {
    return this.post(USERRESAPI.SAVEUSERROLE, params);
  }

  batchDeleteUserRole(params) {
    return this.post(USERRESAPI.BATCHDELETEUSERROLE, params);
  }

  getUserByUserIds(params) {
    return this.post(USERRESAPI.GETUSERBYUSERIDS, params);
  }

  attUploadImage(formData) {
    return this.post_file(USERRESAPI.ATTUPLOADIMAGE,formData,false,()=>{});
  }

}

export const userAdminService = new UserAdminService();

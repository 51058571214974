/**
 * mySetToolSelectExpSearch
 *
 * boreDataTitle: 输入框前面的说明文---限制5个字
 * boreDataTip: 移入显示说明文---tip
 * boreDataSelectsExp: 对象（name和value两组键值对）组成的数组
 * boreDataSelectsKey: 显示和传值的关键字，之间用','隔开
 * boreDataSelectsInit: 初始化，同上
 * boreDataSelectChange: 传递的方法-----可行？---传的是表达式
 * boreDataSelected: 选中的值
 */

export default function mySetToolSelectExpSearch(){

    return {
        restrict: 'EA',
        template: ` <div class="bore-set-tool">
                        <div class="bore-set-tool-title">{{boreDataTitle}}</div>
                        <div class="bore-set-tool-content">
                            <div class="bore-set-tool-select">
                                <input type="text" ng-model="searchName" class="search-box" ng-class="{'show':show}" placeholder="{{showSelected}}"/>
                                <div class="selected-box" ng-class="{'show':show}" ng-click="selecting($event); $event.preventDefault()">{{showSelected}}</div>
                                <ul class="select-list" ng-show="show">
                                    <li ng-click="checked(); $event.preventDefault()" ng-class="{'selected':initValue==boreDataSelected}" ng-show="!includeInit">
                                        {{initName}}
                                    </li>
                                    <li ng-repeat="item in selects track by $index" ng-click="checked($index); $event.preventDefault()" ng-class="{'selected':item[value]==boreDataSelected}">
                                        {{item[name]}}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="bore-set-tool-tip" ng-class="{'show':boreDataTip?true:false}">
                            <span>?</span>
                            <span class="tip-content">{{boreDataTip}}</span>
                        </div>
                    </div>`,
        replace: true,
        scope: {
            boreDataSelected: '=',
            boreDataSelectsExp: '=',
            boreDataSelectChange:'='
        },
        link: function(scope, element, attrs) {
            //关键字
            scope.name = attrs.boreDataSelectsKey.split(",")[0];
            scope.value = attrs.boreDataSelectsKey.split(",")[1];
            scope.initName = attrs.boreDataSelectsInit.split(",")[0];
            scope.initValue = attrs.boreDataSelectsInit.split(",")[1] === "" ? undefined : attrs.boreDataSelectsInit.split(",")[1];
            scope.boreDataTitle = attrs.boreDataTitle ? attrs.boreDataTitle : "";
            scope.boreDataTip = attrs.boreDataTip ? attrs.boreDataTip : "";
            scope.searchName = "";          //搜索关键词

            scope.selects = [];             //指令的选择列表

            //初始化
            function initSelected(){
                scope.boreDataSelected = scope.boreDataSelected == undefined ? scope.initValue : scope.boreDataSelected;

                //判断init是否在列表中--是否需要添加一行，默认不在即需要
                scope.includeInit = false;  //默认不在

                //判断当前应当展示的name------列表可能还没有拿到数据为undefined
                var i = 0, len = scope.boreDataSelectsExp instanceof Array ? scope.boreDataSelectsExp.length : 0;
                for(; i < len; i++) {
                    if(scope.boreDataSelected == scope.boreDataSelectsExp[i][scope.value]){
                        scope.showSelected = scope.boreDataSelectsExp[i][scope.name];
                        break;
                    }
                    if(scope.initName == scope.boreDataSelectsExp[i][scope.name]) {
                        scope.includeInit = true;
                        scope.initValue = scope.boreDataSelectsExp[i][scope.value];
                    }
                }
                if(i < len){
                    //继续排查init是否在其中
                    for(; i < len; i++) {
                        if(scope.initName == scope.boreDataSelectsExp[i][scope.name]) {
                            scope.includeInit = true;
                            scope.initValue = scope.boreDataSelectsExp[i][scope.value];
                        }
                    }
                } else {
                    scope.showSelected = scope.initName;
                }

                //如果存在选中值的方法，初始化应该执行一次
                if(scope.boreDataSelectChange !== undefined){
                    scope.boreDataSelectChange();
                }
                //将指令的选择列表指向传进来的数组-----searchName的值为"";
                scope.selects = scope.boreDataSelectsExp;
            }

            initSelected();

            scope.show = false;
            //开始/取消选择的方法--点击下拉框
            scope.selecting = function($event){
                scope.show = !scope.show;
                if(scope.show){
                    $($event.target).prev()[0].focus();
                } else {
                    scope.searchName = "";
                }
            };

            //选中的方法
            scope.checked = function(index){
                if(index === undefined) {
                    scope.boreDataSelected = scope.initValue;
                } else {
                    scope.boreDataSelected = scope.selects[index][scope.value];
                }
                scope.show = false;
                scope.searchName = "";
            }

            //监视列表是否变化--存在变化重新初始化
            scope.$watch('boreDataSelectsExp', function(){
                initSelected();
            }, true);
            //监视选中值的变化，改变showSelected---加载时选中值较列表晚加载的情况
            scope.$watch('boreDataSelected', function(){

                var i = 0, len = scope.boreDataSelectsExp instanceof Array ? scope.boreDataSelectsExp.length : 0;
                for(; i < len; i++) {
                    if(scope.boreDataSelected == scope.boreDataSelectsExp[i][scope.value]){
                        scope.showSelected = scope.boreDataSelectsExp[i][scope.name];
                        break;
                    }
                }
                if(i >= len) {
                    scope.showSelected = scope.initName;
                }
                //选中值改变的方法只有在监视器里才能符合预期，为什么？顺序？
                if(scope.boreDataSelectChange !== undefined){
                    scope.boreDataSelectChange();
                }
            });


            //搜索功能
            scope.$watch("searchName", function(){
                scope.selects = [];
                if(scope.searchName === "") {
                    scope.selects = scope.boreDataSelectsExp;
                } else {
                    for(var i = 0; i< scope.boreDataSelectsExp.length; i++){
                        if(scope.boreDataSelectsExp[i][scope.name].indexOf(scope.searchName) != -1){
                            scope.selects.push(scope.boreDataSelectsExp[i]);
                        }
                    }
                }
            });
        }
    };
}

/**
 * Created by  jaray  2018-11-28
 */
// import alerttpl from './modaltpl/alert.html'
import windowtpl from './modaltpl/window.html'
// import charttpl from './modaltpl/chart.html'
import confirmtpl from './modaltpl/confirm.html'
import informationtpl from './modaltpl/information.html'

export default function ModalUtils($uibModal, $filter, $timeout,toastr ) {

    // var translate = $filter('translate');
    this.success = function (content, style, size, callback, autoCloseFlag) {
        toastr.success(content);
    }
    this.alert = function (content, style, size, callback, autoCloseFlag) {
        toastr.error(content);
        // $uibModal.open({
        //     template: alerttpl,
        //     windowTemplate: windowtpl,
        //     backdrop: false,
        //     windowClass: style,
        //     size: size,
        //     controller: ['$scope', '$uibModalInstance',function ($scope, $uibModalInstance) {
        //         var emptyBody = '系统异常';
        //         if (content instanceof Object) {
        //             content.title ? $scope.title = content.title : $scope.title = translate('COMMON.TIP');
        //             $scope.content = content.body ? content.body : emptyBody;
        //         } else {
        //             $scope.title = translate('COMMON.TIP');
        //             $scope.content = content ? content : emptyBody;
        //         }
        //         $scope.ok = function () {
        //             $uibModalInstance.close();
        //             if (callback) {
        //                 callback();
        //             }
        //         };
        //         if (style == "modal-success") {
        //             $timeout(function () {
        //                 $uibModalInstance.close();
        //             }, 1300);
        //         }
        //     }]
        // });
    };

    this.confirm = function (content, style, size, ok, close) {
        $uibModal.open({
            template: confirmtpl,
            windowTemplate: windowtpl,
            backdrop: false,
            windowClass: style,
            size: size,
            controller: ['$scope', '$uibModalInstance',function ($scope, $uibModalInstance) {
                content ? $scope.content = content : $scope.content = '系统异常';
                $scope.ok = function () {
                    $uibModalInstance.close();
                    if (ok) {
                        ok();
                    }
                };
                $scope.close = function () {
                    $uibModalInstance.close();
                    if (close) {
                        close();
                    }
                };
            }]
        });
    };

    this.info = function (content, style, size, ok, close) {
        $uibModal.open({
            template: informationtpl,
            windowTemplate: windowtpl,
            backdrop: false,
            windowClass: style,
            size: size,
            controller: ['$scope', '$uibModalInstance',function ($scope, $uibModalInstance) {
                content ? $scope.content = content : $scope.content = '系统异常';
                $scope.ok = function () {
                    $uibModalInstance.close();
                    if (ok) {
                        ok();
                    }
                };
                $scope.close = function () {
                    $uibModalInstance.close();
                    if (close) {
                        close();
                    }
                };
            }]
        });
    };
    this.showMsg = function (title, content) {
        $uibModal.open({
            animation: true,
            template: require('./modaltpl/basicModal.html'),
            size: 'md',
            resolve: {
                content: function () {
                    return content;
                },
                title: function () {
                    return title;
                }
            },
            controller:['$scope', 'title', 'content', function ($scope, title, content) {
                $scope.content =content  ;
                $scope.title = title;
            }]
        });
    };
    this.showFilter= function (widget) {
        $uibModal.open({
            animation: true,
            template: require('./modaltpl/filterModal.html'),
            size: 'md',
            resolve: {
                widget: function () {
                    return widget;
                },
            },
            controller: ['$scope', 'widget',function ($scope, widget ) {
                $scope.widget = widget;
            }]
        });
    };
} 
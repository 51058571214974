import archivesListTpl    from './archivesList.html';
import archivesListCtrl   from './archivesListCtrl';
import archivesDetailCtrl from './archivesDetailCtrl';
import archivesDetailTpl  from './archivesDetail.html';
require('./_archives.scss');
export default angular.module('borepc.pages.archives', [])
.service('archivesDetailModal', ['$uibModal', function archivesDetailModal($uibModal){
  this.openDetail = function(archives, func_ok){
    $uibModal.open({
      animation: true,
      template: archivesDetailTpl,
      controller: ['$scope', '$uibModalInstance', '$timeout', 'toastr', 'archives', 'ok', archivesDetailCtrl],
      backdrop: false,
      size: 'lg',
      windowClass: 'bore-archives-detail-modal',
      resolve: {
        ok: function () {
          return func_ok;
        },
        archives: function () {
          return archives;
        }
      }
    });
  }
}])
.controller('archivesListCtrl', ['$scope', 'toastr', 'archivesDetailModal', archivesListCtrl])
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('archives', {
      url: '/archives',
      title: 'archives',
      template: archivesListTpl,
      title: '归档列表',
      controller: 'archivesListCtrl',
    });
}


import BasicService from './BasicService';
import { ACCOUNTCENTERAPI } from './ApiServer';
/**
 */
class AccountCenterService extends BasicService {
    constructor () {
        super('/api/common');
    }

    saveAccount (params) {
        return this.post(ACCOUNTCENTERAPI.SAVE, params)
    }
}

export const accountCenterService = new AccountCenterService();
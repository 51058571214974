import {prewarningService,userService,userAdminService} from '../../../server'
import prewarningEditTpl from './prewarningEdit.html'
import prewarningEditCtrl from './prewarningEditCtrl'
import windowTpl from '../../../common/theme/components/modal/window.html'
import './_prewarning.scss'

export default  function prewarningListCtrl( ModalUtils,$scope, $state,$uibModal,toastr) {
    $scope.pwList =[];
    $scope.isLoading = true; 
    $scope.search = {};
    $scope.orderBy = [];
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 18,
        onChange: function () {
            $scope.getPwList(); //重新加载
        }
    };

    //获取用户信息
    userAdminService.postUserList({pageNo:1,pageSize:999}).then(function (response) {
        $scope.userList = response.listdata;
        $scope.$apply();
    });


    $scope.savePrewarning = function(data){
        prewarningService.savePrewarning({pwJson:angular.toJson(data)}).then(function (response) {
            if(response.status == '1'){
                toastr.success("成功");
                $scope.getPwList();
                $scope.$apply();
            }else{
                $scope.isLoading =false;
                toastr.error(response.msg || "保存预警失败");
            }
        })
    }

    $scope.getSort = function(column){
        var orderColumn = _.find($scope.orderBy,function(e){
            if(e.orderField==column)return e.orderType;
        })
        if(!orderColumn){
          return "sorting"; 
        }else{
          return "sorting_"+orderColumn.orderType;
        }
    }

    $scope.sortColumn =function(column){
        var orderColumn = _.find($scope.orderBy,function(e){
            if(e.orderField==column)return e.orderType;
        })
        if(!orderColumn) {
            $scope.orderBy.push({orderField:column,orderType:'asc'});
        }else{
            $scope.orderBy = _.filter($scope.orderBy,function(e){
                if(e.orderField==column && e.orderType=='asc'){
                    e.orderType='desc'
                    return e;
                }else if(e.orderField!=column){
                    return e;
                }
            })
        }
        $scope.getPwList();
    }
    

     //获取预警列表
     $scope.getPwList = function(){
        var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "search":$scope.search.value,
            "orderBy":JSON.stringify($scope.orderBy)
        }
        prewarningService.getPwList(params).then(function (response) {
            if(response.status=='1'){
                $scope.pwList = response.data.listdata || [];
                if($scope.pwList.length >0){
                    angular.forEach($scope.pwList,function(pwData){
                        pwData.frequency =angular.fromJson(pwData.frequency);
                        pwData.rule =angular.fromJson(pwData.rule);
                        pwData.target =angular.fromJson(pwData.target);
                        pwData.userIds =angular.fromJson(pwData.userIds);
                    })
                }
                $scope.paginationConf.totalItems = response.data.totolCount;
            }else{
                toastr.error("获取预警列表失败");
            }
            $scope.$apply();
            $scope.isLoading = false;
        });
    }

    $scope.gotoWidget = function(){
        ModalUtils.confirm("将离开预警页面前往图表页面，确定吗?", "modal-info", "lg", function () {
            $state.go("widgetList");
        })
    }

    $scope.updateWarnStatus =function(prewarning,status){
        var params = angular.copy(prewarning);
        params.status = status == '1'?'0':'1';
        prewarningService.updateWarnStatus({pwJson:angular.toJson(params)}).then(function (response) {
            if(response.status != '1'){
                toastr.error(response.msg ||"预警更新失败,请检查您的配置!");
                return;
            }
            $scope.getPwList();
            toastr.success("更新预警状态成功！");
        })
    }
    
    //删除预警
    $scope.delPrewarning = function(prewarning){
        ModalUtils.confirm("确认删除"+prewarning.name+"吗?", "modal-info", "lg", function () {            
            prewarningService.delPrewarning({pwJson:angular.toJson(prewarning)}).then(function (response) {
            	 if(response.status != '1'){
                    toastr.error(response.msg ||"预警删除失败,请检查您的配置!");
                     return;
                 }
                 $scope.getPwList();
                 toastr.success("预警删除成功！");
            })        
        });
    }

    //编辑预警
    $scope.editPrewarning = function (pwData,index) {       
        $uibModal.open({
            template: prewarningEditTpl,
            windowTemplate:windowTpl,
            size: 'lg',
            windowClass: 'pw-modal',
            resolve: {
                ok: function () {
                    return function (data) {
                        // $scope.pwList.splice(index,1,data);
                        $scope.isLoading = true;
                        prewarningService.savePrewarning({pwJson:angular.toJson(data)}).then(function (response) {
                            if(response.status == '1'){
                                toastr.success("成功");
                                $scope.getPwList();
                            }else{
                                $scope.isLoading =false;
                                toastr.error(response.msg ||"编辑预警失败");
                            }
                        })
                    }
                },
                prewarning:function(){
                    return pwData;
                }, 
                originType:function(){
                    return "warnEdit"
                },
                userList:function(){
                    return $scope.userList;
                }       
            },
            controller: ['$scope', '$uibModal', 'ok', 'userList', '$uibModalInstance', 'originType', 'ModalUtils',  'prewarning',prewarningEditCtrl]
        });
    };

    //接收窗口传递的参数
    // uibModal.result.then(function (result) {
    //     //单纯的关闭窗口不需要,重新给list赋值,否则会导致页面显示闪烁问题
    //     if (result && result.isEdit) {
    //         $scope.dtpManagerList = result.dtpManagerList;
    //         $scope.paginationConf.totalItems = result.totalItems;
    //     }
    // });
    
}

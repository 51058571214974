import dataApp from './dataApp.html'
import dataAppCtrl from './dataAppCtrl'
import './dataApp.scss'

import  qrcode from  'qrcode-generator';
import ngQrcode from 'angular-qrcode';
// hacks for the browser
// if using webpack there is a better solution below
window.qrcode = qrcode;
require('qrcode-generator/qrcode_UTF8');



export default angular.module('borepc.pages.dataApp',  [ngQrcode])
    .controller('dataAppCtrl', ['$scope', 'ModalUtils', '$uibModal','$window','toastr',dataAppCtrl])
     .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('dataApp', {
            url: '/dataApp',
            title: '应用',
            controller: 'dataAppCtrl',
            template: dataApp
        })
}
import { modelCenterService } from '../../../server'
import './modelCenter.scss'
import modelBuyCtrl from './modelBuyCtrl'
export default function ($scope, $sce,  $uibModal, ModalUtils) {
    $scope.title;
    $scope.dataType;
    $scope.isLoading = true;
    
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 12,
        onChange: function () {
            $scope.getMarketList();//重新加载
        }
    };

    //获取数据模型列表
    $scope.getMarketList = function () {
        $scope.isLoading = true;
        var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "title": $scope.title,
            "dataType": $scope.dataType
        }
        // $http.post("api/odm/dm/getDataMarketList", params)
        // axios.post("api/odm/dm/getDataMarketList",params).then( res => {
        //     console.log(res)
        // })
        modelCenterService.getDataMarkerList(params).then(function (response) {
            if (response.head && response.head.status != '1') {
                ModalUtils.alert(response.head.errorMsg, "modal-warning", "lg");
            } else {
                $scope.dataMarketList = response.listdata;
                $scope.paginationConf.totalItems = response.totolCount;
                $scope.isLoading = false;
            }
            $scope.$apply();
        })
    }

    //前往购买页面
    $scope.gotoBuyDataMarket = function (dataMarket) {
        $uibModal.open({
            template: require('./modelBuy.html'),
            backdrop: false,
            windowClass: 'buy-model-class',
            size: 'lg',
            controller: ['$rootScope', 'dmData', 'isOrder', '$scope', '$uibModalInstance', '$filter','ModalUtils', '$state',modelBuyCtrl],
            resolve:{
                dmData:dataMarket,
                isOrder:false
            }
        })
    }


    //获取数据模型详情
    $scope.gotoDetail = function (dataMarket) {
        
        $uibModal.open({
            template: require('./modelDetail.html'),
            backdrop: false,
            windowClass: 'modal-class',
            size: 'lg',
            controller: ['$scope', '$uibModalInstance',function ($scope, $uibModalInstance) {
                var description = dataMarket.description;
                $scope.dataMarket = dataMarket;
                //渲染页面
                $scope.dataMarket.description = $sce.trustAsHtml(description)
                $scope.close = function () {
                    $uibModalInstance.close();
                    $scope.dataMarket.description = description;//重置，防止再次进入详情页时二次渲染
                };
                $scope.gotoBuyMarket = function (dataMarket) {
                    $scope.close();
                    $uibModal.open({
                        template: require('./modelBuy.html'),
                        backdrop: false,
                        windowClass: 'buy-model-class',
                        size: 'lg',
                        controller: ['$rootScope', 'dmData', 'isOrder', '$scope', '$uibModalInstance', '$filter','ModalUtils', '$state',modelBuyCtrl],
                        resolve:{
                            dmData:dataMarket,
                            isOrder:false
                        }
                    })
                }
            }]
        });
    }
};
import icon_pic from './wordCloud.png'
import icon_pic_active from './wordCloud-active.png'
var config = {
    rule: {
        keys: 2,
        groups: 0,
        filters: -1,
        values: 1
    },
    info:{
        name: 'CONFIG.WIDGET.WORD_CLOUD',
        value: 'wordCloud',
        // class: 'cWordCloud',
        icon: {
            icon: icon_pic,
            icon_active: icon_pic_active
        },
        row: 'CONFIG.WIDGET.TIPS_DIM_NUM_1_MORE',
        column: 'CONFIG.WIDGET.TIPS_DIM_NUM_0',
        measure: 'CONFIG.WIDGET.TIPS_DIM_NUM_1'
    }
}
export default config;
import userAdmin from './userAdmin.html'
import userAdminCtrl from './userAdminCtrl'

export default angular.module('borepc.pages.userAdmin', [])
    .controller('userAdminCtrl', ['$scope', 'ModalUtils', '$filter','toastr',userAdminCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('userAdmin', {
            url: '/userAdmin',
            title: '用户管理',
            controller: 'userAdminCtrl',
            template: userAdmin
        })
}
import * as util  from  '../util/util'
var KpiRenderEngine = function (jqContainer, options) {
    this.container = jqContainer; // jquery object
    this.options = options;
};

KpiRenderEngine.prototype.html = function (persist) {
    var self = this;
    var temp = "" + self.template;
    var html  =util.StringRender(temp,self.options)
    if (persist) {
        setTimeout(function () {
            self.container.css('background', '#fff');
            html2canvas(self.container, {
                onrendered: function (canvas) {
                    persist.data = canvas.toDataURL("image/jpeg");
                    persist.type = "jpg";
                    persist.widgetType = "kpi";
                }
            });
        }, 1000);
        // persist.data = {name: self.options.kpiName, value: self.options.kpiValue};
        // persist.type = "kpi";
    }
    return html;
};

KpiRenderEngine.prototype.realTimeTicket = function () {
    var self = this;
    return function (o) {
        var htmlStr = "<span style='vertical-align: middle;'>" + o.kpiValue.value + "</span><span style='vertical-align: middle; font-size:" + o.kpiValue.unitFontSize + ";'>" + o.kpiValue.unit + "</span>";
        // $(self.container).find('h3').html(o.kpiValue);
        $(self.container).find('h3').html(htmlStr);
    }
};

KpiRenderEngine.prototype.do = function () {
    var self = this;
    $(self.container).html(self.rendered());
};
{/* <div class='kpi_handleClick'>
                <span name='reload_{{widget.widget.id}}' ng-click='reload(widget)' style='cursor: pointer;margin-right:.5em'><i class='fa fa-refresh'></i> {refresh}</span>
                <span ng-click='config(widget)' ng-if='widgetCfg' style='cursor: pointer'><span class='iconfont icon-icon_edit_kanban' style='font-size:1em!important; line-height:unset!important'></span> {edit}</span>
            </div> */}
// KpiRenderEngine.prototype.template =
// `<div class='small-box {style} kpi_container' style='text-align: left'>
//     <div class="kpi_bgImg kpi_bgStyle">
//         <div class="kpi_header_container">
//             <div class="kpi_name" title='{kpiName}'>{kpiName}</div>
//         </div>
//         <div class='inner' style="padding:0 1.5em 2em 1.5em">
//             <h3 style='color:#fff; text-align:center; font-size: 2.5em'>
//                 <span style="vertical-align: middle; ">{kpiValue.value}</span><span style="vertical-align: middle; font-size: {kpiValue.unitFontSize};" >{kpiValue.unit}</span>
//             </h3>
//         </div>
//     </div>
// </div>`;
KpiRenderEngine.prototype.template =`
    <div class='kpi_container new_kpi_container {style}' style='text-align: left'>
        <div class="kpi_bgImg kpi_bgStyle new_kpi_content">
            <div class="kpi_header_container" style="display: {hasKpiName}">
                <div class="kpi_name kpi1_name" title='{kpiName}'>{kpiName}</div>
            </div>
            <div class='inner {bodyClass}' style="padding:0 1.5em 2em 1.5em; font-size: {valueScale}">
                <h3 style='text-align:center; font-size: 2.5em' class="new_kpi_value new_kpi1_value">
                    <span style="vertical-align: middle; ">{kpiValue.value}</span><span style="vertical-align: middle; font-size: {kpiValue.unitFontSize};" >{kpiValue.unit}</span>
                </h3>
            </div>
        </div>
    </div>`;


export default  KpiRenderEngine;

import dataSupport from './dataSupport.html'
import dataSupportCtrl  from './dataSupportCtrl'
  
export default angular.module('borepc.pages.dataSupport', [])
.controller('dataSupportCtrl', ['$scope', 'ModalUtils',dataSupportCtrl])
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('dataSupport', {
      url: '/dataSupport',
      title: 'dataSupport',
      template: dataSupport,
      controller: 'dataSupportCtrl',
    })
}

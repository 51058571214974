import { util } from '../../../common/utils/util'
const phone = { 
    version: '3.1',
    img: '',//'http://cdn.h5ds.cn/static/images/img-null.png', // 主图
    desc: '', // 描述
    name: '标题', // 标题名称
    type: 'phone', // h5类型
    slider: {
        // 翻页设置
        speed: 0.5, // 翻页动画速度
        effect: 'slide', // 翻页动画
        autoplay: false, // 是否自动翻页
        time: 5 // 自动翻页时间
    },
    style: {
        // body的样式
        width: 320,
        height: 514
    },
    fixeds: [
        // 浮动层
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '浮动层上',
            style: {
                width: 320,
                height: 514
            },
            layers: []
        },
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '浮动层下',
            style: {
                width:320,
                height:514
            },
            layers: []
        }
    ],
    popups: [],
    pages: [
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '空白页面',
            desc: '页面描述',
            style: {
                width: 320,
                height: 514
            },
            layers: [],
            slider: {
                animate: 1,
                autoplay: false,
                lock: false,
                time: 5
            }
        }
    ]
}
const ppt = {
    version: '3.1',
    img: '',//'http://cdn.h5ds.cn/static/images/img-null.png', // 主图
    desc: '', // 描述
    name: '标题', // 标题名称
    type: 'ppt', // h5类型
    slider: {
        // 翻页设置
        speed: 0.5, // 翻页动画速度
        effect: 'slide', // 翻页动画
        autoplay: false, // 是否自动翻页
        time: 5 // 自动翻页时间
    },
    style: {
        // body的样式
        width: 1280,
        height: 720
    },
    fixeds: [
        // 浮动层
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '浮动层上',
            style: {
                width: 1280,
                height: 720
            },
            layers: []
        },
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '浮动层下',
            style: {
                width: 1280,
                height: 720
            },
            layers: []
        }
    ],
    popups: [],
    pages: [
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '空白页面',
            desc: '页面描述',
            style: {
                width: 1280,
                height: 720
            },
            layers: [],
            slider: {
                animate: 1,
                autoplay: false,
                lock: false,
                time: 5
            }
        }
    ]
}
const pc = {
    version: '3.1',
    img: '',
    desc: '', // 描述
    name: '标题', // 标题名称
    type: 'pc', // h5类型
    slider: {
        // 翻页设置
        speed: 0.5, // 翻页动画速度
        effect: 'slide', // 翻页动画
        autoplay: false, // 是否自动翻页
        time: 5 // 自动翻页时间
    },
    style: {

        width: 800,
        height: 600
    },
    fixeds: [
        // 浮动层
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '浮动层上',
            style: {
                width: 800,
                height: 600
            },
            layers: []
        },
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '浮动层下',
            style: {
                width: 800,
                height: 600
            },
            layers: []
        }
    ],
    popups: [],
    pages: [
        {
            id: null,
            className: null,
            keyid: util.randomID(),
            name: '空白页面',
            desc: '页面描述',
            style: {
                width: 800,
                height: 600
            },
            layers: [],
            slider: {
                animate: 1,
                autoplay: false,
                lock: false,
                time: 5
            }
        }
    ]
}

export { phone, ppt, pc }
import borePropertyTpl from './boreProperty.html'
import borePropertyCtrl  from './borePropertyCtrl'
  
export default angular.module('borepc.pages.boreProperty', [])
.controller('borePropertyCtrl', ['ModalUtils','toastr','$scope','$uibModal',borePropertyCtrl])
.config(['$stateProvider',routeConfig]).name ;

function routeConfig($stateProvider) {
  $stateProvider
    .state('boreProperty', {
      url: '/boreProperty',
      title: '变量设置',
      template: borePropertyTpl,
      controller: 'borePropertyCtrl',
    })
}


import {collectionService,userService} from '../../../server'
// import './_report.scss'

export default  function collectionCtrl($scope, toastr,  ModalUtils,   $filter, $state ) {
	var translate = $filter('translate');
	$scope.isLoading = true;
	// currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
	$scope.paginationConf = {
		currentPage: 1,
		totalItems: 0,
		itemsPerPage: 18,
		onChange: function () {
			$scope.getDataList(); //重新加载
		}
	};
	// $scope.getDataList();
	 //获取我的收藏列表
	 $scope.getDataList = function(collectionName){
		var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "collectionName":collectionName
        }
        collectionService.getCollectionList(params).then(function(response){
			$scope.collectionList = response.listdata;
			$scope.paginationConf.totalItems = response.totolCount;
			$scope.isLoading = false;
			$scope.$apply();
        },err =>{
			toastr.error(err.head.errorMsg);
			$scope.isLoading = false;
        })
    }

    $scope.delCollection = function(param){
        ModalUtils.confirm("确定要取消收藏吗?", "modal-warning", "lg", function () {
            collectionService.delCollection({
                collectionId:param.collectionId,
                type:param.type
            }).then(function(response){
                $scope.getDataList();
                toastr.success("取消收藏成功!")
            },err =>{
                toastr.error(err.head.errorMsg || '取消收藏失败')
            })
        })        
    }

    $scope.gotoCollection = function(collection){
        userService.judgePermission({option:"query",resType:collection.type,resId:collection.collectionId}).then(function(data){
            if(!data){
                toastr.error("您没有查看该看板权限！")
                return;
            }
            $state.go('display', {id: collection.collectionId});
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！')
        })
    }


};
import userPanel from './userPanel.html'
import userPanelCtrl from './userPanelCtrl'

export default angular.module('borepc.pages.userPanel', [])
    .controller('userPanelCtrl', ['$scope','$rootScope','toastr','$state','ModalUtils',userPanelCtrl])
    .config(['$stateProvider',routeConfig]).name;

function routeConfig ($stateProvider) {
    $stateProvider
        .state('userPanel', {
            url: '/userPanel',
            title: '用户界面',
            controller: 'userPanelCtrl',
            template: userPanel
        })
}
import { datasetService,datasetMetaTableService,behaviorLogService } from '../../../../server'
import dataContext from  '../../../../charts/DataContext'
import  PubSub from '../../../../common/utils/PubSub'
export default function metaTableModelCrtl($scope, $filter, toastr, metatableId, optFlag,$uibModal, uuid4, ModalUtils) {
    var translate = $filter('translate');
    $scope.optFlag = optFlag;
    $scope.metatableId = metatableId;
    $scope.loading = true;
    $scope.queryAceOpt = cbAcebaseOption;


    
    $scope.getMetaTableDetail = function(){
        datasetMetaTableService.getDetail({id:$scope.metatableId}).then(function(response){
            $scope.curData = response;angular.fromJson()
            $scope.curData.dataJson = angular.fromJson(response.dataJson);
            $scope.curData.dataSql = angular.fromJson(response.dataSql);
            $scope.dtpId = $scope.curData.dataJson.dtpId || "-1";
            $scope.datasourceId = $scope.curData.dataJson.datasourceId;
            getDtpList($scope.dtpId,$scope.datasourceId);
            $scope.$apply();
        },err=>{
            toastr.error(err.head.errorMsg || "获取信息失败!")
        })
    }

    $scope.inserBehaviortLog =function(){
        try {
            behaviorLogService.insertLog({module:"dataset",function:"查看数据集",name:$scope.dataset.name})
        } catch (error) {
            // console.log("插入日志失败:"+error)
        }
    }
     
    $scope.loadData = function (reload) {
        $scope.loading = true;
        // if(!$scope.dtp || !$scope.datasource || !$scope.curData.dataSql){
        //     toastr.warning("请填写必填项再读取数据!")
        //     return;
        // }
        // var query = {"sql":$scope.curData.dataSql}
        dataContext.getColumns({
            isDtpManager: $scope.dtp.isDtpManager,
            dtpId: $scope.dtp.id,
            datasource: $scope.datasource.id,
            query: $scope.curData.dataSql,
            datasetId: null,
            reload: reload,
            callback: function (dps) {
                $scope.loading = false;
                if (dps.msg == "1") {
                    $scope.alerts = [];
                    $scope.selects = dps.columns;
                } else {
                    $scope.alerts = [{
                        msg: dps.msg,
                        type: 'danger'
                    }];
                }
                $scope.$apply()
            }
        });
    };

    var getDtpList = function (dtpId,datasourceId) {
        datasetService.getDtpList().then(function (response) {
            $scope.dtpList = [{
                id: "-1",
                label: "无",
                isDtpManager: false
            }];
            angular.forEach(response.data.listdata, function (item) {
                $scope.dtpList.push(item)
                if(item.id==dtpId){
                    $scope.dtp=item;
                }
            })
            getDatasourceList(datasourceId);
            $scope.$apply();
        });
    }

    //改变选择的dtp
    $scope.changeDtp = function (dtp) {
        if (dtp) $scope.dtp = dtp;
        getDatasourceList();
        $scope.datasource = null; //将数据源置空
        $scope.datasourceList = []
    }
    var getDatasourceList = function (datasourceId) {
        if ($scope.dtp && $scope.dtp.isDtpManager) {
            datasetService.getDataSourceList({
                "dataJson": angular.toJson($scope.dtp)
            }).then(function (response) {
                if (response.status != '1') {
                    toastr.error("获取数据源列表失败,请检查您的DTP配置!");
                } else {
                    $scope.datasourceList = response.data;
                    if(!$scope.datasourceList || $scope.datasourceList.length<1 ||!datasourceId)return;
                    var datasources = _.filter(response.data,function(data){
                        return datasourceId==data.id;
                    })
                    if(datasources.length>0){
                        $scope.datasource = datasources[0];
                    }
                    $scope.$apply();
                }
            })
        } else {
            datasetService.getDataSourceListForDashboard().then(function (response) {
                $scope.datasourceList = response;
                if(!$scope.datasourceList || $scope.datasourceList.length<1)return;
                var datasources = _.filter(response.data,function(data){
                    return id==data.id;
                })
                if(datasources.length>0){
                    $scope.datasource = datasources[0];
                }
                $scope.$apply();
            });
        }
    }

   

    $scope.save = function (data) {
        data.dataJson = {
            dtpId:$scope.dtp && $scope.dtp.id,
            datasourceId:$scope.datasource && $scope.datasource.id
        };
        if(!data||!data.name||!data.code||!data.dataSql||!data.dataSql.sql||!data.dataJson||!data.dataJson.dtpId||!data.dataJson.datasourceId){
            toastr.warning("请输入必填项!")
            return;
        }
        datasetMetaTableService.saveMetaTable({
            dataJson: angular.toJson(data)
        }).then(function (response) {
            $scope.optFlag = 'edit';
            toastr.success("保存数据集成功!");
            $scope.$dismiss();
            PubSub.publish('METATABLECHANGE','') 
        },err=>{
            var errorMsg = (err.head&&err.head.errorMsg)||"保存失败!";
            toastr.error(errorMsg);
            $scope.alerts = [{
                msg: errorMsg,
                type: 'danger'
            }];
        });
    };
  

    $scope.changeDs = function (datasource) {
        if (!$scope.dtp) {
            toastr.warning("请先选择DTP!");
            return;
        }
        if (datasource) $scope.datasource = datasource;
        if ($scope.dtp.isDtpManager) {
            $scope.datasource.type = 'ODTP';
        }
    };

    var initMetaTable = function(){
        if($scope.metatableId){
            $scope.getMetaTableDetail();
        }else{
            $scope.curData = {
                dataJson:{},
                dataSql:{}
            }
           getDtpList();
        }
    }
    initMetaTable();
    /** Ace Editor Starer... **/
    // $scope.queryAceOpt = datasetEditorOptions();
    //编辑弹窗最大化
    $scope.editFullScreenFlag = false;
    $scope.toEditFullScreen = function () {
        if (!$scope.editFullScreenFlag) {
            $('.widgetmodal .modal-dialog').removeClass('modal-lg').addClass('modal-fullScreen');
            $('.widgetmodal .modal-header button span i').removeClass().addClass('fa fa-minus');
            $scope.editFullScreenFlag = true;
        } else {
            $('.widgetmodal .modal-dialog').removeClass('modal-fullScreen').addClass('modal-lg');
            $('.widgetmodal .modal-header button span i').removeClass().addClass('fa fa-square-o');
            $scope.editFullScreenFlag = false;
        }
    }
}
import {userService} from '../../../../server'
  export default  function BaSidebarCtrl($scope, baSidebarService) {
     

    userService.getMenuList().then((data) => {
      let menuList = [];
      let menuType=[]
      const root = _.find(data, (item) => { return item.parentId === '1' }) //根节点
      const root_id=root?root.id:'20001';
      // if(_.isUndefined(root)){
      //   return ;
      // }
      _.forEach(data, (item) => { //一级菜单
        if (item.parentId == root_id) {
          let subMenu = []
          _.forEach(data, (subitem) => { //二级菜单
            if (subitem.parentId == item.id) {
              subMenu.push({
                title: subitem.name,
                code:subitem.code,
                stateRef: subitem.href,
              })
            }
          })
          menuList.push(subMenu.length>0?{
            title: item.name,
            code:item.code,
            icon: item.icon,
            stateRef: item.href,
            subMenu: subMenu
          }:{
            title: item.name,
            code:item.code,
            stateRef: item.href,
            icon: item.icon,
          })
        }
        menuType[item.code]=true;
      })
      // _.forEach(menuList, (item) => {
      //   console.log(item)
      //   baSidebarServiceProvider.addStaticItem(item)
      // })
      $scope.menuType=menuType; 
      $scope.menuItems = menuList;
      $scope.defaultSidebarState = $scope.menuItems[0].stateRef;
      $scope.$apply()
    })


    $scope.hoverItem = function ($event) {
      $scope.showHoverElem = true;
      $scope.hoverElemHeight =  $event.currentTarget.clientHeight;
      var menuTopValue = 66;
      $scope.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - menuTopValue;
    };

    $scope.$on('$stateChangeSuccess', function () {
      if (baSidebarService.canSidebarBeHidden()) {
        baSidebarService.setMenuCollapsed(true);
      }
    });
  }
import { accountCenterService, orderCenterService } from '../../../server'

import accountWarningHtml from '../../../public/dmModel/accountWarning.html'
import windowHtml from '../../../public/util/modal/window.html'

 
 export default function ( $scope, $uibModal, toastr) {
    $scope.isLogin = true;
    //获取账户信息

    var getModelAccount = function (isAssociateAccount) {
        orderCenterService.getOssCompanyInfo().then(function (response) {
            if (!response || !response.companyId) {
                $scope.isLogin = false;
                if(isAssociateAccount){toastr.error("关联账户失败,请检查您输入的appkey和secret");}
                return;
            }
            if(isAssociateAccount){toastr.success("激活账户成功!");}
            $scope.company = response;
            $scope.isLogin = true;
        });
    }
    getModelAccount();

    //关联账户
    $scope.associateAccount = function(){
        var param = {
            "appkey":$scope.company.appkey,
            "appsecret":$scope.company.secret,
            "type":"boreoss"
        }

        accountCenterService.saveAccount(param).then( response =>{
            if(response.head.status!='1'){
                toastr.error("关联账户失败,请检查您输入的appkey和secret", "modal-warning", "lg");
                return;
            }
            getModelAccount(true);
            $scope.getAppOrder()
        }).catch((response) => {
            if(response.data.head.status!='1'){
                toastr.error("关联账户失败,请检查您输入的appkey和secret", "modal-warning", "lg");
                return;
            }
            getModelAccount(true);
            $scope.getAppOrder()
        })
    }

    $scope.getAppOrder = function(){
        orderCenterService.getAppOrder().then(function(response){
            $scope.appOrder = response;
        },err=>{
            toastr.error(err.head.errorMsg || "获取企业开账订单信息失败!")
        })
    }
    $scope.getAppOrder();

    // var openWarningPage = function () {
    //     $uibModal.open({
    //         template: accountWarningHtml,
    //         windowTemplate: windowHtml,
    //         backdrop: false,
    //         size: 'sm',
    //         controller: function ($scope, $uibModalInstance) {

    //         }
    //     });
    // }
};
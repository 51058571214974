import dtpManagerTpl from './dtpManager.html'
import dtpManagerCtrl  from './dtpManagerCtrl'
import dtpDatasourceTpl from './dtpDatasource/dtpDatasource.html'
import dtpDatasourceCrl  from './dtpDatasource/dtpDatasourceCtrl'
  
export default angular.module('borepc.pages.dtpManager', [])
.controller('dtpManagerCtrl', ['ModalUtils','$scope',  '$state',  '$window', '$uibModal','toastr',dtpManagerCtrl])
.controller('dtpDatasourceCrl', ['$stateParams', '$scope','$uibModal','toastr','ModalUtils',dtpDatasourceCrl])
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('dtpManager', {
      url: '/dtpManager',
      title: 'dtpManager',
      template: dtpManagerTpl,
      controller: 'dtpManagerCtrl',
    })
    .state('dtpDatasource', {
      url: '/dtpDatasource/{dtpJson}',
      param:{dtpJson:null},
      title: 'DTP数据源列表',
      template: dtpDatasourceTpl,
      controller: 'dtpDatasourceCrl',
    });
}


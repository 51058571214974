import msgCenterTpl from './msgCenter.html'
import MsgCenterCtrl from './MsgCenterCtrl'
import './_msgCenter.scss'


/** @ngInject */
function msgCenter() {
  return {
    restrict: 'E',
    template: msgCenterTpl,
    controller: 'MsgCenterCtrl'
  };
}
export default angular.module('BlurAdmin.theme.components.msgCenter',[])
  .controller('MsgCenterCtrl', ['$rootScope','$scope','$state',MsgCenterCtrl])
  .directive('msgCenter', msgCenter).name;
import profileTpl from './profile.html'
import ProfilePageCtrl  from './ProfilePageCtrl'
import  ProfileModalCtrl from './ProfileModalCtrl'

  
export default angular.module('borepc.pages.profile', [])
.controller('ProfilePageCtrl', ['$scope', 'fileReader', '$filter', '$uibModal', 'widgetModal',ProfilePageCtrl])
.controller('ProfileModalCtrl', ['$scope', '$uibModalInstance',ProfileModalCtrl]) 
.config(['$stateProvider',routeConfig]).name ;

/** @ngInject */
function routeConfig($stateProvider) {
  $stateProvider
    .state('profile', {
      url: '/profile',
      title: 'Profile',
      template: profileTpl,
      controller: 'ProfilePageCtrl',
    });
}


import {widgetService, userService } from '../../../../server'

export default function($scope, $uibModalInstance, widgetModal, toastr, widgetList, categoryList, widgetId, ok){

    $scope.isLoading = true;                                        //加载状态

    $scope.widgetList = widgetList ? widgetList : [];               //图表列表
    $scope.curWidget = {};                                          //当前的图表
    $scope.curWidget.id = widgetId;                                 //当前的图表的id
    $scope.showWidgetList = [];                                     //筛选后的列表----显示用图表列表

    $scope.categoryList =  categoryList ? categoryList : [];        //目录列表
    $scope.curCategory = undefined;                                 //当前目录--默认为根目录
    $scope.curCategoryList = [];                                    //当前目录列表
    $scope.curChildrenCategoryList = [];                            //当前子目录列表

    $scope.search = {};
    $scope.search.value = "";
    $scope.widgetTypeArr = [{"value":"无"},{"key":"dupont","value":"杜邦图"},{"key":"pie","value":"饼状图"},{"key":"areaMap","value":"区域地图"},{"key":"table","value":"交叉表"},{"key":"datatables","value":"数据表"},{"key":"line","value":"折线/柱状图"},{"key":"contrast","value":"对比图"},{"key":"scatter","value":"散点地图"},{"key":"kpi","value":"指标卡"},{"key":"funnel","value":"漏斗图"},{"key":"san","value":"桑基图"},{"key":"radar","value":"雷达图"},{"key":"map","value":"中国地图"},{"key":"gauge","value":"计量图"},{"key":"wordCloud","value":"标签云"},{"key":"treeMap","value":"矩形树图"},{"key":"heatMapCalendar","value":"热点图（日历）"},{"key":"heatMapTable","value":"热点图（表格）"},{"key":"liquidFill","value":"水球图"},{"key":"chinaMap","value":"中国地图（本地）"},{"key":"chinaMapBmap","value":"中国地图（百度）"},{"key":"relation","value":"关系图"},{"key":"worldMap","value":"世界地图"},{"key":"budget","value":"预算"},{"key":"kpi2","value":"指标卡2"},{"key":"kpi4","value":"指标卡4"},{"key":"custom","value":"自定义"}];
    // $scope.widgetTypeArr = ["杜邦图","饼状图","区域地图","交叉表","数据表","折线/柱状图","对比图","散点地图","指标卡","漏斗图","桑基图","雷达图","中国地图","计量图","标签云","矩形树图","热点图（日历）","热点图（表格）","水球图","中国地图（本地）","中国地图（百度）","关系图","世界地图","预算","指标卡2","自定义"];

    //分页
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 32,
        onChange: function () {

        }
    };
    //获取筛选后图表的总数
    var getPaginationConfTotal = function(){
        $scope.paginationConf.totalItems = $scope.showWidgetList ? $scope.showWidgetList.length : 0;
    };

    $scope.changeWidgetType =function(e){
        $scope.widgetTypeKey = e.key;
        $scope.isLoading = true;
        getshowWidgetList();
    }

    $scope.showWidgetListMax = function(){
        return $scope.paginationConf.currentPage * $scope.paginationConf.itemsPerPage - 1;
    };
    $scope.showWidgetListMin = function(){
        return $scope.paginationConf.currentPage * $scope.paginationConf.itemsPerPage - $scope.paginationConf.itemsPerPage;
    };

    //确定的方法
    $scope.ok = function(){
        //只有重新选择后才执行ok的方法
        if($scope.curWidget.id != widgetId) {
            ok($scope.curWidget);
        }
        $uibModalInstance.close();
    };

    $scope.close = function(){
        $uibModalInstance.close();
    };

    //选择目录的方法----2020.10.09没有分层结构
    $scope.selectCategory = function(categoryName){
        $scope.isLoading = true;
        // $scope.widgetType=null;
        if(categoryName) {
            $scope.curCategory = categoryName;
            $scope.curCategoryList = [];
            $scope.curCategoryList.push(categoryName);
            $scope.curChildrenCategoryList = [];
            getshowWidgetList();
        } else {
            $scope.curCategory = undefined;
            $scope.curCategoryList = [];
            $scope.curChildrenCategoryList = $scope.categoryList;
            getshowWidgetList();
        }
    };

    //搜索的方法
    $scope.search = function() {
        $scope.isLoading = true;
        getshowWidgetList();
    };

    //获取筛选的图表列表
    var getshowWidgetList = function(){
        if($scope.widgetList && $scope.widgetList.length > 0) {
            $scope.showWidgetList = [];
            var i = 0, len = $scope.widgetList.length;
            for(; i< len; i++) {
                //当前目录不为根目录且图表的目录不是当前目录，或 搜索关键字存在且不符合图表标题，则跳过
                if( ($scope.curCategory && $scope.curCategory !== $scope.widgetList[i].categoryName) || ($scope.search.value && $scope.widgetList[i].name.indexOf($scope.search.value) == -1)) {
                    continue;
                }
                const chartType = $scope.widgetList[i].data.config.chart_type;
                if($scope.widgetTypeKey  && chartType!=$scope.widgetTypeKey ){
                    continue;
                }
                $scope.showWidgetList.push($scope.widgetList[i]);
            }
            //分页的图表总数
            getPaginationConfTotal();

        } else {
            $scope.showWidgetList = [];
            getPaginationConfTotal();
        }
        $scope.isLoading = false;
    };

    //展示图表的方法
    $scope.showWidget = function(widget){
        const w=angular.copy(widget)
        w.widget=widget
        userService.judgePermission({option:"query",resType:"widget",resId:widget.id}).then(function(data){
            if(!data){
                toastr.error("您没有查看该图表权限！");
                return;
            }
            widgetModal.show(w);
        },err=>{
            toastr.error(err.head.errorMsg || '获取权限失败！');
        })
    };

    //选择图表的方法
    $scope.selectWidget = function(widget){
        $scope.curWidget = widget;
    };

    //初始化
    //没有传图表列表的情况
    if($scope.widgetList.length == 0) {
        //重新获取图表列表
        widgetService.getWidgetList().then(function (response) {
            $scope.widgetList = response;
            $scope.$apply();
            $scope.selectCategory();
        },err=> {
          toastr.error(err.head && err.head.errorMsg);
       });
    }
    //没有传目录列表的情况
    if($scope.categoryList.length == 0) {
        //重新获取目录列表
        widgetService.getWidgetCategoryList().then(function (response) {
            $scope.categoryList = response;
            $scope.$apply();
            $scope.selectCategory();
        });
    }
    //两个列表都有传值
    if($scope.categoryList.length != 0 && $scope.widgetList.length != 0) {
        //模拟点击根目录
        $scope.selectCategory();
    }

}

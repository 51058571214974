import { dataSupportService, dtpManagerService } from '../../../server'
import './dataSupport.scss'


export default function ($scope, ModalUtils) {
    $scope.showDtp=true;
    //获取dtp列表
    dtpManagerService.getDtpList().then( response => {
        if(response.status!='1'){
            ModalUtils.alert("获取dtp列表失败", "modal-warning", "lg");
            return;
        }
        $scope.dtpList = response.data.listdata;
    })
    //选择dtp
    $scope.checkDtp = function(data){
       getDatasourceList(data)
       if(data.id!=$scope.dtpId){
           $scope.showTableDetailFlag = false;//如果再次选择的不是原来的dtp,关闭右侧表单页面
       }
    }
    //返回dtp列表
    $scope.goBackDtp = function(){
        $scope.showDtp=true;
    }
    //获取数据源列表
    var getDatasourceList = function (data) {
        var params ={
            "dtpManager":data
        }
        dataSupportService.getDataSourceList({
            "dataJson":angular.toJson(params)
        }).then(response => {
            if (response.status == '2') {
                ModalUtils.alert( "获取数据源列表失败", "modal-warning", 'md','',true);
                return
            }
            $scope.dataSourceNameList = response;
            $scope.dtpId = data.id;
            $scope.dtp = data;
            $scope.showDtp =false;
            $scope.$apply()
        })
    }

    //获取数据表列表
    $scope.tableNameList = {};
    $scope.getTableName = function (dataSourceName) {
        var params ={
            dataSourceName : dataSourceName,
            "dtpManager":$scope.dtp
        }
        dataSupportService.getAllTableName({
            "dataJson":angular.toJson(params)
        }).then(response => {
            if (response.status == '2') {
                ModalUtils.alert( response.msg || "获取数据源列表失败", "modal-warning", 'md','',true);
                return
            }
             $scope.tableNameList[dataSourceName] = response;
             $scope.$apply()
        })
    }

    //点击展示数据表
    $scope.showTableDetailFlag = false; 
    $scope.showTableDetail = function (dataSourceName, tableName,tableChName) {
        $scope.showSearchBtn = false;
        $scope.dataSourceName = dataSourceName;
        $scope.tableName = tableName;
        $scope.tableChName = tableChName;
        $scope.pageSize = 50;
        $scope.pageNumber = 1;
        //获取表头 元信息
        $scope.colModel = [];
        $scope.colNames = [];
        $scope.tableHead = [];
        $scope.form = {};
        $scope.addForm = {};
        $scope.searchTableHead = [];
        $scope.searchForm = {};
        var params ={
            dataSourceName:dataSourceName,
            tableName:tableName,
            "dtpManager":$scope.dtp
        }
        dataSupportService.getSchemaData({
            "dataJson":angular.toJson(params)
        }).then(response => {
            if (response.status == '2') {
                ModalUtils.alert( response.msg || "获取SchemaData失败", "modal-warning", 'md','',true);
                return
            }
            for (var i = 0; i < response.length; i++) {
                if (response[i]['IS_SHOW'] === 'N') {
                    $scope.colModel.push({
                        name: response[i]['COL_EN_NAME'],
                        index: response[i]['COL_EN_NAME'],
                        align: "center",
                        editable: false,
                        hidden:true
                    })
                    $scope.colNames.push(response[i]['COL_CH_NAME']);
                }else if (response[i]['IS_SHOW'] === 'Y') {
                    $scope.colModel.push({
                        name: response[i]['COL_EN_NAME'],
                        index: response[i]['COL_EN_NAME'],
                        align: "center",
                        editable: true,
                        editoptions: { size: 50 }
                    })
                    $scope.colNames.push(response[i]['COL_CH_NAME']);
                    $scope.tableHead.push([response[i]['COL_EN_NAME'],response[i]['COL_CH_NAME']]);
                    $scope.form[response[i]['COL_EN_NAME']] = '';
                    $scope.addForm[response[i]['COL_EN_NAME']] = '';
                }
            }
            for (var i = 0; i < response.length; i++) {
                if (response[i]['IS_QUERY'] === 'Y') {
                    $scope.showSearchBtn = true;
                    $scope.searchTableHead.push([response[i]['COL_EN_NAME'],response[i]['COL_CH_NAME']]);
                    $scope.searchForm[response[i]['COL_EN_NAME']] = '';
                }
            }
            $scope.$apply()
        }).then( () => {
            var param = {
                dataSourceName,
                tableName,
                "dtpManager":$scope.dtp,
                "pageSize":$scope.pageSize,
                "pageNumber":$scope.pageNumber,
                "queryParams":{}
            }
            dataSupportService.getTableData({
                "dataJson":angular.toJson(param)
            }).then(response => {
                if (response.status == '2') {
                    ModalUtils.alert( response.msg || "获取tableData失败", "modal-warning", 'md','',true);
                    return
                }
                $scope.localdata = response;
                pageInit($scope.localdata, $scope.colModel,$scope.colNames,$scope.tableChName);
                $scope.$apply()
            }) 
        })
        $scope.showTableDetailFlag = true;
    }

    //生成jqGrid
    function pageInit(data, colModel,colNames,caption) {
        $("#jqGrid").jqGrid('GridUnload'); //销毁之前的表格
        jQuery("#jqGrid").jqGrid(
            {
                data: data,
                datatype: "local",
                mtype: "POST",
                styleUI: 'Bootstrap',//设置jqgrid的全局样式为bootstrap样式 
                colNames: colNames,
                colModel: colModel,
                rowNum : 50,
                rowList: [20, 30, 40],
                rownumbers: true,
                sortname: 'id', //默认的排序列。可以是列名称或者是一个数字，这个参数会被提交到后台
                viewrecords: true, //定义是否要显示总记录数      
                multiselect: true,
                height: "auto",
                autowidth: true,
                shrinkToFit:colNames.length>10?false:true,
                autoScroll: true,
                hidegrid:false,//隐藏收缩按钮
            });
        //表格大小可缩放
        // jQuery("#jqGrid").jqGrid('gridResize', { minWidth: 350, maxWidth: 1000, minHeight: 80, maxHeight: 650 });
    }

    //分页栏
    $scope.preBtn = function () {
        if ($scope.pageNumber === 1) {
            $scope.pageNumber = 1;
        }else{
            $scope.pageNumber -- ;
            var params ={
                "dataSourceName":$scope.dataSourceName, 
                "tableName":$scope.tableName,
                "dtpManager":$scope.dtp, 
                "pageSize":$scope.pageSize,
                "pageNumber":$scope.pageNumber,
                "queryParams":{}
            }
            // $http.post(baseUrl + 'getTableData',{
            //     "dataJson":angular.toJson(params)
            // })
            dataSupportService.getTableData({
                "dataJson":angular.toJson(params)
            }).then(response => {
                if (response.status == '2') {
                    ModalUtils.alert( response.msg || "获取tableData失败", "modal-warning", 'md','',true);
                    return
                }
                $scope.localdata = response;
                pageInit($scope.localdata, $scope.colModel,$scope.colNames,$scope.tableName);
                $scope.$apply()
            })
        }
    }
    $scope.nexBtn = function () {
        $scope.pageNumber ++ ; 
        var params ={
            "dtpManager":$scope.dtp,
            "dataSourceName":$scope.dataSourceName,
            "tableName":$scope.tableName, 
            "pageSize":$scope.pageSize,
            "pageNumber":$scope.pageNumber,
            "queryParams":{}
        }
        // $http.post(baseUrl + 'getTableData',{
        //     "dataJson":angular.toJson(params)
        // })
        dataSupportService.getTableData({
            "dataJson":angular.toJson(params)
        }).then(response => {
            if (response.status == '2') {
                ModalUtils.alert( response.msg || "获取tableData失败", "modal-warning", 'md','',true);
                return
            }
            $scope.localdata = response;
            pageInit($scope.localdata, $scope.colModel,$scope.colNames,$scope.tableName);
            $scope.$apply()
        })
    }


    //获取选区行的数据信息
    $scope.rowId = ""; //所选行的id值
    function getRowInfo () {
        $scope.rowId =  jQuery("#jqGrid").jqGrid("getGridParam", "selrow"); 
        var rowData = jQuery("#jqGrid").jqGrid("getRowData", $scope.rowId);
        return rowData
    }

    //获取多行信息
    $scope.rowIds = [];
    function getSelectedRows() {
        //获取多选到的id集合
        $scope.rowIds = $("#jqGrid").jqGrid("getGridParam", "selarrrow");
        //遍历访问这个集合
        var rowsInfo = [];
        $($scope.rowIds).each(function (index, id) {
            //由id获得对应数据行
            var row = $("#jqGrid").jqGrid('getRowData', id);
            rowsInfo.push(row);
        })
        return rowsInfo;
    }
 
    //新增选项
    $scope.addSubmit = function () {
        //发送数据到服务器
        var data = handleData($scope.dataSourceName,$scope.tableName,'');
        data.addData.dtpManager =  $scope.dtp;
        dataSupportService.save({
            "dataJson":angular.toJson(data.addData)
        }).then(response => {
            if (response.status == '2') {
                ModalUtils.alert( response.msg || "新增数据失败", "modal-warning", 'md','',true);
            }else if(response.status == '1') {
                ModalUtils.success( response.msg || "新增数据成功");
            }
            $scope.$apply()
        }).then(() => {
            var params ={
                "dtpManager":$scope.dtp,
                "dataSourceName":$scope.dataSourceName, 
                "tableName":$scope.tableName, 
                "pageSize": $scope.pageSize, 
                "pageNumber": $scope.pageNumber, 
                "queryParams": {}
            }
            //重新渲染表格
            // $http.post(baseUrl + 'getTableData', { 
            //     "dataJson":angular.toJson(params)
            // })
            dataSupportService.getTableData( { 
                "dataJson":angular.toJson(params)
            }).then(response => {
                if (response.status == '2') {
                    ModalUtils.alert(response.msg || "获取tableData失败", "modal-warning", 'md','',true);
                    return
                }
                $scope.localdata = response;
                pageInit($scope.localdata, $scope.colModel, $scope.colNames, $scope.tableChName);
                $scope.$apply()
            })
        })
        $('#myModal').modal('hide');
    }

    //编辑提示
    $scope.editTip = function () {
        var rowInfo = getSelectedRows();
        if ($scope.rowIds.length !== 1) {
            $('#myModalEditTip').modal();        
        }else{
            // $scope.form = rowdata;
            for (var key in $scope.form) {
                $scope.form[key] = rowInfo[0][key];
            }
            $('#myModalEdit').modal();
        }
    }
    //编辑submit
    $scope.editSubmit = function () {
        var id = getRowInfo()['ID'] ? getRowInfo()['ID'] : getRowInfo()['id'];
        var data = handleData($scope.dataSourceName,$scope.tableName,id);
        data.data.dtpManager =  $scope.dtp;
        dataSupportService.save({
            "dataJson":angular.toJson(data.data)
        }).then(response => {
            if (response.status == '2') {
                ModalUtils.alert( response.msg || "编辑数据失败", "modal-warning", 'md','',true);
                return
            }else if(response.status == '1') {
                ModalUtils.success( response.msg || "编辑数据成功");
                //更新修改行
                $("#jqGrid").jqGrid("setRowData", $scope.rowId, data.queryParams);
            }
            $scope.$apply()
        });
        $('#myModalEdit').modal('hide');
    }
    //处理模态框数据
    function handleData (dataSourceName,tableName,id) {
        var queryParams = angular.copy($scope.form);
        var addQueryParams = angular.copy($scope.addForm);
        var data = {
            dataSourceName,
            tableName,
            id,
            "mappings": queryParams,
        };
        var addData = {
            dataSourceName,
            tableName,
            id,
            "mappings": addQueryParams,
        }
        for (var item in $scope.form) {
            $scope.form[item] = ''
        }
        for (var item in $scope.addForm) {
            $scope.addForm[item] = ''
        }
        return {queryParams,addQueryParams,addData, data}
    }
    //查询submit
    $scope.searchSubmit = function () {
        var data = handleSearchData($scope.dataSourceName, $scope.tableName, $scope.pageSize, $scope.pageNumber);
        data.dtpManager =  $scope.dtp;
        dataSupportService.getTableData({
            "dataJson":angular.toJson(data)
        }).then(response => {
            $scope.localdata = response;
            pageInit($scope.localdata, $scope.colModel,$scope.colNames,$scope.tableChName);
            $scope.$apply()
        })
        $('#myModalSearch').modal('hide');     
        
    }
    //处理查询模态框数据
    function handleSearchData (dataSourceName,tableName,pageSize,pageNumber) {
        var queryParams = angular.copy($scope.searchForm);
        var data = {
            dataSourceName,
            tableName,
            pageSize,
            pageNumber,
            "queryParams" :queryParams
        }
        for (var item in $scope.searchForm) {
            $scope.searchForm[item] = '';
        }
        return data
    }
    //删除行
    $scope.delSubmit = function () {
        // var id = getRowInfo()['ID'] ? getRowInfo()['ID'] : getRowInfo()['id'];
        var idArr = [];
        var id = "";
        var rowsInfo = getSelectedRows();
        for (var i = 0; i < rowsInfo.length; i++) {
            idArr.push(rowsInfo[i]['ID'] ? rowsInfo[i]['ID']:rowsInfo[i]['id']);
        }
        id = idArr.join(",");
        var data = {
            "dataSourceName":$scope.dataSourceName,
            "tableName":$scope.tableName,
            "id":id
            } 
        data.dtpManager =  $scope.dtp;
        dataSupportService.delete({
            "dataJson":angular.toJson(data)
        }).then(response => {
            if (response.status == '2') {
                ModalUtils.alert( response.msg || "删除数据失败", "modal-warning", 'md','',true);
                return
            }else if (response.status == '1') {
                ModalUtils.success(response.msg || "删除数据成功");
                //表内删除
                for (var i = 0, len = $scope.rowIds.length; i < len; i++) {
                    $("#jqGrid").jqGrid("delRowData", $scope.rowIds[0]);
                }
            }  
            $scope.$apply()
        })
        $('#myModalDel').modal('hide') ;
    }
    //删除提示
    $scope.delTip = function () {
        getSelectedRows();
        if ($scope.rowIds.length === 0) {
            $('#myModalTip').modal();        
        }else{
            $('#myModalDel').modal();
        }
    }
};
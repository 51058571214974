/**
 * mySetToolInputbox
 * 
 * boreDataType: text number 输入框的类型
 * boreDataTitle: 输入框前面的说明文---限制5个字
 * boreDataTip: 移入显示说明文---tip
 * boreDataPlaceholder: 无内容时显示的文本
 * boreDataInit: 初始化值
 * boreDataValue: 关联的值
 * boreDataUndefinedBlank: 空值转为undefined----默认不转作为空字符串
 */

export default function mySetToolInputbox(){
    return {
        restrict: 'EA',
        template: ` <div class="bore-set-tool">
                        <div class="bore-set-tool-title" ng-click="inputFocus($event)">{{boreDataTitle}}</div>
                        <div class="bore-set-tool-content">
                            <input type="{{boreDataType}}" class="bore-set-tool-inputbox" ng-model="boreDataValue" placeholder="{{boreDataPlaceholder}}" title="{{boreDataValue ? boreDataValue : boreDataPlaceholder}}">
                        </div>
                        <div class="bore-set-tool-tip" ng-class="{'show':boreDataTip?true:false}">
                            <span>?</span>
                            <span class="tip-content">{{boreDataTip}}</span>
                        </div>
                    </div>`,
        replace: true,
        scope: {
            boreDataValue: '='
        },
        link: function(scope, element, attrs) {
            scope.inputFocus = function($event){
                $($event.target).next().children()[0].focus();
            };
            scope.boreDataType = attrs.boreDataType ? attrs.boreDataType : "text";
            scope.boreDataTitle = attrs.boreDataTitle ? attrs.boreDataTitle : "";
            scope.boreDataTip = attrs.boreDataTip ? attrs.boreDataTip : "";
            scope.boreDataPlaceholder = attrs.boreDataPlaceholder ? attrs.boreDataPlaceholder : "";
            if(scope.boreDataValue === undefined && attrs.boreDataInit !== undefined ){
                if(scope.boreDataType == "number") {
                    scope.boreDataValue = Number(attrs.boreDataInit);
                } else {
                    scope.boreDataValue = attrs.boreDataInit;
                }
            }

            //true则空值作为undefined，否则""
            scope.blankChange = attrs.boreDataUndefinedBlank === "true" ? true : false;
            //迷：怎么通过指令后，number的值变成了字符串
            if(scope.boreDataType == "number"){
                scope.$watch("boreDataValue", function(){
                    if(scope.boreDataValue === "" && scope.blankChange) {
                        //将空字符转为undefined
                        scope.boreDataValue = undefined;
                    } else {
                        //空字符串----不做动作保留空字符串
                        scope.boreDataValue || scope.boreDataValue == "0" ? scope.boreDataValue = Number(scope.boreDataValue) : null;
                    }
                });
            } else {
                if(scope.blankChange) {
                    //将空字符转为undefined
                    scope.$watch("boreDataValue", function(){
                        if(scope.boreDataValue === "") {
                            scope.boreDataValue = undefined;
                        }
                    });
                }
            }

        }
    };
}
import BasicService from './BasicService';
import { DTPAPI } from './ApiServer';
/**
 */
class DtpService extends BasicService {
  dsApiList() {
    return this.get(DTPAPI.DSAPILIST)
  }
  invoke(code,token,param){
    return this.get(DTPAPI.DSINVOKE+'/'+ encodeURIComponent(code) +"?token="+token+"&param="+param)
  }
  dtpDsApiList(dtpId) {
    return this.get(DTPAPI.DTPDSAPILIST+'?dtpId='+dtpId)
  }
  dtpInvoke(dtpId,code,token,param){
    return this.get(DTPAPI.DTPDSINVOKE+'/'+ encodeURIComponent(code) +"?dtpId="+dtpId+"&token="+token+"&param="+param)
  }
}

export const dtpService = new DtpService();

import baseEditorTpl from './base/baseEditor.html'
import BaseEditorCrtl from './base/BaseEditorCrtl'
function widgetEditor($compile) {
  var renderEditor = function (scope, element, attrs) {
    if (_.isUndefined(scope.curWidget) || _.isUndefined(scope.curWidget.config.chart_type)) 
    { return }
    var c_name = scope.curWidget.config.chart_type.toLowerCase();
    var template = '<div id="widgetEditor" class="editor-config" ' + c_name + '-editor ></div>'
    var rhtml = $compile(template)(scope);
    $(element).find('#widgetEditor').replaceWith(rhtml);
  };
  return {
    restrict: 'EA',
    scope: false,
    template:require('./editor.html'),
    compile: function (element, attrs) {
      return {
        post: function (scope, element, attrs) {
          // 监控widget数据变化
          scope.$watch('curWidget.config.chart_type', function (newValue, oldValue) {
            if(newValue!=oldValue){
              renderEditor(scope, element, attrs)
            }
          }, true);
        }
      }
    }
  }
}


export default angular.module('BlurAdmin.theme.components.widgetEditor', [])
  .directive('keys', function () {
    return {
      template: require('./base/keys.html'),
      scope: false
    }
  })
  .directive('groups', function () {
    return {
      template: require('./base/groups.html'),
      scope: false
    }
  })
  .directive('filters', function () {
    return {
      template: require('./base/filters.html'),
      scope: false
    }
  })
  .directive('config', function () {
    return {
      template: require('./base/config.html'),
      scope: false
    }
  })
  //  .component('keys',{  template:keysTpl })
  //  .component('groups',{  template:groupsTpl })
  //  .component('filters',{  template:filtersTpl })
  //  .component('config',{  template:configTpl })
  .directive('baseEditor', function () {
    return {
      template: baseEditorTpl,
      scope: false,
      controllerAs: 'BaseEditorCrtl',
      controller: [BaseEditorCrtl]
    }
  })
  .directive('widgetEditor', ['$compile', widgetEditor]).name;

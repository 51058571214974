import BasicService from './BasicService';
import { PROPERTYAPI } from './ApiServer';
/**
 * @desc 系统配置
 */
class BorePropertyService extends BasicService {
  constructor() {
    super();
  }
  getPropertyList(params){
    return this.post(PROPERTYAPI.GETPROPERTYLIST,params)
  }
  saveProperty(params){
    return this.post(PROPERTYAPI.SAVEPROPERTY,params)
  }
  getPropertyByKey(params){
    return this.post(PROPERTYAPI.GETPROPERTYBYKEY,params)
  }
  delProperty(params){
    return this.post(PROPERTYAPI.DELPROPERTY,params)
  }
}

export const borePropertyService = new BorePropertyService();

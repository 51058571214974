import {messageService} from '../../../server'
import './_message.scss'

export default  function messageCtrl(  toastr, $scope,  $state) {
    $scope.keyword='';
    $scope.isLoading = true;
    // currentPage：当前页；totalItems：总记录数；itemsPerPage：每页记录数；perPageOptions：分页选项；onChange：当页码变更后自动触发的方法
    $scope.paginationConf = {
        currentPage: 1,
        totalItems: 0,
        itemsPerPage: 18,
        onChange: function () {
            $scope.searchMessage(); //重新加载
        }
    };

	$scope.getTypeValue = function(value) {
	    if (value == "0")
	        return "普通消息";
	    if (value == "1")
	        return "系统消息";
		if (value == "2")
			return "预警消息";
		if (value == "3")
			return "定时推送消息";
	    
	};
	
	$scope.searchMessage = function() {
        $scope.isLoading = true;
        var params = {
            "pageNumber": $scope.paginationConf.currentPage,
            "pageSize": $scope.paginationConf.itemsPerPage,
            "keyword":$scope.keyword,
            "status":$scope.status
        }
        messageService.getMessageList(params).then(function(response){
            $scope.messageList = response.list;
            $scope.paginationConf.totalItems = response.count;
            $scope.isLoading = false;
            toastr.success("获取消息列表成功!");
        },err=>{
            toastr.error(err.head.errorMsg || "获取消息列表失败!");
        })
    }
	
	// 前往详情页
	$scope.gotoDetail=function (id){
		$state.go('messageDetail', {id: id});
	}
}

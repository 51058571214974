export  default  function boardArrayFilter () {
    return function (data, categoryId, item, pageNo, pageSize) {
        var filterData = data || new Array();
        if (categoryId) {
            filterData = new Array;
            _.each(data, function (e) {
                var bi = item.indexOf(e.categoryId + '');
                if (item.indexOf(e.categoryId + '') > -1) {
                    filterData.push(e);
                }
            });
        }
        var result = filterData.slice((pageNo - 1) * pageSize, pageNo * pageSize);
        return result;
    }
}